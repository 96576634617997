import { createSlice } from '@reduxjs/toolkit';

const quoteEngineSlice = createSlice({
  name: 'quoteEngine',
  initialState: {
    subHeaderText: 'Centerline Quote Engine',
    questionTitleText: 'What type of service would you like to quote?',
    menuData: [
      {
        text: 'Drivers',
        icon: 'icon-drivers',
        to: '/quote-engine/generate-quote/drivers',
      },
      {
        text: 'Clerical',
        icon: 'icon-clerical',
        to: '/quote-engine/generate-quote/clerical',
        description: 'Managers, Supervisors & Administrative Support',
      },
    ],
    currentTab: 'drivers',
  },
  reducers: {
    setCurrentTab: (state, { payload }) => {
      if (payload !== state.currentTab) {
        state.currentTab = payload;
      }
    },
  },
});

export default quoteEngineSlice.reducer;

export const { setCurrentTab } = quoteEngineSlice.actions;
