import React, { useMemo } from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { formatIsoToDate } from '../../utils';
import { getCurrencyString } from '../../utils/utilities';
import TableFilter from '../TableFilter';
import TablePagination from '../TablePagination';

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const QuotesTable = ({ data, loading }) => {
  const columnClassName = 'align-middle text-nowrap';
  const memoData = useMemo(() => data, [data]);

  const columns = useMemo(
    () => [
      {
        Header: 'Number',
        accessor: 'quoteId',
        className: columnClassName,
        Cell: instance => <Link to={`/quote-engine/view-quote/${instance.value}`}>{instance.value}</Link>,
      },
      {
        Header: 'Name',
        accessor: 'quoteName',
        className: columnClassName,
      },
      {
        Header: 'Type',
        accessor: 'quoteType',
        className: columnClassName,
      },
      {
        Header: 'User',
        accessor: 'quoteUserId',
        className: columnClassName,
      },
      {
        Header: 'Amount',
        accessor: 'totalCost',
        className: columnClassName,
        Cell: instance => getCurrencyString(instance.value).slice(0, -3),
      },
      {
        Header: 'Created',
        accessor: 'created',
        className: columnClassName,
        Cell: instance => <>{`${formatIsoToDate(instance.value)}`}</>,
      },
      {
        Header: 'Expires',
        accessor: 'expires',
        className: columnClassName,
        Cell: instance => <>{`${formatIsoToDate(instance.value)}`}</>,
      },
    ],
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data: memoData,
      initialState: {
        sortBy: [
          {
            id: 'quoteId',
            desc: true,
          },
        ],
        sortType: 'number',
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setFilter,
    setGlobalFilter,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>{column.canSort ? getSortedIcon(column.isSorted, column.isSortedDesc) : null}</span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}>
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  function filter(event, columnName) {
    const filterColumn = columns.find(column => column.Header === columnName);
    if (filterColumn) {
      setFilter(filterColumn.accessor, event.target.value);
    } else {
      setGlobalFilter(event.target.value);
    }
  }

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col md={6} className="mr-auto">
          <TableFilter className="mb-3" columns={columns} onSearchChange={filter} />
        </Col>
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {loading && <Spinner animation="border" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default QuotesTable;
