import React, { useMemo } from 'react';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import './styles.scoped.css';
import { TablePagination } from '../../../components';
import { money } from '../../../utils';
import { sortBy } from 'lodash';
const columnClassName = 'align-middle text-nowrap';
export const workerCompensationColumnsSummary = [
  {
    Header: 'Company Number',
    accessor: 'COMP_NUMBER',
    className: columnClassName,
  },
  {
    Header: 'Company Name',
    accessor: 'COMP_NAME',
    className: columnClassName,
  },
  {
    Header: 'Branch #',
    accessor: 'Branch',
    className: columnClassName,
  },
  {
    Header: 'Branch Name',
    accessor: 'BranchName',
    className: columnClassName,
  },
  {
    Header: 'WC Code',
    accessor: 'WC_Code',
    className: columnClassName,
  },
  {
    Header: 'WC State',
    accessor: 'WC_State',
    className: columnClassName,
  },
  {
    Header: 'Reg Hours',
    accessor: 'Reg_Hours',
    className: columnClassName,
    Cell: instance => instance.value?.toFixed(2),
  },
  {
    Header: 'Reg Pay Amount',
    accessor: 'Reg_Pay_Amount',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Ovt Hours',
    accessor: 'Ovt_Hours',
    className: columnClassName,
    Cell: instance => instance.value?.toFixed(2),
  },
  {
    Header: 'Ovt Pay Amount',
    accessor: 'Ovt_Pay_Amount',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Dbl Hours',
    accessor: 'Dbl_Hours',
    className: columnClassName,
    Cell: instance => instance.value?.toFixed(2),
  },
  {
    Header: 'Dbl Pay Amount',
    accessor: 'Dbl_Pay_Amount',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Extra Pay Amount',
    accessor: 'Extra_Pay_Amount',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'WC Amount',
    accessor: 'WC_Amount',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Start Date',
    accessor: 'Start_Check_Date',
    className: columnClassName,
  },
  {
    Header: 'End Date',
    accessor: 'End_Check_Date',
    className: columnClassName,
  },
  {
    Header: 'Start PE Date',
    accessor: 'Start_Date_PE',
    className: columnClassName,
  },
  {
    Header: 'End PE Date',
    accessor: 'End_Date_PE',
    className: columnClassName,
  },
  {
    Header: 'Total Wages',
    accessor: 'Total_Wages',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Ovt Pay Amount Fac',
    accessor: 'Ovt_Pay_Amount_Fac',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Dbl Pay Amount Fac',
    accessor: 'Dbl_Pay_Amount_Fac',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
];

export const workerCompensationColumns = [
  {
    Header: 'Company Number',
    accessor: 'COMP_NUMBER',
    className: columnClassName,
  },
  {
    Header: 'Employee #',
    accessor: 'Employee_ID',
    className: columnClassName,
  },
  {
    Header: 'Employee Name',
    accessor: 'Employee_Name',
  },
  {
    Header: 'Company Name',
    accessor: 'COMP_NAME',
  },
  {
    Header: 'Last Name',
    accessor: 'LastName',
    className: columnClassName,
  },
  {
    Header: 'First Name',
    accessor: 'FirstName',
    className: columnClassName,
  },
  {
    Header: 'Pay Code',
    accessor: 'Pay_Code',
    className: columnClassName,
  },
  {
    Header: 'Pay Hours',
    accessor: 'Pay_Hours',
    className: columnClassName,
    Cell: instance => instance.value?.toFixed(2),
  },
  {
    Header: 'Pay Rate',
    accessor: 'Pay_Rate',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Pay Amount',
    accessor: 'Pay_Amount',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Pay Amount Calc',
    accessor: 'Pay_Amount_Calc',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Job ID',
    accessor: 'Job_Id',
    className: columnClassName,
  },
  {
    Header: 'Branch #',
    accessor: 'Branch',
    className: columnClassName,
  },
  {
    Header: 'WC Code',
    accessor: 'WC_Code',
    className: columnClassName,
  },
  {
    Header: 'WC State',
    accessor: 'WC_State',
    className: columnClassName,
  },
  {
    Header: 'WC Rate',
    accessor: 'WC_Rate',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'WC Rate Assign',
    accessor: 'WC_RateAssign',
    className: columnClassName,
  },
  {
    Header: 'WC Amount',
    accessor: 'WC_Amount',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Check Date',
    accessor: 'Check_Date',
    className: columnClassName,
  },
  {
    Header: 'Period Ending Date',
    accessor: 'Period_Ending_Date',
    className: columnClassName,
  },
  {
    Header: 'Batch Number',
    accessor: 'Batch_Number',
    className: columnClassName,
  },
  {
    Header: 'Client ID',
    accessor: 'Client_ID',
    className: columnClassName,
  },
  {
    Header: 'Client Name',
    accessor: 'Client_Name',
    className: columnClassName,
  },
  {
    Header: 'Customer BillTo Number',
    accessor: 'Customer_BillToNumber',
    className: columnClassName,
  },
  {
    Header: 'Customer BillTo Company',
    accessor: 'Customer_BillToCompany',
    className: columnClassName,
  },
  {
    Header: 'Job Position',
    accessor: 'Job_Position',
    className: columnClassName,
  },
  {
    Header: 'Workers Comp',
    accessor: 'WorkersComp',
    className: columnClassName,
  },
  {
    Header: 'Reg Hours',
    accessor: 'Reg_Hours',
    className: columnClassName,
    Cell: instance => instance.value?.toFixed(2),
  },
  {
    Header: 'Reg Pay Amount',
    accessor: 'Reg_Pay_Amount',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Ovt Hours',
    accessor: 'Ovt_Hours',
    className: columnClassName,
    Cell: instance => instance.value?.toFixed(2),
  },
  {
    Header: 'Ovt Pay Amount',
    accessor: 'Ovt_Pay_Amount',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Dbl Hours',
    accessor: 'Dbl_Hours',
    className: columnClassName,
    Cell: instance => instance.value?.toFixed(2),
  },
  {
    Header: 'Dbl Pay Amount',
    accessor: 'Dbl_Pay_Amount',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Extra Pay Amount',
    accessor: 'Extra_Pay_Amount',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'No Office Break',
    accessor: 'NoOfficeBreak',
    className: columnClassName,
  },
  {
    Header: 'State Name',
    accessor: 'StateName',
    className: columnClassName,
  },
  {
    Header: 'Branch Name',
    accessor: 'BranchName',
    className: columnClassName,
  },
  {
    Header: 'Start Check Date',
    accessor: 'Start_Check_Date',
    className: columnClassName,
  },
  {
    Header: 'End Check Date',
    accessor: 'End_Check_Date',
    className: columnClassName,
  },
  {
    Header: 'Start PE Date',
    accessor: 'Start_Date_PE',
    className: columnClassName,
  },
  {
    Header: 'End PE Date',
    accessor: 'End_Date_PE',
    className: columnClassName,
  },
  {
    Header: 'OT Comp Wages',
    accessor: 'Ot_Comp_Wages',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'DT Comp Wages',
    accessor: 'Dt_Comp_Wages',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'State Code',
    accessor: 'StateCode',
    className: columnClassName,
  },
  {
    Header: 'Manual Pay',
    accessor: 'Manual_Payroll',
    className: columnClassName,
    Cell: instance => (instance.value ? '1' : '0'),
  },
];

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const WorkerCompensationTable = ({ data, loading, summaryOrDetail }) => {

  const workerCompColumnsToUse = useMemo(
    () =>
      summaryOrDetail === 'Summary'
        ? workerCompensationColumnsSummary
        : workerCompensationColumns,
    [summaryOrDetail],
  );


  const tableInstance = useTable(
    {
      columns: workerCompColumnsToUse,
      data: data,
      initialState: {
        pageSize: 200,
        sortBy: [
          {
            id: 'CreatedAt',
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>
          {column.canSort
            ? getSortedIcon(column.isSorted, column.isSortedDesc)
            : null}
        </span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}
      >
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading && <Spinner animation="border" />}
          </div>
        </Col>
      </Row>
    </Container>

  );
};

export default WorkerCompensationTable;
