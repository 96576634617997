import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { Form, Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SalesTaxForm } from './Form';
import SalesTaxTable, { salesTaxColumns } from './SalesTaxTable';
import Excel from '../../../assets/excel.png';
import { Breadcrumbs, EdgeReportingWindow, SubHeader, ReportLoader } from '../../../components';
import { newDateFormatted } from '../../../utils';
import getCSVData from '../csv';
import { generateReport, getList, getReportData, removeReportData, userHasAccess } from '../operations';

import './styles.scoped.css';

const SalesTax = () => {
  const navigate = useNavigate();
  const {
    clientMenu: { menuItems },
  } = useSelector(state => state.edgeReporting);
  const { user } = useSelector(state => state.admin);
  const [data, setData] = useState([]);
  const [taxCodes, setTaxCodes] = useState([]);
  const [form, setForm] = useState({});
  const [loadingResults, setLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isLoadingTaxCodes, setIsLoadingTaxCodes] = useState(false);

  useEffect(() => {
    if (menuItems) {
      // eslint-disable-next-line no-undef
      if (!userHasAccess(user, menuItems.find(i => i.to === window.location.pathname)?.id)) navigate('/');
    }
  }, [menuItems]);

  const handleGetTaxCodes = async () => {
    setIsLoadingTaxCodes(true);
    const tc = await getList('tax-code');
    setTaxCodes(tc);
    setIsLoadingTaxCodes(false);
  };

  useEffect(() => {
    handleGetTaxCodes();
  }, []);

  const taxCodeOptions = useMemo(
    () =>
      taxCodes?.map(({ TaxCode, TaxCodeDescription }) => ({
        label: TaxCodeDescription,
        value: TaxCode,
      })),
    [taxCodes],
  );

  const fileName = `edge-reporting-sales-tax-${newDateFormatted.toString()}.csv`;

  const handleViewResults = async () => {
    if (
      !(
        dayjs(form?.ToPerEnddate).isAfter(form?.FromPerEnddate) ||
        dayjs(form?.FromPerEnddate).isSame(form?.ToPerEnddate)
      )
    ) {
      // eslint-disable-next-line no-undef
      alert('Invalid date range. Please double check and try again.');
      return;
    }
    setLoadingResults(true);
    try {
      const response = await generateReport({
        endpoint: 'sales-tax-report',
        payload: form,
      });
      if (response.location && response.location !== '') {
        const reportData = await getReportData(response.location);
        if (reportData?.length) {
          await removeReportData(response.location);
          setLoadingResults(false);
          setData(reportData);
          setShowResults(true);
        }
      } else {
        setLoadingResults(false);
        alert('No data found matching your criteria.');
      }
    } catch (error) {
      alert('No data found matching your criteria.');
      setLoadingResults(false);
    }
    setLoadingResults(false);
  };

  const SalesTaxReportForm = ({ form, setForm, isLoadingTaxCodes, taxCodeOptions }) => {
    const handleChange = e => {
      const _form = { ...form, [e.target.name]: e.target.value };
      setForm(_form);
    };
    return (
      <Form
        noValidate
        onSubmit={() => { }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <SalesTaxForm
          form={form}
          setForm={setForm}
          handleChange={handleChange}
          isLoadingTaxCodes={isLoadingTaxCodes}
          taxCodeOptions={taxCodeOptions}
        />

        <div className="buttons-row">
          <Button
            disabled={!form?.ToPerEnddate || !form?.FromPerEnddate}
            onClick={() => handleViewResults()}
            style={{ marginRight: '20px', width: '100px' }}>
            <span>VIEW</span>
          </Button>

          <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
            <span>CANCEL</span>
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <>
      <SubHeader
        text="EDGE Reporting"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Sales Tax report?"
        emailAddress={process.env.REACT_APP_EDGE_REPORTING_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle="Reporting Home"
          middleTitle={showResults && 'Sales Tax Report'}
          midItemAction={() => setShowResults(false)}
          rightTitle={showResults ? 'Viewing Report' : 'Sales Tax Report'}
          route="/edge-reporting"
        />
        {!!data?.length && showResults && (
          <CSVLink filename={fileName} data={getCSVData(data, salesTaxColumns)}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
        )}
      </div>
      {loadingResults && <ReportLoader didAbort={() => setLoadingResults(false)} />}
      {showResults ? (
        <>
          <SalesTaxTable data={data} loading={loadingResults} />
          <div className="buttons-row">
            <Button onClick={() => setShowResults(false)} style={{ marginRight: '20px', width: '100px' }}>
              <span>BACK</span>
            </Button>

            <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
              <span>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <EdgeReportingWindow
          containerStyle={{
            border: '2px solid #343a40',
          }}
          windowContent={SalesTaxReportForm({
            form,
            setForm,
            isLoadingTaxCodes,
            taxCodeOptions,
          })}
          windowTitle="Criteria"
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )}
    </>
  );
};

export default SalesTax;
