/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SubHeader, Footer } from '../../components';
import { MdWeb } from 'react-icons/md';
import { Container, Row } from 'react-bootstrap';
import './styles.scoped.css';
import EdgeMenuItem from '../../components/EdgeMenuItem';

const EdgeReporting = () => {
  const navigate = useNavigate();
  const { clientMenu, subHeader } = useSelector(state => state.edgeReporting);
  const { user } = useSelector(state => state.admin);

  const onPress = item => {
    navigate(item.to);
  };

  const getsReport = id => {
    const myEnv = { ...process.env };
    const edgeAppAccessItems = user?.appAccess?.items;
    const role = edgeAppAccessItems?.find(app => app?.appId?.id === '4')?.roleId
      ?.id;
    const key = `REACT_APP_EDGE_REPORTING_ROLE_${role}`;
    const reportString = myEnv[key];
    const reportArray = reportString?.split(',');
    return reportArray?.includes(String(id));
  };

  return (
    <>
      <SubHeader
        text="EDGE Reporting"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo={true}
        infoText="Need help with your Expenses?"
        emailAddress={process.env.REACT_APP_EXPENSE_CONTACT_EMAIL}
      />
      <Container className="wrap">
        <h1 className="header">{subHeader}</h1>
        <div key={clientMenu.menuHeader} style={{ width: '100%' }}>
          <Row
            className="row"
            style={{ width: '100%' }}
            key={`${clientMenu.menuHeader}-row`}
          >
            {clientMenu.menuItems
              .filter(i => getsReport(i.id))
              .sort((a, b) => a.text.localeCompare(b.text))
              .map(i => (
                < EdgeMenuItem
                  key={i.text}
                  text={i.text}
                  onPress={() => onPress(i)}
                  disabled={i.disabled}
                />
              ))}
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default EdgeReporting;
