import React, { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { Checkbox } from '@mui/material';

import './styles.scoped.css';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';
import { useState } from 'react';
import { TablePagination } from '../../../components';

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const DeductionRegisterTable = ({ data, loading }) => {
  const dispatch = useDispatch();
  const columnClassName = 'align-middle text-nowrap';
  const memoData = useMemo(() => data, [data]);

  const deductionRegisterColumns = useMemo(
    () => [
      {
        Header: 'Driver Number',
        accessor: 'Employee_ID',
        className: columnClassName,
      },
      {
        Header: 'Driver Name',
        accessor: 'Empl_Name',
        className: columnClassName,
      },
      {
        Header: 'Code',
        accessor: 'Transaction_Code',
        className: columnClassName,
      },
      {
        Header: 'Description',
        accessor: 'Transaction_Descirption',
        className: columnClassName,
      },
      {
        Header: 'Type',
        accessor: 'Transaction_Type',
        className: columnClassName,
      },
      {
        Header: 'Driver Amount',
        accessor: 'Transaction_Amount',
        className: columnClassName,
      },
      {
        Header: 'Employer Amount',
        accessor: 'Ded_Amount_Employer',
        className: columnClassName,
      },
      {
        Header: 'Ded%',
        accessor: 'Deduction_Percent',
        className: columnClassName,
      },
      {
        Header: 'Check#',
        accessor: 'check_ID',
        className: columnClassName,
      },
      {
        Header: 'Check Date',
        accessor: 'check_Date',
        className: columnClassName,
      },
      {
        Header: 'Period End Date',
        accessor: 'End_Date_PE',
        className: columnClassName,
      },
      {
        Header: 'Batch #',
        accessor: 'Batch_Number',
        className: columnClassName,
      },
      {
        Header: 'Branch',
        accessor: 'Branch',
        className: columnClassName,
      },
    ],
    [memoData],
  );

  const tableInstance = useTable(
    {
      columns: deductionRegisterColumns,
      data: memoData,
      initialState: {
        pageSize: 200,
        sortBy: [
          {
            id: 'CreatedAt',
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>
          {column.canSort
            ? getSortedIcon(column.isSorted, column.isSortedDesc)
            : null}
        </span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}
      >
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading && <Spinner animation="border" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DeductionRegisterTable;
