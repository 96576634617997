import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { Form, Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import FourCeroOneTable, { fourCeroOneColumns } from './401KTable';
import { FourCeroOneKForm } from './Form';
import Excel from '../../../assets/excel.png';
import { Breadcrumbs, EdgeReportingWindow, SubHeader, ReportLoader } from '../../../components';
import { formatIsoToDate, newDateFormatted } from '../../../utils';
import getCSVData from '../csv';
import { userHasAccess, generateReport, getReportData, removeReportData, getList } from '../operations';

import './styles.scoped.css';

const FourCeroOneKWindow = ({ form, setForm, setFormError, formError, handleViewResults, navigate }) => {
  const handleChange = e => {
    if (dayjs(e.target.value).day() !== 1) {
      setFormError('Date must be a Monday');
    } else {
      setFormError('');
    }
    const _form = { ...form, [e.target.name]: e.target.value };
    setForm(_form);
  };
  return (
    <Form
      noValidate
      onSubmit={() => { }}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <FourCeroOneKForm form={form} handleChange={handleChange} />
      <p className="form-error">{formError || ' '}</p>
      <div className="buttons-row">
        <Button
          disabled={!!formError}
          onClick={() => handleViewResults()}
          style={{ marginRight: '20px', width: '100px' }}>
          <span>VIEW</span>
        </Button>
        <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
          <span>CANCEL</span>
        </Button>
      </div>
    </Form>
  );
};

const getPreviousMonday = () => {
  const date = new Date();
  const day = date.getDay();
  const prevMonday = new Date();
  if (date.getDay() === 0) {
    prevMonday.setDate(date.getDate() - 6);
  } else {
    prevMonday.setDate(date.getDate() - (day - 1));
  }
  return prevMonday;
};

const FourCeroOneK = () => {
  const {
    clientMenu: { menuItems },
  } = useSelector(state => state.edgeReporting);
  const { user } = useSelector(state => state.admin);
  const navigate = useNavigate();
  const now = new Date();
  now.setDate(now.getDate() - ((now.getDay() + 6) % 7));
  const [data, setData] = useState([]);
  const [form, setForm] = useState({
    MondayDate: formatIsoToDate(getPreviousMonday(), 'YYYY-MM-DD'),
  });
  const [formError, setFormError] = useState('');
  const [loadingResults, setLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    if (menuItems) {
      // eslint-disable-next-line no-undef
      if (!userHasAccess(user, menuItems.find(i => i.to === window.location.pathname)?.id)) navigate('/');
    }
  }, [menuItems]);

  const fileName = `edge-reporting-401k-report-${newDateFormatted.toString()}.csv`;

  const handleViewResults = () => {
    setLoadingResults(true);
    Promise.all([
      generateReport({
        endpoint: 'retirement-report',
        payload: {
          currentRunDate: form?.MondayDate ? formatIsoToDate(form?.MondayDate, 'YYYY-MM-DD') : '',
        }
      })
    ])
      .then(values => {
        values.forEach((response, index) => {
          if (response.location) {
            getReportData(response.location).then(async reportData => {
              if (reportData?.length) {
                await removeReportData(response.location);
                if (index === 0) {
                  setData(reportData);
                } else {
                  setDataExport(reportData);
                }
                setShowResults(true);
              }
            });
          } else if (response.statusCode !== 404) {
            if (index === 0) {
              setData(response);
            } else {
              setDataExport(response);
            }
            setShowResults(true);
          } else {
            // eslint-disable-next-line no-undef
            alert('No data found matching your criteria.');
          }
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoadingResults(false);
      });
  }


  return (
    <>
      <SubHeader
        text="EDGE Reporting"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your 401K Report?"
        emailAddress={process.env.REACT_APP_EDGE_REPORTING_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle="Reporting Home"
          middleTitle={showResults && '401K Report'}
          midItemAction={() => setShowResults(false)}
          rightTitle={showResults ? 'Viewing Report' : '401K Report'}
          route="/edge-reporting"
        />
        {!!data?.length && showResults && (
          <CSVLink filename={fileName} data={getCSVData(data, fourCeroOneColumns)}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
        )}
      </div>
      {loadingResults && <ReportLoader didAbort={() => setLoadingResults(false)} />}
      {showResults ? (
        <>
          <FourCeroOneTable data={data} loading={loadingResults} />
          <div className="buttons-row">
            <Button onClick={() => setShowResults(false)} style={{ marginRight: '20px', width: '100px' }}>
              <span>BACK</span>
            </Button>

            <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
              <span>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <EdgeReportingWindow
          containerStyle={{
            border: '2px solid #343a40',
            minHeight: 200,
          }}
          windowContent={FourCeroOneKWindow({ form, setForm, setFormError, formError, handleViewResults, navigate })}
          windowTitle="Criteria"
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )}
    </>
  );
};

export default FourCeroOneK;
