import React, { useEffect } from 'react';

import { Container, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setApps, setSelectedRoleId } from './dashboardSlice';
import { getApps } from './operations';
import { MenuItem, SubHeader, Footer } from '../../components';
import './styles.scoped.css';
import useIsMobileScreen from '../../hooks/useCheckIsMobile';

const Dashboard = () => {
  const data = useSelector(state => state.dashboard);
  const { user } = useSelector(state => state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobileScreen();

  const onPress = item => {
    let to = '';
    switch (item.appId.id) {
      case '1':
        to = '/quote-engine';
        break;
      case '2':
        to = '/driver-qualification';
        break;
      case '3':
        to = '/industry-insights';
        break;
      case '4':
        to = '/edge-reporting';
        break;
      case '5':
        to = '/admin-portal';
        break;
      case '6':
        to = '/dynamic-pricing';
        break;
      case '7':
        to = '/expense-entry';
        break;
      case '8':
        to = '/push-notifications';
        break;
      default:
        break;
    }
    const roleId = user.appAccess.items.find(aAItem => aAItem.appId.id === item.appId.id)?.roleId?.id;
    if (roleId) {
      dispatch(setSelectedRoleId(roleId));
    }
    navigate(to, { item });
  };

  const appsList = async () => {
    const list = await getApps(user.id);
    dispatch(setApps(list));
    dispatch(setSelectedRoleId(null));
  };

  useEffect(() => {
    if (user) appsList();
  }, [user]);

  return (
    <>
      <SubHeader
        text={data.subHeader}
        btnInfo
        infoText="Contact Us:"
        emailAddress={process.env.REACT_APP_PORTAL_CONTACT_EMAIL}
      />
      <Container className="wrap">
        <h1 className="title">What would you like to do?</h1>
        <Row
          className={isMobile ? 'mobile-row' : 'row'}
          style={{
            height: (data.applicationList?.length / 2) * 120 || 0,
            width: isMobile ? '100%' : '740px',
          }}>
          {data.applicationList?.map(item => (
            <MenuItem key={item.id} text={item.appId.name} icon={item.appId.id} onPress={() => onPress(item)} />
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Dashboard;
