import React, { useCallback, useState } from 'react';

import { Checkbox } from '@mui/material';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { Row, Form, InputGroup, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Receipt from './Receipt';
import ExpenseForm from '../../../../components/ExpenseForm';
import './styles.scoped.css';
import { newDateFormatted } from '../../../../utils';
import { checkClientFlatFee } from '../operations';
import { preventMultipleSave } from '../TransferToUltraStaff/utils';

const FormContent = ({
  id,
  hasChanges,
  setShowCancelDialog,
  setReceipts,
  receipts,
  fieldsAreValid,
  form,
  confirmCreateExpense,
  receiptsHaveFiles,
  setShowReceiptFilesDialog,
  setShowFFDialog,
  setShowNonFFDialog,
  errors,
  formHasChanged,
  totalToBeBilled,
  auditKey,
  isCreatingItems,
  isLoading,
  carRentals,
  currentUser,
  receiptsErrors,
  setReceiptsErrors,
  setUploading,
  setUploadError,
  isCR,
  setHasChanges,
  setForm,
  setErrors,
  deleteExpenseFile,
  assignmentLineOfBusinessItems,
}) => {
  const navigate = useNavigate();

  const [saveDisabled, setSaveDisabled] = useState(false);

  const handleCancel = async () => (hasChanges ? setShowCancelDialog(true) : navigate(assignmentLineOfBusinessItems.to));

  const handleAddReceipt = () => {
    setReceipts([...receipts, { markup: '0' }]);
  };

  const handleCreateExpense = async () => {
    preventMultipleSave(setSaveDisabled);
    if (fieldsAreValid()) {
      if (form?.status === 'Non-billable') {
        await confirmCreateExpense({ route: '' });
      } else if (receiptsHaveFiles()) {
        await handleVerifyConfirm();
      } else {
        setShowReceiptFilesDialog(true);
      }
    }
  };

  const handleVerifyConfirm = async () => {
    const clientIsFF = await checkClientFlatFee(form?.customerId);
    if (clientIsFF && form?.flatFeeBilling) {
      await confirmCreateExpense({ isFF: true });
    }

    if (clientIsFF && !form?.flatFeeBilling) {
      setShowFFDialog(true);
    }
    if (!clientIsFF && form?.flatFeeBilling) {
      setShowNonFFDialog(true);
    }
    if (!clientIsFF && !form?.flatFeeBilling) {
      await confirmCreateExpense({
        route: assignmentLineOfBusinessItems.to,
      });
    }
  };

  const customStyles = width => ({
    control: provided => ({
      ...provided,
      width: width,
      height: '30px',
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: '200px',
    }),
  });

  const setField = (fields, value) => {
    if (!hasChanges) {
      setHasChanges(true);
    }
    if (Array.isArray(fields)) {
      const itemsToAdd = {};
      const newErrors = { ...errors };
      fields.forEach(field => {
        itemsToAdd[(field?.fieldName)] = field.fieldValue;

        if (errors && !!errors[field.fieldName]) {
          delete newErrors[(field?.fieldName)];
        }
      });
      setForm({
        ...form,
        ...itemsToAdd,
      });
      setErrors({ ...newErrors });
    } else {
      if (fields === 'nonBillable' && value === true) {
        setForm({
          ...form,
          [fields]: value,
          status: 'Non-billable',
        });
      } else if (fields === 'nonBillable' && value === false) {
        setForm({
          ...form,
          [fields]: value,
          status: 'Unbilled',
        });
      } else if (fields === 'flatFeeBilling' && value === false) {
        setForm({
          ...form,
          [fields]: value,
          nonBillable: false,
          status: 'Unbilled',
          code: 'XC',
        });
      } else if (fields === 'flatFeeBilling' && value === true) {
        setForm({
          ...form,
          [fields]: value,
          nonBillable: true,
          status: 'Flat Fee Billing',
          code: 'XFFBC',
        });
      } else {
        setForm(prevState => ({
          ...prevState,
          [fields]: value,
        }));
      }

      if (errors && !!errors[fields]) {
        const errorsCopy = {
          ...errors,
        };
        delete errorsCopy[fields];
        setErrors(errorsCopy);
      }
    }
  };

  const getTotalProjectedAmount = newReceipts => {
    const projectedAmounts = newReceipts.reduce((result, item) => {
      if (item?.projectedAmount) {
        result += item.projectedAmount;
      }
      return result;
    }, 0);
    setField('totalProjectedAmount', parseFloat(projectedAmounts).toFixed(2));
  };

  const setDebouncedValue = debounce(r => {
    setReceiptField(r.id, r.field, r.value, r.receipts);
  }, 2000);

  const debouncedSetReceiptField = useCallback(value => setDebouncedValue(value), []);

  const carRentalOptions = carRentals
    ?.filter(c => c.status === 'Active')
    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(({ name, id: scopedId }) => ({
      label: name,
      value: scopedId,
    }));

  const setReceiptFile = async ({ id: scopedId, fileName, fileSize }) => {
    const receiptsCopy = [...receipts];
    receiptsCopy[scopedId].uploadedDate = new Date().toISOString();
    receiptsCopy[scopedId].documentName = fileName;
    receiptsCopy[scopedId].documentSize = Math.round(fileSize);
    receiptsCopy[scopedId].uploadedByName = currentUser?.name;

    const receiptsErrorsCopy = [...receiptsErrors];

    if (receiptsErrorsCopy[scopedId]?.uploadedDate) delete receiptsErrorsCopy[scopedId].uploadedDate;

    if (receiptsErrorsCopy[scopedId]?.documentName) delete receiptsErrorsCopy[scopedId].documentName;

    if (receiptsErrorsCopy[scopedId]?.uploadedByName) delete receiptsErrorsCopy[scopedId].uploadedByName;

    if (receiptsErrorsCopy.length) {
      receiptsErrorsCopy.splice(scopedId, 1);
    }

    setReceiptsErrors(receiptsErrorsCopy);
    setReceipts(receiptsCopy);
  };

  const handleDeleteRow = async scopedId => {
    if (receipts[scopedId].documentName) await deleteExpenseFile(receipts[scopedId].documentName);
    const receiptsCopy = receipts.filter((r, index) => index !== scopedId);
    setReceipts(receiptsCopy);
    getTotalProjectedAmount(receipts);
  };

  const setReceiptField = (scopedId, field, value, scopedReceipts) => {
    const receiptsCopy = [...scopedReceipts];
    if (field === 'receiptAmount') {
      if (receiptsCopy[scopedId]?.markup) {
        const multiplier = (1 + parseFloat(receiptsCopy[scopedId]?.markup).toFixed(2) / 100).toFixed(2);

        receiptsCopy[scopedId].expenseTotal = parseFloat(value) * multiplier;
      } else {
        receiptsCopy[scopedId].expenseTotal = value;
      }
    }
    if (field === 'markup' && receiptsCopy[scopedId]?.receiptAmount) {
      const multiplier = (1 + parseFloat(value).toFixed(2) / 100).toFixed(2);

      receiptsCopy[scopedId].expenseTotal = parseFloat(receiptsCopy[scopedId]?.receiptAmount) * multiplier;
    }

    if (
      (field === 'carRentalPickUpDate' && receiptsCopy[scopedId]?.carRentalReturnDate) ||
      (field === 'carRentalReturnDate' && receiptsCopy[scopedId]?.carRentalPickUpDate)
    ) {
      let difference;
      if (field === 'carRentalPickUpDate') {
        difference = dayjs(receiptsCopy[scopedId]?.carRentalReturnDate)?.diff(value, 'day') || 0;
      }
      if (field === 'carRentalReturnDate') {
        difference = dayjs(value)?.diff(receiptsCopy[scopedId]?.carRentalPickUpDate, 'day') || 0;
      }
      if (difference && receiptsCopy[scopedId]?.dailyCharge) {
        receiptsCopy[scopedId].projectedAmount = difference * receiptsCopy[scopedId].dailyCharge;
        getTotalProjectedAmount(receiptsCopy);
      }
      receiptsCopy[scopedId][field] = value;
      receiptsCopy[scopedId].projectedDays = difference;
      setReceipts(receiptsCopy);
    } else {
      if (field === 'dailyCharge' && receiptsCopy[scopedId].projectedDays) {
        receiptsCopy[scopedId].projectedAmount = receiptsCopy[scopedId].projectedDays * value;
        getTotalProjectedAmount(receiptsCopy);
      }
      receiptsCopy[scopedId][field] = value;
      setReceipts(receiptsCopy);
    }
  };

  const deleteDocumentFromReceipt = async scopedId => {
    const receiptsCopy = [...receipts];

    await deleteExpenseFile(receiptsCopy[scopedId].documentName);

    if (receiptsCopy[scopedId]?.uploadedDate) delete receiptsCopy[scopedId].uploadedDate;

    if (receiptsCopy[scopedId]?.documentName) delete receiptsCopy[scopedId].documentName;

    if (receiptsCopy[scopedId]?.uploadedByName) delete receiptsCopy[scopedId].uploadedByName;

    setReceipts(receiptsCopy);
  };

  const setReceiptFields = (scopedId, fields) => {
    const receiptsCopy = [...receipts];

    fields.forEach(field => {
      receiptsCopy[scopedId][(field?.fieldName)] = field.fieldValue;
    });
    setReceipts(receiptsCopy);
  };

  return (
    <Form noValidate onSubmit={() => { }}>
      <Row
        style={{
          margin: '16px 0 0 0',
        }}
        className="top-row">
        <div style={{ width: '40%' }} className="top-col">
          <h1 className="title">Car Expense</h1>
        </div>
        <div className="top-col">
          <h1 className="label">Code</h1> <h1 className="top-value">{form?.code}</h1>{' '}
        </div>
        <div className="top-col">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '225px',
            }}>
            <h1 className="label">Date</h1>
            <h1 className="top-value">{newDateFormatted}</h1>
          </div>
          {(form?.status === 'Unbilled' ||
            form.status === 'Non-billable' ||
            form.status === 'Ready to Bill' ||
            form.status === 'Flat Fee Billing') && (
              <Form.Group
                controlId="flatFeeBilling"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginTop: '25px',
                }}>
                <Form.Label>Flat Fee Billing</Form.Label>
                <Checkbox
                  style={{
                    color: 'red',
                    height: 0,
                    width: 0,
                    marginTop: '5px',
                  }}
                  onChange={event => {
                    setField('flatFeeBilling', event.target.checked);
                  }}
                  checked={!!form?.flatFeeBilling}
                  defaultValue={false}
                />
              </Form.Group>
            )}
        </div>
      </Row>
      <ExpenseForm
        customStyles={customStyles}
        setField={setField}
        errors={errors}
        form={form}
        formHasChanged={formHasChanged}
        setForm={setForm}
      />
      <div className="gray-wrapper">
        {receipts?.map((receipt, index) => (
          <Receipt
            receipt={receipt}
            id={index}
            key={receipt.id}
            carRentals={carRentals}
            receipts={receipts}
            currentUser={currentUser}
            receiptsErrors={receiptsErrors}
            setReceiptsErrors={setReceiptsErrors}
            setReceipts={setReceipts}
            setField={setField}
            customStyles={customStyles}
            setUploading={setUploading}
            setUploadError={setUploadError}
            form={form}
            isCR={isCR}
            handleDeleteRow={handleDeleteRow}
            carRentalOptions={carRentalOptions}
            setReceiptFields={setReceiptFields}
            setReceiptField={setReceiptField}
            debouncedSetReceiptField={debouncedSetReceiptField}
            setReceiptFile={setReceiptFile}
            deleteDocumentFromReceipt={deleteDocumentFromReceipt}
            expenseId={id}
          />
        ))}
        <Row
          className="top-row"
          style={{
            alignItems: 'flex-start',
          }}>
          {/* remove button temporary  */}
          {/* <div onClick={handleAddReceipt} className="another-receipt">
            Add Another Receipt
          </div> */}
          <div className='another-receipt'></div>
          <div
            className="top-col"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}>
            <Form.Group controlId="startDate">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Start Date
              </Form.Label>
              <Form.Control
                style={{
                  width: '150px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="startDate"
                value={form?.startDate?.substring(0, 10)}
                readOnly
                disabled
              />
            </Form.Group>
            <Form.Group controlId="endDate">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                End Date
              </Form.Label>
              <Form.Control
                style={{
                  width: '150px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="endDate"
                value={form?.endDate?.substring(0, 10)}
                readOnly
                disabled
              />
            </Form.Group>
          </div>
          <Form.Group controlId="totalToBeBilled">
            <Form.Label
              style={{
                marginTop: '15px',
              }}>
              Total To Be Billed
            </Form.Label>
            <InputGroup
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '150px',
                opacity: '0.7',
              }}>
              <span
                style={{
                  position: 'absolute',
                  left: '10px',
                  zIndex: 10000,
                }}>
                $
              </span>
              <Form.Control
                style={{
                  width: '225px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  marginLeft: '20px',
                }}
                as="input"
                name="location"
                value={totalToBeBilled}
                readOnly
                disabled
              />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="totalProjectedAmount">
            <Form.Label
              style={{
                marginTop: '15px',
              }}>
              Total Projected Amount
            </Form.Label>
            <InputGroup
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '150px',
                opacity: '0.7',
              }}>
              <span
                style={{
                  position: 'absolute',
                  left: '10px',
                  zIndex: 10000,
                }}>
                $
              </span>
              <Form.Control
                style={{
                  width: '225px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  marginLeft: '20px',
                }}
                as="input"
                name="location"
                readOnly
                disabled
                value={
                  form?.totalProjectedAmount
                    ? parseFloat(form?.totalProjectedAmount).toFixed(2)
                    : parseFloat(0).toFixed(2)
                }
              />
            </InputGroup>
          </Form.Group>
        </Row>
        <Row
          className="top-row"
          style={{
            margin: '5px 0 0 0',
            alignItems: 'flex-start',
          }}>
          <div className="top-col" style={{ width: '70%' }}>
            <Form.Group controlId="notes">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Notes
              </Form.Label>
              <Form.Control
                style={{
                  width: '533px',
                  height: '75px',
                }}
                as="textarea"
                name="notes"
                defaultValue={form?.notes}
                onBlur={event => {
                  setField('notes', event.target.value);
                }}
              />
            </Form.Group>
          </div>
          <div
            className="top-col"
            style={{
              alignItems: 'flex-start',
            }}>
            <Form.Group controlId="auditKey">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Audit Key
              </Form.Label>
              <Form.Control
                style={{
                  width: '200px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="location"
                value={auditKey}
                readOnly
                disabled
              />
            </Form.Group>
          </div>
        </Row>
      </div>
      {(isCreatingItems || isLoading) && (
        <div className="overlay-box">
          <Spinner className="spinner" animation="border" />
        </div>
      )}

      <Row
        className="top-row"
        style={{
          margin: '50px 0 0 0',
          justifyContent: 'center',
        }}>
        <Button
          disabled={saveDisabled}
          className="button-text"
          style={{
            width: '140px',
            marginRight: '20px',
          }}
          onClick={handleCreateExpense}>
          <span>SAVE</span>
        </Button>
        <Button onClick={handleCancel} className="button-secondary" style={{ width: '140px' }}>
          <span>CANCEL</span>
        </Button>
      </Row>
    </Form>
  );
};

export default FormContent;
