/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const publishReportResult = /* GraphQL */ `
  mutation PublishReportResult($result: ResultInput) {
    publishReportResult(result: $result) {
      id
      status
      statusCode
      statusDescription
      location
      __typename
    }
  }
`;
export const generateTatCertificate = /* GraphQL */ `
  mutation GenerateTatCertificate($id: String, $driverName: String) {
    generateTatCertificate(id: $id, driverName: $driverName)
  }
`;
export const publishPushNotification = /* GraphQL */ `
  mutation PublishPushNotification(
    $type: String
    $subject: String
    $message: String
    $url: String
    $status: String
    $lob: String
    $branch: String
    $recipientStatus: String
    $approvedBy: String
    $approvedAt: AWSDateTime
    $sentOn: AWSDateTime
    $createdAt: AWSDateTime
    $createdBy: String
  ) {
    publishPushNotification(
      type: $type
      subject: $subject
      message: $message
      url: $url
      status: $status
      lob: $lob
      branch: $branch
      recipientStatus: $recipientStatus
      approvedBy: $approvedBy
      approvedAt: $approvedAt
      sentOn: $sentOn
      createdAt: $createdAt
      createdBy: $createdBy
    ) {
      responseCode
      responseMessage
      driverCount
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      customerId
      status
      type
      lastLogin
      clientName
      updateDriverQualifications
      StandardDocuments {
        items {
          id
          for
          forName
          category
          type
          customerId
          driverId
          documentName
          accountMasterCode
          createdAt
          updatedAt
          userStandardDocumentsId
          __typename
        }
        nextToken
        __typename
      }
      Audit {
        items {
          id
          event
          eventTime
          objectName
          objectId
          objectData
          createdAt
          updatedAt
          userAuditId
          __typename
        }
        nextToken
        __typename
      }
      appAccess {
        items {
          id
          createdAt
          updatedAt
          userAppAccessId
          applicationUserAppAccessId
          applicationRoleUserAppAccessId
          __typename
        }
        nextToken
        __typename
      }
      cellPhone
      passwordChangeDate
      preferredMFA
      lastResetMFA
      customers {
        items {
          id
          customerId
          active
          createdAt
          updatedAt
          userCustomersId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      customerId
      status
      type
      lastLogin
      clientName
      updateDriverQualifications
      StandardDocuments {
        items {
          id
          for
          forName
          category
          type
          customerId
          driverId
          documentName
          accountMasterCode
          createdAt
          updatedAt
          userStandardDocumentsId
          __typename
        }
        nextToken
        __typename
      }
      Audit {
        items {
          id
          event
          eventTime
          objectName
          objectId
          objectData
          createdAt
          updatedAt
          userAuditId
          __typename
        }
        nextToken
        __typename
      }
      appAccess {
        items {
          id
          createdAt
          updatedAt
          userAppAccessId
          applicationUserAppAccessId
          applicationRoleUserAppAccessId
          __typename
        }
        nextToken
        __typename
      }
      cellPhone
      passwordChangeDate
      preferredMFA
      lastResetMFA
      customers {
        items {
          id
          customerId
          active
          createdAt
          updatedAt
          userCustomersId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      customerId
      status
      type
      lastLogin
      clientName
      updateDriverQualifications
      StandardDocuments {
        items {
          id
          for
          forName
          category
          type
          customerId
          driverId
          documentName
          accountMasterCode
          createdAt
          updatedAt
          userStandardDocumentsId
          __typename
        }
        nextToken
        __typename
      }
      Audit {
        items {
          id
          event
          eventTime
          objectName
          objectId
          objectData
          createdAt
          updatedAt
          userAuditId
          __typename
        }
        nextToken
        __typename
      }
      appAccess {
        items {
          id
          createdAt
          updatedAt
          userAppAccessId
          applicationUserAppAccessId
          applicationRoleUserAppAccessId
          __typename
        }
        nextToken
        __typename
      }
      cellPhone
      passwordChangeDate
      preferredMFA
      lastResetMFA
      customers {
        items {
          id
          customerId
          active
          createdAt
          updatedAt
          userCustomersId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserCustomer = /* GraphQL */ `
  mutation CreateUserCustomer(
    $input: CreateUserCustomerInput!
    $condition: ModelUserCustomerConditionInput
  ) {
    createUserCustomer(input: $input, condition: $condition) {
      id
      customerId
      active
      createdAt
      updatedAt
      userCustomersId
      __typename
    }
  }
`;
export const updateUserCustomer = /* GraphQL */ `
  mutation UpdateUserCustomer(
    $input: UpdateUserCustomerInput!
    $condition: ModelUserCustomerConditionInput
  ) {
    updateUserCustomer(input: $input, condition: $condition) {
      id
      customerId
      active
      createdAt
      updatedAt
      userCustomersId
      __typename
    }
  }
`;
export const deleteUserCustomer = /* GraphQL */ `
  mutation DeleteUserCustomer(
    $input: DeleteUserCustomerInput!
    $condition: ModelUserCustomerConditionInput
  ) {
    deleteUserCustomer(input: $input, condition: $condition) {
      id
      customerId
      active
      createdAt
      updatedAt
      userCustomersId
      __typename
    }
  }
`;
export const createDriverQualification = /* GraphQL */ `
  mutation CreateDriverQualification(
    $input: CreateDriverQualificationInput!
    $condition: ModelDriverQualificationConditionInput
  ) {
    createDriverQualification(input: $input, condition: $condition) {
      id
      tier
      customerId
      name
      driverId
      driverName
      status
      jobId
      jobStartDate
      createEmail
      updateUser
      dqSpecialistUser
      dqSpecialistEmail
      createUserFullName
      dqOwnerFullName
      dqClientApproverUser
      dqClientApproverUserFullName
      dqDocumentName
      questions {
        items {
          id
          user
          text
          createUserFullName
          type
          status
          createdAt
          updatedAt
          driverQualificationQuestionsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      tierDescription
      branch
      driverSkillSummary
      locationState {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locationCity {
        id
        state {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        name
        status
        createdAt
        updatedAt
        stateCityId
        __typename
      }
      driverQualificationChecklistDocuments {
        items {
          id
          item
          source
          category
          type
          order
          documentName
          createdAt
          updatedAt
          driverQualificationDriverQualificationChecklistDocumentsId
          __typename
        }
        nextToken
        __typename
      }
      accountMasterCode
      checkListLevel
      updatedAt
      driverQualificationLocationStateId
      driverQualificationLocationCityId
      __typename
    }
  }
`;
export const updateDriverQualification = /* GraphQL */ `
  mutation UpdateDriverQualification(
    $input: UpdateDriverQualificationInput!
    $condition: ModelDriverQualificationConditionInput
  ) {
    updateDriverQualification(input: $input, condition: $condition) {
      id
      tier
      customerId
      name
      driverId
      driverName
      status
      jobId
      jobStartDate
      createEmail
      updateUser
      dqSpecialistUser
      dqSpecialistEmail
      createUserFullName
      dqOwnerFullName
      dqClientApproverUser
      dqClientApproverUserFullName
      dqDocumentName
      questions {
        items {
          id
          user
          text
          createUserFullName
          type
          status
          createdAt
          updatedAt
          driverQualificationQuestionsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      tierDescription
      branch
      driverSkillSummary
      locationState {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locationCity {
        id
        state {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        name
        status
        createdAt
        updatedAt
        stateCityId
        __typename
      }
      driverQualificationChecklistDocuments {
        items {
          id
          item
          source
          category
          type
          order
          documentName
          createdAt
          updatedAt
          driverQualificationDriverQualificationChecklistDocumentsId
          __typename
        }
        nextToken
        __typename
      }
      accountMasterCode
      checkListLevel
      updatedAt
      driverQualificationLocationStateId
      driverQualificationLocationCityId
      __typename
    }
  }
`;
export const deleteDriverQualification = /* GraphQL */ `
  mutation DeleteDriverQualification(
    $input: DeleteDriverQualificationInput!
    $condition: ModelDriverQualificationConditionInput
  ) {
    deleteDriverQualification(input: $input, condition: $condition) {
      id
      tier
      customerId
      name
      driverId
      driverName
      status
      jobId
      jobStartDate
      createEmail
      updateUser
      dqSpecialistUser
      dqSpecialistEmail
      createUserFullName
      dqOwnerFullName
      dqClientApproverUser
      dqClientApproverUserFullName
      dqDocumentName
      questions {
        items {
          id
          user
          text
          createUserFullName
          type
          status
          createdAt
          updatedAt
          driverQualificationQuestionsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      tierDescription
      branch
      driverSkillSummary
      locationState {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locationCity {
        id
        state {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        name
        status
        createdAt
        updatedAt
        stateCityId
        __typename
      }
      driverQualificationChecklistDocuments {
        items {
          id
          item
          source
          category
          type
          order
          documentName
          createdAt
          updatedAt
          driverQualificationDriverQualificationChecklistDocumentsId
          __typename
        }
        nextToken
        __typename
      }
      accountMasterCode
      checkListLevel
      updatedAt
      driverQualificationLocationStateId
      driverQualificationLocationCityId
      __typename
    }
  }
`;
export const createDriverQualificationChecklistDocuments = /* GraphQL */ `
  mutation CreateDriverQualificationChecklistDocuments(
    $input: CreateDriverQualificationChecklistDocumentsInput!
    $condition: ModelDriverQualificationChecklistDocumentsConditionInput
  ) {
    createDriverQualificationChecklistDocuments(
      input: $input
      condition: $condition
    ) {
      id
      item
      source
      category
      type
      order
      documentName
      driverQualificationSelectedDocuments {
        items {
          id
          item
          source
          category
          type
          pages
          order
          documentName
          for
          customerId
          driverId
          documentAutoSelected
          createdAt
          updatedAt
          driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      driverQualificationDriverQualificationChecklistDocumentsId
      __typename
    }
  }
`;
export const updateDriverQualificationChecklistDocuments = /* GraphQL */ `
  mutation UpdateDriverQualificationChecklistDocuments(
    $input: UpdateDriverQualificationChecklistDocumentsInput!
    $condition: ModelDriverQualificationChecklistDocumentsConditionInput
  ) {
    updateDriverQualificationChecklistDocuments(
      input: $input
      condition: $condition
    ) {
      id
      item
      source
      category
      type
      order
      documentName
      driverQualificationSelectedDocuments {
        items {
          id
          item
          source
          category
          type
          pages
          order
          documentName
          for
          customerId
          driverId
          documentAutoSelected
          createdAt
          updatedAt
          driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      driverQualificationDriverQualificationChecklistDocumentsId
      __typename
    }
  }
`;
export const deleteDriverQualificationChecklistDocuments = /* GraphQL */ `
  mutation DeleteDriverQualificationChecklistDocuments(
    $input: DeleteDriverQualificationChecklistDocumentsInput!
    $condition: ModelDriverQualificationChecklistDocumentsConditionInput
  ) {
    deleteDriverQualificationChecklistDocuments(
      input: $input
      condition: $condition
    ) {
      id
      item
      source
      category
      type
      order
      documentName
      driverQualificationSelectedDocuments {
        items {
          id
          item
          source
          category
          type
          pages
          order
          documentName
          for
          customerId
          driverId
          documentAutoSelected
          createdAt
          updatedAt
          driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      driverQualificationDriverQualificationChecklistDocumentsId
      __typename
    }
  }
`;
export const createDriverQualificationSelectedDocuments = /* GraphQL */ `
  mutation CreateDriverQualificationSelectedDocuments(
    $input: CreateDriverQualificationSelectedDocumentsInput!
    $condition: ModelDriverQualificationSelectedDocumentsConditionInput
  ) {
    createDriverQualificationSelectedDocuments(
      input: $input
      condition: $condition
    ) {
      id
      item
      source
      category
      type
      pages
      order
      documentName
      for
      customerId
      driverId
      documentAutoSelected
      createdAt
      updatedAt
      driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId
      __typename
    }
  }
`;
export const updateDriverQualificationSelectedDocuments = /* GraphQL */ `
  mutation UpdateDriverQualificationSelectedDocuments(
    $input: UpdateDriverQualificationSelectedDocumentsInput!
    $condition: ModelDriverQualificationSelectedDocumentsConditionInput
  ) {
    updateDriverQualificationSelectedDocuments(
      input: $input
      condition: $condition
    ) {
      id
      item
      source
      category
      type
      pages
      order
      documentName
      for
      customerId
      driverId
      documentAutoSelected
      createdAt
      updatedAt
      driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId
      __typename
    }
  }
`;
export const deleteDriverQualificationSelectedDocuments = /* GraphQL */ `
  mutation DeleteDriverQualificationSelectedDocuments(
    $input: DeleteDriverQualificationSelectedDocumentsInput!
    $condition: ModelDriverQualificationSelectedDocumentsConditionInput
  ) {
    deleteDriverQualificationSelectedDocuments(
      input: $input
      condition: $condition
    ) {
      id
      item
      source
      category
      type
      pages
      order
      documentName
      for
      customerId
      driverId
      documentAutoSelected
      createdAt
      updatedAt
      driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId
      __typename
    }
  }
`;
export const createAtsDriverDocuments = /* GraphQL */ `
  mutation CreateAtsDriverDocuments(
    $input: CreateAtsDriverDocumentsInput!
    $condition: ModelAtsDriverDocumentsConditionInput
  ) {
    createAtsDriverDocuments(input: $input, condition: $condition) {
      id
      atsDriverId
      category
      documentName
      driverId
      source
      type
      expirationDate
      atsDateCreated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAtsDriverDocuments = /* GraphQL */ `
  mutation UpdateAtsDriverDocuments(
    $input: UpdateAtsDriverDocumentsInput!
    $condition: ModelAtsDriverDocumentsConditionInput
  ) {
    updateAtsDriverDocuments(input: $input, condition: $condition) {
      id
      atsDriverId
      category
      documentName
      driverId
      source
      type
      expirationDate
      atsDateCreated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAtsDriverDocuments = /* GraphQL */ `
  mutation DeleteAtsDriverDocuments(
    $input: DeleteAtsDriverDocumentsInput!
    $condition: ModelAtsDriverDocumentsConditionInput
  ) {
    deleteAtsDriverDocuments(input: $input, condition: $condition) {
      id
      atsDriverId
      category
      documentName
      driverId
      source
      type
      expirationDate
      atsDateCreated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStandardDocuments = /* GraphQL */ `
  mutation CreateStandardDocuments(
    $input: CreateStandardDocumentsInput!
    $condition: ModelStandardDocumentsConditionInput
  ) {
    createStandardDocuments(input: $input, condition: $condition) {
      id
      for
      forName
      category
      type
      customerId
      driverId
      documentName
      accountMasterCode
      createdAt
      updatedAt
      userStandardDocumentsId
      __typename
    }
  }
`;
export const updateStandardDocuments = /* GraphQL */ `
  mutation UpdateStandardDocuments(
    $input: UpdateStandardDocumentsInput!
    $condition: ModelStandardDocumentsConditionInput
  ) {
    updateStandardDocuments(input: $input, condition: $condition) {
      id
      for
      forName
      category
      type
      customerId
      driverId
      documentName
      accountMasterCode
      createdAt
      updatedAt
      userStandardDocumentsId
      __typename
    }
  }
`;
export const deleteStandardDocuments = /* GraphQL */ `
  mutation DeleteStandardDocuments(
    $input: DeleteStandardDocumentsInput!
    $condition: ModelStandardDocumentsConditionInput
  ) {
    deleteStandardDocuments(input: $input, condition: $condition) {
      id
      for
      forName
      category
      type
      customerId
      driverId
      documentName
      accountMasterCode
      createdAt
      updatedAt
      userStandardDocumentsId
      __typename
    }
  }
`;
export const createQuestions = /* GraphQL */ `
  mutation CreateQuestions(
    $input: CreateQuestionsInput!
    $condition: ModelQuestionsConditionInput
  ) {
    createQuestions(input: $input, condition: $condition) {
      id
      user
      text
      createUserFullName
      type
      driverQualification {
        id
        tier
        customerId
        name
        driverId
        driverName
        status
        jobId
        jobStartDate
        createEmail
        updateUser
        dqSpecialistUser
        dqSpecialistEmail
        createUserFullName
        dqOwnerFullName
        dqClientApproverUser
        dqClientApproverUserFullName
        dqDocumentName
        questions {
          nextToken
          __typename
        }
        createdAt
        tierDescription
        branch
        driverSkillSummary
        locationState {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        locationCity {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        driverQualificationChecklistDocuments {
          nextToken
          __typename
        }
        accountMasterCode
        checkListLevel
        updatedAt
        driverQualificationLocationStateId
        driverQualificationLocationCityId
        __typename
      }
      status
      createdAt
      updatedAt
      driverQualificationQuestionsId
      __typename
    }
  }
`;
export const updateQuestions = /* GraphQL */ `
  mutation UpdateQuestions(
    $input: UpdateQuestionsInput!
    $condition: ModelQuestionsConditionInput
  ) {
    updateQuestions(input: $input, condition: $condition) {
      id
      user
      text
      createUserFullName
      type
      driverQualification {
        id
        tier
        customerId
        name
        driverId
        driverName
        status
        jobId
        jobStartDate
        createEmail
        updateUser
        dqSpecialistUser
        dqSpecialistEmail
        createUserFullName
        dqOwnerFullName
        dqClientApproverUser
        dqClientApproverUserFullName
        dqDocumentName
        questions {
          nextToken
          __typename
        }
        createdAt
        tierDescription
        branch
        driverSkillSummary
        locationState {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        locationCity {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        driverQualificationChecklistDocuments {
          nextToken
          __typename
        }
        accountMasterCode
        checkListLevel
        updatedAt
        driverQualificationLocationStateId
        driverQualificationLocationCityId
        __typename
      }
      status
      createdAt
      updatedAt
      driverQualificationQuestionsId
      __typename
    }
  }
`;
export const deleteQuestions = /* GraphQL */ `
  mutation DeleteQuestions(
    $input: DeleteQuestionsInput!
    $condition: ModelQuestionsConditionInput
  ) {
    deleteQuestions(input: $input, condition: $condition) {
      id
      user
      text
      createUserFullName
      type
      driverQualification {
        id
        tier
        customerId
        name
        driverId
        driverName
        status
        jobId
        jobStartDate
        createEmail
        updateUser
        dqSpecialistUser
        dqSpecialistEmail
        createUserFullName
        dqOwnerFullName
        dqClientApproverUser
        dqClientApproverUserFullName
        dqDocumentName
        questions {
          nextToken
          __typename
        }
        createdAt
        tierDescription
        branch
        driverSkillSummary
        locationState {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        locationCity {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        driverQualificationChecklistDocuments {
          nextToken
          __typename
        }
        accountMasterCode
        checkListLevel
        updatedAt
        driverQualificationLocationStateId
        driverQualificationLocationCityId
        __typename
      }
      status
      createdAt
      updatedAt
      driverQualificationQuestionsId
      __typename
    }
  }
`;
export const createAudit = /* GraphQL */ `
  mutation CreateAudit(
    $input: CreateAuditInput!
    $condition: ModelAuditConditionInput
  ) {
    createAudit(input: $input, condition: $condition) {
      id
      event
      eventTime
      user {
        id
        name
        customerId
        status
        type
        lastLogin
        clientName
        updateDriverQualifications
        StandardDocuments {
          nextToken
          __typename
        }
        Audit {
          nextToken
          __typename
        }
        appAccess {
          nextToken
          __typename
        }
        cellPhone
        passwordChangeDate
        preferredMFA
        lastResetMFA
        customers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      objectName
      objectId
      objectData
      createdAt
      updatedAt
      userAuditId
      __typename
    }
  }
`;
export const updateAudit = /* GraphQL */ `
  mutation UpdateAudit(
    $input: UpdateAuditInput!
    $condition: ModelAuditConditionInput
  ) {
    updateAudit(input: $input, condition: $condition) {
      id
      event
      eventTime
      user {
        id
        name
        customerId
        status
        type
        lastLogin
        clientName
        updateDriverQualifications
        StandardDocuments {
          nextToken
          __typename
        }
        Audit {
          nextToken
          __typename
        }
        appAccess {
          nextToken
          __typename
        }
        cellPhone
        passwordChangeDate
        preferredMFA
        lastResetMFA
        customers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      objectName
      objectId
      objectData
      createdAt
      updatedAt
      userAuditId
      __typename
    }
  }
`;
export const deleteAudit = /* GraphQL */ `
  mutation DeleteAudit(
    $input: DeleteAuditInput!
    $condition: ModelAuditConditionInput
  ) {
    deleteAudit(input: $input, condition: $condition) {
      id
      event
      eventTime
      user {
        id
        name
        customerId
        status
        type
        lastLogin
        clientName
        updateDriverQualifications
        StandardDocuments {
          nextToken
          __typename
        }
        Audit {
          nextToken
          __typename
        }
        appAccess {
          nextToken
          __typename
        }
        cellPhone
        passwordChangeDate
        preferredMFA
        lastResetMFA
        customers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      objectName
      objectId
      objectData
      createdAt
      updatedAt
      userAuditId
      __typename
    }
  }
`;
export const createApplication = /* GraphQL */ `
  mutation CreateApplication(
    $input: CreateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    createApplication(input: $input, condition: $condition) {
      id
      name
      roles {
        items {
          id
          name
          usedBy
          applicationUsedBy
          viewFullPii
          createdAt
          updatedAt
          applicationRolesId
          __typename
        }
        nextToken
        __typename
      }
      userAppAccess {
        items {
          id
          createdAt
          updatedAt
          userAppAccessId
          applicationUserAppAccessId
          applicationRoleUserAppAccessId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
      name
      roles {
        items {
          id
          name
          usedBy
          applicationUsedBy
          viewFullPii
          createdAt
          updatedAt
          applicationRolesId
          __typename
        }
        nextToken
        __typename
      }
      userAppAccess {
        items {
          id
          createdAt
          updatedAt
          userAppAccessId
          applicationUserAppAccessId
          applicationRoleUserAppAccessId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteApplication = /* GraphQL */ `
  mutation DeleteApplication(
    $input: DeleteApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    deleteApplication(input: $input, condition: $condition) {
      id
      name
      roles {
        items {
          id
          name
          usedBy
          applicationUsedBy
          viewFullPii
          createdAt
          updatedAt
          applicationRolesId
          __typename
        }
        nextToken
        __typename
      }
      userAppAccess {
        items {
          id
          createdAt
          updatedAt
          userAppAccessId
          applicationUserAppAccessId
          applicationRoleUserAppAccessId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createApplicationRole = /* GraphQL */ `
  mutation CreateApplicationRole(
    $input: CreateApplicationRoleInput!
    $condition: ModelApplicationRoleConditionInput
  ) {
    createApplicationRole(input: $input, condition: $condition) {
      id
      name
      usedBy
      userAppAccess {
        items {
          id
          createdAt
          updatedAt
          userAppAccessId
          applicationUserAppAccessId
          applicationRoleUserAppAccessId
          __typename
        }
        nextToken
        __typename
      }
      applicationUsedBy
      viewFullPii
      createdAt
      updatedAt
      applicationRolesId
      __typename
    }
  }
`;
export const updateApplicationRole = /* GraphQL */ `
  mutation UpdateApplicationRole(
    $input: UpdateApplicationRoleInput!
    $condition: ModelApplicationRoleConditionInput
  ) {
    updateApplicationRole(input: $input, condition: $condition) {
      id
      name
      usedBy
      userAppAccess {
        items {
          id
          createdAt
          updatedAt
          userAppAccessId
          applicationUserAppAccessId
          applicationRoleUserAppAccessId
          __typename
        }
        nextToken
        __typename
      }
      applicationUsedBy
      viewFullPii
      createdAt
      updatedAt
      applicationRolesId
      __typename
    }
  }
`;
export const deleteApplicationRole = /* GraphQL */ `
  mutation DeleteApplicationRole(
    $input: DeleteApplicationRoleInput!
    $condition: ModelApplicationRoleConditionInput
  ) {
    deleteApplicationRole(input: $input, condition: $condition) {
      id
      name
      usedBy
      userAppAccess {
        items {
          id
          createdAt
          updatedAt
          userAppAccessId
          applicationUserAppAccessId
          applicationRoleUserAppAccessId
          __typename
        }
        nextToken
        __typename
      }
      applicationUsedBy
      viewFullPii
      createdAt
      updatedAt
      applicationRolesId
      __typename
    }
  }
`;
export const createUserApplicationAccess = /* GraphQL */ `
  mutation CreateUserApplicationAccess(
    $input: CreateUserApplicationAccessInput!
    $condition: ModelUserApplicationAccessConditionInput
  ) {
    createUserApplicationAccess(input: $input, condition: $condition) {
      id
      userId {
        id
        name
        customerId
        status
        type
        lastLogin
        clientName
        updateDriverQualifications
        StandardDocuments {
          nextToken
          __typename
        }
        Audit {
          nextToken
          __typename
        }
        appAccess {
          nextToken
          __typename
        }
        cellPhone
        passwordChangeDate
        preferredMFA
        lastResetMFA
        customers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      appId {
        id
        name
        roles {
          nextToken
          __typename
        }
        userAppAccess {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      roleId {
        id
        name
        usedBy
        userAppAccess {
          nextToken
          __typename
        }
        applicationUsedBy
        viewFullPii
        createdAt
        updatedAt
        applicationRolesId
        __typename
      }
      createdAt
      updatedAt
      userAppAccessId
      applicationUserAppAccessId
      applicationRoleUserAppAccessId
      __typename
    }
  }
`;
export const updateUserApplicationAccess = /* GraphQL */ `
  mutation UpdateUserApplicationAccess(
    $input: UpdateUserApplicationAccessInput!
    $condition: ModelUserApplicationAccessConditionInput
  ) {
    updateUserApplicationAccess(input: $input, condition: $condition) {
      id
      userId {
        id
        name
        customerId
        status
        type
        lastLogin
        clientName
        updateDriverQualifications
        StandardDocuments {
          nextToken
          __typename
        }
        Audit {
          nextToken
          __typename
        }
        appAccess {
          nextToken
          __typename
        }
        cellPhone
        passwordChangeDate
        preferredMFA
        lastResetMFA
        customers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      appId {
        id
        name
        roles {
          nextToken
          __typename
        }
        userAppAccess {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      roleId {
        id
        name
        usedBy
        userAppAccess {
          nextToken
          __typename
        }
        applicationUsedBy
        viewFullPii
        createdAt
        updatedAt
        applicationRolesId
        __typename
      }
      createdAt
      updatedAt
      userAppAccessId
      applicationUserAppAccessId
      applicationRoleUserAppAccessId
      __typename
    }
  }
`;
export const deleteUserApplicationAccess = /* GraphQL */ `
  mutation DeleteUserApplicationAccess(
    $input: DeleteUserApplicationAccessInput!
    $condition: ModelUserApplicationAccessConditionInput
  ) {
    deleteUserApplicationAccess(input: $input, condition: $condition) {
      id
      userId {
        id
        name
        customerId
        status
        type
        lastLogin
        clientName
        updateDriverQualifications
        StandardDocuments {
          nextToken
          __typename
        }
        Audit {
          nextToken
          __typename
        }
        appAccess {
          nextToken
          __typename
        }
        cellPhone
        passwordChangeDate
        preferredMFA
        lastResetMFA
        customers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      appId {
        id
        name
        roles {
          nextToken
          __typename
        }
        userAppAccess {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      roleId {
        id
        name
        usedBy
        userAppAccess {
          nextToken
          __typename
        }
        applicationUsedBy
        viewFullPii
        createdAt
        updatedAt
        applicationRolesId
        __typename
      }
      createdAt
      updatedAt
      userAppAccessId
      applicationUserAppAccessId
      applicationRoleUserAppAccessId
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      ApplicationId
      Event
      UserId
      ObjectId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      ApplicationId
      Event
      UserId
      ObjectId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      ApplicationId
      Event
      UserId
      ObjectId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDocumentSubject = /* GraphQL */ `
  mutation CreateDocumentSubject(
    $input: CreateDocumentSubjectInput!
    $condition: ModelDocumentSubjectConditionInput
  ) {
    createDocumentSubject(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDocumentSubject = /* GraphQL */ `
  mutation UpdateDocumentSubject(
    $input: UpdateDocumentSubjectInput!
    $condition: ModelDocumentSubjectConditionInput
  ) {
    updateDocumentSubject(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDocumentSubject = /* GraphQL */ `
  mutation DeleteDocumentSubject(
    $input: DeleteDocumentSubjectInput!
    $condition: ModelDocumentSubjectConditionInput
  ) {
    deleteDocumentSubject(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDocumentSource = /* GraphQL */ `
  mutation CreateDocumentSource(
    $input: CreateDocumentSourceInput!
    $condition: ModelDocumentSourceConditionInput
  ) {
    createDocumentSource(input: $input, condition: $condition) {
      id
      name
      category {
        items {
          id
          name
          createdAt
          updatedAt
          documentSourceCategoryId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDocumentSource = /* GraphQL */ `
  mutation UpdateDocumentSource(
    $input: UpdateDocumentSourceInput!
    $condition: ModelDocumentSourceConditionInput
  ) {
    updateDocumentSource(input: $input, condition: $condition) {
      id
      name
      category {
        items {
          id
          name
          createdAt
          updatedAt
          documentSourceCategoryId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDocumentSource = /* GraphQL */ `
  mutation DeleteDocumentSource(
    $input: DeleteDocumentSourceInput!
    $condition: ModelDocumentSourceConditionInput
  ) {
    deleteDocumentSource(input: $input, condition: $condition) {
      id
      name
      category {
        items {
          id
          name
          createdAt
          updatedAt
          documentSourceCategoryId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDocumentCategory = /* GraphQL */ `
  mutation CreateDocumentCategory(
    $input: CreateDocumentCategoryInput!
    $condition: ModelDocumentCategoryConditionInput
  ) {
    createDocumentCategory(input: $input, condition: $condition) {
      id
      name
      type {
        items {
          id
          Name
          For
          createdAt
          updatedAt
          documentCategoryTypeId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      documentSourceCategoryId
      __typename
    }
  }
`;
export const updateDocumentCategory = /* GraphQL */ `
  mutation UpdateDocumentCategory(
    $input: UpdateDocumentCategoryInput!
    $condition: ModelDocumentCategoryConditionInput
  ) {
    updateDocumentCategory(input: $input, condition: $condition) {
      id
      name
      type {
        items {
          id
          Name
          For
          createdAt
          updatedAt
          documentCategoryTypeId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      documentSourceCategoryId
      __typename
    }
  }
`;
export const deleteDocumentCategory = /* GraphQL */ `
  mutation DeleteDocumentCategory(
    $input: DeleteDocumentCategoryInput!
    $condition: ModelDocumentCategoryConditionInput
  ) {
    deleteDocumentCategory(input: $input, condition: $condition) {
      id
      name
      type {
        items {
          id
          Name
          For
          createdAt
          updatedAt
          documentCategoryTypeId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      documentSourceCategoryId
      __typename
    }
  }
`;
export const createDocumentType = /* GraphQL */ `
  mutation CreateDocumentType(
    $input: CreateDocumentTypeInput!
    $condition: ModelDocumentTypeConditionInput
  ) {
    createDocumentType(input: $input, condition: $condition) {
      id
      Name
      For
      createdAt
      updatedAt
      documentCategoryTypeId
      __typename
    }
  }
`;
export const updateDocumentType = /* GraphQL */ `
  mutation UpdateDocumentType(
    $input: UpdateDocumentTypeInput!
    $condition: ModelDocumentTypeConditionInput
  ) {
    updateDocumentType(input: $input, condition: $condition) {
      id
      Name
      For
      createdAt
      updatedAt
      documentCategoryTypeId
      __typename
    }
  }
`;
export const deleteDocumentType = /* GraphQL */ `
  mutation DeleteDocumentType(
    $input: DeleteDocumentTypeInput!
    $condition: ModelDocumentTypeConditionInput
  ) {
    deleteDocumentType(input: $input, condition: $condition) {
      id
      Name
      For
      createdAt
      updatedAt
      documentCategoryTypeId
      __typename
    }
  }
`;
export const createChecklist = /* GraphQL */ `
  mutation CreateChecklist(
    $input: CreateChecklistInput!
    $condition: ModelChecklistConditionInput
  ) {
    createChecklist(input: $input, condition: $condition) {
      id
      tier
      clientId
      clientName
      checklistDocumentName
      accountMasterCode
      checkListLevel
      items {
        items {
          id
          item
          source
          category
          type
          pages
          order
          createdAt
          updatedAt
          checklistItemsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChecklist = /* GraphQL */ `
  mutation UpdateChecklist(
    $input: UpdateChecklistInput!
    $condition: ModelChecklistConditionInput
  ) {
    updateChecklist(input: $input, condition: $condition) {
      id
      tier
      clientId
      clientName
      checklistDocumentName
      accountMasterCode
      checkListLevel
      items {
        items {
          id
          item
          source
          category
          type
          pages
          order
          createdAt
          updatedAt
          checklistItemsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChecklist = /* GraphQL */ `
  mutation DeleteChecklist(
    $input: DeleteChecklistInput!
    $condition: ModelChecklistConditionInput
  ) {
    deleteChecklist(input: $input, condition: $condition) {
      id
      tier
      clientId
      clientName
      checklistDocumentName
      accountMasterCode
      checkListLevel
      items {
        items {
          id
          item
          source
          category
          type
          pages
          order
          createdAt
          updatedAt
          checklistItemsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChecklistItems = /* GraphQL */ `
  mutation CreateChecklistItems(
    $input: CreateChecklistItemsInput!
    $condition: ModelChecklistItemsConditionInput
  ) {
    createChecklistItems(input: $input, condition: $condition) {
      id
      item
      source
      category
      type
      pages
      order
      createdAt
      updatedAt
      checklistItemsId
      __typename
    }
  }
`;
export const updateChecklistItems = /* GraphQL */ `
  mutation UpdateChecklistItems(
    $input: UpdateChecklistItemsInput!
    $condition: ModelChecklistItemsConditionInput
  ) {
    updateChecklistItems(input: $input, condition: $condition) {
      id
      item
      source
      category
      type
      pages
      order
      createdAt
      updatedAt
      checklistItemsId
      __typename
    }
  }
`;
export const deleteChecklistItems = /* GraphQL */ `
  mutation DeleteChecklistItems(
    $input: DeleteChecklistItemsInput!
    $condition: ModelChecklistItemsConditionInput
  ) {
    deleteChecklistItems(input: $input, condition: $condition) {
      id
      item
      source
      category
      type
      pages
      order
      createdAt
      updatedAt
      checklistItemsId
      __typename
    }
  }
`;
export const createAirLine = /* GraphQL */ `
  mutation CreateAirLine(
    $input: CreateAirLineInput!
    $condition: ModelAirLineConditionInput
  ) {
    createAirLine(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAirLine = /* GraphQL */ `
  mutation UpdateAirLine(
    $input: UpdateAirLineInput!
    $condition: ModelAirLineConditionInput
  ) {
    updateAirLine(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAirLine = /* GraphQL */ `
  mutation DeleteAirLine(
    $input: DeleteAirLineInput!
    $condition: ModelAirLineConditionInput
  ) {
    deleteAirLine(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAirPort = /* GraphQL */ `
  mutation CreateAirPort(
    $input: CreateAirPortInput!
    $condition: ModelAirPortConditionInput
  ) {
    createAirPort(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAirPort = /* GraphQL */ `
  mutation UpdateAirPort(
    $input: UpdateAirPortInput!
    $condition: ModelAirPortConditionInput
  ) {
    updateAirPort(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAirPort = /* GraphQL */ `
  mutation DeleteAirPort(
    $input: DeleteAirPortInput!
    $condition: ModelAirPortConditionInput
  ) {
    deleteAirPort(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHotel = /* GraphQL */ `
  mutation CreateHotel(
    $input: CreateHotelInput!
    $condition: ModelHotelConditionInput
  ) {
    createHotel(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHotel = /* GraphQL */ `
  mutation UpdateHotel(
    $input: UpdateHotelInput!
    $condition: ModelHotelConditionInput
  ) {
    updateHotel(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHotel = /* GraphQL */ `
  mutation DeleteHotel(
    $input: DeleteHotelInput!
    $condition: ModelHotelConditionInput
  ) {
    deleteHotel(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCarRental = /* GraphQL */ `
  mutation CreateCarRental(
    $input: CreateCarRentalInput!
    $condition: ModelCarRentalConditionInput
  ) {
    createCarRental(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCarRental = /* GraphQL */ `
  mutation UpdateCarRental(
    $input: UpdateCarRentalInput!
    $condition: ModelCarRentalConditionInput
  ) {
    updateCarRental(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCarRental = /* GraphQL */ `
  mutation DeleteCarRental(
    $input: DeleteCarRentalInput!
    $condition: ModelCarRentalConditionInput
  ) {
    deleteCarRental(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createState = /* GraphQL */ `
  mutation CreateState(
    $input: CreateStateInput!
    $condition: ModelStateConditionInput
  ) {
    createState(input: $input, condition: $condition) {
      id
      name
      city {
        items {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateState = /* GraphQL */ `
  mutation UpdateState(
    $input: UpdateStateInput!
    $condition: ModelStateConditionInput
  ) {
    updateState(input: $input, condition: $condition) {
      id
      name
      city {
        items {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteState = /* GraphQL */ `
  mutation DeleteState(
    $input: DeleteStateInput!
    $condition: ModelStateConditionInput
  ) {
    deleteState(input: $input, condition: $condition) {
      id
      name
      city {
        items {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCity = /* GraphQL */ `
  mutation CreateCity(
    $input: CreateCityInput!
    $condition: ModelCityConditionInput
  ) {
    createCity(input: $input, condition: $condition) {
      id
      state {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      name
      status
      createdAt
      updatedAt
      stateCityId
      __typename
    }
  }
`;
export const updateCity = /* GraphQL */ `
  mutation UpdateCity(
    $input: UpdateCityInput!
    $condition: ModelCityConditionInput
  ) {
    updateCity(input: $input, condition: $condition) {
      id
      state {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      name
      status
      createdAt
      updatedAt
      stateCityId
      __typename
    }
  }
`;
export const deleteCity = /* GraphQL */ `
  mutation DeleteCity(
    $input: DeleteCityInput!
    $condition: ModelCityConditionInput
  ) {
    deleteCity(input: $input, condition: $condition) {
      id
      state {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      name
      status
      createdAt
      updatedAt
      stateCityId
      __typename
    }
  }
`;
export const createExpense = /* GraphQL */ `
  mutation CreateExpense(
    $input: CreateExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    createExpense(input: $input, condition: $condition) {
      id
      code
      driverId
      driverName
      assignmentId
      status
      nonBillable
      customerId
      customerName
      branch
      branchName
      costCenter
      startDate
      endDate
      totalToBeBilled
      notes
      auditKey
      invoiceDate
      invoiceNumber
      amountBilled
      variance
      statementDate
      expenseReportAppropriationNbr
      expenseItem {
        items {
          id
          flightDate
          paidThrough
          receiptAmount
          markup
          expenseTotal
          documentName
          documentSize
          uploadedByName
          uploadedDate
          hotelConfirmationNumber
          hotelPhoneNumber
          hotelStreetAddress
          checkInDate
          lastNightOfStay
          dailyCharge
          totalReceiptAmount
          carRentalContractNumber
          carRentalPickUpLocation
          carRentalReturnLocation
          carRentalPickUpDate
          carRentalReturnDate
          originalReceiptAmount
          otherExpenseName
          otherExpenseDescription
          otherExpenseDate
          hotelDriverId
          hotelDriverName
          hotelAssignmentId
          projectedDays
          projectedAmount
          createdAt
          updatedAt
          expenseExpenseItemId
          expenseItemAirLineId
          expenseItemDepartureAirPortId
          expenseItemArrivalAirPortId
          expenseItemHotelId
          expenseItemHotelStateId
          expenseItemHotelCityId
          expenseItemCarRentalCompanyId
          __typename
        }
        nextToken
        __typename
      }
      sharedInvoice
      createdAt
      hotel {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      hotelConfirmationNumber
      hotelPhoneNumber
      hotelStreetAddress
      hotelState {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      hotelCity {
        id
        state {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        name
        status
        createdAt
        updatedAt
        stateCityId
        __typename
      }
      documentName
      documentSize
      uploadedByName
      uploadedDate
      totalReceiptAmount
      totalProjectedAmount
      checkedForUltraStaff
      inv
      payType
      payAmt
      billUnits
      ok
      payWeekEnding
      originalExpenseId
      correctionExpenseId
      rebilledExpenseId
      flatFeeBilling
      lob
      confirmedBy
      updatedBy
      updatedAt
      __typename
    }
  }
`;
export const updateExpense = /* GraphQL */ `
  mutation UpdateExpense(
    $input: UpdateExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    updateExpense(input: $input, condition: $condition) {
      id
      code
      driverId
      driverName
      assignmentId
      status
      nonBillable
      customerId
      customerName
      branch
      branchName
      costCenter
      startDate
      endDate
      totalToBeBilled
      notes
      auditKey
      invoiceDate
      invoiceNumber
      amountBilled
      variance
      statementDate
      expenseReportAppropriationNbr
      expenseItem {
        items {
          id
          flightDate
          paidThrough
          receiptAmount
          markup
          expenseTotal
          documentName
          documentSize
          uploadedByName
          uploadedDate
          hotelConfirmationNumber
          hotelPhoneNumber
          hotelStreetAddress
          checkInDate
          lastNightOfStay
          dailyCharge
          totalReceiptAmount
          carRentalContractNumber
          carRentalPickUpLocation
          carRentalReturnLocation
          carRentalPickUpDate
          carRentalReturnDate
          originalReceiptAmount
          otherExpenseName
          otherExpenseDescription
          otherExpenseDate
          hotelDriverId
          hotelDriverName
          hotelAssignmentId
          projectedDays
          projectedAmount
          createdAt
          updatedAt
          expenseExpenseItemId
          expenseItemAirLineId
          expenseItemDepartureAirPortId
          expenseItemArrivalAirPortId
          expenseItemHotelId
          expenseItemHotelStateId
          expenseItemHotelCityId
          expenseItemCarRentalCompanyId
          __typename
        }
        nextToken
        __typename
      }
      sharedInvoice
      createdAt
      hotel {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      hotelConfirmationNumber
      hotelPhoneNumber
      hotelStreetAddress
      hotelState {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      hotelCity {
        id
        state {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        name
        status
        createdAt
        updatedAt
        stateCityId
        __typename
      }
      documentName
      documentSize
      uploadedByName
      uploadedDate
      totalReceiptAmount
      totalProjectedAmount
      checkedForUltraStaff
      inv
      payType
      payAmt
      billUnits
      ok
      payWeekEnding
      originalExpenseId
      correctionExpenseId
      rebilledExpenseId
      flatFeeBilling
      lob
      confirmedBy
      updatedBy
      updatedAt
      __typename
    }
  }
`;
export const deleteExpense = /* GraphQL */ `
  mutation DeleteExpense(
    $input: DeleteExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    deleteExpense(input: $input, condition: $condition) {
      id
      code
      driverId
      driverName
      assignmentId
      status
      nonBillable
      customerId
      customerName
      branch
      branchName
      costCenter
      startDate
      endDate
      totalToBeBilled
      notes
      auditKey
      invoiceDate
      invoiceNumber
      amountBilled
      variance
      statementDate
      expenseReportAppropriationNbr
      expenseItem {
        items {
          id
          flightDate
          paidThrough
          receiptAmount
          markup
          expenseTotal
          documentName
          documentSize
          uploadedByName
          uploadedDate
          hotelConfirmationNumber
          hotelPhoneNumber
          hotelStreetAddress
          checkInDate
          lastNightOfStay
          dailyCharge
          totalReceiptAmount
          carRentalContractNumber
          carRentalPickUpLocation
          carRentalReturnLocation
          carRentalPickUpDate
          carRentalReturnDate
          originalReceiptAmount
          otherExpenseName
          otherExpenseDescription
          otherExpenseDate
          hotelDriverId
          hotelDriverName
          hotelAssignmentId
          projectedDays
          projectedAmount
          createdAt
          updatedAt
          expenseExpenseItemId
          expenseItemAirLineId
          expenseItemDepartureAirPortId
          expenseItemArrivalAirPortId
          expenseItemHotelId
          expenseItemHotelStateId
          expenseItemHotelCityId
          expenseItemCarRentalCompanyId
          __typename
        }
        nextToken
        __typename
      }
      sharedInvoice
      createdAt
      hotel {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      hotelConfirmationNumber
      hotelPhoneNumber
      hotelStreetAddress
      hotelState {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      hotelCity {
        id
        state {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        name
        status
        createdAt
        updatedAt
        stateCityId
        __typename
      }
      documentName
      documentSize
      uploadedByName
      uploadedDate
      totalReceiptAmount
      totalProjectedAmount
      checkedForUltraStaff
      inv
      payType
      payAmt
      billUnits
      ok
      payWeekEnding
      originalExpenseId
      correctionExpenseId
      rebilledExpenseId
      flatFeeBilling
      lob
      confirmedBy
      updatedBy
      updatedAt
      __typename
    }
  }
`;
export const createExpenseItem = /* GraphQL */ `
  mutation CreateExpenseItem(
    $input: CreateExpenseItemInput!
    $condition: ModelExpenseItemConditionInput
  ) {
    createExpenseItem(input: $input, condition: $condition) {
      id
      expense {
        id
        code
        driverId
        driverName
        assignmentId
        status
        nonBillable
        customerId
        customerName
        branch
        branchName
        costCenter
        startDate
        endDate
        totalToBeBilled
        notes
        auditKey
        invoiceDate
        invoiceNumber
        amountBilled
        variance
        statementDate
        expenseReportAppropriationNbr
        expenseItem {
          nextToken
          __typename
        }
        sharedInvoice
        createdAt
        hotel {
          id
          name
          status
          createdAt
          updatedAt
          __typename
        }
        hotelConfirmationNumber
        hotelPhoneNumber
        hotelStreetAddress
        hotelState {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        hotelCity {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        documentName
        documentSize
        uploadedByName
        uploadedDate
        totalReceiptAmount
        totalProjectedAmount
        checkedForUltraStaff
        inv
        payType
        payAmt
        billUnits
        ok
        payWeekEnding
        originalExpenseId
        correctionExpenseId
        rebilledExpenseId
        flatFeeBilling
        lob
        confirmedBy
        updatedBy
        updatedAt
        __typename
      }
      airLine {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      departureAirPort {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      arrivalAirPort {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      flightDate
      paidThrough
      receiptAmount
      markup
      expenseTotal
      documentName
      documentSize
      uploadedByName
      uploadedDate
      hotel {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      hotelConfirmationNumber
      hotelPhoneNumber
      hotelStreetAddress
      hotelState {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      hotelCity {
        id
        state {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        name
        status
        createdAt
        updatedAt
        stateCityId
        __typename
      }
      checkInDate
      lastNightOfStay
      dailyCharge
      totalReceiptAmount
      carRentalCompany {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      carRentalContractNumber
      carRentalPickUpLocation
      carRentalReturnLocation
      carRentalPickUpDate
      carRentalReturnDate
      originalReceiptAmount
      otherExpenseName
      otherExpenseDescription
      otherExpenseDate
      hotelDriverId
      hotelDriverName
      hotelAssignmentId
      projectedDays
      projectedAmount
      createdAt
      updatedAt
      expenseExpenseItemId
      expenseItemAirLineId
      expenseItemDepartureAirPortId
      expenseItemArrivalAirPortId
      expenseItemHotelId
      expenseItemHotelStateId
      expenseItemHotelCityId
      expenseItemCarRentalCompanyId
      __typename
    }
  }
`;
export const updateExpenseItem = /* GraphQL */ `
  mutation UpdateExpenseItem(
    $input: UpdateExpenseItemInput!
    $condition: ModelExpenseItemConditionInput
  ) {
    updateExpenseItem(input: $input, condition: $condition) {
      id
      expense {
        id
        code
        driverId
        driverName
        assignmentId
        status
        nonBillable
        customerId
        customerName
        branch
        branchName
        costCenter
        startDate
        endDate
        totalToBeBilled
        notes
        auditKey
        invoiceDate
        invoiceNumber
        amountBilled
        variance
        statementDate
        expenseReportAppropriationNbr
        expenseItem {
          nextToken
          __typename
        }
        sharedInvoice
        createdAt
        hotel {
          id
          name
          status
          createdAt
          updatedAt
          __typename
        }
        hotelConfirmationNumber
        hotelPhoneNumber
        hotelStreetAddress
        hotelState {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        hotelCity {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        documentName
        documentSize
        uploadedByName
        uploadedDate
        totalReceiptAmount
        totalProjectedAmount
        checkedForUltraStaff
        inv
        payType
        payAmt
        billUnits
        ok
        payWeekEnding
        originalExpenseId
        correctionExpenseId
        rebilledExpenseId
        flatFeeBilling
        lob
        confirmedBy
        updatedBy
        updatedAt
        __typename
      }
      airLine {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      departureAirPort {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      arrivalAirPort {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      flightDate
      paidThrough
      receiptAmount
      markup
      expenseTotal
      documentName
      documentSize
      uploadedByName
      uploadedDate
      hotel {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      hotelConfirmationNumber
      hotelPhoneNumber
      hotelStreetAddress
      hotelState {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      hotelCity {
        id
        state {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        name
        status
        createdAt
        updatedAt
        stateCityId
        __typename
      }
      checkInDate
      lastNightOfStay
      dailyCharge
      totalReceiptAmount
      carRentalCompany {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      carRentalContractNumber
      carRentalPickUpLocation
      carRentalReturnLocation
      carRentalPickUpDate
      carRentalReturnDate
      originalReceiptAmount
      otherExpenseName
      otherExpenseDescription
      otherExpenseDate
      hotelDriverId
      hotelDriverName
      hotelAssignmentId
      projectedDays
      projectedAmount
      createdAt
      updatedAt
      expenseExpenseItemId
      expenseItemAirLineId
      expenseItemDepartureAirPortId
      expenseItemArrivalAirPortId
      expenseItemHotelId
      expenseItemHotelStateId
      expenseItemHotelCityId
      expenseItemCarRentalCompanyId
      __typename
    }
  }
`;
export const deleteExpenseItem = /* GraphQL */ `
  mutation DeleteExpenseItem(
    $input: DeleteExpenseItemInput!
    $condition: ModelExpenseItemConditionInput
  ) {
    deleteExpenseItem(input: $input, condition: $condition) {
      id
      expense {
        id
        code
        driverId
        driverName
        assignmentId
        status
        nonBillable
        customerId
        customerName
        branch
        branchName
        costCenter
        startDate
        endDate
        totalToBeBilled
        notes
        auditKey
        invoiceDate
        invoiceNumber
        amountBilled
        variance
        statementDate
        expenseReportAppropriationNbr
        expenseItem {
          nextToken
          __typename
        }
        sharedInvoice
        createdAt
        hotel {
          id
          name
          status
          createdAt
          updatedAt
          __typename
        }
        hotelConfirmationNumber
        hotelPhoneNumber
        hotelStreetAddress
        hotelState {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        hotelCity {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        documentName
        documentSize
        uploadedByName
        uploadedDate
        totalReceiptAmount
        totalProjectedAmount
        checkedForUltraStaff
        inv
        payType
        payAmt
        billUnits
        ok
        payWeekEnding
        originalExpenseId
        correctionExpenseId
        rebilledExpenseId
        flatFeeBilling
        lob
        confirmedBy
        updatedBy
        updatedAt
        __typename
      }
      airLine {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      departureAirPort {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      arrivalAirPort {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      flightDate
      paidThrough
      receiptAmount
      markup
      expenseTotal
      documentName
      documentSize
      uploadedByName
      uploadedDate
      hotel {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      hotelConfirmationNumber
      hotelPhoneNumber
      hotelStreetAddress
      hotelState {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      hotelCity {
        id
        state {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        name
        status
        createdAt
        updatedAt
        stateCityId
        __typename
      }
      checkInDate
      lastNightOfStay
      dailyCharge
      totalReceiptAmount
      carRentalCompany {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      carRentalContractNumber
      carRentalPickUpLocation
      carRentalReturnLocation
      carRentalPickUpDate
      carRentalReturnDate
      originalReceiptAmount
      otherExpenseName
      otherExpenseDescription
      otherExpenseDate
      hotelDriverId
      hotelDriverName
      hotelAssignmentId
      projectedDays
      projectedAmount
      createdAt
      updatedAt
      expenseExpenseItemId
      expenseItemAirLineId
      expenseItemDepartureAirPortId
      expenseItemArrivalAirPortId
      expenseItemHotelId
      expenseItemHotelStateId
      expenseItemHotelCityId
      expenseItemCarRentalCompanyId
      __typename
    }
  }
`;
export const createTATQuiz = /* GraphQL */ `
  mutation CreateTATQuiz(
    $input: CreateTATQuizInput!
    $condition: ModelTATQuizConditionInput
  ) {
    createTATQuiz(input: $input, condition: $condition) {
      id
      livesOfPotentialVictims
      isTraffickingInTheUntiedStates
      whereTraffickingHappen
      victimsNeedAidInTheirRecovery
      humanTraffickingIsExploitation
      traffickersGroomPotentialVictims
      vulnerabilityFactors
      minorsSellingSexIsProstitution
      redFlagsToWatchOutFor
      crimesInProgress
      certificate
      status
      attempts
      employeeFullName
      emailNotificationStatus
      emailNotificationDateTime
      firstName
      lastName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTATQuiz = /* GraphQL */ `
  mutation UpdateTATQuiz(
    $input: UpdateTATQuizInput!
    $condition: ModelTATQuizConditionInput
  ) {
    updateTATQuiz(input: $input, condition: $condition) {
      id
      livesOfPotentialVictims
      isTraffickingInTheUntiedStates
      whereTraffickingHappen
      victimsNeedAidInTheirRecovery
      humanTraffickingIsExploitation
      traffickersGroomPotentialVictims
      vulnerabilityFactors
      minorsSellingSexIsProstitution
      redFlagsToWatchOutFor
      crimesInProgress
      certificate
      status
      attempts
      employeeFullName
      emailNotificationStatus
      emailNotificationDateTime
      firstName
      lastName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTATQuiz = /* GraphQL */ `
  mutation DeleteTATQuiz(
    $input: DeleteTATQuizInput!
    $condition: ModelTATQuizConditionInput
  ) {
    deleteTATQuiz(input: $input, condition: $condition) {
      id
      livesOfPotentialVictims
      isTraffickingInTheUntiedStates
      whereTraffickingHappen
      victimsNeedAidInTheirRecovery
      humanTraffickingIsExploitation
      traffickersGroomPotentialVictims
      vulnerabilityFactors
      minorsSellingSexIsProstitution
      redFlagsToWatchOutFor
      crimesInProgress
      certificate
      status
      attempts
      employeeFullName
      emailNotificationStatus
      emailNotificationDateTime
      firstName
      lastName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPushNotification = /* GraphQL */ `
  mutation CreatePushNotification(
    $input: CreatePushNotificationInput!
    $condition: ModelPushNotificationConditionInput
  ) {
    createPushNotification(input: $input, condition: $condition) {
      id
      type
      subject
      message
      url
      status
      lob
      branch
      recipientStatus
      approvedBy
      approvedAt
      sentOn
      createdAt
      createdBy
      updatedAt
      __typename
    }
  }
`;
export const updatePushNotification = /* GraphQL */ `
  mutation UpdatePushNotification(
    $input: UpdatePushNotificationInput!
    $condition: ModelPushNotificationConditionInput
  ) {
    updatePushNotification(input: $input, condition: $condition) {
      id
      type
      subject
      message
      url
      status
      lob
      branch
      recipientStatus
      approvedBy
      approvedAt
      sentOn
      createdAt
      createdBy
      updatedAt
      __typename
    }
  }
`;
export const deletePushNotification = /* GraphQL */ `
  mutation DeletePushNotification(
    $input: DeletePushNotificationInput!
    $condition: ModelPushNotificationConditionInput
  ) {
    deletePushNotification(input: $input, condition: $condition) {
      id
      type
      subject
      message
      url
      status
      lob
      branch
      recipientStatus
      approvedBy
      approvedAt
      sentOn
      createdAt
      createdBy
      updatedAt
      __typename
    }
  }
`;
