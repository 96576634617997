import { createSlice } from '@reduxjs/toolkit';

const searchExpensesInitialState = {
  attachedOptions: [
    {
      label: 'All',
      value: 'all'
    },
    {
      label: 'Attached',
      value: 'attached'
    },
    {
      label: 'Not-Attached',
      value: 'not-attached'
    },
  ],
  costOptions: [
    process.env.REACT_APP_MDS_COST_CENTER,
    process.env.REACT_APP_DMS_COST_CENTER
  ],
  form: {
    "status": '',
    "code": '',
    "costCenter": '',
    "branch": '',
    "customerId": '',
    "driverId": '',
    "notes": '',
    "assignmentId": '',
    "endDate": '',
    "createdAt": '',
    "payWeekEnding": '',
    "documentName": '',
    "expenseItemHotelId": '',
    "expenseItemHotelStateId": '',
    "expenseItemHotelCityId": '',
    "expenseItemAirLineId": '',
    "expenseItemCarRentalCompanyId": '',
    "isReceiptAttached": '',
    "lob": '',
  },
  statuses: [
    {
      label: 'Non-billable',
      value: 'Non-billable'
    },
    {
      label: 'Ready to Bill',
      value: 'Ready to Bill'
    },
    {
      label: 'Transmitted',
      value: 'Transmitted'
    },
    {
      label: 'Unbilled',
      value: 'Unbilled'
    },
    {
      label: 'Billed',
      value: 'Billed'
    },
    {
      label: 'Flat Fee Billing',
      value: 'Flat Fee Billing'
    },
    {
      label: 'Voided',
      value: 'Voided'
    },
  ],
};

const searchExpensesFormSlice = createSlice({
  name: 'searchExpensesForm',
  initialState: searchExpensesInitialState,
  reducers: {
    reset: state => {
      Object.assign(state, searchExpensesInitialState);
    },
    setForm: (state, { payload }) => {
      state.form = payload;
    },
  }
});

export default searchExpensesFormSlice.reducer;

export const {
  attachedOptions,
  removeFormField,
  reset,
  costOptions,
  setForm,
  statuses,
} = searchExpensesFormSlice.actions;
