import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import { Form, Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { WorkerCompensationForm } from './Form';
import WorkerCompensationTable, {
  workerCompensationColumns,
  workerCompensationColumnsSummary,
} from './WorkerCompensationTable';
import Excel from '../../../assets/excel.png';
import { Breadcrumbs, EdgeReportingWindow, SubHeader, ReportLoader } from '../../../components';
import { newDateFormatted } from '../../../utils';
import { getStates } from '../../DriverQualification/ListDQ/NewRequest/operations';
import getCSVData from '../csv';
import { generateReport, getList, getReportData, removeReportData, userHasAccess } from '../operations';

import './styles.scoped.css';

const WorkerCompensation = () => {
  const navigate = useNavigate();
  const {
    clientMenu: { menuItems },
  } = useSelector(state => state.edgeReporting);
  const { user } = useSelector(state => state.admin);
  const [data, setData] = useState([]);
  const [branches, setBranches] = useState([]);
  const [WCCodes, setWCCodes] = useState([]);
  const [WCStates, setWCStates] = useState([]);
  const [form, setForm] = useState({ ReportFormat: 'Summary', FilterBy: 'standard' });
  const [loadingResults, setLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isLoadingBranches, setIsLoadingBranches] = useState(false);
  const [isLoadingWCCodes, setIsLoadingWCCodes] = useState(false);
  const [isLoadingWCStates, setIsLoadingWCStates] = useState(false);

  const groupAndSum = (arr, groupKeys, sumKeys) =>
    Object.values(
      arr.reduce((acc, curr) => {
        const group = groupKeys.map(k => curr[k]).join('-');
        acc[group] =
          acc[group] || Object.fromEntries(groupKeys.map(k => [k, curr[k]]).concat(sumKeys.map(k => [k, 0])));
        sumKeys.forEach(k => (acc[group][k] += curr[k]));
        return acc;
      }, {}),
    );

  useEffect(() => {
    if (menuItems) {
      if (!userHasAccess(user, menuItems.find(i => i.to === window.location.pathname)?.id)) navigate('/');
    }
  }, [menuItems]);

  const handleGetBranches = async () => {
    setIsLoadingBranches(true);
    const b = await getList('clients/branches');
    setBranches(b);
    setIsLoadingBranches(false);
  };

  const handleGetWCCodes = async () => {
    setIsLoadingWCCodes(true);
    const wcc = await getList('worker-comp-code');
    setWCCodes(wcc);
    setIsLoadingWCCodes(false);
  };

  const handleGetWCStates = async () => {
    setIsLoadingWCStates(true);
    const wcs = await getStates();
    setWCStates(wcs);
    setIsLoadingWCStates(false);
  };

  useEffect(() => {
    handleGetBranches();
    handleGetWCCodes();
    handleGetWCStates();
  }, []);

  const branchOptions = useMemo(
    () =>
      branches?.map(({ Branch_Code, Branch_Name }) => ({
        label: Branch_Name,
        value: Branch_Code,
      })),
    [branches],
  );

  const WCCodeOptions = useMemo(
    () =>
      WCCodes?.map(({ WKCOMP_CODE, WKCOMP_DESCRIPTION, WKCOMP_STATE }) => ({
        label: `${WKCOMP_STATE} ${WKCOMP_DESCRIPTION}`,
        value: WKCOMP_CODE,
      })),
    [WCCodes],
  );

  const WCStateOptions = useMemo(
    () =>
      WCStates?.map(({ name, id }) => ({
        label: name,
        value: id,
      })).sort((a, b) => (a.label < b.label ? -1 : 1)),
    [WCStates],
  );

  const filteredData = useMemo(
    () =>
      form.FilterBy === 'standard'
        ? data.filter(d => parseFloat(d.WC_Amount) !== 0)
        : form.FilterBy === 'exception'
          ? data.filter(d => parseFloat(d.WC_Amount) === 0)
          : data,
    [form.FilterBy, data],
  );

  const dataToUse = useMemo(
    () =>
      sortBy(filteredData, o =>
        form.ReportFormat === 'Summary' ? [-o.Branch, -o.WC_Code] : [o.LastName, o.FirstName, o.Pay_Code],
      ),
    [filteredData, form.ReportFormat],
  );

  const dataToUseSummary = useMemo(() => {
    if (form.ReportFormat === 'Summary') {
      return groupAndSum(
        dataToUse,
        [
          'COMP_NUMBER',
          'COMP_NAME',
          'Branch',
          'BranchName',
          'WC_Code',
          'WC_State',
          'Start_Check_Date',
          'End_Check_Date',
          'Start_Date_PE',
          'End_Date_PE',
        ],
        [
          'Reg_Hours',
          'Reg_Pay_Amount',
          'Ovt_Hours',
          'Ovt_Pay_Amount',
          'Dbl_Hours',
          'Dbl_Pay_Amount',
          'Extra_Pay_Amount',
          'WC_Amount',
          'Ovt_Pay_Amount_Fac',
          'Dbl_Pay_Amount_Fac',
          'Total_Wages',
        ],
      );
    }
  }, [dataToUse, form.ReportFormat]);

  const fileName = `edge-reporting-worker-comp-${newDateFormatted.toString()}.csv`;

  const handleViewResults = async () => {
    const checkRangeUsed = !!form?.ToCheckdate && !!form?.FromCheckdate;
    const periodRangeUsed = !!form?.ToPerEnddate && !!form?.FromPerEnddate;
    if (!checkRangeUsed && !periodRangeUsed) {
      alert('Please select either Check Date or Period End Date range.');
      return;
    }
    if (
      checkRangeUsed &&
      !(dayjs(form?.ToCheckdate).isAfter(form?.FromCheckdate) || dayjs(form?.FromCheckdate).isSame(form?.ToCheckdate))
    ) {
      alert('Invalid Check Date range. Please double check and try again.');
      return;
    }
    if (
      periodRangeUsed &&
      !(
        dayjs(form?.ToPerEnddate).isAfter(form?.FromPerEnddate) ||
        dayjs(form?.FromPerEnddate).isSame(form?.ToPerEnddate)
      )
    ) {
      alert('Invalid Period End Date range. Please double check and try again.');
      return;
    }
    setLoadingResults(true);
    try {
      const response = await generateReport({
        endpoint: 'worker-comp-report',
        payload: form,
      });
      if (response.location && response.location !== '') {
        const reportData = await getReportData(response.location);
        if (reportData?.length) {
          await removeReportData(response.location);
          setLoadingResults(false);
          setData(reportData);
          setShowResults(true);
        }
      } else {
        setLoadingResults(false);
        alert('No data found matching your criteria.');
      }
    } catch (error) {
      alert('No data found matching your criteria.');
      setLoadingResults(false);
    }
    setLoadingResults(false);
  };

  const WorkerCompensationReportForm = ({
    form,
    setForm,
    branchOptions,
    WCCodeOptions,
    WCStateOptions,
    isLoadingBranches,
    isLoadingWCCodes,
    isLoadingWCStates,
  }) => {
    const handleChange = e => {
      const _form = { ...form, [e.target.name]: e.target.value };
      setForm(_form);
    };
    return (
      <Form
        noValidate
        onSubmit={() => { }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <WorkerCompensationForm
          form={form}
          setForm={setForm}
          handleChange={handleChange}
          branchOptions={branchOptions}
          WCCodeOptions={WCCodeOptions}
          WCStateOptions={WCStateOptions}
          isLoadingBranches={isLoadingBranches}
          isLoadingWCCodes={isLoadingWCCodes}
          isLoadingWCStates={isLoadingWCStates}
        />

        <div className="buttons-row">
          <Button onClick={() => handleViewResults()} style={{ marginRight: '20px', width: '100px' }}>
            <span>VIEW</span>
          </Button>

          <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
            <span>CANCEL</span>
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <>
      <SubHeader
        text="EDGE Reporting"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Worker Compensation report?"
        emailAddress={process.env.REACT_APP_EDGE_REPORTING_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle="Reporting Home"
          middleTitle={showResults && 'Worker Compensation Report'}
          midItemAction={() => setShowResults(false)}
          rightTitle={showResults ? `Viewing Report (${form.ReportFormat})` : 'Worker Compensation Report'}
          route="/edge-reporting"
        />
        {!!data?.length && showResults && (
          <CSVLink
            filename={fileName}
            data={getCSVData(
              form.ReportFormat === 'Summary' ? dataToUseSummary : dataToUse,
              form.ReportFormat === 'Summary' ? workerCompensationColumnsSummary : workerCompensationColumns,
            )}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
        )}
      </div>
      {loadingResults && <ReportLoader didAbort={() => setLoadingResults(false)} />}
      {showResults ? (
        <>
          <WorkerCompensationTable
            data={form.ReportFormat === 'Summary' ? dataToUseSummary : dataToUse}
            loading={loadingResults}
            summaryOrDetail={form.ReportFormat}
            FilterBy={form.FilterBy}
          />
          <div className="buttons-row">
            <Button onClick={() => setShowResults(false)} style={{ marginRight: '20px', width: '100px' }}>
              <span>BACK</span>
            </Button>

            <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
              <span>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <EdgeReportingWindow
          containerStyle={{
            border: '2px solid #343a40',
          }}
          windowContent={WorkerCompensationReportForm({
            form,
            setForm,
            branchOptions,
            WCCodeOptions,
            WCStateOptions,
            isLoadingBranches,
            isLoadingWCCodes,
            isLoadingWCStates,
          })}
          windowTitle="Criteria"
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )}
    </>
  );
};

export default WorkerCompensation;
