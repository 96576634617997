import { graphqlOperation, API } from 'aws-amplify';
import { getUser } from '../../../graphql/customQueries'
import * as mutations from '../../../graphql/mutations';

export const getAccessList = async (id) => {
    try {
        const body = await API.graphql({
            query: getUser,
            variables: { id }
        });
        return body?.data?.getUser.appAccess.items;
    } catch (e) {
        console.log('GraphQL getUserApps Error:', e);
    }
    return null;
};

export const addAppAccess = async (payload) => {
    try {
        const body = await API.graphql(
            graphqlOperation(mutations.createUserApplicationAccess, {
                input: payload
            })

        );
        return body;
    } catch (e) {
        console.log('GraphQL createUserApplicationAccess Error:', e);
    }
    return null;
};

export const editAppAccess = async (payload) => {
    try {
        const body = await API.graphql(
            graphqlOperation(mutations.updateUserApplicationAccess, {
                input: payload
            })

        );
        return body;
    } catch (e) {
        console.log('GraphQL createUserApplicationAccess Error:', e);
    }
    return null;
};

export const deleteAppAccess = async (input) => {
    try {
        const body = await API.graphql(
            graphqlOperation(mutations.deleteUserApplicationAccess, {
                input
            })

        );
        return body;
    } catch (e) {
        console.log('GraphQL deleteUserApplicationAccess Error:', e);
    }
    return null;
};
