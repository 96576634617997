import React, { useState, useRef } from 'react';
import { Auth } from 'aws-amplify';

import PasswordRules from '../passwordRules';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import '../styles.scoped.css';

const ForgotPasswordSubmit = ({ nextStep, setRequiredAttributes, userData }) => {
    const [passwordCharCount, setPasswordCharCount] = useState(0);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [error, setErrorMessage] = useState('');

    const forgotPasswordSubmit = payload => {
        Auth.forgotPasswordSubmit(payload?.username, payload?.code, payload?.password).then(response => {
            if (response === 'SUCCESS') {
                signIn(payload);
            }
        }).catch((e) => {
            console.log('e', e);
            if (e.code === 'ExpiredCodeException') {
                setErrorMessage(e.code);
            }
            if (e.code === 'CodeMismatchException') {
                setErrorMessage(e.code);
            }
            if (e.code === 'LimitExceededException') {
                setErrorMessage(e.code);
            }
            if (e.message === 'Confirmation code cannot be empty') {
                setErrorMessage(e.message);
            }
        });
    };

    const signIn = payload => {
        Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
        Auth.signIn(payload?.username, payload?.password)
            .then(user => {
                if (user.challengeName === 'CUSTOM_CHALLENGE') {
                    if (user.challengeParam.customMfaChallenge === 'PICK_MFA_FACTOR') {
                        let requiredAttributes = JSON.parse(user.challengeParam.publicChallengeParameters);
                        setRequiredAttributes(requiredAttributes);
                        nextStep({
                            ...userData,
                            nextStep: 'MFACode',
                            user: user
                        });
                    }
                }
            })
            .catch(e => {
                console.log('e: ', e);
                if (e.code === 'LimitExceededException') {
                    setErrorMessage(e.code);
                }
                if (e.code === 'ExpiredCodeException') {
                    setErrorMessage(e.code);
                }
            });
    };

    return (
        <>
            {error === 'LimitExceededException' || error === 'NotAuthorizedException' || error === 'ExpiredCodeException' ? (
                <Container fluid>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-center mt-5 w-25">
                            {error === 'LimitExceededException' && (
                                <p className="border border-danger m-2 p-1 text-center text-danger">{'Attempt limit exceeded, please try after some time.'}</p>
                            )}
                            {error === 'NotAuthorizedException' && (
                                <p className="border border-danger m-2 p-1 text-center text-danger">{'Invalid user, please try again.'}</p>
                            )}
                            {error === 'ExpiredCodeException' && (
                                <p className="border border-danger m-2 p-1 text-center text-danger">{'Invalid code provided, please request a code again'}</p>
                            )}
                        </Col>
                        <Col xs={12} className="d-flex align-items-center justify-content-center mb-4">
                            <Button
                                onClick={() => {
                                    window.location.reload();
                                }}
                                type="button"
                                className="btn-md">
                                Try Again
                            </Button>
                        </Col>
                    </Row>
                </Container>

            ) : (
                <Container>
                    <div className="password-border">
                        <img style={{ width: '370px', marginBottom: '35px' }} src={require('../../../assets/cl_login.jpg')} />
                        <div className="pt-3" style={{ width: '358px' }}>
                            <Form.Group>
                                <Form.Label>
                                    <div className="mb-2">{`
                            We have sent a password reset code by ${userData?.user?.CodeDeliveryDetails?.AttributeName === 'phone_number' ? 'phone' : userData?.user?.CodeDeliveryDetails?.AttributeName} to 
                            ${userData?.CodeDeliveryDetails?.Destination}. Enter it below to reset your password.`}
                                        <br />
                                    </div>
                                </Form.Label>
                                {error === 'CodeMismatchException' && (
                                    <p className="border border-danger p-2 text-danger">Invalid verification code provided, please try <br />again</p>
                                )}
                                {error === 'Confirmation code cannot be empty' && (
                                    <p className="border border-danger p-2 text-danger">Confirmation code cannot be empty</p>
                                )}
                                <Form.Group>
                                    <Form.Label
                                        for="code">
                                        <strong>Code</strong>
                                    </Form.Label>
                                    <Form.Control
                                        as="input"
                                        type='password'
                                        name="code"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        spellCheck="false"
                                        defaultValue=""
                                        onChange={() => setErrorMessage('')}
                                        onBlur={event => {
                                            nextStep({
                                                ...userData,
                                                code: event.target.value,
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        <strong>New Password</strong>
                                    </Form.Label>
                                    <Form.Control
                                        as="input"
                                        name="password"
                                        defaultValue={userData?.password}
                                        placeholder=""
                                        type='password'
                                        onChange={event => {
                                            setPasswordCharCount(event.target.value.length);
                                            (event.target.value === userData?.newPassword) ?
                                                setPasswordMatch(true) : setPasswordMatch(false);
                                            nextStep({
                                                ...userData,
                                                password: event.target.value,
                                            });
                                        }}
                                        onBlur={event => {
                                            nextStep({
                                                ...userData,
                                                password: event.target.value,
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        <strong>Enter New Password Again</strong>
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        as="input"
                                        name="newPassword"
                                        defaultValue={userData?.newPassword}
                                        placeholder=""
                                        type='password'
                                        onChange={(e) => {
                                            setPasswordCharCount(e.target.value.length);
                                            (e.target.value === userData?.password) ?
                                                setPasswordMatch(true) : setPasswordMatch(false);

                                        }}
                                        onBlur={event => {
                                            nextStep({
                                                ...userData,
                                                newPassword: event.target.value,
                                            });
                                        }}
                                    />
                                </Form.Group>
                                {passwordCharCount > 0 &&
                                    <PasswordRules
                                        password={userData?.password}
                                        newPassword={userData?.newPassword}
                                        passwordCharCount={passwordCharCount}
                                        passwordMatch={passwordMatch}
                                        setInvalidPassword={setInvalidPassword}
                                    />
                                }
                                <Button
                                    disabled={!invalidPassword}
                                    className="w-100 mt-2"
                                    onClick={() => {
                                        forgotPasswordSubmit(userData);
                                    }}>
                                    Change Password
                                </Button>
                            </Form.Group>
                        </div>
                    </div>
                </Container>
            )}</>
    )
}

export default ForgotPasswordSubmit;