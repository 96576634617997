import React, { useState, useEffect } from 'react';

import { Radio } from '@mui/material';
import { API } from 'aws-amplify';
import { Button, Col, Container, Dropdown, Form, Row, Spinner } from 'react-bootstrap';
import { MdHome } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import {
  setTier,
  setDriver,
  setClient,
  setJob,
  resetData,
  setBranch,
  setLocationState,
  setLocationCity,
} from './newRequestSlice';
import { createDQ, getClientWithChecklist, getStates } from './operations';
import { Breadcrumbs, SubHeader, InputBox, ModalConfirmation } from '../../../../components';
import { getCitiesFiltered } from '../../../ExpenseEntry/DriverSolutions/Hotel/operations';

import './styles.scoped.css';

function tabsBorderRadius() {
  // eslint-disable-next-line no-undef
  document.querySelectorAll('.rounded-top-left').forEach(elem => {
    elem.style.borderTopLeftRadius = '15px';
  });
  // eslint-disable-next-line no-undef
  document.querySelectorAll('.rounded-top-right').forEach(elem => {
    elem.style.borderTopRightRadius = '15px';
  });
}

const NewRequest = () => {
  const { id, name } = useSelector(state => state.admin.user);
  const { tierData, driver, client, job, tier, branch, locationState, locationCity } = useSelector(
    state => state.newRequest,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createDqUserName = `${name}`;
  const [showForm, setShowForm] = useState(false);
  const [postSuccess, setPostSuccess] = useState(false);
  const [dqId, setDqId] = useState();
  const apiName = 'centerlineportal';
  const [locationData, setLocationData] = useState();
  const [searching, setSearching] = useState(false);
  const [loadingCitiesData, setLoadingCitiesData] = useState(false);
  const [citiesError, setCitiesError] = useState(false);
  const [checklistError, setChecklistError] = useState(false);
  const [checkListLevel, setCheckListLevel] = useState(null);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-undef
  window.onpopstate = () => {
    clearForm();
  };
  const clearCityData = () => {
    dispatch(
      setLocationCity({
        cityId: '',
        cityName: '',
        isValid: true,
      }),
    );
    setLocationData({
      ...locationData,
      cities: [],
    });
  };

  const getAndSetFormData = async () => {
    const states = await getStates();
    if (states) {
      setLocationData({ ...locationData, states: states });
    }
  };

  let currentCities = [];
  let prevToken = '';

  const getCitiesBatch = async (nextToken = null) => {
    setLoadingCitiesData(true);
    if (prevToken !== nextToken) {
      prevToken = nextToken;
      const data = await getCitiesFiltered({
        state: locationState.stateId,
        token: nextToken,
      });
      const token = data?.nextToken;

      if (data?.items) {
        const items = data?.items;
        currentCities = currentCities.concat(items);
        if (data?.items.length === 0) {
          setCitiesError(true);
        }
      }
      if (token) {
        getCitiesBatch(token);
      } else {
        dispatch(
          setLocationCity({
            cityId: '',
            cityName: '',
            isValid: true,
          }),
        );
        setLocationData({ ...locationData, cities: currentCities });
      }
    }
    setLoadingCitiesData(false);
  };

  const handleStateChange = async () => {
    setCitiesError(false);
    setLoadingCitiesData(true);
    await getCitiesBatch();
    setLoadingCitiesData(false);
  };

  useEffect(() => {
    if (locationState.stateId) {
      handleStateChange();
    }
  }, [locationState.stateId]);

  const stateOptions = locationData?.states
    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(({ name: aName, id: anId }) => ({
      label: aName,
      value: anId,
    }));

  const cityOptions = locationData?.cities
    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(({ name: aName, id: anId }) => ({
      label: aName,
      value: anId,
    }));

  const customStyles = width => ({
    control: provided => ({
      ...provided,
      width: width,
      height: '30px',
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: '160px',
      width: width,
    }),
    container: provided => ({
      ...provided,
      width: width,
    }),
    menu: provided => ({
      ...provided,
      width: width,
    }),
  });

  useEffect(() => {
    getAndSetFormData();
  }, []);

  const homeButton = () => {
    clearForm();
    navigate('../driver-qualification', { replace: true });
  };

  const fetchDriver = async data => {
    if (!data.driverId) {
      dispatch(
        setDriver({
          ...driver,
          isValid: false,
        }),
      );
      return;
    }
    setSearching(true);
    const path = `drivers/${data.driverId}`;
    try {
      const response = await API.get(apiName, path);
      if (response.response_code === 200) {
        const result = response.response_body;
        dispatch(
          setDriver({
            ...driver,
            isValid: true,
            name: `${result.driverFirstName} ${result.driverLastName}`,
            status: result.driverStatus,
            skills: result.driverSkills,
            cityState: `${result.driverCity}, ${result.driverState}`,
            disable: true,
            fetchSuccess: true,
          }),
        );
        setShowForm(true);
      }
    } catch (e) {
      dispatch(
        setDriver({
          ...driver,
          isValid: false,
          disable: false,
          fetchSuccess: false,
        }),
      );
    }
    setSearching(false);
  };

  const detectCheckListLevel = async info => {
    if (info?.clientId) {
      const [singleClient, cldClient, amcClient] = await Promise.all([
        getClientWithChecklist({ clientId: info?.clientId, checkListLevel: 'Single Client' }),
        getClientWithChecklist({ clientId: 0, checkListLevel: 'CLD Default' }),
        getClientWithChecklist({
          clientId: 0,
          checkListLevel: 'AMC',
          accountMasterCode: info?.clientAccountMasterCode,
        }),
      ]);

      if (singleClient || cldClient || amcClient) {
        if (singleClient) {
          setCheckListLevel('Single Client');
          dispatch(setTier({ ...tier, isValid: false }));
        } else if (amcClient) {
          setCheckListLevel('AMC');
          dispatch(setTier({ ...tier, isValid: true, isSelected: '', value: 'Select...' }));
        } else {
          setCheckListLevel('CLD Default');
          dispatch(setTier({ ...tier, isValid: true, isSelected: '', value: 'Select...' }));
        }
        setChecklistError(false);
      } else {
        setCheckListLevel(null);
        setChecklistError(true);
      }
    } else {
      setCheckListLevel(null);
    }
  };

  const fetchClient = async data => {
    setLoading(true);
    const path = `clients/${data.clientId}`;
    let newClient = {
      ...client,
      clientAccountMasterCode: null,
      clientId: '',
      status: '',
      name: '',
      isValid: false,
      fetchSuccess: false,
    };
    try {
      const response = await API.get(apiName, path);
      if (response.response_code === 200) {
        const result = response.response_body;
        newClient = {
          ...client,
          clientAccountMasterCode: result.clientAccountMasterCode,
          clientId: result.clientId,
          status: result.clientStatus,
          name: result.clientName,
          isValid: true,
          fetchSuccess: true,
        };
        dispatch(setClient(newClient));
      }
    } catch (e) {
      dispatch(setClient(newClient));
    }
    await detectCheckListLevel(newClient);
    setLoading(false);
  };

  const fetchJobOrder = async data => {
    const path = `job-orders/${data.jobOrderId}`;
    try {
      const response = await API.get(apiName, path);
      if (response.response_code === 200) {
        const result = response.response_body;
        dispatch(
          setJob({
            ...job,
            startDate: result.jobStartDate,
            status: result.jobStatus,
            isValid: true,
            fetchSuccess: true,
          }),
        );
      }
    } catch (e) {
      dispatch(
        setJob({
          ...job,
          startDate: '',
          status: '',
          isValid: false,
          fetchSuccess: false,
        }),
      );
    }
  };

  const fetchNewDqId = async () => {
    const path = '/dqf-ids';
    try {
      const response = await API.get(apiName, path, {
        body: {},
      });
      if (response) {
        setDqId(response?.response_body.id);
        return response?.response_body.id;
      }
      return undefined;
    } catch (error) {
      console.log('Fetch new DQ Id error:', error);
      return undefined;
    }
  };

  const assignTier = value => {
    dispatch(setTier({ ...tier, isValid: true, isSelected: value, value: value }));
  };

  const clearForm = () => {
    dispatch(resetData());
    setShowForm(false);
    setPostSuccess(false);
  };

  const tierIsValid = () => {
    let isValid = false;
    if (tier.value !== 'Select...' || checkListLevel !== 'Single Client') {
      isValid = true;
      if (tier.value === '2') {
        if (tier.description) {
          isValid = true;
        } else {
          isValid = false;
        }
      }
    }

    return isValid;
  };

  const isValid = async () => {
    setLoading(true);
    if (
      driver.fetchSuccess &&
      client.fetchSuccess &&
      branch?.branchId &&
      tierIsValid() &&
      locationCity.cityId &&
      locationState.stateId
    ) {
      await postDQ();
    } else {
      if (!client.fetchSuccess) {
        dispatch(setClient({ ...client, isValid: false }));
      }

      if (!locationState.stateId) {
        dispatch(setLocationState({ ...locationState, isValid: false }));
      }

      if (!locationCity.cityId) {
        dispatch(
          setLocationCity({
            ...locationCity,
            isValid: false,
          }),
        );
      }

      if (!branch?.branchId) {
        dispatch(
          setBranch({
            ...branch,
            branchId: '',
            isValid: false,
          }),
        );
      }
    }

    setLoading(false);
  };

  const postDQ = async () => {
    const adqId = await fetchNewDqId();

    try {
      const response = await createDQ(
        driver,
        client,
        job,
        tier.value === 'Select...' && checkListLevel !== 'Single Client' ? { value: 0 } : tier,
        adqId,
        id,
        createDqUserName,
        branch,
        locationState,
        locationCity,
        checkListLevel,
      );
      if (response.data) {
        setPostSuccess(true);
      }
    } catch (e) {
      console.log('POST DQ ERROR', e);
    }
  };

  useEffect(() => {
    tabsBorderRadius();
  }, []);

  return (
    <>
      <SubHeader
        text="Centerline Driver Qualification"
        btnSmall={<MdHome size={28} color="#FFF" />}
        btnInfo
        smallAction={homeButton}
        emailAddress={process.env.REACT_APP_DQF_CONTACT_EMAIL}
        infoText="Need help with your driver qualification?"
      />
      <Breadcrumbs
        leftTitle="DQF Home"
        midItemAction={() => {
          setShowForm(false);
          dispatch(
            setDriver({
              ...driver,
              driverId: '',
              isValid: true,
              disable: false,
            }),
          );
        }}
        middleTitle={'Select Driver\u00A0'}
        rightTitle={driver.driverId && 'New Request'}
        route="/driver-qualification"
      />
      <Container fluid style={{ height: '100vh' }}>
        <Container className="mw-100 p-5">
          <p className="text-center rounded-top-left rounded-top-right p-2 text-white bg-primary mx-100 m-0">
            Request DQF
          </p>
          <div className="border border-primary">
            <div className="form-group">
              <Form noValidate onSubmit={() => { }}>
                <Form.Group controlId="formRequestDq">
                  <Row>
                    <Col className="bold-column">Driver Information</Col>
                  </Row>
                  <Row className="padding-row">
                    <InputBox
                      title="Driver Id"
                      style={{ width: '50%' }}
                      value={driver.driverId}
                      span
                      onChange={e => {
                        dispatch(
                          setDriver({
                            ...driver,
                            driverId: e.target.value,
                            isValid: true,
                          }),
                        );
                      }}
                      disabled={driver.disable || searching}
                      errorMessage="Please enter a valid Driver ID"
                      error={!driver.isValid}
                    />
                    {showForm && (
                      <>
                        <div className="card-body">
                          <p>Driver Name</p>
                          <div className="gray-text">{driver.name}</div>
                        </div>
                        <div className="card-body">
                          <p>Driver Status</p>
                          <div className="gray-text">{driver.status}</div>
                        </div>
                      </>
                    )}
                  </Row>
                  {!showForm && (
                    <Row className="padding-row">
                      <Col>
                        <div className="btn-center">
                          <Button className="btn" onClick={() => fetchDriver(driver)}>
                            {searching ? <Spinner animation="border" size="sm" style={{ margin: 24 }} /> : 'Search'}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {showForm && (
                    <>
                      <Row className="padding-row">
                        <Col>City, State</Col>
                        <Col>Skills</Col>
                      </Row>
                      <Row>
                        <Col className="gray-text">{driver?.cityState}</Col>
                        <Col className="scroll-list">
                          {driver.skills.map(item => (
                            <div className="gray-text" key={item?.id}>
                              {`${item?.skillName}: ${item?.skillDescription}`}
                            </div>
                          ))}
                        </Col>
                      </Row>

                      <Row className="pt-3 pb-3">
                        <Col className="font-weight-bold">Client Information</Col>
                      </Row>
                      <Row className="pt-1">
                        <InputBox
                          title="Client Id"
                          style={{ width: '50%' }}
                          value={client.clientId}
                          span
                          onChange={e => {
                            dispatch(
                              setClient({
                                ...client,
                                clientId: e.target.value,
                                isValid: true,
                              }),
                            );
                          }}
                          onBlur={() => fetchClient(client)}
                          errorMessage="Please enter a valid Client ID"
                          error={!client.isValid}
                        />
                        <Col>
                          <div>Client Name</div>
                          <div className="text-secondary">{client.name}</div>
                        </Col>
                        <Col>
                          <div>Client Status</div>
                          <div className="text-secondary">{client.status}</div>
                        </Col>
                      </Row>
                      <br />
                      <Row className="pt-1">
                        <InputBox
                          title="Job Order"
                          style={{ width: '50%' }}
                          value={job.jobOrderId}
                          onChange={e => {
                            dispatch(
                              setJob({
                                ...job,
                                jobOrderId: e.target.value,
                                isValid: true,
                              }),
                            );
                          }}
                          onBlur={() => fetchJobOrder(job)}
                          errorMessage="Please enter a valid Job Order ID"
                          error={!job.isValid}
                        />
                        <Col>
                          <div>Job Start Date</div>
                          <div className="text-secondary">{job.startDate}</div>
                        </Col>
                        <Col>
                          <div>Job Status</div>
                          <div className="text-secondary">{job.status}</div>
                        </Col>
                      </Row>
                      <Row>
                        <InputBox
                          title="Branch"
                          style={{ width: '50%' }}
                          value={branch?.branchId}
                          span
                          onChange={e => {
                            const re = /^[0-9\b]+$/;

                            if (e.target.value === '' || re.test(e.target.value)) {
                              dispatch(
                                setBranch({
                                  ...branch,
                                  branchId: e.target.value,
                                  isValid: true,
                                }),
                              );
                            }
                          }}
                          errorMessage="Please enter a valid Branch ID"
                          error={!branch?.isValid}
                        />
                      </Row>
                      <Row>
                        <div className="col">
                          <Form.Group controlId="locationState">
                            <Form.Label
                              style={{
                                marginTop: '15px',
                              }}>
                              <div className="required"> State</div>
                            </Form.Label>
                            <div className="dropdown-spinner-container">
                              <Select
                                isClearable
                                styles={customStyles('400px')}
                                options={stateOptions}
                                onChange={(event, action) => {
                                  if (action.action === 'clear') {
                                    dispatch(
                                      setLocationState({
                                        stateId: '',
                                        stateName: '',
                                        isValid: false,
                                      }),
                                    );
                                    clearCityData();
                                  }
                                  if (action.action === 'select-option') {
                                    dispatch(
                                      setLocationState({
                                        stateId: event.value,
                                        stateName: event.label,
                                        isValid: true,
                                      }),
                                    );
                                    clearCityData();
                                  }
                                }}
                                value={stateOptions?.filter(state => state.value === locationState.stateId)}
                              />
                            </div>
                            {!locationState.isValid && <div className="error">Please select a State</div>}
                          </Form.Group>
                        </div>
                        <div className="col">
                          <Form.Group controlId="locationCity">
                            <Form.Label
                              style={{
                                marginTop: '15px',
                              }}>
                              <div className="required"> City</div>
                            </Form.Label>

                            <div className="dropdown-spinner-container">
                              <div className="filter-row">
                                <Select
                                  isClearable
                                  defaultInputValue={locationCity.cityName}
                                  isDisabled={!locationState?.stateId || !locationData?.cities?.length}
                                  styles={customStyles('400px')}
                                  options={cityOptions}
                                  onChange={(event, action) => {
                                    if (action.action === 'clear') {
                                      dispatch(
                                        setLocationCity({
                                          cityId: '',
                                          cityName: '',
                                          isValid: false,
                                        }),
                                      );
                                    }
                                    if (action.action === 'select-option') {
                                      dispatch(
                                        setLocationCity({
                                          cityId: event.value,
                                          cityName: event.label,
                                          isValid: true,
                                        }),
                                      );
                                    }
                                  }}
                                  value={cityOptions?.filter(city => city.value === locationCity.cityId)}
                                />
                                <Spinner
                                  style={{ marginLeft: '10px' }}
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  aria-hidden="true"
                                  className="dropdown-spinner"
                                  hidden={(!loadingCitiesData && cityOptions?.length !== 0) || citiesError}
                                />
                              </div>
                              {!locationCity.isValid && <div className="error">Please select a City</div>}
                              {citiesError && <div className="error">No Cities available</div>}
                            </div>
                          </Form.Group>
                        </div>
                      </Row>
                      <Row>
                        <Col className="font-weight-bold">Checklist Level</Col>
                      </Row>
                      <Row style={{ height: 100 }}>
                        <Col>
                          <Row>
                            <span className="radio-group mr-2 ml-2 mt-2">
                              <Radio
                                style={{
                                  color: '#da0f2e',
                                  transform: 'scale(1)',
                                }}
                                checked={checkListLevel === 'CLD Default'}
                                disabled={checkListLevel !== 'CLD Default'}
                              />
                              <span style={{ color: checkListLevel === 'CLD Default' ? 'black' : 'gray' }}>
                                CLD Default
                              </span>
                            </span>
                            <span className="radio-group mr-2 ml-2 mt-2">
                              <Radio
                                style={{
                                  color: '#da0f2e',
                                  transform: 'scale(1)',
                                }}
                                checked={checkListLevel === 'AMC'}
                                disabled={checkListLevel !== 'AMC'}
                              />
                              <span style={{ color: checkListLevel === 'AMC' ? 'black' : 'gray' }}>AMC</span>
                            </span>
                            <span className="radio-group mr-2 ml-2 mt-2">
                              <Radio
                                style={{
                                  color: '#da0f2e',
                                  transform: 'scale(1)',
                                }}
                                checked={checkListLevel === 'Single Client'}
                                disabled={checkListLevel !== 'Single Client'}
                              />
                              <span style={{ color: checkListLevel === 'Single Client' ? 'black' : 'gray' }}>
                                Single Client
                              </span>
                            </span>
                          </Row>
                        </Col>
                        {checkListLevel === 'AMC' && (
                          <Col>
                            <p style={{ fontWeight: 'bold' }}>AMC Code</p>
                            <div className="value">{client?.clientAccountMasterCode}</div>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col className="font-weight-bold">Driver Qualification Tier</Col>
                      </Row>
                      <Row>
                        <Col
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}>
                          <div style={{ width: '50%' }}>
                            <Form.Label>
                              Tier <span>*</span>
                            </Form.Label>
                            <Dropdown
                              style={{ width: '100%' }}
                              onSelect={eventKey => {
                                assignTier(eventKey);
                              }}>
                              <Dropdown.Toggle
                                style={{ width: '80%' }}
                                className="p-2"
                                disabled={checkListLevel !== 'Single Client'}>
                                {tier.value !== 'Select...'
                                  ? tierData.find(tierD => tierD.value.toString() === tier.value.toString())?.label
                                  : 'Select...'}
                              </Dropdown.Toggle>
                              <Dropdown.Menu style={{ width: '80%' }}>
                                {tierData.map(item => (
                                  <Dropdown.Item eventKey={item.value} key={item.value}>
                                    {item.label}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                            {!tier.isValid && (
                              <Row className="invalid-input">
                                <Col>Please select a Tier</Col>
                              </Row>
                            )}
                          </div>
                          {tier.value === '2' && (
                            <InputBox
                              title="Other Description"
                              style={{ width: '40%' }}
                              value={tier.description}
                              onChange={e => {
                                dispatch(
                                  setTier({
                                    ...tier,
                                    description: e.target.value,
                                  }),
                                );
                              }}
                              errorMessage="Please enter a valid description"
                              error={!tier.description}
                            />
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                  {postSuccess && (
                    <ModalConfirmation
                      title="Success!"
                      body={`Driver Qualification ${dqId} requested successfully.`}
                      primaryButtonTitle="Close"
                      onClick={() => {
                        clearForm();
                        navigate('/driver-qualification', { replace: true });
                      }}
                      isOpen={postSuccess}
                      primaryButton
                    />
                  )}
                </Form.Group>
              </Form>
            </div>
            {showForm && (
              <div className="btn-wrapper">
                <Button
                  className="btn"
                  onClick={() => {
                    isValid();
                  }}
                  disabled={loading}>
                  {loading ? <Spinner animation="border" size="sm" style={{ margin: 24 }} /> : 'Request'}
                </Button>
              </div>
            )}
          </div>
          <ModalConfirmation
            isOpen={checklistError}
            title="Checklist Error"
            onClick={() => {
              setChecklistError(false);
              setCheckListLevel(null);
              dispatch(
                setClient({
                  ...(client || {}),
                  clientAccountMasterCode: null,
                  clientId: '',
                  status: '',
                  name: '',
                  isValid: false,
                  fetchSuccess: false,
                }),
              );
            }}
            primaryButton
            primaryButtonTitle="OK"
            body="The user selected has no checklist defined. A checklist must be defined to create a new DQF request."
          />
        </Container>
      </Container>
    </>
  );
};

export default NewRequest;
