import React, { useEffect, useCallback, useState } from 'react';

import { Checkbox } from '@mui/material';
import { debounce } from 'lodash';
import { Row, Form, InputGroup, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Receipt from './Receipt';
import ExpenseForm from '../../../../components/ExpenseForm';
import { newDateFormatted } from '../../../../utils';
import { checkClientFlatFee } from '../../DriverSolutions/operations';
import { preventMultipleSave } from '../TransferToUltraStaff/utils';

import './styles.scoped.css';

const FormContent = ({
  id,
  hasChanges,
  setShowCancelDialog,
  setHasChanges,
  setForm,
  form,
  setSaveDisabled,
  confirmCreateExpense,
  receiptsHaveFiles,
  setShowReceiptFilesDialog,
  setReceipts,
  receipts,
  totalToBeBilled,
  auditKey,
  isCreatingItems,
  isLoading,
  saveDisabled,
  receiptsErrors,
  setUploading,
  setUploadError,
  formHasChanged,
  setShowFFDialog,
  setShowNonFFDialog,
  requiredReceiptFields,
  setReceiptsErrors,
  formData,
  deleteExpenseFile,
  currentUser,
  assignmentLineOfBusinessItems,
}) => {
  const navigate = useNavigate();

  const [errors, setErrors] = useState();

  const handleCancel = async () => (hasChanges ? setShowCancelDialog(true) : navigate(assignmentLineOfBusinessItems.to));

  const setField = (fields, value) => {
    if (!hasChanges) {
      setHasChanges(true);
    }
    if (Array.isArray(fields)) {
      const itemsToAdd = {};
      const newErrors = {
        ...errors,
      };
      fields.forEach(field => {
        itemsToAdd[(field?.fieldName)] = field.fieldValue;

        if (errors && !!errors[field.fieldName]) {
          delete newErrors[(field?.fieldName)];
        }
      });
      setForm({
        ...form,
        ...itemsToAdd,
      });
      setErrors({
        ...newErrors,
      });
    } else {
      if (fields === 'nonBillable' && value === true) {
        setForm({
          ...form,
          [fields]: value,
          status: 'Non-billable',
        });
      } else if (fields === 'nonBillable' && value === false) {
        setForm({
          ...form,
          [fields]: value,
          status: 'Unbilled',
        });
      } else if (fields === 'flatFeeBilling' && value === false) {
        setForm({
          ...form,
          [fields]: value,
          nonBillable: false,
          status: 'Unbilled',
          code: 'XA',
        });
      } else if (fields === 'flatFeeBilling' && value === true) {
        setForm({
          ...form,
          [fields]: value,
          nonBillable: true,
          status: 'Flat Fee Billing',
          code: 'XFFBA',
        });
      } else {
        setForm(prevState => ({
          ...prevState,
          [fields]: value,
        }));
      }

      if (errors && !!errors[fields]) {
        const errorsCopy = {
          ...errors,
        };
        delete errorsCopy[fields];
        setErrors(errorsCopy);
      }
    }
  };

  const handleCreateExpense = async () => {
    preventMultipleSave(setSaveDisabled);
    if (fieldsAreValid()) {
      if (form?.status === 'Non-billable') {
        await confirmCreateExpense({ route: '' });
      } else if (receiptsHaveFiles()) {
        await handleVerifyConfirm();
      } else {
        setShowReceiptFilesDialog(true);
      }
    }
  };

  const handleAddReceipt = () => {
    setReceipts([...receipts, { markup: '0' }]);
  };

  const setReceiptField = (id, field, value, scopedReceipts) => {
    const receiptsCopy = [...scopedReceipts];
    if (field === 'receiptAmount') {
      if (receiptsCopy[id]?.markup) {
        const multiplier = (1 + parseFloat(receiptsCopy[id]?.markup).toFixed(2) / 100).toFixed(2);

        receiptsCopy[id].expenseTotal = parseFloat(value) * multiplier;
      } else {
        receiptsCopy[id].expenseTotal = value;
      }
    }
    if (field === 'markup' && receiptsCopy[id]?.receiptAmount) {
      const multiplier = (1 + parseFloat(value).toFixed(2) / 100).toFixed(2);

      receiptsCopy[id].expenseTotal = parseFloat(receiptsCopy[id]?.receiptAmount) * multiplier;
    }
    receiptsCopy[id][field] = value;
    setReceipts(receiptsCopy);
  };

  const setDebouncedValue = debounce(r => {
    setReceiptField(r.id, r.field, r.value, r.receipts);
  }, 2000);

  const debouncedSetReceiptField = useCallback(value => setDebouncedValue(value), []);

  const handleVerifyConfirm = async () => {
    const clientIsFF = await checkClientFlatFee(form?.customerId);
    if (clientIsFF && form?.flatFeeBilling) {
      await confirmCreateExpense({ isFF: true });
    }
    if (clientIsFF && !form?.flatFeeBilling) {
      setShowFFDialog(true);
    }
    if (!clientIsFF && form?.flatFeeBilling) {
      setShowNonFFDialog(true);
    }
    if (!clientIsFF && !form?.flatFeeBilling) {
      await confirmCreateExpense({
        route: assignmentLineOfBusinessItems.to,
      });
    }
  };

  const setReceiptFields = (id, fields) => {
    const receiptsCopy = [...receipts];

    fields.forEach(field => {
      receiptsCopy[id][(field?.fieldName)] = field.fieldValue;
    });
    setReceipts(receiptsCopy);
  };

  const fieldsAreValid = () => {
    let formErrorsCopy = {
      ...errors,
    };
    const receiptsErrorsCopy = [...receiptsErrors];
    const requiredFields = [
      'code',
      'driverId',
      'driverName',
      'assignmentId',
      'status',
      'customerId',
      'customerName',
      'branch',
    ];

    requiredFields.forEach(field => {
      if (!form[field] || form[field] === '' || form[field] === '--') {
        formErrorsCopy = {
          ...formErrorsCopy,
          [field]: 'Please provide a valid value.',
        };
      }
    });

    receipts.forEach((r, index) => {
      requiredReceiptFields.forEach(rf => {
        if (rf === 'markup' || rf === 'receiptAmount') {
          if (r[rf] === '') {
            receiptsErrorsCopy[index] = {
              ...receiptsErrorsCopy[index],
              [rf]: 'Please provide a valid value.',
            };
          }
        } else if (!r[rf] || r[rf] === '' || r[rf] === '--') {
          receiptsErrorsCopy[index] = {
            ...receiptsErrorsCopy[index],
            [rf]: 'Please provide a valid value.',
          };
        }
      });
    });

    setReceiptsErrors(receiptsErrorsCopy);
    setErrors(formErrorsCopy);

    return !receiptsErrorsCopy.length && !Object.keys(formErrorsCopy).length;
  };

  const paymentValues = [
    {
      name: 'Adelman',
      id: 'Adelman',
    },
    {
      name: 'Credit Card',
      id: 'Credit Card',
    },
  ];

  const airlineOptions = formData?.airlines
    ?.filter(a => a.status === 'Active')
    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(({ name, id }) => ({
      label: name,
      value: id,
    }));

  const airportOptions = formData?.airports
    ?.filter(a => a.status === 'Active')
    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(({ name, id }) => ({
      label: `${name} (${id})`,
      value: id,
    }));

  const paymentOptions = paymentValues?.map(({ name, id }) => (
    <option key={name} value={id}>
      {name}
    </option>
  ));

  const handleDeleteRow = async id => {
    if (receipts[id].documentName) await deleteExpenseFile(receipts[id].documentName);
    const receiptsCopy = receipts.filter((r, index) => index !== id);
    setReceipts(receiptsCopy);
  };

  const setReceiptFile = async ({ id, fileName, fileSize }) => {
    const receiptsCopy = [...receipts];
    receiptsCopy[id].uploadedDate = new Date().toISOString();
    receiptsCopy[id].documentName = fileName;
    receiptsCopy[id].documentSize = Math.round(fileSize);
    receiptsCopy[id].uploadedByName = currentUser?.name;

    const receiptsErrorsCopy = [...receiptsErrors];

    if (receiptsErrorsCopy[id]?.uploadedDate) delete receiptsErrorsCopy[id].uploadedDate;

    if (receiptsErrorsCopy[id]?.documentName) delete receiptsErrorsCopy[id].documentName;

    if (receiptsErrorsCopy[id]?.uploadedByName) delete receiptsErrorsCopy[id].uploadedByName;

    if (receiptsErrorsCopy.length) {
      receiptsErrorsCopy.splice(id, 1);
    }

    setReceiptsErrors(receiptsErrorsCopy);
    setReceipts(receiptsCopy);
  };

  const customStyles = width => ({
    control: provided => ({
      ...provided,
      width: width,
      height: '30px',
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: '200px',
    }),
    menu: provided => ({
      ...provided,
      zIndex: 100000,
    }),
  });

  const deleteDocumentFromReceipt = async id => {
    const receiptsCopy = [...receipts];

    await deleteExpenseFile(receiptsCopy[id].documentName);

    if (receiptsCopy[id]?.uploadedDate) delete receiptsCopy[id].uploadedDate;

    if (receiptsCopy[id]?.documentName) delete receiptsCopy[id].documentName;

    if (receiptsCopy[id]?.uploadedByName) delete receiptsCopy[id].uploadedByName;

    setReceipts(receiptsCopy);
  };

  return (
    <Form>
      <Row
        style={{
          margin: '16px 0 0 0',
        }}
        className="top-row">
        <div
          style={{
            width: '40%',
          }}
          className="top-col">
          <h1 className="title">Airline Expense</h1>
        </div>
        <div className="top-col">
          <h1 className="label">Code</h1> <h1 className="top-value">{form?.code}</h1>
        </div>
        <div className="top-col">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '225px',
            }}>
            <h1 className="label">Date</h1>
            <h1 className="top-value">{newDateFormatted}</h1>
          </div>
          {(form?.status === 'Unbilled' ||
            form.status === 'Non-billable' ||
            form.status === 'Ready to Bill' ||
            form.status === 'Flat Fee Billing') && (
              <Form.Group
                controlId="flatFeeBilling"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginTop: '25px',
                }}>
                <Form.Label>Flat Fee Billing</Form.Label>
                <Checkbox
                  style={{
                    color: 'red',
                    height: 0,
                    width: 0,
                    marginTop: '5px',
                  }}
                  onChange={event => {
                    setField('flatFeeBilling', event.target.checked);
                  }}
                  checked={!!form?.flatFeeBilling}
                  defaultValue={false}
                />
              </Form.Group>
            )}
        </div>
      </Row>
      <ExpenseForm setField={setField} errors={errors} form={form} setForm={setForm} formHasChanged={formHasChanged} />
      <div className="gray-wrapper">
        {receipts?.map((receipt, index) => (
          <Receipt
            key={receipt.id}
            receipt={receipt}
            id={index}
            expenseId={id}
            receipts={receipts}
            handleDeleteRow={handleDeleteRow}
            customStyles={customStyles}
            airlineOptions={airlineOptions}
            setReceiptFields={setReceiptFields}
            receiptsErrors={receiptsErrors}
            airportOptions={airportOptions}
            debouncedSetReceiptField={debouncedSetReceiptField}
            setReceiptField={setReceiptField}
            paymentOptions={paymentOptions}
            setReceiptFile={setReceiptFile}
            setUploading={setUploading}
            setUploadError={setUploadError}
            deleteDocumentFromReceipt={deleteDocumentFromReceipt}
            form={form}
          />
        ))}
        <Row
          className="top-row"
          style={{
            alignItems: 'flex-start',
          }}>
          {/* remove button temporary  */}
          {/* <div onClick={handleAddReceipt} className="another-receipt">
            Add Another Receipt
          </div> */}
          <div className='another-receipt'></div>
          <div
            className="top-col"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}>
            <Form.Group controlId="startDate">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Start Date
              </Form.Label>
              <Form.Control
                style={{
                  width: '150px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="startDate"
                value={form?.startDate?.substring(0, 10)}
                readOnly
                disabled
              />
            </Form.Group>
            <Form.Group controlId="endDate">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                End Date
              </Form.Label>
              <Form.Control
                style={{
                  width: '150px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="endDate"
                value={form?.endDate?.substring(0, 10)}
                readOnly
                disabled
              />
            </Form.Group>
          </div>
          <Form.Group controlId="totalToBeBilled">
            <Form.Label
              style={{
                marginTop: '15px',
              }}>
              Total To Be Billed
            </Form.Label>
            <InputGroup
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '150px',
                opacity: '0.7',
              }}>
              <span
                style={{
                  position: 'absolute',
                  left: '10px',
                  zIndex: 10000,
                }}>
                $
              </span>
              <Form.Control
                style={{
                  width: '225px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  marginLeft: '20px',
                }}
                as="input"
                name="location"
                value={totalToBeBilled}
                readOnly
                disabled
              />
            </InputGroup>
          </Form.Group>
        </Row>
        <Row
          className="top-row"
          style={{
            margin: '5px 0 0 0',
            alignItems: 'flex-start',
          }}>
          <div
            className="top-col"
            style={{
              width: '70%',
            }}>
            <Form.Group controlId="notes">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Notes
              </Form.Label>
              <Form.Control
                style={{
                  width: '533px',
                  height: '75px',
                }}
                as="textarea"
                name="notes"
                defaultValue={form?.notes}
                onBlur={event => {
                  setField('notes', event.target.value);
                }}
              />
            </Form.Group>
          </div>
          <div
            className="top-col"
            style={{
              alignItems: 'flex-start',
            }}>
            <Form.Group controlId="auditKey">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Audit Key
              </Form.Label>
              <Form.Control
                style={{
                  width: '200px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="location"
                value={auditKey}
                readOnly
                disabled
              />
            </Form.Group>
          </div>
        </Row>
      </div>
      {(isCreatingItems || isLoading) && (
        <div className="overlay-box">
          <Spinner className="spinner" animation="border" />
        </div>
      )}

      <Row
        className="top-row"
        style={{
          margin: '50px 0 0 0',
          justifyContent: 'center',
        }}>
        <Button
          disabled={saveDisabled}
          className="button-text"
          style={{
            width: '140px',
            marginRight: '20px',
          }}
          onClick={handleCreateExpense}>
          <span>SAVE</span>
        </Button>
        <Button
          onClick={handleCancel}
          className="button-secondary"
          style={{
            width: '140px',
          }}>
          <span>CANCEL</span>
        </Button>
      </Row>
    </Form>
  );
};

export default FormContent;
