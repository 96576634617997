import { createSlice } from '@reduxjs/toolkit';

const expensesEntrySlice = createSlice({
  name: 'expenseEntry',
  initialState: {
    subHeader: 'Choose your line of business.',
    clientMenu: {
      menuHeader: 'Client Menu',
      menuItems: [
        {
          lob: 'MDS',
          text: 'Mobile Driver Solutions',
          to: '/expense-entry/mobile-driver-solutions',
          disabled: false,
        },
        {
          lob: 'DMS',
          text: 'Driver Management Services',
          to: '/expense-entry/driver-management-services',
          disabled: false,
        },
        {
          lob: null,
          text: 'Expense Maintenance',
          to: '/expense-entry/expense-maintenance',
          disabled: false,
        },
      ],
    },
    entryTypeMenu: [
      {
        header: 'Entry Type',
        items: [
          {
            text: 'Airline',
            icon: 'airplanemode_active',
            to: '/expense-entry/mobile-driver-solutions/airline',
          },
          {
            text: 'Hotel',
            icon: 'hotel',
            to: '/expense-entry/mobile-driver-solutions/hotel',
          },
          {
            text: 'Car',
            icon: 'directions_car',
            to: '/expense-entry/mobile-driver-solutions/car',
          },
          {
            text: 'Other',
            icon: 'receipt',
            to: '/expense-entry/mobile-driver-solutions/other',
          },
          {
            text: 'Search Expenses',
            icon: 'search',
            to: '/expense-entry/mobile-driver-solutions/search-expenses',
          },
          {
            text: 'Airline',
            icon: 'airplanemode_active',
            to: '/expense-entry/driver-management-services/airline',
          },
          {
            text: 'Hotel',
            icon: 'hotel',
            to: '/expense-entry/driver-management-services/hotel',
          },
          {
            text: 'Car',
            icon: 'directions_car',
            to: '/expense-entry/driver-management-services/car',
          },
          {
            text: 'Other',
            icon: 'receipt',
            to: '/expense-entry/driver-management-services/other',
          },
          {
            text: 'Search Expenses',
            icon: 'search',
            to: '/expense-entry/driver-management-services/search-expenses',
          },
        ],
      },
    ],
    managementMenu: [
      {
        header: 'Management',
        items: [
          {
            text: 'Ready to Bill - Open Timecards',
            icon: 'check_circle',
            to: '/expense-entry/mobile-driver-solutions/ready-to-bill-open',
          },
          {
            text: 'Ready to Bill - Not Open Timecards',
            icon: 'warning',
            to: '/expense-entry/mobile-driver-solutions/ready-to-bill-closed',
          },
          {
            text: 'Transfer to UltraStaff',
            icon: 'file_upload',
            to: '/expense-entry/mobile-driver-solutions/transfer-to-ultrastaff',
          },
          {
            text: 'Ready to Bill - Open Timecards',
            icon: 'check_circle',
            to: '/expense-entry/driver-management-services/ready-to-bill-open',
          },
          {
            text: 'Ready to Bill - Not Open Timecards',
            icon: 'warning',
            to: '/expense-entry/driver-management-services/ready-to-bill-closed',
          },
          {
            text: 'Transfer to UltraStaff',
            icon: 'file_upload',
            to: '/expense-entry/driver-management-services/transfer-to-ultrastaff',
          },
          // {
          //   text: 'Reporting',
          //   icon: 'equalizer',
          //   to: '/expense-entry/mobile-driver-solutions/reporting',
          //   disabled: true,
          // },
        ],
      },
    ],
    readyToBillCurrentIds: [],
  },
  reducers: {
    setRTBCurrentIds: (state, { payload }) => {
      state.readyToBillCurrentIds = payload;
    },
  },
});

export default expensesEntrySlice.reducer;

export const {
  setRTBCurrentIds
} = expensesEntrySlice.actions;
