import React, { useEffect, useLayoutEffect, useState } from 'react';

import { Container, Row } from 'react-bootstrap';
import { MdWeb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DriverSolutionsMenuItem, SubHeader } from '../../../components';

import './styles.scoped.css';

const DriverSolutions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clientMenu, entryTypeMenu, managementMenu } = useSelector(state => state.expenseEntry);
  const appAccess = useSelector(state => state.admin?.user?.appAccess);
  const [currentExpenseRole, setCurrentExpenseRole] = useState('');
  const [assignmentLineOfBusinessItems, setAssignmentLineOfBusinessItems] = useState({});

  const getAssignmentLineOfBusinessItems = () => {
    clientMenu?.menuItems?.map(i => {
      if (location.pathname.includes(i.to)) {
        return setAssignmentLineOfBusinessItems(i);
      }
    })
  }

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      document.querySelector('div.root').style.background = '#D0CED0';
      document.querySelector('span.wrap').style.background = '#ffff';
    }
    return () => {
      document.querySelector('div.root').style.background = '#ffff';
    }
  }, [location.pathname]);

  useEffect(() => {
    getAssignmentLineOfBusinessItems();
  }, [])

  useEffect(() => {
    const access = appAccess?.items?.filter(app => app.appId?.name === 'Expense Entry')?.[0];
    if (access && access.roleId) {
      setCurrentExpenseRole(access?.roleId?.id);
    }
  }, [appAccess, location.pathname]);

  const onPress = item => {
    navigate(item.to);
  };

  return (
    <>
      <SubHeader
        text="Expenses"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Expenses?"
        emailAddress={process.env.REACT_APP_EXPENSE_CONTACT_EMAIL}
      />

      <h1 className="header">{assignmentLineOfBusinessItems.text}</h1>

      {currentExpenseRole ?
        <>
          <h1 key={`${entryTypeMenu.header}-h1`} className="sub-header ">
            {entryTypeMenu[0].header}
          </h1>
          <Container className="wrap">
            {entryTypeMenu.map(item => (
              <div key={item.header}>
                <Row className="row" key={`${item.header}-row`}>
                  {item.items.map(i => (
                    i.to.includes(assignmentLineOfBusinessItems.to) &&
                    <DriverSolutionsMenuItem
                      key={i.icon}
                      text={i.text}
                      icon={i.icon}
                      onPress={() =>
                        onPress(i)
                      } />
                  ))}
                </Row>
              </div>
            ))}
          </Container>
        </>
        :
        null
      }

      {currentExpenseRole === '9' || currentExpenseRole === '31' || currentExpenseRole === '32' ?
        <>
          <h1 key={`${managementMenu.header}-h1`} className="sub-header">
            {managementMenu[0].header}
          </h1>
          <Container className="wrap">
            {managementMenu.map(item => (
              <div key={item.header}>
                <Row className="row" key={`${item.header}-row`}>
                  {item.items.map(i => (
                    i.to.includes(location.pathname) &&
                    <DriverSolutionsMenuItem
                      key={i.icon}
                      text={i.text}
                      icon={i.icon}
                      onPress={() => onPress(i)}
                      disabled={i.disabled}
                    />
                  ))}
                </Row>
              </div>
            ))}
          </Container>
        </>
        :
        null
      }
    </>

  );
};

export default DriverSolutions;
