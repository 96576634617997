import React, { useEffect, useState } from 'react';
import {
  Breadcrumbs,
  EdgeReportingWindow,
  SubHeader,
  ReportLoader,
} from '../../../components';
import dayjs from 'dayjs';
import { MdWeb } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Row, Form, Button, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import './styles.scoped.css';
import { customStyles } from '../utils';
import {
  generateReport,
  generateSingleReport,
  getReportData,
  removeReportData,
  getInvoiceImage,
  getList,
} from '../operations';
import { Radio } from '@mui/material';
import InvoiceTable, {
  invoiceColumns,
  invoiceColumnsCostumer,
} from './InvoiceTable';
import Excel from '../../../assets/excel.png';
import PDF from '../../../assets/pdf.png';
import { getStates } from '../../DriverQualification/ListDQ/NewRequest/operations';
import { userHasAccess } from '../operations';
import { useSelector } from 'react-redux';
import getCSVData from '../csv';

const InvoicePdf = () => {
  const navigate = useNavigate();
  const {
    clientMenu: { menuItems },
  } = useSelector(state => state.edgeReporting);
  const { user } = useSelector(state => state.admin);
  const [data, setData] = useState([]);
  const [form, setForm] = useState({});
  const [loadingResults, setLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isCustomer, setIsCustomer] = useState(true);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [clientStatuses, setClientStatuses] = useState([]);
  const [lines, setLines] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    if (menuItems) {
      if (
        !userHasAccess(
          user,
          menuItems.find(i => i.to === window.location.pathname)?.id,
        )
      )
        navigate('/');
    }
  }, [menuItems, navigate, user]);

  useEffect(() => {
    getAndSetFormData();
  }, []);

  const getAndSetFormData = async () => {
    const states = await getStates();
    if (states) {
      setStateOptions(
        states
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map(({ name, id }) => ({
            label: name,
            value: id,
          })),
      );
    }
  };

  useEffect(() => {
    getList('client-status').then(res => {
      const statuses = res.map(({ REF_CODE, REF_DESCRIPTION }) => ({
        label: REF_DESCRIPTION,
        value: REF_CODE,
      }));
      setClientStatuses(statuses);
    });
    getList('line-of-business').then(res => {
      const lines = res.map(({ LineOfBusinessCode, LineOfBusinessDesc }) => ({
        label: LineOfBusinessDesc,
        value: LineOfBusinessCode,
      }));
      setLines(lines);
    });
  }, []);

  const handleViewResults = async () => {
    if (
      !(
        dayjs(form?.ToPerEnddate).isAfter(form?.FromPerEnddate) ||
        dayjs(form?.FromPerEnddate).isSame(form?.ToPerEnddate)
      )
    ) {
      alert('Invalid date range. Please double check and try again.');
      return;
    }
    setLoadingResults(true);
    const {
      FromPerEnddate = '',
      ToPerEnddate = '',
      Status = '',
      LineOfBusiness = '',
      BillToNumber = 0,
      AMC = '',
      City = '',
      State = '',
      CustomerNumber = 0,
      CustomerName = '',
      invoiceId = 0,
    } = form;
    try {
      const response = isCustomer
        ? await generateReport({
          endpoint: 'invoice-report-client',
          payload: {
            FromPerEnddate,
            ToPerEnddate,
            Status,
            LineOfBusiness,
            BillToNumber: parseInt(BillToNumber),
            AMC,
            City,
            State,
            CustomerNumber: parseInt(CustomerNumber),
            CustomerName,
          },
        })
        : await generateSingleReport({
          endpoint: `invoice-report-invoice-number?invoiceId=${invoiceId}`,
        });

      if (response.location && response.location !== '') {
        const reportData = await getReportData(response.location);
        if (reportData?.length) {
          await removeReportData(response.location);
          setLoadingResults(false);
          setData(reportData);
          setShowResults(true);
        }
      } else if (response.length === 0) {
        alert('No data found');
        setLoadingResults(false);
      } else {
        setLoadingResults(false);
        if (response.statusCode !== 404) {
          setData(response);
          setShowResults(true);
        } else {
          setLoadingResults(false);
          alert('No data found matching your criteria.');
        }
      }
    } catch (error) {
      console.log(error);
      setLoadingResults(false);
    }
  };

  const handleExportPDF = async () => {
    setLoadingResults(true);
    const id = isCustomer ? selectedInvoice?.Invoice_ID : data[0].Invoice_ID;
    if (isCustomer && !selectedInvoice) {
      alert('Please select an invoice');
      return;
    }
    const response = await generateReport({
      endpoint: 'invoice-pdf',
      payload: { invoiceId: `${id}` },
    });
    if (response.location && response.location !== '') {
      const reportData = await getReportData(response.location);
      if (reportData?.pdfBase64) {
        await removeReportData(response.location);
        let blob = null;
        blob = b64toBlob(reportData?.pdfBase64, 'application/pdf');
        const blobURL = URL.createObjectURL(blob);
        const newWindowViewer = window.open(blobURL);
        const theDoc = newWindowViewer.document;
        const theScript = theDoc.createElement('script');
        function injectThis() {
          window.print();
        }
        theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
        theDoc.body.appendChild(theScript);
      } else {
        alert('PDF not available for this invoice');
        return;
      }
      setLoadingResults(false);
    }
  };

  const b64toBlob = (content, contentType) => {
    contentType = contentType || '';
    const sliceSize = 512;
    // method which converts base64 to binary
    const byteCharacters = window.atob(content);

    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
      type: contentType,
    }); // statement which creates the blob
    return blob;
  };

  const setSelectedRow = check => {
    setSelectedInvoice(check);
  };

  const CheckPDFForm = ({ setForm, form }) => {
    const handleChange = e => {
      const _form = { ...form, [e.target.name]: e.target.value };
      setForm(_form);
    };

    return (
      <Form
        noValidate
        onSubmit={() => { }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <p style={{ marginTop: '20px' }}>
          Select to search by Customer Info or Invoice Number
        </p>
        <span>
          <span className="radio-group">
            <Radio
              style={{
                color: '#da0f2e',
                transform: 'scale(1)',
              }}
              onChange={() => setIsCustomer(true)}
              checked={isCustomer}
            />
            <span>Customer Info</span>
          </span>
          <span className="radio-group">
            <Radio
              style={{
                color: '#da0f2e',
                transform: 'scale(1)',
                marginLeft: 50,
              }}
              onChange={() => setIsCustomer(false)}
              checked={!isCustomer}
            />
            <span>Invoice Number</span>
          </span>
        </span>
        {isCustomer ? (
          <div className="filters-row">
            <div className="filter-row">
              <Form.Group
                as={Row}
                controlId="Status"
                style={{ alignItems: 'center' }}
              >
                <Form.Label style={{ width: '290px' }}>
                  Customer Status
                </Form.Label>
                <div className="dropdown-spinner-container">
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    styles={customStyles('860px')}
                    options={clientStatuses}
                    isClearable={true}
                    onChange={selectedOptions => {
                      const selectedCodes = selectedOptions.map(
                        option => option.value,
                      );
                      setForm({
                        ...form,
                        Status: selectedCodes.join(','),
                      });
                    }}
                    value={form?.EmpStatus?.split(',').map(code =>
                      clientStatuses.find(option => option.value === code),
                    )}
                  />
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    aria-hidden="true"
                    className="dropdown-spinner"
                    hidden={true}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="filter-row">
              <Form.Group
                as={Row}
                controlId="EmpStatus"
                style={{ alignItems: 'center' }}
              >
                <Form.Label style={{ width: '290px' }}>
                  Line Of Business
                </Form.Label>
                <div className="dropdown-spinner-container">
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    styles={customStyles('860px')}
                    options={lines}
                    isClearable={true}
                    onChange={selectedOptions => {
                      const selectedLines = selectedOptions.map(
                        option => option.value,
                      );
                      setForm({
                        ...form,
                        LineOfBusiness: selectedLines.join(','),
                      });
                    }}
                    value={form?.LineOfBusiness?.split(',').map(line =>
                      lines.find(option => option?.value === line),
                    )}
                  />
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    aria-hidden="true"
                    className="dropdown-spinner"
                    hidden={true}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="filter-row">
              <Form.Group
                as={Row}
                controlId="BillToNumber"
                style={{ alignItems: 'center' }}
              >
                <Form.Label style={{ width: '290px' }}>
                  Bill To Number
                </Form.Label>
                <Form.Control
                  style={{
                    width: '360px',
                    height: '30px',
                    marginRight: '20px',
                  }}
                  as="input"
                  name="BillToNumber"
                  value={form?.BillToNumber}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                as={Row}
                controlId="AMC"
                style={{ alignItems: 'center' }}
              >
                <Form.Label
                  style={{
                    width: '80px',
                    marginLeft: '90px',
                    marginRight: '40px',
                  }}
                >
                  AMC Code
                </Form.Label>
                <Form.Control
                  style={{
                    width: '300px',
                    height: '30px',
                  }}
                  as="input"
                  name="AMC"
                  value={form?.AMC}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <div
              className="filter-row"
              style={{
                marginTop: '30px',
              }}
            >
              <Form.Group
                as={Row}
                controlId="FromPerEnddate"
                style={{ alignItems: 'center' }}
              >
                <Form.Label style={{ width: '290px' }}>
                  Week Ending Date
                </Form.Label>
                <Form.Control
                  style={{
                    width: '150px',
                    height: '30px',
                    marginRight: '20px',
                  }}
                  as="input"
                  type="date"
                  name="FromPerEnddate"
                  value={form?.FromPerEnddate}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                as={Row}
                controlId="FromPerEnddate"
                style={{ alignItems: 'center' }}
              >
                <Form.Label style={{ margin: '0 25px' }}>To</Form.Label>
                <Form.Control
                  style={{
                    width: '150px',
                    height: '30px',
                    marginRight: '20px',
                  }}
                  as="input"
                  type="date"
                  name="ToPerEnddate"
                  value={form?.ToPerEnddate}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Row} controlId="locationState">
                <Form.Label style={{ marginLeft: 110, marginRight: 83 }}>
                  State
                </Form.Label>
                <Form.Group>
                  <div className="dropdown-spinner-container">
                    <Select
                      name="State"
                      isClearable={true}
                      styles={customStyles('300px')}
                      options={stateOptions}
                      onChange={e => {
                        const _form = { ...form, State: e?.value || '' };
                        setForm(_form);
                      }}
                      value={stateOptions?.filter(
                        state => state?.value === form?.State,
                      )}
                    />
                  </div>
                </Form.Group>
              </Form.Group>
              <Form.Group as={Row}>
                <div className="dropdown-spinner-container">
                  <Form.Group
                    as={Row}
                    controlId="City"
                    style={{ alignItems: 'center' }}
                  >
                    <Form.Label style={{ marginLeft: 100, marginRight: 20 }}>
                      City
                    </Form.Label>
                    <Form.Control
                      style={{
                        width: '300px',
                        height: '30px',
                      }}
                      as="input"
                      name="City"
                      value={form?.City}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
              </Form.Group>
            </div>
            <div className="filter-row">
              <Form.Group as={Row} style={{ alignItems: 'center' }}>
                <Form.Label style={{ width: '290px' }}>
                  Customer Name
                </Form.Label>
                <Form.Control
                  style={{
                    width: '360px',
                    height: '30px',
                    marginRight: '20px',
                  }}
                  as="input"
                  name="CustomerName"
                  value={form?.CustomerName}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <div className="filter-row">
              <Form.Group as={Row} style={{ alignItems: 'center' }}>
                <Form.Label style={{ width: '290px' }}>
                  Customer Number
                </Form.Label>
                <Form.Control
                  style={{
                    width: '360px',
                    height: '30px',
                    marginRight: '20px',
                  }}
                  type="number"
                  as="input"
                  name="CustomerNumber"
                  value={form?.CustomerNumber}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
          </div>
        ) : (
          <div className="filter-row">
            <Form.Group
              as={Row}
              controlId="invoiceId"
              style={{ alignItems: 'center' }}
            >
              <Form.Label style={{ width: '100px' }}>Invoice #</Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                type="number"
                name="invoiceId"
                value={form?.invoiceId}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
        )}
        <div className="buttons-row">
          <Button
            onClick={handleViewResults}
            style={{ marginRight: '20px', width: '100px' }}
          >
            <span>VIEW</span>
          </Button>

          <Button
            style={{ width: '100px' }}
            onClick={() => navigate('/edge-reporting')}
            className="button-secondary"
          >
            <span>CANCEL</span>
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <>
      <SubHeader
        text="EDGE Reporting"
        btnSmall={<MdWeb color={'#fff'} size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo={false}
        emailAddress={process.env.REACT_APP_EDGE_REPORTING_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}
      >
        <Breadcrumbs
          leftTitle={'Reporting Home'}
          middleTitle={showResults && 'Invoice PDF'}
          midItemAction={() => setShowResults(false)}
          rightTitle={
            showResults
              ? `Viewing Report ${isCustomer ? '(Customer)' : '(Invoice)'}`
              : 'Invoice PDF'
          }
          route="/edge-reporting"
        />
        {data?.length && showResults ? (
          <span>
            <img
              onClick={() => handleExportPDF()}
              src={PDF}
              alt="export-to-pdf"
              style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }}
            />
            <CSVLink
              filename={`edge-reporting-invoice-${isCustomer ? selectedInvoice?.Invoice_ID : data[0].Invoice_ID
                }.csv`}
              data={getCSVData(
                data,
                Object.keys(data[0]).length < 21
                  ? invoiceColumnsCostumer
                  : invoiceColumns,
              )}
            >
              <img
                src={Excel}
                alt="export-to-excel"
                style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }}
              />
            </CSVLink>
          </span>
        ) : null}
      </div>
      {loadingResults && (
        <ReportLoader didAbort={() => setLoadingResults(false)} />
      )}
      {showResults ? (
        <>
          <InvoiceTable
            data={data}
            loading={loadingResults}
            rowSelected={setSelectedRow}
            isCustomer={isCustomer}
          />
          <div className="buttons-row">
            <Button
              onClick={() => setShowResults(false)}
              style={{ marginRight: '20px', width: '100px' }}
            >
              <span>BACK</span>
            </Button>

            <Button
              style={{ width: '100px' }}
              onClick={() => navigate('/edge-reporting')}
              className="button-secondary"
            >
              <span>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <EdgeReportingWindow
          containerStyle={{
            border: '2px solid #343a40',
          }}
          windowContent={CheckPDFForm({ form, setForm })}
          windowTitle={'Criteria'}
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )}
    </>
  );
};

export default InvoicePdf;
