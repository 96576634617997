import React from 'react';

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EditButton from './EditButton';

export const variablesDependingOnType = {
  list: {
    Airlines: 'listAirLines',
    Airports: 'listAirPorts',
    Companies: 'listCarRentals',
    Cities: 'listCities',
    Hotels: 'listHotels',
  },
  create: {
    Airlines: 'createAirLine',
    Airports: 'createAirPort',
    Companies: 'createCarRental',
    Cities: 'createCity',
    Hotels: 'createHotel',
  },
  update: {
    Airlines: 'updateAirLine',
    Airports: 'updateAirPort',
    Companies: 'updateCarRental',
    Cities: 'updateCity',
    Hotels: 'updateHotel',
  },
  delete: {
    Airlines: 'deleteAirLine',
    Airports: 'deleteAirPort',
    Companies: 'deleteCarRental',
    Cities: 'deleteCity',
    Hotels: 'deleteHotel',
  },
  singular: {
    Airlines: 'Airline',
    Airports: 'Airport',
    Companies: 'Company',
    Cities: 'City',
    Hotels: 'Hotel',
  },
};

export const columnClassName = 'align-middle text-nowrap';

export const commonColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    className: columnClassName,
    Cell: ({ value }) => value && value.charAt(0).toUpperCase() + value.slice(1),
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: columnClassName,
    Cell: ({ value }) => value && value.charAt(0).toUpperCase() + value.slice(1),
  },
];

export const buildColumns = ({ hasState, hasId, setOpenEditModal }) => {
  let newColumns = commonColumns;
  if (hasState) {
    newColumns = [
      commonColumns[0],
      { Header: 'State', accessor: 'state.name', className: columnClassName },
      commonColumns[1],
    ];
  } else if (hasId) {
    newColumns = [
      {
        Header: 'Id',
        accessor: 'id',
        className: columnClassName,
        Cell: ({ value }) => (value.length > 3 ? value.substring(0, 8).concat('...') : value),
      },
      commonColumns[0],
      commonColumns[1],
    ];
  }
  newColumns = [
    ...newColumns,
    {
      Header: '',
      accessor: 'editExpense',
      className: columnClassName,
      Cell: instance => EditButton(instance, setOpenEditModal),
    },
  ];
  return newColumns;
};

export const getSortIcon = (isSorted, isSortedDesc) => {
  let iconProp = faSort;
  if (isSorted) {
    iconProp = isSortedDesc ? faSortDown : faSortUp;
  }
  return <FontAwesomeIcon icon={iconProp} className="ml-1 fa-sm" />;
};
