const locationsData = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'California',
    value: 'CA',
    additionalAreas: [
      //   { label: 'San Francisco', value: 'CA - San Francisco' },
      { label: 'Southern', value: 'CA - Southern' },
      { label: 'Northern', value: 'CA - Northern' },
    ],
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'District of Columbia',
    value: 'DC',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
    additionalAreas: [
      { label: 'Chicago', value: 'Chicago or Cook County' },
      { label: 'Cook County', value: 'Chicago or Cook County' },
      { label: 'Other', value: 'Other' },
    ],
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
    additionalAreas: [
      { label: 'Duluth', value: 'Minneapolis, St Paul, or Duluth' },
      { label: 'Minneapolis', value: 'Minneapolis, St Paul, or Duluth' },
      { label: 'St Paul', value: 'Minneapolis, St Paul, or Duluth' },
      { label: 'Other', value: 'Other' },
    ],
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New York',
    value: 'NY',
    additionalAreas: [
      { label: 'New York City', value: 'NYC / Westchester County' },
      { label: 'Westchester County', value: 'NYC / Westchester County' },
      { label: 'Other', value: 'Other' },
    ],
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
    additionalAreas: [
      { label: 'Philadelphia', value: 'Philadelphia or Pittsburgh' },
      { label: 'Pittsburgh', value: 'Philadelphia or Pittsburgh' },
      { label: 'Other', value: 'Other' },
    ],
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
    additionalAreas: [
      { label: 'Dallas', value: 'Dallas, Austin, or San Antonio' },
      { label: 'Austin', value: 'Dallas, Austin, or San Antonio' },
      { label: 'San Antonio', value: 'Dallas, Austin, or San Antonio' },
      { label: 'Other', value: 'Other' },
    ],
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];

export const getStates = () => {
  return locationsData.map(location => ({
    label: location.label,
    value: location.value,
  }));
};

export const getStateWithValue = value => {
  return locationsData.find(location => location.value === value);
};

export const getStateRegion = state => {
  const filteredLocation = locationsData.find(
    location => location.value === state,
  );

  if (filteredLocation && filteredLocation.additionalAreas) {
    return filteredLocation.additionalAreas;
  }
  return [];
};
