import React from 'react';
import { Row, Form, Spinner } from 'react-bootstrap';
import { customStyles } from '../utils';
import Select from 'react-select';
import './styles.scoped.css';

export const MasterCodeForm = ({
  form,
  setForm,
  handleChange,
  amcOptions,
  payCodeOptions,
  isLoadingPayCodes,
  isLoadingAmcCodes,
}) => {
  return (
    <div className="filters-container">
      <div
        className="filter-row"
        style={{
          marginTop: '30px',
        }}
      >
        <Form.Group
          as={Row}
          controlId="FromPerEnddate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>
            <div className="required">Period End Date</div>
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            type="date"
            name="FromPerEnddate"
            value={form?.FromPerEnddate}
            onChange={handleChange}
            onBlur={null}
          />
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="ToPerEnddate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label
            style={{
              width: '20px',
              marginLeft: '30px',
              marginRight: '20px',
            }}
          >
            To
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
            }}
            as="input"
            type="date"
            name="ToPerEnddate"
            value={form?.ToPerEnddate}
            onChange={handleChange}
            onBlur={null}
          />
        </Form.Group>
      </div>
      <p style={{ fontWeight: 700 }}>And/Or</p>

      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="AMCCodeFrom"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>AMC Code</Form.Label>
          <div className="dropdown-spinner-container">
            <Select
              isDisabled={isLoadingAmcCodes}
              isMulti
              closeMenuOnSelect={false}
              styles={customStyles('365px')}
              options={amcOptions}
              defaultValue={form?.AMC?.split(',').map(code =>
                amcOptions.find(option => option.value === code),
              )}
              onChange={selectedOptions => {
                const selectedCodes = selectedOptions.map(
                  option => option.value,
                );
                setForm({
                  ...form,
                  AMC: selectedCodes.join(','),
                });
              }}
            />
            <Spinner
              as="span"
              animation="border"
              size="sm"
              aria-hidden="true"
              className="dropdown-spinner"
              hidden={!isLoadingAmcCodes}
            />
          </div>
        </Form.Group>
      </div>
      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="BillToNumber"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Bill To Number</Form.Label>
          <Form.Control
            style={{
              width: '360px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            name="BillToNumber"
            value={form?.BillToNumber}
            type="number"
            onChange={handleChange}
          />
        </Form.Group>
      </div>
      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="BillToName"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Bill To Name</Form.Label>
          <Form.Control
            style={{
              width: '360px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            name="BillToName"
            value={form?.BillToName}
            onChange={handleChange}
          />
        </Form.Group>
      </div>
      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="payCode"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Pay Code</Form.Label>
          <div className="dropdown-spinner-container">
            <Select
              isMulti
              closeMenuOnSelect={false}
              isDisabled={isLoadingPayCodes}
              styles={customStyles('365px')}
              options={payCodeOptions}
              isClearable={true}
              defaultValue={form?.PayCode?.split(',').map(code =>
                amcOptions.find(option => option.value === code),
              )}
              onChange={selectedOptions => {
                const selectedCodes = selectedOptions.map(
                  option => option.value,
                );
                setForm({
                  ...form,
                  PayCode: selectedCodes.join(','),
                });
              }}
            />
            <Spinner
              as="span"
              animation="border"
              size="sm"
              aria-hidden="true"
              className="dropdown-spinner"
              hidden={!isLoadingPayCodes}
            />
          </div>
        </Form.Group>
      </div>
    </div>
  );
};

export const MonthYearForm = ({
  yearOptions,
  amcOptions,
  payCodeOptions,
  lobOptions,
  form,
  handleChange,
  setForm,
  branchOptions,
  isLoadingBranches,
  isLoadingAmcCodes,
  isLoadingPayCodes,
  isLoadingLOB,
}) => {
  return (
    <div className="filters-container">
      <div className="filter-row">
        <Form.Group as={Row} controlId="Year" style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>
            <div className="required">Year</div>
          </Form.Label>
          <Select
            isMulti
            closeMenuOnSelect={false}
            styles={customStyles('365px')}
            options={yearOptions}
            value={form?.Year?.split(',').map(code =>
              yearOptions.find(option => option.value === code),
            )}
            onChange={selectedOptions => {
              const selectedYears = selectedOptions.map(option => option.value);
              setForm({
                ...form,
                Year: selectedYears.join(','),
              });
            }}
          />
        </Form.Group>
      </div>
      <p style={{ fontWeight: 700 }}>And one or more of the following</p>
      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="AMCCodeFrom"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>AMC Code</Form.Label>
          <div className="dropdown-spinner-container">
            <Select
              isDisabled={isLoadingAmcCodes}
              isMulti
              closeMenuOnSelect={false}
              styles={customStyles('365px')}
              options={amcOptions}
              defaultValue={form?.AMC?.split(',').map(code =>
                amcOptions.find(option => option.value === code),
              )}
              onChange={selectedOptions => {
                const selectedCodes = selectedOptions.map(
                  option => option.value,
                );
                setForm({
                  ...form,
                  AMC: selectedCodes.join(','),
                });
              }}
            />
            <Spinner
              as="span"
              animation="border"
              size="sm"
              aria-hidden="true"
              className="dropdown-spinner"
              hidden={!isLoadingAmcCodes}
            />
          </div>
        </Form.Group>
      </div>
      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="branch"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Branch Number</Form.Label>
          <div className="dropdown-spinner-container">
            <Select
              isMulti
              closeMenuOnSelect={false}
              styles={customStyles('365px')}
              isDisabled={isLoadingBranches}
              options={branchOptions}
              isClearable={true}
              defaultValue={form?.BranchNumber?.split(',').map(code =>
                branchOptions.find(option => option.value === code),
              )}
              onChange={selectedOptions => {
                const selectedCodes = selectedOptions.map(
                  option => option.value,
                );
                setForm({
                  ...form,
                  BranchNumber: selectedCodes.join(','),
                });
              }}
            />
            <Spinner
              as="span"
              animation="border"
              size="sm"
              aria-hidden="true"
              className="dropdown-spinner"
              hidden={!isLoadingBranches}
            />
          </div>
        </Form.Group>
      </div>
      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="BillToNumber"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Bill To Number</Form.Label>
          <Form.Control
            style={{
              width: '360px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            name="BillToNumber"
            type="number"
            value={form?.BillToNumber}
            onChange={handleChange}
          />
        </Form.Group>
      </div>
      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="BillToName"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Bill To Name</Form.Label>
          <Form.Control
            style={{
              width: '360px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            name="BillToName"
            value={form?.BillToName}
            onChange={handleChange}
          />
        </Form.Group>
      </div>
      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="billToName"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Line of Business</Form.Label>
          <div className="dropdown-spinner-container">
            <Select
              isDisabled={isLoadingLOB}
              styles={customStyles('365px')}
              options={lobOptions}
              isClearable={true}
              onChange={(event, action) => {
                if (action.action === 'clear') {
                  setForm({
                    ...form,
                    LineOfBusiness: '',
                  });
                }
                if (action.action === 'select-option') {
                  setForm({
                    ...form,
                    LineOfBusiness: event.value,
                  });
                }
              }}
              defaultValue={lobOptions?.filter(
                option => option.value === form?.LineOfBusiness,
              )}
            />
            <Spinner
              as="span"
              animation="border"
              size="sm"
              aria-hidden="true"
              className="dropdown-spinner"
              hidden={!isLoadingLOB}
            />
          </div>
        </Form.Group>
      </div>
      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="payCode"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Pay Code</Form.Label>
          <div className="dropdown-spinner-container">
            <Select
              isMulti
              closeMenuOnSelect={false}
              isDisabled={isLoadingPayCodes}
              styles={customStyles('365px')}
              options={payCodeOptions}
              isClearable={true}
              defaultValue={form?.PayCode?.split(',').map(code =>
                amcOptions.find(option => option.value === code),
              )}
              onChange={selectedOptions => {
                const selectedCodes = selectedOptions.map(
                  option => option.value,
                );
                setForm({
                  ...form,
                  PayCode: selectedCodes.join(','),
                });
              }}
            />
            <Spinner
              as="span"
              animation="border"
              size="sm"
              aria-hidden="true"
              className="dropdown-spinner"
              hidden={!isLoadingPayCodes}
            />
          </div>
        </Form.Group>
      </div>
    </div>
  );
};
