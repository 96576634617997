import React, { useEffect } from 'react';

import { Home } from '@mui/icons-material';
import { Form, Button, Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './styles.scoped.css';
import { setForm, setErrors } from './driversSlice';
import GeneratedDriversQuote from './generatedDriversQuote';
import { generateDriverData } from './operations';
import { Tabs, Footer, SubHeader } from '../../../../components';
import { setCurrentTab } from '../../quoteEngineSlice';
import { getStateRegion, getStates } from '../locationList';

const GeneratedQuote = currentQuote => (
  <>
    <hr className="ml-n3 mr-n3 mt-3 mb-3 border border-dark" />
    <GeneratedDriversQuote className="p-0" quote={currentQuote.currentQuote} showSave />
  </>
);

const GenerateDriversQuote = () => {
  const dispatch = useDispatch();

  const { form, errors, currentQuote, loading } = useSelector(state => state.quoteEngineDrivers);

  useEffect(() => {
    dispatch(setCurrentTab('drivers'));
  }, []);

  const setField = (field, value) => {
    dispatch(
      setForm({
        ...form,
        [field]: value === true ? ['on'] : value,
      }),
    );

    if (errors[field]) {
      dispatch(
        setErrors({
          ...errors,
          [field]: null,
        }),
      );
    }
  };

  const validateForm = () => {
    const {
      location,
      region,
      numberOfDrivers,
      totalAnnualMilage,
      totalAnnualHoursRegular,
      totalAnnualHoursOt,
      numberDropHook,
      numberDelay,
      numberStop,
      numberOther,
      startDate,
    } = form;

    const newErrors = {};

    const invalidValueText = 'Please provide a valid value.';

    if (!location) newErrors.location = 'Please select a state.';
    if (location && getStateRegion(form?.location).length && !region) {
      newErrors.region = 'Please select a region.';
    }
    if (!startDate) newErrors.startDate = invalidValueText;
    if (numberOfDrivers === '') newErrors.numberOfDrivers = invalidValueText;
    if (totalAnnualMilage === '') newErrors.totalAnnualMilage = invalidValueText;
    if (totalAnnualHoursRegular === '') newErrors.totalAnnualHoursRegular = invalidValueText;
    if (totalAnnualHoursOt === '') newErrors.totalAnnualHoursOt = invalidValueText;
    if (numberDropHook === '') newErrors.numberDropHook = invalidValueText;
    if (numberDelay === '') newErrors.numberDelay = invalidValueText;
    if (numberStop === '') newErrors.numberStop = invalidValueText;
    if (numberOther === '') newErrors.numberOther = invalidValueText;
    return newErrors;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      dispatch(setErrors(formErrors));
    } else {
      await generateDriverData(dispatch, form);
    }
  };
  const { currentTab, subHeaderText } = useSelector(state => state.quoteEngine);

  const navigate = useNavigate();

  const showTotalAnnualMileage = location => location && location !== 'CA' && location !== 'WA';
  const showTotalNumberPerDriverPerYear = location => location && location !== 'CA' && location !== 'WA';

  const stateOptions = getStates().map(({ label, value }) => (
    <option key={label} value={value}>
      {label}
    </option>
  ));

  const renderDriversContent = () => (
    <div className="content-container">
      <Form noValidate onSubmit={() => { }}>
        <Form.Group controlId="formLocations">
          <h1 className="title">Generate Dedicated Model, Class A Quote</h1>
          <Form.Label style={{ marginTop: '15px' }}>Location (State)</Form.Label>
          <Form.Control
            className="col-sm-5 p-0"
            as="select"
            name="location"
            value={form?.location}
            onChange={event => {
              setField('location', event.target.value);
            }}
            onBlur={null}
            isInvalid={!!errors?.location}>
            <option value="" disabled>
              Select a location
            </option>

            {stateOptions}
          </Form.Control>
          <Form.Control.Feedback type="invalid">{errors?.location}</Form.Control.Feedback>
        </Form.Group>
        {getStateRegion(form?.location).length ? (
          <Form.Group controlId="formLocationArea" className="col-md-5 p-0">
            <Form.Label>Specific Area</Form.Label>
            <Form.Control
              as="select"
              name="region"
              value={form.region}
              onChange={event => {
                setField('region', event.target.value);
              }}
              isInvalid={!!errors?.region}>
              <option value="" disabled>
                Select an area
              </option>
              {getStateRegion(form?.location).map(({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors?.region}</Form.Control.Feedback>
          </Form.Group>
        ) : null}
        <Form.Group controlId="union" className="ml-3">
          <Form.Check
            type="checkbox"
            label="Union"
            isInvalid={!!errors?.union}
            checked={form?.union}
            onChange={event => setField('union', event.target.checked)}
          />
        </Form.Group>

        <Form.Group controlId="formNumberOfDrivers" className="col-sm-5 p-0">
          <Form.Label>Number of Drivers</Form.Label>
          <Form.Control
            name="numberOfDrivers"
            value={form?.numberOfDrivers}
            onChange={event => setField('numberOfDrivers', event.target.value)}
            isInvalid={!!errors?.numberOfDrivers}
            type="number"
            pattern="[0-9]*"
          />
          <Form.Control.Feedback type="invalid">{errors?.numberOfDrivers}</Form.Control.Feedback>
        </Form.Group>
        <div className="border border-dark ml-n2 mr-n2 mb-2 pt-2 pl-2 pr-2">
          {showTotalAnnualMileage(form?.location) && (
            <>
              <Form.Group controlId="formTotalAnnualMilage" className="col-sm-5 p-0">
                <Form.Label>Total Annual Mileage (Per Driver)</Form.Label>
                <Form.Control
                  name="totalAnnualMilage"
                  value={form?.totalAnnualMilage}
                  onChange={event => setField('totalAnnualMilage', event.target.value)}
                  isInvalid={!!errors?.totalAnnualMilage}
                  type="number"
                  pattern="[0-9]*"
                />
                <Form.Control.Feedback type="invalid">{errors?.totalAnnualMilage}</Form.Control.Feedback>
              </Form.Group>
              <h5 className="text-center">OR</h5>
            </>
          )}
          <Form.Group controlId="formTotalAnnualHoursRegular" className="col-sm-5 p-0">
            <Form.Label>Total Annual Hours (Regular Hours, Per Driver)</Form.Label>
            <Form.Control
              name="totalAnnualHoursRegular"
              value={form?.totalAnnualHoursRegular}
              onChange={event => setField('totalAnnualHoursRegular', event.target.value)}
              isInvalid={!!errors?.totalAnnualHoursRegular}
              type="number"
              pattern="[0-9]*"
            />
            <Form.Control.Feedback type="invalid">{errors?.totalAnnualHoursRegular}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formTotalAnnualHoursOt" className="col-sm-5 p-0">
            <Form.Label>Total Annual Hours (Overtime Hours, Per Driver)</Form.Label>
            <Form.Control
              name="totalAnnualHoursOt"
              value={form?.totalAnnualHoursOt}
              onChange={event => setField('totalAnnualHoursOt', event.target.value)}
              isInvalid={!!errors?.totalAnnualHoursOt}
              type="number"
              pattern="[0-9]*"
            />
            <Form.Control.Feedback type="invalid">{errors?.totalAnnualHoursOt}</Form.Control.Feedback>
          </Form.Group>
        </div>

        {showTotalNumberPerDriverPerYear(form?.location) && (
          <>
            <h5>Total Number per Driver per Year</h5>

            <Form.Group controlId="formNumberDropHook" className="col-sm-5 p-0">
              <Form.Label>Drop & Hook (1/3 Hr)</Form.Label>
              <Form.Control
                name="numberDropHook"
                value={form?.numberDropHook}
                onChange={event => setField('numberDropHook', event.target.value)}
                isInvalid={!!errors?.numberDropHook}
                type="number"
                pattern="[0-9]*"
              />
              <Form.Control.Feedback type="invalid">{errors?.numberDropHook}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formNumberDelay" className="col-sm-5 p-0">
              <Form.Label>Delay</Form.Label>
              <Form.Control
                name="numberDelay"
                value={form?.numberDelay}
                onChange={event => setField('numberDelay', event.target.value)}
                isInvalid={!!errors?.numberDelay}
                type="number"
                pattern="[0-9]*"
              />
              <Form.Control.Feedback type="invalid">{errors?.numberDelay}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formNumberStop" className="col-sm-5 p-0">
              <Form.Label>Stop</Form.Label>
              <Form.Control
                name="numberStop"
                value={form?.numberStop}
                onChange={event => setField('numberStop', event.target.value)}
                isInvalid={!!errors?.numberStop}
                type="number"
                pattern="[0-9]*"
              />
              <Form.Control.Feedback type="invalid">Please provide a valid value.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formNumberOther" className="col-sm-5 p-0">
              <Form.Label>Other assessorial</Form.Label>
              <Form.Control
                name="numberOther"
                value={form?.numberOther}
                onChange={event => setField('numberOther', event.target.value)}
                isInvalid={!!errors?.numberOther}
                type="number"
                pattern="[0-9]*"
              />
              <Form.Control.Feedback type="invalid">{errors?.numberOther}</Form.Control.Feedback>
            </Form.Group>
          </>
        )}
        <h1 className="title" style={{ marginTop: '20px' }}>
          Pay Surcharges
        </h1>

        <Form.Group controlId="hazmat" className="col-md-5 p-0 ml-3">
          <Form.Check
            type="checkbox"
            label="Hazmat"
            onChange={event => setField('hazmat', event.target.checked)}
            checked={form?.hazmat}
          />
        </Form.Group>
        <Form.Group controlId="doubleEndorsement" className="col-md-5 p-0 ml-3">
          <Form.Check
            type="checkbox"
            label="Double Endorsement"
            checked={form?.doubleEndorsement}
            onChange={event => setField('doubleEndorsement', event.target.checked)}
          />
        </Form.Group>
        <Form.Group controlId="otr" className="col-md-5 p-0 ml-3">
          <Form.Check
            type="checkbox"
            label="Away from home - OTR"
            checked={form?.awayFromHome}
            onChange={event => setField('awayFromHome', event.target.checked)}
          />
        </Form.Group>
        <Form.Group controlId="nightShift" className="col-md-5 p-0 ml-3">
          <Form.Check
            type="checkbox"
            label="Night Shift"
            checked={form?.nightShift}
            onChange={event => setField('nightShift', event.target.checked)}
          />
        </Form.Group>
        <Form.Group controlId="touch" className="col-md-5 p-0 ml-3">
          <Form.Check
            type="checkbox"
            label="Touch? (vs. No Touch)"
            onChange={event => setField('touchNoTouch', event.target.checked)}
            checked={form?.touchNoTouch}
          />
        </Form.Group>
        <Form.Group controlId="team" className="col-md-5 p-0 ml-3">
          <Form.Check
            type="checkbox"
            label="Team Driver"
            onChange={event => setField('teamDriver', event.target.checked)}
            checked={form?.teamDriver}
          />
        </Form.Group>
        <Form.Row className="align-items-end justify-content-between m-0">
          <Form.Group controlId="formStartDate" className="col-sm-5 p-0">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              name="startDate"
              value={form?.startDate}
              onChange={event => setField('startDate', event.target.value)}
              isInvalid={!!errors?.startDate}
              type="date"
            />
            <Form.Control.Feedback type="invalid">{errors?.startDate}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-sm-5 col-md-3 col-lg-2 d-flex justify-content-end">
            <Button className="w-100" disabled={false} type="submit" title="" onClick={handleSubmit}>
              <span className="button-text mr-2">Generate</span>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!loading} />
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
      {currentQuote && !loading && <GeneratedQuote currentQuote={currentQuote} />}
    </div>
  );

  const changeTab = tabName => {
    if (tabName !== currentTab) {
      dispatch(setCurrentTab(tabName));
      navigate('/quote-engine/generate-quote/clerical');
    }
  };

  return (
    <>
      <SubHeader
        text={subHeaderText}
        btnSmall={<Home sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigate('/quote-engine')}
        btnLarge={
          <Button onClick={() => navigate('/quote-engine/my-quotes')}>
            <span className="button-text">My Quotes</span>
          </Button>
        }
        btnInfo
        emailAddress={process.env.REACT_APP_QUOTE_ENGINE_CONTACT_EMAIL}
      />
      <Container className="wrap" fluid>
        <Tabs
          styles={{ width: '1200px' }}
          currentContent={() => currentTab === 'drivers' && renderDriversContent()}
          tabs={['Drivers', 'Clerical']}
          activeTab={currentTab}
          setTab={changeTab}
        />
      </Container>
      <Footer />
    </>
  );
};

export default GenerateDriversQuote;
