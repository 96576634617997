import { Row, Form, Spinner } from 'react-bootstrap';
import { customStyles } from '../utils';
import Select from 'react-select';
import './styles.scoped.css';

export const ARAgingReportForm = ({
  form,
  setForm,
  handleChange,
  AMCCodeOptions,
  branchNumberOptions,
  LOBCodeOptions,
  isLoadingAMCCodes,
  isLoadingBranchNumbers,
  isLoadingLOBCodes,
}) => {
  return (
    <div className="filter-main-container">
      {/* LEFT SIDE */}
      <div className="filter-half-container">
        <div className="filter-row">
          <Form.Group
            as={Row}
            controlId="AgeByDate"
            style={{ alignItems: 'center' }}
          >
            <Form.Label style={{ width: '290px', marginBottom: 0 }}>
              <div className="required">Date To Age By</div>
            </Form.Label>
            <Form.Control
              style={{
                width: '150px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              type="date"
              name="AgeByDate"
              value={form?.AgeByDate}
              onChange={handleChange}
              onBlur={null}
            />
          </Form.Group>
        </div>

        <div className="filter-row">
          <Form.Group as={Row} style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px', marginBottom: 0 }}>
              AMC Code
            </Form.Label>
            <Select
              isDisabled={isLoadingAMCCodes}
              isMulti
              closeMenuOnSelect={false}
              styles={customStyles('365px')}
              options={AMCCodeOptions}
              defaultValue={form?.AMC?.split(',').map(code =>
                AMCCodeOptions.find(option => option.value === code),
              )}
              onChange={selectedOptions => {
                const selectedCodes = selectedOptions.map(
                  option => option.value,
                );
                setForm({
                  ...form,
                  AMC: selectedCodes.join(','),
                });
              }}
            />
            <Spinner
              as="span"
              animation="border"
              size="sm"
              aria-hidden="true"
              className="dropdown-spinner"
              hidden={!isLoadingAMCCodes}
            />
          </Form.Group>
        </div>

        <div className="filter-row">
          <Form.Group as={Row} style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px', marginBottom: 0 }}>
              Branch Number
            </Form.Label>
            <Select
              isDisabled={isLoadingBranchNumbers}
              isMulti
              closeMenuOnSelect={false}
              styles={customStyles('365px')}
              options={branchNumberOptions}
              defaultValue={form?.Branch?.split(',').map(code =>
                branchNumberOptions.find(
                  option => String(option.value) === code,
                ),
              )}
              onChange={selectedOptions => {
                const selectedCodes = selectedOptions.map(
                  option => option.value,
                );
                setForm({
                  ...form,
                  Branch: selectedCodes.join(','),
                });
              }}
            />
            <Spinner
              as="span"
              animation="border"
              size="sm"
              aria-hidden="true"
              className="dropdown-spinner"
              hidden={!isLoadingBranchNumbers}
            />
          </Form.Group>
        </div>

        <div className="filter-row">
          <Form.Group as={Row} style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px' }}>
              Line of Business
            </Form.Label>
            <Select
              isDisabled={isLoadingLOBCodes}
              isMulti
              closeMenuOnSelect={false}
              styles={customStyles('365px')}
              options={LOBCodeOptions}
              defaultValue={form?.LOB?.split(',').map(code =>
                LOBCodeOptions.find(option => option.value === code),
              )}
              onChange={selectedOptions => {
                const selectedCodes = selectedOptions.map(option => option.value);
                setForm({
                  ...form,
                  LOB: selectedCodes.join(','),
                });
              }}
            />
            <Spinner
              as="span"
              animation="border"
              size="sm"
              aria-hidden="true"
              className="dropdown-spinner"
              hidden={!isLoadingLOBCodes}
            />
          </Form.Group>
        </div>



        <div className="filter-row">
          <Form.Group
            as={Row}
            controlId="BillToNumber"
            style={{ alignItems: 'center' }}
          >
            <Form.Label style={{ width: '290px', marginBottom: 0 }}>
              Bill To Number
            </Form.Label>
            <Form.Control
              style={{
                width: '360px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              name="BillToNumber"
              defaultValue={form?.BillToNumber}
              onChange={handleChange}
              type="number"
            />
          </Form.Group>
        </div>

        <div className="filter-row">
          <Form.Group
            as={Row}
            controlId="historical"
            style={{ alignItems: 'center' }}
          >
            <Form.Label style={{ width: '290px', marginBottom: 0 }}>
              <div />
            </Form.Label>
            <Form.Check
              style={{
                width: '150px',
                height: '30px',
                marginRight: '20px',
                accentColor: '#da0f2e',
              }}
              className="filter-checkbox"
              type="checkbox"
              id="historical"
              label="Historical"
              checked={!!form?.Historical}
              onChange={() =>
                setForm({ ...form, Historical: form.Historical ? 0 : 1 })
              }
              onBlur={null}
            />
          </Form.Group>
        </div>

        <div className="filter-separation" />
        <p className="required" style={{ fontWeight: '700', width: '100%' }}>
          Aging Days
        </p>

        <div className="filter-row">
          <Form.Group
            as={Row}
            controlId="AgingDaysLessThan"
            style={{ alignItems: 'center' }}
          >
            <Form.Label style={{ width: '290px', marginBottom: 0 }}>
              <div>Less Than</div>
            </Form.Label>
            <Form.Control
              style={{
                width: '150px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              type="number"
              name="AgingDaysLessThan"
              value={form?.AgingDaysLessThan}
              onChange={handleChange}
              onBlur={null}
            />
          </Form.Group>
        </div>

        <div className="filter-row">
          <Form.Group
            as={Row}
            controlId="AgingDaysBetweenFrom1"
            style={{ alignItems: 'center' }}
          >
            <Form.Label style={{ width: '290px', marginBottom: 0 }}>
              <div>Between</div>
            </Form.Label>
            <Form.Control
              style={{
                width: '150px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              type="number"
              name="AgingDaysBetweenFrom1"
              value={form?.AgingDaysBetweenFrom1}
              onChange={handleChange}
              onBlur={null}
            />
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="AgingDaysBetweenTo1"
            style={{ alignItems: 'center' }}
          >
            <Form.Label
              style={{
                width: '20px',
                marginLeft: '30px',
                marginRight: '20px',
                marginBottom: 0,
              }}
            >
              To
            </Form.Label>
            <Form.Control
              style={{
                width: '150px',
                height: '30px',
              }}
              as="input"
              type="number"
              name="AgingDaysBetweenTo1"
              value={form?.AgingDaysBetweenTo1}
              onChange={handleChange}
              onBlur={null}
            />
          </Form.Group>
        </div>

        <div className="filter-row">
          <Form.Group
            as={Row}
            controlId="AgingDaysBetweenFrom2"
            style={{ alignItems: 'center' }}
          >
            <Form.Label style={{ width: '290px', marginBottom: 0 }}>
              <div>Between</div>
            </Form.Label>
            <Form.Control
              style={{
                width: '150px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              type="number"
              name="AgingDaysBetweenFrom2"
              value={form?.AgingDaysBetweenFrom2}
              onChange={handleChange}
              onBlur={null}
            />
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="AgingDaysBetweenTo2"
            style={{ alignItems: 'center' }}
          >
            <Form.Label
              style={{
                width: '20px',
                marginLeft: '30px',
                marginRight: '20px',
                marginBottom: 0,
              }}
            >
              To
            </Form.Label>
            <Form.Control
              style={{
                width: '150px',
                height: '30px',
              }}
              as="input"
              type="number"
              name="AgingDaysBetweenTo2"
              value={form?.AgingDaysBetweenTo2}
              onChange={handleChange}
              onBlur={null}
            />
          </Form.Group>
        </div>
      </div>

      {/* RIGHT SIDE */}
      <div className="filter-half-container">
        <div
          className="filter-row"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 335,
          }}
        >
          <Form.Group controlId="SortSequence">
            <Form.Label style={{ width: '290px', marginBottom: 20 }}>
              <div>Sort Sequence</div>
            </Form.Label>
            <Form.Check
              style={{
                height: '30px',
                marginRight: '20px',
                marginBottom: 12,
                accentColor: '#da0f2e',
              }}
              type="radio"
              name="SortSequence"
              label="By Bill To Name"
              checked={form?.SortSequence === 1}
              onChange={() => setForm({ ...form, SortSequence: 1 })}
              onBlur={null}
            />
            <Form.Check
              style={{
                height: '30px',
                marginRight: '20px',
                marginBottom: 12,
                accentColor: '#da0f2e',
              }}
              type="radio"
              name="SortSequence"
              label="By Branch Number"
              checked={form?.SortSequence === 2}
              onChange={() => setForm({ ...form, SortSequence: 2 })}
              onBlur={null}
            />
            <Form.Check
              style={{
                height: '30px',
                marginRight: '20px',
                marginBottom: 12,
                accentColor: '#da0f2e',
              }}
              type="radio"
              name="SortSequence"
              label="By Account Master Code (AMC)"
              checked={form?.SortSequence === 3}
              onChange={() => setForm({ ...form, SortSequence: 3 })}
              onBlur={null}
            />
          </Form.Group>
        </div>
      </div>
    </div>
  );
};
