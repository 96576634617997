import { createSlice } from '@reduxjs/toolkit';

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    subHeader: 'Centerline Portal',
    applicationList: [],
    unfliteredAppsList: [],
    selectedRoleId: null,
  },
  reducers: {
    setApps: (state, { payload }) => {
      state.applicationList = payload;
    },
    setAllApps: (state, { payload }) => {
      state.unfliteredAppsList = payload;
    },
    setSelectedRoleId: (state, { payload }) => {
      state.selectedRoleId = payload;
    },
  },
});

export default dashboardSlice.reducer;

export const { setApps, setAllApps, setSelectedRoleId } = dashboardSlice.actions;
