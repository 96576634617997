import React, { useEffect, useMemo, useState } from 'react';

import { MdWeb } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import getCSVData from './csv';
import { CSVLink } from 'react-csv';
import { getStates } from '../../DriverQualification/ListDQ/NewRequest/operations';
import { newDateFormatted } from '../../../utils';
import { generateReport, getReportData, removeReportData, getList } from '../operations';
import { DriverSexualHarassmentTrainingForm } from './Form';
import { Breadcrumbs, EdgeReportingWindow, SubHeader, ReportLoader } from '../../../components';
import DriverSexualHarassmentTrainingTable from './DriverSexualHarassmentTrainingTable';
import { Button } from 'react-bootstrap';
import Excel from '../../../assets/excel.png';
import { formatIsoToDate } from '../../../utils';
import './styles.scoped.css';

const DriverSexualHarassmentTraining = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [form, setForm] = useState({
        EmpStatus: '',
        EmpState: 'ca,il',
        EmpHoursGreaterThan: 0
    });
    const [driverStatuses, setDriverStatuses] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [loadingResults, setLoadingResults] = useState(false);
    const [isLoadingStates, setIsLoadingStates] = useState(false);
    const [locationData, setLocationData] = useState();

    const [isLoadingDriverStatuses, setIsLoadingDriverStatuses] = useState(false);
    const [driverStatusError, setDriverStatusError] = useState(false);
    const [empHoursGreaterThanError, setEmpHourGreaterThanError] = useState(false);

    const fetchDriverStatuses = async () => {
        setIsLoadingDriverStatuses(true);
        const driverStatusOptions = await getList('driver-status');
        setDriverStatuses(driverStatusOptions);
        setIsLoadingDriverStatuses(false);
    };

    const driverStatusOptions = useMemo(
        () => driverStatuses.map(({ REF_CODE, REF_DESCRIPTION }) => ({
            label: REF_DESCRIPTION,
            value: REF_CODE,
        })),
        [driverStatuses],
    );

    const fetchStateOptions = async () => {
        setIsLoadingStates(true);
        const states = await getStates();
        if (states) {
            const stateOptions = states?.filter(state => state.id === 'il' || state.id === 'ca')
                ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                .map(({ name, id }) => ({
                    label: name,
                    value: id,
                }));
            setLocationData({ ...locationData, states: stateOptions });
        }
        setIsLoadingStates(false);
    }

    const fileName = `sexual-harassment-training-report-${newDateFormatted.toString()}.csv`;

    const handleViewResults = async () => {
        if (formIsValid()) {
            setLoadingResults(true);
            Promise.all([
                generateReport({
                    endpoint: 'sexual-harassment-training-report',
                    payload: {
                        EmpStatus: form?.EmpStatus,
                        EmpState: form?.EmpState,
                        EmplCity: form?.EmplCity,
                        FromStartDate: form?.FromStartDate ? formatIsoToDate(form?.FromStartDate, 'YYYY-MM-DD') : '',
                        ToStartDate: form?.ToStartDate ? formatIsoToDate(form?.ToStartDate, 'YYYY-MM-DD') : '',
                        EmpHoursGreaterThan: form?.EmpHoursGreaterThan ?? '',
                        FromPerEnddate: form?.FromPerEnddate ? formatIsoToDate(form?.FromPerEnddate, 'YYYY-MM-DD') : '',
                        ToPerEnddate: form?.ToPerEnddate ? formatIsoToDate(form?.ToPerEnddate, 'YYYY-MM-DD') : '',
                        FromCheckDate: form?.FromCheckDate ? formatIsoToDate(form?.FromCheckDate, 'YYYY-MM-DD') : '',
                        ToCheckDate: form?.ToCheckDate ? formatIsoToDate(form?.ToCheckDate, 'YYYY-MM-DD') : '',
                    }
                })
            ]).then(values => {
                values.forEach((response, index) => {
                    if (response.location) {
                        getReportData(response.location).then(async reportData => {
                            if (reportData?.length) {
                                await removeReportData(response.location);
                                if (index === 0) {
                                    setData(reportData);
                                } else {
                                    setDataExport(reportData);
                                }
                                setShowResults(true);
                            }
                        });
                    } else if (response.statusCode !== 404) {
                        if (index === 0) {
                            setData(response);
                        } else {
                            setDataExport(response);
                        }
                        setShowResults(true);
                    } else {
                        // eslint-disable-next-line no-undef
                        alert('No data found matching your criteria.');
                    }
                });
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setLoadingResults(false);
            });
        }
    }

    const handleChange = (e) => {
        const _form = { ...form, [e.target.name]: e.target.value };
        setForm(_form);
    };

    const formIsValid = () => {
        let isValid = true;

        if (form?.EmpState === '' || null) {
            setDriverStatusError(true);
            isValid = false;
        }

        if (form?.EmpHoursGreaterThan <= 0 || null) {
            setEmpHourGreaterThanError(true);
            isValid = false;
        }
        return isValid;
    }

    useEffect(() => {
        fetchDriverStatuses();
        fetchStateOptions();
    }, []);

    return (
        <>
            <SubHeader
                text="EDGE Reporting"
                btnSmall={<MdWeb color="#fff" size={28} />}
                smallAction={() => navigate('/dashboard')}
                btnInfo
                infoText="Need help with your Client Search report?"
                emailAddress={process.env.REACT_APP_EDGE_REPORTING_CONTACT_EMAIL}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '30px',
                }}>
                <Breadcrumbs
                    leftTitle="Reporting Home"
                    middleTitle={showResults ? 'Driver Anti-Sexual Harassment Training' : 'Driver Sexual Harassment Training'}
                    midItemAction={() => setShowResults(false)}
                    rightTitle={showResults ? 'Viewing Report' : 'Edge Report'}
                    route="/edge-reporting"
                />
                {!!data?.length && showResults && (
                    <CSVLink filename={fileName} data={getCSVData(data)}>
                        <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
                    </CSVLink>
                )}
            </div>
            {loadingResults && <ReportLoader didAbort={() => setLoadingResults(false)} />}
            {showResults ? (
                <>
                    <DriverSexualHarassmentTrainingTable data={data} loading={loadingResults} />
                    <div className="buttons-row">
                        <Button onClick={() => setShowResults(false)} style={{ marginRight: '20px', width: '100px' }}>
                            <span>BACK</span>
                        </Button>

                        <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
                            <span>CANCEL</span>
                        </Button>
                    </div>
                </>
            ) : (
                <EdgeReportingWindow
                    containerStyle={{
                        border: '2px solid #343a40'
                    }}
                    windowContent={DriverSexualHarassmentTrainingForm({
                        form,
                        setForm,
                        locationData,
                        driverStatusOptions,
                        isLoadingStates,
                        isLoadingDriverStatuses,
                        handleViewResults,
                        handleChange,
                        navigate,
                        handleViewResults,
                        driverStatusError,
                        setDriverStatusError,
                        empHoursGreaterThanError,
                        setEmpHourGreaterThanError,
                    })}
                    windowTitle="Criteria"
                    headerStyle={{ backgroundColor: '#343a40' }}
                />
            )}
        </>
    );
}

export default DriverSexualHarassmentTraining;