import React, { useState } from 'react';

import { FormControlLabel } from '@mui/material';
import { Button, Col, Row, Spinner } from 'react-bootstrap';

import { getClient } from './operations';
import styles from './styles';
import { InputBox, Switch } from '../../../components';

import './styles.scoped.css';

const CustomerItem = ({ customer, customers, setCustomers }) => {
  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState(customer.customerId);

  const deleteRow = () => {
    const newList = [...customers.filter(item => item.id !== customer.id)];
    setCustomers(newList);
  };

  const updateCustomer = (attributes, values, anId = customer?.id) => {
    const newList = [...customers];
    let shouldUpdate = false;
    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      const attribute = attributes[i];
      const indexToChange = newList.findIndex(c => c.id === anId);
      if (newList[indexToChange][attribute] !== value) {
        shouldUpdate = true;
        newList[indexToChange] = { ...newList[indexToChange], [attribute]: value };
      }
    }
    if (shouldUpdate) setCustomers(newList);
  };

  const onTapOutside = () => {
    if (customerId) {
      setLoading(true);
      getClient(customerId)
        .then(response => {
          if (response.response_code === 200) {
            const { clientName, clientStatus } = response.response_body;
            updateCustomer(
              ['customerId', 'clientName', 'clientStatus', 'isValid', 'error'],
              [customerId, clientName, clientStatus, true, ''],
            );
          } else {
            updateCustomer(
              ['customerId', 'clientName', 'clientStatus', 'isValid', 'error'],
              [customerId, 'No Client Found', 'No Client Found', false, 'No Client Found'],
            );
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Row style={{ padding: 16, paddingBottom: 0 }}>
      <Col>
        <div style={styles.firstColumn}>
          <Button disabled={loading} style={styles.minus} onClick={deleteRow}>
            -
          </Button>
          <InputBox
            loading={loading}
            value={customerId}
            onChange={e => {
              setCustomerId(Number(e.target.value));
            }}
            onBlur={onTapOutside}
            errorMessage={customer?.error}
            error={customer?.error}
            type="number"
          />
        </div>
      </Col>
      <Col>
        <Col>
          <div className="label">
            {loading ? (
              <Spinner animation="border" size="sm" style={{ marginLeft: 48 }} />
            ) : customerId ? (
              customer?.clientName
            ) : (
              'Enter Client ID Number'
            )}
          </div>
        </Col>
      </Col>
      <Col>
        <Col>
          <div className="label">
            {loading ? (
              <Spinner animation="border" size="sm" style={{ marginLeft: 48 }} />
            ) : customerId ? (
              customer?.clientStatus
            ) : (
              'Enter Client ID Number'
            )}
          </div>
        </Col>
      </Col>
      <div style={{ width: 80 }}>
        <FormControlLabel
          control={
            <Switch
              disabled={!customer?.isValid}
              sx={{ m: 1 }}
              checked={customer?.active}
              onChange={e => updateCustomer(['active'], [e.target.checked])}
            />
          }
        />
      </div>
    </Row>
  );
};

export default CustomerItem;
