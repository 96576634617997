import React, { useEffect, useMemo, useState } from "react";

import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { getList, getBranchPushNotifications } from './operations';
import { PushNotificationsHeader, BranchPushNotificationsTable, SubHeader, Footer } from '../../../components';
import { Button, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Home } from '@mui/icons-material';
import "./styles.scoped.css";

const BranchPushNotifications = () => {
  const navigate = useNavigate();
  const { statuses } = useSelector(state => state.pushNotifications);
  const [form, setForm] = useState({});
  const [branches, setBranches] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoadingBranches, setIsLoadingBranches] = useState(false)
  const [notifications, setNotifications] = useState([]);

  let nextToken = null;
  let prevToken = null;

  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);

  const formFilters = () => {
    const filters = {};

    if (form) {
      filters.type = { eq: 'Branch' };
    }

    if (form.branch) {
      filters.branch = { contains: form?.branch };
    }

    if (form?.status !== undefined && form?.status !== "" && form?.status !== null) {
      filters.status = { eq: form?.status };
    }

    if (form?.createdAtStartDate && form?.createdAtEndDate) {
      const startDate = dayjs(form?.createdAtStartDate)
        .startOf('day')
        .toISOString();
      // Set the end date to the end of the day
      const endDate = dayjs(form?.createdAtEndDate)
        .endOf('day')
        .toISOString();
      filters.createdAt = { between: [`${dayjs.utc(startDate).format()}`, `${dayjs.utc(endDate).format()}`] };
    }

    return filters;
  }

  const getFilteredNotifications = async (token) => {
    const filters = formFilters();
    const notifications = await getBranchPushNotifications(
      filters,
      token,
    );

    return {
      list: notifications?.items,
      tokenResult: notifications?.nextToken,
    }
  }

  const getBatch = async (nextToken = undefined) => {
    if (prevToken !== nextToken) {
      prevToken = nextToken;

      const response = await getFilteredNotifications(nextToken);
      const items = response?.list;
      const token = response?.tokenResult;

      console.log('items:', items);
      console.log('token', token);
      if (token !== null) {
        getBatch(token);
        if (items) {
          setNotifications([items, ...items]);
        }
      } else {
        setIsSearching(false);
      }
    }
  }

  const handleSearch = async () => {
    setIsSearching(true);
    const response = await getFilteredNotifications(nextToken);

    if (Array.isArray(response?.list) && response?.list.length > 0) {
      setNotifications([...response?.list]);
      setIsSearching(false);
    } else {
      getBatch(response?.tokenResult);
    }

  }

  const handleGetBranches = async () => {
    setIsLoadingBranches(true);
    const response = await getList('branches');
    setBranches(response)
    setIsLoadingBranches(false);
  };

  const branchOptions = useMemo(
    () =>
      branches?.map(({ BranchName, Branch }) => ({
        label: BranchName,
        value: Branch,
      })),
    [branches],
  );

  const convertArrayToObject = (items) => {
    let arr = [];
    let branch = {};

    if (items) {
      String(items).split(', ')?.forEach(i => (
        branch = branches?.find(
          option => String(option.Branch) === i
        ),
        arr.push({
          label: branch?.BranchName, value: branch?.Branch
        })
      ))
    };

    return arr;
  }

  useEffect(() => {
    handleGetBranches();
    handleSearch();
  }, [])

  return (
    <>
      <SubHeader
        text={"Push Notifications"}
        btnSmall={<Home sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigate('/push-notifications')}
      />

      <div className="wrap">
        <PushNotificationsHeader
          form={form}
          setForm={setForm}
          branches={branchOptions}
          isLoadingBranches={isLoadingBranches}
          setIsSearching={setIsSearching}
          setNotifications={setNotifications}
          route={`add-branch-push-notification`}
          statuses={statuses}
          title={"Branch Push Notifications"}
          handleSearch={handleSearch}
          convertArrayToObject={convertArrayToObject}
        />

        {Array.isArray(notifications) && !isSearching ? (
          <BranchPushNotificationsTable
            data={notifications}
            editRoute={'/push-notifications/branch-push-notifications/edit-branch-push-notification'}
          />
        ) : (
          <div className="h-100 d-flex justify-content-center p-5">
            <Spinner animation="border" />
          </div>
        )
        }

        <Row className="justify-content-center">
          <Button
            className="button-secondary"
            onClick={() => { navigate('/push-notifications') }}
          >
            Cancel
          </Button>
        </Row>

      </div>

      <Footer />
    </>
  );
}

export default BranchPushNotifications;
