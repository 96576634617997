import axios from 'axios';

// headers: {"Access-Control-Allow-Origin": "*"}
const quoteApi = axios.create({
  baseURL: process.env.REACT_APP_QUOTE_ENGINE_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default quoteApi;
