import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { MdOutlineArrowBack } from 'react-icons/md';
import './styles.scoped.css';

const AccessList = ({
  onClose,
  userApps = [],
  makeEdit,
  loading = false,
  editing = false,
  userType = 'Client',
  appAdminType,
  isGlobalAdmin,
}) => {
  const unfliteredAppsData = useSelector(
    state => state.dashboard.unfliteredAppsList,
  );
  const [appsData, setAppsData] = useState([]);
  const [selectedApp, setSelectedApp] = useState(-1);
  const [selectedRole, setSelectedRole] = useState(-1);
  const [step, setStep] = useState(0);
  const [title, setTitle] = useState('Add Application Access');
  const [appRoles, setAppRoles] = useState([]);

  useEffect(() => {
    if (selectedApp && selectedApp > -1) {
      const roles = appsData.find(item => item.id === selectedApp);
      setAppRoles(
        roles?.roles?.items.filter(
          role => role.usedBy === userType || role.usedBy === 'All',
        ),
      );
    }
  }, [selectedApp, appsData]);

  useEffect(() => {
    if (unfliteredAppsData && userApps) {
      const idsToRemove = [];
      userApps.forEach(app => {
        idsToRemove.push(app.appId.id);
      });
      const difference = unfliteredAppsData.filter(
        x => !idsToRemove.includes(x.id),
      );
      const filtered = difference.filter(
        app =>
          app.roles.items.filter(
            role => role.usedBy === userType || role.usedBy === 'All',
          ).length > 0,
      );
      setAppsData(filtered);
    }
  }, [unfliteredAppsData, userApps]);

  useEffect(() => {
    const editApp = unfliteredAppsData.filter(x => x.id === editing);
    if (editing) {
      setAppsData(editApp);
      setSelectedApp(editing);
    }
  }, [editing]);

  const next = () => {
    if (selectedApp < 0) {
      alert('Please choose an appplication.');
      return;
    }
    if (step < 1) {
      setStep(1);
      setTitle(
        `Set ${appsData.find(item => item.id === selectedApp).name} Role`,
      );
    } else if (selectedRole > -1) {
      makeEdit({ selectedApp, selectedRole });
    } else {
      alert('Please choose a role.');
    }
  };

  const back = () => {
    setStep(0);
    setSelectedRole(-1);
    setTitle('Add Application Access');
  };

  return (
    <Modal show>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="scroll-box__wrapper">
          <div className="scroll-box__container" role="list">
            <div
              className="scroll-box__item"
              role="listitem"
              key={`scroll-box-item-0}`}
              style={{
                marginLeft: `-${step * 100}%`,
                transition: 'margin 400ms',
              }}
            >
              <form onChange={e => setSelectedApp(e.target.value)}>
                {appsData
                  .filter(
                    x =>
                      !userApps.includes(x) &&
                      (isGlobalAdmin || x?.id === appAdminType),
                  )
                  .map((item, i) => (
                    <p className="line" key={item.id}>
                      <input
                        readOnly
                        checked={selectedApp === item.id}
                        className="margin-right"
                        type="radio"
                        value={item.id}
                        name={item.name}
                      />
                      <span
                        onClick={() => setSelectedApp(item.id)}
                        className={`${selectedApp === item.id
                          ? 'selected pointer'
                          : 'pointer'
                          }`}
                      >
                        {item.name}
                      </span>
                    </p>
                  ))}
              </form>
            </div>
            <div
              className="scroll-box__item"
              role="listitem"
              key={`scroll-box-item-1}`}
            >
              <form onChange={e => setSelectedRole(e.target.value)}>
                {appRoles?.map((item, i) => (
                  <p className="line" key={item.id}>
                    <input
                      readOnly
                      checked={selectedRole === item.id}
                      className="margin-right"
                      type="radio"
                      value={item.id}
                      name={item.name}
                    />
                    <span
                      onClick={() => setSelectedRole(item.id)}
                      className={`${selectedRole === item.id
                        ? 'selected pointer'
                        : 'pointer'
                        }`}
                    >
                      {item.name}
                    </span>
                  </p>
                ))}
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ flex: 1, flexDirection: 'row' }}>
          {step === 1 ? (
            <MdOutlineArrowBack size={32} className="pointer" onClick={back} />
          ) : null}
        </div>
        <Button
          variant="primary"
          style={{ backgroundColor: '#da0f2e', borderColor: '#da0f2e ' }}
          onClick={next}
        >
          {loading ? <Spinner animation="border" size="sm" /> : 'Next'}
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccessList;
