import { createSlice } from '@reduxjs/toolkit';

const viewQuoteSlice = createSlice({
  name: 'viewQuote',
  initialState: {
    selectedQuote: null,
    loading: false,
  },
  reducers: {
    setSelectedQuote: (state, { payload }) => {
      state.selectedQuote = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

export default viewQuoteSlice.reducer;

export const { setSelectedQuote, setLoading } = viewQuoteSlice.actions;
