import { createSlice } from '@reduxjs/toolkit';

const standardDocumentSlice = createSlice({
    name: 'standardDocument',
    initialState: {
        clientData: {},
        driverData: {},
        category: '',
        type: '',
        driver: '',
        isClient: '',
        documentTypes: [],
        documentCategories: [],
        documentCategoryTypeId: '',
    },
    reducers: {
        setClientData: (state, { payload }) => {
            state.clientData = payload;
        },
        setDriverData: (state, { payload }) => {
            state.driverData = payload;
        },
        setCategory: (state, { payload }) => {
            state.category = payload;
        },
        setType: (state, { payload }) => {
            state.type = payload;
        },
        setClient: (state, { payload }) => {
            state.isClient = payload;
        },
        setDriver: (state, { payload }) => {
            state.driver = payload;
        },
        setDocumentCategories: (state, { payload }) => {
            state.documentCategories = payload;
        },
        setDocumentTypes: (state, { payload }) => {
            state.documentTypes = payload;
        },
        setDocumentCategoryTypeId: (state, { payload }) => {
            state.documentCategoryTypeId = payload;
        },
    },
});

export default standardDocumentSlice.reducer;

export const { setCategory, setType, setClient, setClientData, setDriverData, setDocumentCategoryTypeId, setDocumentCategories, setDocumentTypes } = standardDocumentSlice.actions;

