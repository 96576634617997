import React, { useEffect, useState } from 'react';

import { Storage } from 'aws-amplify';
import FileSaver from 'file-saver';
import { Row, Form } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';

import { formatIsoToDate } from '../../../utils';

import './Airline/styles.scoped.css';

const DocumentRow = ({ receipt, id, deleteDocumentFromReceipt, disabled }) => {
  const hasId = id === 0 || !!id;
  const [currentFile, setCurrentFile] = useState(null);
  const handleGenerateFile = async () => {
    const config = {
      expires: process.env.REACT_APP_AMPLIFY_STORAGE_GET_EXPIRES,
      level: 'public',
      responseContentType: 'application/octet-stream',
      download: true,
    };
    const file = await Storage.get(`expense/${receipt?.documentName}`, config);
    if (file) {
      setCurrentFile(file);
    }
  };

  const handleDownload = () => {
    FileSaver.saveAs(currentFile.Body, receipt?.documentName);
  };

  useEffect(() => {
    if (receipt?.documentName) {
      handleGenerateFile();
    }
  }, [receipt?.documentName]);

  return (
    <Row className="top-row" style={{ margin: '5px 0 0 0' }}>
      <div
        className="top-col"
        style={{
          width: hasId ? '40%' : 'auto',
          justifyContent: 'flex-start',
        }}>
        <div className={hasId ? 'below-container' : ''}>
          <div
            className={hasId ? 'below-container' : ''}
            style={{
              justifyContent: 'flex-start',
              width: hasId ? '300px' : '364px',
            }}>
            <Form.Group controlId="formFlightDate" style={{ display: 'flex', flexDirection: 'column' }}>
              <Form.Label style={{ marginTop: '15px' }}>Document Name</Form.Label>
              {currentFile && (
                <input
                  onClick={handleDownload}
                  value={receipt?.documentName}
                  readOnly
                  style={{
                    all: 'unset',
                    color: '#da0f2e',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                    pointerEvents: 'all',
                  }}
                />
              )}
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="top-col">
        <Form.Group controlId="uploadedByName">
          <Form.Label style={{ marginTop: '15px' }}>Upload By</Form.Label>
          <Form.Control
            style={{
              height: '30px',
              padding: 0,
              border: '0px',
              backgroundColor: 'white',
            }}
            name="uploadedByName"
            as="input"
            readOnly
            value={receipt?.uploadedByName}
          />
        </Form.Group>
      </div>
      <div className="top-col" style={{ width: '200px' }}>
        <Form.Group controlId="uploadedDate">
          <Form.Label style={{ marginTop: '15px' }}>Uploaded Date</Form.Label>
          <Form.Control
            style={{
              height: '30px',
              padding: 0,
              border: '0px',
              backgroundColor: 'white',
            }}
            name="flightDate"
            as="input"
            readOnly
            value={formatIsoToDate(receipt?.uploadedDate)}
          />
        </Form.Group>
      </div>
      {!disabled && (
        <MdDelete
          color="#da0f2e"
          size={30}
          cursor="pointer"
          onClick={() => (hasId ? deleteDocumentFromReceipt(id) : deleteDocumentFromReceipt())}
        />
      )}
    </Row>
  );
};

export default DocumentRow;
