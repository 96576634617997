import React, { useEffect } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Amplify, Auth } from 'aws-amplify';
import { useIdleTimer } from 'react-idle-timer';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { setVersion } from './app/appSlice';
import { Header } from './components';
import Routes from './routes';
import data from '../package.json';
import './App.scss';

const App = () => {
  const dispatch = useDispatch();
  const { storedVersion } = useSelector(state => state.app);
  const { version } = data;
  const { route } = useAuthenticator(context => [context.route]);
  const { signOut } = useAuthenticator(context => [context.signOut]);
  Amplify.configure({
    API: {
      endpoints: [
        {
          name: 'centerlineportal',
          endpoint: `${process.env.REACT_APP_BASE_URL}`,
          custom_header: async () => ({
            Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          }),
        },
      ],
    },
  });

  useIdleTimer({
    timeout: process.env.REACT_APP_TIMEOUT_MINS * 60000,
    onIdle: () => {
      if (route === 'authenticated') {
        signOut();
      }
    },
    debounce: 500,
  });

  useEffect(() => {
    versionCheck();
  }, [storedVersion, version]);

  const versionCheck = () => {
    if (semverGreaterThan(version, storedVersion)) {
      refreshCacheAndReload();
    }
  };

  const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());
      const b = Number(versionsB.shift());
      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
    }
    return false;
  };

  const refreshCacheAndReload = async () => {
    try {
      // eslint-disable-next-line no-undef
      if (window?.caches) {
        // eslint-disable-next-line no-undef
        const { caches } = window;
        const cacheNames = await caches.keys();
        const cacheDeletionPromises = cacheNames.map(n => caches.delete(n));
        await Promise.all(cacheDeletionPromises);
        console.log('The cache has been deleted.');
        // @ts-ignore: Firefox still has a `forceReload` parameter.
        // eslint-disable-next-line no-undef
        window.location.reload(true);
        dispatch(setVersion(version));
      }
    } catch (error) {
      console.log('An error occurred while deleting the cache.', true);
      console.log(error, true);
    }
  };

  return (
    <div className="root">
      <BrowserRouter>
        <Header />
        <div style={{ overflow: 'scroll' }}>
          <Routes />
        </div>
      </BrowserRouter>
    </div>
  );
};
export default App;
