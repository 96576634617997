import { formatIsoToDate } from '../../../utils';

const getCSVData = data => {
  const csvData = [
    [
      'Branch',
      'LOB',
      'Customer Number',
      'Customer Name',
      'Status',
      'Phone',
      'SPC',
      'Bill To #',
      'Bill To Name',
      'Address Line 1',
      'Address Line 2',
      'City',
      'State',
      'ZIP',
      'Industry',
      'Territory',
      'Location',
      'Email',
      'Website',
      'Referral Source',
      'Date Entered',
      'Date Last Updated',
      'AMC',
    ],
  ];
  let i;
  for (i = 0; i < data.length; i += 1) {
    csvData.push([
      `${data[i].Branch || ''}`,
      `${data[i].LOB || ''}`,
      `${data[i].CUST_ID || ''}`,
      `${data[i].CUST_NAME || ''}`,
      `${data[i].CUST_STATUS || ''}`,
      `${data[i].NUM_PHONE || ''}`,
      `${data[i].SPC || ''}`,
      `${data[i].Bill_To || ''}`,
      `${data[i].Bill_To_Client || ''}`,
      `${data[i].Address_Line_1 || ''}`,
      `${data[i].Address_Line_2 || ''}`,
      `${data[i].CUST_CITY || ''}`,
      `${data[i].CUST_STATE || ''}`,
      `${data[i].CUST_POSTCODE || ''}`,
      `${data[i].DET_INDUSTRY || ''}`,
      `${data[i].DET_TERRITORY || ''}`,
      `${data[i].DET_LOCATION || ''}`,
      `${data[i].NUM_EMAILADDRESS || ''}`,
      `${data[i].Web_Site || ''}`,
      `${data[i].Referral_Source || ''}`,
      `${formatIsoToDate(data[i].Date_Entered) || ''}`,
      `${formatIsoToDate(data[i].Date_LastUpdated) || ''}`,
      `${data[i].AMC || ''}`,
    ]);
  }
  return csvData;
};

export default getCSVData;
