import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Checkbox, Radio } from '@mui/material';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { debounce } from 'lodash';
import { Row, Form, InputGroup, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import { getCitiesFiltered } from './operations';
import Receipt from './Receipt';
import SharedReceipt from './SharedReceipt';
import { ExpenseReceiptUploader } from '../../../../components';
import ExpenseForm from '../../../../components/ExpenseForm';
import InvoiceDataRow from '../../../../components/InvoiceDataRow';
import { formatIsoToDate } from '../../../../utils';
import {
  DisableClickOverlay,
  formatPhoneNumber,
  getCurrencyString,
  isBilledOrTransmitted,
} from '../../../../utils/utilities';
import DocumentRow from '../documentRow';
import {
  getCalculatedVariance,
  handleCreateVoidExpense,
  handleRebillExpense,
  handleVoidAndRebillExpense,
} from '../expensesUtils';
import { uploadExpenseFile } from '../operations';
import { preventMultipleSave } from '../TransferToUltraStaff/utils';

import './styles.scoped.css';

dayjs.extend(customParseFormat);

const EditFormContent = ({
  user,
  formData,
  form,
  singleOrShared,
  errors,
  isLoading,
  isLoadingCustomers,
  isLoadingBranches,
  setUploading,
  setUploadError,
  sharedReceipts,
  receipts,
  totalToBeBilled,
  auditKey,
  setSharedReceipts,
  sharedReceiptErrors,
  setSharedReceiptsErrors,
  receiptsErrors,
  deleteExpenseFile,
  setForm,
  expenseDate,
  id,
  isReadyToBill,
  setShowConfirmRTB,
  hasChanges,
  setHasChanges,
  setErrors,
  setReceipts,
  setShowCancelDialog,
  navigateCancelAction,
  setSingleOrShared,
  setIsLoading,
  fieldsAreValid,
  confirmEditExpense,
  receiptsHaveFiles,
  handleVerifyConfirm,
  setShowReceiptFilesDialog,
  setErrorModalOpened,
  clients,
  branches,
  deletedReceipts,
  setDeletedReceipts,
  deletedReceiptDocs,
  setDeletedReceiptDocs,
  currentUser,
  setReceiptsErrors,
  setReceiptModified,
  setShowDeleteConfirmation,
  assignmentLineOfBusinessItems,
}) => {
  const navigate = useNavigate();

  const [saveDisabled, setSaveDisabled] = useState(false);
  const [inputSave, setSave] = useState('');
  const [formattedPhoneNumber, setFormPhoneNumber] = useState('');
  const newStateRef = useState();
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const isSettingState = useRef([]);
  const isSettingCity = useRef([]);
  const rebilled = useRef(false);
  const hasSetFormCityOptions = useRef(false);

  const formHasChanged = useRef({
    drivers: false,
    assignments: false,
    clients: false,
    branches: false,
  });

  let currentCities = [];
  let prevToken = '';

  const setDebouncedValue = debounce(r => {
    setReceiptField(r.id, r.field, r.value, r.receipts);
  }, 1000);

  const debouncedSetReceiptField = useCallback(value => setDebouncedValue(value), []);

  const getCitiesBatch = async (nextToken = null) => {
    if (prevToken !== nextToken) {
      setIsLoadingCities(true);
      prevToken = nextToken;
      const data = await getCitiesFiltered({
        state: newStateRef.current[0]?.fieldValue,
        token: nextToken,
      });
      const token = data?.nextToken;

      if (data?.items) {
        const items = data?.items;
        currentCities = currentCities.concat(items);
      }
      if (token) {
        getCitiesBatch(token);
      } else {
        setIsLoadingCities(false);
        setField([
          ...newStateRef.current,
          {
            fieldName: 'selectedCities',
            fieldValue: currentCities,
          },
        ]);
      }
    }
  };

  const handleStateChange = async newVal => {
    newStateRef.current = newVal;
    await getCitiesBatch();
  };

  const formHotelOptions = useMemo(() => {
    const hotelOptions = formData?.hotels
      ?.filter(h => h.status === 'Active')
      ?.sort((a, b) => (a.name > b.name ? 1 : -1))
      .map(({ name, id: scopedId }) => ({
        label: name,
        value: scopedId,
      }));

    const result = hotelOptions || [];
    if (singleOrShared === 'shared') {
      if (form.expenseHotelId && form.selectedHotel && !hotelOptions.find(ho => ho.value === form.expenseHotelId)) {
        result.push({
          label: form.selectedHotel,
          value: form.expenseHotelId,
        });
      }
    }
    result?.sort((a, b) => (a.label > b.label ? 1 : -1));
    return result;
  }, [formData?.hotels, singleOrShared, form?.selectedHotel, form?.expenseHotelId]);

  const formStateOptions = formData?.states
    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(({ name, id: scopedId }) => ({
      label: name,
      value: scopedId,
    }));

  const formCityOptions = useMemo(() => {
    const cityOptions =
      form?.selectedCities
        ?.filter(h => h.status === 'Active')
        .map(({ name, id: scopedId }) => ({
          label: name,
          value: scopedId,
        })) || [];

    const result = cityOptions || [];

    if (
      form.expenseHotelCityId &&
      form.selectedCity &&
      !cityOptions.find(ao => ao.value === form.expenseHotelCityId) &&
      form.expenseHotelStateId === form?.selectedCityState
    ) {
      result.push({
        label: form.selectedCity,
        value: form.expenseHotelCityId,
      });
    }

    result.sort((a, b) => (a.label > b.label ? 1 : -1));
    return result;
  }, [
    form?.selectedCities,
    form?.expenseHotelCityId,
    form?.selectedCity,
    form?.expenseHotelStateId,
    form?.selectedCityState,
  ]);

  const deleteDocumentFromForm = async () => {
    const formCopy = {
      ...form,
    };
    await deleteExpenseFile(formCopy.documentName);
    if (formCopy?.uploadedDate) delete formCopy.uploadedDate;
    if (formCopy?.documentName) delete formCopy.documentName;
    if (formCopy?.documentSize) delete formCopy.documentSize;
    if (formCopy?.uploadedByName) delete formCopy.uploadedByName;

    setForm(formCopy);
  };

  const setField = (fields, value) => {
    if (!hasChanges) {
      setHasChanges(true);
    }
    if (Array.isArray(fields)) {
      const itemsToAdd = {};
      const newErrors = { ...errors };
      fields.forEach(field => {
        itemsToAdd[(field?.fieldName)] = field.fieldValue;

        if (errors && !!errors[field.fieldName]) delete newErrors[(field?.fieldName)];
      });
      setForm({
        ...form,
        ...itemsToAdd,
      });
      setErrors({
        ...newErrors,
      });
    } else {
      if (fields === 'nonBillable' && value === true) {
        setForm({
          ...form,
          [fields]: value,
          status: 'Non-billable',
        });
      } else if (fields === 'nonBillable' && value === false) {
        setForm({
          ...form,
          [fields]: value,
          status: 'Unbilled',
        });
      } else if (fields === 'flatFeeBilling' && value === false) {
        setForm({
          ...form,
          [fields]: value,
          nonBillable: false,
          status: 'Unbilled',
          code: 'XH',
        });
      } else if (fields === 'flatFeeBilling' && value === true) {
        setForm({
          ...form,
          [fields]: value,
          nonBillable: true,
          status: 'Flat Fee Billing',
          code: `XFFBH${singleOrShared === 'shared' ? 'S' : ''}`,
        });
      } else {
        setForm(prevState => ({
          ...prevState,
          [fields]: value,
        }));
      }

      if (errors && !!errors[fields]) {
        const errorsCopy = {
          ...errors,
        };
        delete errorsCopy[fields];
        setErrors(errorsCopy);
      }
    }
  };

  const getTotalProjectedAmount = newReceipts => {
    const projectedAmounts = newReceipts.reduce((result, item) => {
      if (item?.projectedAmount) {
        result += item?.projectedAmount || 0;
      }
      return result;
    }, 0);
    setField('totalProjectedAmount', parseFloat(projectedAmounts).toFixed(2));
  };

  const setReceiptField = (scopedId, field, value, scopedReceipts) => {
    const receiptsCopy = [...scopedReceipts];
    if (field === 'receiptAmount') {
      if (receiptsCopy[scopedId]?.markup) {
        const multiplier = (1 + parseFloat(receiptsCopy[scopedId]?.markup).toFixed(2) / 100).toFixed(2);

        receiptsCopy[scopedId].expenseTotal = parseFloat(value) * multiplier;
      } else {
        receiptsCopy[scopedId].expenseTotal = value;
      }
    }
    if (field === 'markup' && receiptsCopy[scopedId]?.receiptAmount) {
      const multiplier = (1 + parseFloat(value).toFixed(2) / 100).toFixed(2);

      receiptsCopy[scopedId].expenseTotal = parseFloat(receiptsCopy[scopedId]?.receiptAmount) * multiplier;
    }
    if (
      (field === 'checkInDate' && receiptsCopy[scopedId]?.lastNightOfStay) ||
      (field === 'lastNightOfStay' && receiptsCopy[scopedId]?.checkInDate)
    ) {
      let difference;
      if (field === 'checkInDate') {
        difference = dayjs(receiptsCopy[scopedId]?.lastNightOfStay)?.diff(value, 'day') || 0;
      }
      if (field === 'lastNightOfStay') {
        difference = dayjs(value)?.diff(receiptsCopy[scopedId]?.checkInDate, 'day') || 0;
      }
      if (difference && receiptsCopy[scopedId]?.dailyCharge) {
        receiptsCopy[scopedId].projectedAmount = (difference + 1) * receiptsCopy[scopedId].dailyCharge;
        getTotalProjectedAmount(receiptsCopy);
      }
      receiptsCopy[scopedId][field] = value;
      receiptsCopy[scopedId].projectedDays = difference + 1;
      setReceipts(receiptsCopy);
    } else {
      if (field === 'dailyCharge' && receiptsCopy[scopedId].projectedDays) {
        receiptsCopy[scopedId].projectedAmount = receiptsCopy[scopedId].projectedDays * value;
        getTotalProjectedAmount(receiptsCopy);
      }
      receiptsCopy[scopedId][field] = value;
      setReceipts(receiptsCopy);
    }
  };

  const isPartitionExpense = form?.code === 'XHS' && form?.status === 'Ready to Bill';

  const handleCancel = async () => (hasChanges ? setShowCancelDialog(true) : navigateCancelAction());

  const handleRadioButtonChange = event => {
    setSingleOrShared(event.target.value);
  };

  const getTotalToBeBilledColor = () => {
    let color = 'transparent';
    if (!isPartitionExpense && singleOrShared === 'shared') {
      if (
        totalToBeBilled === form?.totalReceiptAmount?.toString() &&
        (form?.totalReceiptAmount?.toString() !== '0' && form?.totalReceiptAmount?.toString() !== '0.00')
      ) {
        color = '#caf982';
      } else {
        color = '#eec6bd';
      }
    }
    return color;
  };

  const handleEditExpense = async () => {
    try {
      preventMultipleSave(setSaveDisabled);
      if (form?.isVoided || form?.isVoidedRebilled || rebilled.current && assignmentLineOfBusinessItems.to) {
        if (form?.isVoided) {
          if (singleOrShared === 'shared') {
            await handleCreateVoidExpense({
              id: id,
              expense: form,
              receipts: sharedReceipts,
              auditKey: auditKey,
              totalToBeBilled: totalToBeBilled,
              setIsLoading: setIsLoading,
              setForm: setForm,
              navigate: navigate,
              expenseType: 'hotel',
              updatedBy: user.id,
              route: assignmentLineOfBusinessItems.to,
            });
          } else {
            await handleCreateVoidExpense({
              id: id,
              expense: form,
              receipts: receipts,
              auditKey: auditKey,
              totalToBeBilled: totalToBeBilled,
              setIsLoading: setIsLoading,
              setForm: setForm,
              navigate: navigate,
              expenseType: 'hotel',
              updatedBy: user.id,
              route: assignmentLineOfBusinessItems.to,
            });
          }
        }
        if (rebilled.current && assignmentLineOfBusinessItems.to) {
          if (singleOrShared === 'shared') {
            await handleRebillExpense({
              id: id,
              expense: form,
              receipts: sharedReceipts,
              setIsLoading: setIsLoading,
              navigate: navigate,
              expenseType: 'hotel',
              updatedBy: user.id,
              route: assignmentLineOfBusinessItems.to,
            });
          } else {
            await handleRebillExpense({
              id: id,
              expense: form,
              receipts: receipts,
              setIsLoading: setIsLoading,
              navigate: navigate,
              expenseType: 'hotel',
              updatedBy: user.id,
              route: assignmentLineOfBusinessItems.to,
            });
          }
        }

        if (form?.isVoidedRebilled && assignmentLineOfBusinessItems.to) {
          if (singleOrShared === 'shared') {
            await handleVoidAndRebillExpense({
              id: id,
              expense: form,
              receipts: sharedReceipts,
              auditKey: auditKey,
              totalToBeBilled: totalToBeBilled,
              setIsLoading: setIsLoading,
              setForm: setForm,
              navigate: navigate,
              expenseType: 'hotel',
              updatedBy: user.id,
              route: assignmentLineOfBusinessItems.to
            });
          } else {
            await handleVoidAndRebillExpense({
              id: id,
              expense: form,
              receipts: receipts,
              auditKey: auditKey,
              totalToBeBilled: totalToBeBilled,
              setIsLoading: setIsLoading,
              setForm: setForm,
              navigate: navigate,
              expenseType: 'hotel',
              updatedBy: user.id,
              route: assignmentLineOfBusinessItems.to
            });
          }
        }
      } else if (fieldsAreValid()) {
        if (form?.status === 'Non-billable') {
          await confirmEditExpense({
            route: `${assignmentLineOfBusinessItems.to}/search-expenses`
          });
        } else if (
          (receiptsHaveFiles() && singleOrShared === 'single') ||
          (singleOrShared === 'shared' && form.documentName)
        ) {
          if (form?.code === 'XHSR') {
            await confirmEditExpense({
              rebilledShared: true,
              route: assignmentLineOfBusinessItems.to,
            });
          } else if (
            (singleOrShared === 'shared' &&
              (totalToBeBilled === form?.totalReceiptAmount?.toString() &&
                form?.totalReceiptAmount?.toString() !== '0' &&
                form?.totalReceiptAmount?.toString() !== '0.00')) ||
            singleOrShared === 'single'
          ) {
            await handleVerifyConfirm();
          } else {
            await confirmEditExpense({
              isFF: form?.flatFeeBilling,
              route: assignmentLineOfBusinessItems.to,
            });
          }
        } else {
          setShowReceiptFilesDialog(true);
        }
      } else {
        setErrorModalOpened(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const clientOptions = clients?.map(({ CUST_ID, CUST_NAME }) => ({
    label: `${CUST_NAME} - ${CUST_ID}`,
    value: CUST_ID,
  }));

  const branchOptions = branches?.map(({ Branch_Code, Branch_Name }) => ({
    label: `${Branch_Name} - ${Branch_Code}`,
    value: Branch_Code,
  }));

  const handleAddReceipt = () => {
    if (singleOrShared === 'shared') {
      setSharedReceipts([...sharedReceipts, { markup: '0' }]);
    } else {
      setReceipts([...receipts, { markup: '0' }]);
    }
  };

  const handleDeleteRow = scopedId => {
    let allReceipts = [...receipts];

    if (singleOrShared === 'shared') allReceipts = [...sharedReceipts];
    if (!deletedReceipts?.includes(allReceipts[scopedId])) {
      setDeletedReceipts([...deletedReceipts, allReceipts[scopedId]]);
    }
    if (!deletedReceiptDocs?.includes(allReceipts[scopedId].documentName)) {
      setDeletedReceiptDocs([...deletedReceiptDocs, allReceipts[scopedId].documentName]);
    }
    const receiptsCopy = allReceipts.filter((r, index) => index !== scopedId);
    if (singleOrShared === 'shared') {
      setSharedReceipts(receiptsCopy);
    } else {
      setReceipts(receiptsCopy);
    }
    getTotalProjectedAmount(receipts);
  };

  const setReceiptFile = async ({ id: scopedId, fileName, fileSize }) => {
    const receiptsCopy = [...receipts];
    receiptsCopy[scopedId].uploadedDate = new Date().toISOString();
    receiptsCopy[scopedId].documentName = fileName;
    receiptsCopy[scopedId].documentSize = Math.round(fileSize);
    receiptsCopy[scopedId].uploadedByName = currentUser?.name;

    const receiptsErrorsCopy = [...receiptsErrors];

    if (receiptsErrorsCopy[scopedId]?.uploadedDate) delete receiptsErrorsCopy[scopedId].uploadedDate;

    if (receiptsErrorsCopy[scopedId]?.documentName) delete receiptsErrorsCopy[scopedId].documentName;

    if (receiptsErrorsCopy[scopedId]?.uploadedByName) delete receiptsErrorsCopy[scopedId].uploadedByName;

    if (receiptsErrorsCopy.length) {
      receiptsErrorsCopy.splice(scopedId, 1);
    }

    setReceiptsErrors(receiptsErrorsCopy);
    setReceipts(receiptsCopy);
    setReceiptModified(true);
  };

  const setFormReceipt = async ({ fileName, fileSize }) => {
    const formCopy = {
      ...form,
    };
    formCopy.uploadedDate = new Date().toISOString();
    formCopy.documentName = fileName;
    formCopy.documentSize = Math.round(fileSize);
    formCopy.uploadedByName = currentUser?.name;

    const formErrorsCopy = {
      ...errors,
    };

    if (formErrorsCopy?.uploadedDate) delete formErrorsCopy.uploadedDate;

    if (formErrorsCopy?.documentName) delete formErrorsCopy.documentName;

    if (formErrorsCopy?.uploadedByName) delete formErrorsCopy.uploadedByName;

    setErrors(formErrorsCopy);
    setForm(formCopy);
  };

  const customStyles = width => ({
    control: provided => ({
      ...provided,
      width: width,
      height: '30px',
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: '200px',
    }),
  });

  const deleteDocumentFromReceipt = async scopedId => {
    const receiptsCopy = [...receipts];

    if (!deletedReceiptDocs?.includes(receipts[scopedId].documentName)) {
      setDeletedReceiptDocs([...deletedReceiptDocs, receipts[scopedId].documentName]);
    }

    receiptsCopy[scopedId].uploadedDate = null;
    receiptsCopy[scopedId].documentName = null;
    receiptsCopy[scopedId].uploadedByName = null;

    setReceipts(receiptsCopy);
    setReceiptModified(true);
    setForm({
      ...form,
      status: 'Unbilled'
    });
  };

  const setReceiptFields = (scopedId, fields) => {
    const receiptsCopy = [...receipts];
    fields.forEach(field => {
      receiptsCopy[scopedId][(field?.fieldName)] = field.fieldValue;
    });
    setReceipts(receiptsCopy);
  };

  const getTopComponent = () => {
    if (isBilledOrTransmitted(form) && !form?.originalExpenseId) {
      return (
        <Form.Group
          controlId="void"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <Form.Label
            style={{
              marginTop: '15px',
            }}>
            Void
          </Form.Label>

          <Checkbox
            style={{
              color: 'red',
              height: 0,
              width: 0,
              marginTop: '5px',
              pointerEvents: 'all',
            }}
            onChange={event => {
              setField('isVoided', event.target.checked);
            }}
            checked={!!form?.isVoided}
            defaultValue={false}
          />
        </Form.Group>
      );
    }
    if (form?.correctionExpenseId && form?.status === 'Voided') {
      return (
        <Form.Group controlId="correctionExpenseId">
          <Form.Label style={{ marginTop: '15px' }}>Correction Expense ID</Form.Label>
          <Form.Control
            style={{
              height: '30px',
              padding: 0,
              border: '0px',
              backgroundColor: 'white',
              opacity: '0.7',
            }}
            as="input"
            name="status"
            readOnly
            value={form?.correctionExpenseId}
            disabled
          />
        </Form.Group>
      );
    }
    if (!id?.includes('CR')) {
      return (
        <Form.Group
          controlId="nonBillable"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <Form.Label
            style={{
              marginTop: '15px',
            }}>
            Non-billable
          </Form.Label>

          <Checkbox
            style={{
              color: form?.flatFeeBilling ? 'gray' : 'red',
              height: 0,
              width: 0,
              marginTop: '5px',
            }}
            onChange={event => {
              setField('nonBillable', event.target.checked);
            }}
            disabled={!!form?.flatFeeBilling}
            checked={!!form?.nonBillable}
            defaultValue={false}
          />
        </Form.Group>
      );
    }
    return undefined;
  };

  const getBottomComponent = () => {
    if (form?.originalExpenseId) {
      return (
        <Form.Group controlId="originalExpenseId">
          <Form.Label style={{ marginTop: '15px' }}>Original Expense ID</Form.Label>
          <Form.Control
            style={{
              height: '30px',
              padding: 0,
              border: '0px',
              backgroundColor: 'white',
              opacity: '0.7',
            }}
            as="input"
            name="status"
            readOnly
            value={form?.originalExpenseId}
            disabled
          />
        </Form.Group>
      );
    }

    if (form?.rebilledExpenseId) {
      return (
        <Form.Group controlId="rebilledExpenseId">
          <Form.Label style={{ marginTop: '15px' }}>Rebilled Expense ID</Form.Label>
          <Form.Control
            style={{
              height: '30px',
              padding: 0,
              border: '0px',
              backgroundColor: 'white',
              opacity: '0.7',
            }}
            as="input"
            name="status"
            readOnly
            value={form?.rebilledExpenseId}
            disabled
          />
        </Form.Group>
      );
    }

    if (isBilledOrTransmitted(form)) {
      return (
        <Form.Group
          controlId="voidAndRebill"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <Form.Label
            style={{
              marginTop: '15px',
            }}>
            Void and Rebill
          </Form.Label>

          <Checkbox
            disabled={!!form?.isVoided}
            style={{
              color: 'red',
              height: 0,
              width: 0,
              marginTop: '5px',
              pointerEvents: 'all',
            }}
            onChange={event => {
              setField('isVoidedRebilled', event.target.checked);
            }}
            checked={!!form?.isVoidedRebilled}
            defaultValue={false}
          />
        </Form.Group>
      );
    }

    if (form?.correctionExpenseId && !form?.rebilledExpenseId) {
      return (
        <Form.Group
          controlId="voidAndRebill"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <Form.Label
            style={{
              marginTop: '15px',
            }}>
            Rebill
          </Form.Label>

          <Checkbox
            style={{
              color: 'red',
              height: 0,
              width: 0,
              marginTop: '5px',
              pointerEvents: 'all',
            }}
            onChange={() => {
              rebilled.current = !rebilled.current;
            }}
          />
        </Form.Group>
      );
    }

    return undefined;
  };

  const setFormCityOptions = () => {
    if (form?.selectedCities?.length && Object.keys(receipts?.[0]).length) {
      const formCopy = { ...form };
      hasSetFormCityOptions.current = true;
      const {
        name,
        state: { id: stateId },
      } = form?.selectedCities?.find(aCity => aCity.id === formCopy.expenseHotelCityId) || {};
      formCopy.selectedCity = name;
      formCopy.selectedCityState = stateId;
      setField([
        {
          fieldName: 'selectedCity',
          fieldValue: name,
        },
        {
          fieldName: 'selectedCityState',
          fieldValue: stateId,
        },
      ]);
    }
  };

  const getSelectedState = () => {
    let selectedState = '';
    if (form?.expenseHotelStateId) {
      formData?.states?.forEach(state => {
        if (state.id === form?.expenseHotelStateId) {
          selectedState = state?.name;
        }
      });
    }
    return selectedState;
  };

  useEffect(() => {
    if (!form?.selectedCities && form?.expenseHotelStateId) {
      handleStateChange([
        {
          fieldName: 'expenseItemHotelStateId',
          fieldValue: form?.expenseHotelStateId,
        },

        {
          fieldName: 'selectedState',
          fieldValue: getSelectedState(),
        },
      ]);
    }
  }, [form?.expenseHotelStateId, form?.selectedCities]);

  useEffect(() => {
    if (hasSetFormCityOptions.current === false) {
      if (form.expenseHotelCityId !== undefined) setFormCityOptions();
    }
  }, [form?.selectedCities, form.expenseHotelCityId]);

  const startEdit = useMemo(() => form?.driverId, [form?.driverId]);

  return (
    <Form>
      <Row
        style={{
          margin: '16px 0 0 0',
        }}
        className="top-row">
        <div
          style={{
            width: '40%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <div className="title centered-text">Hotel Expense</div>
          <div
            className="centered-text"
            style={{
              marginLeft: '20px',
            }}>
            <div className="label centered-text">ID</div> <div className="top-value centered-text">{id}</div>
          </div>
        </div>
        <div className="top-col">
          <div className="label centered-text">Code</div> <div className="top-value centered-text">{form?.code}</div>
        </div>
        <div className="top-col">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '225px',
            }}>
            <div className="label centered-text">Date</div>
            <div className="top-value centered-text">{expenseDate && formatIsoToDate(expenseDate)}</div>
          </div>
          {(form?.status === 'Unbilled' ||
            form.status === 'Non-billable' ||
            form.status === 'Ready to Bill' ||
            form.status === 'Flat Fee Billing') &&
            !id?.includes('CR') && (
              <Form.Group
                controlId="flatFeeBilling"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginTop: '40px',
                }}>
                <Form.Label>Flat Fee Billing</Form.Label>
                <Checkbox
                  style={{
                    color: 'red',
                    height: 0,
                    width: 0,
                    marginTop: '5px',
                  }}
                  onChange={event => {
                    setField('flatFeeBilling', event.target.checked);
                  }}
                  checked={!!(form?.flatFeeBilling)}
                  defaultValue={false}
                />
              </Form.Group>
            )}
        </div>
      </Row>
      <Row
        className="top-row"
        style={{
          justifyContent: 'space-between',
        }}>
        <div
          className="top-col"
          style={{
            width: '40%',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <h1
            className="label"
            style={{
              marginLeft: '15px',
              marginTop: '10px',
            }}>
            Single Driver or Shared Invoice?
          </h1>
          <div className="radio-container">
            <div className="radio-group">
              <Radio
                style={{
                  color: isPartitionExpense ? '#d7d7d7' : '#da0f2e',
                  transform: 'scale(1)',
                }}
                labe="Single Drive"
                value="single"
                onChange={handleRadioButtonChange}
                checked={singleOrShared === 'single'}
                disabled={true}
              />
              <span
                className="label"
                style={{
                  marginLeft: '5px',
                }}>
                Single Driver
              </span>
            </div>
            <div className="radio-group">
              <Radio
                style={{
                  color: isPartitionExpense ? '#d7d7d7' : '#da0f2e',
                  transform: 'scale(1)',
                }}
                title="Shared Invoice"
                value="shared"
                onChange={handleRadioButtonChange}
                checked={singleOrShared === 'shared'}
                disabled={true}
              />
              <span
                className="label"
                style={{
                  marginLeft: '5px',
                }}>
                Shared Invoice
              </span>
            </div>
          </div>
        </div>
        <div
          className="top-col"
          style={{
            width: '31%',
          }}
        />
        {singleOrShared === 'shared' && (
          <div
            className="top-col"
            style={{
              width: '29%',
            }}>
            <Form.Group controlId="status">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Status
              </Form.Label>
              <Form.Control
                style={{
                  width: '225px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="status"
                readOnly
                value={form?.status}
                disabled
              />
            </Form.Group>
            {getTopComponent()}
          </div>
        )}
      </Row>
      {singleOrShared === 'single' &&
        (startEdit ? (
          <ExpenseForm
            customStyles={customStyles}
            setField={setField}
            errors={errors}
            form={form}
            rebilled={rebilled}
            formHasChanged={formHasChanged}
            setForm={setForm}
          />
        ) : (
          <div className="edit-form-spinner">
            <Spinner animation="border" size="sm" />
          </div>
        ))}
      {singleOrShared === 'shared' && (
        <Row
          className="top-row"
          style={{
            margin: '5px 0 0 0',
          }}>
          <div
            className="top-col"
            style={{
              width: '40%',
            }}>
            <Form.Group controlId="customer">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                <div className="required">Customer Name & Customer Number</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                {isPartitionExpense ? (
                  <div
                    className="disabledText"
                    style={{
                      width: '385px',
                    }}>
                    {form?.selectedCustomer}
                  </div>
                ) : (
                  <>
                    <Select
                      styles={customStyles('385px')}
                      defaultInputValue={form?.selectedCustomer}
                      options={clientOptions}
                      onChange={event =>
                        setField([
                          {
                            fieldName: 'customerId',
                            fieldValue: event.value,
                          },
                          {
                            fieldName: 'customerName',
                            fieldValue: event.label.split(' -').shift(),
                          },
                          {
                            fieldName: 'selectedCustomer',
                            fieldValue: event.label,
                          },
                        ])
                      }
                    />
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      aria-hidden="true"
                      className="dropdown-spinner"
                      hidden={!isLoadingCustomers}
                    />
                  </>
                )}
              </div>

              {errors?.customerId && <div className="error">{errors?.customerId}</div>}
            </Form.Group>
          </div>
          <div className="top-col">
            <Form.Group controlId="branch">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                <div className="required">Client Branch</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                {isPartitionExpense ? (
                  <div
                    className="disabledText"
                    style={{
                      minWidth: '225px',
                    }}>
                    {form?.branchSelection}
                  </div>
                ) : (
                  <>
                    <Select
                      styles={customStyles('225px')}
                      defaultInputValue={form?.branchSelection}
                      options={branchOptions}
                      onChange={event =>
                        setField([
                          {
                            fieldName: 'branch',
                            fieldValue: event.value,
                          },
                          {
                            fieldName: 'branchName',
                            fieldValue: event.label.split(' -').shift(),
                          },
                          {
                            fieldName: 'branchSelection',
                            fieldValue: event.label,
                          },
                        ])
                      }
                    />
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      aria-hidden="true"
                      className="dropdown-spinner"
                      hidden={!isLoadingBranches}
                    />
                  </>
                )}
              </div>

              {errors?.branch && <div className="error">{errors?.branch}</div>}
            </Form.Group>
          </div>
          <div className="top-col">
            <Form.Group controlId="costCenter">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Cost Center
              </Form.Label>
              <Form.Control
                style={{
                  width: '225px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="costCenter"
                readOnly
                value={form?.costCenter}
                disabled
              />
            </Form.Group>
            {getBottomComponent()}
          </div>
        </Row>
      )}
      {singleOrShared === 'shared' && (
        <div>
          <Row className="top-row" style={{ margin: '5px 0 0 0', alignItems: 'start' }}>
            <div
              className="top-col"
              style={{
                width: '364px',
                justifyContent: 'flex-start',
              }}>
              <Form.Group controlId="hotel">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">Hotel</div>
                </Form.Label>
                <div className="dropdown-spinner-container">
                  {isPartitionExpense ? (
                    <div
                      className="disabledText"
                      style={{
                        minWidth: '300px',
                      }}>
                      {form?.selectedHotel}
                    </div>
                  ) : (
                    <Select
                      styles={customStyles('300px')}
                      options={formHotelOptions}
                      onChange={event => {
                        setField([
                          {
                            fieldName: 'expenseHotelId',
                            fieldValue: event.value,
                          },
                          {
                            fieldName: 'selectedHotel',
                            fieldValue: event.label,
                          },
                        ]);
                      }}
                      value={formHotelOptions?.filter(fho => fho.value === form?.expenseHotelId)}
                    />
                  )}
                </div>
                {errors?.expenseHotelId && <div className="error">{errors?.expenseHotelId}</div>}
              </Form.Group>
            </div>
            <div
              className="top-col"
              style={{
                width: '284px',
              }}>
              <Form.Group controlId="hotelConfirmationNumber">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">Hotel Confirmation Number</div>
                </Form.Label>
                {isPartitionExpense ? (
                  <div
                    className="disabledText"
                    style={{
                      minWidth: '225px',
                    }}>
                    {form?.hotelConfirmationNumber}
                  </div>
                ) : (
                  <Form.Control
                    key="hotelConfirmationNumber"
                    style={{
                      width: '225px',
                      height: '30px',
                      paddingLeft: 10,
                    }}
                    type="input"
                    name="hotelConfirmationNumber"
                    defaultValue={form?.hotelConfirmationNumber}
                    onBlur={event => {
                      setField('hotelConfirmationNumber', event.target.value);
                    }}
                    isInvalid={!!errors?.hotelConfirmationNumber}
                  />
                )}

                <Form.Control.Feedback type="invalid">{errors?.hotelConfirmationNumber}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div
              className="top-col"
              style={{
                width: '284px',
              }}>
              <Form.Group controlId="hotelPhoneNumber">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">Hotel Phone Number</div>
                </Form.Label>
                {isPartitionExpense ? (
                  <div
                    className="disabledText"
                    style={{
                      minWidth: '225px',
                    }}>
                    {form?.hotelPhoneNumber}
                  </div>
                ) : (
                  <Form.Control
                    key="hotelPhoneNumber"
                    style={{
                      width: '225px',
                      height: '30px',
                      paddingLeft: 10,
                    }}
                    type="input"
                    name="hotelPhoneNumber"
                    onBlur={event => {
                      const str = event.target.value?.replace(/\D/, '');
                      setField('hotelPhoneNumber', formatPhoneNumber(str));
                    }}
                    onChange={event => {
                      setFormPhoneNumber(formatPhoneNumber(event.target.value));
                    }}
                    value={formattedPhoneNumber || form?.hotelPhoneNumber}
                    isInvalid={!!errors?.hotelPhoneNumber}
                  />
                )}

                <Form.Control.Feedback type="invalid">{errors?.hotelPhoneNumber}</Form.Control.Feedback>
              </Form.Group>
            </div>
            {!form?.documentName && (
              <div
                className="top-col"
                style={{
                  width: '200px',
                }}>
                <Form.Group
                  controlId="receipt"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <Form.Label
                    style={{
                      marginTop: '15px',
                    }}>
                    <div className="required">Receipt</div>
                  </Form.Label>

                  <Form.Control
                    name="receipt"
                    as="span"
                    style={{
                      border: 'none',
                      outline: 'none',
                      paddingLeft: 0,
                      height: 'auto',
                    }}>
                    <ExpenseReceiptUploader
                      func={uploadExpenseFile}
                      setReceiptFile={setFormReceipt}
                      uploadStart={() => setUploading(true)}
                      uploadEnd={() => setUploading(false)}
                      uploadError={() => {
                        setUploading(false);
                        setUploadError('Upload failed. Please ensure you have a network connection and try again.');
                      }}
                    />
                  </Form.Control>
                </Form.Group>
              </div>
            )}
          </Row>
          {form?.documentName && (
            <DocumentRow
              deleteDocumentFromReceipt={deleteDocumentFromForm}
              receipt={form}
              disabled={
                isPartitionExpense
                  ? true
                  : false || isBilledOrTransmitted(form) || form.status === 'Voided' || id?.includes('CR')
              }
            />
          )}
          <Row
            className="top-row"
            style={{
              margin: '5px 0 0 0',
            }}>
            <div
              className="top-col"
              style={{
                width: '364px',
                justifyContent: 'flex-start',
              }}>
              <Form.Group controlId="hotelStreetAddress">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">Hotel Street Address</div>
                </Form.Label>
                {isPartitionExpense ? (
                  <div
                    className="disabledText"
                    style={{
                      width: '300px',
                    }}>
                    {form?.hotelStreetAddress}
                  </div>
                ) : (
                  <Form.Control
                    key="$hotelStreetAddress"
                    style={{
                      width: '300px',
                      height: '30px',
                      paddingLeft: 10,
                    }}
                    type="input"
                    name="hotelStreetAddress"
                    defaultValue={form?.hotelStreetAddress}
                    onBlur={event => {
                      setField('hotelStreetAddress', event.target.value);
                    }}
                    isInvalid={!!errors?.hotelStreetAddress}
                  />
                )}

                <Form.Control.Feedback type="invalid">{errors?.hotelStreetAddress}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div
              className="top-col"
              style={{
                width: '284px',
              }}>
              <Form.Group controlId="expenseHotelStateId">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">State</div>
                </Form.Label>
                <div className="dropdown-spinner-container">
                  {isPartitionExpense ? (
                    <div
                      className="disabledText"
                      style={{
                        width: '225px',
                      }}>
                      {form?.selectedState}
                    </div>
                  ) : (
                    <Select
                      styles={customStyles('225px')}
                      options={formStateOptions}
                      onChange={event => {
                        handleStateChange([
                          {
                            fieldName: 'expenseHotelStateId',
                            fieldValue: event.value,
                          },

                          {
                            fieldName: 'selectedState',
                            fieldValue: event.label,
                          },
                        ]);
                      }}
                      value={formStateOptions?.filter(state => state.value === form?.expenseHotelStateId)}
                    />
                  )}
                </div>
                {errors?.expenseHotelStateId && <div className="error">{errors?.expenseHotelStateId}</div>}
              </Form.Group>
            </div>
            <div
              className="top-col"
              style={{
                width: '284px',
              }}>
              <Form.Group controlId="expenseHotelCityId">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">City</div>
                </Form.Label>
                <div className="dropdown-spinner-container">
                  {isPartitionExpense ? (
                    <div
                      className="disabledText"
                      style={{
                        width: '225px',
                      }}>
                      {form?.selectedCity}
                    </div>
                  ) : (
                    <Select
                      isDisabled={!form?.selectedState || isLoadingCities}
                      onFocus={() => {
                        setSave('');
                      }}
                      styles={customStyles('225px')}
                      options={formCityOptions}
                      placeholder="Select a City"
                      value={formCityOptions.filter(city => city.value === form?.expenseHotelCityId)}
                      onChange={event => {
                        setField([
                          {
                            fieldName: 'expenseHotelCityId',
                            fieldValue: event.value,
                          },

                          {
                            fieldName: 'selectedCity',
                            fieldValue: event.label,
                          },
                        ]);
                        setSave(event.label);
                      }}
                    />
                  )}
                </div>
                {errors?.expenseHotelCityId && <div className="error">{errors?.expenseHotelCityId}</div>}
              </Form.Group>
            </div>
            <div
              className="top-col"
              style={{
                width: '284px',
              }}>
              <Form.Group controlId="totalReceiptAmount">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">Total Receipt Amount</div>
                </Form.Label>
                {isPartitionExpense ? (
                  <div
                    className="disabledText"
                    style={{
                      width: '150px',
                    }}>
                    {form?.totalReceiptAmount}
                  </div>
                ) : (
                  <InputGroup
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '150px',
                    }}>
                    <span
                      style={{
                        position: 'absolute',
                        left: '10px',
                        zIndex: 10000,
                      }}>
                      $
                    </span>
                    <Form.Control
                      key="$formtotalReceiptAmount"
                      style={{
                        width: '150px',
                        height: '30px',
                        padding: 0,
                        paddingLeft: '25px',
                        backgroundColor:
                          totalToBeBilled === form?.totalReceiptAmount?.toString() &&
                            form?.totalReceiptAmount?.toString() !== '0' &&
                            form?.totalReceiptAmount?.toString() !== '0.00'
                            ? '#caf982'
                            : '#eec6bd',
                      }}
                      type="input"
                      name="totalReceiptAmount"
                      defaultValue={form?.totalReceiptAmount}
                      onBlur={event => {
                        setField('totalReceiptAmount', event.target.value);
                      }}
                      isInvalid={!!errors?.totalReceiptAmount}
                    />
                  </InputGroup>
                )}

                <Form.Control.Feedback type="invalid">{errors?.totalReceiptAmount}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
        </div>
      )}
      <div className="gray-wrapper">
        {singleOrShared === 'shared'
          ? sharedReceipts?.map((receipt, index) => (
            <DisableClickOverlay
              key={receipt.id}
              isDisabled={isBilledOrTransmitted(form) || id?.includes('CR') || form?.status === 'Voided'}>
              <SharedReceipt
                receipt={receipt}
                id={index}
                sharedReceipts={sharedReceipts}
                getTotalProjectedAmount={getTotalProjectedAmount}
                setSharedReceipts={setSharedReceipts}
                sharedReceiptErrors={sharedReceiptErrors}
                setSharedReceiptsErrors={setSharedReceiptsErrors}
                receiptsErrors={receiptsErrors}
                handleDeleteRow={handleDeleteRow}
                form={form}
                expenseId={id}
                setForm={setForm}
              />
            </DisableClickOverlay>
          ))
          : receipts?.map((receipt, index) => (
            <Receipt
              receipt={receipt}
              id={index}
              key={receipt.id}
              isCR={id?.includes('CR')}
              setReceiptFields={setReceiptFields}
              formData={formData}
              setSave={setSave}
              isSettingState={isSettingState}
              isSettingCity={isSettingCity}
              receipts={receipts}
              handleDeleteRow={handleDeleteRow}
              customStyles={customStyles}
              receiptsErrors={receiptsErrors}
              setReceiptField={setReceiptField}
              debouncedSetReceiptField={debouncedSetReceiptField}
              inputSave={inputSave}
              deleteDocumentFromReceipt={deleteDocumentFromReceipt}
              setReceiptFile={setReceiptFile}
              setUploading={setUploading}
              setUploadError={setUploadError}
              form={form}
              hotelOptions={formHotelOptions}
              expenseId={id}
            />
          ))}
        <DisableClickOverlay
          isDisabled={isBilledOrTransmitted(form) || id?.includes('CR') || form?.status === 'Voided'}>
          <Row
            className="top-row"
            style={{
              alignItems: 'flex-start',
            }}>
            {/* remove button temporary  */}
            {/* <div onClick={handleAddReceipt} className="another-receipt">
              {!isPartitionExpense && <>Add Another {singleOrShared === 'shared' ? 'Driver' : 'Receipt'}</>}
            </div> */}
            <div className='another-receipt'></div>

            <div
              className="top-col"
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}>
              <Form.Group controlId="startDate">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  Start Date
                </Form.Label>
                <Form.Control
                  style={{
                    width: '150px',
                    height: '30px',
                    padding: 0,
                    border: '0px',
                    backgroundColor: 'white',
                    opacity: '0.7',
                  }}
                  as="input"
                  name="startDate"
                  readOnly
                  value={form?.startDate?.substring(0, 10)}
                  disabled
                />
              </Form.Group>
              <Form.Group controlId="endDate">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  End Date
                </Form.Label>
                <Form.Control
                  style={{
                    width: '150px',
                    height: '30px',
                    padding: 0,
                    border: '0px',
                    backgroundColor: 'white',
                    opacity: '0.7',
                  }}
                  as="input"
                  name="endDate"
                  readOnly
                  value={form?.endDate?.substring(0, 10)}
                  disabled
                />
              </Form.Group>
            </div>
            <Form.Group controlId="totalToBeBilled">
              <Form.Label
                style={{
                  marginTop: '15px',
                  width: '150px',
                  marginRight: '20px',
                }}>
                Total To Be Billed
              </Form.Label>
              <InputGroup
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '125px',
                  opacity: '0.7',
                  backgroundColor: getTotalToBeBilledColor(),
                }}>
                <span
                  style={{
                    position: 'absolute',
                    left: '10px',
                    zIndex: 10000,
                  }}>
                  $
                </span>
                <Form.Control
                  style={{
                    width: '150px',
                    height: '30px',
                    padding: 0,
                    border: '0px',
                    marginLeft: '20px',
                    backgroundColor: getTotalToBeBilledColor(),
                  }}
                  as="input"
                  name="totalToBeBilled"
                  readOnly
                  value={totalToBeBilled}
                  disabled
                />
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="totalProjectedAmount">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Total Projected Amount
              </Form.Label>
              <InputGroup
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '150px',
                  opacity: '0.7',
                }}>
                <span
                  style={{
                    position: 'absolute',
                    left: '10px',
                    zIndex: 10000,
                  }}>
                  $
                </span>
                <Form.Control
                  style={{
                    width: '225px',
                    height: '30px',
                    padding: 0,
                    border: '0px',
                    backgroundColor: 'white',
                    marginLeft: '20px',
                  }}
                  as="input"
                  name="totalProjectedAmount"
                  disabled
                  readOnly
                  value={
                    form?.totalProjectedAmount
                      ? parseFloat(form?.totalProjectedAmount).toFixed(2)
                      : parseFloat(0).toFixed(2)
                  }
                />
              </InputGroup>
            </Form.Group>
          </Row>
        </DisableClickOverlay>
        <DisableClickOverlay
          isDisabled={isBilledOrTransmitted(form) || id?.includes('CR') || form?.status === 'Voided'}>
          <Row
            className="top-row"
            style={{
              margin: '5px 0 0 0',
              alignItems: 'flex-start',
            }}>
            <div
              className="top-col"
              style={{
                width: '70%',
              }}>
              <Form.Group controlId="notes">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  Notes
                </Form.Label>
                <Form.Control
                  style={{
                    width: '533px',
                    height: '75px',
                  }}
                  as="textarea"
                  name="notes"
                  defaultValue={form?.notes}
                  onBlur={event => {
                    setField('notes', event.target.value);
                  }}
                />
              </Form.Group>
            </div>
            <div
              className="top-col"
              style={{
                alignItems: 'flex-start',
              }}>
              <Form.Group controlId="auditKey">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  Audit Key
                </Form.Label>
                <Form.Control
                  style={{
                    width: '200px',
                    height: '30px',
                    padding: 0,
                    border: '0px',
                    backgroundColor: 'white',
                    opacity: '0.7',
                  }}
                  as="input"
                  name="auditKey"
                  readOnly
                  value={auditKey}
                  disabled
                />
              </Form.Group>
            </div>
          </Row>
        </DisableClickOverlay>
      </div>
      {isLoading && (
        <div className="overlay-box">
          <Spinner className="spinner" animation="border" />
        </div>
      )}

      <InvoiceDataRow
        setField={setField}
        invoiceDate={form?.invoiceDate}
        invoiceNumber={form?.invoiceNumber}
        amountBilled={form?.amountBilled ? getCurrencyString(form?.amountBilled) : ''}
        variance={getCurrencyString(
          getCalculatedVariance({
            amountBilled: form?.amountBilled,
            totalToBeBilled: totalToBeBilled,
          }),
        )}
        statementDate={form?.statementDate}
        expenseReportAppropriationNbr={form?.expenseReportAppropriationNbr}
      />

      <Row
        className="top-row"
        style={{
          margin: singleOrShared === 'single' ? '20px 0 0 0' : '50px 0 0 0',
          justifyContent: 'center',
          pointerEvents: 'all',
        }}>
        {isReadyToBill && (
          <Button
            onClick={() => setShowConfirmRTB(true)}
            className="button-secondary"
            style={{
              width: '150px',
              marginRight: '20px',
            }}>
            <span>READY TO BILL</span>
          </Button>
        )}
        {!isBilledOrTransmitted(form) && !id?.includes('CR') && form.status !== 'Voided' && (
          <Button
            className="button-text"
            style={{
              width: '140px',
              marginRight: '20px',
            }}
            onClick={() => { setShowDeleteConfirmation(true) }}>
            <span>DELETE</span>
          </Button>
        )}
        <Button
          disabled={saveDisabled}
          className="button-text"
          style={{
            width: '140px',
            marginRight: '20px',
          }}
          onClick={handleEditExpense}>
          <span>SAVE</span>
        </Button>
        <Button
          onClick={handleCancel}
          className="button-secondary"
          style={{
            width: '140px',
          }}>
          <span>CANCEL</span>
        </Button>
      </Row>
    </Form>
  );
};

export default EditFormContent;
