/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import React, { useState } from 'react';

import { Auth } from 'aws-amplify';
import { Button, Container, Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import '../styles.scoped.css';
import { setSelectedRoleId } from '../../Dashboard/dashboardSlice';

const VerifyMFACode = ({ nextStep, userData }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [error, setErrorMessage] = useState('');
  const [countdown, setCountdown] = useState(0);

  const params = new URLSearchParams(location.search);
  const [dqf] = [params.get('dqf')];

  const timer = () => {
    setCountdown(10);
    setErrorMessage('');

    const countdownInterval = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(countdownInterval);

      setCountdown(0);
    }, 10000);

    nextStep({
      ...userData,
      verifyCode: '',
    });
  };

  const sendVerifyChallenge = async payload => {
    Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
    Auth.sendCustomChallengeAnswer(payload?.user, payload?.verifyCode)
      .then(user => {
        if (user?.signInUserSession?.accessToken?.jwtToken) {
          dispatch(setSelectedRoleId(2));
          if (dqf) {
            navigate(`/driver-qualification/view-dqf/${dqf}`, {
              mobileView: true,
            });
            window.location.reload();
          } else {
            window.location.reload();
          }
        }
        if (user.challengeName === 'CUSTOM_CHALLENGE') {
          if (user.challengeParam.customMfaChallenge === 'RETRY_MFA_CODE') {
            setErrorMessage('Invalid code, try again');
            nextStep({
              ...userData,
              verifyCode: '',
            });
          }
        }
      })
      .catch(e => {
        console.log('e', e);
        if (e.code === 'LimitExceededException') {
          setErrorMessage(e.code);
        }
        if (e.code === 'NotAuthorizedException') {
          setErrorMessage(e.code);
        }
        if (e.code === 'ExpiredCodeException') {
          setErrorMessage(e.code);
        }
        if (e.message === 'Challenge response cannot be empty') {
          setErrorMessage(e.message);
        }
      });
  };

  const resendVerifyChallenge = payload => {
    if (countdown === 0) {
      timer();

      Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
      Auth.signIn(payload.username, payload.password).then(user => {
        if (user.challengeName === 'CUSTOM_CHALLENGE') {
          if (user.challengeParam.customMfaChallenge === 'PICK_MFA_FACTOR') {
            Auth.sendCustomChallengeAnswer(user, payload?.code).catch(e => {
              console.log('e', e);
              if (e.code === 'LimitExceededException') {
                setErrorMessage(e.code);
              }
              if (e.code === 'NotAuthorizedException') {
                setErrorMessage(e.code);
              }
              if (e.code === 'ExpiredCodeException') {
                setErrorMessage(e.code);
              }
            });
          }
        }
      });
    }
  };

  return (
    <>
      {error === 'LimitExceededException' || error === 'NotAuthorizedException' || error === 'ExpiredCodeException' ? (
        <Container fluid>
          <Row>
            <Col xs={12} className="d-flex justify-content-center mt-5 w-25">
              {error === 'LimitExceededException' && (
                <p className="border border-danger m-2 p-1 text-center text-danger">
                  Attempt limit exceeded, please try after some time.
                </p>
              )}
              {error === 'NotAuthorizedException' && (
                <p className="border border-danger m-2 p-1 text-center text-danger">Invalid user, please try again.</p>
              )}
              {error === 'ExpiredCodeException' && (
                <p className="border border-danger m-2 p-1 text-center text-danger">
                  Attempt limit exceeded, please try after some time.
                </p>
              )}
            </Col>
            <Col xs={12} className="d-flex align-items-center justify-content-center mb-4">
              <Button
                onClick={() => {
                  window.location.reload();
                }}
                type="button"
                className="btn-md">
                Try Again
              </Button>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col xs={12} className="d-flex justify-content-center mt-5 text-center w-25">
              <p className="fs-1 font-weight-bold">Please enter the code below and click Verify</p>
            </Col>
            <Col xs={12} className="d-flex justify-content-center mt-2 text-center w-25">
              {error === 'Challenge response cannot be empty' && (
                <p className="border border-danger p-2 text-danger w-50">Challenge response cannot be empty</p>
              )}
              {error === 'Invalid code, try again' && (
                <p className="border border-danger m-2 p-1 text-center text-danger w-50">
                  Invalid verification code provided, please try again
                </p>
              )}
            </Col>
            <Col xs={12}>
              <Row className="d-flex flex-col justify-content-center mb-3">
                <div className="text-center">
                  <Form.Group>
                    <Form.Label>
                      Enter Code
                      <span className="required" />
                    </Form.Label>
                    <Form.Control
                      as="input"
                      className={error.includes('Invalid code') && 'border-danger'}
                      onChange={e => {
                        if (e.target.value.length > 0) {
                          setErrorMessage('');
                        }
                      }}
                      name="verifyCode"
                      onBlur={event => {
                        nextStep({
                          ...userData,
                          verifyCode: event.target.value,
                        });
                      }}
                      style={{ textAlign: 'center', width: '100px' }}
                    />
                  </Form.Group>
                </div>
              </Row>
              <Row className="d-flex align-items-center justify-content-center mb-4">
                <Button
                  onClick={() => {
                    sendVerifyChallenge(userData);
                  }}
                  type="button"
                  className="btn">
                  Verify
                </Button>
              </Row>
              <Row className="d-flex flex-col justify-content-center mb-2 text-center">
                <p className="fs-4 font-weight-bold">Didn’t get the code? Click Resend to try again</p>
              </Row>
              <Row className="d-flex flex-col justify-content-center mb-2">
                <p className="fs-1 font-weight-bold">You can Resend in {countdown} seconds</p>
              </Row>
              <Row className="d-flex align-items-center justify-content-center p-1">
                <Button
                  disabled={countdown > 0}
                  onClick={() => {
                    resendVerifyChallenge(userData);
                  }}
                  type="button"
                  className="btn">
                  Re-send Code
                </Button>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default VerifyMFACode;
