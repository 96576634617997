import React, { useMemo, useState, useEffect } from 'react';

import { Row, Form, InputGroup } from 'react-bootstrap';
import { MdWarning } from 'react-icons/md';

import { ExpenseReceiptUploader } from '../../../../components';
import { DisableClickOverlay, isBilledOrTransmitted } from '../../../../utils/utilities';
import DocumentRow from '../documentRow';
import { uploadExpenseFile } from '../../DriverSolutions/operations';

import './styles.scoped.css';

const Receipt = ({
  receipt,
  id,
  receipts,
  handleDeleteRow,
  debouncedSetReceiptField,
  receiptsErrors,
  setReceiptFile,
  setUploading,
  setUploadError,
  deleteDocumentFromReceipt,
  form,
  isCR,
  expenseId,
}) => {
  const [receiptAmountValue, setReceiptAmountValue] = useState(0);
  const [markupValue, setMarkupValue] = useState(0);

  const handleReceiptAmountBlur = () => {
    if (receiptAmountValue === '') {
      setReceiptAmountValue(0);
    }
  };

  const handleMarkupBlur = () => {
    if (markupValue === '') {
      setMarkupValue(0);
    }
  };

  useEffect(() => {
      if (receipt.receiptAmount !== undefined && receipt.receiptAmount !== null) {
        setReceiptAmountValue(receipt.receiptAmount);
      } else {
        setReceiptAmountValue(0);
      }
    }, [receipt.receiptAmount]);
  
  useEffect(() => {
    if (receipt.markup !== undefined && receipt.markup !== null) {
      setMarkupValue(receipt.markup);
    } else {
      setMarkupValue(0);
    }
  }, [receipt.markup]);

  return (
  <div className="receipt-wrapper">
    {!receipt.documentName && (
      <div className="warning-container">
        <MdWarning size={36} title="Receipt document was not attached" color="#da0f2e" />
      </div>
    )}
    {receipts.length > 1 && (
      <div onClick={() => handleDeleteRow(id)} className="delete-row">
        Delete Row
      </div>
    )}
    <DisableClickOverlay isDisabled={isBilledOrTransmitted(form) || isCR || form?.status === 'Voided'}>
      <Row className="top-row" style={{ margin: '5px 0 0 0' }}>
        <div className="top-col" style={{ width: '40%', justifyContent: 'flex-start' }}>
          <div className="below-container">
            <Form.Group controlId="otherExpenseName">
              <Form.Label style={{ marginTop: '15px' }}>
                <div className="required"> Expense Name</div>
              </Form.Label>
              <Form.Control
                key={`${id}otherExpenseName`}
                style={{
                  width: '300px',
                  height: '30px',
                  paddingLeft: 10,
                }}
                type="input"
                name="otherExpenseName"
                defaultValue={receipt?.otherExpenseName}
                onChange={event => {
                  debouncedSetReceiptField({
                    id: id,
                    field: 'otherExpenseName',
                    value: event.target.value,
                    receipts: receipts,
                  });
                }}
                isInvalid={!!receiptsErrors[id]?.otherExpenseName}
              />
              <Form.Control.Feedback type="invalid">{receiptsErrors[id]?.otherExpenseName}</Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="top-col">
          <Form.Group controlId="otherExpenseDescription">
            <Form.Label style={{ marginTop: '15px' }}>
              <div className="required">Expense Description</div>
            </Form.Label>
            <Form.Control
              key={`${id}otherExpenseDescription`}
              style={{
                width: '300px',
                height: '30px',
                paddingLeft: 10,
              }}
              type="input"
              name="otherExpenseDescription"
              defaultValue={receipt?.otherExpenseDescription}
              onChange={event => {
                debouncedSetReceiptField({
                  id: id,
                  field: 'otherExpenseDescription',
                  value: event.target.value,
                  receipts: receipts,
                });
              }}
              isInvalid={!!receiptsErrors[id]?.otherExpenseDescription}
            />
            <Form.Control.Feedback type="invalid">{receiptsErrors[id]?.otherExpenseDescription}</Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="top-col">
          <Form.Group controlId="otherExpenseDate">
            <Form.Label style={{ marginTop: '15px' }}>
              <div className="required">Expense Date</div>
            </Form.Label>
            <Form.Control
              style={{ width: '150px', height: '30px' }}
              name="otherExpenseDate"
              type="date"
              as="input"
              defaultValue={receipt?.otherExpenseDate}
              onChange={event => {
                debouncedSetReceiptField({
                  id: id,
                  field: 'otherExpenseDate',
                  value: event.target.value,
                  receipts: receipts,
                });
              }}
              isInvalid={!!receiptsErrors[id]?.otherExpenseDate}
            />
            <Form.Control.Feedback type="invalid">{receiptsErrors[id]?.otherExpenseDate}</Form.Control.Feedback>
          </Form.Group>
        </div>
      </Row>
    </DisableClickOverlay>
    <DisableClickOverlay isDisabled={isBilledOrTransmitted(form) || isCR || form?.status === 'Voided'}>
      <Row className="top-row" style={{ margin: '5px 0 0 0', alignItems: 'start' }}>
        <div className="top-col" style={{ width: '40%', justifyContent: 'flex-start' }}>
          <div className="below-container" style={{ justifyContent: 'flex-end' }}>
            <div
              className="below-container"
              style={{
                justifyContent: 'space-between',
                width: '300px',
              }}>
              <Form.Group controlId="receiptAmount">
                <Form.Label style={{ marginTop: '15px' }}>
                  <div>Receipt Amount</div>
                </Form.Label>
                <InputGroup style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      position: 'absolute',
                      left: '10px',
                      zIndex: 10000,
                    }}>
                    $
                  </span>
                  <Form.Control
                    key={`${id}receiptAmount`}
                    style={{
                      width: '150px',
                      height: '30px',
                      padding: 0,
                      paddingLeft: '25px',
                    }}
                    type="number"
                    step="0.01"
                    min="0"
                    name="receiptAmount"
                    value={receiptAmountValue}
                    onChange={event => {
                      setReceiptAmountValue(event.target.value)
                      debouncedSetReceiptField({
                        id: id,
                        field: 'receiptAmount',
                        value: Number(event.target.value),
                        receipts: receipts,
                      });
                    }}
                    onBlur={handleReceiptAmountBlur}
                    isInvalid={!!receiptsErrors[id]?.receiptAmount}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="markup">
                <Form.Label style={{ marginTop: '15px' }}>
                  <div className="required">Markup</div>
                </Form.Label>
                <InputGroup style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      position: 'absolute',
                      right: '10px',
                      zIndex: 10000,
                    }}>
                    %
                  </span>
                  <Form.Control
                    style={{
                      width: '75px',
                      height: '30px',
                      padding: 0,
                      paddingLeft: '10px',
                    }}
                    type="number"
                    step="1"
                    min="0"
                    name="markup"
                    value={markupValue}
                    onChange={event => {
                      setMarkupValue(event.target.value)
                      debouncedSetReceiptField({
                        id: id,
                        field: 'markup',
                        value: event.target.value,
                        receipts: receipts,
                      });
                    }}
                    onBlur={handleMarkupBlur}
                    isInvalid={!!receiptsErrors[id]?.markup}
                  />
                </InputGroup>

                <Form.Control.Feedback type="invalid">{receiptsErrors[id]?.markup}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="top-col">
          <Form.Group controlId="expenseTotal">
            <Form.Label style={{ marginTop: '15px' }}>Expense Total</Form.Label>
            <InputGroup
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '150px',
                opacity: '0.7',
              }}>
              <span
                style={{
                  position: 'absolute',
                  left: '10px',
                  zIndex: 10000,
                }}>
                $
              </span>
              <Form.Control
                style={{
                  width: '225px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  marginLeft: '20px',
                }}
                as="input"
                name="location"
                readOnly
                disabled
                value={receipt?.expenseTotal ? parseFloat(receipt?.expenseTotal).toFixed(2) : parseFloat(0).toFixed(2)}
              />
            </InputGroup>
          </Form.Group>
        </div>
        {!receipt?.documentName && expenseId ? (
          <div className="top-col">
            <Form.Group controlId="receipt" style={{ display: 'flex', flexDirection: 'column' }}>
              <Form.Label style={{ marginTop: '15px' }}>
                <div>Receipt</div>
              </Form.Label>

              <Form.Control
                name="receipt"
                as="span"
                style={{
                  border: 'none',
                  outline: 'none',
                  paddingLeft: 0,
                  height: 'auto',
                }}>
                <ExpenseReceiptUploader
                  func={uploadExpenseFile}
                  setReceiptFile={setReceiptFile}
                  receiptId={id}
                  uploadStart={() => setUploading(true)}
                  uploadEnd={() => setUploading(false)}
                  uploadError={() => {
                    setUploading(false);
                    setUploadError('Upload failed. Please ensure you have a network connection and try again.');
                  }}
                  expenseId={expenseId}
                />
              </Form.Control>
            </Form.Group>
          </div>
        ) : null
        }
      </Row>
    </DisableClickOverlay>
    {receipt?.documentName && (
      <DocumentRow
        id={id}
        deleteDocumentFromReceipt={deleteDocumentFromReceipt}
        receipt={receipt}
        disabled={isBilledOrTransmitted(form) || form.status === 'Voided' || isCR}
      />
    )}
    <div className="gray-divider-light" />
  </div>
);
}

export default Receipt;
