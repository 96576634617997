import React, { useMemo, useState, useEffect } from 'react';

import { Row, Form, InputGroup } from 'react-bootstrap';
import { MdWarning } from 'react-icons/md';
import Select from 'react-select';

import { ExpenseReceiptUploader } from '../../../../components';
import { DisableClickOverlay, isBilledOrTransmitted } from '../../../../utils/utilities';
import DocumentRow from '../documentRow';
import { uploadExpenseFile } from '../operations';

import './styles.scoped.css';

const Receipt = ({
  receipt,
  id,
  receipts,
  handleDeleteRow,
  customStyles,
  airlineOptions,
  setReceiptFields,
  receiptsErrors,
  airportOptions,
  debouncedSetReceiptField,
  setReceiptField,
  paymentOptions,
  setReceiptFile,
  setUploading,
  setUploadError,
  deleteDocumentFromReceipt,
  form,
  isCR,
  setReceiptModified,
  expenseId,
}) => {

  const [receiptAmountValue, setReceiptAmountValue] = useState(0);
  const [markupValue, setMarkupValue] = useState(0);

  const handleReceiptAmountBlur = () => {
    if (receiptAmountValue === '') {
      setReceiptAmountValue(0);
    }
  };

  const handleMarkupBlur = () => {
    if (markupValue === '') {
      setMarkupValue(0);
    }
  };

  useEffect(() => {
    if (receipt.receiptAmount !== undefined && receipt.receiptAmount !== null) {
      setReceiptAmountValue(receipt.receiptAmount);
    } else {
      setReceiptAmountValue(0);
    }
  }, [receipt.receiptAmount]);

  useEffect(() => {
    if (receipt.markup !== undefined && receipt.markup !== null) {
      setMarkupValue(receipt.markup);
    } else {
      setMarkupValue(0);
    }
  }, [receipt.markup]);

  const customAirlineOptions = useMemo(() => {
    const result = airlineOptions || [];
    if (
      receipt.expenseItemAirLineId &&
      receipt.selectedAirline &&
      !result.find(ao => ao.value === receipt.expenseItemAirLineId)
    ) {
      result.push({
        label: receipt.selectedAirline,
        value: receipt.expenseItemAirLineId,
      });
      result?.sort((a, b) => (a.label > b.label ? 1 : -1));
    }

    return result;
  }, [airlineOptions, receipt.expenseItemAirLineId, receipt.selectedAirline]);

  const customAirportOptions = useMemo(() => {
    const result = airportOptions || [];
    if (
      receipt.expenseItemArrivalAirPortId &&
      receipt.selectedArrivalAirport &&
      !result.find(ao => ao.value === receipt.expenseItemArrivalAirPortId)
    ) {
      result.push({
        label: receipt.selectedArrivalAirport,
        value: receipt.expenseItemArrivalAirPortId,
      });
    }
    if (
      receipt.expenseItemDepartureAirPortId &&
      receipt.selectedDepartureAirport &&
      !result.find(ao => ao.value === receipt.expenseItemDepartureAirPortId)
    ) {
      result.push({
        label: receipt.selectedDepartureAirport,
        value: receipt.expenseItemDepartureAirPortId,
      });
    }

    result?.sort((a, b) => (a.label > b.label ? 1 : -1));
    return result;
  }, [
    airportOptions,
    receipt.expenseItemArrivalAirPortId,
    receipt.expenseItemDepartureAirPortId,
    receipt.selectedDepartureAirport,
    receipt.selectedArrivalAirport,
  ]);

  return (
    <div className="receipt-wrapper">
      <DisableClickOverlay isDisabled={isBilledOrTransmitted(form) || isCR || form?.status === 'Voided'}>
        {!receipt.documentName && (
          <div className="warning-container">
            <MdWarning size={36} title="Receipt document was not attached" color="#da0f2e" />
          </div>
        )}
        {receipts.length > 1 && (
          <div onClick={() => handleDeleteRow(id)} className="delete-row">
            Delete Row
          </div>
        )}
        <Row className="top-row" style={{ margin: '5px 0 0 0' }}>
          <div className="top-col" style={{ width: '40%', justifyContent: 'flex-start' }}>
            <div className="below-container">
              <Form.Group controlId="airline">
                <Form.Label style={{ marginTop: '15px' }}>
                  <div className="required">Airline</div>
                </Form.Label>
                <div className="dropdown-spinner-container">
                  <Select
                    styles={customStyles('300px')}
                    options={customAirlineOptions}
                    onChange={event => {
                      setReceiptFields(id, [
                        {
                          fieldName: 'expenseItemAirLineId',
                          fieldValue: event.value,
                        },
                        {
                          fieldName: 'selectedAirline',
                          fieldValue: event.label,
                        },
                      ]);
                    }}
                    value={customAirlineOptions?.find(airline => airline.value === receipt.expenseItemAirLineId)}
                  />
                </div>
                {receiptsErrors[id]?.expenseItemAirLineId && (
                  <div className="error">{receiptsErrors[id]?.expenseItemAirLineId}</div>
                )}
              </Form.Group>
            </div>
          </div>
          <div className="top-col">
            <Form.Group controlId="departureAirport">
              <Form.Label style={{ marginTop: '15px' }}>
                <div className="required">Departure Airport</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  styles={customStyles('225px')}
                  options={customAirportOptions}
                  onChange={event => {
                    setReceiptFields(id, [
                      {
                        fieldName: 'expenseItemDepartureAirPortId',
                        fieldValue: event.value,
                      },
                      {
                        fieldName: 'selectedDepartureAirport',
                        fieldValue: event.label,
                      },
                    ]);
                  }}
                  value={customAirportOptions?.find(airport => airport.value === receipt.expenseItemDepartureAirPortId)}
                />
              </div>
              {receiptsErrors[id]?.expenseItemDepartureAirPortId && (
                <div className="error">{receiptsErrors[id]?.expenseItemDepartureAirPortId}</div>
              )}
            </Form.Group>
          </div>
          <div className="top-col">
            <Form.Group controlId="arrivalAirport">
              <Form.Label style={{ marginTop: '15px' }}>
                <div className="required">Arrival Airport</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  styles={customStyles('225px')}
                  options={airportOptions}
                  onChange={event => {
                    setReceiptFields(id, [
                      {
                        fieldName: 'expenseItemArrivalAirPortId',
                        fieldValue: event.value,
                      },
                      {
                        fieldName: 'selectedArrivalAirport',
                        fieldValue: event.label,
                      },
                    ]);
                  }}
                  value={airportOptions?.find(airport => airport.value === receipt.expenseItemArrivalAirPortId)}
                />
              </div>
              {receiptsErrors[id]?.expenseItemArrivalAirPortId && (
                <div className="error">{receiptsErrors[id]?.expenseItemArrivalAirPortId}</div>
              )}
            </Form.Group>
          </div>
        </Row>
        <Row className="top-row" style={{ margin: '0' }}>
          <div className="top-col" style={{ width: '40%', justifyContent: 'flex-start' }}>
            <div className="below-container">
              <div className="below-container" style={{ justifyContent: 'flex-start', width: '300px' }}>
                <Form.Group controlId="formFlightDate">
                  <Form.Label style={{ marginTop: '15px' }}>
                    <div className="required">Flight Date</div>
                  </Form.Label>
                  <Form.Control
                    style={{ width: '150px', height: '30px' }}
                    name="flightDate"
                    type="date"
                    as="input"
                    defaultValue={receipt.flightDate}
                    onChange={event => {
                      debouncedSetReceiptField({
                        id: id,
                        field: 'flightDate',
                        value: event.target.value,
                        receipts: receipts,
                      });
                    }}
                    isInvalid={!!receiptsErrors[id]?.flightDate}
                  />
                  <Form.Control.Feedback type="invalid">{receiptsErrors[id]?.flightDate}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="top-col">
            <Form.Group controlId="payment">
              <Form.Label style={{ marginTop: '15px' }}>
                <div className="required">Paid Through</div>
              </Form.Label>
              <Form.Control
                style={{ width: '225px', height: '30px', padding: 0 }}
                as="select"
                name="payment"
                value={receipt.paidThrough}
                onChange={event => {
                  setReceiptField(id, 'paidThrough', event.target.value, receipts);
                }}
                onBlur={null}
                isInvalid={!!receiptsErrors[id]?.paidThrough}
                defaultValue="">
                <option value="" disabled>
                  Select
                </option>

                {paymentOptions}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{receiptsErrors[id]?.paidThrough}</Form.Control.Feedback>
            </Form.Group>
          </div>
        </Row>
        <Row className="top-row" style={{ margin: '5px 0 0 0', alignItems: 'start' }}>
          <div className="top-col" style={{ width: '40%', justifyContent: 'flex-start' }}>
            <div className="below-container" style={{ justifyContent: 'flex-end' }}>
              <div
                className="below-container"
                style={{
                  justifyContent: 'space-between',
                  width: '300px',
                }}>
                <Form.Group controlId="receiptAmount">
                  <Form.Label style={{ marginTop: '15px' }}>
                    <div>Receipt Amount</div>
                  </Form.Label>
                  <InputGroup style={{ display: 'flex', alignItems: 'center' }}>
                    <span
                      style={{
                        position: 'absolute',
                        left: '10px',
                        zIndex: 10000,
                      }}>
                      $
                    </span>
                    <Form.Control
                      key={`${id}receiptAmount`}
                      style={{
                        width: '150px',
                        height: '30px',
                        padding: 0,
                        paddingLeft: '25px',
                      }}
                      type="number"
                      step="0.01"
                      min="0"
                      name="receiptAmount"
                      value={receiptAmountValue}
                      onChange={event => {
                        setReceiptAmountValue(event.target.value)
                        debouncedSetReceiptField({
                          id: id,
                          field: 'receiptAmount',
                          value: Number(event.target.value),
                          receipts: receipts,
                        });
                      }}
                      onBlur={handleReceiptAmountBlur}
                      isInvalid={!!receiptsErrors[id]?.receiptAmount}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="markup">
                  <Form.Label style={{ marginTop: '15px' }}>
                    <div className="required">Markup</div>
                  </Form.Label>
                  <InputGroup style={{ display: 'flex', alignItems: 'center' }}>
                    <span
                      style={{
                        position: 'absolute',
                        right: '10px',
                        zIndex: 10000,
                      }}>
                      %
                    </span>
                    <Form.Control
                      style={{
                        width: '75px',
                        height: '30px',
                        padding: 0,
                        paddingLeft: '10px',
                      }}
                      type="number"
                      step="1"
                      min="0"
                      name="markup"
                      value={markupValue}
                      onChange={event => {
                        setMarkupValue(event.target.value)
                        debouncedSetReceiptField({
                          id: id,
                          field: 'markup',
                          value: event.target.value,
                          receipts: receipts,
                        });
                      }}
                      onBlur={handleMarkupBlur}
                      isInvalid={!!receiptsErrors[id]?.markup}
                    />
                  </InputGroup>

                  <Form.Control.Feedback type="invalid">{receiptsErrors[id]?.markup}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="top-col">
            <Form.Group controlId="expenseTotal">
              <Form.Label style={{ marginTop: '15px' }}>Expense Total</Form.Label>
              <InputGroup
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '150px',
                  opacity: '0.7',
                }}>
                <span
                  style={{
                    position: 'absolute',
                    left: '10px',
                    zIndex: 10000,
                  }}>
                  $
                </span>
                <Form.Control
                  style={{
                    width: '225px',
                    height: '30px',
                    padding: 0,
                    border: '0px',
                    backgroundColor: 'white',
                    marginLeft: '20px',
                  }}
                  as="input"
                  name="location"
                  readOnly
                  disabled
                  value={receipt.expenseTotal ? parseFloat(receipt.expenseTotal).toFixed(2) : parseFloat(0).toFixed(2)}

                //   isInvalid={!!errors?.location}
                />
              </InputGroup>

              <Form.Control.Feedback type="invalid">{/* {errors?.location} */}</Form.Control.Feedback>
            </Form.Group>
          </div>
          {!receipt.documentName && expenseId ? (
            <div className="top-col">
              <Form.Group controlId="receipt" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label style={{ marginTop: '15px' }}>
                  <div>Receipt</div>
                </Form.Label>
                <Form.Control
                  name="receipt"
                  as="span"
                  style={{
                    border: 'none',
                    outline: 'none',
                    paddingLeft: 0,
                    height: 'auto',
                  }}>
                  <ExpenseReceiptUploader
                    func={uploadExpenseFile}
                    setReceiptFile={setReceiptFile}
                    receiptId={id}
                    uploadStart={() => setUploading(true)}
                    uploadEnd={() => setUploading(false)}
                    uploadError={() => {
                      setUploading(false);
                      setUploadError('Upload failed. Please ensure you have a network connection and try again.');
                    }}
                    expenseId={expenseId}
                  />
                </Form.Control>
              </Form.Group>
            </div>
          ) : null
          }
        </Row>
      </DisableClickOverlay>
      {receipt.documentName && (
        <DocumentRow
          id={id}
          deleteDocumentFromReceipt={deleteDocumentFromReceipt}
          receipt={receipt}
          setReceiptModified={setReceiptModified}
          disabled={isBilledOrTransmitted(form) || form.status === 'Voided' || isCR}
        />
      )}
      <div className="gray-divider-light" />
    </div>
  );
};

export default Receipt;
