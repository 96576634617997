import React, { useEffect, useState } from 'react';

import { API } from 'aws-amplify';
import { Container, Row } from 'react-bootstrap';
import { MdWeb } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setRoleId } from './driverQualificationSlice';
import { DriverQualificationMenuItem, SubHeader, Footer } from '../../components';
import { getDriverQualificationRoleId } from '../../graphql/customQueries';

import './styles.scoped.css';

const DriverQualification = () => {
  const header = useSelector(state => state.driverQualification.header);
  const clientMenu = useSelector(state => state.driverQualification.clientMenu);
  const corporateMenu = useSelector(state => state.driverQualification.corporateMenu);
  const managementMenu = useSelector(state => state.driverQualification.managementMenu);
  const id = useSelector(state => state.admin.user.id);
  const roleId = useSelector(state => state.driverQualification.roleId);
  const [usedBy, setUsedBy] = useState('');
  const [appId, setAppId] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onPress = item => {
    navigate(item.to);
  };

  const getDQRole = async () => {
    try {
      const response = await API.graphql({
        query: getDriverQualificationRoleId,
        variables: {
          id: id,
        },
      });
      response?.data?.getUser?.appAccess?.items?.map(item => {
        dispatch(setRoleId(item.roleId.id));
      });
    } catch (e) {
      console.log('error', e);
    }

  };

  const getUsedBy = async () => {
    try {
      const response = await API.graphql({
        query: getDriverQualificationRoleId,
        variables: {
          id: id,
        },
      });
      setUsedBy();
      response?.data?.getUser?.appAccess?.items?.map(item => {
        setUsedBy(item?.roleId?.usedBy);
      });
    } catch (e) {
      console.log('error', e);
    }

  };

  const getAppId = async () => {
    try {
      const response = await API.graphql({
        query: getDriverQualificationRoleId,
        variables: {
          id: id,
        },
      });
      response?.data?.getUser?.appAccess?.items?.map(item => {
        setAppId(item?.appId.id);
      });
    } catch (e) {
      console.log('error', e)
    }

  };

  useEffect(() => {
    if (id) {
      getDQRole();
      getAppId();
      getUsedBy();
    }
  }, [id, roleId]);

  return (
    <>
      <SubHeader
        text="Centerline Driver Qualification"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        emailAddress={process.env.REACT_APP_DQF_CONTACT_EMAIL}
        btnInfo
      />
      <Container className="wrap">
        <h1 className="header">{header}</h1>

        {appId === `${2}` && usedBy === 'Client'
          ? clientMenu.map(item => (
            <div key={item.header}>
              <Row className="row" key={`${item.header}-row`}>
                {item.items.map(i => (
                  <DriverQualificationMenuItem key={i.icon} text={i.text} icon={i.icon} onPress={() => onPress(i)} />
                ))}
              </Row>
            </div>
          ))
          : null}

        {(appId === `${2}` && roleId === `${10}`) || (appId === `${2}` && roleId === `${3}`)
          ? corporateMenu.map(item => (
            <div key={item.header}>
              <Row className="row" key={`${item.header}-row`}>
                {item.items.map(i => (
                  <DriverQualificationMenuItem key={i.icon} text={i.text} icon={i.icon} onPress={() => onPress(i)} />
                ))}
              </Row>
            </div>
          ))
          : null}
        {/* TODO: remove trailing or */}
        {appId === `${2}` && roleId === `${10}` && usedBy === 'Corporate'
          ? managementMenu.map(item => (
            <div key={item.header}>
              <h1 key={`${item.header}-h1`} className="header">
                {item.header}
              </h1>
              <Row className="row" key={`${item.header}-row`}>
                {item.items.map(i => (
                  <DriverQualificationMenuItem key={i.icon} text={i.text} icon={i.icon} onPress={() => onPress(i)} />
                ))}
              </Row>
            </div>
          ))
          : null}
      </Container>
      <Footer />
    </>
  );
};

export default DriverQualification;
