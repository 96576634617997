import React, { useRef, useCallback, useMemo } from 'react';

import { Checkbox } from '@mui/material';
import { debounce } from 'lodash';
import { Row, Form, InputGroup, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Receipt from './Receipt';
import ExpenseForm from '../../../../components/ExpenseForm';
import InvoiceDataRow from '../../../../components/InvoiceDataRow';
import { formatIsoToDate } from '../../../../utils';
import { DisableClickOverlay, getCurrencyString, isBilledOrTransmitted } from '../../../../utils/utilities';
import {
  getCalculatedVariance,
  handleCreateVoidExpense,
  handleRebillExpense,
  handleVoidAndRebillExpense,
} from '../expensesUtils';
import { deleteExpense, checkClientFlatFee } from '../operations';
import { preventMultipleSave } from '../TransferToUltraStaff/utils';

import './styles.scoped.css';

const EditFormContent = ({
  user,
  hasChanges,
  setShowCancelDialog,
  navigateCancelAction,
  setHasChanges,
  errors,
  setForm,
  form,
  setErrors,
  setReceipts,
  setIsLoading,
  id,
  bulkDeleteExpenseItems,
  receipts,
  bulkDeleteReceipts,
  setSaveDisabled,
  rebilled,
  auditKey,
  totalToBeBilled,
  fieldsAreValid,
  confirmEditExpense,
  receiptsHaveFiles,
  setShowReceiptFilesDialog,
  deletedReceipts,
  setDeletedReceipts,
  deletedReceiptDocs,
  setDeletedReceiptDocs,
  currentUser,
  receiptsErrors,
  setReceiptsErrors,
  setShowFFDialog,
  setShowNonFFDialog,
  expenseDate,
  setBranches,
  setUploading,
  setUploadError,
  isLoading,
  saveDisabled,
  setReceiptModified,
  setShowDeleteConfirmation,
  assignmentLineOfBusinessItems,
}) => {
  const navigate = useNavigate();

  const formHasChanged = useRef({
    drivers: false,
    assignments: false,
    clients: false,
    branches: false,
  });

  const setDebouncedValue = debounce(r => {
    setReceiptField(r.id, r.field, r.value, r.receipts);
  }, 2000);

  const debouncedSetReceiptField = useCallback(value => setDebouncedValue(value), []);

  const handleCancel = async () => (hasChanges ? setShowCancelDialog(true) : navigateCancelAction());

  const setField = (fields, value) => {
    if (!hasChanges) {
      setHasChanges(true);
    }
    if (Array.isArray(fields)) {
      const itemsToAdd = {};
      const newErrors = { ...errors };
      fields.forEach(field => {
        itemsToAdd[(field?.fieldName)] = field.fieldValue;

        if (errors && !!errors[field.fieldName]) delete newErrors[(field?.fieldName)];
      });
      setForm({ ...form, ...itemsToAdd });
      setErrors({ ...newErrors });
    } else {
      if (fields === 'nonBillable' && value === true) {
        setForm({
          ...form,
          [fields]: value,
          status: 'Non-billable',
        });
      } else if (fields === 'nonBillable' && value === false) {
        setForm({
          ...form,
          [fields]: value,
          status: 'Unbilled',
        });
      } else if (fields === 'flatFeeBilling' && value === false) {
        setForm({
          ...form,
          [fields]: value,
          nonBillable: false,
          status: 'Unbilled',
          code: 'XX',
        });
      } else if (fields === 'flatFeeBilling' && value === true) {
        setForm({
          ...form,
          [fields]: value,
          nonBillable: true,
          status: 'Flat Fee Billing',
          code: 'XFFBX',
        });
      } else {
        setForm(prevState => ({
          ...prevState,
          [fields]: value,
        }));
      }

      if (errors && !!errors[fields]) {
        const errorsCopy = { ...errors };
        delete errorsCopy[fields];
        setErrors(errorsCopy);
      }
    }
  };

  const setReceiptField = (scopedId, field, value, scopedReceipts) => {
    const receiptsCopy = [...scopedReceipts];
    if (field === 'receiptAmount') {
      if (receiptsCopy[scopedId]?.markup) {
        const multiplier = (1 + parseFloat(receiptsCopy[scopedId]?.markup).toFixed(2) / 100).toFixed(2);

        receiptsCopy[scopedId].expenseTotal = parseFloat(value) * multiplier;
      } else {
        receiptsCopy[scopedId].expenseTotal = value;
      }
    }
    if (field === 'markup' && receiptsCopy[scopedId]?.receiptAmount) {
      const multiplier = (1 + parseFloat(value).toFixed(2) / 100).toFixed(2);

      receiptsCopy[scopedId].expenseTotal = parseFloat(receiptsCopy[scopedId]?.receiptAmount) * multiplier;
    }
    receiptsCopy[scopedId][field] = value;
    setReceipts(receiptsCopy);
  };

  const handleEditExpense = async () => {
    try {
      preventMultipleSave(setSaveDisabled);
      if (form?.isVoided || form?.isVoidedRebilled || rebilled.current && assignmentLineOfBusinessItems.to) {
        if (form?.isVoided) {
          await handleCreateVoidExpense({
            id: id,
            expense: form,
            receipts: receipts,
            auditKey: auditKey,
            totalToBeBilled: totalToBeBilled,
            setIsLoading: setIsLoading,
            setForm: setForm,
            navigate: navigate,
            expenseType: 'other',
            updatedBy: user.id,
            route: assignmentLineOfBusinessItems.to,
          });
        }
        if (rebilled.current) {
          await handleRebillExpense({
            id: id,
            expense: form,
            receipts: receipts,
            setIsLoading: setIsLoading,
            navigate: navigate,
            expenseType: 'other',
            updatedBy: user.id,
            route: assignmentLineOfBusinessItems.to,
          });
        }

        if (form?.isVoidedRebilled && assignmentLineOfBusinessItems.to) {
          await handleVoidAndRebillExpense({
            id: id,
            expense: form,
            receipts: receipts,
            auditKey: auditKey,
            totalToBeBilled: totalToBeBilled,
            setIsLoading: setIsLoading,
            setForm: setForm,
            navigate: navigate,
            expenseType: 'other',
            updatedBy: user.id,
            route: assignmentLineOfBusinessItems.to,
          });
        }
      } else if (fieldsAreValid()) {
        if (form?.status === 'Non-billable') {
          await confirmEditExpense({ route: `${assignmentLineOfBusinessItems.to}/search-expenses` });
        } else if (receiptsHaveFiles()) {
          await handleVerifyConfirm();
        } else {
          setShowReceiptFilesDialog(true);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddReceipt = () => {
    setReceipts([...receipts, { markup: '0' }]);
  };

  const handleDeleteRow = scopedId => {
    if (!deletedReceipts?.includes(receipts[scopedId])) {
      setDeletedReceipts([...deletedReceipts, receipts[scopedId]]);
    }
    if (!deletedReceiptDocs?.includes(receipts[scopedId].documentName)) {
      setDeletedReceiptDocs([...deletedReceiptDocs, receipts[scopedId].documentName]);
    }
    const receiptsCopy = receipts.filter((r, index) => index !== scopedId);
    setReceipts(receiptsCopy);
    setReceiptModified(true);
  };

  const setReceiptFile = async ({ id: scopedId, fileName, fileSize }) => {
    const receiptsCopy = [...receipts];
    receiptsCopy[scopedId].uploadedDate = new Date().toISOString();
    receiptsCopy[scopedId].documentName = fileName;
    receiptsCopy[scopedId].documentSize = Math.round(fileSize);
    receiptsCopy[scopedId].uploadedByName = currentUser?.name;

    const receiptsErrorsCopy = [...receiptsErrors];

    if (receiptsErrorsCopy[scopedId]?.uploadedDate) delete receiptsErrorsCopy[scopedId].uploadedDate;

    if (receiptsErrorsCopy[scopedId]?.documentName) delete receiptsErrorsCopy[scopedId].documentName;

    if (receiptsErrorsCopy[scopedId]?.uploadedByName) delete receiptsErrorsCopy[scopedId].uploadedByName;

    if (receiptsErrorsCopy.length) {
      receiptsErrorsCopy.splice(scopedId, 1);
    }

    setReceiptsErrors(receiptsErrorsCopy);
    setReceipts(receiptsCopy);
    setReceiptModified(true);
  };

  const customStyles = width => ({
    control: provided => ({
      ...provided,
      width: width,
      height: '30px',
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: '200px',
    }),
  });

  const deleteDocumentFromReceipt = async scopedId => {
    const receiptsCopy = [...receipts];

    if (!deletedReceiptDocs?.includes(receipts[scopedId].documentName)) {
      setDeletedReceiptDocs([...deletedReceiptDocs, receipts[scopedId].documentName]);
    }

    receiptsCopy[scopedId].uploadedDate = null;
    receiptsCopy[scopedId].documentName = null;
    receiptsCopy[scopedId].uploadedByName = null;

    setReceipts(receiptsCopy);
    setReceiptModified(true);
    setForm({
      ...form,
      status: 'Unbilled'
    });
  };

  const handleVerifyConfirm = async () => {
    const clientIsFF = await checkClientFlatFee(form?.customerId);
    if (clientIsFF && form?.flatFeeBilling) {
      await confirmEditExpense({
        isFF: true,
        route: assignmentLineOfBusinessItems.to,
      });
    }

    if (clientIsFF && !form?.flatFeeBilling) {
      setShowFFDialog(true);
    }
    if (!clientIsFF && form?.flatFeeBilling) {
      setShowNonFFDialog(true);
    }
    if (!clientIsFF && !form?.flatFeeBilling) {
      await confirmEditExpense({
        route: assignmentLineOfBusinessItems.to,
      });
    }
  };

  const startEdit = useMemo(() => form?.driverId, [form?.driverId]);

  return (
    <Form>
      <Row style={{ margin: '16px 0 0 0' }} className="top-row">
        <div
          style={{
            width: '40%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <div className="title centered-text">Other Expense</div>
          <div className="centered-text" style={{ marginLeft: '20px' }}>
            <div className="label centered-text">ID</div> <div className="top-value centered-text">{id}</div>
          </div>
        </div>
        <div className="top-col">
          <div className="label centered-text">Code</div>
          <div className="top-value centered-text">{form?.code}</div>
        </div>
        <div className="top-col">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '225px',
            }}>
            <div className="label centered-text">Date</div>
            <div className="top-value centered-text">{expenseDate && formatIsoToDate(expenseDate)}</div>
          </div>
          {(form?.status === 'Unbilled' ||
            form.status === 'Non-billable' ||
            form.status === 'Ready to Bill' ||
            form.status === 'Flat Fee Billing') &&
            !id?.includes('CR') && (
              <Form.Group
                controlId="flatFeeBilling"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginTop: '40px',
                }}>
                <Form.Label>Flat Fee Billing</Form.Label>
                <Checkbox
                  style={{
                    color: 'red',
                    height: 0,
                    width: 0,
                    marginTop: '5px',
                  }}
                  onChange={event => {
                    setField('flatFeeBilling', event.target.checked);
                  }}
                  checked={!!form?.flatFeeBilling}
                  defaultValue={false}
                />
              </Form.Group>
            )}
        </div>
      </Row>
      {startEdit ? (
        <ExpenseForm
          customStyles={customStyles}
          setField={setField}
          errors={errors}
          form={form}
          setBranches={setBranches}
          setForm={setForm}
          rebilled={rebilled}
          formHasChanged={formHasChanged}
        />
      ) : (
        <div className="edit-form-spinner">
          <Spinner animation="border" size="sm" />
        </div>
      )}
      <div className="gray-wrapper">
        {receipts?.map((receipt, index) => (
          <Receipt
            key={index}
            receipt={receipt}
            id={index}
            receipts={receipts}
            handleDeleteRow={handleDeleteRow}
            debouncedSetReceiptField={debouncedSetReceiptField}
            receiptsErrors={receiptsErrors}
            setReceiptFile={setReceiptFile}
            setUploading={setUploading}
            setUploadError={setUploadError}
            deleteDocumentFromReceipt={deleteDocumentFromReceipt}
            form={form}
            isCR={id?.includes('CR')}
            expenseId={id}
          />
        ))}
        <DisableClickOverlay
          isDisabled={isBilledOrTransmitted(form) || id?.includes('CR') || form?.status === 'Voided'}>
          <Row className="top-row" style={{ alignItems: 'flex-start' }}>
            {/* remove button temporary  */}
            {/* <div onClick={handleAddReceipt} className="another-receipt">
              Add Another Receipt
            </div> */}
            <div className='another-receipt'></div>
            <div
              className="top-col"
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}>
              <Form.Group controlId="startDate">
                <Form.Label style={{ marginTop: '15px' }}>Start Date</Form.Label>
                <Form.Control
                  style={{
                    width: '150px',
                    height: '30px',
                    padding: 0,
                    border: '0px',
                    backgroundColor: 'white',
                    opacity: '0.7',
                  }}
                  as="input"
                  name="startDate"
                  value={form?.startDate?.substring(0, 10)}
                  readOnly
                  disabled
                />
              </Form.Group>
              <Form.Group controlId="endDate">
                <Form.Label style={{ marginTop: '15px' }}>End Date</Form.Label>
                <Form.Control
                  style={{
                    width: '150px',
                    height: '30px',
                    padding: 0,
                    border: '0px',
                    backgroundColor: 'white',
                    opacity: '0.7',
                  }}
                  as="input"
                  name="endDate"
                  value={form?.endDate?.substring(0, 10)}
                  readOnly
                  disabled
                />
              </Form.Group>
            </div>
            <Form.Group controlId="totalToBeBilled">
              <Form.Label style={{ marginTop: '15px' }}>Total To Be Billed</Form.Label>
              <InputGroup
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '150px',
                  opacity: '0.7',
                }}>
                <span
                  style={{
                    position: 'absolute',
                    left: '10px',
                    zIndex: 10000,
                  }}>
                  $
                </span>
                <Form.Control
                  style={{
                    width: '225px',
                    height: '30px',
                    padding: 0,
                    border: '0px',
                    backgroundColor: 'white',
                    marginLeft: '20px',
                  }}
                  as="input"
                  name="location"
                  value={totalToBeBilled}
                  readOnly
                  disabled
                />
              </InputGroup>
            </Form.Group>
          </Row>
        </DisableClickOverlay>
        <DisableClickOverlay
          isDisabled={isBilledOrTransmitted(form) || id?.includes('CR') || form?.status === 'Voided'}>
          <Row className="top-row" style={{ margin: '5px 0 0 0', alignItems: 'flex-start' }}>
            <div className="top-col" style={{ width: '70%' }}>
              <Form.Group controlId="notes">
                <Form.Label style={{ marginTop: '15px' }}>Notes</Form.Label>
                <Form.Control
                  style={{
                    width: '533px',
                    height: '75px',
                  }}
                  as="textarea"
                  name="notes"
                  defaultValue={form?.notes}
                  onBlur={event => {
                    setField('notes', event.target.value);
                  }}
                />
              </Form.Group>
            </div>
            <div className="top-col" style={{ alignItems: 'flex-start' }}>
              <Form.Group controlId="auditKey">
                <Form.Label style={{ marginTop: '15px' }}>Audit Key</Form.Label>
                <Form.Control
                  style={{
                    width: '200px',
                    height: '30px',
                    padding: 0,
                    border: '0px',
                    backgroundColor: 'white',
                    opacity: '0.7',
                  }}
                  as="input"
                  name="location"
                  value={auditKey}
                  readOnly
                  disabled
                />
              </Form.Group>
            </div>
          </Row>
        </DisableClickOverlay>
      </div>
      {isLoading && (
        <div className="overlay-box">
          <Spinner className="spinner" animation="border" />
        </div>
      )}
      <InvoiceDataRow
        setField={setField}
        invoiceDate={form?.invoiceDate}
        invoiceNumber={form?.invoiceNumber}
        amountBilled={form?.amountBilled ? getCurrencyString(form?.amountBilled) : ''}
        variance={getCurrencyString(
          getCalculatedVariance({
            amountBilled: form?.amountBilled,
            totalToBeBilled: totalToBeBilled,
          }),
        )}
        statementDate={form?.statementDate}
        expenseReportAppropriationNbr={form?.expenseReportAppropriationNbr}
      />

      <Row
        className="top-row"
        style={{
          margin: '20px 0 0 0',
          justifyContent: 'center',
          pointerEvents: 'all',
        }}>
        {!isBilledOrTransmitted(form) && !id.includes('CR') && form.status !== 'Voided' && (
          <Button className="button-text" style={{ width: '140px', marginRight: '20px' }}
            onClick={() => { setShowDeleteConfirmation(true) }}>
            <span>DELETE</span>
          </Button>
        )}
        <Button
          disabled={saveDisabled}
          className="button-text"
          style={{ width: '140px', marginRight: '20px' }}
          onClick={handleEditExpense}>
          <span>SAVE</span>
        </Button>
        <Button onClick={handleCancel} className="button-secondary" style={{ width: '140px' }}>
          <span>CANCEL</span>
        </Button>
      </Row>
    </Form>
  );
};

export default EditFormContent;
