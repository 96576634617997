import React, { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import * as queries from '../graphql/queries';

const Private = ({ Component, params = {} }) => {
  const location = useLocation();
  const { route } = useAuthenticator(context => [context.route]);
  const { selectedRoleId } = useSelector(state => state.dashboard);
  const navigate = useNavigate();
  const [loadedScreen, setLoadedScreen] = useState('');

  useEffect(() => {
    if (route === 'authenticated') {
      verifyRoute(selectedRoleId, location.pathname)
        .then(response => {
          if (!response || !response.authorized) {
            navigate('/');
          }
        })
        .finally(() => {
          setLoadedScreen(location.pathname);
        });
    }
  }, [location.pathname, selectedRoleId, route]);

  return route !== 'authenticated' ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : loadedScreen !== location.pathname ? (
    <LoadingScreen />
  ) : (
    <Component {...params} />
  );
};

const verifyRoute = async (roleId, route) => {
  try {
    const response = await API.graphql({
      query: queries.verifyRoute,
      variables: { roleId, route },
    });
    return response.data.verifyRoute;
  } catch (e) {
    return e;
  }
};

const LoadingScreen = () => (
  <div style={{ width: '100%', minHeight: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Spinner animation="border" size="lg" />
  </div>
);

export default Private;
