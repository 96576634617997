import { API } from 'aws-amplify';

import { listActiveUsers } from '../../../graphql/customQueries';

export const hasActiveUsers = async (id, nextToken = null) => {
  try {
    const response = await API.graphql({
      query: listActiveUsers,
      variables: { customerId: id, status: true, nextToken },
    });
    // Return true if there is a customerId equal to the requested one and is active (thus DQF can be sent)
    if (response?.data?.listUsers?.nextToken && response?.data?.listUsers?.nextToken !== nextToken) {
      return (
        !!response?.data?.listUsers?.items?.filter(({ customers }) =>
          customers?.items?.find(({ customerId, active }) => customerId === id && active),
        ).length > 0 || hasActiveUsers(id, response?.data?.listUsers?.nextToken)
      );
    }
    return (
      !!response?.data?.listUsers?.items?.filter(({ customers }) =>
        customers?.items?.find(({ customerId, active }) => customerId === id && active),
      ).length > 0
    );
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getSkillsFromDriver = async driverId => {
  const endpoint = `drivers/${driverId}`;
  try {
    const response = await API.get('centerlineportal', endpoint);
    if (response.response_code === 200) {
      return response.response_body;
    }
    return undefined;
  } catch (e) {
    if (e?.message?.includes('404')) {
      return null;
    }
    console.log(e);
    return undefined;
  }
};
