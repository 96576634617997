import { API } from 'aws-amplify';

import { listAuditbyObjectNamebyObjectId } from '../../../../graphql/queries';
import { getAirlineFormData } from '../../../../graphql/customQueries';

export const getFormData = async () => {
  try {
    const response = await API.graphql({
      query: getAirlineFormData,
      variables: {
        limit: 10000,
      },
    });
    return mapFormData(response.data);
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

const mapFormData = data => ({
  airports: data?.listAirPorts?.items,
  airlines: data?.listAirLines?.items,
});

export const getAudit = async (id) => {
  try {
    const response = await API.graphql({
      query: listAuditbyObjectNamebyObjectId,
      variables: {
        objectName: "Expense",
        objectId: { eq: id },
        limit: 1000
      },
    })
    return response?.data?.listAuditbyObjectNamebyObjectId?.items;
  } catch (e) {
    console.log('error', e)
  }
}
