import React, { useEffect, useState } from 'react';

import { faUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConstructionOutlined, Home, Web } from '@mui/icons-material';
import { Storage } from 'aws-amplify';
import { Row, Col, Spinner, Container, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  getTypes,
  getClient,
  getDriver,
  getCategories,
  getStandardDocument,
  createStandardDocument,
  updateStandardDocument,
} from './operations';
import {
  setType,
  setClient,
  setCategory,
  setClientData,
  setDriverData,
  setDocumentCategoryTypeId,
} from './standardDocumentSlice';
import { Breadcrumbs, SubHeader, Footer, InputBox, Modal, SelectorList } from '../../../../components';
import './styles.scoped.css';

const StandardDocument = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const { id } = useSelector(state => state.admin.user);
  const {
    type,
    isClient,
    category,
    clientData,
    driverData,
    documentCategoryTypeId,
    documentCategories,
    documentTypes,
  } = useSelector(state => state.standardDocument);
  const [standardDocumentId, setStandardDocumentId] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfmodal, setPdfmodal] = useState(false);
  const [pdfError, setPdfError] = useState(false);
  const [pdfErrorMessage, setPdfErrorMessage] = useState('');
  const [uploadName, setUploadName] = useState('');
  const [clientInputValue, setClientInputValue] = useState(null);
  const [driverInputValue, setDriverInputValue] = useState(null);
  const [error, setError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [clientError, setClientError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [categoryErrorMessage, setCategoryErrorMessage] = useState('');
  const [clientErrorMessage, setClientErrorMessage] = useState('');
  const [typeErrorMessage, setTypeErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [isDisable, setDisable] = useState(true);
  const [standardDocumentName, setStandardDocumentName] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [forName, setForName] = useState('');

  const forClientSelectorOptions = [
    { label: 'All Clients', name: 'All Client' },
    { label: 'Client', name: 'Client' },
  ];

  const forDriverSelectorOptions = [
    { label: 'Driver', name: 'Driver' },
  ];

  useEffect(() => {
    clearForm();
  }, []);

  useEffect(() => {
    setStandardDocumentId(location.pathname.split('/').pop());
    getCategories();
    setEditing(location.pathname.split('/')[2] === 'edit');
  }, [location]);

  useEffect(() => {
    if (documentCategoryTypeId !== null && documentCategoryTypeId !== '') {
      getTypes(documentCategoryTypeId);
    }
  }, [documentCategoryTypeId]);

  useEffect(() => {
    if (editing && standardDocumentId) {
      getEditingData();
    }
  }, [editing, standardDocumentId]);

  const putPDF = async (pdf) => {
    setStandardDocumentName(pdf.name);
    const result = await Storage.put(`standard-documents/${standardDocumentId}-${pdf.name}`, pdf, {
      level: 'public',
      contentType: 'application/pdf',
    });
    if (result && result.key) {
      getPDF(result.key);
      setPdfError(false);
      setUploadName(`${standardDocumentId}-${pdf.name}`)
    } else {
      pdfError(true);
      setPdfErrorMessage('Please upload document');
    }
  };

  const getPDF = async key => {
    const config = {
      expires: process.env.REACT_APP_AMPLIFY_STORAGE_GET_EXPIRES,
      level: 'public',
      download: false,
    };
    await Storage.get(key, config)
      .then(response => {
        fetch(response).then(result => {
          if (result.status === 200) {
            setPdfUrl(result.url);
            setPdfError(false);
          } else {
            setPdfError(true);
            setPdfErrorMessage('Please upload document');
          }
        });
      })
      .catch(err => console.log('This is the error', err));
  };

  const deletePDF = async (pdf) => {
    try {
      const response = await Storage.remove(`standard-documents/${pdf}`, {
        level: 'public',
        contentType: 'application/pdf',
      })
      return response;
    } catch (e) {
      console.log(`Error: ${JSON.stringify(e)}`);
      return e;
    }
  };

  const getEditingData = async () => {
    const editingData = await getStandardDocument(standardDocumentId);
    setDriverInputValue(editingData.driverId);
    setClientInputValue(editingData.customerId);
    getPDF(`standard-documents/${editingData.documentName}`);
    setUploadName(editingData.documentName);
    setForName(editingData?.forName);
    dispatch(setCategory(editingData.category));
    dispatch(setType(editingData.type));
    dispatch(setClient(editingData.for));
    setDisable(false);
  };

  const fetchClient = async () => {
    setLoading(true);
    const response = await getClient(clientInputValue);
    if (response.response_code === 200) {
      dispatch(setClientData(response.response_body));
      setForName(response?.response_body?.clientName);
      setError(false);
    } else {
      setError(true);
      setClientInputValue(null);
      dispatch(setClientData({}));
    }
    setLoading(false);
    console.log(response?.response_body?.clientName);
  };

  const fetchDriver = async () => {
    setLoading(true);
    const response = await getDriver(driverInputValue);
    if (response.response_code === 200) {
      dispatch(setDriverData(response.response_body));
      setForName(`${response?.response_body?.driverFirstName} ${response?.response_body?.driverLastName}`);
      setError(false);
    } else {
      setError(true);
      setDriverInputValue(null);
      dispatch(setDriverData({}));
    }
    setLoading(false);
  };

  const onTypeChange = val => {
    if (val) {
      dispatch(setType(val));
      setTypeError(false);
      setTypeErrorMessage('');
      dispatch(setClient(''));
    } else {
      setCategoryError(true);
      setTypeErrorMessage('Type is required');
    }
  };

  const onCategoryChange = val => {
    if (val) {
      dispatch(setCategory(documentCategories?.find(item => item.id === val)?.name));
      setDisable(false);
      setCategoryError(false);
      setCategoryErrorMessage('');
    } else {
      setDisable(true);
      setCategoryError(true);
      setCategoryErrorMessage('Category is required');
    }
  };

  const onCategorySelect = val => {
    if (val) {
      dispatch(setDocumentCategoryTypeId(val));
    }
  };

  const onClientChange = val => {
    if (val) {
      dispatch(setClient(val));
      setClientError(false);
      setClientErrorMessage('');
    } else {
      setClientError(true);
      setClientErrorMessage('Client is required');
    }
  };

  const formIsValid = () => {
    let isValid = true;

    if (category === '' || null) {
      setCategoryError(true);
      setCategoryErrorMessage('Category is required');
      isValid = false;
    }
    if (type === '' || null) {
      setTypeError(true);
      setTypeErrorMessage('Type is required');
      isValid = false;
    }
    if (isClient === '' || null) {
      setClientError(true);
      setClientErrorMessage('Client is required');
      isValid = false;
    }
    if (uploadName === '' || null) {
      setPdfError(true);
      setPdfErrorMessage('Please upload document');
      isValid = false;
    }
    return isValid;
  };

  const clearForm = () => {
    setPdfUrl('');
    setUploadName('');
    dispatch(setCategory(''));
    dispatch(setClient(''));
    dispatch(setType(''));
    dispatch(setDocumentCategoryTypeId(''));
    setCategoryError(false);
    setCategoryErrorMessage('');
    setForName('');
    setUploadName('');
    setStandardDocumentName('');
    setTypeError(false);
    setTypeErrorMessage('');
    setClientError(false);
    setClientErrorMessage('');
  };

  const onSubmit = async () => {
    if (formIsValid()) {
      const payload = {
        id: standardDocumentId,
        documentName: uploadName,
        customerId: isClient === 'Client' ? parseInt(clientInputValue) : 0,
        driverId: isClient === 'Driver' ? parseInt(driverInputValue) : 0,
        forName: forName,
        type: type,
        for: isClient,
        category: category,
        userStandardDocumentsId: id,
        accountMasterCode: ' ',
      };

      if (editing) {
        await updateStandardDocument(payload);
        clearForm();
        return navigation(-1);
      }
      await createStandardDocument(payload);
      clearForm();
      return navigation(-1);
    }
    return undefined;
  };

  const removePdf = (pdf) => {
    setPdfUrl(null);
    setPdfmodal(false);
    setUploadName(null);
    return deletePDF(pdf);
  };

  const onCancel = () => {
    setShowCancelModal(true);
  };

  return (
    <>
      <SubHeader
        text="Centerline Driver Qualification"
        btnSmall={<Web sx={{ color: '#fff', fontSize: 30 }} />}
        btnSmall2={<Home sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigation('/standard-documents')}
        small2Action={() => navigation('/driver-qualification')}
      />
      <Breadcrumbs
        leftTitle="DQF Home"
        midItemAction={() => navigation('/standard-documents')}
        middleTitle={'Standard Documents'}
        rightTitle={standardDocumentId && editing ? `Document ${standardDocumentId}` : `New Document`}
        route={'/driver-qualification'}
      />
      <div className="wrap">
        <div className="inner">
          <div className="bar">Standard Document</div>
          <div className="content">
            <Row className="inner-inner">
              <p className="thick-label">Document Preview</p>
              <p className="thick-label">{standardDocumentId}</p>
            </Row>
            <Row className="row-margin">
              {pdfUrl ? (
                <span style={{ paddingLeft: 15, paddingRight: 15, width: '100%' }}>
                  <embed src={pdfUrl} width="100%" height="300" />
                </span>
              ) : (
                <span style={{ padding: 15 }}>
                  <input
                    type="file"
                    accept="application/pdf"
                    onChange={e => {
                      const file = e.target.files[0];
                      putPDF(file);
                    }}
                  />
                </span>
              )}
            </Row>
            <Row className="row-margin">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  paddingLeft: 15,
                  paddingRight: 15,
                  marginTop: 15,
                }}>
                {pdfUrl && (
                  <>
                    <span>
                      <FontAwesomeIcon icon={faUpload} color="#da0f2e" size="xl" />
                      <span style={{ marginLeft: 20 }}>{standardDocumentName}</span>
                    </span>
                    {pdfUrl && (
                      <FontAwesomeIcon
                        icon={faTrash}
                        color="#da0f2e"
                        size="xl"
                        onClick={setPdfmodal}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </>
                )}
              </div>
              {!pdfUrl && (
                <div
                  style={{
                    color: 'red',
                    fontSize: 12,
                    paddingLeft: 15,
                    marginTop: 10,
                  }}>
                  {pdfErrorMessage}
                </div>
              )}
            </Row>
            <Container fluid>
              <Row className="headers">
                <span style={{ width: '20%', paddingRight: 5 }}>Category</span>
                <span style={{ width: '25%', paddingRight: 5 }}>Type</span>
                <span style={{ width: '18%' }}>For</span>

                {isClient === 'Client' && (
                  <>
                    <span style={{ width: '19%' }}>Client ID</span>
                    <span style={{ width: '15%', paddingLeft: 5 }}>Client Name</span>
                  </>
                )}

                {isClient === 'Driver' && (
                  <>
                    <span style={{ width: '19%', paddingRight: 5 }}>Driver ID</span>
                    <span style={{ width: '15%' }}>Driver Name</span>
                  </>
                )}
              </Row>
              <Row className="row-margin">
                <span style={{ width: '20%', paddingRight: 5 }}>
                  <SelectorList
                    value={category || 'Select Category'}
                    data={documentCategories.map(aCategory => ({ label: aCategory.name, value: aCategory.id }))}
                    onChange={onCategoryChange}
                    onSelect={onCategorySelect}
                  />
                  {categoryError && (
                    <div style={{ color: 'red', fontSize: 12, marginTop: 5 }}>{categoryErrorMessage}</div>
                  )}
                </span>

                <span style={{ width: '25%', paddingRight: 5 }}>
                  <SelectorList
                    value={type || 'Select Type'}
                    data={documentTypes.map(aType => ({ label: aType.Name, value: aType.Name }))}
                    style={{ paddingRight: 5 }}
                    onChange={onTypeChange}
                    disabled={isDisable}
                  />
                  {typeError && <div style={{ color: 'red', fontSize: 12, marginTop: 5 }}>{typeErrorMessage}</div>}
                </span>
                <span style={{ width: '15%' }}>
                  {documentTypes.find(item => item.Name === type)?.For === 'Client' ?
                    <SelectorList
                      value={isClient || 'Select'}
                      data={forClientSelectorOptions.map(item => {
                        return { label: item.name, value: item.name };
                      })}
                      onChange={onClientChange}
                      disabled={isDisable}
                    /> :
                    <SelectorList
                      value={isClient || 'Select'}
                      data={forDriverSelectorOptions.map(item => {
                        return { label: item.name, value: item.name };
                      })}
                      onChange={onClientChange}
                      disabled={isDisable}
                    />}
                  {clientError && (
                    <div style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                      {clientErrorMessage}
                    </div>
                  )}
                </span>
                <Col style={{ display: 'flex', flexDirection: 'row' }}>
                  {isClient === 'Client' && (
                    <>
                      <div>
                        <InputBox
                          value={clientInputValue}
                          onChange={e => setClientInputValue(e.target.value) && setDriverInputValue('')}
                          onBlur={() => (clientInputValue ? fetchClient(clientInputValue) : 0)}
                          errorMessage="Please enter a valid Client ID"
                          error={error}
                        />
                      </div>
                      <div>
                        <span className="value">
                          {loading ? (
                            <div style={{ marginLeft: 2 }}>
                              <Spinner animation="border" size="sm" />
                            </div>
                          ) : clientInputValue ? (
                            clientData.clientName
                          ) : (
                            ''
                          )}
                        </span>
                      </div>
                    </>
                  )}
                  {isClient === 'Driver' && (
                    <>
                      <InputBox
                        value={driverInputValue}
                        onChange={e => setDriverInputValue(e.target.value) && setClientInputValue('')}
                        onBlur={() => (driverInputValue ? fetchDriver(driverInputValue) : 0)}
                        errorMessage="Please enter a valid Driver ID"
                        error={error}
                      />
                      <div className="value">
                        {loading ? (
                          <div style={{ marginLeft: 20 }}>
                            <Spinner animation="border" size="sm" />
                          </div>
                        ) : !!driverInputValue ? (
                          `${driverData.driverFirstName} ${driverData.driverLastName
                          }`
                        ) : (
                          ''
                        )}
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
            <Row
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: '2%',
              }}>
              <span>
                <Button className="button" onClick={onSubmit}>
                  Submit
                </Button>
                <Button className="button" variant="custom" onClick={onCancel}>
                  Cancel
                </Button>
              </span>
            </Row>
          </div>
        </div>
      </div>
      {pdfmodal && (
        <Modal
          isOpen
          title="Remove the document?"
          secondaryOnClick={() => setPdfmodal(false)}
          primaryButtonTitle="Remove"
          secondaryButtonTitle="Cancel"
          optionalClose
          bodyContent={false}
          onClick={() => {
            removePdf(`${standardDocumentId}-${uploadName}`);
            updateStandardDocument({ id: standardDocumentId, documentName: '' });
          }}
        />
      )}
      {showCancelModal && (
        <Modal
          isOpen
          title="Cancel pending changes?"
          secondaryOnClick={() => setShowCancelModal(false)}
          primaryButtonTitle="Yes"
          secondaryButtonTitle="No"
          optionalClose
          bodyContent={false}
          onClick={() => {
            if (uploadName && pdfUrl) {
              removePdf(`${standardDocumentId}-${uploadName}`);
            }
            clearForm();
            navigation(-1);
          }}
        />
      )}
      <Footer />
    </>
  );
};
export default StandardDocument;
