import { Document, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { getCurrencyString, getDaysBetween } from '../../../../utils/utilities';
import {
  getFooter,
  getFormField,
  getHeader,
  getSection,
  getTitle,
  styles,
} from '../quoteDocument';

const getSurchargeGroup = (title, pay, bill, markUp) => {
  if (pay + bill + markUp === 0) {
    return null;
  }
  return (
    <>
      <View style={styles.row}>
        <View style={[styles.column, { marginBottom: 2 }]}>
          <Text style={styles.formTitle}>{`${title}: `}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={[styles.column, { marginRight: 10 }]}>
          <Text style={styles.formValue}>
            {`Pay ${getCurrencyString(pay)}`}
          </Text>
        </View>
        <View style={[styles.column, { marginRight: 10 }]}>
          <Text style={styles.formValue}>
            {`Bill ${getCurrencyString(bill)}`}
          </Text>
        </View>
        <View style={[styles.column]}>
          <Text style={styles.formValue}>{`Mark-up ${markUp}`}</Text>
        </View>
      </View>
    </>
  );
};
export const DriversQuoteDocument = ({ quote }) => {
  const {
    created,
    custId,
    delayBill,
    delayMarkUp,
    delayPay,
    doubleEndorsement,
    doubleEndorsementSurcharge,
    dropHookBill,
    dropHookMarkUp,
    dropHookPay,
    estPayPerDriver,
    expires,
    hazmat,
    hazmatSurcharge,
    hourlyRateBill,
    hourlyRateMarkUp,
    hourlyRatePay,
    location,
    milageRateBill,
    milageRateMarkUp,
    milageRatePay,
    nightShift,
    nightShiftSurcharge,
    numberDelay,
    numberDropHook,
    numberOfDrivers,
    numberOther,
    numberStop,
    otRateBill,
    otRateMarkUp,
    otRatePay,
    otherBill,
    otherMarkUp,
    otherPay,
    otr,
    otrSurcharge,
    perDiemMileBill,
    perDiemMileMarkUp,
    perDiemMilePay,
    quoteId,
    quoteName,
    quoteUserId,
    region,
    startDate,
    stopBill,
    stopMarkUp,
    stopPay,
    team,
    teamSurcharge,
    totalAnnualHoursOt,
    totalAnnualHoursRegular,
    totalAnnualMilage,
    totalCost,
    totalSurcharges,
    touch,
    touchSurcharge,
    union,
  } = quote;
  const daysValid = getDaysBetween(new Date(), new Date(expires));
  const showRegion = region && region.length;
  const showTotalAnnualMilage = totalAnnualMilage > 0;
  const showTotalAnnualHours = totalAnnualHoursRegular + totalAnnualHoursOt > 0;
  const showNumberDriversPerYear =
    numberDropHook + numberDelay + numberStop + numberOther > 0;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          {getHeader(quoteName, quoteId)}
          <View style={styles.row}>
            {getFormField('Created', created)}
            {getFormField('Expires', expires)}
          </View>
          <View style={styles.row}>
            {getFormField('Generated by', `${quoteUserId}, ${custId}`)}
          </View>
          <View style={styles.row}>
            {getSection('Dedicated Model, Class A Quote', [
              {
                label: 'Location (State)',
                value: location,
              },
              showRegion && {
                label: 'Region',
                value: region,
              },
              {
                label: 'Union',
                value: union,
              },
              {
                label: 'Number of Drivers',
                value: numberOfDrivers,
              },
              showTotalAnnualMilage && {
                label: 'Total Annual Mileage (Per Driver)',
                value: totalAnnualMilage,
              },
              showTotalAnnualHours && {
                label: 'Total Annual Hours (Regular Hours, Per Driver)',
                value: totalAnnualHoursRegular,
              },
              showTotalAnnualHours && {
                label: 'Total Annual Hours (Overtime Hours, Per Driver)',
                value: totalAnnualHoursOt,
              },
            ])}
          </View>
          {showNumberDriversPerYear && (
            <View style={styles.row}>
              {getSection('Total Number per Driver per Year', [
                {
                  label: 'Drop & Hook (1/3 Hr)',
                  value: numberDropHook,
                },
                {
                  label: 'Delay',
                  value: numberDelay,
                },
                {
                  label: 'Stop',
                  value: numberStop,
                },
                {
                  label: 'Other assessorial',
                  value: numberOther,
                },
              ])}
            </View>
          )}
          <View style={styles.row}>
            {getSection('Pay Surcharges', [
              {
                label: 'Hazmat',
                value: hazmat,
              },
              {
                label: 'Double Endorsement',
                value: doubleEndorsement,
              },
              {
                label: 'Away from home - OTR',
                value: otr,
              },
              {
                label: 'Night Shift',
                value: nightShift,
              },
              {
                label: 'Touch? (vs. No Touch)',
                value: touch,
              },
              {
                label: 'Team Driver',
                value: team,
              },
            ])}
          </View>
          <View style={styles.row}>{getTitle('Start Date')}</View>
          <View style={styles.row}>{getFormField('', startDate)}</View>
          {getFooter(daysValid, 1, 2, 'Driver')}
        </View>
      </Page>
      {/* Generated Quote */}
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          {getHeader(quoteName, quoteId)}
          <View style={styles.row}>
            {getSection('Pay Surcharges', [
              hazmatSurcharge > 0 && {
                label: 'Hazmat',
                value: `${getCurrencyString(hazmatSurcharge)} / Hr`,
              },
              doubleEndorsementSurcharge > 0 && {
                label: 'Double Endorsement',
                value: `${getCurrencyString(doubleEndorsementSurcharge)} / Hr`,
              },
              otrSurcharge > 0 && {
                label: 'Away from home - OTR',
                value: `${getCurrencyString(otrSurcharge)} / Hr`,
              },
              nightShiftSurcharge > 0 && {
                label: 'Night Shift',
                value: `${getCurrencyString(nightShiftSurcharge)} / Hr`,
              },
              touchSurcharge > 0 && {
                label: 'Touch? (vs. No Touch)',
                value: `${getCurrencyString(touchSurcharge)} / Hr`,
              },
              teamSurcharge > 0 && {
                label: 'Team Driver',
                value: `${getCurrencyString(teamSurcharge)} / Hr`,
              },
            ])}
          </View>
          <View style={[styles.row, { alignItems: 'bottom' }]}>
            <View style={[styles.column]}>
              <Text style={styles.h5}>{'Total Pay Surcharges: '}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.formValue}>
                {`${getCurrencyString(totalSurcharges)} / Hr`}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column}>
              {getSurchargeGroup(
                'Hourly Rate (CA & WA required)',
                hourlyRatePay,
                hourlyRateBill,
                hourlyRateMarkUp,
              )}
              {getSurchargeGroup(
                'OT Rate',
                otRatePay,
                otRateBill,
                otRateMarkUp,
              )}
              {getSurchargeGroup(
                'Mileage Rate',
                milageRatePay,
                milageRateBill,
                milageRateMarkUp,
              )}
              {getSurchargeGroup(
                'Per Diem / Mile',
                perDiemMilePay,
                perDiemMileBill,
                perDiemMileMarkUp,
              )}
              {getSurchargeGroup(
                'Drop & Hook (1/3 Hr)',
                dropHookPay,
                dropHookBill,
                dropHookMarkUp,
              )}
              {getSurchargeGroup('Delay', delayPay, delayBill, delayMarkUp)}
              {getSurchargeGroup('Stop', stopPay, stopBill, stopMarkUp)}
              {getSurchargeGroup(
                'Other assessorial (Load & Unload)',
                otherPay,
                otherBill,
                otherMarkUp,
              )}
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.h5}>Total Cost (Annual Estimate): </Text>
              <Text style={styles.formValue}>
                {getCurrencyString(totalCost)}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.h5}>
                Estimated Annual Pay per Driver (Excluding Per Diem):{' '}
              </Text>
              <Text style={styles.formValue}>
                {getCurrencyString(estPayPerDriver)}
              </Text>
            </View>
          </View>
          {getFooter(daysValid, 2, 2, 'Driver')}
        </View>
      </Page>
    </Document>
  );
};

export default DriversQuoteDocument;
