import React, { useRef, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';

const fileTypes = ['PDF'];

const ExpenseReceiptUploader = ({
  func,
  setReceiptFile,
  receiptId,
  uploadStart,
  uploadEnd,
  uploadError,
  expenseId,
}) => {
  const [hasExceededSize, setHasExceededSize] = useState(false);
  const [hasAnotherExtension, setHasAnotherExtension] = useState(false);

  const handleChange = newFile => {
    setHasAnotherExtension(false);
    if (newFile?.size / 1024 > process.env.REACT_APP_PDF_MAX_SIZE) {
      setHasExceededSize(true);
    } else {
      setHasExceededSize(false);
      func({
        file: newFile,
        setReceiptFile: setReceiptFile,
        receiptId: receiptId,
        fileSize: newFile?.size / 1024,
        uploadStart: uploadStart,
        uploadEnd: uploadEnd,
        uploadError: uploadError,
        expenseId: expenseId,
      });
    }
  };

  const onTypeError = () => {
    setHasAnotherExtension(true);
    setHasExceededSize(false);
  };

  return (
    <div>
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        onTypeError={onTypeError}
      >
        <div
          style={{
            border: '2px dashed #da0f2e',
            borderRadius: 12,
            padding: 12,
            cursor: 'pointer',
          }}
        >
          <p style={{ margin: 0, color: '#da0f2e' }}>
            Upload or drop a file right here
          </p>
        </div>
      </FileUploader>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '2rem',
          maxWidth: 250,
        }}
      >
        {hasAnotherExtension && (
          <div style={{ fontSize: '12px', color: '#da0f2e' }}>
            File type is not PDF. Please select a PDF file and try again.
          </div>
        )}
        {hasExceededSize && (
          <div style={{ fontSize: '12px', color: '#da0f2e' }}>
            File size too large for upload. Please size down to{' '}
            {process.env.REACT_APP_PDF_MAX_SIZE} KB and try again.
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpenseReceiptUploader;
