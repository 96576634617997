import { updateExpense } from '../operations';

export const checkOptions = [
  { id: 0, text: 'The Driver and Assignment' },
  { id: 1, text: 'The Client Name and Number' },
  { id: 2, text: 'The amount to bill the client' },
];

export const bulkUpdateExpenses = async items => {
  Promise.all(items.map(item => updateExpense(item))).then(() => { });
};

export const handleFlagChecked = async (selectedExpenses, navigate, userId) => {
  const editedExpenses = [];

  selectedExpenses.forEach(e => {
    const newExp = { id: e.id, checkedForUltraStaff: true, confirmedBy: userId };
    editedExpenses.push(newExp);
  });
  await bulkUpdateExpenses(editedExpenses);
};

export const handleRemoveChecked = async (selectedExpenses, navigate, assignmentLineOfBusinessItems) => {
  const editedExpenses = [];

  selectedExpenses.forEach(e => {
    const newExp = { id: e.id, checkedForUltraStaff: false };
    editedExpenses.push(newExp);
  });
  await bulkUpdateExpenses(editedExpenses);
  navigate(`${assignmentLineOfBusinessItems.to}/ready-to-bill-open`);
};

export const checkReadyToFlag = (selectedCheckboxes, setIsReadyToFlag) => {
  if (selectedCheckboxes.every(element => element === true)) {
    setIsReadyToFlag(true);
  } else {
    setIsReadyToFlag(false);
  }
};
