import React, { useRef, useCallback } from 'react';

import { Checkbox } from '@mui/material';
import { debounce } from 'lodash';
import { Row, Form, InputGroup, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Receipt from './Receipt';
import ExpenseForm from '../../../../components/ExpenseForm';
import { newDateFormatted } from '../../../../utils';
import { deleteExpenseFile, checkClientFlatFee } from '../operations';
import { preventMultipleSave } from '../TransferToUltraStaff/utils';

import './styles.scoped.css';

const FormContent = ({
  id,
  hasChanges,
  setShowCancelDialog,
  setReceipts,
  receipts,
  setHasChanges,
  errors,
  setForm,
  form,
  setErrors,
  setSaveDisabled,
  fieldsAreValid,
  confirmCreateExpense,
  receiptsHaveFiles,
  setShowReceiptFilesDialog,
  currentUser,
  receiptsErrors,
  setReceiptsErrors,
  setShowFFDialog,
  setShowNonFFDialog,
  setUploading,
  setUploadError,
  totalToBeBilled,
  auditKey,
  isCreatingItems,
  isLoading,
  saveDisabled,
  assignmentLineOfBusinessItems,
}) => {
  const navigate = useNavigate();

  const formHasChanged = useRef({
    drivers: false,
    assignments: false,
    clients: false,
    branches: false,
  });

  const setDebouncedValue = debounce(r => {
    setReceiptField(r.id, r.field, r.value, r.receipts);
  }, 2000);

  const debouncedSetReceiptField = useCallback(value => setDebouncedValue(value), []);

  const handleCancel = async () => (hasChanges ? setShowCancelDialog(true) : navigate(assignmentLineOfBusinessItems.to));

  const handleAddReceipt = () => {
    setReceipts([...receipts, { markup: '0' }]);
  };

  //sets form field
  const setField = (fields, value) => {
    if (!hasChanges) {
      setHasChanges(true);
    }
    //check if fields is an array, because sometimes is needed to set multiple fields at the same time or only one field
    if (Array.isArray(fields)) {
      const itemsToAdd = {};
      const newErrors = { ...errors };

      fields.forEach(field => {
        itemsToAdd[(field?.fieldName)] = field.fieldValue;

        if (errors && !!errors[field.fieldName]) delete newErrors[(field?.fieldName)];
      });
      setForm({
        ...form,
        ...itemsToAdd,
      });
      setErrors({
        ...newErrors,
      });
    } else {
      if (fields === 'nonBillable' && value === true) {
        setForm({
          ...form,
          [fields]: value,
          status: 'Non-billable',
        });
      } else if (fields === 'flatFeeBilling' && value === false) {
        setForm({
          ...form,
          [fields]: value,
          nonBillable: false,
          status: 'Unbilled',
          code: 'XX',
        });
      } else if (fields === 'flatFeeBilling' && value === true) {
        setForm({
          ...form,
          [fields]: value,
          nonBillable: true,
          status: 'Flat Fee Billing',
          code: 'XFFBX',
        });
      } else {
        setForm(prevState => ({
          ...prevState,
          [fields]: value,
        }));
      }

      if (errors && !!errors[fields]) {
        const errorsCopy = {
          ...errors,
        };
        delete errorsCopy[fields];
        setErrors(errorsCopy);
      }
    }
  };

  const handleCreateExpense = async () => {
    preventMultipleSave(setSaveDisabled);
    if (fieldsAreValid()) {
      if (form?.status === 'Non-billable') {
        await confirmCreateExpense({ route: '' });
      } else if (receiptsHaveFiles()) {
        await handleVerifyConfirm();
      } else {
        setShowReceiptFilesDialog(true);
      }
    }
  };
  //when attaching a document (pdf, images, etc) we set some data to the expenseItem/receipt like documentName, size, date, etc
  const setReceiptFile = async ({ id, fileName, fileSize }) => {
    const receiptsCopy = [...receipts];
    receiptsCopy[id].uploadedDate = new Date().toISOString();
    receiptsCopy[id].documentName = fileName;
    receiptsCopy[id].documentSize = Math.round(fileSize);
    receiptsCopy[id].uploadedByName = currentUser?.name;

    const receiptsErrorsCopy = [...receiptsErrors];

    if (receiptsErrorsCopy[id]?.uploadedDate) delete receiptsErrorsCopy[id].uploadedDate;

    if (receiptsErrorsCopy[id]?.documentName) delete receiptsErrorsCopy[id].documentName;

    if (receiptsErrorsCopy[id]?.uploadedByName) delete receiptsErrorsCopy[id].uploadedByName;

    if (receiptsErrorsCopy.length) {
      receiptsErrorsCopy.splice(id, 1);
    }

    setReceiptsErrors(receiptsErrorsCopy);
    setReceipts(receiptsCopy);
  };

  //for deleting a receipt (pressing Delete Row, it only will show when 2+ receipts)

  const handleDeleteRow = async id => {
    if (receipts[id].documentName) await deleteExpenseFile(receipts[id].documentName);
    const receiptsCopy = receipts.filter((r, index) => index !== id);
    setReceipts(receiptsCopy);
  };

  //when deleting the receipt we also delete the file from s3
  const deleteDocumentFromReceipt = async id => {
    const receiptsCopy = [...receipts];

    await deleteExpenseFile(receiptsCopy[id].documentName);

    if (receiptsCopy[id]?.uploadedDate) delete receiptsCopy[id].uploadedDate;

    if (receiptsCopy[id]?.documentName) delete receiptsCopy[id].documentName;

    if (receiptsCopy[id]?.uploadedByName) delete receiptsCopy[id].uploadedByName;

    setReceipts(receiptsCopy);
  };

  //same as setting the expense/form fields but this is for receipts
  //we receive an id (the receipt id, it will be the same as the index) and for that receipt we add the field value
  //if it has some errors and we added a new value, then we clear out the error for that field
  const setReceiptField = (id, field, value, scopedReceipts) => {
    const receiptsCopy = [...scopedReceipts];
    if (field === 'receiptAmount') {
      if (receiptsCopy[id]?.markup) {
        const multiplier = (1 + parseFloat(receiptsCopy[id]?.markup).toFixed(2) / 100).toFixed(2);

        receiptsCopy[id].expenseTotal = parseFloat(value) * multiplier;
      } else {
        receiptsCopy[id].expenseTotal = value;
      }
    }
    if (field === 'markup' && receiptsCopy[id]?.receiptAmount) {
      const multiplier = (1 + parseFloat(value).toFixed(2) / 100).toFixed(2);

      receiptsCopy[id].expenseTotal = parseFloat(receiptsCopy[id]?.receiptAmount) * multiplier;
    }
    receiptsCopy[id][field] = value;
    setReceipts(receiptsCopy);
  };

  const handleVerifyConfirm = async () => {
    const clientIsFF = await checkClientFlatFee(form?.customerId);
    if (clientIsFF && form?.flatFeeBilling) {
      await confirmCreateExpense({ isFF: true });
    }

    if (clientIsFF && !form?.flatFeeBilling) {
      setShowFFDialog(true);
    }
    if (!clientIsFF && form?.flatFeeBilling) {
      setShowNonFFDialog(true);
    }
    if (!clientIsFF && !form?.flatFeeBilling) {
      await confirmCreateExpense({
        route: assignmentLineOfBusinessItems.to,
      });
    }
  };

  return (
    <Form noValidate onSubmit={() => { }}>
      <Row
        style={{
          margin: '16px 0 0 0',
        }}
        className="top-row">
        <div
          style={{
            width: '40%',
          }}
          className="top-col">
          <h1 className="title">Other Expense</h1>
        </div>
        <div className="top-col">
          <h1 className="label">Code</h1> <h1 className="top-value">{form?.code}</h1>
        </div>
        <div className="top-col">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '225px',
            }}>
            <h1 className="label">Date</h1>
            <h1 className="top-value">{newDateFormatted}</h1>
          </div>
          {(form?.status === 'Unbilled' ||
            form.status === 'Non-billable' ||
            form.status === 'Ready to Bill' ||
            form.status === 'Flat Fee Billing') && (
              <Form.Group
                controlId="flatFeeBilling"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginTop: '25px',
                }}>
                <Form.Label>Flat Fee Billing</Form.Label>
                <Checkbox
                  style={{
                    color: 'red',
                    height: 0,
                    width: 0,
                    marginTop: '5px',
                  }}
                  onChange={event => {
                    setField('flatFeeBilling', event.target.checked);
                  }}
                  checked={!!form?.flatFeeBilling}
                  defaultValue={false}
                />
              </Form.Group>
            )}
        </div>
      </Row>
      {/* expense form is the component for driverName, assignment, customer and client branch */}
      <ExpenseForm setField={setField} errors={errors} form={form} setForm={setForm} formHasChanged={formHasChanged} />
      <div className="gray-wrapper">
        {/* here we map the receipt data of each receipt to a Receipt component */}
        {receipts?.map((receipt, index) => (
          <Receipt
            key={receipt.id}
            receipt={receipt}
            id={index}
            receipts={receipts}
            handleDeleteRow={handleDeleteRow}
            debouncedSetReceiptField={debouncedSetReceiptField}
            receiptsErrors={receiptsErrors}
            setReceiptFile={setReceiptFile}
            setUploading={setUploading}
            setUploadError={setUploadError}
            deleteDocumentFromReceipt={deleteDocumentFromReceipt}
            form={form}
            isCR={false}
            expenseId={id}
          />
        ))}
        <Row
          className="top-row"
          style={{
            alignItems: 'flex-start',
          }}>
          {/* remove button temporary  */}
          {/* <div onClick={handleAddReceipt} className="another-receipt">
            Add Another Receipt
          </div> */}
          <div className='another-receipt'></div>
          <div
            className="top-col"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}>
            <Form.Group controlId="startDate">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Start Date
              </Form.Label>
              <Form.Control
                style={{
                  width: '150px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="startDate"
                value={form?.startDate?.substring(0, 10)}
                readOnly
                disabled
              />
            </Form.Group>
            <Form.Group controlId="endDate">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                End Date
              </Form.Label>
              <Form.Control
                style={{
                  width: '150px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="endDate"
                value={form?.endDate?.substring(0, 10)}
                readOnly
                disabled
              />
            </Form.Group>
          </div>
          <Form.Group controlId="totalToBeBilled">
            <Form.Label
              style={{
                marginTop: '15px',
              }}>
              Total To Be Billed
            </Form.Label>
            <InputGroup
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '150px',
                opacity: '0.7',
              }}>
              <span
                style={{
                  position: 'absolute',
                  left: '10px',
                  zIndex: 10000,
                }}>
                $
              </span>
              <Form.Control
                style={{
                  width: '225px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  marginLeft: '20px',
                }}
                as="input"
                name="location"
                value={totalToBeBilled}
                readOnly
                disabled
              />
            </InputGroup>
          </Form.Group>
        </Row>
        <Row
          className="top-row"
          style={{
            margin: '5px 0 0 0',
            alignItems: 'flex-start',
          }}>
          <div
            className="top-col"
            style={{
              width: '70%',
            }}>
            <Form.Group controlId="notes">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Notes
              </Form.Label>
              <Form.Control
                style={{
                  width: '533px',
                  height: '75px',
                }}
                as="textarea"
                name="notes"
                defaultValue={form?.notes}
                onBlur={event => {
                  setField('notes', event.target.value);
                }}
              />
            </Form.Group>
          </div>
          <div
            className="top-col"
            style={{
              alignItems: 'flex-start',
            }}>
            <Form.Group controlId="auditKey">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Audit Key
              </Form.Label>
              <Form.Control
                style={{
                  width: '200px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="location"
                value={auditKey}
                readOnly
                disabled
              />
            </Form.Group>
          </div>
        </Row>
      </div>
      {(isCreatingItems || isLoading) && (
        <div className="overlay-box">
          <Spinner className="spinner" animation="border" />
        </div>
      )}

      <Row
        className="top-row"
        style={{
          margin: '50px 0 0 0',
          justifyContent: 'center',
        }}>
        <Button
          disabled={saveDisabled}
          className="button-text"
          style={{
            width: '140px',
            marginRight: '20px',
          }}
          onClick={handleCreateExpense}>
          <span>SAVE</span>
        </Button>
        <Button
          onClick={handleCancel}
          className="button-secondary"
          style={{
            width: '140px',
          }}>
          <span>CANCEL</span>
        </Button>
      </Row>
    </Form>
  );
};

export default FormContent;
