import * as queries from '../../../graphql/queries';
import { API, Storage, graphqlOperation } from 'aws-amplify';
import {
  setDqPdfDocument,
  setPdfError,
  setDQ,
  setQuestions,
  setError,
} from './viewDQFSlice';
import * as mutations from '../../../graphql/mutations';
import { getUser } from '../../../graphql/queries';

export const getDQByID = async (dispatch, id) => {
  try {
    const response = await API.graphql({
      query: queries.getDriverQualification,
      variables: { id: id },
    });
    if (response.data.getDriverQualification) {
      dispatch(setDQ(response.data.getDriverQualification));
    } else {
      dispatch(setError('Not Found'));
    }
  } catch (e) {
    console.log(e);
  }
};
export const getDQEmail = async id => {
  try {
    const response = await API.graphql({
      query: queries.getDriverQualification,
      variables: { id: id },
    });

    if (response.data.getDriverQualification) {
      return response.data.getDriverQualification?.dqClientApproverUser;
    }
  } catch (e) {
    console.log('get dq error', e);
  }
};

export const getPDF = async (dispatch, pdf) => {
  const config = {
    expires: process.env.REACT_APP_AMPLIFY_STORAGE_GET_EXPIRES,
    level: 'public',
    download: false,
  };
  await Storage.get(`dqf/${pdf}`, config)
    .then(response => {
      fetch(response).then(result => {
        if (result.status === 200) {
          dispatch(setDqPdfDocument(result.url));
          dispatch(setPdfError(false));
        } else {
          dispatch(setPdfError(true));
        }
      });
    })
    .catch(err => console.log('This is the error', err));
};

export const updateDQStatus = async (
  dispatch,
  id,
  status,
  clientId,
  clientApproverName,
) => {
  const update = {
    id: id,
    status: status.toString(),
    dqClientApproverUser: clientId,
    dqClientApproverUserFullName: clientApproverName,
  };
  try {
    const response = await API.graphql(
      graphqlOperation(mutations.updateDriverQualification, {
        input: update,
      }),
    );

    dispatch(setDQ(response.data.updateDriverQualification));

    return true;
  } catch (e) {
    console.log('Update Status Error:', e);
    return false;
  }
};

export const createQuestion = async (
  dispatch,
  id,
  createUser,
  text,
  clientApproverName,
  statusChange,
  dqOwnerFullName,
) => {
  const create = {
    driverQualificationQuestionsId: id,
    user: createUser,
    text: text,
    status: 'In-Progress',
    createUserFullName: clientApproverName
      ? clientApproverName
      : dqOwnerFullName,
    type: statusChange,
  };
  try {
    const response = await API.graphql(
      graphqlOperation(mutations.createQuestions, { input: create }),
    );
    dispatch(setQuestions(response.data.createQuestions));
    return true;
  } catch (e) {
    console.log('Update Status Error: ' + JSON.stringify(e));
    return false;
  }
};

export const generatePWToken = async (objectId, email) => {
  const data = {
    ApplicationId: '2',
    ObjectId: objectId,
    UserId: email,
    Event: 'GeneratePWToken',
  };
  try {
    const response = await API.graphql(
      graphqlOperation(mutations.createEvent, {
        input: data,
      }),
    );
    return true;
  } catch (e) {
    console.log('Update Status Error: ' + JSON.stringify(e));
    return false;
  }
};

export const fetchUser = async id => {
  try {
    const response = await API.graphql({
      query: getUser,
      variables: { id: id },
    });
    return response.data.getUser.name;
  } catch (e) {
    console.log(e);
  }
};

export const getUserInfo = async id => {
  try {
    const response = await API.graphql({
      query: getUser,
      variables: { id: id },
    });
    return response.data.getUser;
  } catch (e) {
    console.log(e);
  }
};
