import React from 'react';

import { MdEdit } from 'react-icons/md';

const EditButton = (tableContent, setOpenEditModal) => {
  const thisContent = tableContent.data[parseInt(tableContent.cell.row.id)];
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      <div style={{ cursor: 'pointer', padding: 4 }} onClick={() => setOpenEditModal(thisContent)}>
        <MdEdit color="#da0f2e" />
      </div>
    </div>
  );
};

export default EditButton;
