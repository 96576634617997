import { API, Storage, graphqlOperation } from 'aws-amplify';
import dayjs from 'dayjs';

import * as customMutations from '../../../graphql/customMutations';
import * as customQueries from '../../../graphql/customQueries';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';

export const uploadExpenseFile = async ({
  expenseId,
  file,
  setReceiptFile,
  receiptId,
  fileSize,
  uploadStart,
  uploadEnd,
  uploadError,
}) => {
  const folderName = 'expense/';
  const fileExt = `.${file.name.split('.').pop()}`;

  try {
    uploadStart();
    const result = await Storage.put(
      folderName + expenseId + '_' + (file?.name?.replace(/\.[^/.]+$/, '') || '') + fileExt,
      file,
      {
        level: 'public',
        contentType: 'application/octet-stream',
      },
    );

    if (result?.key) {
      if (receiptId !== undefined) {
        setReceiptFile({
          id: receiptId,
          fileName: expenseId + '_' + (file?.name?.replace(/\.[^/.]+$/, '') || '') + fileExt,
          fileSize: fileSize,
        });
      } else {
        setReceiptFile({
          fileName: expenseId + (file?.name?.replace(/\.[^/.]+$/, '') || '') + fileExt,
          fileSize: fileSize,
        });
      }
      uploadEnd();
    }
  } catch (e) {
    console.error('Error uploading expense file:', e);
    uploadError();
  }
};

export const createExpense = async expense => {
  try {
    const response = await API.graphql(graphqlOperation(customMutations.createExpense, { input: expense }));
    return response.data.createExpense;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const createExpenseItem = async item => {
  
  try {
    const response = await API.graphql(
      graphqlOperation(customMutations.createExpenseItem, { input: { ...item, __typename: undefined } }),
    );
    return response.data.createExpenseItem;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getDrivers = async query => {
  const endpoint = query;

  try {
    const response = await API.get('centerlineportal', endpoint);
    if (response.response_code === 200) {
      return response.response_body;
    }
    return undefined;
  } catch (e) {
    if (e?.message?.includes('404')) {
      return [];
    }
    console.log(e);
    return undefined;
  }
};

export const getAssignments = async query => {
  const endpoint = query;

  try {
    const response = await API.get('centerlineportal', endpoint);
    if (response.response_code === 200) {
      return response.response_body;
    }
    return undefined;
  } catch (e) {
    if (e) {
      return [];
    }
    console.log(e);
    return undefined;
  }
};

export const getClients = async query => {
  const endpoint = query;
  try {
    const response = await API.get('centerlineportal', endpoint);
    if (response.response_code === 200) {
      return response.response_body;
    }
    return undefined;
  } catch (e) {
    if (e?.message?.includes('404')) {
      return [];
    }
    console.log(e);
    return undefined;
  }
};
export const getClientBranches = async query => {
  const endpoint = query ? `clients/branches${query}` : 'clients/branches';

  try {
    const response = await API.get('centerlineportal', endpoint);
    if (response.response_code === 200) {
      return response.response_body;
    }
    return undefined;
  } catch (e) {
    if (e?.message?.includes('404')) {
      return [];
    }
    console.log(e);
    return undefined;
  }
};

export const deleteExpenseFile = async fileName => {
  const folderName = 'expense/';
  await Storage.remove(folderName + fileName, {
    level: 'public',
    contentType: 'application/octet-stream',
  });
};

export const getExpenses = async () => {
  try {
    await API.graphql(graphqlOperation(customQueries.listExpenses));
  } catch (e) {
    console.log(e);
  }
};

export const getExpense = async id => {
  try {
    const response = await API.graphql({
      query: queries.getExpense,
      variables: { id: id },
    });
    return response.data.getExpense;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const updateExpense = async expense => {
  try {
    const response = await API.graphql(graphqlOperation(customMutations.updateExpense, { input: expense }));
    return response.data.updateExpense;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
export const updateExpenseItem = async expenseItem => {
  try {
    const response = await API.graphql(
      graphqlOperation(customMutations.updateExpenseItem, {
        input: expenseItem,
      }),
    );
    return response.data.updateExpenseItem;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const deleteExpense = async expense => {
  try {
    const response = await API.graphql(graphqlOperation(mutations.deleteExpense, { input: { id: expense } }));
    return response.data.deleteExpense;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const deleteExpenseItem = async expense => {
  try {
    const response = await API.graphql(graphqlOperation(mutations.deleteExpenseItem, { input: { id: expense } }));
    return response.data.deleteExpenseItem;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getExpenseId = async () => {
  try {
    const response = await API.get('centerlineportal', 'expense-ids');
    return response.response_body.id;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const checkClientFlatFee = async clientId => {
  try {
    const response = await API.get('centerlineportal', `client-flatfee/${clientId}`);

    if (response.response_code === 200) {
      return response.response_body?.flatFeeFound;
    }
    return undefined;
  } catch (e) {
    if (e?.message?.includes('404')) {
      return [];
    }
    console.log(e);
    return undefined;
  }
};

export const getAudit = async (id) => {
  try {
    const response = await API.graphql({
      query: queries.listAuditbyObjectNamebyObjectId,
      variables: {
        objectName: "Expense",
        objectId: { eq: id },
        limit: 1000
      },
    })
    return response?.data?.listAuditbyObjectNamebyObjectId?.items;
  } catch (e) {
    console.log('error', e)
  }
}