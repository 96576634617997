import { createSlice } from '@reduxjs/toolkit';

//TODO double check the role access for each menu item

const expensesEntrySlice = createSlice({
  name: 'edgeReporting',
  initialState: {
    subHeader: 'Choose your report',
    clientMenu: {
      menuItems: [
        {
          id: 1,
          text: 'Deduction Register',
          to: '/edge-reporting/deduction-register',
          disabled: false,
        },
        {
          id: 2,
          text: 'Client Billing by AMC MM/YR',
          to: '/edge-reporting/client-billing-amc-mm-yr',
          disabled: false,
        },
        {
          id: 3,
          text: 'Check PDF',
          to: '/edge-reporting/check-pdf',
          disabled: false,
        },
        {
          id: 4,
          text: 'Invoice PDF',
          to: '/edge-reporting/invoice-pdf',
          disabled: false,
        },
        {
          id: 5,
          text: 'Payroll Check Register',
          to: '/edge-reporting/payroll-check-register',
          disabled: false,
        },
        {
          id: 6,
          text: 'AR Aging Report',
          to: '/edge-reporting/ar-aging-report',
          disabled: false,
        },
        {
          id: 7,
          text: 'Gross Margin AMC',
          to: '/edge-reporting/gross-margin-amc',
          disabled: false,
        },
        {
          id: 8,
          text: 'SA Client Ranking by AMC',
          to: '/edge-reporting/sa-client-ranking-amc',
          disabled: false,
        },
        {
          id: 9,
          text: 'Sales Tax Report',
          to: '/edge-reporting/sales-tax-report',
          disabled: false,
        },
        {
          id: 10,
          text: 'Contract Activity Search',
          to: '/edge-reporting/contract-activity-search',
          disabled: false,
        },
        {
          id: 11,
          text: 'Client Search',
          to: '/edge-reporting/client-search',
          disabled: false,
        },
        {
          id: 12,
          text: 'Driver Search',
          to: '/edge-reporting/driver-search',
          disabled: false,
        },
        {
          id: 13,
          text: '401K Report',
          to: '/edge-reporting/401k-report',
          disabled: false,
        },
        {
          id: 14,
          text: 'Risk Claims Validation',
          to: '/edge-reporting/risk-claims-validation',
          disabled: false,
        },
        {
          id: 15,
          text: 'Worker Compensation',
          to: '/edge-reporting/worker-compensation',
          disabled: false,
        },
        {
          id: 16,
          text: 'Check Reconciliation',
          to: '/edge-reporting/check-reconciliation',
          disabled: false,
        },
        {
          id: 17,
          text: 'Web Portal Activity',
          to: '/edge-reporting/web-portal-activity',
          disabled: false,
        },
        {
          id: 18,
          text: 'Client Contact Activity',
          to: '/edge-reporting/client-contact-activity',
          disabled: false,
        },
        {
          id: 19,
          text: 'Driver Sexual Harassment Training',
          to: '/edge-reporting/driver-sexual-harassment-training',
          disabled: false,
        },
      ],
    },
  },
  reducers: {},
});

export default expensesEntrySlice.reducer;

export const { setRTBCurrentIds } = expensesEntrySlice.actions;
