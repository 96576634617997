// import { setLoading, setCurrentQuote } from './driversSlice';
import { Auth } from 'aws-amplify';
import quoteApi from '../../../utils/quoteAPi';
import { setMyQuotes, setLoading } from './myQuotesSlice';

export const getMyQuotes = async dispatch => {
  dispatch(setLoading(true));
  const body = {
    request: 'GetQuotesList',
    custId: 'UPS',
  };
  await quoteApi
    .post('', body, {
      headers: {
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
    })
    .then(resp => {
      const { statusCode, body } = resp.data;
      if (statusCode === 200) {
        dispatch(setMyQuotes(body?.items));
      }
      dispatch(setLoading(false));
    });
  dispatch(setLoading(false));
};
