import { Button, Col, Row, Form, Spinner } from 'react-bootstrap';
import { customStyles } from '../utils';
import Select from 'react-select';
import './styles.scoped.css';

export const DriverSexualHarassmentTrainingForm = ({
  form,
  setForm,
  handleChange,
  locationData,
  isLoadingStates,
  driverStatusOptions,
  isLoadingDriverStatuses,
  navigate,
  handleViewResults,
  driverStatusError,
  setDriverStatusError,
  empHoursGreaterThanError,
  setEmpHourGreaterThanError,
}) => {

  return (
    <div className="filters-container">
      <div className="filter-row">
        <Form.Group as={Row} style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>Corporation #</Form.Label>
          <Form.Label style={{ width: '290px' }}>100</Form.Label>
        </Form.Group>
      </div>
      <div className="filter-row">
        <Form.Group as={Row} style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>Driver Status</Form.Label>
          <Select
            isDisabled={isLoadingDriverStatuses}
            isMulti
            closeMenuOnSelect={false}
            styles={customStyles('365px')}
            options={driverStatusOptions}
            defaultValue={form?.DriverStatus?.split(',').map(code =>
              driverStatusOptions.find(option => option.value === code),
            )}
            onChange={selectedOptions => {
              const selectedCodes = selectedOptions.map(option => option.value);
              setForm({
                ...form,
                EmpStatus: selectedCodes.join(','),
              });
            }}
          />
          <Spinner
            as="span"
            animation="border"
            size="sm"
            aria-hidden="true"
            className="dropdown-spinner"
            hidden={!isLoadingDriverStatuses}
          />
        </Form.Group>
      </div>

      <div className="filter-row">
        <Form.Group as={Row} style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>
            <div className='required'>Driver State</div>
          </Form.Label>
          <Col>
            <Row>
              <Select
                isClearable
                isMulti
                isDisabled={isLoadingStates}
                styles={customStyles('365px')}
                options={locationData?.states}
                onChange={selectedStates => {
                  const states = selectedStates?.map(state => state.value);
                  setForm({
                    ...form,
                    EmpState: states.join(','),
                  });
                }}
                value={form?.EmpState?.split(',').map(selectedState =>
                  locationData?.states?.find(state => state.value === selectedState),
                )}
                onBlur={() => { setDriverStatusError(false) }}
              />
              <Spinner
                as="span"
                animation="border"
                size="sm"
                aria-hidden="true"
                className="dropdown-spinner"
                hidden={!isLoadingStates}
              />
            </Row>
            {driverStatusError && (
              <Row>
                <Col>
                  <p style={{ fontSize: 15 }} className="fs-3 text-danger">Please provide a valid value</p>
                </Col>
              </Row>
            )}
          </Col>
        </Form.Group>
      </div>
      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="EmplCity"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Driver City</Form.Label>
          <Form.Control
            style={{
              width: '360px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            type="text"
            name="EmplCity"
            defaultValue={form?.EmplCity}
            onBlur={handleChange}
          />
        </Form.Group>
      </div>

      <div
        className="filter-row"
        style={{
          marginTop: '30px',
        }}
      >
        <Form.Group
          as={Row}
          controlId="FromStartDate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>
            <div>Driver Start Date</div>
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            type="date"
            name="FromStartDate"
            value={form?.FromStartDate}
            onChange={handleChange}
            max={form?.ToStartDate || undefined}
          />
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="ToStartDate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label
            style={{
              width: '20px',
              marginLeft: '30px',
              marginRight: '20px',
            }}
          >
            To
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
            }}
            as="input"
            type="date"
            name="ToStartDate"
            value={form?.ToStartDate}
            onChange={handleChange}
            min={form?.FromStartDate || undefined}
          />
        </Form.Group>
      </div>

      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="EmpHoursGreaterThan"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>
            <div className='required'>Work Hours Greater Than</div>
          </Form.Label>
          <Col>
            <Row>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                type="number"
                name="EmpHoursGreaterThan"
                defaultValue={form?.EmpHoursGreaterThan}
                onChange={handleChange}
                onBlur={() => { setEmpHourGreaterThanError(false) }}
                min={0}
              />
            </Row>
            {empHoursGreaterThanError && (
              <Row>
                <Col>
                  <p style={{ fontSize: 15 }} className="fs-4 text-danger">Please provide a valid value</p>
                </Col>
              </Row>
            )}
          </Col>
        </Form.Group>
      </div>

      <div
        className="filter-row"
        style={{
          marginTop: '30px',
        }}
      >
        <Form.Group
          as={Row}
          controlId="FromPerEnddate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>
            <div>Pay Period End Date</div>
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            type="date"
            name="FromPerEnddate"
            value={form?.FromPerEnddate}
            onChange={handleChange}
            max={form?.ToPerEnddate || undefined}
          />
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="ToPerEnddate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label
            style={{
              width: '20px',
              marginLeft: '30px',
              marginRight: '20px',
            }}
          >
            To
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
            }}
            as="input"
            type="date"
            name="ToPerEnddate"
            value={form?.ToPerEnddate}
            onChange={handleChange}
            min={form?.FromPerEnddate || undefined}
          />
        </Form.Group>
      </div>

      <div
        className="filter-row"
        style={{
          marginTop: '30px',
        }}
      >
        <Form.Group
          as={Row}
          controlId="FromCheckDate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>
            <div>Start Check Date</div>
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            type="date"
            name="FromCheckDate"
            value={form?.FromCheckDate}
            onChange={handleChange}
            max={form?.ToCheckDate || undefined}
          />
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="ToCheckDate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label
            style={{
              width: '20px',
              marginLeft: '30px',
              marginRight: '20px',
            }}
          >
            To
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
            }}
            as="input"
            type="date"
            name="ToCheckDate"
            value={form?.ToCheckDate}
            onChange={handleChange}
            min={form?.FromCheckDate || undefined}
          />
        </Form.Group>
      </div>
      <div className="buttons-row">
        <Button onClick={() => handleViewResults()} style={{ marginRight: '20px', width: '100px' }}>
          <span>VIEW</span>
        </Button>

        <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
          <span>CANCEL</span>
        </Button>
      </div>
    </div>
  );
};
