import { Row, Form, Spinner, } from 'react-bootstrap';
import { customStyles, formatSocial } from '../utils';
import { Tooltip, Radio } from '@mui/material';
import { MdInfo } from 'react-icons/md';
import Select from 'react-select';
import './styles.scoped.css';

export const WorkerCompensationForm = ({
  form,
  setForm,
  handleChange,
  branchOptions,
  WCCodeOptions,
  WCStateOptions,
  isLoadingBranches,
  isLoadingWCCodes,
  isLoadingWCStates,
}) => {
  return (
    <div className="filters-container">
      <div className="filter-row">
        <Form.Group as={Row} style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>Corporation #</Form.Label>
          <Form.Label style={{ width: '290px' }}>100</Form.Label>
        </Form.Group>
      </div>
      <div className="filter-row">
        <Form.Group as={Row} style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>Branch</Form.Label>
          <Select
            isDisabled={isLoadingBranches}
            isMulti
            closeMenuOnSelect={false}
            styles={customStyles('365px')}
            options={branchOptions}
            value={form?.Branch?.split(',').map(code =>
              branchOptions.find(option => String(option.value) === code),
            )}
            onChange={selectedOptions => {
              const selectedCodes = selectedOptions.map(option => option.value);
              setForm({
                ...form,
                Branch: selectedCodes.join(','),
              });
            }}
          />
          <Spinner
            as="span"
            animation="border"
            size="sm"
            aria-hidden="true"
            className="dropdown-spinner"
            hidden={!isLoadingBranches}
          />
        </Form.Group>
      </div>

      <div
        className="filter-row"
        style={{
          marginTop: '30px',
        }}
      >
        <Form.Group
          as={Row}
          controlId="FromCheckdate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>
            <div className="required">Check Date </div>
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            type="date"
            name="FromCheckdate"
            value={form?.FromCheckdate}
            onChange={handleChange}
            onBlur={null}
          />
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="ToCheckdate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label
            style={{
              width: '20px',
              marginLeft: '30px',
              marginRight: '20px',
            }}
          >
            To
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
            }}
            as="input"
            type="date"
            name="ToCheckdate"
            value={form?.ToCheckdate}
            onChange={handleChange}
            onBlur={null}
          />
        </Form.Group>
      </div>
      <div className="filter-row">
        <b>OR</b>
      </div>
      <div
        className="filter-row"
        style={{
          marginTop: '30px',
        }}
      >
        <Form.Group
          as={Row}
          controlId="FromPerEnddate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>
            <div className="required">Period End Date </div>
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            type="date"
            name="FromPerEnddate"
            value={form?.FromPerEnddate}
            onChange={handleChange}
            onBlur={null}
          />
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="ToPerEnddate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label
            style={{
              width: '20px',
              marginLeft: '30px',
              marginRight: '20px',
            }}
          >
            To
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
            }}
            as="input"
            type="date"
            name="ToPerEnddate"
            value={form?.ToPerEnddate}
            onChange={handleChange}
            onBlur={null}
          />
        </Form.Group>
      </div>

      <div className="filter-row">
        <Form.Group as={Row} style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>Work Comp Code</Form.Label>
          <Select
            isDisabled={isLoadingWCCodes}
            isMulti
            closeMenuOnSelect={false}
            styles={customStyles('365px')}
            options={WCCodeOptions}
            defaultValue={form?.CompCode?.split(',').map(code =>
              WCCodeOptions.find(option => option.value === code),
            )}
            onChange={selectedOptions => {
              const selectedCodes = selectedOptions.map(option => option.value);
              setForm({
                ...form,
                CompCode: selectedCodes.join(','),
              });
            }}
          />
          <Spinner
            as="span"
            animation="border"
            size="sm"
            aria-hidden="true"
            className="dropdown-spinner"
            hidden={!isLoadingWCCodes}
          />
        </Form.Group>
      </div>

      <div className="filter-row">
        <Form.Group as={Row} style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>Work Comp State</Form.Label>
          <Select
            isDisabled={isLoadingWCStates}
            isMulti
            closeMenuOnSelect={false}
            styles={customStyles('365px')}
            options={WCStateOptions}
            defaultValue={form?.CompState?.split(',').map(code =>
              WCStateOptions.find(option => option.value === code),
            )}
            onChange={selectedOptions => {
              const selectedCodes = selectedOptions.map(option => option.value);
              setForm({
                ...form,
                CompState: selectedCodes.join(','),
              });
            }}
          />
          <Spinner
            as="span"
            animation="border"
            size="sm"
            aria-hidden="true"
            className="dropdown-spinner"
            hidden={!isLoadingWCStates}
          />
        </Form.Group>
      </div>

      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="EmplLastName"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Driver Last Name</Form.Label>
          <Form.Control
            style={{
              width: '360px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            name="EmplLastName"
            value={form?.EmplLastName}
            onChange={handleChange}
          />
        </Form.Group>
      </div>

      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="EmplFirstName"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Driver First Name</Form.Label>
          <Form.Control
            style={{
              width: '360px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            name="EmplFirstName"
            value={form?.EmplFirstName}
            onChange={handleChange}
          />
        </Form.Group>
      </div>

      <div className="filter-row">
        <Form.Group as={Row} controlId="EmpId" style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>Driver Number</Form.Label>
          <Form.Control
            style={{
              width: '360px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            name="EmpId"
            value={form?.EmpId}
            type="number"
            onChange={handleChange}
          />
        </Form.Group>
      </div>

      <div className="filter-row">
        <Form.Group as={Row} controlId="SSNO" style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>Driver SSN</Form.Label>
          <Form.Control
            style={{
              width: '360px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            name="SSNO"
            value={formatSocial(form?.SSNO || '')}
            onChange={handleChange}
          />
        </Form.Group>
      </div>
      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="ReportFormat"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Report Type</Form.Label>
          <div className="dropdown-spinner-container">
            <Select
              styles={customStyles('360px')}
              options={[
                { label: 'Detail', value: 'Detail' },
                { label: 'Summary', value: 'Summary' },
              ]}
              onChange={(selectedOption, action) => {
                const _form = {
                  ...form,
                  ReportFormat:
                    action.action === 'clear' ? '' : selectedOption.value,
                };
                setForm(_form);
              }}
              name="ReportFormat"
              value={{
                label: form?.ReportFormat,
                value: form?.ReportFormat,
              }}
            />
          </div>
        </Form.Group>
      </div>
      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="ReportType"
          name="ReportType"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '280px' }}>Filter For
            <Tooltip
              aria-multiline={true}
              title={<div>
                Standard = Work Comp Amount is not zero.<br />
                All Wages = All Work Comp Amounts.<br />
                Exception = Work Comp Amounts equal zero.
              </div>}
              arrow style={{ marginLeft: 5, marginBottom: 3 }}>
              <MdInfo color="red" />
            </Tooltip>
          </Form.Label>
          <span>
            <span className="radio-group">
              <Radio
                style={{
                  color: '#da0f2e',
                  transform: 'scale(1)',
                }}
                onChange={() => setForm({
                  ...form,
                  FilterBy: 'standard',
                })}
                name="fliterStandard"
                checked={form?.FilterBy === 'standard'}
              />
              <span>Standard</span>
            </span>
            <span className="radio-group">
              <Radio
                style={{
                  color: '#da0f2e',
                  transform: 'scale(1)',
                  marginLeft: 30,
                }}
                onChange={() => setForm({
                  ...form,
                  FilterBy: 'allWages',
                })}
                name="fliterAllWages"
                checked={form?.FilterBy === 'allWages'}
              />
              <span>All Wages</span>
            </span>
            <span className="radio-group">
              <Radio
                style={{
                  color: '#da0f2e',
                  transform: 'scale(1)',
                  marginLeft: 30,
                }}
                onChange={() => setForm({
                  ...form,
                  FilterBy: 'exception',
                })}
                name='fliterException'
                checked={form?.FilterBy === 'exception'}
              />
              <span>Exception</span>
            </span>
          </span>
        </Form.Group>
      </div>
    </div>
  );
};
