import React, { useEffect, useState } from 'react';

import { Web, Home } from '@mui/icons-material';
import { Auth } from 'aws-amplify';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { addAppAccess, getAccessList, deleteAppAccess, editAppAccess } from './operations';
import { SubHeader, AccessList, Modal, Footer } from '../../../components';

import './styles.scoped.css';

const EditAccess = () => {
  const navigation = useNavigate();
  const { user, type } = useParams();
  const [modalObj, setModalObj] = useState();
  const [accessModalVisible, setAccessModalVisible] = useState(false);
  const [adding, setAdding] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [editing, setEditing] = useState();
  const [loading, setLoading] = useState(false);
  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
  const [appAdminType, setAppAdminType] = useState('');
  const [thisUserAppList, setThisUserAppList] = useState([]);

  useEffect(() => {
    if (user) {
      getList();
    }
  }, [user]);

  const getList = async () => {
    setLoading(true);
    const list = await getAccessList(user);
    setThisUserAppList(list);
    setLoading(false);
  };

  const showAppList = () => {
    setAccessModalVisible(true);
  };

  const checkIfGlobalAdmin = list => {
    list.forEach(app => {
      if (app?.appId && app?.roleId) {
        if (app?.appId?.id === '5' && app?.roleId?.id !== '6') {
          setAppAdminType(app?.roleId?.applicationUsedBy);
        }
        if (app?.appId?.id === '5' && app?.roleId?.id === '6') {
          setIsGlobalAdmin(true);
        }
      }
    });
  };

  const handleGetUser = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const currentUserObj = currentUser.signInUserSession.idToken.payload;
    if (currentUserObj) {
      const { email } = currentUserObj;
      const list = await getAccessList(email);
      checkIfGlobalAdmin(list);
    }
  };
  useEffect(() => {
    handleGetUser();
  }, [thisUserAppList]);

  const addAccess = async obj => {
    setAdding(true);
    let result;
    if (editing) {
      result = await editAppAccess({
        id: thisUserAppList.find(item => item.appId.id === obj.selectedApp).id,
        applicationRoleUserAppAccessId: obj.selectedRole,
        applicationUserAppAccessId: obj.selectedApp,
      });
    } else {
      result = await addAppAccess({
        userAppAccessId: user,
        applicationRoleUserAppAccessId: obj.selectedRole,
        applicationUserAppAccessId: obj.selectedApp,
      });
    }
    setAdding(false);
    setEditing(false);
    if (result.data) {
      getList().then(() => setAccessModalVisible(false));
    } else {
      setModalObj({
        title: 'Unable to add access at this time.',
        action: null,
      });
    }
  };

  const removeAccess = app => {
    setModalObj({
      title: `Do you want to remove all access from ${app.appId.name} for ${user}?`,
      action: 'Remove',
      id: app.id,
    });
  };

  const editAccess = app => {
    setEditing(app.appId.id);
    setAccessModalVisible(true);
  };

  const onRemove = async () => {
    setRemoving(true);
    const { id } = modalObj;
    const result = await deleteAppAccess({ id });
    setRemoving(false);
    if (result.data) {
      getList().then(() => setModalObj(null));
    } else {
      setModalObj({
        title: 'Unable to remove access at this time.',
        action: null,
      });
    }
  };

  return (
    <div>
      <SubHeader
        btnSmall={<Web sx={{ color: '#fff', fontSize: 30 }} />}
        btnSmall2={<Home sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigation('/dashboard')}
        small2Action={() => navigation(-1)}
        text="Admin Portal"
      />
      <Container className="wrap">
        <div className="inner" style={{ width: '100%', margin: 'auto' }}>
          <div className="bar">User Application Access</div>
          <div className="content">
            <Row>
              <Col>
                <p className="label">User</p>
                <p className="value">{user}</p>
              </Col>
              <Col>
                <p className="label">User Name</p>
                <p className="value">{user}</p>
              </Col>
              <Col />
            </Row>
            <Row>
              <Col>
                {(appAdminType !== '' || isGlobalAdmin) && (
                  <Button className="btn-txt" onClick={showAppList}>
                    Add New App
                  </Button>
                )}
              </Col>
            </Row>
            {loading ? (
              <div className="spinner-wrap">
                <Spinner animation="border" />
              </div>
            ) : (
              <div className="rectangle">
                {thisUserAppList?.length ? (
                  thisUserAppList?.map(app => (
                    <Row key={app?.appId?.id}>
                      <Col>
                        <p className="label">Application</p>
                        <p className="value">{app.appId.name}</p>
                      </Col>
                      <Col>
                        <p className="label">Role(s)</p>
                        <p className="value">{app.roleId.name}</p>
                      </Col>

                      <Col
                        style={{
                          alignItems: 'flex-end',
                        }}>
                        <span>
                          <Button
                            style={{ margin: 5 }}
                            disabled={app?.appId?.id !== appAdminType && !isGlobalAdmin}
                            className="btn-txt inverse"
                            onClick={() => editAccess(app)}>
                            Edit Access
                          </Button>
                          <Button
                            style={{ margin: 5 }}
                            disabled={app?.appId?.id !== appAdminType && !isGlobalAdmin}
                            className="btn-txt"
                            onClick={() => removeAccess(app)}>
                            Remove All Access
                          </Button>
                        </span>
                      </Col>
                    </Row>
                  ))
                ) : (
                  <div>{user} has no application access</div>
                )}
              </div>
            )}
          </div>
        </div>
      </Container>
      {accessModalVisible && (
        <div className="modal-wrap">
          <AccessList
            isGlobalAdmin={isGlobalAdmin}
            appAdminType={appAdminType}
            userType={type}
            editing={editing}
            loading={adding}
            userApps={thisUserAppList}
            onClose={() => {
              setAccessModalVisible(false);
              setEditing(false);
            }}
            makeEdit={addAccess}
          />
        </div>
      )}
      <Modal
        loading={removing}
        isOpen={!!modalObj}
        title={modalObj?.title}
        onClick={onRemove}
        secondaryOnClick={() => setModalObj(null)}
        primaryButtonTitle={modalObj?.action}
        secondaryButtonTitle="Close"
        optionalClose
        bodyContent={false}
      />
      <Footer />
    </div>
  );
};

export default EditAccess;
