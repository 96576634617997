import { API } from 'aws-amplify';
import { listStandardDocuments } from '../../../../graphql/queries';
import { setList } from './standardDocumentsSlice';
import store from '../../../../app/store';

export const getItems = async () => {
    try {
        const response = await API.graphql({
            query: listStandardDocuments,
        });
        store.dispatch(setList(response.data?.listStandardDocuments?.items));
    } catch (e) {
        console.log(e);
    }
};