import React from 'react';

import { Web } from '@mui/icons-material';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setCurrentTab } from './quoteEngineSlice';
import { Button, MenuItem, SubHeader, Footer } from '../../components';
import './styles.scoped.css';

const QuoteEngine = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { menuData, subHeaderText, questionTitleText } = useSelector(state => state.quoteEngine);

  const onPress = item => {
    navigate(item.to);
  };

  return (
    <>
      <SubHeader
        text={subHeaderText}
        btnSmall={<Web sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigate('/dashboard')}
        btnLarge={<Button text="MY QUOTES" onPress={() => navigate('/quote-engine/my-quotes')} />}
        btnInfo
        emailAddress={process.env.REACT_APP_QUOTE_ENGINE_CONTACT_EMAIL}
        infoText="Need help with your quote?"
      />
      <Container className="wrap">
        <h1 className="title">{questionTitleText}</h1>
        <div className="inner-wrap" style={{ height: (menuData.length / 2) * 120 }}>
          {menuData?.map(item => (
            <MenuItem
              key={item.text}
              text={item.text}
              icon={item.icon}
              description={item.description}
              onPress={() => {
                dispatch(setCurrentTab(item.text.toLowerCase()));
                onPress(item);
              }}
            />
          ))}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default QuoteEngine;
