/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { SubHeader, Footer } from '../../components'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Web, Home } from '@mui/icons-material';
import { getClientAccess } from './operations';
import { CommonTable as DQTable } from '../../components';
import "./styles.scoped.css";

const ClientAccess = () => {
    const navigation = useNavigate();
    const { listData } = useSelector((state) => state.clientAccess);

    useEffect(() => {
        getClientAccess();
    }, []);

    const appAccess = (user) => {
        navigation(`/edit-access/${user.type}/${user.id}`)
    }

    return (
        <>
            <SubHeader
                btnSmall={<Web sx={{ color: '#fff', fontSize: 30 }} />}
                btnSmall2={<Home
                    sx={{ color: '#fff', fontSize: 30 }}
                />}
                smallAction={() => navigation('/dashboard')}
                small2Action={() => navigation(-1)}
                text="Admin Portal"
            />
            <div className="wrap">
                <DQTable
                    data={listData.filter(item => item.type === 'Client')}
                    dataType='Client'
                    handleSetData={getClientAccess}
                    handleViewApps={appAccess}
                    addUser={() => navigation('/client-users/add')}
                    updateClientUser={() => navigation('/client-users/update')}
                    showInviteButton
                />
            </div>
            <Footer />
        </>
    );
}

export default ClientAccess;   