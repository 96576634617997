// Desc: This file contains the function to convert the data to csv format
const getCSVData = (data = [], headers = []) => {
  const columnNames = headers.map(({ Header }) => Header);
  const columns = headers.map(header => ({
    Cell: ({ value }) => (typeof value === 'number' ? value : `=""${value}""`),
    ...header,
  }));

  const csvData = [columnNames];

  for (let i = 0; i < data.length; i += 1) {
    const dataRow = [];
    for (let j = 0; j < columns.length; j++) {
      dataRow.push(
        columns[j].Cell({
          value: data[i][columns[j].accessor],
          row: { original: data[i], id: i },
        }),
      );
    }
    csvData.push(dataRow);
  }
  return csvData;
};

export default getCSVData;
