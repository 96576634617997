/* eslint-disable consistent-return */
import { API, Storage, graphqlOperation } from 'aws-amplify';

import {
  getDriverQualification,
  getListChecklistByClientIdTier,
  getListChecklistBycheckListLevelAccountMasterCodeClientIdTier,
} from '../../../graphql/customQueries';
import {
  createDriverQualificationChecklistDocuments,
  deleteDriverQualificationSelectedDocuments,
  updateDriverQualification,
  updateDriverQualificationChecklistDocuments,
} from '../../../graphql/mutations';
import {
  getDriverQualificationChecklistDocuments,
  listDocumentCategories,
  listDocumentSources,
  listDocumentTypes,
} from '../../../graphql/queries';

export const dqSelectRest = async (inputDocuments, outputDocument) => {
  try {
    return await API.post('centerlineportal', 'dqassemblement', {
      body: {
        outputDocument,
        inputDocuments,
      },
    });
  } catch (e) {
    console.log('error', e);
    return false;
  }
};

export const createChecklistDocument = async document => {
  try {
    const input = {
      driverQualificationDriverQualificationChecklistDocumentsId: document?.id,
      item: document?.item,
      source: document?.source,
      category: document?.category,
      type: document?.type,
      order: document?.order,
      documentName: document?.documentName,
    };

    return await API.graphql(graphqlOperation(createDriverQualificationChecklistDocuments, { input }));
  } catch (e) {
    console.log('error', e);
    return false;
  }
};

export const getCategories = async () => {
  try {
    const response = await API.graphql({
      query: listDocumentCategories,
    });
    return response?.data?.listDocumentCategories?.items;
  } catch (e) {
    console.log(e);
  }
};

export const getListCheckListBycheckListLevelAccountMasterCodeClientIdTier = async (
  checkListLevel,
  accountMasterCode,
  clientId,
  tier,
) => {
  try {
    const response = await API.graphql({
      query: getListChecklistBycheckListLevelAccountMasterCodeClientIdTier,
      variables: {
        checkListLevel,
        filter: {
          clientId: checkListLevel === 'Single Client' ? { eq: clientId } : undefined,
          accountMasterCode: checkListLevel === 'AMC' && accountMasterCode ? { eq: accountMasterCode } : undefined,
          tier: tier !== undefined || tier !== null ? { eq: tier } : undefined,
        },
      },
    });

    return response.data?.listChecklistBycheckListLevelAccountMasterCodeClientIdTier?.items?.[0];
  } catch (e) {
    console.log(e);
  }
};

export const getChecklistByClientIdTierDocument = async (clientId, tier) => {
  try {
    const response = await API.graphql({
      query: getListChecklistByClientIdTier,
      variables: {
        clientId: clientId,
        tier: { eq: tier },
      },
    });
    return response?.data?.listChecklistByClientIdTier?.items.map(item => item.checklistDocumentName);
  } catch (e) {
    console.log(e);
  }
};

export const getDQByID = async id => {
  try {
    const response = await API.graphql({
      query: getDriverQualification,
      variables: { id },
    });
    return response?.data?.getDriverQualification;
  } catch (e) {
    console.log(e);
  }
};

export const getDriverChecklistDocuments = async id => {
  try {
    const response = await API.graphql({
      query: getDriverQualificationChecklistDocuments,
      variables: { id },
    });
    return response?.data?.getDriverQualificationChecklistDocuments;
  } catch (e) {
    console.log(e);
  }
};

export const getPDF = async pdf => {
  try {
    const config = {
      expires: process.env.REACT_APP_AMPLIFY_STORAGE_GET_EXPIRES,
      level: 'public',
      download: false,
    };

    const response = await Storage.get(pdf, config);
    const result = await fetch(response);
    if (result.status === 200) {
      return result.url;
    }
    console.error('PDF error');
    return result;
  } catch (err) {
    console.log('This is the error', err);
    return err;
  }
};

export const getSources = async () => {
  try {
    const response = await API.graphql({
      query: listDocumentSources,
    });
    return response?.data?.listDocumentSources?.items;
  } catch (e) {
    console.log(e);
  }
};

export const getTypes = async () => {
  try {
    const response = await API.graphql({
      query: listDocumentTypes,
    });
    return response?.data?.listDocumentTypes?.items;
  } catch (e) {
    console.log(e);
  }
};

export const getDriverQualificationDocuments = async id => {
  try {
    const response = await API.graphql({
      query: getDriverQualificationChecklistDocuments,
      variables: { id },
    });
    return response?.data?.getDriverQualification?.driverQualificationChecklistDocuments?.items.map(items =>
      items?.driverQualificationSelectedDocuments?.items?.map((itemss, index) => itemss[index]),
    );
  } catch (e) {
    console.log(e);
  }
};

export const removeDriverQualificationSelectedDocuments = async id => {
  try {
    const body = await API.graphql(
      graphqlOperation(deleteDriverQualificationSelectedDocuments, {
        input: { id },
      }),
    );
    return body;
  } catch (e) {
    console.log('GraphQL createDriverQualificationChecklistDocuments Error:', e);
  }
};

export const updateDriverQualificationChecklistDocument = async input => {
  try {
    const body = await API.graphql(
      graphqlOperation(updateDriverQualificationChecklistDocuments, {
        input: input,
      }),
    );
    return body;
  } catch (e) {
    console.log('GraphQL updateDriverQualificationSelectedDocuments Error:', e);
  }
};

export const updateDQOwner = async (id, dqSpecialistUser, dqOwnerFullName, userId) => {
  const input = {
    id: id,
    dqSpecialistEmail: dqSpecialistUser,
    dqOwnerFullName,
    updateUser: userId,
  };
  try {
    await API.graphql(graphqlOperation(updateDriverQualification, { input: input }));
    return true;
  } catch (e) {
    console.log(`Update Status Error: ${JSON.stringify(e)}`);
    return false;
  }
};

export const updateDQDocName = async (id, docName) => {
  const update = {
    id: id,
    dqDocumentName: docName,
  };
  try {
    await API.graphql(
      graphqlOperation(updateDriverQualification, {
        input: update,
      }),
    );
    return true;
  } catch (e) {
    console.log(`Update Status Error: ${JSON.stringify(e)}`);
    return false;
  }
};

export const updateDQStatus = async (id, status, userId, clientApproverName) => {
  const update = {
    id: id,
    status: status,
    dqClientApproverUser: userId,
    dqClientApproverUserFullName: clientApproverName,
  };

  try {
    const response = await API.graphql(
      graphqlOperation(updateDriverQualification, {
        input: update,
      }),
    );
    console.log('Driver Qualification Status', response);
    return true;
  } catch (e) {
    console.log('Update Status Error:', e);
    return false;
  }
};

export const updateChecklistDocuments = async items => {
  try {
    const response = await updateDriverQualificationChecklistDocument(items);
    return response;
  } catch (e) {
    console.log('error', e);
  }
};
