import { formatIsoToDate } from '../../../utils';

const getCSVData = data => {
  const csvData = [
    [
      'Branch',
      'LOB',
      'Job#',
      'Asgn Status',
      'Appl Last Name',
      'Appl First Name',
      'Appl Middle Name',
      'Position',
      'Start Date',
      'End Date',
      'Client Name',
    ],
  ];
  let i;
  for (i = 0; i < data.length; i += 1) {
    csvData.push([
      `${data[i].Branch || ''}`,
      `${data[i].LOB || ''}`,
      `${data[i].JOB_ID || ''}`,
      `${data[i].ASS_STATUS || ''}`,
      `${data[i].Appl_Last_Name || ''}`,
      `${data[i].Appl_First_Name || ''}`,
      `${data[i].Appl_Middle_Name || ''}`,
      `${data[i].ASS_POSITION || ''}`,
      `${formatIsoToDate(data[i].ASS_STARTDATE) || ''}`,
      `${formatIsoToDate(data[i].ASS_ENDDATE) || ''}`,
      `${data[i].CUST_NAME || ''}`,
    ]);
  }
  return csvData;
};

export default getCSVData;
