import React, { useEffect, useState, useRef, useMemo } from 'react';

import { faUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Home } from '@mui/icons-material';
import { Radio } from '@mui/material';
import { Storage } from 'aws-amplify';
import { Row, Col, Form, Spinner, Container, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import './styles.scoped.css';
import Select from 'react-select';

import {
  addRow,
  clearRows,
  removeRow,
  setTier,
  setClientData,
  updateItem,
  updateSource,
  updateCategory,
  updateType,
  updateOrder,
  updatePages,
  removeClientData,
  removeRowByProp,
} from './checkListSlice';
import {
  getAMC,
  getClient,
  getSources,
  getCategories,
  getTypes,
  newChecklist,
  createChecklistItem,
  getList,
  editChecklist,
  editChecklistItem,
  getChecklistByClientIdTier,
  removeChecklist,
  removeChecklistItems,
} from './operations';
import { Breadcrumbs, SubHeader, Footer, InputBox, ChecklistRow, Modal } from '../../../../components';
import { createUUID } from '../../../../utils/createUUID';

const ChecklistDetails = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const windowEndRef = useRef(null);

  const { tierData: dqTierData, rows, clientData } = useSelector(state => state.checkList);
  const selectedTier = useSelector(state => state.checkList.selectedTier);
  const { listData } = useSelector(state => state.checklistTemplates);

  const [id, setId] = useState();
  const [clientInputValue, setClientInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [posting, setPosting] = useState(false);
  const [pdfUrl, setPdfUrl] = useState();
  const [pdfError, setPdfError] = useState(false);
  const [uploadName, setUploadName] = useState();
  const [showModal, setShowModal] = useState();
  const [clientError, setClientError] = useState(false);
  const [editing, setEditing] = useState(false);
  const [pdfmodal, setPdfmodal] = useState(false);
  const [key, setKey] = useState();
  const [successModal, setSuccessModal] = useState(false);
  const [showValidClientModal, setShowValidClientModal] = useState(false);
  const [formattedDocumentData, setFormattedDocumentData] = useState({});
  const [clientName, setClientName] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [removeChecklistModal, setRemoveChecklistModal] = useState(false);
  const [checkListLevel, setCheckListLevel] = useState(null);
  const [AMCCode, setAMCCode] = useState('');
  const [AMCName, setAMCName] = useState('');
  const [AMCCodeError, setAMCCodeError] = useState(false);
  const [showCLDDisabledAlert, setShowCLDDisabledAlert] = useState(false);
  const [rowsToDelete, setRowsToDelete] = useState([]);

  const formatData = () => {
    if (rows.length) {
      const formattedData = {
        sources: [],
        categories: [],
        types: [],
      };

      rows.forEach(row => {
        formattedData.sources = [...formattedData.sources, row.source];
        formattedData.categories = [...formattedData.categories, row.category];
        formattedData.types = [...formattedData.types, row.type];
      });

      setFormattedDocumentData(formattedData);
    }
  };

  const tierDropDownStyles = width => ({
    control: provided => ({
      ...provided,
      width: width,
      height: '30px',
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: '200px',
    }),
    menu: provided => ({
      ...provided,
      zIndex: 100000,
    }),
  });

  const getClientChecklist = async (clientId, tier) => {
    const hasCheckList = await getChecklistByClientIdTier(clientId, tier);
    if (hasCheckList) {
      setShowValidClientModal(true);
    }
  };

  const tierOptions = dqTierData?.map(({ value, label }) => ({
    label: label,
    value: value,
  }));

  const getEditData = async () => {
    const editingData = await getList(id);
    setClientName(editingData.clientName);
    getPDF(editingData.checklistDocumentName);
    setClientInputValue(editingData.clientId);
    setUploadName(editingData.checklistDocumentName);
    dispatch(setTier(editingData.tier));
    setCheckListLevel(editingData.checkListLevel);
    if (editingData.checkListLevel === 'AMC' && editingData.accountMasterCode) {
      setAMCCode(editingData.accountMasterCode);
      fetchAMCCode(editingData.accountMasterCode);
    }

    const edittingItems = editingData.items?.items.sort((a, b) => parseInt(a?.order) - parseInt(b?.order));
    edittingItems.forEach(item => {
      dispatch(
        addRow({
          id: item.id,
          category: item.category,
          item: item.item,
          source: item.source,
          type: item.type,
          pages: item.pages,
          order: item.order,
          checklistItemsId: item.order,
        }),
      );
    });
  };

  const addRows = async checklistItemsId => {
    await rows.forEach(row => {
      if (row?.id) {
        editChecklistItem({
          id: row.id,
          checklistItemsId,
          item: row.item,
          order: row.order ? row.order : rows.length?.toString(),
          pages: row.pages,
          source: row.source,
          type: row.type,
          category: row.category,
        });
      } else {
        createChecklistItem({
          checklistItemsId,
          item: row.item,
          order: row.order ? row.order : rows.length?.toString(),
          pages: row.pages,
          source: row.source,
          type: row.type,
          category: row.category,
        });
      }
      setPosting(false);
    });
    // throw success modal
    setSuccessModal(true);
  };

  const scrollToBottom = () => {
    windowEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchClient = async value => {
    setClientError(false);
    setLoading(true);
    const response = await getClient(value || clientInputValue);
    if (response.response_code === 200) {
      dispatch(setClientData(response.response_body));
    } else {
      setClientError(true);
      setClientInputValue('');
      dispatch(setClientData({}));
    }
    setLoading(false);
  };

  const fetchAMCCode = async value => {
    setAMCCodeError(false);
    setLoading(true);
    const response = await getAMC(value || AMCCode);
    if (response.response_code === 200 && response.response_body?.length > 0) {
      setAMCCode(response.response_body[0]?.Code);
      setAMCName(response.response_body[0]?.Description);
    } else {
      setAMCCodeError(true);
      setAMCCode('');
      setAMCName(null);
    }
    setLoading(false);
  };

  const putPDF = async pdf => {
    setUploadName(pdf.name);
    const result = await Storage.put(`checklist/${id}.pdf`, pdf, {
      level: 'public',
      contentType: 'application/pdf',
    });
    if (result && result.key) {
      getPDF(result.key);
    }
  };

  const getPDF = async scopedKey => {
    setKey(scopedKey);
    const config = {
      expires: process.env.REACT_APP_AMPLIFY_STORAGE_GET_EXPIRES,
      level: 'public',
      download: false,
    };
    await Storage.get(scopedKey, config)
      .then(response => {
        fetch(response).then(result => {
          if (result.status === 200) {
            setPdfUrl(result.url);
            setPdfError(false);
          } else {
            setPdfError(true);
          }
        });
      })
      .catch(err => console.log('This is the error', err));
  };

  const deletePdf = async pdf => {
    try {
      const response = await Storage.remove(`checklist/${pdf}`, {
        level: 'public',
        contentType: 'application/pdf',
      });
      return response;
    } catch (e) {
      console.log(`Error: ${JSON.stringify(e)}`);
      return e;
    }
  };

  const deleteRow = async (idToDelete, checklistItemsIdToDelete) => {
    try {
      setRowsToDelete([...rowsToDelete, idToDelete]);
      dispatch(
        idToDelete
          ? removeRow(idToDelete)
          : removeRowByProp({ prop: 'checklistItemsId', value: checklistItemsIdToDelete }),
      );
    } catch (e) {
      console.log('error', e);
    }
  };

  const putDocumentRow = () => {
    dispatch(
      addRow({
        category: '0',
        item: '',
        source: '3',
        type: '0',
        pages: 'All',
        order: '1',
        checklistItemsId: 0,
      }),
    );
  };

  const newRow = () => {
    dispatch(
      addRow({
        category: '',
        item: '',
        source: '',
        type: '',
        pages: 'All',
        order: (rows.length + 1)?.toString() || '1',
        checklistItemsId: createUUID(),
      }),
    );
    scrollToBottom();
  };

  const onSubmit = () => {
    let errorMessage = '';
    // check that client id is set, file is uploaded, at least one row
    if (checkListLevel === 'Single Client' && (!selectedTier || !clientInputValue)) {
      errorMessage = 'Please select a Tier and Client ID';
    } else if (checkListLevel === 'AMC' && !AMCCode) {
      errorMessage = 'Please enter an AMC Code';
    } else if (!uploadName) {
      errorMessage = 'Please upload the document';
    } else if (rows?.length < 1) {
      errorMessage = 'Please add at least one row';
    }
    if (errorMessage === '') {
      if (editing) {
        edit();
      } else {
        createNew();
      }
    } else {
      alert(errorMessage);
    }
  };

  const createNew = async () => {
    setPosting(true);
    const newList = await newChecklist({
      checkListLevel,
      checklistDocumentName: key,
      clientId: checkListLevel === 'AMC' || checkListLevel === 'CLD Default' ? 0 : parseInt(clientInputValue),
      clientName:
        checkListLevel === 'AMC' || checkListLevel === 'CLD Default'
          ? ''
          : clientInputValue
          ? clientData.clientName
          : '',
      id,
      tier: checkListLevel === 'AMC' || checkListLevel === 'CLD Default' ? 0 : parseInt(selectedTier),
      accountMasterCode: checkListLevel === 'AMC' && AMCCode ? AMCCode || '' : '',
    });

    if (newList.errors) {
      // error
      setPosting(false);
    } else {
      addRows(newList.id);
    }
  };

  const edit = async () => {
    setPosting(true);
    try {
      await Promise.all(rowsToDelete.map(idToDelete => removeChecklistItems(idToDelete)));
    } catch (error) {
      console.log('error', error);
    }
    const editedList = await editChecklist({
      checkListLevel,
      checklistDocumentName: key,
      clientId: checkListLevel === 'AMC' || checkListLevel === 'CLD Default' ? 0 : parseInt(clientInputValue),
      clientName:
        checkListLevel === 'AMC' || checkListLevel === 'CLD Default'
          ? ''
          : clientInputValue
          ? clientData.clientName
          : '',
      id,
      tier: checkListLevel === 'AMC' || checkListLevel === 'CLD Default' ? 0 : parseInt(selectedTier),
      accountMasterCode: checkListLevel === 'AMC' && AMCCode ? AMCCode || '' : '',
    });
    if (editedList.errors) {
      // error
      setPosting(false);
    } else {
      addRows(id);
    }
  };

  const removePdf = () => {
    setPdfmodal(true);
  };

  const onCancel = () => {
    setShowCancelModal(true);
  };

  const onDelete = async () => {
    try {
      await rows.forEach(item => {
        removeChecklistItems(item?.id);
      });
      await removeChecklist(id);
      deletePdf(id);
      navigation(-1);
    } catch (error) {
      console.log('error', e);
    }
  };

  const cldDisabled = useMemo(
    () => listData.find(checklist => checklist.checkListLevel === 'CLD Default' && checklist.id !== id),
    [listData, id],
  );

  const thereIsAnUncompletedRow = useMemo(() => rows.find(row => !row.source || !row.category || !row.type), [rows]);

  useEffect(() => {
    formatData();
  }, [rows]);

  useEffect(() => {
    if (clientInputValue && !location.pathname.includes('edit') && selectedTier) {
      getClientChecklist(clientInputValue, selectedTier);
    }
  }, [clientInputValue, selectedTier]);

  useEffect(() => {
    setId(location.pathname.split('/').pop());
    getSources();
    getCategories();
    getTypes();
    dispatch(clearRows());
    setClientName('');
    dispatch(setTier(null));
    setEditing(location.pathname.split('/')[2] === 'edit-details');
  }, [location]);

  useEffect(() => {
    if (clientName === null || clientName === '') {
      fetchClient(clientInputValue);
      setClientName(clientData?.clientName);
    }
  }, [clientName, selectedTier]);

  useEffect(() => {
    if (editing && id) {
      getEditData();
    }
  }, [editing, id]);

  return (
    <>
      <SubHeader
        text="Centerline Driver Qualification"
        btnSmall={<Home sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigation('/driver-qualification')}
      />
      <Breadcrumbs
        leftTitle="DQF Home"
        midItemAction={() => navigation('/checklist-template')}
        middleTitle="Checklist Templates"
        rightTitle={id && editing ? `Template ${id}` : 'New Checklist'}
        route="/driver-qualification"
      />
      <div className="wrap">
        <div className="inner">
          <div className="bar">Checklist</div>
          <div className="content">
            <Row className="inner-inner">
              <p className="thick-label mb-0">Level</p>
              <p className="thick-label mb-0">{id}</p>
            </Row>
            <Row>
              <span className="radio-group mr-4 mb-2">
                <Radio
                  style={{
                    color: '#da0f2e',
                    transform: 'scale(1)',
                  }}
                  onChange={() => setCheckListLevel('Single Client')}
                  checked={checkListLevel === 'Single Client'}
                />
                <span>Single Client</span>
              </span>
              <span className="radio-group mr-4 mb-2">
                <Radio
                  style={{
                    color: '#da0f2e',
                    transform: 'scale(1)',
                  }}
                  onChange={() => setCheckListLevel('AMC')}
                  checked={checkListLevel === 'AMC'}
                />
                <span>AMC</span>
              </span>
              <span className="radio-group mr-4 mb-2">
                <Radio
                  style={{
                    color: cldDisabled ? '#da0f2e88' : '#da0f2e',
                    transform: 'scale(1)',
                  }}
                  onChange={() => (cldDisabled ? setShowCLDDisabledAlert(true) : setCheckListLevel('CLD Default'))}
                  checked={checkListLevel === 'CLD Default'}
                />
                <span style={{ color: cldDisabled ? 'gray' : 'black' }}>CLD Default</span>
              </span>
            </Row>
            {checkListLevel === 'Single Client' && (
              <>
                <Row className="inner-inner">
                  <p className="thick-label">Tier</p>
                </Row>
                <Row>
                  <Col>
                    <Select
                      styles={tierDropDownStyles('540px')}
                      options={tierOptions}
                      onChange={event => dispatch(setTier(event.value))}
                      value={tierOptions.find(option => option.value === selectedTier)}
                    />
                  </Col>
                  <Col />
                </Row>
                <Row className="row-margin">
                  <Col>
                    <p className="thick-label mb-0">Client</p>
                  </Col>
                  <Col />
                </Row>
                <Row className="row-margin">
                  <InputBox
                    title="Client ID"
                    value={clientInputValue}
                    span
                    onChange={e => setClientInputValue(e.target.value)}
                    onBlur={() => (clientInputValue ? fetchClient(clientInputValue) : null)}
                    errorMessage="Please enter a valid Client ID"
                    error={clientError}
                    placeholder="Enter ID..."
                  />
                  <Col>
                    <Form.Label>Client Name</Form.Label>
                    {loading ? (
                      <div style={{ marginLeft: 20 }}>
                        <Spinner animation="border" size="sm" />
                      </div>
                    ) : (
                      <div className="value">{clientData.clientName || clientName}</div>
                    )}
                  </Col>
                </Row>
              </>
            )}
            {checkListLevel === 'AMC' && (
              <>
                <Row className="row-margin">
                  <p className="thick-label mb-0 ml-2">AMC Code</p>
                </Row>
                <Row className="row-margin">
                  <InputBox
                    title="AMC Code"
                    value={AMCCode}
                    span
                    onChange={e => setAMCCode(e.target.value)}
                    onBlur={() => AMCCode && fetchAMCCode(AMCCode)}
                    errorMessage="Please enter a valid AMC Code"
                    error={AMCCodeError}
                    placeholder="Enter AMC Code..."
                  />
                  <Col>
                    <Form.Label>AMC Name</Form.Label>
                    {loading ? (
                      <div style={{ marginLeft: 20 }}>
                        <Spinner animation="border" size="sm" />
                      </div>
                    ) : (
                      <div className="value">{AMCName}</div>
                    )}
                  </Col>
                </Row>
              </>
            )}
            <Row className="row-margin">
              <Col>
                <p className="thick-label">Checklist Document Preview</p>
              </Col>
              <Col style={{ textAlign: 'right', marginTop: 10 }}>
                {editing && pdfUrl && (
                  <FontAwesomeIcon
                    icon={faTrash}
                    color="#da0f2e"
                    size="xl"
                    onClick={removePdf}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </Col>
            </Row>
            {pdfUrl ? (
              <embed src={pdfUrl} width="100%" height="600" />
            ) : (
              <input
                type="file"
                accept="application/pdf"
                onChange={e => {
                  const file = e.target.files[0];
                  putPDF(file);
                  putDocumentRow();
                }}
              />
            )}

            <Row className="row-margin">
              <Col>
                <p className="thick-label">Checklist Document</p>
              </Col>
              <Col />
            </Row>
            <Row className="row-margin">
              <Col>
                {uploadName && (
                  <>
                    <FontAwesomeIcon icon={faUpload} color="#da0f2e" size="xl" />
                    <span style={{ marginLeft: 20 }}>{uploadName}</span>
                  </>
                )}
              </Col>
              <Col />
            </Row>
            <Container fluid>
              <Row className="headers">
                <span style={{ width: '20%', paddingLeft: 15 }}>Item</span>
                <span style={{ width: '15%' }}>Source</span>
                <span style={{ width: '15%' }}>Category</span>
                <span style={{ width: '20%' }}>Type</span>
                <span style={{ width: '15%' }}>Pages</span>
                <span style={{ width: '10%' }}>Order</span>
              </Row>
              {rows.map((item, index) => (
                <ChecklistRow
                  id={index}
                  key={item?.id || item?.checklistItemsId}
                  row={item}
                  highlight={showModal === item.checklistItemsId}
                  onItemChange={e =>
                    dispatch(
                      updateItem({
                        checklistItemsId: item.checklistItemsId,
                        item: e.target.value,
                      }),
                    )
                  }
                  onSourceChange={val =>
                    dispatch(
                      updateSource({
                        checklistItemsId: item.checklistItemsId,
                        source: val,
                      }),
                    )
                  }
                  onCategoryChange={val =>
                    dispatch(
                      updateCategory({
                        checklistItemsId: item.checklistItemsId,
                        category: val,
                      }),
                    )
                  }
                  onTypeChange={val =>
                    dispatch(
                      updateType({
                        checklistItemsId: item.checklistItemsId,
                        type: val,
                      }),
                    )
                  }
                  onOrderChange={val =>
                    dispatch(
                      updateOrder({
                        checklistItemsId: item.checklistItemsId,
                        order: val,
                      }),
                    )
                  }
                  onPagesChange={e =>
                    dispatch(
                      updatePages({
                        checklistItemsId: item.checklistItemsId,
                        pages: e.target.value,
                      }),
                    )
                  }
                  onTrash={() => {
                    setShowModal(item.checklistItemsId);
                    setSelectedRow(item);
                  }}
                  documentData={formattedDocumentData}
                />
              ))}
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Button className="plus" onClick={newRow} title={!uploadName ? 'Please upload Document first' : null}>
                  +
                </Button>
              </Row>
              <Row style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <span>
                  {editing && (
                    <Button
                      disabled={posting || thereIsAnUncompletedRow}
                      className="button-danger"
                      variant="triary"
                      onClick={() => setRemoveChecklistModal(true)}>
                      Delete
                    </Button>
                  )}
                  <Button disabled={posting || thereIsAnUncompletedRow} className="button" onClick={onSubmit}>
                    {posting ? 'Saving...' : 'Save'}
                  </Button>
                  <Button className="button" variant="custom" onClick={onCancel}>
                    Cancel
                  </Button>
                </span>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <div ref={windowEndRef} />
      <Modal
        isOpen={!!showModal}
        title="Delete this row?"
        secondaryOnClick={() => setShowModal(null)}
        primaryButtonTitle="Delete"
        secondaryButtonTitle="Cancel"
        optionalClose
        bodyContent={false}
        onClick={() => {
          deleteRow(selectedRow.id, selectedRow.checklistItemsId);
          setShowModal(null);
        }}
      />
      {showValidClientModal && (
        <Modal
          isOpen={showValidClientModal}
          title={`Client ID: ${clientInputValue} and Tier: ${
            dqTierData.find(item => item.value === selectedTier)?.label
          } already exist's`}
          primaryButtonTitle="Confirm"
          bodyContent={false}
          onClick={() => {
            dispatch(removeClientData({}));
            setClientInputValue('');
            setShowValidClientModal(false);
          }}
        />
      )}
      {pdfmodal && (
        <Modal
          isOpen
          title="Remove the document?"
          secondaryOnClick={() => setPdfmodal(false)}
          primaryButtonTitle="Remove"
          secondaryButtonTitle="Cancel"
          optionalClose
          bodyContent={false}
          onClick={() => {
            setPdfUrl(null);
            setUploadName(null);
            setPdfmodal(false);
          }}
        />
      )}
      {showCancelModal && (
        <Modal
          isOpen
          title="Cancel pending changes"
          secondaryOnClick={() => setShowCancelModal(false)}
          primaryButtonTitle="Yes"
          secondaryButtonTitle="No"
          optionalClose
          bodyContent={false}
          onClick={() => navigation(-1)}
        />
      )}
      {removeChecklistModal && (
        <Modal
          isOpen
          title="Delete Checklist"
          secondaryOnClick={() => setRemoveChecklistModal(false)}
          primaryButtonTitle="Yes"
          secondaryButtonTitle="No"
          optionalClose
          bodyContent={false}
          onClick={onDelete}
        />
      )}
      {successModal && (
        <Modal
          isOpen
          title="Success"
          primaryButtonTitle="OK"
          secondaryButtonTitle={null}
          bodyContent={false}
          onClick={() => navigation(-1)}
        />
      )}
      {showCLDDisabledAlert && (
        <Modal
          isOpen
          title="CLD Default Disabled"
          primaryButtonTitle="OK"
          secondaryButtonTitle={null}
          body="A CLD Default checklist already exists. To create a new CLD Default checklist, please edit or delete the existing
              one."
          onClick={() => setShowCLDDisabledAlert(false)}
        />
      )}
      <Footer />
    </>
  );
};
export default ChecklistDetails;
