import React, { useEffect, useMemo, useRef, useState } from "react";

import Select from 'react-select';
import { Col, Button, Form, Spinner, Row } from 'react-bootstrap';
import { Checkbox } from '@mui/material';
import "./styles.scoped.css";

const FormContent = ({
    confirmBranchNotification,
    errors,
    handleCancel,
    handleCheckbox,
    messageCharCount,
    messageCharLimit,
    location,
    customStyles,
    recipientStatuses,
    isLoadingRecipientStatus,
    form,
    isLoading,
    branches,
    lobs,
    isLoadingLOBS,
    setForm,
    headerTitle,
    isLoadingBranches,
    setField,
    setMessageCharCount,
    setSubjectCharCount,
    subjectCharCount,
    subjectCharLimit,
    convertBranchArrayToObject,
    convertLOBSArrayToObject,
    setShowDeleteModal,
    getLOBs,
    getBranches,
    hasBranchSelected,
    hasLOBSelected,
}) => {

    const driverStatusesOptions = useMemo(
        () =>
            recipientStatuses?.map(({ REF_CODE, REF_DESCRIPTION }) => ({
                label: REF_DESCRIPTION,
                value: REF_CODE,
                key: REF_CODE,
            })),
        [recipientStatuses],
    );

    const branchesOptions = useMemo(
        () =>
            branches?.map(({ BranchName, Branch }) => ({
                label: BranchName,
                value: Branch,
                key: Branch,
            })),
        [branches],
    );

    const lobsOptions = useMemo(
        () =>
            lobs?.map(({ LineOfBusinessCode, LineOfBusinessDesc }) => ({
                label: LineOfBusinessCode,
                value: LineOfBusinessDesc,
                key: LineOfBusinessCode,
            })),
        [lobs],
    );

    return (
        <div className="wrap">
            <Row>
                <Col>
                    <div className="window-header">
                        <span>{headerTitle}</span>
                    </div>
                    <div className="window-container">
                        <div style={{ marginTop: '30px' }}>
                            <Row className="mb-3 ml-2">
                                <Col className="col-2">
                                    <Form.Label>
                                        <div className="required">Branch</div>
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <div className="dropdown-spinner-container">
                                        <Select
                                            ref={hasBranchSelected}
                                            name={"branch"}
                                            styles={customStyles('15%')}
                                            options={branchesOptions}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            onChange={(selectedOptions, action) => {
                                                setForm({
                                                    ...form,
                                                    branch: action.action === 'clear' ? '' : selectedOptions.map(option => option.value).join(', '),
                                                    lob: action.action === 'clear' ? '' : form.lob,
                                                });
                                            }}
                                            onBlur={() => {
                                                if (form?.branch) {
                                                    getBranches(form?.branch);
                                                }
                                            }}
                                            isDisabled={isLoadingBranches}
                                            value={convertBranchArrayToObject(form?.branch)}
                                        />
                                        <div className="dropdown-spinner-container">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                aria-hidden="true"
                                                className="dropdown-spinner"
                                                hidden={!isLoadingBranches}
                                            />
                                        </div>
                                    </div>
                                    {errors?.branch && <span className="error">{errors?.branch}</span>}
                                </Col>
                                <>
                                    <Col className="col-2">
                                        <Form.Label>
                                            <div className="required">LOB</div>
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <div className="dropdown-spinner-container">
                                            <Select
                                                ref={hasLOBSelected}
                                                name={"lob"}
                                                styles={customStyles('15%')}
                                                options={lobsOptions}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                onChange={(selectedOptions, action) => {
                                                    setForm({
                                                        ...form,
                                                        lob: action.action === 'clear' ? '' : selectedOptions.map(option => option.value).join(', '),
                                                        branch: action.action === 'clear' ? '' : form.branch,
                                                    });
                                                }}
                                                onBlur={() => {
                                                    if (form?.lob) {
                                                        getLOBs(form?.lob);
                                                    }
                                                }}
                                                isDisabled={isLoadingLOBS}
                                                value={convertLOBSArrayToObject(form?.lob)}
                                            />
                                            <div className="dropdown-spinner-container">
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    aria-hidden="true"
                                                    className="dropdown-spinner"
                                                    hidden={!isLoadingLOBS}
                                                />
                                            </div>
                                        </div>
                                        {errors?.lob && <span className="error">{errors?.lob}</span>}
                                    </Col>
                                </>
                            </Row>
                            <Row className="mb-3 ml-2">
                                <Col className="col-2">
                                    <Form.Label>
                                        <span className="required">Subject</span>
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        key="subject"
                                        style={{
                                            width: "35%"
                                        }}
                                        type="input"
                                        maxLength={subjectCharLimit}
                                        name="subject"
                                        onChange={e => {
                                            setSubjectCharCount(e.target.value.length);
                                        }}
                                        onBlur={e => {
                                            setField('subject', e.target.value);
                                        }}
                                        defaultValue={form?.subject}
                                        isInvalid={!!errors?.subject || subjectCharCount >= subjectCharLimit}
                                    />
                                    <span style={{ fontSize: '14px' }}>{`${subjectCharCount}/${subjectCharLimit} characters remaining.`}</span>
                                    <Form.Control.Feedback type="invalid">{errors?.subject}</Form.Control.Feedback>
                                </Col>
                            </Row>
                        </div>

                        <Row className="mb-3 ml-2">
                            <Col className="col-2">
                                <Form.Label>
                                    <span className="required">Message</span>
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    style={{
                                        width: '50%',
                                        height: '75px',
                                    }}
                                    as="textarea"
                                    maxLength={messageCharLimit}
                                    name="message"
                                    onChange={e => {
                                        setMessageCharCount(e.target.value.length);
                                    }}
                                    onBlur={e => {
                                        setField([{
                                            fieldName: 'message',
                                            fieldValue: e.target.value
                                        }]);
                                    }}
                                    defaultValue={form?.message}
                                    isInvalid={!!errors?.message || messageCharCount >= messageCharLimit}
                                />
                                <span style={{ fontSize: '14px' }}>{`${messageCharCount}/${messageCharLimit} characters remaining.`}</span>
                                <Form.Control.Feedback type="invalid">{errors?.message}</Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row className="mb-3 ml-2">
                            <Col className="col-2">
                                <Form.Label>
                                    <div>URL</div>
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    key="subject"
                                    style={{
                                        width: '35%',
                                        height: '30px',
                                    }}
                                    type="input"
                                    name="url"
                                    onBlur={e => {
                                        setField('url', e.target.value);
                                    }}
                                    defaultValue={form?.url}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3 ml-2">
                            <Col className="col-2">
                                <Form.Label>
                                    <div className="required">Recipient Status</div>
                                </Form.Label>
                            </Col>
                            <Col>
                                <div className="dropdown-spinner-container">
                                    <Select
                                        styles={customStyles('100%')}
                                        options={driverStatusesOptions}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        onChange={driverStatusesOptions => {
                                            const selectedDriverStatuses = driverStatusesOptions.map(
                                                option => option.value
                                            );
                                            setForm({
                                                ...form,
                                                recipientStatus: selectedDriverStatuses.join(', '),
                                            });
                                        }}
                                        isDisabled={!isLoadingRecipientStatus}
                                        value={form?.recipientStatus?.split(', ').map(recipientStatus =>
                                            driverStatusesOptions?.find(option => option.value === recipientStatus))
                                        }
                                    />
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        aria-hidden="true"
                                        className="dropdown-spinner"
                                        hidden={isLoadingRecipientStatus}
                                    />
                                </div>
                                {errors?.recipientStatus && <span className="error">{errors?.recipientStatus}</span>}
                            </Col>
                        </Row>

                        <Row className="mb-3 ml-2">
                            <Col className="col-2"></Col>
                            <Col className="row align-items-center">
                                <Checkbox
                                    checked={form?.status === "Sent" ? true : false}
                                    style={{
                                        color: 'red',
                                        height: 0,
                                        marginLeft: '15px',
                                        marginRight: '15px',
                                        width: 0
                                    }}
                                    onChange={(e) => handleCheckbox(e)}
                                />
                                <span><strong>Approved to send </strong>(Checking this box and clicking Save will send the Notification.)</span>
                            </Col>
                        </Row>
                    </div>
                </Col >
            </Row>
            <Row className="justify-content-center mt-5">
                {location.pathname.includes('edit') &&

                    <Button
                        className="btn button-secondary mr-2"
                        style={{
                            height: '40px',
                            width: '100px',
                        }}
                        onClick={() => { setShowDeleteModal(true) }}
                    ><span>DELETE</span>
                    </Button>
                }
                {isLoading && (
                    <div style={{ margin: '4px' }}>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            aria-hidden="true"
                            className="spinner"
                            hidden={!isLoading}
                        />
                    </div>)}
                <Button
                    className="btn"
                    onClick={() => { confirmBranchNotification() }}
                >Save
                </Button>
                <Button
                    className="button-secondary ml-2"
                    onClick={() => { handleCancel() }}
                >Cancel
                </Button>
            </Row>
        </div >
    )
}

export default FormContent;