import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { Row, Form, Button, Spinner } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import { getExportCSVData, getCSVData } from './csv';
import DeductionRegisterTable from './DeductionRegisterTable';
import ExcelBlack from '../../../assets/excel-black.png';
import Excel from '../../../assets/excel.png';
import { Breadcrumbs, EdgeReportingWindow, SubHeader, ReportLoader } from '../../../components';
import { formatIsoToDate, newDateFormatted } from '../../../utils';
import { userHasAccess, generateReport, getReportData, removeReportData, getList } from '../operations';
import { customStyles, formatSocial } from '../utils';

import './styles.scoped.css';

const DeductionRegisterForm = ({
  setForm,
  form,
  isLoadingDeductions,
  deductionSortOptions,
  isLoadingBranches,
  branchOptions,
  handleViewResults,
  navigate,
}) => {
  const handleChange = e => {
    const _form = { ...form, [e.target.name]: e.target.value };
    setForm(_form);
  };
  return (
    <Form
      noValidate
      onSubmit={() => { }}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div className="filters-row">
        <div
          className="filter-row"
          style={{
            marginTop: '30px',
          }}>
          <Form.Group as={Row} controlId="FromPerEnddate" style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px' }}>Period End Date</Form.Label>
            <Form.Control
              style={{
                width: '150px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              type="date"
              name="FromPerEnddate"
              defaultValue={form?.FromPerEnddate}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Row} controlId="ToPerEnddate" style={{ alignItems: 'center' }}>
            <Form.Label
              style={{
                width: '20px',
                marginLeft: '30px',
                marginRight: '20px',
              }}>
              To
            </Form.Label>
            <Form.Control
              style={{
                width: '150px',
                height: '30px',
              }}
              as="input"
              type="date"
              name="ToPerEnddate"
              defaultValue={form?.ToPerEnddate}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid" />
          </Form.Group>
        </div>
        <div className="filter-row">
          <Form.Group as={Row} controlId="FromEmplLastName" style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px' }}>Driver Last Name</Form.Label>
            <Form.Control
              style={{
                width: '360px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              name="FromEmplLastName"
              defaultValue={form?.FromEmplLastName}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Row} controlId="ToEmplLastName" style={{ alignItems: 'center' }}>
            <Form.Label
              style={{
                width: '20px',
                marginLeft: '30px',
                marginRight: '20px',
              }}>
              To
            </Form.Label>
            <Form.Control
              style={{
                width: '360px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              name="ToEmplLastName"
              defaultValue={form?.ToEmplLastName}
              onChange={handleChange}
            />
          </Form.Group>
        </div>

        <div className="filter-row">
          <Form.Group as={Row} controlId="FromEmplFirstName" style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px' }}>Driver First Name</Form.Label>
            <Form.Control
              style={{
                width: '360px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              name="FromEmplFirstName"
              defaultValue={form?.FromEmplFirstName}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Row} controlId="ToEmplFirstName" style={{ alignItems: 'center' }}>
            <Form.Label
              style={{
                width: '20px',
                marginLeft: '30px',
                marginRight: '20px',
              }}>
              To
            </Form.Label>
            <Form.Control
              style={{
                width: '360px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              name="ToEmplFirstName"
              defaultValue={form?.ToEmplFirstName}
              onChange={handleChange}
            />
          </Form.Group>
        </div>

        <div className="filter-row">
          <Form.Group as={Row} style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px' }}>Driver Number</Form.Label>
            <Form.Control
              style={{
                width: '360px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              name="FromEmplID"
              value={form?.FromEmplID}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Row} controlId="ToEmplID" style={{ alignItems: 'center' }}>
            <Form.Label
              style={{
                width: '20px',
                marginLeft: '30px',
                marginRight: '20px',
              }}>
              To
            </Form.Label>
            <Form.Control
              style={{
                width: '360px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              name="ToEmplID"
              defaultValue={form?.ToEmplID}
              onChange={handleChange}
            />
          </Form.Group>
        </div>

        <div className="filter-row">
          <Form.Group as={Row} controlId="SSNO" style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px' }}>SSN</Form.Label>
            <Form.Control
              style={{
                width: '150px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              name="SSNO"
              value={formatSocial(form?.SSNO || '')}
              onChange={handleChange}
            />
          </Form.Group>
        </div>
        <div className="filter-row">
          <Form.Group as={Row} controlId="deductionSort" style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px' }}>Deduction Code</Form.Label>
            <Select
              isDisabled={isLoadingDeductions}
              isMulti
              closeMenuOnSelect={false}
              styles={customStyles('365px')}
              options={deductionSortOptions}
              defaultValue={form?.DedCode?.split(',').map(code =>
                deductionSortOptions.find(option => option.value === code),
              )}
              onChange={selectedOptions => {
                const selectedCodes = selectedOptions.map(option => option.value);
                setForm({
                  ...form,
                  DedCode: selectedCodes.join(','),
                });
              }}
            />
            <Spinner
              as="span"
              animation="border"
              size="sm"
              aria-hidden="true"
              className="dropdown-spinner"
              hidden={!isLoadingDeductions}
            />
          </Form.Group>
        </div>
        <div className="filter-row">
          <Form.Group as={Row} controlId="FromBranch" style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px' }}>Branch</Form.Label>
            <div className="dropdown-spinner-container">
              <Select
                styles={customStyles('150px')}
                isDisabled={isLoadingBranches}
                options={branchOptions}
                isClearable
                onChange={(event, action) => {
                  if (action.action === 'clear') {
                    setForm({
                      ...form,
                      FromBranch: '',
                    });
                  }
                  if (action.action === 'select-option') {
                    setForm({
                      ...form,
                      FromBranch: event.value,
                    });
                  }
                }}
                defaultValue={branchOptions?.filter(option => option.value === form?.FromBranch)}
              />
              <Spinner
                as="span"
                animation="border"
                size="sm"
                aria-hidden="true"
                className="dropdown-spinner"
                hidden={!isLoadingBranches}
              />
            </div>
          </Form.Group>
          <Form.Group as={Row} controlId="ToBranch" style={{ alignItems: 'center' }}>
            <Form.Label
              style={{
                width: '20px',
                marginLeft: '55px',
                marginRight: '20px',
              }}>
              To
            </Form.Label>
            <div className="dropdown-spinner-container">
              <Select
                styles={customStyles('150px')}
                isDisabled={isLoadingBranches}
                options={branchOptions}
                isClearable
                onChange={(event, action) => {
                  if (action.action === 'clear') {
                    setForm({ ...form, ToBranch: '' });
                  }
                  if (action.action === 'select-option') {
                    setForm({
                      ...form,
                      ToBranch: event.value,
                    });
                  }
                }}
                defaultValue={branchOptions?.filter(option => option.value === form?.ToBranch)}
              />
              <Spinner
                as="span"
                animation="border"
                size="sm"
                aria-hidden="true"
                className="dropdown-spinner"
                hidden={!isLoadingBranches}
              />
            </div>
          </Form.Group>
        </div>
      </div>
      <div className="buttons-row">
        <Button onClick={() => handleViewResults()} style={{ marginRight: '20px', width: '100px' }}>
          <span>VIEW</span>
        </Button>

        <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
          <span>CANCEL</span>
        </Button>
      </div>
    </Form>
  );
};

const DeductionRegister = () => {
  const navigate = useNavigate();
  const {
    clientMenu: { menuItems },
  } = useSelector(state => state.edgeReporting);
  const { user } = useSelector(state => state.admin);
  const [data, setData] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const [branches, setBranches] = useState([]);
  const [form, setForm] = useState({});
  const [loadingResults, setLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isLoadingDeductions, setIsLoadingDeductions] = useState(false);
  const [isLoadingBranches, setIsLoadingBranches] = useState(false);

  const viewFullPii = useMemo(() => user?.appAccess?.items?.find(item => item.appId.id === '4')?.roleId?.viewFullPii, [
    user,
  ]);

  useEffect(() => {
    if (menuItems) {
      // eslint-disable-next-line no-undef
      if (!userHasAccess(user, menuItems.find(i => i.to === window.location.pathname)?.id)) navigate('/');
    }
  }, [menuItems]);

  const handleGetDeductions = async () => {
    setIsLoadingDeductions(true);
    const newDeductions = await getList('deduction-codes');
    setDeductions(newDeductions);
    setIsLoadingDeductions(false);
  };
  const handleGetBranches = async () => {
    setIsLoadingBranches(true);
    const newBranches = await getList('clients/branches');
    setBranches(newBranches);
    setIsLoadingBranches(false);
  };

  useEffect(() => {
    handleGetDeductions();
    handleGetBranches();
  }, []);

  const deductionSortOptions = deductions?.map(({ Code, Description }) => ({
    label: Description,
    value: Code,
  }));

  const branchOptions = branches?.map(({ Branch_Code, Branch_Name }) => ({
    label: `${Branch_Name} - ${Branch_Code}`,
    value: Branch_Code,
  }));

  const fileName = `edge-reporting-deduction-register-${newDateFormatted.toString()}.csv`;
  const exportFileName = `edge-reporting-deduction-register-export-${newDateFormatted.toString()}.csv`;

  const handleViewResults = async () => {
    if (
      !(
        dayjs(form?.ToPerEnddate).isAfter(form?.FromPerEnddate) ||
        dayjs(form?.FromPerEnddate).isSame(form?.ToPerEnddate)
      )
    ) {
      // eslint-disable-next-line no-undef
      alert('Invalid date range. Please double check and try again.');
      return;
    }
    setLoadingResults(true);
    Promise.all([
      generateReport({
        endpoint: 'deduction-register-report',
        payload: {
          FromPerEnddate: form?.FromPerEnddate ? formatIsoToDate(form?.FromPerEnddate, 'YYYY-MM-DD') : '',
          ToPerEnddate: form?.ToPerEnddate ? formatIsoToDate(form?.ToPerEnddate, 'YYYY-MM-DD') : '',
          FromEmplLastName: form?.FromEmplLastName ?? '',
          ToEmplLastName: form?.FromEmplLastName ?? '',
          FromEmplFirstName: form?.FromEmplFirstName ?? '',
          ToEmplFirstName: form?.ToEmplFirstName ?? '',
          FromEmplID: form?.FromEmplID ? parseInt(form?.FromEmplID) : '',
          ToEmplID: form?.ToEmplID ? parseInt(form?.ToEmplID) : '',
          SSNO: form?.SSNO ?? '',
          DedCode: form?.DedCode ?? '',
          FromBranch: form?.FromBranch?.toString() ?? '',
          ToBranch: form?.ToBranch?.toString() ?? '',
        },
      }),
      generateReport({
        endpoint: 'deduction-register-export',
        payload: {
          FromPerEnddate: form?.FromPerEnddate ? formatIsoToDate(form?.FromPerEnddate, 'YYYY-MM-DD') : '',
          ToPerEnddate: form?.ToPerEnddate ? formatIsoToDate(form?.ToPerEnddate, 'YYYY-MM-DD') : '',
          FromEmplLastName: form?.FromEmplLastName ?? '',
          ToEmplLastName: form?.FromEmplLastName ?? '',
          FromEmplFirstName: form?.FromEmplFirstName ?? '',
          ToEmplFirstName: form?.ToEmplFirstName ?? '',
          FromEmplID: form?.FromEmplID ? parseInt(form?.FromEmplID) : '',
          ToEmplID: form?.ToEmplID ? parseInt(form?.ToEmplID) : '',
          SSNO: form?.SSNO ?? '',
          DedCode: form?.DedCode ?? '',
          FromBranch: form?.FromBranch?.toString() ?? '',
          ToBranch: form?.ToBranch?.toString() ?? '',
          viewFullPii,
        },
      }),
    ])
      .then(values => {
        values.forEach((response, index) => {
          if (response.location) {
            getReportData(response.location).then(async reportData => {
              if (reportData?.length) {
                await removeReportData(response.location);
                if (index === 0) {
                  setData(reportData);
                } else {
                  setDataExport(reportData);
                }
                setShowResults(true);
              }
            });
          } else if (response.statusCode !== 404) {
            if (index === 0) {
              setData(response);
            } else {
              setDataExport(response);
            }
            setShowResults(true);
          } else {
            // eslint-disable-next-line no-undef
            alert('No data found matching your criteria.');
          }
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoadingResults(false);
      });
  };

  return (
    <>
      <SubHeader
        text="EDGE Reporting"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Deduction Register report?"
        emailAddress={process.env.REACT_APP_EDGE_REPORTING_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle="Reporting Home"
          middleTitle={showResults && 'Deduction Register'}
          midItemAction={() => setShowResults(false)}
          rightTitle={showResults ? 'Viewing Report' : 'Deduction Register'}
          route="/edge-reporting"
        />
        {!!data?.length && showResults && (
          <div className="excel-container">
            {!!dataExport?.length && (
              <div>
                <CSVLink filename={exportFileName} data={getExportCSVData(dataExport)}>
                  <img
                    src={ExcelBlack}
                    alt="export-to-excel"
                    style={{ padding: '20px 10px 0 0 ', cursor: 'pointer' }}
                  />
                </CSVLink>
                <p style={{ fontSize: 12 }}>Garn.</p>
              </div>
            )}
            <CSVLink filename={fileName} data={getCSVData(data)}>
              <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
            </CSVLink>
          </div>
        )}
      </div>
      {loadingResults && <ReportLoader didAbort={() => setLoadingResults(false)} />}
      {showResults ? (
        <>
          <DeductionRegisterTable data={data} loading={loadingResults} />
          <div className="buttons-row">
            <Button onClick={() => setShowResults(false)} style={{ marginRight: '20px', width: '100px' }}>
              <span>BACK</span>
            </Button>

            <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
              <span>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <EdgeReportingWindow
          containerStyle={{
            border: '2px solid #343a40',
          }}
          windowContent={DeductionRegisterForm({
            form,
            setForm,
            isLoadingDeductions,
            deductionSortOptions,
            isLoadingBranches,
            branchOptions,
            handleViewResults,
            navigate,
          })}
          windowTitle="Criteria"
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )}
    </>
  );
};

export default DeductionRegister;
