import React, { useEffect, useMemo, useState } from 'react';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { Radio } from '@mui/material';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { money } from '../../../utils';
import './styles.scoped.css';
import { TablePagination } from '../../../components';

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const columnClassName = 'align-middle text-nowrap';
export const checkColumnsSingle = [
  {
    Header: 'Driver Number',
    accessor: 'Employee_ID',
    className: columnClassName,
  },
  {
    Header: 'Driver Name',
    accessor: 'Employee_Name',
    className: columnClassName,
  },
  {
    Header: 'Check #',
    accessor: 'Check_ID',
    className: columnClassName,
  },
  {
    Header: 'Check Date',
    accessor: 'Check_Date',
    className: columnClassName,
  },
  {
    Header: 'Gross Pay',
    accessor: 'Gross_Wages',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Net Pay',
    accessor: 'Net_Wages',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Period End Date',
    accessor: 'Period_Ending_Date',
    className: columnClassName,
  },
  {
    Header: 'VOID',
    accessor: 'Void',
    className: columnClassName,
    Cell: instance => (instance.value ? 'Yes' : 'No'),
  },
];
export const checkColumns = [
  {
    Header: 'Driver Number',
    accessor: 'Employee_ID',
    className: columnClassName,
  },
  {
    Header: 'Driver Name',
    accessor: 'Employee_Name',
    className: columnClassName,
  },
  {
    Header: 'Check #',
    accessor: 'Check_ID',
    className: columnClassName,
  },
  {
    Header: 'Check Date',
    accessor: 'Check_Date',
    className: columnClassName,
  },
  {
    Header: 'Gross Pay',
    accessor: 'Gross_Wages',
    className: columnClassName,
  },
  {
    Header: 'Net Pay',
    accessor: 'Net_Wages',
    className: columnClassName,
  },
  {
    Header: 'Period End Date',
    accessor: 'Period_Ending_Date',
    className: columnClassName,
  },
  {
    Header: 'VOID',
    accessor: 'Void',
    className: columnClassName,
    Cell: instance => (instance.value ? 'Yes' : 'No'),
  },
  {
    Header: 'Customer Number',
    accessor: 'Client_Id',
    className: columnClassName,
  },
  {
    Header: 'Customer Name',
    accessor: 'Client_Name',
    className: columnClassName,
  },
  {
    Header: 'Job',
    accessor: 'Job_id',
    className: columnClassName,
  },
  {
    Header: 'Type',
    accessor: 'Pay_Code',
    className: columnClassName,
  },
  {
    Header: 'Description',
    accessor: 'Pay_Code_Description',
    className: columnClassName,
  },
  {
    Header: 'Hours',
    accessor: 'Pay_Hours',
    className: columnClassName,
  },
  {
    Header: 'Rate',
    accessor: 'Pay_Rate',
    className: columnClassName,
  },
  {
    Header: 'Amount',
    accessor: 'Pay_Amount',
    className: columnClassName,
  },
];

const CheckTable = ({ data, loading, rowSelected, isSingle }) => {
  const memoData = useMemo(() => data, [data]);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    rowSelected(data[selectedRow]);
  }, [selectedRow, rowSelected, data]);

  const checkColumnsToUse = useMemo(
    () =>
      isSingle
        ? [
            {
              id: 'select',
              Cell: ({ row }) => (
                <div className="px-1">
                  <Radio
                    style={{
                      color: '#da0f2e',
                      transform: 'scale(1)',
                    }}
                    onChange={() => setSelectedRow(row.id)}
                    checked={selectedRow === row.id}
                  />
                </div>
              ),
            },
            ...checkColumnsSingle,
          ]
        : checkColumns,
    [selectedRow, isSingle],
  );

  const tableInstance = useTable(
    {
      columns: checkColumnsToUse,
      data: memoData,
      initialState: {
        pageSize: 200,
        sortBy: [
          {
            id: 'CreatedAt',
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>
          {column.canSort
            ? getSortedIcon(column.isSorted, column.isSortedDesc)
            : null}
        </span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}
      >
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading && <Spinner animation="border" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CheckTable;
