import React, { useEffect, useState } from 'react';

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  getCategories,
  getSources,
  getTypes,
} from '../../features/DriverQualification/Management/ChecklistDetails/operations';
import InputBox from '../InputBox';
import SelectorList from '../SelectorList';
import './styles.scoped.css';

const ChecklistRow = ({
  id,
  row,
  onTrash,
  onItemChange,
  onSourceChange,
  onCategoryChange,
  onTypeChange,
  onOrderChange,
  onPagesChange,
  documentData,
  highlight = false,
}) => {
  const { rows } = useSelector(state => state.checkList);
  const location = useLocation();

  const [orderOptions, setOrderOptions] = useState([]);
  const [documentSources, setDocumentSources] = useState([]);
  const [documentCategories, setDocumentCategories] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);

  const getAndSetSources = async () => {
    const sources = await getSources();
    if (sources) {
      setDocumentSources(sources);
    }
    if (id === 0) {
      const categories = await getCategories('3');
      const types = await getTypes('0');

      if (categories) {
        setDocumentCategories(categories);
      }
      if (types) {
        setDocumentTypes(types);
      }
    }
  };

  useEffect(() => {
    getAndSetSources();
  }, [location]);

  const handleSourceChanged = async () => {
    const categories = await getCategories(row.source);
    if (categories) {
      setDocumentCategories(categories);
    }
  };

  useEffect(() => {
    if (row.source) {
      handleSourceChanged();
    }
  }, [row.source]);

  const handleCategoryChanged = async () => {
    const types = await getTypes(row.category);
    if (types) {
      setDocumentTypes(types);
    }
  };

  useEffect(() => {
    if (row.category) {
      handleCategoryChanged();
    }
  }, [row.category]);

  useEffect(() => {
    if (rows) {
      let options = [];
      rows.forEach((_, i) => options.push({ label: `${i + 1}`, value: `${i + 1}` }));
      options = options.filter(option => option.value != 1);
      setOrderOptions(options);
    }
  }, [rows]);

  return (
    <>
      {id === 0 ? (
        <Row className={`row ${highlight ? 'highlight' : ''}`}>
          <span style={{ width: '20%' }} />
          <span style={{ width: '15%' }}>{documentSources?.find(item => item.id === row.source)?.name}</span>
          <span style={{ width: '15%' }}>{documentCategories?.find(item => item.id === row.category)?.name}</span>
          <span style={{ width: '20%' }}>{documentTypes?.find(item => item.id === row.type)?.Name}</span>
          <span style={{ width: '15%' }}>{row?.pages}</span>
          <span style={{ width: '10%' }}>{row.order}</span>
        </Row>
      ) : (
        <Row className={`row ${highlight ? 'highlight' : ''}`}>
          <span style={{ width: '20%' }}>
            <InputBox value={row.item} onChange={onItemChange} placeholder="Item" />
          </span>
          <span style={{ width: '15%' }}>
            <SelectorList
              value={documentSources?.find(item => item.id === row.source)?.name || 'Select Source'}
              data={documentSources?.reduce((result, source) => {
                if (source.id !== '3' && id !== 0) {
                  result.push({ label: source.name, value: source.id });
                }
                return result;
              }, [])}
              onChange={onSourceChange}
            />
          </span>
          <span style={{ width: '15%' }}>
            <SelectorList
              value={documentCategories?.find(item => item.id === row.category)?.name || 'Select Category'}
              data={documentCategories?.map(category => ({ label: category.name, value: category.id }))}
              onChange={onCategoryChange}
            />
          </span>
          <span style={{ width: '20%' }}>
            <SelectorList
              value={documentTypes?.find(item => item.id === row.type)?.Name || 'Select Type'}
              data={documentTypes?.reduce((result, type) => {
                documentData.types.length = result.length;
                if (
                  !(
                    documentData?.sources?.includes(row?.source) &&
                    documentData?.categories?.includes(row?.category) &&
                    documentData.types?.includes(type.id)
                  )
                ) {
                  result.push({
                    label: type.Name,
                    value: type.id,
                  });
                }
                return result;
              }, [])}
              onChange={onTypeChange}
            />
          </span>
          <span style={{ width: '15%' }}>
            <InputBox
              style={{ marginRight: 10 }}
              colStyle={{ paddingLeft: 0, paddingRight: 0 }}
              value={row.pages}
              onChange={onPagesChange}
              placeholder="e.g. All,1-5,8,11-13"
              errorMessage="check input"
              error={
                row.pages !== '' &&
                (!/^(\s*\d+\s*(-\s*\d+\s*)?)(,\s*\d+\s*(-\s*\d+\s*)?)*$/.test(row.pages) &&
                  row.pages.toLowerCase() !== 'all')
              }
            />
          </span>
          <span style={{ width: '10%' }}>
            <SelectorList value={row.order || 'Select'} data={orderOptions} onChange={onOrderChange} />
          </span>
          <div style={{ flex: 1, textAlign: 'center', marginTop: 5 }}>
            <FontAwesomeIcon icon={faTrash} color="#da0f2e" size="lg" onClick={onTrash} />
          </div>
        </Row>
      )}
    </>
  );
};

export default ChecklistRow;
