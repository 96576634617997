import { createSlice } from '@reduxjs/toolkit';

const pushNotificationsSlice = createSlice({
  name: 'pushNotifications',
  initialState: {
    menuData: [
      {
        roleId: '27',
        items: [
          { title: 'National Push Notifications', to: '/push-notifications/national-push-notifications' },
          { title: 'LOB Push Notifications', to: '/push-notifications/lob-push-notifications' },
          { title: 'Branch Push Notifications', to: '/push-notifications/branch-push-notifications' }
        ]
      },
      {
        roleId: '28',
        items: [
          { title: 'LOB Push Notifications', to: '/push-notifications/lob-push-notifications' },
          { title: 'Branch Push Notifications', to: '/push-notifications/branch-push-notifications' }
        ]
      },
      {
        roleId: '29',
        items: [
          { title: 'Branch Push Notifications', to: '/push-notifications/branch-push-notifications' }
        ]
      }
    ]
    ,
    statuses: [
      {
        label: 'Pending',
        value: 'Pending',
      },
      {
        label: 'Sent',
        value: 'Sent'
      }
    ]
  },
  reducers: {},
});

export default pushNotificationsSlice.reducer;

export const { } = pushNotificationsSlice.actions;
