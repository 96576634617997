// import { setLoading, setCurrentQuote } from './driversSlice';
import { Auth } from 'aws-amplify';
import quoteApi from '../../../utils/quoteAPi';

export const saveQuote = async (dispatch, quoteName, quoteId) => {
  const body = {
    request: 'SaveQuote',
    custId: 'UPS',
    quoteId,
    quoteName,
  };
  const success = await quoteApi
    .post('', body, {
      headers: {
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
    })
    .then(resp => {
      const { statusCode, body } = resp.data;
      if (statusCode === 200 && body === 'Success') {
        return true;
      }
    });
  if (success) {
    return true;
  }
};
