import React from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import './styles.scoped.css';

const customStyles = width => ({
  control: provided => ({
    ...provided,
    width: width,
    height: '30px',
    minWidth: '175px',
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: '200px',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#da0f2e',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#ffffff',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: '#ffffff',
  }),
});

const PushNotificationsHeader = ({
  id,
  branches,
  isLoadingLOBs,
  isLoadingBranches,
  form,
  setNotifications,
  handleSearch,
  lobs,
  route,
  setForm,
  statuses,
  title,
  convertArrayToObject,
}) => {

  return (
    <Container>
      <Col>
        <Row className="align-middle justify-content-between">
          <Breadcrumbs
            style={{ marginLeft: 0 }}
            midItemAction={() => navigate(`../push-notifications/national-push-notifications/${id}`, { replace: true })}
            middleTitle={id && `File ${id}\u00A0`}
            leftTitle="PN Home"
            rightTitle={title}
            route="/push-notifications"
          />
          <Link to={route}>
            <Button
              style={{
                height: '40px',
                width: '100px'
              }}
            >
              <span>ADD</span>
            </Button>
          </Link>
        </Row>
        <Row className="d-flex justify-content-between mt-3 mb-2">
          <span className="text">Select your Criteria:</span>
          <div>
            <Button
              className="btn button-secondary"
              style={{
                height: '40px',
                width: '100px',
              }}
              onClick={() => handleSearch()}
            ><span>SEARCH</span>
            </Button>

            <Button
              variant="secondary"
              className='btn button-ternary'
              style={{
                borderColor: '#6c757d',
                height: '42px',
                marginLeft: '20px',
                padding: '8px',
                width: '100px'
              }}
              onClick={() => {
                setForm({
                  ...form,
                  lob: '',
                  branch: '',
                  status: '',
                  createdAtStartDate: '',
                  createdAtEndDate: '',
                  createdAt: '',
                });
                setNotifications([]);
              }}
            >
              <span>RESET</span>
            </Button>
          </div>
        </Row>
      </Col>
      <Col>

        <Row>
          <Form.Label
            style={{
              width: '275px',
            }}>
            Notification Created Date Range
          </Form.Label>
          <Form.Group as={Row} controlId="createdAtStartDate">
            <div style={{ flexDirection: 'column' }}>
              <Form.Control
                style={{
                  width: '210px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                type="date"
                name="createdAtStartDate"
                value={form?.createdAtStartDate}
                onChange={event => {
                  setForm({
                    ...form,
                    'createdAtStartDate': event.target.value
                  });
                }}
              />
            </div>
          </Form.Group>
          <Form.Label
            style={{
              width: '20px',
              marginLeft: '20px',
              marginRight: '30px',
            }}>
            To
          </Form.Label>
          <Form.Group as={Row} controlId="createdAtEndDate">
            <div style={{ flexDirection: 'column' }}>
              <Form.Control
                style={{
                  width: '210px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                type="date"
                name="createdAtEndDate"
                value={form?.createdAtEndDate}
                onChange={event => {
                  setForm({
                    ...form,
                    'createdAtEndDate': event.target.value
                  });
                }}
              />
            </div>
          </Form.Group>
          <Form.Group as={Row} controlId="status">
            <Form.Label style={{
              width: '24px',
              marginLeft: '35px',
              marginRight: '45px',
              marginTop: '15px',
            }}>Status</Form.Label>
            <div className="dropdown-spinner-container">
              <Select
                styles={customStyles('175px')}
                options={statuses}
                isClearable
                onChange={(event, action) => {
                  if (action.action === 'clear') {
                    setForm(
                      {
                        ...form,
                        'status': '',
                      });
                  }
                  if (action.action === 'select-option') {
                    setForm(
                      {
                        ...form,
                        'status': event.value,
                      });
                  }
                }}
                value={statuses?.filter(state => state.value === form?.status)}
              />
            </div>
          </Form.Group>
          {branches &&
            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '55px', marginRight: '15px', marginLeft: '55px', marginBottom: 0 }}>
                Branch
              </Form.Label>
              <Select
                isDisabled={isLoadingBranches}
                isMulti
                closeMenuOnSelect={false}
                styles={customStyles('100%')}
                options={branches}
                value={convertArrayToObject(form?.branch) || ''}
                onChange={selectedOptions => {
                  const selectedBranches = selectedOptions.map(
                    option => option.value,
                  );
                  setForm({
                    ...form,
                    branch: selectedBranches.join(', '),
                  });
                }}
              />
              <Spinner
                as="span"
                animation="border"
                size="sm"
                aria-hidden="true"
                className="dropdown-spinner"
                hidden={!isLoadingBranches}
              />
            </Form.Group>
          }
          {lobs &&
            <Form.Group as={Row} controlId="lob">
              <Form.Label style={{
                width: '24px',
                marginLeft: '45px',
                marginRight: '25px',
                marginTop: '8px',
              }}>LOB</Form.Label>
              <Select
                styles={customStyles('100%')}
                options={lobs}
                isMulti
                closeMenuOnSelect={false}
                onChange={lobs => {
                  const selectedLobs = lobs.map(
                    option => option.value
                  );
                  setForm({
                    ...form,
                    lob: selectedLobs.join(', '),
                  });
                }}
                isDisabled={isLoadingLOBs}
                value={form?.lob?.split(', ').map(lob =>
                  lobs.find(option => option.value === lob) || '')
                }
              />
              <div className="dropdown-spinner-container">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingLOBs}
                />
              </div>
            </Form.Group>
          }
        </Row>
      </Col >
    </Container >
  )
}

export default PushNotificationsHeader;
