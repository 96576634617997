import { API } from 'aws-amplify';
import { listApplications } from '../../graphql/customQueries';

export const getApps = async (id) => {
    if (!id) return [];
    let apps = [];
    let token;
    const getPage = async () => {
        try {
            const response = await API.graphql({
                query: listApplications,
                variables: { id, limit: 2000, nextToken: token },
            });
            return { items: response?.data?.listUserApplicationAccesses?.items, nextToken: response?.data?.listUserApplicationAccesses?.nextToken };
        } catch (e) {
            console.log('listApplications: ' + e.errors[0].message);
        }
    }
    do {
        const result = await getPage();
        const { items, nextToken } = result;
        token = nextToken;
        apps = [...apps, ...items];
    } while (token);

    return apps;
};