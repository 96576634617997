import React from 'react';

import { Col } from 'react-bootstrap';

import QuoteField from '../GenerateQuote/quoteField';

export const QuoteFieldCol = ({ label, value }) => (
  <Col className="col-auto">
    <QuoteField label={label} value={value} />
  </Col>
);

export default QuoteFieldCol;
