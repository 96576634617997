import React from 'react';

import { Col, Form, Spinner } from 'react-bootstrap';

const InputBox = ({
  colStyle,
  title,
  error,
  errorMessage,
  style,
  span = false,
  value,
  disabled,
  loading = false,
  type = 'text',
  ...rest
}) => (
  <div style={{ ...style }}>
    <Col style={{ ...colStyle }}>
      {!!title && (
        <Form.Label>
          {title} {span && <span style={{ color: '#da0f2e' }}>*</span>}
        </Form.Label>
      )}
      <div style={{ height: 45 }}>
        {loading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <Form.Control value={value} disabled={disabled} {...rest} style={{ borderColor: 'black' }} type={type} />
        )}
      </div>
      {error && <div style={{ color: 'red', fontSize: 12 }}>{errorMessage}</div>}
    </Col>
  </div>
);

export default InputBox;
