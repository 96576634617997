export const listHasDuplicates = array => new Set(array).size !== array.length;

export const getListDuplicates = (array, comparationValue) => {
  const newArr = [];
  let index = 0;
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < array.length; j++) {
      if (comparationValue) {
        if (array[i][comparationValue] === array[j][comparationValue] && i !== j) {
          newArr[index] = array[i];
          index++;
        }
      } else if (array[i] === array[j] && i !== j) {
        newArr[index] = array[i];
        index++;
      }
    }
  }
  return newArr;
};
