import React, { useEffect } from 'react';
import { Form, Button, Container, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './styles.scoped.css';
import Tabs from '../../../../components/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTab } from '../../quoteEngineSlice';
import { Footer, SubHeader } from '../../../../components';
import { Home } from '@mui/icons-material';
import { setForm, setErrors } from './clericalSlice';
import { getStateRegion, getStates } from '../locationList';
import GeneratedClericalQuote from './generatedClericalQuote';
import { generateClericalData } from './operations';

const GenerateClerical = () => {
  const dispatch = useDispatch();
  const { currentTab, subHeaderText } = useSelector(state => state.quoteEngine);
  const { form, errors, currentQuote, loading } = useSelector(
    state => state.quoteEngineClerical,
  );
  useEffect(() => {
    dispatch(setCurrentTab('clerical'));
  }, []);

  const stateOptions = getStates().map(({ label, value }) => (
    <option key={label} value={value}>
      {label}
    </option>
  ));
  const setField = (field, value) => {
    dispatch(
      setForm({
        ...form,
        [field]: value,
      }),
    );

    if (!!errors[field]) {
      dispatch(
        setErrors({
          ...errors,
          [field]: null,
        }),
      );
    }
  };
  const validateForm = () => {
    const { location, region, manager, admin, supervisor, startDate } = form;

    const newErrors = {};

    const invalidValueText = 'Please provide a valid value.';

    if (!location) newErrors.location = 'Please select a state.';
    if (location && getStateRegion(form?.location).length && !region) {
      newErrors.region = 'Please select a region.';
    }
    if (!startDate) newErrors.startDate = invalidValueText;
    if (manager === '') newErrors.manger = invalidValueText;
    if (admin === '') newErrors.admin = invalidValueText;
    if (supervisor === '') newErrors.supervisor = invalidValueText;
    if (manager + supervisor + admin < 1) {
      newErrors.totalWorkers = 'At least one worker is required';
    }
    return newErrors;
  };

  const GeneratedQuote = () => (
    <>
      <hr className="ml-n3 mr-n3 mt-3 mb-3 border border-dark" />
      <GeneratedClericalQuote className="p-0" quote={currentQuote} showSave />
    </>
  );

  const handleSubmit = async e => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      dispatch(setErrors(formErrors));
    } else {
      await generateClericalData(dispatch, form);
    }
  };
  const navigate = useNavigate();

  const renderClericalContent = () => (
    <div className="content-container">
      <Form noValidate onSubmit={() => { }}>
        <Form.Group controlId="formLocations">
          <h1 className="title">Generate Clerical Quote</h1>
          <Form.Label style={{ marginTop: '15px' }}>
            Location (State)
          </Form.Label>
          <Form.Control
            className="col-sm-5 p-0"
            as="select"
            name="location"
            value={form?.location}
            onChange={event => {
              setField('location', event.target.value);
            }}
            onBlur={null}
            isInvalid={!!errors?.location}
          >
            <option value="" disabled>
              Select a location
            </option>

            {stateOptions}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors?.location}
          </Form.Control.Feedback>
        </Form.Group>
        {getStateRegion(form?.location).length ? (
          <Form.Group controlId="formLocationArea" className="col-md-5 p-0">
            <Form.Label>Specific Area</Form.Label>
            <Form.Control
              as="select"
              name="region"
              value={form?.region}
              onChange={event => {
                setField('region', event.target.value);
              }}
              isInvalid={!!errors?.region}
            >
              <option value="" disabled>
                Select an area
              </option>
              {getStateRegion(form?.location).map(({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors?.region}
            </Form.Control.Feedback>
          </Form.Group>
        ) : null}
        <Form.Group controlId="formManager" className="col-sm-5 p-0">
          <Form.Label>Manager</Form.Label>
          <Form.Control
            name="manager"
            value={form?.manager}
            onChange={event => {
              setField('manager', event.target.value);
            }}
            isInvalid={!!errors?.manager}
            type="number"
            pattern="[0-9]*"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.manager}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formAdmin" className="col-sm-5 p-0">
          <Form.Label>Admin</Form.Label>
          <Form.Control
            name="admin"
            value={form?.admin}
            onChange={event => {
              setField('admin', event.target.value);
            }}
            isInvalid={!!errors?.admin}
            type="number"
            pattern="[0-9]*"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.admin}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formSupervisor" className="col-sm-5 p-0">
          <Form.Label>Supervisor</Form.Label>
          <Form.Control
            name="supervisor"
            value={form?.supervisor}
            onChange={event => {
              setField('supervisor', event.target.value);
            }}
            isInvalid={!!errors?.supervisor}
            type="number"
            pattern="[0-9]*"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.supervisor}
          </Form.Control.Feedback>
        </Form.Group>
        {errors?.totalWorkers && (
          <span className="error">{errors?.totalWorkers}</span>
        )}
        <Form.Row className="align-items-end justify-content-between m-0">
          <Form.Group controlId="formStartDate" className="col-sm-5 p-0">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              name="startDate"
              value={form?.startDate}
              onChange={event => {
                setField('startDate', event.target.value);
              }}
              isInvalid={!!errors?.startDate}
              type="date"
            />
            <Form.Control.Feedback type="invalid">
              {errors?.startDate}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-sm-5 col-md-3 col-lg-2 d-flex justify-content-end">
            <Button className="w-100" type="submit" onClick={handleSubmit}>
              <span className="button-text mr-2">Generate</span>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                hidden={!loading}
              />
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
      {currentQuote && !loading && (
        <GeneratedQuote quote={currentQuote} showSave />
      )}
    </div>
  );

  const changeTab = tabName => {
    if (tabName !== currentTab) {
      dispatch(setCurrentTab(tabName));
      navigate('/quote-engine/generate-quote/drivers');
    }
  };

  return (
    <>
      <SubHeader
        text={subHeaderText}
        btnSmall={<Home sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigate('/quote-engine')}
        btnLarge={
          <Button onClick={() => navigate('/quote-engine/my-quotes')}>
            <span className="button-text">My Quotes</span>
          </Button>
        }
        btnInfo={true}
        emailAddress={process.env.REACT_APP_QUOTE_ENGINE_CONTACT_EMAIL}
      />
      <Container className="wrap" fluid>
        <Tabs
          styles={{ width: '1200px' }}
          currentContent={() =>
            currentTab === 'clerical' && renderClericalContent()
          }
          tabs={['Drivers', 'Clerical']}
          activeTab={currentTab}
          setTab={changeTab}
        />
      </Container>
      <Footer />
    </>
  );
};

export default GenerateClerical;
