import { formatIsoToDate } from '../../../utils';

const getCSVData = data => {
  const csvData = [
    [
      'Company Number',
      'Employee Number #',
      'Last Name',
      'First Name',
      'Middle Name',
      'Suffix',
      'Address Line 1',
      'Address Line 2',
      'City',
      'State',
      'ZIP',
      'Email',
      'Home Number',
      'Work Number',
      'Extension',
      'SMS',
      'Email',
      'Hours Worked',
      'Hire Date',
      'Termination Date',
      'Test Code',
      'Completed Code',
      'Expiration Date',
    ],
  ];
  let i;
  for (i = 0; i < data.length; i += 1) {
    csvData.push([
      `${'100'}`,
      `${data[i].EmployeeNumber || ''}`,
      `${data[i].LastName || ''}`,
      `${data[i].FirstName || ''}`,
      `${data[i].MiddleName || ''}`,
      `${data[i].Suffix || ''}`,
      `${data[i].APPL_ADDRESS1 || ''}`,
      `${data[i].APPL_ADDRESS2 || ''}`,
      `${data[i].APPL_CITY || ''}`,
      `${data[i].APPL_STATE || ''}`,
      `${data[i].APPL_POSTCODE || ''}`,
      `${data[i].EmailAddress || ''}`,
      `${data[i].NUM_HOMEPHONE || ''}`,
      `${data[i].NUM_WORKPHONE || ''}`,
      `${data[i].NUM_WORKEXTEN || ''}`,
      `${data[i].SMS || ''}`,
      `${data[i].Email || ''}`,
      `${data[i].HOURS_WORKED || ''}`,
      `${formatIsoToDate(data[i].HireDate) || ''}`,
      `${formatIsoToDate(data[i].TerminationDate) || ''}`,
      `${data[i].Test_Code || ''}`,
      `${formatIsoToDate(data[i].Test_Date) || ''}`,
      `${formatIsoToDate(data[i].Test_Expiration_Date) || ''}`,
    ]);
  }
  return csvData;
};

export default getCSVData;
