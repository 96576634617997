import React, { useEffect, useState } from 'react';

import { Row, Form, Button, Breadcrumb } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CheckReconciliationTable, { deductionRegisterColumns } from './CheckReconciliationTable';
import Excel from '../../../assets/excel.png';
import { Breadcrumbs, EdgeReportingWindow, SubHeader, ReportLoader } from '../../../components';
import { newDateFormatted } from '../../../utils';
import { formatPhoneNumber } from '../../../utils/utilities';
import { getStates } from '../../DriverQualification/ListDQ/NewRequest/operations';
import getCSVData from '../csv';
import { generateReport, getReportData, removeReportData, userHasAccess } from '../operations';

import './styles.scoped.css';

const CheckReconciliationForm = ({ setForm, form, handleViewResults, navigate }) => {
  const handleChange = e => {
    const _form = {
      ...form,
      [e.target.name]: e.target.name === 'EmplPhone' ? formatPhoneNumber(e.target.value) : e.target.value,
    };
    setForm(_form);
  };

  return (
    <Form
      noValidate
      onSubmit={() => {}}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 20,
      }}>
      <div className="filters-row">
        <div className="filter-row">
          <Form.Group as={Row} controlId="AcctNumber" style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px' }}>Acct #</Form.Label>
            <Form.Control
              style={{
                width: '360px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              name="AcctNumber"
              defaultValue="CHECKING100"
              disabled
              onChange={handleChange}
            />
          </Form.Group>
        </div>

        <div
          className="filter-row"
          style={{
            marginTop: '30px',
          }}>
          <Form.Group as={Row} controlId="FromCheckdate" style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px' }}>
              <span className="required">Check Date</span>
            </Form.Label>
            <Form.Control
              style={{
                width: '150px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              type="date"
              name="FromCheckdate"
              value={form?.FromCheckdate}
              onChange={handleChange}
              max={form?.ToCheckdate || undefined}
            />
          </Form.Group>
          <Form.Group as={Row} controlId="ToCheckdate" style={{ alignItems: 'center' }}>
            <Form.Label
              style={{
                width: '20px',
                marginLeft: '30px',
                marginRight: '20px',
              }}>
              To
            </Form.Label>
            <Form.Control
              style={{
                width: '150px',
                height: '30px',
              }}
              as="input"
              type="date"
              name="ToCheckdate"
              value={form?.ToCheckdate}
              onChange={handleChange}
              min={form?.FromCheckdate || undefined}
            />
            <Form.Control.Feedback type="invalid" />
          </Form.Group>
        </div>

        <div
          className="filter-row"
          style={{
            marginTop: '30px',
          }}>
          <Form.Group as={Row} controlId="FromClearVoiddate" style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px' }}>
              <span className="required">Cleared/Void Date</span>
            </Form.Label>
            <Form.Control
              style={{
                width: '150px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              type="date"
              name="FromClearVoiddate"
              value={form?.FromClearVoiddate}
              onChange={handleChange}
              max={form?.ToClearVoiddate || undefined}
            />
          </Form.Group>
          <Form.Group as={Row} controlId="ToClearVoiddate" style={{ alignItems: 'center' }}>
            <Form.Label
              style={{
                width: '20px',
                marginLeft: '30px',
                marginRight: '20px',
              }}>
              To
            </Form.Label>
            <Form.Control
              style={{
                width: '150px',
                height: '30px',
              }}
              as="input"
              type="date"
              name="ToClearVoiddate"
              value={form?.ToClearVoiddate}
              onChange={handleChange}
              min={form?.FromClearVoiddate || undefined}
            />
            <Form.Control.Feedback type="invalid" />
          </Form.Group>
        </div>

        <div
          className="filter-row"
          style={{
            marginTop: '30px',
          }}>
          <Form.Group as={Row} controlId="UnClearedChecks" style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px', marginBottom: 0 }}>
              <div />
            </Form.Label>
            <Form.Check
              style={{
                width: '150px',
                height: '30px',
                marginRight: '20px',
                accentColor: '#da0f2e',
              }}
              className="filter-checkbox"
              type="checkbox"
              id="UnClearedChecks"
              label="UnClearedChecks"
              checked={!!form?.UnClearedChecks}
              onChange={() =>
                setForm({
                  ...form,
                  UnClearedChecks: form.UnClearedChecks ? 0 : 1,
                })
              }
              onBlur={null}
            />
          </Form.Group>
        </div>

        <div
          className="filter-row"
          style={{
            marginTop: '30px',
          }}>
          <Form.Group as={Row} controlId="ClearedChecks" style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px', marginBottom: 0 }}>
              <div />
            </Form.Label>
            <Form.Check
              style={{
                width: '150px',
                height: '30px',
                marginRight: '20px',
                accentColor: '#da0f2e',
              }}
              className="filter-checkbox"
              type="checkbox"
              id="ClearedChecks"
              label="ClearedChecks"
              checked={!!form?.ClearedChecks}
              onChange={() =>
                setForm({
                  ...form,
                  ClearedChecks: form.ClearedChecks ? 0 : 1,
                })
              }
              onBlur={null}
            />
          </Form.Group>
        </div>

        <div
          className="filter-row"
          style={{
            marginTop: '30px',
          }}>
          <Form.Group as={Row} controlId="VoidedChecks" style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px', marginBottom: 0 }}>
              <div />
            </Form.Label>
            <Form.Check
              style={{
                width: '150px',
                height: '30px',
                marginRight: '20px',
                accentColor: '#da0f2e',
              }}
              className="filter-checkbox"
              type="checkbox"
              id="VoidedChecks"
              label="VoidedChecks"
              checked={!!form?.VoidedChecks}
              onChange={() =>
                setForm({
                  ...form,
                  VoidedChecks: form.VoidedChecks ? 0 : 1,
                })
              }
              onBlur={null}
            />
          </Form.Group>
        </div>

        <div
          className="filter-row"
          style={{
            marginTop: '30px',
          }}>
          <Form.Group as={Row} controlId="BypassDirectDeposit" style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '290px', marginBottom: 0 }}>
              <div />
            </Form.Label>
            <Form.Check
              style={{
                width: '150px',
                height: '30px',
                marginRight: '20px',
                accentColor: '#da0f2e',
              }}
              className="filter-checkbox"
              type="checkbox"
              id="BypassDirectDeposit"
              label="BypassDirectDeposit"
              checked={!!form?.BypassDirectDeposit}
              onChange={() =>
                setForm({
                  ...form,
                  BypassDirectDeposit: form.BypassDirectDeposit ? 0 : 1,
                })
              }
              onBlur={null}
            />
          </Form.Group>
        </div>
      </div>

      <div className="buttons-row">
        <Button onClick={() => handleViewResults()} style={{ marginRight: '20px', width: '100px' }}>
          <span>VIEW</span>
        </Button>

        <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
          <span>CANCEL</span>
        </Button>
      </div>
    </Form>
  );
};

const CheckReconciliation = () => {
  const navigate = useNavigate();
  const {
    clientMenu: { menuItems },
  } = useSelector(state => state.edgeReporting);
  const { user } = useSelector(state => state.admin);
  const [data, setData] = useState([]);
  const [form, setForm] = useState({
    UnClearedCheck: 0,
    ClearedChecks: 0,
    VoidedChecks: 0,
    BypassDirectDeposit: 1,
  });
  const [loadingResults, setLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [locationData, setLocationData] = useState();

  useEffect(() => {
    if (menuItems) {
      // eslint-disable-next-line no-undef
      if (!userHasAccess(user, menuItems.find(i => i.to === window.location.pathname)?.id)) navigate('/');
    }
  }, [menuItems]);

  useEffect(() => {
    getAndSetFormData();
  }, []);

  const getAndSetFormData = async () => {
    const states = await getStates();
    if (states) {
      setLocationData({ ...locationData, states: states });
    }
  };

  const fileName = `edge-reporting-check-reconciliation-${newDateFormatted.toString()}.csv`;

  const handleViewResults = async () => {
    setLoadingResults(true);
    const {
      FromCheckdate = '',
      ToCheckdate = '',
      FromClearVoiddate = '',
      ToClearVoiddate = '',
      UnClearedChecks = 0,
      ClearedChecks = 0,
      VoidedChecks = 0,
      BypassDirectDeposit = 0,
    } = form;
    try {
      const response = await generateReport({
        endpoint: 'check-reconciliation-report',
        payload: {
          FromCheckdate,
          ToCheckdate,
          FromClearVoiddate,
          ToClearVoiddate,
          UnClearedChecks,
          ClearedChecks,
          VoidedChecks,
          BypassDirectDeposit,
        },
      });
      if (response.location && response.location !== '') {
        const reportData = await getReportData(response.location);
        if (reportData?.length) {
          await removeReportData(response.location);
          setLoadingResults(false);
          setData(reportData);
          setShowResults(true);
        }
      } else {
        setLoadingResults(false);
        setLoadingResults(false);
        // eslint-disable-next-line no-undef
        alert('No data found matching your criteria.');
      }
    } catch (error) {
      console.log(error);
      setLoadingResults(false);
    }
  };

  return (
    <>
      <SubHeader
        text="EDGE Reporting"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Check Reconciliation report?"
        emailAddress={process.env.REACT_APP_EDGE_REPORTING_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle="Reporting Home"
          middleTitle={showResults && 'Check Reconciliation'}
          midItemAction={() => setShowResults(false)}
          rightTitle={showResults ? 'Viewing Report' : 'Check Reconciliation'}
          route="/edge-reporting"
        />
        {!!data?.length && showResults && (
          <CSVLink filename={fileName} data={getCSVData(data, deductionRegisterColumns)}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
        )}
      </div>
      {loadingResults && <ReportLoader didAbort={() => setLoadingResults(false)} />}
      {showResults ? (
        <>
          <CheckReconciliationTable data={data} loading={loadingResults} />
          <div className="buttons-row">
            <Button onClick={() => setShowResults(false)} style={{ marginRight: '20px', width: '100px' }}>
              <span>BACK</span>
            </Button>

            <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
              <span>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <EdgeReportingWindow
          containerStyle={{
            border: '2px solid #343a40',
          }}
          windowContent={CheckReconciliationForm({ form, setForm, handleViewResults, navigate })}
          windowTitle="Criteria"
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )}
    </>
  );
};

export default CheckReconciliation;
