import { createSlice } from "@reduxjs/toolkit";

const assembleNewDQFSlice = createSlice({
    name: 'assembleNewDQF',
    initialState: {
        documentCategories: [],
        documentSources: [],
        documentTypes: [],
    },
    reducers: {
        setDocumentCategories: (state, { payload }) => {
            state.documentCategories = payload;
        },
        setDocumentSources: (state, { payload }) => {
            state.documentSources = payload;
        },
        setDocumentTypes: (state, { payload }) => {
            state.documentTypes = payload;
        },
    }
});

export default assembleNewDQFSlice.reducer;

export const {
    setDocumentCategories,
    setDocumentSources,
    setDocumentTypes,
} = assembleNewDQFSlice.actions;