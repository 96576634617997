import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';

import { Auth } from 'aws-amplify';
import { useBeforeunload } from 'react-beforeunload';
import { MdWeb } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import FormContent from './FormContent';
import { Breadcrumbs, ExpensesWindow, ModalConfirmation, SubHeader } from '../../../../components';
import { resetData } from '../../../../components/ExpenseForm/expenseFormSlice';
import { formatIsoToDate } from '../../../../utils';
import { getUserInfo } from '../../../DriverQualification/ViewDQF/operations';
import { calculateAuditKey, FlatFeeModal, receiptsHaveReceiptAmount } from '../expensesUtils';
import { createExpense, createExpenseItem, deleteExpenseFile, getExpenseId, updateExpense } from '../../DriverSolutions/operations';
import { dateToTimeZero } from '../TransferToUltraStaff/utils';

import './styles.scoped.css';

const Other = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { clientMenu } = useSelector(state => state.expenseEntry);
  const [expenseId, setExpenseId] = useState(null);

  const { user } = useSelector(state => state.admin);
  const [auditKey, setAuditKey] = useState('--');
  const [currentUser, setCurrentUser] = useState('');
  const [totalToBeBilled, setTotalToBeBilled] = useState('0.00');
  const [receipts, setReceipts] = useState([{ markup: '0', receiptAmount: '0.0' }]);
  const [isCreatingItems, setIsCreatingItems] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showReceiptFilesDialog, setShowReceiptFilesDialog] = useState(false);
  const hasSetRebillData = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showFFDialog, setShowFFDialog] = useState(false);
  const [showNonFFDialog, setShowNonFFDialog] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState();
  const [assignmentLineOfBusinessItems, setAssignmentLineOfBusinessItems] = useState({});
  const assignmentLineOfBusiness = useRef('');

  const getAssignmentLineOfBusinessItems = () => {
    clientMenu?.menuItems?.map(i => {
      if (location.pathname.includes(i.to)) {
        setAssignmentLineOfBusinessItems(i);
      }
    })
  };

  const getId = async () => {
    const id = await getExpenseId();
    setExpenseId(id);
  }

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      document.querySelector('div.root').style.background = '#D0CED0';
      document.querySelector('span.wrap').style.background = '#ffff';
      document.querySelector('.left-title').style.color = '#ffff';
      document.querySelector('.right-title').style.color = '#66CCFF';
    }
    return () => {
      document.querySelector('div.root').style.background = '#ffff';
    }
  }, []);

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      assignmentLineOfBusiness.current = 'DMS';
    }
    if (location.pathname.includes('mobile-driver-solutions')) {
      assignmentLineOfBusiness.current = 'MDS';
    }
  }, []);

  useEffect(() => {
    if (expenseId !== '' || expenseId !== null) {
      getId();
    }
  }, [])

  useEffect(() => {
    if (uploadError) {
      // eslint-disable-next-line no-undef
      alert(uploadError);
      setUploadError(null);
    }
  }, [uploadError]);

  useEffect(
    () => () => {
      dispatch(resetData());
    },
    [],
  );

  const [errors, setErrors] = useState();
  const [receiptsErrors, setReceiptsErrors] = useState([]);

  //form default values
  const [form, setForm] = useState({
    code: 'XX',
    status: 'Unbilled',
    nonBillable: false,
    checkedForUltraStaff: false,
    lob: assignmentLineOfBusiness,
    inv: 'I',
    payType: 'F',
    billUnits: '1',
    payAmt: 0.0,
    ok: 'T',
    lob: assignmentLineOfBusiness.current,
  });

  const requiredReceiptFields = ['otherExpenseName', 'otherExpenseDescription', 'otherExpenseDate', 'markup', 'receiptAmount'];

  const checkReceiptsErrors = () => {
    const allErrors = [...receiptsErrors];

    allErrors.forEach((receiptErrors, id) => {
      const receiptErrorsCopy = { ...receiptErrors };
      requiredReceiptFields.forEach(field => {
        if (receipts?.[id][field]) {
          delete receiptErrorsCopy[field];
          if (!Object.keys(receiptErrorsCopy).length) {
            allErrors.splice(id, 1);
          } else {
            allErrors[id] = receiptErrorsCopy;
          }
        }
      });

      setReceiptsErrors(allErrors);
    });
  };

  useEffect(() => {
    checkReceiptsErrors();
  }, [receipts]);

  //to prevent the user from navigating, when the expense has changes
  useBeforeunload(event => {
    if (hasChanges) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    if (assignmentLineOfBusiness.current != '' && form?.lob === '') {
      setForm({
        ...form,
        lob: assignmentLineOfBusiness.current
      })
    }
  }, [form])

  //get current user data when the component mount, used to attach the name to the uploadName on receipt
  useEffect(() => {
    getAndSetUserInfo();
    getAssignmentLineOfBusinessItems();
  }, []);

  //for rebill, check if expenses and receipts are passed
  useEffect(() => {
    if ((form, location?.state?.expense && location?.state.receipts && !hasSetRebillData.current)) {
      hasSetRebillData.current = true;
      setIsLoading(true);
      const rebilledExpense = {
        ...location?.state?.expense,
        originalExpenseId: location?.state?.expenseId,
        code: `${form?.code}R`,
        status: 'Unbilled',
        statementDate: dateToTimeZero(location?.state?.expense?.statementDate),
      };
      delete rebilledExpense.id;

      setTimeout(() => {
        setForm(rebilledExpense);
        setIsLoading(false);
      }, 5000);

      const receiptsCopy = [...(location?.state?.receipts || [])];
      receiptsCopy.forEach(receipt => {
        delete receipt.id;
        delete receipt.expenseExpenseItemId;
        receipt.otherExpenseDate = formatIsoToDate(receipt?.otherExpenseDate, 'YYYY-MM-DD');
      });

      setReceipts(receiptsCopy);
    }
  }, [location?.state, form]);

  //delete the files from s3 if the user navigates without saving
  const bulkDeleteReceipts = async items => {
    Promise.all(items.map(item => deleteExpenseFile(item)));
  };

  const removeReceiptDocs = async () => {
    const receiptDocs = [];
    receipts?.forEach(r => {
      receiptDocs.push(r?.documentName);
    });
    if (receiptDocs.length) {
      await bulkDeleteReceipts(receiptDocs);
    }
  };

  // checks for the minimum required fields to create a expense
  const fieldsAreValid = () => {
    let formErrorsCopy = {
      ...errors,
    };
    const receiptsErrorsCopy = [...receiptsErrors];
    const requiredFields = [
      'code',
      'driverId',
      'driverName',
      'assignmentId',
      'status',
      'customerId',
      'customerName',
      'branch',
    ];

    requiredFields.forEach(field => {
      if (!form[field] || form[field] === '' || form[field] === '--') {
        formErrorsCopy = {
          ...formErrorsCopy,
          [field]: 'Please provide a valid value.',
        };
      }
    });

    receipts.forEach((r, index) => {
      requiredReceiptFields.forEach(rf => {
        if (rf === 'markup' || rf === 'receiptAmount') {
          if (r[rf] === '') {
            receiptsErrorsCopy[index] = {
              ...receiptsErrorsCopy[index],
              [rf]: 'Please provide a valid value.',
            };
          }
        } else if (!r[rf] || r[rf] === '' || r[rf] === '--') {
          receiptsErrorsCopy[index] = {
            ...receiptsErrorsCopy[index],
            [rf]: 'Please provide a valid value.',
          };
        }
      });
    });

    setReceiptsErrors(receiptsErrorsCopy);
    setErrors(formErrorsCopy);

    return !receiptsErrorsCopy.length && !Object.keys(formErrorsCopy).length;
  };

  // bulk create expense items when pressing save
  const bulkCreateExpenseItem = async (items, route) => {
    Promise.all(items.map(item => createExpenseItem(item))).then(() => {
      setIsCreatingItems(false);
      if (!location?.state?.expenseId) {
        setTimeout(() => {
          navigate(route || -1);
        }, 1000);
      }
    });
  };

  const receiptsHaveFiles = () => {
    let haveFiles = true;
    receipts.forEach(r => {
      if (!r?.documentName) {
        haveFiles = false;
      }
    });
    return haveFiles;
  };

  //gets the receipt status before creating the receipt
  const getReceiptStatus = () => {
    let status = form?.status;
    if (form?.status !== 'Non-billable' && form?.status !== 'Flat Fee Billing') {
      if (receiptsHaveFiles() && fieldsAreValid() && receiptsHaveReceiptAmount(receipts)) {
        status = 'Ready to Bill';
      } else {
        status = 'Unbilled';
      }
    }

    return status;
  };

  //expense creation when pressing save (if fields are valid)
  const confirmCreateExpense = async ({ isFF = false }) => {
    setIsCreatingItems(true);
    if (fieldsAreValid()) {
      //get the next available id for the expense
      const id = await getExpenseId();
      //gets cost center (cost center will have 2 possible values, one if we come from mobile drivers solutions
      // and the other is from)
      const costCenter = location.pathname.includes('mobile')
        ? process.env.REACT_APP_MDS_COST_CENTER
        : process.env.REACT_APP_DMS_COST_CENTER;
      const expense = {
        id: expenseId,
        code: isFF ? 'XFFBX' : form?.code,
        driverId: form?.driverId,
        driverName: form?.driverName,
        assignmentId: form?.assignmentId,
        status: isFF ? 'Flat Fee Billing' : getReceiptStatus(),
        nonBillable: isFF || form?.nonBillable,
        customerId: form?.customerId,
        customerName: form?.customerName,
        branch: form?.branch,
        branchName: form?.branchName,
        costCenter: parseInt(costCenter),
        startDate: form?.startDate ? dateToTimeZero(form?.startDate) : null,
        endDate: form?.endDate ? dateToTimeZero(form?.endDate) : null,
        totalToBeBilled: totalToBeBilled,
        notes: form?.notes,
        auditKey: auditKey,
        flatFeeBilling: isFF,
        variance: -totalToBeBilled,
        lob: form?.lob,
        updatedBy: user.id
      };
      //for rebill, set the original expenseId if we are passing an id
      if (location?.state?.expenseId) {
        expense.originalExpenseId = location?.state?.expenseId;
      }
      try {
        const resp = await createExpense(expense);
        if (resp) {
          const receiptsCopy = [...receipts];
          receiptsCopy.forEach(receipt => {
            String(receipt.receiptAmount);
            delete receipt.createdAt;
            delete receipt.updatedAt;
            receipt.expenseExpenseItemId = resp.id;
            receipt.otherExpenseDate = receipt?.otherExpenseDate ? dateToTimeZero(receipt?.otherExpenseDate) : null;
          });
          await bulkCreateExpenseItem(receiptsCopy);
          if (location?.state?.expenseId) {
            await updateExpense({
              id: location?.state?.expenseId,
              rebilledExpenseId: resp?.id,
            });
            navigate(assignmentLineOfBusinessItems.to);
          }
        }
        setIsCreatingItems(false);
      } catch (error) {
        setIsCreatingItems(false);
        console.log(error);

        // eslint-disable-next-line no-undef
        alert('There was an error, please try again later');
      }
    }
    setIsCreatingItems(false);
  };

  const getAndSetUserInfo = async () => {
    const { email } = (await Auth.currentSession()).getIdToken().payload;
    const user = await getUserInfo(email);
    setCurrentUser(user);
  };

  //calculates the total to be biled based on the expenseTotal field of each receipt
  const calculateTotal = () => {
    setTotalToBeBilled(
      parseFloat(receipts?.reduce((a, b) => parseFloat(a) + (parseFloat(b?.expenseTotal) || 0), 0)).toFixed(2),
    );
  };

  //gets the startDate and endDate of the Expense, based on the receipts max Date and the receipt minimum Date
  const getStartAndEndDate = () => {
    let startDate = '';
    let endDate = '';
    const emptyDate = '--';

    receipts.forEach(r => {
      if (!startDate.length && r?.otherExpenseDate) {
        startDate = r?.otherExpenseDate;
        endDate = r?.otherExpenseDate;
      } else {
        if (r?.otherExpenseDate && r?.otherExpenseDate < startDate) {
          startDate = r?.otherExpenseDate;
        }
        if (r?.otherExpenseDate && r?.otherExpenseDate > endDate) {
          endDate = r?.otherExpenseDate;
        }
      }
    });

    const emptyDates = {
      startDate: emptyDate,
      endDate: emptyDate,
    };
    const newDates = {
      startDate: formatIsoToDate(startDate),
      endDate: formatIsoToDate(endDate),
    };
    if (!!startDate && !!endDate) {
      setForm({
        ...form,
        ...newDates,
      });
    } else {
      setForm({
        ...form,
        ...emptyDates,
      });
    }
  };

  //calculates start date and total on every receipt change
  useEffect(() => {
    calculateTotal();
    getStartAndEndDate();
    if (Object.keys(receipts[0]) && !hasChanges) {
      setHasChanges(true);
    }
  }, [receipts]);

  useEffect(() => {
    calculateAuditKey(form, totalToBeBilled, setAuditKey);
  }, [form]);

  return (
    <>
      <SubHeader
        text="Expenses"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Expenses?"
        emailAddress={process.env.REACT_APP_EXPENSE_CONTACT_EMAIL}
      />
      <Breadcrumbs
        leftTitle={assignmentLineOfBusinessItems.text}
        rightTitle="Add Other"
        route={assignmentLineOfBusinessItems.to}
        color={assignmentLineOfBusinessItems.lob === 'DMS' && '#ffff'}
      />
      <ExpensesWindow
        windowContent={
          <FormContent
            id={expenseId}
            hasChanges={hasChanges}
            setShowCancelDialog={setShowCancelDialog}
            setReceipts={setReceipts}
            receipts={receipts}
            setHasChanges={setHasChanges}
            errors={errors}
            setForm={setForm}
            form={form}
            setErrors={setErrors}
            setSaveDisabled={setSaveDisabled}
            fieldsAreValid={fieldsAreValid}
            confirmCreateExpense={confirmCreateExpense}
            receiptsHaveFiles={receiptsHaveFiles}
            setShowReceiptFilesDialog={setShowReceiptFilesDialog}
            currentUser={currentUser}
            receiptsErrors={receiptsErrors}
            setReceiptsErrors={setReceiptsErrors}
            setShowFFDialog={setShowFFDialog}
            setShowNonFFDialog={setShowNonFFDialog}
            setUploading={setUploading}
            setUploadError={setUploadError}
            totalToBeBilled={totalToBeBilled}
            auditKey={auditKey}
            isCreatingItems={isCreatingItems}
            isLoading={isLoading}
            saveDisabled={saveDisabled}
            assignmentLineOfBusinessItems={assignmentLineOfBusinessItems}
          />
        }
        windowTitle="Add Other"
      />
      <ModalConfirmation
        title="Cancel?"
        body="Changes you made will be lost"
        primaryButtonTitle="Stay on Page"
        optionalClose
        primaryButton
        secondaryButtonTitle="Leave Page"
        onClick={() => {
          setShowCancelDialog(false);
        }}
        secondaryOnClick={async () => {
          setShowCancelDialog(false);
          await removeReceiptDocs();
          navigate(assignmentLineOfBusinessItems.to);
        }}
        isOpen={showCancelDialog}
      />
      <ModalConfirmation
        title="Please Note!"
        body="Please attach the receipt when available or this expense will not be billed"
        primaryButton
        primaryButtonTitle="OK"
        onClick={async () => {
          setShowReceiptFilesDialog(false);
          await confirmCreateExpense({
            isFF: form?.flatFeeBilling,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        isOpen={showReceiptFilesDialog}
      />
      <FlatFeeModal
        showDialog={showFFDialog}
        clientIsFlatFee
        setShowDialog={setShowFFDialog}
        ffAction={async () => {
          await confirmCreateExpense({
            isFF: true,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        billableAction={async () => {
          await confirmCreateExpense({
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        cancelAction={() => { }}
      />
      <FlatFeeModal
        showDialog={showNonFFDialog}
        clientIsFlatFee={false}
        setShowDialog={setShowNonFFDialog}
        ffAction={async () => {
          await confirmCreateExpense({
            isFF: true,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        billableAction={async () => {
          await confirmCreateExpense({
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        cancelAction={() => { }}
      />
      {uploading && <div className="overlay-box" />}
    </>
  );
};

export default Other;
