import React, { useEffect, useMemo, useState } from 'react';

import { Form, Button, Spinner } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import ARAgingReportTable, { arAgingColumns } from './ARAgingReportTable';
import ARAgingReportTableHistorical, {
  arAgingColumns as arAgingColumnsHistorical,
} from './ARAgingReportTableHistorical';
import { ARAgingReportForm } from './Form';
import Excel from '../../../assets/excel.png';
import { Breadcrumbs, EdgeReportingWindow, SubHeader } from '../../../components';
import { newDateFormatted } from '../../../utils';
import getCSVData from '../csv';
import { generateSingleReport, getList } from '../operations';

import './styles.scoped.css';

const ARAgingForm = ({
  form,
  setForm,
  AMCCodeOptions,
  branchNumberOptions,
  LOBCodeOptions,
  isLoadingAMCCodes,
  isLoadingBranchNumbers,
  isLoadingLOBCodes,
  handleViewResults,
  navigate,
}) => {
  const handleChange = e => {
    const _form = {
      ...form,
      [e.target.name]:
        e.target.name.includes('AgingDays') || e.target.name.includes('BillToNumber')
          ? Number(e.target.value)
          : e.target.value,
    };
    setForm(_form);
  };
  return (
    <Form
      noValidate
      onSubmit={() => { }}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <ARAgingReportForm
        form={form}
        setForm={setForm}
        handleChange={handleChange}
        AMCCodeOptions={AMCCodeOptions}
        branchNumberOptions={branchNumberOptions}
        LOBCodeOptions={LOBCodeOptions}
        isLoadingAMCCodes={isLoadingAMCCodes}
        isLoadingBranchNumbers={isLoadingBranchNumbers}
        isLoadingLOBCodes={isLoadingLOBCodes}
      />

      <div className="buttons-row">
        <Button onClick={() => handleViewResults()} style={{ marginRight: '20px', width: '100px' }}>
          <span>VIEW</span>
        </Button>

        <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
          <span>CANCEL</span>
        </Button>
      </div>
    </Form>
  );
};

const ARAgingReport = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [AMCCodes, setAMCCodes] = useState([]);
  const [branchNumbers, setBranchNumbers] = useState([]);
  const [LOBCodes, setLOB] = useState([]);

  const [form, setForm] = useState({
    Historical: 0,
    SortSequence: 1,
    AgingDaysLessThan: 30,
    AgingDaysBetweenFrom1: 30,
    AgingDaysBetweenTo1: 60,
    AgingDaysBetweenFrom2: 60,
    AgingDaysBetweenTo2: 90,
    Branch: '',
    AMC: '',
    BillToNumber: 0,
  });

  const [loadingResults, setLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);

  const [isLoadingAMCCodes, setIsLoadingAMCCodes] = useState(false);
  const [isLoadingBranchNumbers, setIsLoadingBranchNumbers] = useState(false);
  const [isLoadingLOBCodes, setIsLoadingLOB] = useState(false);

  const handleGetAMCCodes = async () => {
    setIsLoadingAMCCodes(true);
    const amcc = await getList('amcs');
    setAMCCodes(amcc);
    setIsLoadingAMCCodes(false);
  };

  const handleGetBranchNumbers = async () => {
    setIsLoadingBranchNumbers(true);
    const bn = await getList('branches');
    setBranchNumbers(bn);
    setIsLoadingBranchNumbers(false);
  };

  const handleGetLOB = async () => {
    setIsLoadingLOB(true);
    const lob = await getList('line-of-business');
    setLOB(lob);
    setIsLoadingLOB(false);
  };

  useEffect(() => {
    handleGetAMCCodes();
    handleGetBranchNumbers();
    handleGetLOB();
  }, []);

  const AMCCodeOptions = useMemo(
    () =>
      AMCCodes.map(({ Code, Description }) => ({
        label: Description,
        value: Code,
      })),
    [AMCCodes],
  );

  const branchNumberOptions = useMemo(
    () =>
      branchNumbers.map(({ Branch, BranchName }) => ({
        label: BranchName,
        value: Branch,
      })),
    [branchNumbers],
  );

  const LOBCodeOptions = useMemo(
    () =>
      LOBCodes.map(({ LineOfBusinessCode, LineOfBusinessDesc }) => ({
        label: LineOfBusinessDesc,
        value: LineOfBusinessCode,
      })),
    [LOBCodes],
  );

  const fileName = `edge-reporting-ar-aging-report${form?.Historical ? '-historical' : ''
    }-${newDateFormatted.toString()}.csv`;

  const handleViewResults = async () => {
    setLoadingResults(true);
    try {
      const response = await generateSingleReport({
        endpoint: 'aging-report',
        payload: form,
      });
      if (response?.length) {
        setLoadingResults(false);
        setData(response);
        setShowResults(true);
      } else {
        setLoadingResults(false);
        // eslint-disable-next-line no-undef
        alert('No data found matching your criteria.');
      }
    } catch (error) {
      console.log(error);
      setLoadingResults(false);
      // eslint-disable-next-line no-undef
      alert('No data found matching your criteria.');
    }
    setLoadingResults(false);
  };

  return (
    <>
      <SubHeader
        text="EDGE Reporting"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your AR Aging Report report?"
        emailAddress={process.env.REACT_APP_EDGE_REPORTING_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle="Reporting Home"
          middleTitle={showResults && 'AR Aging Report'}
          midItemAction={() => setShowResults(false)}
          rightTitle={showResults ? `Viewing Report${form?.Historical ? ' (Historical)' : ''}` : 'AR Aging Report'}
          route="/edge-reporting"
        />
        {!!data?.length && showResults && (
          <CSVLink
            filename={fileName}
            data={getCSVData(data, form?.Historical ? arAgingColumnsHistorical : arAgingColumns)}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
        )}
      </div>
      {loadingResults && (
        <div className="overlay-box">
          <Spinner className="spinner" animation="border" />
        </div>
      )}
      {showResults ? (
        <>
          {form?.Historical ? (
            <ARAgingReportTableHistorical data={data} loading={loadingResults} />
          ) : (
            <ARAgingReportTable data={data} loading={loadingResults} />
          )}
          <div className="buttons-row">
            <Button onClick={() => setShowResults(false)} style={{ marginRight: '20px', width: '100px' }}>
              <span>BACK</span>
            </Button>

            <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
              <span>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <EdgeReportingWindow
          containerStyle={{
            border: '2px solid #343a40',
          }}
          windowContent={ARAgingForm({
            form,
            setForm,
            AMCCodeOptions,
            branchNumberOptions,
            LOBCodeOptions,
            isLoadingAMCCodes,
            isLoadingBranchNumbers,
            isLoadingLOBCodes,
            handleViewResults,
            navigate,
          })}
          windowTitle="Criteria"
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )}
    </>
  );
};

export default ARAgingReport;
