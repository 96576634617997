import React, { useEffect, useState, useRef } from 'react';

import { Checkbox, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { Row } from 'react-bootstrap';
import { MdInfo } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { InputBox, SelectorList } from '..';
import './styles.scoped.css';

function formatIsoToDate(iso) {
  if (!iso) {
    return null;
  }
  return dayjs(iso).format('MM/DD/YYYY');
}

function isOverflowActive(event) {
  return event.offsetHeight < event.scrollHeight || event.offsetWidth < event.scrollWidth;
}
const SelectDocumentRow = ({ row, onOrderChange, onPagesChange, onCheckboxChange, setOverflowActive }) => {
  const { rows, selectedDocuments } = useSelector(state => state.selectDocumentDetails);
  const [orderOptions, setOrderOptions] = useState([]);
  const textRef = useRef();
  const overflowActive = useRef();

  useEffect(() => {
    if (isOverflowActive(textRef.current)) {
      overflowActive.current = true;
      setOverflowActive(true);
      return;
    }

    overflowActive.current = false;
    setOverflowActive(false);
  }, [isOverflowActive]);

  useEffect(() => {
    if (rows) {
      const options = [];
      rows.forEach((_, i) => options.push({ label: `${i + 1}`, value: `${i + 1}` }));
      setOrderOptions(options);
    }
  }, [rows]);
  return (
    <>
      <Row>
        <span style={{ width: '10%' }} className="table-cells">
          {formatIsoToDate(row.atsDateCreated)}
        </span>
        <span style={{ width: '10%' }} className="table-cells">
          {formatIsoToDate(row.expirationDate)}
        </span>
        {overflowActive ? (
          <>
            <Tooltip title={row.documentName} arrow placement="top">
              <span ref={textRef} className="hiddenText table-cells" style={{ width: '20%' }}>
                {row.documentName}
              </span>
            </Tooltip>
          </>
        ) : (
          <span ref={textRef} style={{ width: '20%' }} className="table-cells">
            {row.documentName}
          </span>
        )}
        <span style={{ width: '10%' }} className="table-cells">
          {row.source}
        </span>
        <span style={{ width: '10%' }} className="table-cells">
          {row.category}
        </span>
        <span style={{ width: '15%' }} className="table-cells">
          {row.type}
        </span>
        <span style={{ width: '6%' }} className="table-cells">
          <Checkbox
            style={{
              color: '#da0f2e',
              height: 0,
              width: 0,
            }}
            onChange={() => {
              onCheckboxChange(row);
            }}
            checked={!!selectedDocuments?.find(item => item.documentName === row.documentName)}
          />
        </span>
        <span style={{ width: '9%' }} className="table-cells">
          <InputBox
            value={row.pages}
            onChange={onPagesChange}
            placeholder="e.g. All,1-5,8,11-13"
            errorMessage="check input"
            error={
              row.pages !== '' &&
              row.pages !== undefined &&
              (!/^(\s*\d+\s*(-\s*\d+\s*)?)(,\s*\d+\s*(-\s*\d+\s*)?)*$/.test(row.pages) &&
                row.pages.toLowerCase() !== 'all')
            }
          />
        </span>
        <span style={{ width: '10%' }} className="table-cells">
          <SelectorList value={row.order} data={orderOptions} onChange={onOrderChange} />
        </span>
      </Row>
    </>
  );
};

export default SelectDocumentRow;
