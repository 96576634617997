import React, { useEffect, useMemo, useRef, useState } from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Row, Form, InputGroup } from 'react-bootstrap';
import { MdWarning } from 'react-icons/md';
import Select from 'react-select';

import { getCitiesFiltered } from './operations';
import { ExpenseReceiptUploader } from '../../../../components';
import { DisableClickOverlay, formatPhoneNumber, isBilledOrTransmitted } from '../../../../utils/utilities';
import DocumentRow from '../documentRow';
import { uploadExpenseFile } from '../../DriverSolutions/operations';

import './styles.scoped.css';

dayjs.extend(customParseFormat);

const Receipt = ({
  receipt,
  id,
  setReceiptFields,
  formData,
  setSave,
  isSettingState,
  isSettingCity,
  receipts,
  handleDeleteRow,
  customStyles,
  receiptsErrors,
  setReceiptField,
  debouncedSetReceiptField,
  inputSave,
  deleteDocumentFromReceipt,
  setReceiptFile,
  setUploading,
  setUploadError,
  form,
  isCR,
  hotelOptions,
  expenseId,
}) => {
  const [cityInput, setCityInput] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const newStateRef = useState();
  const hasSetReceiptOptions = useRef(false);

  const [receiptAmountValue, setReceiptAmountValue] = useState(0);
  const [markupValue, setMarkupValue] = useState(0);
  const [dailyChargeValue, setDailyChargeValue] = useState(0);

  const handleReceiptAmountBlur = () => {
    if (receiptAmountValue === '') {
      setReceiptAmountValue(0);
    }
  };

  const handleMarkupBlur = () => {
    if (markupValue === '') {
      console.log('set to zero')
      setMarkupValue(0);
    }
  };

  const handleDailyChargBlur = () => {
    if (dailyChargeValue === '') {
      setDailyChargeValue(0);
    }
  };

  useEffect(() => {
    if (receipt.receiptAmount !== undefined && receipt.receiptAmount !== null) {
      setReceiptAmountValue(receipt.receiptAmount);
    } else {
      setReceiptAmountValue(0);
    }
  }, [receipt.receiptAmount]);

  useEffect(() => {
    if (receipt.markup !== undefined && receipt.markup !== null) {
      setMarkupValue(receipt.markup);
    } else {
      setMarkupValue(0);
    }
  }, [receipt.markup]);

  useEffect(() => {
      if (receipt.dailyCharge !== undefined && receipt.dailyCharge !== null) {
        setDailyChargeValue(receipt.dailyCharge);
      } else {
        setDailyChargeValue(0);
      }
    }, [receipt.dailyCharge]);

  let currentCities = [];
  let prevToken = '';

  const getCitiesBatch = async (nextToken = null) => {
    if (prevToken !== nextToken) {
      setIsLoadingCities(true);
      prevToken = nextToken;
      const data = await getCitiesFiltered({
        state: newStateRef.current[0]?.fieldValue,
        token: nextToken,
      });
      const token = data?.nextToken;

      if (data?.items) {
        const items = data?.items;
        currentCities = currentCities.concat(items);
      }
      if (token) {
        getCitiesBatch(token);
      } else {
        setIsLoadingCities(false);
        setReceiptFields(id, [
          ...newStateRef.current,
          {
            fieldName: 'selectedCities',
            fieldValue: currentCities,
          },
        ]);
      }
    }
  };

  const handleStateChange = async newVal => {
    setCityInput('');
    newStateRef.current = newVal;
    setReceiptFields(id, [...newStateRef.current]);
    await getCitiesBatch();
  };

  const stateOptions = formData?.states
    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(({ name, id: scopedId }) => ({
      label: name,
      value: scopedId,
    }));

  const customHotelOptions = useMemo(() => {
    const result = hotelOptions || [];
    if (
      receipt.expenseItemHotelId &&
      receipt.selectedHotel &&
      !result.find(ao => ao.value === receipt.expenseItemHotelId)
    ) {
      result.push({
        label: receipt.selectedHotel,
        value: receipt.expenseItemHotelId,
      });
      result?.sort((a, b) => (a.label > b.label ? 1 : -1));
    }
    return result;
  }, [hotelOptions, receipt.expenseItemHotelId, receipt.selectedHotel]);

  const customCityOptions = useMemo(() => {
    const cityOptions = receipt?.selectedCities
      ?.filter(co => co.status === 'Active')
      .map(({ name, id: scopedId }) => ({
        label: name,
        value: scopedId,
      }));

    const result = cityOptions || [];

    if (
      receipt.expenseItemHotelCityId &&
      receipt.selectedCity &&
      !result.find(ao => ao.value === receipt.expenseItemHotelCityId) &&
      receipt.expenseItemHotelStateId === receipt?.selectedCityState
    ) {
      result.push({
        label: receipt.selectedCity,
        value: receipt.expenseItemHotelCityId,
      });
    }

    result.sort((a, b) => (a.label > b.label ? 1 : -1));
    return result;
  }, [
    receipt?.selectedCities,
    receipt?.expenseItemHotelCityId,
    receipt?.selectedCity,
    receipt?.expenseItemHotelStateId,
    receipt?.selectedCityState,
  ]);

  const getSelectedState = () => {
    let selectedState = '';
    if (receipt?.expenseItemHotelStateId) {
      formData?.states?.forEach(state => {
        if (state.id === receipt?.expenseItemHotelStateId) {
          selectedState = state?.name;
        }
      });
    }
    return selectedState;
  };

  const getSelectedCity = () => {
    let selectedCity = '';
    if (receipt?.expenseItemHotelCityId) {
      receipt?.selectedCities?.forEach(city => {
        if (city.id === receipt?.expenseItemHotelCityId) {
          selectedCity = city?.name;
        }
      });
    }

    setReceiptFields(id, [
      {
        fieldName: 'expenseItemHotelCityId',
        fieldValue: receipt?.expenseItemHotelCityId,
      },

      {
        fieldName: 'selectedCity',
        fieldValue: selectedCity,
      },
    ]);
    setCityInput(selectedCity);
    setSave(selectedCity);
  };

  const setReceiptOptions = () => {
    if (receipt?.selectedCities?.length && Object.keys(receipts?.[0]).length) {
      const receiptCopy = { ...receipt };
      hasSetReceiptOptions.current = true;
      const {
        name,
        state: { id: stateId },
      } = receipt?.selectedCities?.filter(aCity => aCity.id === receiptCopy.expenseItemHotelCityId)?.[0] || {};
      receiptCopy.selectedCity = name;
      receiptCopy.selectedCityState = stateId;
      setReceiptFields(id, [
        {
          fieldName: 'selectedCity',
          fieldValue: name,
        },
        // Removed to prevent transfer to UltraStaff Error
        // {
        //   fieldName: 'selectedCityState',
        //   fieldValue: stateId,
        // },
      ]);
    }
  };

  useEffect(() => {
    if (hasSetReceiptOptions.current === false) {
      if (receipt.expenseItemHotelCityId !== undefined) setReceiptOptions();
    }
  }, [receipt?.selectedCities, receipt.expenseItemHotelCityId]);

  useEffect(() => {
    if (
      !isSettingState.current?.[id] &&
      receipt?.expenseItemHotelCityId &&
      !receipt?.selectedCity &&
      !receipt?.selectedCities
    ) {
      isSettingState.current[id] = true;
      handleStateChange([
        {
          fieldName: 'expenseItemHotelStateId',
          fieldValue: receipt?.expenseItemHotelStateId,
        },

        {
          fieldName: 'selectedState',
          fieldValue: getSelectedState(),
        },
      ]);
    }
  }, [receipt]);

  useEffect(() => {
    if (
      !isSettingCity.current?.[id] &&
      receipt?.selectedCities &&
      receipt?.expenseItemHotelCityId &&
      !receipt?.selectedCity
    ) {
      isSettingCity.current[id] = true;
      getSelectedCity();
    }
  }, [receipt?.selectedCities]);

  return (
    <div className="receipt-wrapper">
      {!receipt.documentName && (
        <div className="warning-container">
          <MdWarning size={36} title="Receipt document was not attached" color="#da0f2e" />
        </div>
      )}
      <DisableClickOverlay isDisabled={isBilledOrTransmitted(form) || isCR || form?.status === 'Voided'}>
        {receipts.length > 1 && (
          <div onClick={() => handleDeleteRow(id)} className="delete-row">
            Delete Row
          </div>
        )}
      </DisableClickOverlay>
      <DisableClickOverlay isDisabled={isBilledOrTransmitted(form) || isCR || form?.status === 'Voided'}>
        <Row
          className="top-row"
          style={{
            margin: '5px 0 0 0',
          }}>
          <div
            className="top-col"
            style={{
              width: '40%',
              justifyContent: 'flex-start',
            }}>
            <div className="below-container">
              <Form.Group controlId="hotel">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">Hotel</div>
                </Form.Label>
                <div className="dropdown-spinner-container">
                  <Select
                    styles={customStyles('300px')}
                    options={customHotelOptions}
                    onChange={event => {
                      setReceiptFields(id, [
                        {
                          fieldName: 'expenseItemHotelId',
                          fieldValue: event.value,
                        },
                        {
                          fieldName: 'selectedHotel',
                          fieldValue: event.label,
                        },
                      ]);
                    }}
                    value={customHotelOptions?.find(hotel => hotel.value === receipt?.expenseItemHotelId)}
                  />
                </div>
                {receiptsErrors[id]?.expenseItemHotelId && (
                  <div className="error">{receiptsErrors[id]?.expenseItemHotelId}</div>
                )}
              </Form.Group>
            </div>
          </div>
          <div className="top-col">
            <Form.Group controlId="hotelConfirmationNumber">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                <div className="required">Hotel Confirmation Number</div>
              </Form.Label>
              <Form.Control
                key={`${id}hotelConfirmationNumber`}
                style={{
                  width: '225px',
                  height: '30px',
                  paddingLeft: 10,
                }}
                type="input"
                name="hotelConfirmationNumber"
                defaultValue={receipt?.hotelConfirmationNumber}
                onBlur={event => {
                  setReceiptField(id, 'hotelConfirmationNumber', event.target.value, receipts);
                }}
                isInvalid={!!receiptsErrors[id]?.hotelConfirmationNumber}
              />
              <Form.Control.Feedback type="invalid">
                {receiptsErrors[id]?.hotelConfirmationNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="top-col">
            <Form.Group controlId="hotelPhoneNumber">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                <div className="required">Hotel Phone Number</div>
              </Form.Label>
              <Form.Control
                key={`${id}hotelPhoneNumber`}
                style={{
                  width: '225px',
                  height: '30px',
                  paddingLeft: 10,
                }}
                type="input"
                name="hotelPhoneNumber"
                onChange={event => {
                  const str = event.target.value?.replace(/\D/, '');
                  debouncedSetReceiptField({
                    id: id,
                    field: 'hotelPhoneNumber',
                    value: formatPhoneNumber(str),
                    receipts: receipts,
                  });
                  setPhoneNumber(formatPhoneNumber(event.target.value));
                }}
                value={phoneNumber || receipt?.hotelPhoneNumber}
                isInvalid={!!receiptsErrors[id]?.hotelPhoneNumber}
              />
              <Form.Control.Feedback type="invalid">{receiptsErrors[id]?.hotelPhoneNumber}</Form.Control.Feedback>
            </Form.Group>
          </div>
        </Row>
      </DisableClickOverlay>
      <DisableClickOverlay isDisabled={isBilledOrTransmitted(form) || isCR || form?.status === 'Voided'}>
        <Row
          className="top-row"
          style={{
            margin: '5px 0 0 0',
          }}>
          <div
            className="top-col"
            style={{
              width: '40%',
              justifyContent: 'flex-start',
            }}>
            <div className="below-container">
              <Form.Group controlId="hotelStreetAddress">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">Hotel Street Address</div>
                </Form.Label>
                <Form.Control
                  key={`${id}hotelStreetAddress`}
                  style={{
                    width: '300px',
                    height: '30px',
                    paddingLeft: 10,
                  }}
                  type="input"
                  name="hotelStreetAddress"
                  defaultValue={receipt?.hotelStreetAddress}
                  onChange={event => {
                    debouncedSetReceiptField({
                      id: id,
                      field: 'hotelStreetAddress',
                      value: event.target.value,
                      receipts: receipts,
                    });
                  }}
                  isInvalid={!!receiptsErrors[id]?.hotelStreetAddress}
                />
                <Form.Control.Feedback type="invalid">{receiptsErrors[id]?.hotelStreetAddress}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="top-col">
            <Form.Group controlId="expenseItemHotelStateId">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                <div className="required"> State</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  styles={customStyles('225px')}
                  options={stateOptions}
                  onChange={event => {
                    handleStateChange([
                      {
                        fieldName: 'expenseItemHotelStateId',
                        fieldValue: event.value,
                      },

                      {
                        fieldName: 'selectedState',
                        fieldValue: event.label,
                      },
                    ]);
                  }}
                  value={stateOptions?.find(state => state.value === receipt.expenseItemHotelStateId)}
                />
              </div>
              {receiptsErrors[id]?.expenseItemHotelStateId && (
                <div className="error">{receiptsErrors[id]?.expenseItemHotelStateId}</div>
              )}
            </Form.Group>
          </div>
          <div className="top-col">
            <Form.Group controlId="expenseItemHotelCityId">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                <div className="required">City</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  isDisabled={
                    (!receipt?.selectedState && !cityInput.length && !receipt?.selectedCity) || isLoadingCities
                  }
                  onFocus={() => {
                    setCityInput(inputSave);
                    setSave('');
                  }}
                  onInputChange={(value, action) => {
                    if (action.action === 'input-change') {
                      setCityInput(value);
                    }
                  }}
                  styles={customStyles('225px')}
                  options={customCityOptions}
                  placeholder="Select a City"
                  blurInputOnSelect
                  value={customCityOptions?.find(city => city.value === receipt.expenseItemHotelCityId)}
                  onChange={event => {
                    setReceiptFields(id, [
                      {
                        fieldName: 'expenseItemHotelCityId',
                        fieldValue: event.value,
                      },

                      {
                        fieldName: 'selectedCity',
                        fieldValue: event.label,
                      },
                    ]);
                    setSave(event.label);
                    setCityInput(event.label);
                  }}
                />
              </div>
              {receiptsErrors[id]?.expenseItemHotelCityId && (
                <div className="error">{receiptsErrors[id]?.expenseItemHotelCityId}</div>
              )}
            </Form.Group>
          </div>
        </Row>
      </DisableClickOverlay>
      <DisableClickOverlay isDisabled={isBilledOrTransmitted(form) || isCR || form?.status === 'Voided'}>
        <Row
          className="top-row"
          style={{
            margin: '0',
          }}>
          <div
            className="top-col"
            style={{
              width: '40%',
              justifyContent: 'flex-start',
            }}>
            <div className="below-container">
              <div
                className="below-container"
                style={{
                  justifyContent: 'flex-start',
                  width: '300px',
                }}>
                <Form.Group controlId="checkInDate">
                  <Form.Label
                    style={{
                      marginTop: '15px',
                    }}>
                    <div className="required">Check In Date</div>
                  </Form.Label>
                  <Form.Control
                    style={{
                      width: '150px',
                      height: '30px',
                    }}
                    name="checkInDate"
                    type="date"
                    as="input"
                    defaultValue={receipt?.checkInDate}
                    onChange={event => {
                      debouncedSetReceiptField({
                        id: id,
                        field: 'checkInDate',
                        value: event.target.value,
                        receipts: receipts,
                      });
                    }}
                    isInvalid={!!receiptsErrors[id]?.checkInDate}
                  />
                  <Form.Control.Feedback type="invalid">{receiptsErrors[id]?.checkInDate}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </div>
          <div
            className="top-col"
            style={{
              justifyContent: 'flex-start',
            }}>
            <div
              className="below-container"
              style={{
                justifyContent: 'flex-start',
                width: '300px',
              }}>
              <Form.Group controlId="lastNightOfStay">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">Last Night of Stay</div>
                </Form.Label>
                <Form.Control
                  style={{
                    width: '150px',
                    height: '30px',
                  }}
                  name="lastNightOfStay"
                  type="date"
                  as="input"
                  defaultValue={receipt?.lastNightOfStay}
                  onChange={event => {
                    debouncedSetReceiptField({
                      id: id,
                      field: 'lastNightOfStay',
                      value: event.target.value,
                      receipts: receipts,
                    });
                  }}
                  isInvalid={!!receiptsErrors[id]?.lastNightOfStay}
                />
                <Form.Control.Feedback type="invalid">{receiptsErrors[id]?.lastNightOfStay}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="top-col">
            <Form.Group controlId="projectedDays">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Projected Days
              </Form.Label>
              <Form.Control
                style={{
                  width: '225px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  color: 'gray',
                  paddingLeft: '5px',
                }}
                as="input"
                name="projectedDays"
                readOnly
                disabled
                value={receipt?.projectedDays ? receipt?.projectedDays : '0'}
              />
            </Form.Group>
          </div>
        </Row>
      </DisableClickOverlay>
      <DisableClickOverlay isDisabled={isBilledOrTransmitted(form) || isCR || form?.status === 'Voided'}>
        <Row
          className="top-row"
          style={{
            margin: '5px 0 0 0',
          }}>
          <div
            className="top-col"
            style={{
              width: '40%',
              justifyContent: 'flex-start',
            }}>
            <div className="below-container">
              <div
                className="below-container"
                style={{
                  justifyContent: 'flex-start',
                  width: '300px',
                }}>
                <Form.Group controlId="dailyCharge">
                  <Form.Label
                    style={{
                      marginTop: '15px',
                    }}>
                    <div className="required">Daily Charge</div>
                  </Form.Label>
                  <InputGroup
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '150px',
                    }}>
                    <span
                      style={{
                        position: 'absolute',
                        left: '10px',
                        zIndex: 10000,
                      }}>
                      $
                    </span>
                    <Form.Control
                      key={`${id}dailyCharge`}
                      style={{
                        width: '150px',
                        height: '30px',
                        padding: 0,
                        paddingLeft: '25px',
                      }}
                      type="number"
                      step="0.01"
                      min="0"
                      name="dailyCharge"
                      value={dailyChargeValue}
                      onChange={event => {
                        setDailyChargeValue(event.target.value)
                        debouncedSetReceiptField({
                          id: id,
                          field: 'dailyCharge',
                          value: Number(event.target.value),
                          receipts: receipts,
                        });
                      }}
                      onBlur={handleDailyChargBlur}
                      isInvalid={!!receiptsErrors[id]?.dailyCharge}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>
          <div
            className="below-container"
            style={{
              justifyContent: 'flex-start',
              width: '30%',
            }}
          />
          <div className="top-col">
            <Form.Group controlId="expenseTotal">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Projected Amount
              </Form.Label>
              <InputGroup
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '150px',
                  opacity: '0.7',
                }}>
                <span
                  style={{
                    position: 'absolute',
                    left: 10,
                    top: 3,
                    zIndex: 10000,
                  }}>
                  $
                </span>
                <Form.Control
                  style={{
                    width: '225px',
                    height: '30px',
                    padding: 0,
                    border: '0px',
                    backgroundColor: 'white',
                    marginLeft: '20px',
                  }}
                  as="input"
                  name="location"
                  readOnly
                  disabled
                  value={
                    receipt?.projectedAmount
                      ? parseFloat(receipt?.projectedAmount).toFixed(2)
                      : parseFloat(0).toFixed(2)
                  }
                />
              </InputGroup>
            </Form.Group>
          </div>
        </Row>
      </DisableClickOverlay>
      <DisableClickOverlay isDisabled={isBilledOrTransmitted(form) || isCR || form?.status === 'Voided'}>
        <Row
          className="top-row"
          style={{
            margin: '5px 0 0 0',
            alignItems: 'start',
          }}>
          <div
            className="top-col"
            style={{
              width: '40%',
              justifyContent: 'flex-start',
            }}>
            <div
              className="below-container"
              style={{
                justifyContent: 'flex-end',
              }}>
              <div
                className="below-container"
                style={{
                  justifyContent: 'space-between',
                  width: '300px',
                }}>
                <Form.Group controlId="receiptAmount">
                  <Form.Label
                    style={{
                      marginTop: '15px',
                    }}>
                    <div className="required">Receipt Amount</div>
                  </Form.Label>
                  <InputGroup
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <span
                      style={{
                        position: 'absolute',
                        left: 10,
                        top: 3,
                        zIndex: 10000,
                      }}>
                      $
                    </span>
                    <Form.Control
                      key={`${id}receiptAmount`}
                      style={{
                        width: '150px',
                        height: '30px',
                        padding: 0,
                        paddingLeft: '25px',
                      }}
                      type="number"
                      step="0.01"
                      min="0"
                      name="receiptAmount"
                      value={receiptAmountValue}
                      onChange={event => {
                        setReceiptAmountValue(event.target.value)
                        debouncedSetReceiptField({
                          id: id,
                          field: 'receiptAmount',
                          value: Number(event.target.value),
                          receipts: receipts,
                        });
                      }}
                      onBlur={handleReceiptAmountBlur}
                      isInvalid={!!receiptsErrors[id]?.receiptAmount}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="markup">
                  <Form.Label
                    style={{
                      marginTop: '15px',
                    }}>
                    <div className="required">Markup</div>
                  </Form.Label>
                  <InputGroup
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <span
                      style={{
                        position: 'absolute',
                        right: '10px',
                        zIndex: 10000,
                      }}>
                      %
                    </span>
                    <Form.Control
                      style={{
                        width: '75px',
                        height: '30px',
                        padding: 0,
                        paddingLeft: '10px',
                      }}
                      type="number"
                      step="1"
                      min="0"
                      name="markup"
                      value={markupValue}
                      onChange={event => {
                        setMarkupValue(event.target.value)
                        debouncedSetReceiptField({
                          id: id,
                          field: 'markup',
                          value: event.target.value,
                          receipts: receipts,
                        });
                      }}
                      onBlur={handleMarkupBlur}
                      isInvalid={!!receiptsErrors[id]?.markup}
                    />
                  </InputGroup>

                  <Form.Control.Feedback type="invalid">{receiptsErrors[id]?.markup}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="top-col">
            <Form.Group controlId="expenseTotal">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Expense Total
              </Form.Label>
              <InputGroup
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '150px',
                  opacity: '0.7',
                }}>
                <span
                  style={{
                    position: 'absolute',
                    left: '10px',
                    zIndex: 10000,
                  }}>
                  $
                </span>
                <Form.Control
                  style={{
                    width: '225px',
                    height: '30px',
                    padding: 0,
                    border: '0px',
                    backgroundColor: 'white',
                    marginLeft: '20px',
                  }}
                  as="input"
                  name="location"
                  readOnly
                  disabled
                  value={
                    receipt?.expenseTotal ? parseFloat(receipt?.expenseTotal).toFixed(2) : parseFloat(0).toFixed(2)
                  }
                />
              </InputGroup>
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
          </div>
          {!receipt?.documentName && expenseId ? (
            <div className="top-col">
              <Form.Group
                controlId="receipt"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div>Receipt</div>
                </Form.Label>

                <Form.Control
                  name="receipt"
                  as="span"
                  style={{
                    border: 'none',
                    outline: 'none',
                    paddingLeft: 0,
                    height: 'auto',
                  }}>
                  <ExpenseReceiptUploader
                    func={uploadExpenseFile}
                    setReceiptFile={setReceiptFile}
                    receiptId={id}
                    uploadStart={() => setUploading(true)}
                    uploadEnd={() => setUploading(false)}
                    uploadError={() => {
                      setUploading(false);
                      setUploadError('Upload failed. Please ensure you have a network connection and try again.');
                    }}
                    expenseId={expenseId}
                  />
                </Form.Control>
              </Form.Group>
            </div>
          ) : null
          }
        </Row>
      </DisableClickOverlay>
      {receipt?.documentName && (
        <DocumentRow
          id={id}
          deleteDocumentFromReceipt={deleteDocumentFromReceipt}
          receipt={receipt}
          disabled={isBilledOrTransmitted(form) || form.status === 'Voided'}
        />
      )}
      <div className="gray-divider-light" />
    </div>
  );
};

export default Receipt;
