import { graphqlOperation, API } from 'aws-amplify';
import * as customQueries from '../../graphql/customQueries'
import * as mutations from '../../graphql/mutations';
import { setClientAccessList } from '../../features/ClientAccess/clientAccessSlice';
import store from '../../app/store';

export const getClientAccess = async () => {
    try {
        const body = await API.graphql({
            query: customQueries.listUsers
        });
        store.dispatch(setClientAccessList(body.data.listUsers))
    } catch (e) {
        console.log('GraphQL listUsers Error:', e);
    }
    return null;
};

export const updateUser = async (id, status) => {
    const update = {
        id,
        status,
    };
    try {
        await API.graphql(
            graphqlOperation(mutations.updateUser, { input: update }),
        );
        return true;
    } catch (e) {
        console.log('Update Status Error: ' + JSON.stringify(e));
        return false;
    }
};

export const updateUserRole = async (id, role) => {
    const update = { id, role };
    try {
        await API.graphql(
            graphqlOperation(mutations.updateUser, { input: update }),
        );
        return true;
    } catch (e) {
        console.log('Update Status Error: ' + JSON.stringify(e));
        return false;
    }
};
