import React from 'react';

import { Modal as BootModal, Button, Spinner } from 'react-bootstrap';

const Modal = ({
  title,
  body = '',
  primaryButtonTitle,
  secondaryButtonTitle,
  onClick,
  secondaryOnClick,
  isOpen = false,
  optionalClose = false,
  bodyContent = true,
  loading = false,
}) => (
  <BootModal show={isOpen}>
    <BootModal.Header>
      <BootModal.Title>{title}</BootModal.Title>
    </BootModal.Header>
    {!!bodyContent && <p style={{ padding: 20 }}>{body}</p>}
    <BootModal.Footer>
      <Button variant="primary" style={{ backgroundColor: '#da0f2e', borderColor: '#da0f2e ' }} onClick={onClick}>
        {loading ? <Spinner animation="border" size="sm" /> : primaryButtonTitle}
      </Button>
      {optionalClose && (
        <Button variant="secondary" onClick={secondaryOnClick}>
          {secondaryButtonTitle}
        </Button>
      )}
    </BootModal.Footer>
  </BootModal>
);

export default Modal;
