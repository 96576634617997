import React from 'react';
import {
  MdAirplanemodeActive,
  MdHotel,
  MdDirectionsCar,
  MdReceipt,
  MdEqualizer,
  MdList,
  MdSearch,
  MdCheck,
  MdFileUpload,
  MdWarning,
} from 'react-icons/md';
import './styles.scoped.css';

const DriverSolutionsMenuItem = ({ text, icon, onPress, disabled }) => {
  const getIcon = () => {
    switch (icon) {
      case 'airplanemode_active':
        return <MdAirplanemodeActive size={36} color="#da0f2e" />;
      case 'hotel':
        return <MdHotel size={36} color="#da0f2e" />;
      case 'directions_car':
        return <MdDirectionsCar size={36} color="#da0f2e" />;
      case 'receipt':
        return <MdReceipt size={36} color="#da0f2e" />;
      case 'file_upload':
        return <MdFileUpload size={36} color="#da0f2e" />;
      case 'check_circle':
        return <MdCheck size={36} color="#da0f2e" />;
      case 'equalizer':
        return <MdEqualizer size={36} color="#da0f2e" />;
      case 'search':
        return <MdSearch size={36} color="#da0f2e" />;
      case 'warning':
        return <MdWarning size={36} color="#da0f2e" />;
      default:
        break;
    }
  };

  return (
    <div
      style={disabled ? { opacity: '0.3' } : {}}
      onClick={() => !disabled && onPress()}
      className="menu-item"
      key={text}
    >
      {getIcon()}
      <span className="menu-text">{text}</span>
    </div>
  );
};

export default DriverSolutionsMenuItem;
