import React from "react";
import { Container } from 'react-bootstrap'
import "./styles.scoped.css";

const NotFound = () => {
    return (
        <Container className="wrap">
            <h1>404 Not found</h1>
        </Container>
    );
}

export default NotFound;