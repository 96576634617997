import { graphqlOperation, API } from 'aws-amplify';
import * as customQueries from '../../graphql/customQueries'
import * as mutations from '../../graphql/mutations';
import { setClientAccessList } from './clientAccessSlice';
import store from '../../app/store';


export const getClientAccess = async () => {
    let users = [];
    let token;
    const getPage = async () => {
        try {
            const response = await API.graphql({
                query: customQueries.listUsers,
                variables: { limit: 2000, nextToken: token },
            });
            return { items: response?.data?.listUsers?.items, nextToken: response?.data?.listUsers?.nextToken };
        } catch (e) {
            console.log('listApplications: ' + e.errors[0].message);
        }
    }
    do {
        const result = await getPage();
        const { items, nextToken } = result;
        token = nextToken;
        users = [...users, ...items];
    } while (token);
    store.dispatch(setClientAccessList(users))
};

export const updateUser = async (id, status) => {
    const update = {
        id,
        status,
    };
    try {
        await API.graphql(
            graphqlOperation(mutations.updateUser, { input: update }),
        );
        return true;
    } catch (e) {
        console.log('Update Status Error: ' + JSON.stringify(e));
        return false;
    }
};

export const updateUserRole = async (id, role) => {
    const update = { id, role };
    try {
        await API.graphql(
            graphqlOperation(mutations.updateUser, { input: update }),
        );
        return true;
    } catch (e) {
        console.log('Update Status Error: ' + JSON.stringify(e));
        return false;
    }
};
