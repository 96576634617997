import React, { useEffect, useState } from 'react';

import { Radio } from '@mui/material';
import { Row, Form, Button, Spinner } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import CheckTable, { checkColumns, checkColumnsSingle } from './CheckTable';
import Excel from '../../../assets/excel.png';
import PDF from '../../../assets/pdf.png';
import { Breadcrumbs, EdgeReportingWindow, SubHeader, ReportLoader } from '../../../components';
import { newDateFormatted } from '../../../utils';
import getCSVData from '../csv';
import {
  generateReport,
  generateSingleReport,
  getReportData,
  removeReportData,
  getCheckImage,
  getList,
  userHasAccess,
} from '../operations';
import { customStyles, formatSocial } from '../utils';

import './styles.scoped.css';

const CheckPDFForm = ({ setForm, form, setIsSingle, isSingle, driverStatuses, handleViewResults, navigate }) => {
  const handleChange = e => {
    const _form = { ...form, [e.target.name]: e.target.value };
    setForm(_form);
  };

  return (
    <Form
      noValidate
      onSubmit={() => { }}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <p style={{ marginTop: '20px' }}>Select to search by Single Driver or Check Number</p>
      <span>
        <span className="radio-group">
          <Radio
            style={{
              color: '#da0f2e',
              transform: 'scale(1)',
            }}
            onChange={() => setIsSingle(true)}
            checked={isSingle}
          />
          <span>Single Driver</span>
        </span>
        <span className="radio-group">
          <Radio
            style={{
              color: '#da0f2e',
              transform: 'scale(1)',
              marginLeft: 50,
            }}
            onChange={() => setIsSingle(false)}
            checked={!isSingle}
          />
          <span>Check Number</span>
        </span>
      </span>
      {isSingle ? (
        <div className="filters-row">
          <div className="filter-row">
            <Form.Group as={Row} controlId="EmpStatus" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Driver Status</Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  styles={customStyles('970px')}
                  options={driverStatuses}
                  isClearable
                  onChange={selectedOptions => {
                    console.log(selectedOptions);
                    const selectedCodes = selectedOptions.map(option => option.value);
                    setForm({
                      ...form,
                      EmpStatus: selectedCodes.join(','),
                    });
                  }}
                  value={form?.EmpStatus?.split(',').map(code => driverStatuses.find(option => option.value === code))}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden
                />
              </div>
            </Form.Group>
            x
          </div>

          <div className="filter-row">
            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Driver Last Name</Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                name="EmplLastName"
                value={form?.EmplLastName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label
                style={{
                  width: '120px',
                  marginLeft: '100px',
                  fontWeight: 'bold',
                }}>
                OR
              </Form.Label>
              <Form.Label
                style={{
                  width: '20px',
                  marginRight: '20px',
                }}>
                SSN
              </Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                name="SSNO"
                value={formatSocial(form?.SSNO || '')}
                onChange={handleChange}
              />
            </Form.Group>
          </div>

          <div className="filter-row">
            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Driver First Name</Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                name="EmplFirstName"
                value={form?.EmplFirstName}
                onChange={handleChange}
              />
            </Form.Group>
          </div>

          <div className="filter-row">
            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Driver Number</Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                  marginRight: '20px',
                }}
                type="number"
                as="input"
                name="EmpId"
                value={form?.EmpId}
                onChange={handleChange}
              />
            </Form.Group>
          </div>

          <div
            className="filter-row"
            style={{
              marginTop: '30px',
            }}>
            <Form.Group as={Row} controlId="FromPerEnddate" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Period End Date</Form.Label>
              <Form.Control
                style={{
                  width: '150px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                type="date"
                name="FromPerEnddate"
                value={form?.FromPerEnddate}
                onChange={handleChange}
                max={form?.ToPerEnddate || undefined}
              />
            </Form.Group>
            <Form.Group as={Row} controlId="ToPerEnddate" style={{ alignItems: 'center' }}>
              <Form.Label
                style={{
                  width: '20px',
                  marginLeft: '30px',
                  marginRight: '20px',
                }}>
                To
              </Form.Label>
              <Form.Control
                style={{
                  width: '150px',
                  height: '30px',
                }}
                as="input"
                type="date"
                name="ToPerEnddate"
                value={form?.ToPerEnddate}
                onChange={handleChange}
                min={form?.FromPerEnddate || undefined}
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
          </div>
        </div>
      ) : (
        <div className="filter-row">
          <Form.Group as={Row} controlId="FromEmplLastName" style={{ alignItems: 'center' }}>
            <Form.Label style={{ width: '100px' }}>Check #</Form.Label>
            <Form.Control
              style={{
                width: '360px',
                height: '30px',
                marginRight: '20px',
              }}
              as="input"
              name="checkId"
              value={form?.checkId}
              onChange={handleChange}
              type="number"
            />
          </Form.Group>
        </div>
      )}
      <div className="buttons-row">
        <Button onClick={handleViewResults} style={{ marginRight: '20px', width: '100px' }}>
          <span>VIEW</span>
        </Button>

        <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
          <span>CANCEL</span>
        </Button>
      </div>
    </Form>
  );
};

const CheckPdf = () => {
  const {
    clientMenu: { menuItems },
  } = useSelector(state => state.edgeReporting);
  const { user } = useSelector(state => state.admin);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [form, setForm] = useState({});
  const [loadingResults, setLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isSingle, setIsSingle] = useState(true);
  const [selectedCheck, setSelectedCheck] = useState(null);
  const [driverStatuses, setDriverStatuses] = useState([]);

  useEffect(() => {
    if (menuItems) {
      // eslint-disable-next-line no-undef
      if (!userHasAccess(user, menuItems.find(i => i.to === window.location.pathname)?.id)) navigate('/');
    }
  }, [menuItems]);

  useEffect(() => {
    getList('driver-status').then(res => {
      const statuses = res.map(({ REF_CODE, REF_DESCRIPTION }) => ({
        label: REF_DESCRIPTION,
        value: REF_CODE,
      }));
      setDriverStatuses(statuses);
    });
  }, []);

  const fileName = `edge-reporting-check-${newDateFormatted.toString()}.csv`;

  const handleViewResults = async () => {
    setLoadingResults(true);
    const {
      FromPerEnddate = '',
      ToPerEnddate = '',
      EmpStatus = '',
      SSNO = '',
      EmplLastName = '',
      EmplFirstName = '',
      EmpId,
      checkId,
    } = form;
    try {
      if (isSingle) {
        const response = await generateReport({
          endpoint: 'check-driver-report',
          payload: {
            FromPerEnddate,
            ToPerEnddate,
            EmpStatus,
            SSNO,
            EmplLastName,
            EmplFirstName,
            EmpId,
          },
        });
        if (response.location && response.location !== '') {
          const reportData = await getReportData(response.location);
          if (reportData?.length) {
            await removeReportData(response.location);
            setLoadingResults(false);
            setData(reportData);
            setShowResults(true);
          }
        } else {
          setLoadingResults(false);
          setLoadingResults(false);
          // eslint-disable-next-line no-undef
          alert('No data found matching your criteria.');
        }
      } else {
        const response = await generateSingleReport({
          endpoint: `check-report?checkId=${checkId}`,
        });
        if (response?.length) {
          setLoadingResults(false);
          setData(response);
          setShowResults(true);
        } else {
          setLoadingResults(false);
          setLoadingResults(false);
          // eslint-disable-next-line no-undef
          alert('No data found matching your criteria.');
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-undef
      alert('No data found matching your criteria.');
      setLoadingResults(false);
    }
    setLoadingResults(false);
  };

  const handleExportPDF = async () => {
    setLoadingResults(true);
    const id = isSingle ? selectedCheck?.Check_ID : data[0].Check_ID;
    if (isSingle && !selectedCheck) {
      // eslint-disable-next-line no-undef
      alert('Please select a check');
      return;
    }
    const pdf = await getCheckImage(id);
    setLoadingResults(false);
    if (pdf.includes('404')) {
      // eslint-disable-next-line no-undef
      alert('PDF not available for this check');
      return;
    }
    // eslint-disable-next-line no-undef
    const pdfWindow = window.open('');
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${encodeURI(pdf)}'></iframe>`,
    );
  };

  const setSelectedRow = check => {
    setSelectedCheck(check);
  };

  return (
    <>
      <SubHeader
        text="EDGE Reporting"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo={false}
        emailAddress={process.env.REACT_APP_EDGE_REPORTING_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle="Reporting Home"
          middleTitle={showResults && 'Check PDF'}
          midItemAction={() => setShowResults(false)}
          rightTitle={showResults ? `Viewing Report ${isSingle ? '(Driver)' : '(Check Detail)'}` : 'Check PDF'}
          route="/edge-reporting"
        />
        {data?.length && showResults ? (
          <span>
            <img
              onClick={() => handleExportPDF()}
              src={PDF}
              alt="export-to-pdf"
              style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }}
            />
            <CSVLink
              filename={fileName}
              data={getCSVData(data, Object.keys(data[0]).length < 16 ? checkColumnsSingle : checkColumns)}>
              <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
            </CSVLink>
          </span>
        ) : null}
      </div>
      {loadingResults && <ReportLoader didAbort={() => setLoadingResults(false)} />}
      {showResults ? (
        <>
          <CheckTable data={data} loading={loadingResults} rowSelected={setSelectedRow} isSingle={isSingle} />
          <div className="buttons-row">
            <Button onClick={() => setShowResults(false)} style={{ marginRight: '20px', width: '100px' }}>
              <span>BACK</span>
            </Button>

            <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
              <span>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <EdgeReportingWindow
          containerStyle={{
            border: '2px solid #343a40',
          }}
          windowContent={CheckPDFForm({
            form,
            setForm,
            setIsSingle,
            isSingle,
            driverStatuses,
            handleViewResults,
            navigate,
          })}
          windowTitle="Criteria"
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )}
    </>
  );
};

export default CheckPdf;
