import React, { useEffect, useState } from "react";

import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { Button, Col, Container, Row } from 'react-bootstrap';
import { Radio } from '@mui/material';

import '../styles.scoped.css';

const MFACode = ({ getCognitoUser, nextStep, requiredAttributes, userData }) => {
    const navigate = useNavigate();
    const [error, setErrorMessage] = useState('');

    const params = new URLSearchParams(location.search);
    const [dqf, email] = [params.get('dqf') || '', params.get('email') || ''];
    const { route } = useAuthenticator(context => [context.route]);

    const handleVerifyUser = async () => {
        const currentLoggedEmail = (await Auth.currentSession()).getIdToken().payload.email;

        if (email === currentLoggedEmail) {
            navigate(`/driver-qualification/view-dqf/${dqf}`, {
                mobileView: true,
            });
        } else {
            getCognitoUser();
        }
    };

    const sendCustomChallenge = async payload => {
        Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
        Auth.sendCustomChallengeAnswer(payload?.user, payload?.code)
            .then(user => {
                if (user) {
                    nextStep({
                        ...userData,
                        nextStep: 'VerifyCode',
                    })
                }
            })
            .catch(e => {
                console.log('e', e);
                if (e.code === 'LimitExceededException') {
                    setErrorMessage(e.code);
                }
                if (e.code === 'NotAuthorizedException') {
                    setErrorMessage(e.code);
                }
            });
    };

    useEffect(() => {
        if (route === 'authenticated') {
            handleVerifyUser();
        }
    }, [route, navigate])

    return (
        <>
            {error === 'LimitExceededException' || error === 'NotAuthorizedException' ? (
                <Container fluid>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-center mt-5 w-25">
                            <p className="border border-danger m-2 p-1 text-center text-danger">{error === 'LimitExceededException' ? 'Attempt limit exceeded, please try after some time.' : 'Invalid user, please try again.'}</p>
                        </Col>
                        <Col xs={12} className="d-flex align-items-center justify-content-center mb-4">
                            <Button
                                onClick={() => {
                                    window.location.reload();
                                }}
                                type="button"
                                className="btn-md">
                                Try Again
                            </Button>
                        </Col>
                    </Row>
                </Container>
            ) :
                (<Container fluid>
                    <Row>
                        <Col xs={12} className="mt-5">
                            <Row className="d-flex justify-content-center text-center">
                                <section className="fs-1 font-weight-bold">
                                    To safeguard our drivers' privacy an access code will be sent to you.
                                    <br />
                                    How do you want to recieve the code?
                                </section>
                            </Row>
                        </Col>
                        <Col xs={12} style={{ width: '375px' }}>
                            <Row>
                                {error === 'CodeMismatchException' && (
                                    <p className="border border-danger p-2 text-danger">Invalid verification code provided, please try again</p>
                                )}
                            </Row>
                            {requiredAttributes.map(i => (
                                <Row key={i.code} className="d-flex justify-content-center text-center">
                                    <div className="m-2">
                                        <Radio
                                            style={{
                                                color: '#da0f2e',
                                                transform: 'scale(1)',
                                            }}
                                            onChange={() => {
                                                nextStep({
                                                    ...userData,
                                                    code: i.code,
                                                });
                                            }}
                                            checked={userData?.code === i.code ? true : false}
                                        />
                                        <span>Send Code via {i.code}</span>
                                        <p className="text-secondary">
                                            {i.code === 'SMS'
                                                ? `Cell number on record: ${i.value}`
                                                : null
                                                    || i.code === 'EMAIL'
                                                    ? `Email on record: ${i.value}`
                                                    : null}
                                        </p>
                                    </div>
                                </Row>
                            ))}
                            <Row className="d-flex align-items-center justify-content-center">
                                <Button
                                    disabled={!userData?.code}
                                    onClick={() => {
                                        sendCustomChallenge(userData);
                                        nextStep({
                                            ...userData,
                                            code: ''
                                        })
                                    }}
                                    type="button"
                                    className="btn-md">
                                    Send Code
                                </Button>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row className="d-flex justify-content-center mt-4 text-center">
                                <div className="fs-1 font-weight-bold">
                                    If there is a change in the email or phone number or you need assistance <br />
                                    Contact the Team at 888-213-7483.
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                )}
        </>

    )
}

export default MFACode;