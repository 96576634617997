/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://6ttxbysbnnesvm22qprychq47y.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-s4bqam4hdrb6rjfkjlt6yvawwa",
    "aws_cognito_identity_pool_id": "us-west-2:7b4e2dfd-7cf4-4866-8b5f-c4cb29a57336",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_kQGTue4Mp",
    "aws_user_pools_web_client_id": "6i84kdpc2i986qr26qsipg8o79",
    "oauth": {
        "domain": "centerlineportal-production.auth.us-west-2.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://portal.centerlinedrivers.com/",
        "redirectSignOut": "https://protal.centerlinedrivers.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "centerlineportal-storage143719-production",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
