import React, { useEffect, useMemo, useState } from 'react';

import { Row, Form, Button, Spinner } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import DriverSearchTable, { driverSearchColumns, driverSearchColumnsSummary } from './DriverSearchTable';
import Excel from '../../../assets/excel.png';
import { Breadcrumbs, EdgeReportingWindow, SubHeader, ReportLoader } from '../../../components';
import { newDateFormatted } from '../../../utils';
import getCSVData from '../csv';
import { userHasAccess, generateReport, getReportData, removeReportData, getList } from '../operations';
import { customStyles } from '../utils';

import './styles.scoped.css';

const DriverSearch = () => {
  const navigate = useNavigate();
  const {
    clientMenu: { menuItems },
  } = useSelector(state => state.edgeReporting);
  const { user } = useSelector(state => state.admin);
  const [driverStatuses, setDriverStatuses] = useState([]);
  const [data, setData] = useState([]);
  const [form, setForm] = useState({ ReportFormat: 'Summary' });
  const [loadingResults, setLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isLoadingStatuses, setIsLoadingStatuses] = useState(false);

  const viewFullPii = useMemo(() => user?.appAccess?.items?.find(item => item.appId.id === '4')?.roleId?.viewFullPii, [
    user,
  ]);

  useEffect(() => {
    if (menuItems) {
      // eslint-disable-next-line no-undef
      if (!userHasAccess(user, menuItems.find(i => i.to === window.location.pathname)?.id)) navigate('/');
    }
  }, [menuItems, navigate, user]);

  useEffect(() => {
    setIsLoadingStatuses(true);
    getList('driver-status').then(res => {
      const statuses = res.map(({ REF_CODE, REF_DESCRIPTION }) => ({
        label: REF_DESCRIPTION,
        value: REF_CODE,
      }));
      setIsLoadingStatuses(false);
      setDriverStatuses(statuses);
    });
  }, []);

  const fileName = `edge-reporting-driver-search-${newDateFormatted.toString()}.csv`;

  const handleViewResults = async () => {
    setLoadingResults(true);
    const { EmpStatus, EmplLastName, EmplFirstName, EmpId, ReportFormat } = form;
    try {
      const response = await generateReport({
        endpoint: 'driver-search-report',
        payload: {
          EmpStatus,
          EmplLastName,
          EmplFirstName,
          EmpId,
          ReportFormat,
          viewFullPii,
        },
      });
      if (response.location && response.location !== '') {
        const reportData = await getReportData(response.location);
        if (reportData?.length) {
          await removeReportData(response.location);
          setLoadingResults(false);
          setData(reportData);
          setShowResults(true);
        }
      } else {
        setLoadingResults(false);
        setLoadingResults(false);
        // eslint-disable-next-line no-undef
        alert('No data found matching your criteria.');
      }
    } catch (error) {
      console.log(error);
      setLoadingResults(false);
    }
  };

  const DriverSearchForm = ({ setForm, form }) => {
    const handleChange = e => {
      const _form = { ...form, [e.target.name]: e.target.value };
      setForm(_form);
    };
    return (
      <Form
        noValidate
        onSubmit={() => { }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <div className="filters-row">
          <div className="filter-row">
            <Form.Group as={Row} controlId="EmpStatus" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Driver Status</Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  styles={customStyles('360px')}
                  options={driverStatuses}
                  isClearable
                  onChange={selectedOptions => {
                    const selectedCodes = selectedOptions.map(option => option.value);
                    setForm({
                      ...form,
                      EmpStatus: selectedCodes.join(','),
                    });
                  }}
                  value={form?.EmpStatus?.split(',').map(code => driverStatuses.find(option => option.value === code))}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingStatuses}
                />
              </div>
            </Form.Group>
          </div>
          <div className="filter-row">
            <Form.Group as={Row} controlId="EmplLastName" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Driver Last Name</Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                name="EmplLastName"
                value={form?.EmplLastName}
                onChange={handleChange}
              />
            </Form.Group>
          </div>

          <div className="filter-row">
            <Form.Group as={Row} controlId="EmplFirstName" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Driver First Name</Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                name="EmplFirstName"
                value={form?.EmplFirstName}
                onChange={handleChange}
              />
            </Form.Group>
          </div>

          <div className="filter-row">
            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Driver Number</Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                type="number"
                name="EmpId"
                value={form?.EmpId}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="filter-row">
            <Form.Group as={Row} controlId="ReportFormat" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Report Format</Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  styles={customStyles('360px')}
                  options={[{ label: 'Detail', value: 'Detail' }, { label: 'Summary', value: 'Summary' }]}
                  isClearable
                  onChange={(selectedOption, action) => {
                    const _form = {
                      ...form,
                      ReportFormat: action.action === 'clear' ? '' : selectedOption.value,
                    };
                    setForm(_form);
                  }}
                  name="ReportFormat"
                  value={{
                    label: form?.ReportFormat,
                    value: form?.ReportFormat,
                  }}
                />
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="buttons-row">
          <Button onClick={() => handleViewResults()} style={{ marginRight: '20px', width: '100px' }}>
            <span>VIEW</span>
          </Button>

          <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
            <span>CANCEL</span>
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <>
      <SubHeader
        text="EDGE Reporting"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Driver Search?"
        emailAddress={process.env.REACT_APP_EDGE_REPORTING_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle="Reporting Home"
          middleTitle={showResults && 'Driver Search'}
          midItemAction={() => setShowResults(false)}
          rightTitle={showResults ? `Viewing Report (${form.ReportFormat})` : 'Driver Search'}
          route="/edge-reporting"
        />
        {showResults && (
          <CSVLink
            filename={fileName}
            data={getCSVData(data, form.ReportFormat === 'Summary' ? driverSearchColumnsSummary : driverSearchColumns)}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
        )}
      </div>
      {loadingResults && <ReportLoader didAbort={() => setLoadingResults(false)} />}
      {showResults ? (
        <>
          <DriverSearchTable data={data} loading={loadingResults} summaryOrDetail={form.ReportFormat} />
          <div className="buttons-row">
            <Button onClick={() => setShowResults(false)} style={{ marginRight: '20px', width: '100px' }}>
              <span>BACK</span>
            </Button>

            <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
              <span>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <EdgeReportingWindow
          containerStyle={{
            border: '2px solid #343a40',
          }}
          windowContent={DriverSearchForm({ form, setForm })}
          windowTitle="Criteria"
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )}
    </>
  );
};

export default DriverSearch;
