import { API, Storage, graphqlOperation } from 'aws-amplify';
import { getReportResult, getReport } from '../../graphql/customQueries';

export const userHasAccess = (user, id) => {
  const myEnv = { ...process.env };
  const edgeAppAccessItems = user?.appAccess?.items;
  const role = edgeAppAccessItems?.find(app => app?.appId?.id === '4')?.roleId
    ?.id;
  const key = `REACT_APP_EDGE_REPORTING_ROLE_${role}`;
  const reportString = myEnv[key];
  const reportArray = reportString?.split(',');
  return reportArray?.includes(String(id));
};

export const getDrivers = async () => {
  try {
    const response = await API.get('centerlineportal', 'drivers');
    if (response.response_code === 200) {
      return response.response_body;
    }
  } catch (e) {
    if (e?.message?.includes('404')) {
      return [];
    }
    console.log(e);
  }
};

export const getCheckSorts = async () => {
  try {
    const response = await API.get('centerlineportal', 'checksortone-codes');
    if (response.response_code === 200) {
      return response.response_body;
    }
  } catch (e) {
    if (e?.message?.includes('404')) {
      return [];
    }
    console.log(e);
  }
};

export const getList = async (apiName = '') => {
  try {
    const response = await API.get('centerlineportal', apiName);
    if (response.response_code === 200) {
      return response.response_body;
    }
  } catch (e) {
    if (e?.message?.includes('404')) {
      return [];
    }
    console.log(e);
  }
};

export const generateSingleReport = async ({ endpoint, payload }) => {
  try {
    const response = payload
      ? await API.post('centerlineportal', endpoint, {
        body: payload,
      })
      : await API.get('centerlineportal', `${endpoint}`);
    if (response.response_code === 200) {
      return response.response_body;
    }
  } catch (e) {
    console.log(e);
    if (e?.message?.includes('404')) {
      return [];
    } else {
      throw Error(e?.message ? e?.message : e);
    }
  }
};

export const generateReport = async ({ endpoint, payload }) => {
  return new Promise((resolve, reject) => {
    API.graphql({
      query: getReport,
      variables: {
        reportName: endpoint,
        criteria: JSON.stringify(payload),
      },
    })
      .then(response => {
        const { statusCode, id } = response?.data?.report;
        if (statusCode === 200) {
          const subscription = API.graphql(
            graphqlOperation(getReportResult, { id }),
          ).subscribe({
            next: result => {
              // Stop receiving data updates from the subscription
              subscription.unsubscribe();
              resolve(result?.value?.data?.onReportResult);
            },
          });
        } else {
          reject('Unable to generate report');
        }
      })
      .catch(error => {
        console.log('error: ', error);
      });
  });
};

export const getCheckImage = async checkId => {
  try {
    const response = await API.get(
      'centerlineportal',
      `check-pdf?checkId=${checkId}`,
    );
    if (response.response_code === 200) {
      return response.response_body?.pdfBase64;
    }
  } catch (e) {
    if (e?.message?.includes('404')) {
      return e?.message;
    }
    console.log(e);
  }
};

export const getInvoiceImage = async invoiceId => {
  try {
    const response = await API.get(
      'centerlineportal',
      `invoice-pdf?invoiceId=${invoiceId}`,
    );
    if (response.response_code === 200) {
      return response.response_body?.pdfBase64;
    }
  } catch (e) {
    if (e?.message?.includes('404')) {
      return e?.message;
    }
    console.log(e);
  }
};

export const getReportData = async location => {
  const config = {
    expires: process.env.REACT_APP_AMPLIFY_STORAGE_GET_EXPIRES,
    level: 'public',
    download: false,
  };

  try {
    const response = await Storage.get(location, config);
    const fileResponse = await fetch(response);
    if (fileResponse.status === 200) {
      const jsonData = await fileResponse.json();
      return jsonData?.length || jsonData?.pdfBase64 ? jsonData : [];
    } else {
      console.log('Unable to fetch JSON file.');
      return [];
    }
  } catch (error) {
    console.log('Error:', error);
  }
};

export const removeReportData = async location => {
  const config = {
    expires: process.env.REACT_APP_AMPLIFY_STORAGE_GET_EXPIRES,
    level: 'public',
    download: false,
  };

  try {
    const response = await Storage.remove(location, config);
  } catch (error) {
    console.log('Error:', error);
  }
};
