import React from 'react';
import './styles.scoped.css';
import { useLocation } from 'react-router-dom';
import useIsMobileScreen from '../../hooks/useCheckIsMobile';

const Footer = () => {
  const location = useLocation();
  const isMobile = useIsMobileScreen();

  return (
    <>
      <div style={{ height: 50 }} />
      <span className={`footer ${isMobile ? 'mobile' : ''}`}>
        <span className={`footer-item ${isMobile ? 'mobile' : ''}`}>
          Our Brands: TrueBlue • PeopleReady • PeopleScout • Staff Management • SIMOS
        </span>
        <span className={`footer-item ${isMobile ? 'mobile' : ''}`}>
          {location.pathname.includes('driver-qualification') && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.PUBLIC_URL}/driverQualificationLicense.html`}>
              License Agreement
            </a>
          )}
          {location.pathname.includes('quote') && (
            <a target="_blank" rel="noopener noreferrer" href={`${process.env.PUBLIC_URL}/quoteLicense.html`}>
              License Agreement
            </a>
          )}
        </span>
        <span className={`footer-item ${isMobile ? 'mobile' : ''}`}>
          All rights reserved © TrueBlue {new Date().getFullYear()}
        </span>
      </span>
    </>
  );
};

export default Footer;
