import { formatIsoToDate } from '../../../utils';

export const getCSVAMC = data => {
  const csvData = [
    [
      'AMC Code',
      'BillTo Number',
      'AsgnBranch Code Detail',
      'Invoice Number',
      'Invoice Date',
      'Check Number',
      'Check Date',
      'Void',
      'Invoice Type',
      'Transaction Type',
      'Pay Code Type',
      'Bill Hours',
      'Bill Rate',
      'Pay Hours',
      'Pay Rate',
      'US FICA Burden',
      'Job ID',
      'ASgn PC',
      'Period Start Date',
      'Period End Date',
      'Job Position',
      'PO Number',
      'BillTo Name',
      'Applicant Name',
      'AsgnLOB Detail',
      'Tax Amount',
      'Discount Amount',
      'Bill Amount',
      'Pay Amount',
      'Applicant Empl Number',
      'Branch Name',
      'Bill Department',
      'Customer ID',
      'Assgn Start Date',
      'Assgn End Date',
      'Customer City',
      'Customer State',
      'Customer ZIP',
      'Customer SPC',
    ],
  ];
  let i;
  for (i = 0; i < data.length; i += 1) {
    csvData.push([
      `${data[i].AccountMasterCode}`,
      `${data[i].Customer_BillToNumber}`,
      `${data[i].AsgnBranch_Code_Detail}`,
      `${data[i].Invoice_Number}`,
      `${formatIsoToDate(data[i].Invoice_Date)}`,
      `${data[i].Check_Number}`,
      `${formatIsoToDate(data[i].Check_Date)}`,
      `${data[i].void}`,
      `${data[i].Invoice_Type}`,
      `${data[i].Transaction_Type}`,
      `${data[i].Pay_Code_Type}`,
      `${data[i].Bill_Hours}`,
      `${data[i].Bill_Rate}`,
      `${data[i].Pay_Hours}`,
      `${data[i].Pay_Rate}`,
      `${data[i].US_FICA_Burden}`,
      `${data[i].Job_Id}`,
      `${data[i].ASS_PC}`,
      `${data[i].Period_StartDate}`,
      `${data[i].Period_EndDate}`,
      `${data[i].Job_Position}`,
      `${data[i].PO_Number}`,
      `${data[i].T_INVOICE_COMPANY_NAME}`,
      `${data[i].Applicant_Name}`,
      `${data[i].AsgnLineOfBusiness_Detail}`,
      `${data[i].TaxAmt}`,
      `${data[i].DisAmt}`,
      `${data[i].Bill_Amount}`,
      `${data[i].Pay_Amount}`,
      `${data[i].Applicant_Id}`,
      `${data[i].T_INVOICE_COMPANY_NAME}`,
      `${data[i].Customer_BillToDepartment}`,
      `${data[i].CUST_ID}`,
      `${data[i].ASS_STARTDATE}`,
      `${data[i].ASS_ENDDATE}`,
      `${data[i].CUST_CITY}`,
      `${data[i].CUST_STATE}`,
      `${data[i].CUST_ZIP}`,
      `${data[i].CUST_SALESPC}`,
    ]);
  }
  return csvData;
};

export const getCSVMY = data => {
  const csvData = [
    [
      'Branch',
      'Branch Name',
      'Customer Name',
      'Customer Number',
      'Year',
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
      'Totals',
    ],
  ];
  let i;
  for (i = 0; i < data.length; i += 1) {
    csvData.push([
      `${data[i].Branch}`,
      `${data[i].BranchName}`,
      `${data[i].CustomerName}`,
      `${data[i].CustomerNumber}`,
      `${data[i].YR}`,
      `${data[i].M1}`,
      `${data[i].M2}`,
      `${data[i].M3}`,
      `${data[i].M4}`,
      `${data[i].M5}`,
      `${data[i].M6}`,
      `${data[i].M7}`,
      `${data[i].M8}`,
      `${data[i].M9}`,
      `${data[i].M10}`,
      `${data[i].M11}`,
      `${data[i].M12}`,
      `${data[i].Bill_Amount}`,
    ]);
  }
  return csvData;
};
