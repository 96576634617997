import React from 'react';

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import {
  Col,
  Container,
  Row,
  Table
} from 'react-bootstrap';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import TableFilter from '../TableFilter';
import TablePagination from '../TablePagination';
import './styles.scoped.css';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

function getSortIcon(isSorted, isSortedDesc) {
  let iconProp = faSort;
  if (isSorted) {
    iconProp = isSortedDesc ? faSortDown : faSortUp;
  }
  return <FontAwesomeIcon icon={iconProp} className="ml-1 fa-sm" />;
}

export default function CommonTable({
  data,
}) {
  const columnClassName = 'align-middle text-nowrap';
  const memoData = React.useMemo(() => data, [data]);
  const skipPageResetRef = React.useRef();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date and Time',
        accessor: 'eventTime',
        className: columnClassName,
        Cell: instance => <>{`${dayjs.utc(instance.value).local().format('MM/DD/YYYY h:mmA')}`}</>
      },
      {
        Header: 'User',
        accessor: 'user',
        className: columnClassName,
        Cell: instance => <>
          {`${instance?.row?.original?.objectData === "{Status=Transmitted to Billed}" ? "System" : instance?.row?.original?.user?.name}` && `${instance?.row?.original?.user?.name}` === null ? "" : instance?.row?.original?.user?.name}
        </>
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: columnClassName,
        Cell: instance => <>{`${instance?.row?.original?.objectData.replace("{Status=", " ").replace("}", " ")}`}</>
      },
      {
        Header: 'Event',
        accessor: 'event',
        className: columnClassName,
      },
      {
        Header: 'Transmit Result',
        accessor: '',
        className: columnClassName,
        Cell: instance => <>
          {`${instance?.row?.original?.objectData === "{Status=Transmitted}" ? "Success" : ""}`}
        </>,
      },

    ],
    [])
    ;

  const tableInstance = useTable(
    {
      columns,
      data: memoData,
      initialState: {
        sortBy: [
          {
            id: 'eventTime',
            desc: false,
          },
        ],
      },
      sortDescFirst: false,
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
      autoResetGlobalFilter: !skipPageResetRef.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setFilter,
    setGlobalFilter,
  } = tableInstance;
  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>{column.canSort ? getSortIcon(column.isSorted, column.isSortedDesc) : null}</span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}>
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  function filter(event, columnName) {
    const filterColumn = columns.find(column => column.Header === columnName);
    if (filterColumn) {
      setFilter(filterColumn.accessor, event.target.value);
    } else {
      setGlobalFilter(event.target.value);
    }
  }

  return (
    <Container fluid>
      <Row className='header'>
        <Col>
          <TableFilter columns={columns} onSearchChange={filter} />
        </Col>
        <Col className="d-flex justify-content-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row style={{ backgroundColor: 'white' }}><h1 className="title">Expense History</h1></Row>
      <Row className='table-row'>
        <Col className="table-body">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
        </Col>
      </Row>
    </Container >
  );
}
