import React, { useEffect, useState } from 'react';
import { CONNECTION_STATE_CHANGE } from '@aws-amplify/pubsub';
import { Hub } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from 'react-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import "./styles.scoped.css";

const ReportLoader = ({ didAbort }) => {

    useEffect(() => {
        setTimeout(() => {
            setConnectionState('timeout');
        }, process.env.REACT_APP_GENERATE_REPORT_TIMEOUT_MINS * 60000);
    }, []);

    Hub.listen('api', (data) => {
        const { payload } = data;
        if (payload.event === CONNECTION_STATE_CHANGE) {
            const connectionState = payload.data.connectionState;
            setConnectionState(connectionState);
        }
    });

    const [connectionState, setConnectionState] = useState();

    return (
        <div className="overlay-box">
            <div className="spinner-wrap">
                <FontAwesomeIcon onClick={didAbort} icon={faTimes} className="fa-lg" style={{ position: 'absolute', right: 10, top: 10 }} />
                {connectionState !== 'timeout' ?
                    <>
                        <span className='spinner-text'>
                            <Spinner animation="border" className='spinner' />
                            {connectionState === 'Connected' ? <span>Standby, <br />Fetching Data...</span> : !connectionState ? null : <span>Connecting...</span>}
                        </span>
                    </> :
                    <span className='spinner-text'>{connectionState === 'timeout' && 'Report generation timed out. Please narrow your search terms and try again.'}</span>}
            </div>
        </div>
    );
}

export default ReportLoader;
