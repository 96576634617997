import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import './styles.scoped.css';

function formatIsoToDate(iso) {
    if (!iso) {
        return null;
    }
    return dayjs(iso).format('MM/DD/YYYY');
}

const DriverQualificationHeader = ({
    data,
    dqOwner,
    checkOwnership,
    tierData,
}) => {
    return (
        <Row>
            <Col>
                <Row>
                    <Col>Driver </Col>
                    <Col className="text-secondary">{data?.driverName}</Col>
                </Row>
                <br />
                <Row>
                    <Col>Generated by</Col>
                    <Col className="text-secondary">
                        {data?.createUserFullName}
                    </Col>
                </Row>
                <br />
                {!checkOwnership ? (
                    <>
                        <Row>
                            <Col>DQF Specialist</Col>
                            <Col className="text-secondary">
                                No Current Owner
                            </Col>
                        </Row>
                        <br />
                        {data?.tierDescription && (
                            <Row>
                                <Col>Tier Desc.</Col>
                                <Col className="text-secondary">
                                    {data?.tierDescription}
                                </Col>
                            </Row>
                        )}
                    </>
                ) : (
                    <Row>
                        <Col>DQF Specialist</Col>
                        <Col className="text-secondary">{dqOwner}</Col>
                    </Row>
                )}
            </Col>
            <Col>
                <Row>
                    <Col>Job Order</Col>
                    <Col className="text-secondary">{data?.jobId}</Col>
                </Row>
                <br />
                <Row>
                    <Col>Job Start Date</Col>
                    <Col className="text-secondary">
                        {formatIsoToDate(data?.jobStartDate)}
                    </Col>
                </Row>
            </Col>
            <Col>
                <Row>
                    <Col>Status</Col>
                    <Col className="text-secondary">{data?.status}</Col>
                </Row>
                <br />
                <Row>
                    <Col>Created</Col>
                    <Col className="text-secondary">
                        {formatIsoToDate(data?.createdAt)}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>Branch</Col>
                    <Col className="text-secondary">{data?.branch}</Col>
                </Row>
                <br />
                <Row>
                    <Col>Tier</Col>
                    <Col className="text-secondary">
                        {
                            tierData?.filter(
                                tier => tier.value === data?.tier,
                            )?.[0]?.label
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default DriverQualificationHeader;
