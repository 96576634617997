import { API } from 'aws-amplify';

import { listCarRentals, listAuditbyObjectNamebyObjectId } from '../../../../graphql/queries';

export const getCarRentals = async () => {
  try {
    const response = await API.graphql({
      query: listCarRentals,
    });
    return response.data.listCarRentals;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getAudit = async (id) => {
  try {
    const response = await API.graphql({
      query: listAuditbyObjectNamebyObjectId,
      variables: {
        objectName: "Expense",
        objectId: { eq: id },
        limit: 1000
      },
    })
    return response?.data?.listAuditbyObjectNamebyObjectId?.items;
  } catch (e) {
    console.log('error', e)
  }
}