import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { Row, Form, Button, Spinner } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import GrossMarginAMCTable, { grossMarginAMCColumns } from './GrossMarginAMCTable';
import Excel from '../../../assets/excel.png';
import { Breadcrumbs, EdgeReportingWindow, SubHeader, ReportLoader } from '../../../components';
import { newDateFormatted } from '../../../utils';
import getCSVData from '../csv';
import { userHasAccess, generateReport, getReportData, removeReportData, getList } from '../operations';
import { customStyles } from '../utils';

import './styles.scoped.css';

const GrossMarginAMC = () => {
  const navigate = useNavigate();
  const {
    clientMenu: { menuItems },
  } = useSelector(state => state.edgeReporting);
  const { user } = useSelector(state => state.admin);
  const [data, setData] = useState([]);
  const [form, setForm] = useState({});
  const [loadingResults, setLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isLoadingBranches, setIsLoadingBranches] = useState(false);
  const [branches, setBranches] = useState([]);
  const [isLoadingLOB, setIsLoadingLOB] = useState(false);
  const [isLoadingTT, setIsLoadingTT] = useState(false);
  const [isLoadingAmcCodes, setIsLoadingAmcCodes] = useState(false);
  const [amcCodes, setAmcCodes] = useState([]);
  const [lob, setLob] = useState([]);
  const [tt, setTT] = useState([]);

  useEffect(() => {
    if (menuItems) {
      // eslint-disable-next-line no-undef
      if (!userHasAccess(user, menuItems.find(i => i.to === window.location.pathname)?.id)) navigate('/');
    }
  }, [menuItems, navigate, user]);

  useEffect(() => {
    handleGetBranches();
    handleGetLOB();
    handleGetTT();
    handleGetAmcCodes();
  }, []);

  const handleGetBranches = async () => {
    setIsLoadingBranches(true);
    const branches = await getList('clients/branches');
    setBranches(
      branches.map(({ Branch_Code, Branch_Name }) => ({
        label: `${Branch_Name} - ${Branch_Code}`,
        value: Branch_Code,
      })),
    );
    setIsLoadingBranches(false);
  };

  const handleGetLOB = async () => {
    setIsLoadingLOB(true);
    const lob = await getList('line-of-business');
    setLob(
      lob.map(({ LineOfBusinessCode, LineOfBusinessDesc }) => ({
        label: LineOfBusinessDesc,
        value: LineOfBusinessCode,
      })),
    );
    setIsLoadingLOB(false);
  };

  const handleGetTT = async () => {
    setIsLoadingTT(true);
    const transType = await getList('transaction-type');
    setTT(
      transType.map(({ Tranaction_Type, Tranaction_Description }) => ({
        label: Tranaction_Description,
        value: Tranaction_Type,
      })),
    );
    setIsLoadingTT(false);
  };

  const handleGetAmcCodes = async () => {
    setIsLoadingAmcCodes(true);
    const amcCodes = await getList('amcs');
    setAmcCodes(
      amcCodes.map(({ Code, Description }) => ({
        label: Description,
        value: Code,
      })),
    );
    setIsLoadingAmcCodes(false);
  };

  const fileName = `edge-reporting-gross-margin-amc-${newDateFormatted.toString()}.csv`;

  const handleViewResults = async () => {
    if (
      !(
        dayjs(form?.ToPerEnddate).isAfter(form?.FromPerEnddate) ||
        dayjs(form?.FromPerEnddate).isSame(form?.ToPerEnddate)
      )
    ) {
      // eslint-disable-next-line no-undef
      alert('Invalid date range. Please double check and try again.');
      return;
    }
    setLoadingResults(true);
    const { FromPerEnddate, ToPerEnddate, Branch, LineOfBusiness, BillToNumber, AMC, TransactionType } = form;
    try {
      const response = await generateReport({
        endpoint: 'gross-margin-amc-report',
        payload: {
          FromPerEnddate,
          ToPerEnddate,
          Branch,
          LineOfBusiness,
          BillToNumber,
          AMC,
          TransactionType,
        },
      });

      if (response.location && response.location !== '') {
        const reportData = await getReportData(response.location);
        if (reportData?.length) {
          await removeReportData(response.location);
          setLoadingResults(false);
          setData(reportData);
          setShowResults(true);
        }
      } else {
        setLoadingResults(false);
        setLoadingResults(false);
        // eslint-disable-next-line no-undef
        alert('No data found matching your criteria.');
      }
    } catch (error) {
      // eslint-disable-next-line no-undef
      alert('No data found matching your criteria.');
      setLoadingResults(false);
    }
    setLoadingResults(false);
  };

  const GrossMarginAMCForm = ({ setForm, form }) => {
    const handleChange = e => {
      const _form = { ...form, [e.target.name]: e.target.value };
      setForm(_form);
    };
    return (
      <Form
        noValidate
        onSubmit={() => { }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <div className="filters-row">
          <div
            className="filter-row"
            style={{
              marginTop: '30px',
            }}>
            <Form.Group as={Row} controlId="FromPerEnddate" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>
                <div className="required">Period End Date</div>
              </Form.Label>
              <Form.Control
                style={{
                  width: '150px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                type="date"
                name="FromPerEnddate"
                defaultValue={form?.FromPerEnddate}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Row} controlId="ToPerEnddate" style={{ alignItems: 'center' }}>
              <Form.Label
                style={{
                  width: '20px',
                  marginLeft: '30px',
                  marginRight: '20px',
                }}>
                To
              </Form.Label>
              <Form.Control
                style={{
                  width: '150px',
                  height: '30px',
                }}
                as="input"
                type="date"
                name="ToPerEnddate"
                defaultValue={form?.ToPerEnddate}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
          </div>
          <div className="filter-row">
            <Form.Group as={Row} controlId="Branch" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Branch</Form.Label>
              <Select
                isDisabled={isLoadingBranches}
                isMulti
                closeMenuOnSelect={false}
                styles={customStyles('360px')}
                options={branches}
                value={form?.Branch?.split(',').map(code => branches.find(option => String(option.value) === code))}
                onChange={selectedOptions => {
                  const selectedCodes = selectedOptions.map(option => option.value);
                  setForm({
                    ...form,
                    Branch: selectedCodes.join(','),
                  });
                }}
              />
              <Spinner
                as="span"
                animation="border"
                size="sm"
                aria-hidden="true"
                className="dropdown-spinner"
                hidden={!isLoadingBranches}
              />
            </Form.Group>
          </div>
          <div className="filter-row">
            <Form.Group as={Row} controlId="BillToNumber" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Bill To Number</Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '35px',
                  marginRight: '20px',
                }}
                type="number"
                as="input"
                name="BillToNumber"
                value={form?.BillToNumber}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="filter-row">
            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>AMC Code</Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  styles={customStyles('360px')}
                  options={amcCodes}
                  isClearable
                  onChange={(option, action) => {
                    setForm({
                      ...form,
                      AMC: action.action === 'clear' ? '' : option.value,
                    });
                  }}
                  defaultValue={form?.AMC}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingAmcCodes}
                />
              </div>
            </Form.Group>
          </div>
          <div className="filter-row">
            <Form.Group as={Row} controlId="ReportFormat" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Line Of Business</Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  styles={customStyles('360px')}
                  options={lob}
                  isClearable
                  name="LineOfBusiness"
                  onChange={selectedOptions => {
                    const selectedCodes = selectedOptions.map(option => option.value);
                    setForm({
                      ...form,
                      LineOfBusiness: selectedCodes.join(','),
                    });
                  }}
                  value={form?.LineOfBusiness?.split(',').map(code => lob.find(option => option.value === code))}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingLOB}
                />
              </div>
            </Form.Group>
          </div>
          <div className="filter-row">
            <Form.Group as={Row} controlId="ReportFormat" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Transaction Type</Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  styles={customStyles('360px')}
                  options={tt}
                  isClearable
                  name="TransactionType"
                  onChange={selectedOptions => {
                    const selectedCodes = selectedOptions.map(option => option.value);
                    setForm({
                      ...form,
                      TransactionType: selectedCodes.join(','),
                    });
                  }}
                  value={form?.TransactionType?.split(',').map(code => tt.find(option => option.value === code))}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingTT}
                />
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="buttons-row">
          <Button
            disabled={!form?.ToPerEnddate || !form?.FromPerEnddate}
            onClick={handleViewResults}
            style={{ marginRight: '20px', width: '100px' }}>
            <span>VIEW</span>
          </Button>

          <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
            <span>CANCEL</span>
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <>
      <SubHeader
        text="EDGE Reporting"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with Gross Margin AMC?"
        emailAddress={process.env.REACT_APP_EDGE_REPORTING_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle="Reporting Home"
          middleTitle={showResults && 'Gross Margin AMC'}
          midItemAction={() => setShowResults(false)}
          rightTitle={showResults ? 'Viewing Report' : 'Gross Margin AMC'}
          route="/edge-reporting"
        />
        {showResults && (
          <CSVLink filename={fileName} data={getCSVData(data, grossMarginAMCColumns)}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
        )}
      </div>
      {loadingResults && <ReportLoader didAbort={() => setLoadingResults(false)} />}
      {showResults ? (
        <>
          <GrossMarginAMCTable data={data} loading={loadingResults} summaryOrDetail={form.ReportFormat} />
          <div className="buttons-row">
            <Button onClick={() => setShowResults(false)} style={{ marginRight: '20px', width: '100px' }}>
              <span>BACK</span>
            </Button>

            <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
              <span>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <EdgeReportingWindow
          containerStyle={{
            border: '2px solid #343a40',
          }}
          windowContent={GrossMarginAMCForm({ form, setForm })}
          windowTitle="Criteria"
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )}
    </>
  );
};

export default GrossMarginAMC;
