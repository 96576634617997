import { API } from 'aws-amplify';

import * as queries from '../../../graphql/queries';

const LIMIT = 100;
let dqs = [];
let prevToken = '';

export const getCorporateDQs = async ({ nextToken, type, status, userId }) => {
  if (type === 'Corporate') {
    const response = await API.graphql({
      query: queries.listDriverQualificationsByStatusByCustomerIdAndCreatedBy,
      variables: {
        limit: LIMIT,
        sortDirection: 'DESC',
        status,
        nextToken,
      },
    });
    return response?.data;
  }
  const response = await API.graphql({
    query: queries.listClientDriverQualificationsByStatus,
    variables: {
      limit: LIMIT,
      userId,
      status,
      lastEvaluatedKey: nextToken,
    },
  });
  return response?.data;
};

export const getBatch = async ({ type, setData, setLoading, status, userId, isFirst = false, nextToken = null }) => {
  setLoading(true);
  if (isFirst) {
    dqs = [];
    prevToken = '';
  }
  if (prevToken !== nextToken || !nextToken) {
    prevToken = nextToken;
    const response = await getCorporateDQs({ nextToken, type, status, userId });
    let items;
    let token;
    if (type === 'Corporate') {
      items = response.listDriverQualificationsByStatusByCustomerIdAndCreatedBy?.items;
      token = response.listDriverQualificationsByStatusByCustomerIdAndCreatedBy?.nextToken;
    } else {
      items = response.listClientDriverQualificationsByStatus?.list;
      token = response.listClientDriverQualificationsByStatus?.lastEvaluatedKey;
    }
    dqs = dqs.concat(items);

    if (token) {
      getBatch({ type, setData, setLoading, status, userId, nextToken: token });
    } else {
      setData(dqs);
      setLoading(false);
    }
  } else {
    setLoading(false);
  }
};
