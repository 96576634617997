import React, { useEffect, useMemo, useState } from 'react';

import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import { Form, Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CASTable from './CASTable';
import getCSVData from './csv';
import { ContractActivityForm } from './Form';
import Excel from '../../../assets/excel.png';
import { Breadcrumbs, EdgeReportingWindow, SubHeader, ReportLoader } from '../../../components';
import { newDateFormatted } from '../../../utils';
import { getList, userHasAccess } from '../operations';

import './styles.scoped.css';

const ContractActivitySearch = () => {
  const navigate = useNavigate();
  const {
    clientMenu: { menuItems },
  } = useSelector(state => state.edgeReporting);
  const { user } = useSelector(state => state.admin);
  const [data, setData] = useState([]);
  const [branches, setBranches] = useState([]);
  const [LOB, setLOB] = useState([]);
  const [CAS, setCAS] = useState([]);
  const [form, setForm] = useState({});
  const [loadingResults, setLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isLoadingBranches, setIsLoadingBranches] = useState(false);
  const [isLoadingLOB, setIsLoadingLOB] = useState(false);
  const [isLoadingCAS, setIsLoadingCAS] = useState(true);

  useEffect(() => {
    if (menuItems) {
      // eslint-disable-next-line no-undef
      if (!userHasAccess(user, menuItems.find(i => i.to === window.location.pathname)?.id)) navigate('/');
    }
  }, [menuItems]);

  const handleGetLOB = async () => {
    setIsLoadingLOB(true);
    const lob = await getList('line-of-business');
    setLOB(lob);
    setIsLoadingLOB(false);
  };

  const handleGetCAS = async () => {
    setIsLoadingCAS(true);
    const cas = await getList('contract-activity-status');
    setCAS(cas);
    setIsLoadingCAS(false);
  };

  const handleGetBranches = async () => {
    setIsLoadingBranches(true);
    const branches = await getList('clients/branches');
    setBranches(branches);
    setIsLoadingBranches(false);
  };

  useEffect(() => {
    handleGetCAS();
    handleGetLOB();
    handleGetBranches();
  }, []);

  const casOptions = useMemo(
    () =>
      CAS.map(({ REF_CODE, REF_DESCRIPTION }) => ({
        label: REF_DESCRIPTION,
        value: REF_CODE,
      })),
    [CAS],
  );

  const lobOptions = useMemo(
    () =>
      LOB.map(({ LineOfBusinessCode, LineOfBusinessDesc }) => ({
        label: LineOfBusinessDesc,
        value: LineOfBusinessCode,
      })),
    [LOB],
  );

  const branchOptions = useMemo(
    () =>
      branches?.map(({ Branch_Code, Branch_Name }) => ({
        label: Branch_Name,
        value: Branch_Code,
      })),
    [branches],
  );

  const fileName = `edge-reporting-activity-search-${newDateFormatted.toString()}.csv`;

  const handleViewResults = async () => {
    if (
      !(
        dayjs(form?.ToPerEnddate).isAfter(form?.FromPerEnddate) ||
        dayjs(form?.FromPerEnddate).isSame(form?.ToPerEnddate)
      )
    ) {
      // eslint-disable-next-line no-undef
      alert('Invalid date range. Please double check and try again.');
      return;
    }
    setLoadingResults(true);
    try {
      const response = await API.post('centerlineportal', 'contract-activity-search', {
        body: {
          CAFromStartdate: form?.FromPerEnddate,
          CAToEnddate: form?.ToPerEnddate,
          CAStatus: form?.CAS,
          LineOfBusiness: form?.LOB,
          Branch: form?.branches,
          EmplLastName: form?.FromEmplLastName,
          EmplFirstName: form?.FromEmplFirstName,
          EmpId: +form?.FromEmplID,
        },
      });
      if (response.response_body?.length) {
        setLoadingResults(false);
        setData(response.response_body);
        setShowResults(true);
      } else {
        setLoadingResults(false);
        // eslint-disable-next-line no-undef
        alert('No data found matching your criteria.');
      }
    } catch (e) {
      setLoadingResults(false);
      // eslint-disable-next-line no-undef
      alert('No data found matching your criteria.');
      console.log('error: ', e);
    }
    setLoadingResults(false);
  };

  const CASForm = ({
    form,
    setForm,
    isLoadingCAS,
    isLoadingLOB,
    isLoadingBranches,
    casOptions,
    lobOptions,
    branchOptions,
  }) => {
    const handleChange = e => {
      const _form = { ...form, [e.target.name]: e.target.value };
      setForm(_form);
    };
    return (
      <Form
        noValidate
        onSubmit={() => { }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <ContractActivityForm
          form={form}
          setForm={setForm}
          handleChange={handleChange}
          isLoadingCAS={isLoadingCAS}
          isLoadingLOB={isLoadingLOB}
          isLoadingBranches={isLoadingBranches}
          casOptions={casOptions}
          lobOptions={lobOptions}
          branchOptions={branchOptions}
        />

        <div className="buttons-row">
          <Button
            disabled={!form?.ToPerEnddate || !form?.FromPerEnddate}
            onClick={handleViewResults}
            style={{ marginRight: '20px', width: '100px' }}>
            <span>VIEW</span>
          </Button>

          <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
            <span>CANCEL</span>
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <>
      <SubHeader
        text="EDGE Reporting"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Contract Activity Search report?"
        emailAddress={process.env.REACT_APP_EDGE_REPORTING_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle="Reporting Home"
          middleTitle={showResults && 'Contract Activity Search'}
          midItemAction={() => setShowResults(false)}
          rightTitle={showResults ? 'Viewing Report' : 'Contract Activity Search'}
          route="/edge-reporting"
        />
        {!!data?.length && showResults && (
          <CSVLink filename={fileName} data={getCSVData(data)}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
        )}
      </div>
      {loadingResults && <ReportLoader didAbort={() => setLoadingResults(false)} />}
      {showResults ? (
        <>
          <CASTable data={data} loading={loadingResults} />
          <div className="buttons-row">
            <Button onClick={() => setShowResults(false)} style={{ marginRight: '20px', width: '100px' }}>
              <span>BACK</span>
            </Button>

            <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
              <span>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <EdgeReportingWindow
          containerStyle={{
            border: '2px solid #343a40',
          }}
          windowContent={CASForm({
            form,
            setForm,
            isLoadingCAS,
            isLoadingLOB,
            isLoadingBranches,
            casOptions,
            lobOptions,
            branchOptions,
          })}
          windowTitle="Criteria"
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )}
    </>
  );
};

export default ContractActivitySearch;
