import React from 'react';

import './styles.scoped.css';

export const TransferResultsBody = ({ results }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}>
    <div className="results-modal-row">
      <div className="results-modal-header-title">Expense ID</div>
      <div className="results-modal-header-title">Error</div>
    </div>
    {results.map(res => (
      <div className="results-modal-row">
        <div className="results-modal-id ">{res.id}</div>
        <div className="results-modal-result">{res.status}</div>
      </div>
    ))}
  </div>
);

export const dateToTimeZero = dateToFormat => {
  try {
    const startDate = dateToFormat?.substring(0, 10);
    const date = new Date(startDate);
    date.setUTCHours(0, 0, 0, 0);
    return date.toISOString();
  } catch (e) {
    return null;
  }
};

export const formattedDate = dateToFormat =>
  dateToFormat
    ? new Date(dateToFormat).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
    : '';

export const preventMultipleSave = setDisabled => {
  setDisabled(true);
  setTimeout(() => {
    setDisabled(false);
  }, 5000);
};
