import { API, Storage, graphqlOperation } from 'aws-amplify';
import { getUser } from '../../graphql/customQueries';


export const getList = async (apiName = '') => {
    try {
        const response = await API.get('centerlineportal', apiName);
        if (response.response_code === 200) {
            return response.response_body;
        }
    } catch (e) {
        if (e?.message?.includes('404')) {
            return [];
        }
        console.log(e);
    }
};

export const getRoleId = async (id) => {
    try {
        const response = await API.graphql({
            query: getUser,
            variables: { id }
        });
        return response.data.getUser?.appAccess?.items;
    } catch (e) {
        console.log('error', e)
    }
}