import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Container, Button, Spinner } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Excel from '../../../../assets/excel.png';
import { Breadcrumbs, ReadyToBillTable, SubHeader } from '../../../../components';
import { formatIsoToDate, newDateFormatted } from '../../../../utils';
import { getExpensesCsvData } from '../../../../utils/utilities';
import { getClosedAssignments } from '../ReadyToBillOpen/operations';

import './styles.scoped.css';

const ReadyToBillClosed = () => {
  const navigate = useNavigate();
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const { clientMenu, readyToBillCurrentIds } = useSelector(state => state.expenseEntry);
  const assignmentLineOfBusiness = useRef('');
  const [assignmentLineOfBusinessItems, setAssignmentLineOfBusinessItems] = useState({});

  const getAssignmentLineOfBusinessItems = () => {
    clientMenu?.menuItems?.map(i => {
      if (location.pathname.includes(i.to)) {
        setAssignmentLineOfBusinessItems(i);
      }
    })
  };

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      document.querySelector('div.root').style.background = '#D0CED0';
      document.querySelector('span.wrap').style.background = '#ffff';
      document.querySelector('.left-title').style.color = '#ffff';
      document.querySelector('.right-title').style.color = '#66CCFF';
    }
    return () => {
      document.querySelector('div.root').style.background = '#ffff';
    }
  }, []);

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      assignmentLineOfBusiness.current = 'DMS';
    }
    if (location.pathname.includes('mobile-driver-solutions')) {
      assignmentLineOfBusiness.current = 'MDS';
    }
  }, [])

  const formatExpenses = exp => {
    const formattedExpenses = [...exp];

    formattedExpenses?.forEach(e => {
      e.driverNameNumber = `${e?.driverName} - ${e?.driverId}`;
      e.customerNameNumber = `${e?.customerName} - ${e?.customerId}`;
      e.startDate = formatIsoToDate(e.startDate);
      e.createdAt = formatIsoToDate(e.createdAt);
    });

    return formattedExpenses;
  };

  let expensesData = [];
  let prevToken = '';

  const getBatch = async (lob = assignmentLineOfBusiness.current, nextToken = null) => {
    if (prevToken !== nextToken) {
      prevToken = nextToken;

      const data = await getClosedAssignments(lob, nextToken);
      const items = data?.list;
      const token = data?.lastEvaluatedKey;
      if (items) {
        expensesData = expensesData.concat(items);
      }
      if (token) {
        getBatch(assignmentLineOfBusiness.current, token);
      } else {
        const formattedExpenses = formatExpenses(expensesData);

        setExpenses(formattedExpenses);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    if (assignmentLineOfBusiness.current) {
      getAssignmentLineOfBusinessItems();
      getBatch();
    }
  }, [assignmentLineOfBusiness.current]);

  const fileName = `expense-tracker-closed-assignments-${newDateFormatted.toString()}.csv`;

  return (
    <>
      <SubHeader
        text="Expenses"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Expenses?"
        emailAddress={process.env.REACT_APP_EXPENSE_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle={assignmentLineOfBusinessItems.text}
          rightTitle="Ready to Bill - Not Open Timecards"
          route={assignmentLineOfBusinessItems.to}
          color={assignmentLineOfBusinessItems.lob === "DMS" && '#ffff'}
        />
        {expenses?.length && readyToBillCurrentIds?.length ? (
          <CSVLink filename={fileName} data={getExpensesCsvData(expenses, readyToBillCurrentIds)}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
        ) : null}
      </div>
      <Container className="wrap" fluid>
        {loading ? (
          <div className="h-100 d-flex justify-content-center p-5">
            <Spinner animation="border" />
          </div>
        ) : (
          <ReadyToBillTable data={expenses} isOpenAssignments={false} assignmentLineOfBusinessItems={assignmentLineOfBusinessItems} />
        )}
      </Container>
      <div className="buttons-container">
        <Button onClick={() => navigate(assignmentLineOfBusinessItems.to)} className="button-secondary">
          <span>CANCEL</span>
        </Button>
      </div>
    </>
  );
};

export default ReadyToBillClosed;
