import { useNavigate } from 'react-router-dom';
import './styles.scoped.css';

const EdgeReportingHeader = ({
  leftTitle,
  rightTitle,
  middleTitle,
  midItemAction,
}) => {
  const navigate = useNavigate();
  return (
    <div className="navigation-header">
      <div onClick={() => navigate('/edge-reporting')} className="left-title">
        {leftTitle}
      </div>
      {middleTitle && (
        <div
          onClick={midItemAction}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <img
            style={{ width: '12px', height: '18px', marginRight: '10px' }}
            src={require('../../assets/arrow-right.png')}
          />
          <div className="left-title">{middleTitle}</div>
        </div>
      )}
      {rightTitle && (
        <>
          <img
            style={{ width: '12px', height: '18px' }}
            src={require('../../assets/arrow-right.png')}
          />
          <div className="right-title">{rightTitle}</div>
        </>
      )}
    </div>
  );
};

export default EdgeReportingHeader;
