import React from 'react';

import { Web } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SubHeader, Footer } from '../../components';
import useIsMobileScreen from '../../hooks/useCheckIsMobile';

import './styles.scoped.css';

const AdminPortal = () => {
  const menuData = useSelector(state => state.admin.menuData);
  const navigate = useNavigate();
  const isMobile = useIsMobileScreen();

  return (
    <>
      <SubHeader
        text="Admin Portal"
        btnSmall={<Web sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigate(-1)}
      />
      <div className="wrap">
        {menuData.map(item => (
          <div className="section" key={`${item.header}-section`}>
            <h1 className="header" key={`${item.header}-header`}>
              {item.header}
            </h1>
            <span className="row" key={`${item.header}-span`}>
              {item.items.map(i => (
                <div
                  key={i.title}
                  style={{ minWidth: isMobile ? '320px' : '390px' }}
                  className="button"
                  onClick={() => navigate(i.to)}>
                  {i.title}
                </div>
              ))}
            </span>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default AdminPortal;
