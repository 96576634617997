import React, { useEffect } from 'react';
import { Breadcrumbs, SubHeader, Footer, StandardDocumentsTable } from '../../../../components'
import { useNavigate } from 'react-router-dom';
import { Home } from '@mui/icons-material';
import { getItems } from './operations';
import { useSelector } from 'react-redux';
import "./styles.scoped.css";

const StandardDocuments = () => {
    const navigation = useNavigate();
    const { listData } = useSelector(state => state.standardDocuments);

    useEffect(() => {
        getItems();
    }, []);

    return (
        <>
            <SubHeader
                text="Centerline Driver Qualification"
                btnSmall={<Home
                    sx={{ color: '#fff', fontSize: 30 }}
                />}
                smallAction={() => navigation('/driver-qualification', { replace: true })}
            />
            <Breadcrumbs
                leftTitle="DQF Home"
                middleTitle={'Standard Documents'}
                route={'/driver-qualification'}
            />
            <div className="wrap">
                <p className="title">Standard Documents</p>
                <StandardDocumentsTable data={listData} />
            </div>
            <Footer />
        </>
    );
}
export default StandardDocuments;