const styles = {
  selectStyle: {
    control: base => ({
      ...base,
      borderColor: 'black',
    }),
  },
  dropdownStyle: {
    backgroundColor: 'white',
    width: '100%',
    borderColor: 'black',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: '200',
    color: 'black',
    marginRight: 20,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10%',
  },
  buttonPrimary: {
    backgroundColor: '#da0f2e',
  },
  buttonSecondary: {
    backgroundColor: 'white',
    color: '#da0f2e',
    marginTop: 20,
    marginLeft: 10,
  },
  wrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  marginTop: {
    marginTop: 20,
  },
  radioRow: {
    margin: 20,
  },
  plus: {
    fontWeight: 800,
    marginLeft: 56,
  },
  firstColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  minus: {
    fontWeight: 800,
    marginBottom: 8,
    backgroundColor: '#e9e9ea',
    border: '0 solid #e9e9ea',
    padding: 0,
    height: 32,
    width: 32,
    marginRight: 10,
    boxShadow: 'none',
  },
};

export default styles;
