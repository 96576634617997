import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import dashboardReducer from '../features/Dashboard/dashboardSlice';
import adminReducer from '../features/AdminPortal/adminSlice';
import driverQualificationReducer from '../features/DriverQualification/driverQualificationSlice';
import newRequestReducer from '../features/DriverQualification/ListDQ/NewRequest/newRequestSlice';
import clientAccessReducer from '../features/ClientAccess/clientAccessSlice';
import quoteEngineReducer from '../features/QuoteEngine/quoteEngineSlice';
import driversSlice from '../features/QuoteEngine/GenerateQuote/Drivers/driversSlice';
import clericalSlice from '../features/QuoteEngine/GenerateQuote/Clerical/clericalSlice';
import myQuotesSlice from '../features/QuoteEngine/MyQuotes/myQuotesSlice';
import viewQuoteSlice from '../features/QuoteEngine/ViewQuote/viewQuoteSlice';
import viewDQFSlice from '../features/DriverQualification/ViewDQF/viewDQFSlice';
import expensesEntrySlice from '../features/ExpenseEntry/expensesEntrySlice';
import appAccessSlice from '../features/ClientAccess/EditAccess/appAccessSlice';
import addClientUserSlice from '../features/ClientAccess/AddClientUser/addClientUserSlice';
import checkListSlice from '../features/DriverQualification/Management/ChecklistDetails/checkListSlice';
import checklistTemplatesSlice from '../features/DriverQualification/Management/ChecklistTemplates/checklistTemplatesSlice';
import standardDocumentSlice from '../features/DriverQualification/Management/StandardDocument/standardDocumentSlice';
import standardDocumentsSlice from '../features/DriverQualification/Management/StandardDocuments/standardDocumentsSlice';
import expenseFormSlice from '../components/ExpenseForm/expenseFormSlice';
import assembleNewDQFSlice from '../features/DriverQualification/AssembleNewDQF/assembleNewDQFSlice';
import selectDocumentSlice from '../features/DriverQualification/SelectDocumentDetails/selectDocumentSlice';
import appSlice from './appSlice';
import edgeReportingSlice from '../features/EdgeReporting/edgeReportingSlice';
import searchExpensesFormSlice from '../features/ExpenseEntry/DriverSolutions/SearchExpenses/searchExpensesFormSlice';
import pushNotificationsSlice from '../features/PushNotifications/pushNotificationsSlice';

const persistConfig = {
  key: 'root',
  blacklist: [
    'appAccess',
    'expenseForm',
    'expenseEntry',
    'edgeReporting',
    'admin',
    'clientAccess',
    'newRequest',
    'viewDQF',
    'assembleNewDQF',
    'checkList',
  ],
  storage,
};

const rootReducer = combineReducers({
  addClientUser: addClientUserSlice,
  admin: adminReducer,
  appAccess: appAccessSlice,
  checkList: checkListSlice,
  checklistTemplates: checklistTemplatesSlice,
  clientAccess: clientAccessReducer,
  dashboard: dashboardReducer,
  driverQualification: driverQualificationReducer,
  expenseEntry: expensesEntrySlice,
  myQuotes: myQuotesSlice,
  newRequest: newRequestReducer,
  standardDocument: standardDocumentSlice,
  standardDocuments: standardDocumentsSlice,
  quoteEngine: quoteEngineReducer,
  quoteEngineDrivers: driversSlice,
  quoteEngineClerical: clericalSlice,
  viewQuote: viewQuoteSlice,
  viewDQF: viewDQFSlice,
  expenseForm: expenseFormSlice,
  assembleNewDQF: assembleNewDQFSlice,
  selectDocumentDetails: selectDocumentSlice,
  app: appSlice,
  edgeReporting: edgeReportingSlice,
  searchExpensesForm: searchExpensesFormSlice,
  pushNotifications: pushNotificationsSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    }
  }),
});
