import { createSlice } from '@reduxjs/toolkit';

const newRequestInitialState = {
  tierData: [
    {
      label: 'Complete file (Based on client requirements)',
      value: 1,
    },
    {
      label: 'Other',
      value: 2,
    },
  ],
  driver: {
    driverId: '',
    name: '',
    status: '',
    skills: [],
    cityState: '',
    isValid: true,
    disable: false,
    fetchSuccess: false,
  },
  client: {
    clientId: '',
    name: '',
    status: '',
    isValid: true,
    fetchSuccess: false,
  },
  job: {
    jobOrderId: '',
    startDate: '',
    status: '',
    isValid: true,
    fetchSuccess: false,
  },
  tier: {
    value: 'Select...',
    isValid: true,
    isSelected: '',
    description: '',
  },
  branch: {
    branchId: '',
    isValid: true,
  },
  locationState: {
    stateId: '',
    stateName: '',
    isValid: true,
  },
  locationCity: {
    cityId: '',
    cityName: '',
    isValid: true,
  },
};

const newRequestSlice = createSlice({
  name: 'newRequest',
  initialState: newRequestInitialState,
  reducers: {
    setString: (state, { payload }) => {
      state.string = payload;
    },
    setDriver: (state, { payload }) => {
      state.driver = payload;
    },
    setClient: (state, { payload }) => {
      state.client = payload;
    },
    setJob: (state, { payload }) => {
      state.job = payload;
    },
    setTier: (state, { payload }) => {
      state.tier = payload;
    },
    setBranch: (state, { payload }) => {
      state.branch = payload;
    },
    setLocationState: (state, { payload }) => {
      state.locationState = payload;
    },
    setLocationCity: (state, { payload }) => {
      state.locationCity = payload;
    },
    resetData: state => {
      Object.assign(state, newRequestInitialState);
    },
  },
});

export default newRequestSlice.reducer;

export const {
  setString,
  setDriver,
  setClient,
  setJob,
  setTier,
  resetData,
  setBranch,
  setLocationState,
  setLocationCity,
} = newRequestSlice.actions;
