import { createSlice } from '@reduxjs/toolkit';

const myQuotesSlice = createSlice({
  name: 'myQuotes',
  initialState: {
    myQuotes: [],
    loading: false,
  },
  reducers: {
    setMyQuotes: (state, { payload }) => {
      state.myQuotes = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

export default myQuotesSlice.reducer;

export const { setMyQuotes, setLoading } = myQuotesSlice.actions;
