import React from 'react';
import { getCurrencyString } from '../../../utils/utilities';

const DriverRatesField = ({ label, value }) => {
  const { pay, bill, markUp } = value;
  if (pay + bill + markUp === 0) {
    return null;
  }

  return (
    <p>
      {label}
      <br />
      <span className="text-muted mr-3">{`Pay ${getCurrencyString(pay)}`}</span>
      <span className="text-muted mr-3">
        {`Bill ${getCurrencyString(bill)}`}
      </span>
      <span className="text-muted mr-3">{`Mark-up ${markUp}`}</span>
    </p>
  );
};

export default DriverRatesField;
