import { createSlice } from '@reduxjs/toolkit';

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    user: {},
    menuData: [
      // {
      //   header: 'Centerline Mobile App',
      //   items: [
      //     { title: 'Frequently Asked Questions', to: '/faq' },
      //     { title: 'Push Notifications - Event Based', to: '/push-events' },
      //     { title: 'Push Notifications - Bulk', to: '/push-bulk' },
      //     { title: 'Surveys Links', to: '/surveys' },
      //     { title: 'News Board', to: '/faq' },
      //   ],
      // },
      // {
      //   header: 'Quote Engine',
      //   items: [{ title: 'Configurations', to: '/quote-config' }],
      // },
      // {
      //   header: 'Driver Qualifications',
      //   items: [{ title: 'Configurations', to: '/driver-config' }],
      // },
      // {
      //   header: 'Dynamic Pricing',
      //   items: [{ title: 'Configurations', to: '/pricing-config' }],
      // },
      {
        header: 'Access',
        items: [{ title: 'Client Access', to: '/client-users' }, { title: 'Corporate Access', to: '/corporate-users' }],
      },
      {
        header: 'Configurations',
        items: [{ title: 'Table Contents', to: '/table-contents' }],
      },
    ],
  },
  reducers: {
    addUser: (state, { payload }) => {
      state.user = payload;
    },
  },
});

export default adminSlice.reducer;

export const { addUser } = adminSlice.actions;
