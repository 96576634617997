import { createSlice } from '@reduxjs/toolkit';

const driverQualificationSlice = createSlice({
  name: 'driverQualification',
  roleId: null,
  initialState: {
    header: 'Qualification Files',
    clientMenu: [
      {
        header: 'Client',
        items: [
          {
            text: 'New Files',
            icon: 'forward_to_inbox',
            to: '/driver-qualification/sent',
          },
          {
            text: 'Action Needed',
            icon: 'hourglass_top',
            to: '/driver-qualification/client-review',
          },
          {
            text: 'Information Requested',
            icon: 'question_answer',
            to: '/driver-qualification/information-requested',
          },
          {
            text: 'Approved',
            icon: 'check_circle_outline',
            to: '/driver-qualification/approved',
          },
          {
            text: 'Rejected',
            icon: 'highlight_off',
            to: '/driver-qualification/rejected',
          },
        ],
      },
    ],
    corporateMenu: [
      {
        header: 'Corporate',
        items: [
          {
            text: 'New Request',
            icon: 'note_add',
            to: '/driver-qualification/new-request',
          },
          {
            text: 'Requested',
            icon: 'folder_shared',
            to: '/driver-qualification/requested',
          },
          {
            text: 'Assembling',
            icon: 'build_circle',
            to: '/driver-qualification/assembling',
          },
          {
            text: 'Pending Review',
            icon: 'forward_to_inbox',
            to: '/driver-qualification/sent',
          },
          {
            text: 'Client Review',
            icon: 'hourglass_top',
            to: '/driver-qualification/client-review',
          },
          {
            text: 'Information Requested',
            icon: 'question_answer',
            to: '/driver-qualification/information-requested',
          },
          {
            text: 'Approved',
            icon: 'check_circle_outline',
            to: '/driver-qualification/approved',
          },
          {
            text: 'Rejected',
            icon: 'highlight_off',
            to: '/driver-qualification/rejected',
          },
        ],
      },
    ],
    managementMenu: [
      {
        header: 'Management',
        items: [
          {
            text: 'Checklist Templates',
            icon: 'list_alt',
            to: '/checklist-template',
          },
          {
            text: 'Standard Documents',
            icon: 'file_copy',
            to: '/standard-documents',
          },
        ],
      },
    ],
  },
  reducers: {
    setRoleId: (state, { payload }) => {
      state.roleId = payload;
    },
  },
});

export default driverQualificationSlice.reducer;

export const { setRoleId } = driverQualificationSlice.actions;
