import { createSlice } from '@reduxjs/toolkit';

const viewDQFInitialState = {
  dqPdfDocument: '',
  pdfError: '',
  isExpired: '',
  dq: null,
  cognitoUser: null,
  error: null,
};

const viewDQFSlice = createSlice({
  name: 'viewDQF',
  initialState: viewDQFInitialState,
  reducers: {
    setDqPdfDocument: (state, { payload }) => {
      state.dqPdfDocument = payload;
    },
    setPdfError: (state, { payload }) => {
      state.pdfError = payload;
    },
    setIsExpired: (state, { payload }) => {
      state.isExpired = payload;
    },
    setDQ: (state, { payload }) => {
      state.dq = payload;
    },
    setQuestions: (state, { payload }) => {
      state.dq.questions.items = [...state.dq.questions.items, payload];
    },
    setCognitoUser: (state, { payload }) => {
      state.cognitoUser = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export default viewDQFSlice.reducer;

export const {
  setDqPdfDocument,
  setPdfError,
  setIsExpired,
  setDQ,
  setQuestions,
  setCognitoUser,
  setError,
} = viewDQFSlice.actions;
