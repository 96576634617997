import React, { useEffect, useState } from 'react';

import { Web } from '@mui/icons-material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ClericalQuoteCard from './clericalQuoteCard';
import DriverQuoteCard from './driverQuoteCard';
import { getQuote } from './operations';
import { Button, SubHeader } from '../../../components';
import ClericalQuoteDocument from '../GenerateQuote/Clerical/clericalPDF';
import DriversQuoteDocument from '../GenerateQuote/Drivers/driversPDF';

const ViewQuote = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { quoteId } = useParams();
  const { selectedQuote, loading } = useSelector(state => state.viewQuote);
  const { subHeaderText } = useSelector(state => state.quoteEngine);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    getQuote(dispatch, quoteId);
  }, [quoteId]);

  const handleSetMounted = () => {
    setMounted(true);
  };

  useEffect(() => {
    setTimeout(() => {
      handleSetMounted();
    }, 3000);
  }, []);

  if (selectedQuote !== null && !loading) {
    return (
      <Container fluid>
        <SubHeader
          text={subHeaderText}
          btnSmall={<Web sx={{ color: '#fff', fontSize: 30 }} />}
          smallAction={() => navigate('/dashboard')}
          btnLarge={<Button text="MY QUOTES" onPress={() => navigate('/quote-engine/my-quotes')} />}
          btnInfo
          emailAddress={process.env.REACT_APP_QUOTE_ENGINE_CONTACT_EMAIL}
        />
        <Row>
          <Col className="p-0">
            {selectedQuote && mounted && (
              <PDFDownloadLink
                document={
                  selectedQuote?.quoteType?.toLowerCase() === 'clerical' ? (
                    <ClericalQuoteDocument quote={selectedQuote} />
                  ) : (
                    <DriversQuoteDocument quote={selectedQuote} />
                  )
                }
                fileName={`quote_${selectedQuote?.quoteId}.pdf`}>
                {({ scopedLoading, error }) => (
                  <Button disabled={error || scopedLoading} className="mb-3" text="DOWNLOAD">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      hidden={!scopedLoading}
                    />
                  </Button>
                )}
              </PDFDownloadLink>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="border border-light rounded shadow-sm">
            {selectedQuote?.quoteType?.toLowerCase() === 'clerical' ? (
              <ClericalQuoteCard quote={selectedQuote} />
            ) : (
              <DriverQuoteCard quote={selectedQuote} />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
  return <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!loading} />;
};

export default ViewQuote;
