import React, { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { useLocation, useNavigate } from 'react-router-dom';

import MFACode from '../Login/MFACode';
import VerifyMFACode from '../Login/VerifyMFACode';
import Authenticator from '../Login/AuthContainer';

import './styles.scoped.css';

const VerifyDQFAuthLink = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [currentIndex, setCurrentIndex] = useState(0);
    const [requiredAttributes, setRequiredAttributes] = useState([]);
    const [userData, setUserData] = useState({
        code: '',
        verifyCode: '',
        username: '',
        nextStep: 'Code',
    });

    const params = new URLSearchParams(location.search);
    const [dqf, email] = [params.get('dqf') || '', params.get('email') || ''];

    const { route } = useAuthenticator(context => [context.route]);

    const getCognitoUser = async () => {
        try {
            if (email) {
                await Auth.signIn(email).then((user) => {
                    if (user.challengeName === 'CUSTOM_CHALLENGE') {
                        if (user.challengeParam.customMfaChallenge === 'PICK_MFA_FACTOR') {
                            setRequiredAttributes(JSON.parse(user.challengeParam.publicChallengeParameters));
                            nextStep({
                                ...userData,
                                nextStep: 'MFACode',
                                user: user,
                                username: email,
                            });
                        }
                    }
                });
            }
        } catch (e) {
            console.log('e', e);
            setErrorMessage('Failed to Authenticate');
        }
    }

    const nextStep = (stepData) => {
        setUserData({ ...userData, ...stepData });
    }

    useEffect(() => {
        if (dqf && email && route !== 'authenticated') {
            getCognitoUser();
        }
    }, [route, navigate])

    return (
        <>
            <Authenticator
                currentIndex={currentIndex}
                nextStep={nextStep}
            >
                {userData?.nextStep === 'MFACode' && requiredAttributes &&
                    <MFACode
                        getCognitoUser={getCognitoUser}
                        nextStep={nextStep}
                        requiredAttributes={requiredAttributes}
                        userData={userData}
                    />
                }
                {userData?.nextStep === 'VerifyCode' &&
                    <VerifyMFACode
                        nextStep={nextStep}
                        userData={userData}
                    />
                }
            </Authenticator>
        </>
    );
};

export default VerifyDQFAuthLink;
