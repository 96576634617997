import { createSlice } from "@reduxjs/toolkit";

const clientAccessSlice = createSlice({
    name: "clientAccess",
    initialState: { listData: [] },
    reducers: {
        setClientAccessList: (state, { payload }) => {
            state.listData = payload;
        },
    },
});

export default clientAccessSlice.reducer;

export const { setClientAccessList } = clientAccessSlice.actions;