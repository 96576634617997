import React, { useEffect, useState } from "react";

import dayjs from 'dayjs';
import { getBranchPushNotification } from "./operations";
import { useSelector } from 'react-redux';
import { Breadcrumbs, SubHeader, Footer } from '../../../components'
import { getList } from './operations';
import { useNavigate, useParams } from 'react-router-dom';
import { Home } from '@mui/icons-material';
import ViewFormContent from './ViewFormContent';
import "./styles.scoped.css";

const viewBRANCHPushNotifications = () => {
    const navigate = useNavigate();
    const user = useSelector(state => state.admin?.user);
    const { id } = useParams();
    const [branches, setBranches] = useState([]);
    const [lobs, setLOBs] = useState([]);
    const [recipientStatuses, setRecipientStatuses] = useState([]);
    const [messageCharCount, setMessageCharCount] = useState(0);
    const messageCharLimit = 150;
    const [subjectCharCount, setSubjectCharCount] = useState(0);
    const subjectCharLimit = 50;
    const [isLoadingBranches, setIsLoadingBranches] = useState(false);
    const [isLoadingRecipientStatus, setIsLoadingRecipientStatus] = useState(false);
    const [isLoadingLOBS, setIsLoadingLOBs] = useState(false);
    const [form, setForm] = useState({});

    const utc = require('dayjs/plugin/utc')
    dayjs.extend(utc)

    const handleGetRecipientStatuses = async () => {
        setIsLoadingRecipientStatus(true);
        try {
            const responses = await getList('driver-status');
            setRecipientStatuses(responses);
            setIsLoadingRecipientStatus(false);
        } catch (e) {
            console.log('e')
        }
    }

    const handleGetBranches = async () => {
        setIsLoadingBranches(true);
        try {
            const responses = await getList('branches');
            setBranches(responses);
            setIsLoadingBranches(false);
        } catch (e) {
            console.log('e', e)
        }
    };

    const handleGetLOB = async () => {
        setIsLoadingLOBs(true);
        try {
            const responses = await getList('line-of-business');
            setLOBs(responses);
            setIsLoadingLOBs(false);
        } catch (e) {
            console.log('e', e)

        }
    };

    const customStyles = width => ({
        control: provided => ({
            ...provided,
            width: width,
            height: '100%',
            minWidth: '300px',
        }),
        menuList: provided => ({
            ...provided,
            maxHeight: '200px',
        }),
        menu: provided => ({
            ...provided,
            zIndex: 100000,
        }),
    });

    const getNotification = async (id) => {
        try {
            const response = await getBranchPushNotification(id);
            setForm({
                ...form,
                id: response?.id,
                type: response?.type,
                subject: response?.subject,
                message: response?.message,
                url: response?.url,
                status: response?.status,
                lob: response?.lob,
                branch: response?.branch,
                recipientStatus: response?.recipientStatus,
                approvedBy: response?.name,
                approvedAt: response?.approvedAt,
                sentOn: response?.sentOn,
                createdBy: response?.createdBy,
                createdAt: response?.createdAt
            });
        } catch (e) {
            console.log('e', e);
        }

    }

    const handleCancel = () => {
        setForm({});
        navigate(-1);
    }

    const handleCheckbox = (e) => {
        if (e.target.checked === true) {
            setForm({
                ...form,
                status: 'Sent',
                sentOn: dayjs().utc().format(),
                approvedBy: user.name,
                approvedAt: dayjs().utc().format(),
            });
        }
        if (e.target.checked !== true) {
            setForm({
                ...form,
                status: 'Pending'
            });
        }
    }

    const convertBranchArrayToObject = (items) => {
        let arr = [];
        let branch = {};

        if (Array.isArray(branches) && branches.length > 0) {
            String(items).split(', ')?.forEach(i => {
                branch = branches?.find(
                    option => String(option.Branch) === i
                ),
                    arr.push({
                        label: `${branch?.Branch} - ${branch?.BranchName}`, value: branch?.Branch
                    })
            })
        };

        return arr;
    }

    const convertRecipientStatusArrayToObject = (items) => {
        let arr = [];
        let recipientStatus = {};

        if (Array.isArray(recipientStatuses) && recipientStatuses.length > 0) {
            String(items).split(', ')?.forEach(i => {
                recipientStatus = recipientStatuses?.find(
                    option => String(option.REF_CODE) === i
                ),
                    arr.push({
                        label: `${recipientStatus?.REF_DESCRIPTION}`, value: recipientStatus?.REF_CODE
                    })
            })
        };
        return arr;
    }

    const convertLOBSArrayToObject = (items) => {
        const arr = [];

        if (items) {
            items.split(', ').forEach(i => {
                arr.push({ label: i, value: i })
            });
        };
        return arr;
    }

    useEffect(() => {
        if (form?.subject || form?.message) {
            setSubjectCharCount(form?.subject.trim().length);
            setMessageCharCount(form?.message.trim().length);
        }
    }, [form?.subject, form?.message])

    useEffect(() => {
        if (id) {
            getNotification(id);
        }
    }, [id])

    useEffect(() => {
        handleGetBranches();
        handleGetLOB();
        handleGetRecipientStatuses();
    }, [])

    return (
        <>
            <SubHeader
                text={"Push Notifications"}
                btnSmall={<Home sx={{ color: '#fff', fontSize: 30 }} />}
                smallAction={() => navigate(`/ push - notifications`)}
            />

            <div style={{ marginLeft: 60 }}>
                <Breadcrumbs
                    leftTitle="PN Home"
                    middleTitle={"Branch Push Notifications"}
                    midItemAction={() => navigate(`../push-notifications/branch-push-notifications`, { replace: true })}
                    rightTitle={`${location.pathname.includes('edit') ? 'Edit Branch Notification' : 'View Branch Notification'}`}
                    route="/push-notifications/branch-push-notifications"
                    style={{ marginLeft: 30 }}
                />
            </div>

            <ViewFormContent
                customStyles={customStyles}
                branches={branches}
                lobs={lobs}
                headerTitle={`${'View Branch Push Notification'}`}
                handleCheckbox={handleCheckbox}
                recipientStatuses={recipientStatuses}
                form={form}
                handleCancel={handleCancel}
                messageCharCount={messageCharCount}
                messageCharLimit={messageCharLimit}
                subjectCharCount={subjectCharCount}
                subjectCharLimit={subjectCharLimit}
                isLoadingRecipientStatus={isLoadingRecipientStatus}
                isLoadingBranches={isLoadingBranches}
                isLoadingLOBS={isLoadingLOBS}
                convertBranchArrayToObject={convertBranchArrayToObject}
                convertLOBSArrayToObject={convertLOBSArrayToObject}
                convertRecipientStatusArrayToObject={convertRecipientStatusArrayToObject}

            />

            <Footer />
        </>
    );
}

export default viewBRANCHPushNotifications;   