import { API } from 'aws-amplify';

import { listExpenseSearch } from '../../../../graphql/customQueries';
import { getExpense, listHotels, listStates } from '../../../../graphql/queries';

export const getExpensesFiltered = async (
  filters,
  lastEvaluatedKey
) => {
  try {
    const response = await API.graphql({
      query: listExpenseSearch,
      variables: {
        assignmentId: filters.assignmentId,
        branch: filters.branch,
        code: filters.code,
        costCenter: filters.costCenter,
        customerId: filters.customerId,
        documentName: filters.documentName,
        driverId: filters.driverId,
        driverName: filters.driverName,
        createdAt: filters.createdAt,
        endDate: filters.endDate,
        expenseItemAirLineId: filters.expenseItemAirLineId,
        expenseItemCarRentalCompanyId: filters.expenseItemCarRentalCompanyId,
        expenseItemHotelCityId: filters.expenseItemHotelCityId,
        expenseItemHotelId: filters.expenseItemHotelId,
        expenseItemHotelStateId: filters.expenseItemHotelStateId,
        notes: filters.notes,
        payWeekEnding: filters.payWeekEnding,
        status: filters.status,
        limit: 100,
        lob: filters.lob,
        lastEvaluatedKey: lastEvaluatedKey
      },
    });

    return response?.data?.listExpenseSearch;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getExpenseById = async id => {
  try {
    const response = await API.graphql({
      query: getExpense,
      variables: {
        id: id,
      },
    });
    return response.data.getExpense;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getExpenseItemsIds = async (filters) => {
  try {
    const response = await API.graphql({
      query: listExpenseItemsIdsFiltered,
      variables: {
        filter: filters,
        limit: 100,
        sortDirection: 'DESC',
      },
    });
    return response.data?.listExpenseItems?.items;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getHotels = async () => {
  try {
    const response = await API.graphql({
      query: listHotels,
      variables: {
        limit: 10000,
      },
    });
    return response?.data?.listHotels?.items;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};


export const getStates = async () => {
  try {
    const response = await API.graphql({
      query: listStates,
      variables: {
        limit: 10000,
      },
    });

    return response.data?.listStates?.items?.length ? response.data?.listStates?.items : [];
  } catch (e) {
    console.log(e);
  }
  return undefined;
};
