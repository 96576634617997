import { API, Storage, graphqlOperation } from 'aws-amplify';
import { getUser } from '../../../graphql/customQueries';
import { getDriversCountForPushNotification, getPushNotification, listPushNotifications } from '../../../graphql/queries';
import { createPushNotification, deletePushNotification, updatePushNotification, publishPushNotification } from '../../../graphql/mutations';

export const getList = async (apiName = '') => {
    try {
        const response = await API.get('centerlineportal', apiName);
        if (response.response_code === 200) {
            return response.response_body;
        }
    } catch (e) {
        if (e?.message?.includes('404')) {
            return [];
        }
        console.log(e);
    }
};

export const getBranchPushNotification = async (id) => {
    try {
        const response = await API.graphql({
            query: getPushNotification,
            variables: {
                id: id
            }
        });
        return response?.data?.getPushNotification;
    } catch (e) {
        console.log('error', e)
    }
}

export const createBranchPushNotification = async (payload) => {
    const input = {
        type: payload?.type,
        subject: payload?.subject,
        message: payload?.message,
        url: payload?.url,
        status: payload?.status,
        lob: payload?.lob,
        branch: payload?.branch,
        recipientStatus: payload?.recipientStatus,
        approvedBy: payload?.approvedBy,
        approvedAt: payload?.approvedAt,
        sentOn: payload?.sentOn,
        createdBy: payload?.createdBy,
        createdAt: payload?.createdAt,
    };
    try {
        const response = await API.graphql(
            graphqlOperation(createPushNotification, {
                input: input
            })
        );
        return response?.data?.createPushNotification;
    } catch (e) {
        console.log('error', e)
    }
};

export const updateBranchPushNotification = async (payload) => {
    const input = {
        id: payload?.id,
        type: payload?.type,
        subject: payload?.subject,
        message: payload?.message,
        url: payload?.url,
        status: payload?.status,
        lob: payload?.lob,
        branch: payload?.branch,
        recipientStatus: payload?.recipientStatus,
        approvedBy: payload?.approvedBy,
        approvedAt: payload?.approvedAt,
        sentOn: payload?.sentOn,
    };
    try {
        const response = await API.graphql(
            graphqlOperation(updatePushNotification, {
                input: input
            })
        );
        return response?.data?.updatePushNotification;
    } catch (e) {
        console.log('error', e)
    }
};

export const getBranchPushNotifications = async (
    filters,
    nextToken
) => {
    try {
        const response = await API.graphql({
            query: listPushNotifications,
            variables: {
                filter: filters,
                nextToken: nextToken,
            },
        });
        return response?.data?.listPushNotifications;
    } catch (e) {
        console.log('error', e)
    }
}

export const deleteBranchPushNotification = async (id) => {
    try {
        const response = await API.graphql({
            query: deletePushNotification,
            variables: {
                input: {
                    id: id
                }
            }
        });
        return response?.data?.getPushNotification;
    } catch (e) {
        console.log('error', e)
    }
}
export const getRoleId = async (id) => {
    try {
        const response = await API.graphql({
            query: getUser,
            id: id
        });
        return response.data.getUser?.appAccess?.items;
    } catch (e) {
        console.log('error', e)
    }
}

export const getCountForPushNotification = async (payload) => {
    try {
        const response = await API.graphql({
            query: getDriversCountForPushNotification,
            variables: {
                type: payload?.type,
                lob: payload?.lob,
                branch: payload?.branch,
                recipientStatus: payload?.recipientStatus
            }
        });
        return response?.data?.getDriversCountForPushNotification;
    } catch (e) {
        console.log('error', e)
    }
}

export const sendPushNotification = async (payload) => {
    try {
        const response = await API.graphql(
            graphqlOperation(publishPushNotification, {
                id: payload?.id,
                type: payload?.type,
                subject: payload?.subject,
                message: payload?.message,
                url: payload?.url,
                status: payload?.status,
                lob: payload?.lob,
                branch: payload?.branch,
                recipientStatus: payload?.recipientStatus,
                approvedBy: payload?.approvedBy,
                approvedAt: payload?.approvedAt,
                sentOn: payload?.sentOn,
            })
        );
        return response?.data?.publishPushNotification;
    } catch (e) {
        console.log('error', e)
    }
}