import { createSlice } from '@reduxjs/toolkit';

const checklistTemplatesSlice = createSlice({
    name: 'checkListTemplates',
    initialState: {
        listData: []
    },
    reducers: {
        setList: (state, { payload }) => {
            state.listData = payload;
        },
    },
});

export default checklistTemplatesSlice.reducer;

export const { setList } = checklistTemplatesSlice.actions;

