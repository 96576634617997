import React, { useCallback, useMemo, useState } from 'react';

import { Checkbox, Radio } from '@mui/material';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { debounce } from 'lodash';
import { Row, Form, InputGroup, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import { getCitiesFiltered } from './operations';
import Receipt from './Receipt';
import SharedReceipt from './SharedReceipt';
import { ExpenseReceiptUploader } from '../../../../components';
import ExpenseForm from '../../../../components/ExpenseForm';
import { newDateFormatted } from '../../../../utils';
import { formatPhoneNumber, isBilledOrTransmitted } from '../../../../utils/utilities';
import DocumentRow from '../documentRow';
import { checkClientFlatFee, uploadExpenseFile } from '../operations';

import './styles.scoped.css';

dayjs.extend(customParseFormat);

const FormContent = ({
  id,
  formData,
  form,
  singleOrShared,
  errors,
  formHasChanged,
  isLoading,
  isLoadingCustomers,
  isLoadingBranches,
  location,
  setUploading,
  setUploadError,
  sharedReceipts,
  receipts,
  totalToBeBilled,
  auditKey,
  isCreatingItems,
  saveDisabled,
  setSharedReceipts,
  sharedReceiptErrors,
  setSharedReceiptsErrors,
  receiptsErrors,
  isSettingState,
  isSettingCity,
  hasChanges,
  setHasChanges,
  setForm,
  setErrors,
  setReceipts,
  setSingleOrShared,
  setShowCancelDialog,
  confirmCreateExpense,
  setShowFFDialog,
  setShowNonFFDialog,
  preventMultipleSave,
  setSaveDisabled,
  fieldsAreValid,
  receiptsHaveFiles,
  setShowReceiptFilesDialog,
  clients,
  data,
  deleteExpenseFile,
  currentUser,
  setReceiptsErrors,
  currentStateRef,
  assignmentLineOfBusinessItems,
}) => {
  const navigate = useNavigate();

  const [formCityInput, setFormCityInput] = useState('');
  const [formattedPhoneNumber, setFormPhoneNumber] = useState('');
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const [inputSave, setSave] = useState('');

  const newStateRef = useState();

  let currentCities = [];
  let prevToken = '';

  const getCitiesBatch = async (nextToken = null) => {
    if (prevToken !== nextToken) {
      setIsLoadingCities(true);
      prevToken = nextToken;
      const aData = await getCitiesFiltered({
        state: newStateRef.current[0]?.fieldValue,
        token: nextToken,
      });
      const token = aData?.nextToken;

      if (aData?.items) {
        const items = aData?.items;
        currentCities = currentCities.concat(items);
      }
      if (token) {
        getCitiesBatch(token);
      } else {
        setIsLoadingCities(false);
        setField([
          ...newStateRef.current,
          {
            fieldName: 'selectedCities',
            fieldValue: currentCities,
          },
        ]);
      }
    }
  };

  const handleStateChange = async newVal => {
    setFormCityInput('');
    newStateRef.current = newVal;
    await getCitiesBatch();
  };

  const formHotelOptions = useMemo(
    () =>
      formData?.hotels
        ?.filter(h => h.status === 'Active')
        ?.sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(({ name, id: scopedId }) => ({
          label: name,
          value: scopedId,
        })),
    [formData?.hotels],
  );

  const formStateOptions = formData?.states
    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(({ name, id }) => ({
      label: name,
      value: id,
    }));

  const formCityOptions = useMemo(
    () =>
      form?.selectedCities
        ?.filter(h => h.status === 'Active')
        ?.sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(({ name, id: scopedId }) => ({
          label: name,
          value: scopedId,
        })),
    [form?.selectedCities],
  );

  const setField = (fields, value) => {
    if (!hasChanges) {
      setHasChanges(true);
    }
    if (Array.isArray(fields)) {
      const itemsToAdd = {};
      const newErrors = {
        ...errors,
      };
      fields.forEach(field => {
        itemsToAdd[(field?.fieldName)] = field.fieldValue;

        if (errors && !!errors[field.fieldName]) {
          delete newErrors[(field?.fieldName)];
        }
      });
      setForm({
        ...form,
        ...itemsToAdd,
      });
      setErrors({ ...newErrors });
    } else {
      if (fields === 'nonBillable' && value === true) {
        setForm({
          ...form,
          [fields]: value,
          status: 'Non-billable',
        });
      } else if (fields === 'nonBillable' && value === false) {
        setForm({
          ...form,
          [fields]: value,
          status: 'Unbilled',
        });
      } else if (fields === 'flatFeeBilling' && value === false) {
        setForm({
          ...form,
          [fields]: value,
          nonBillable: false,
          status: 'Unbilled',
          code: 'XH',
        });
      } else if (fields === 'flatFeeBilling' && value === true) {
        setForm({
          ...form,
          [fields]: value,
          nonBillable: true,
          status: 'Flat Fee Billing',
          code: `XFFBH${singleOrShared === 'shared' ? 'S' : ''}`,
        });
      } else {
        setForm(prevState => ({
          ...prevState,
          [fields]: value,
        }));
      }

      if (errors && !!errors[fields]) {
        const errorsCopy = {
          ...errors,
        };
        delete errorsCopy[fields];
        setErrors(errorsCopy);
      }
    }
  };

  const getTotalProjectedAmount = newReceipts => {
    const projectedAmounts = newReceipts.reduce((result, item) => {
      if (item?.projectedAmount) {
        result += item?.projectedAmount || 0;
      }
      return result;
    }, 0);
    setField('totalProjectedAmount', parseFloat(projectedAmounts).toFixed(2));
  };

  const setReceiptField = (id, field, value, scopeReceipts) => {
    const receiptsCopy = [...scopeReceipts];
    if (field === 'receiptAmount') {
      if (receiptsCopy[id]?.markup) {
        const multiplier = (1 + parseFloat(receiptsCopy[id]?.markup).toFixed(2) / 100).toFixed(2);

        receiptsCopy[id].expenseTotal = parseFloat(value) * multiplier;
      } else {
        receiptsCopy[id].expenseTotal = value;
      }
    }
    if (field === 'markup' && receiptsCopy[id]?.receiptAmount) {
      const multiplier = (1 + parseFloat(value).toFixed(2) / 100).toFixed(2);

      receiptsCopy[id].expenseTotal = parseFloat(receiptsCopy[id]?.receiptAmount) * multiplier;
    }
    if (
      (field === 'checkInDate' && receiptsCopy[id]?.lastNightOfStay) ||
      (field === 'lastNightOfStay' && receiptsCopy[id]?.checkInDate)
    ) {
      let difference;
      if (field === 'checkInDate') {
        difference = dayjs(receiptsCopy[id]?.lastNightOfStay)?.diff(value, 'day') || 0;
      }
      if (field === 'lastNightOfStay') {
        difference = dayjs(value)?.diff(receiptsCopy[id]?.checkInDate, 'day') || 0;
      }
      if (difference && receiptsCopy[id]?.dailyCharge) {
        receiptsCopy[id].projectedAmount = (difference + 1) * receiptsCopy[id].dailyCharge;
        getTotalProjectedAmount(receiptsCopy);
      }
      receiptsCopy[id][field] = value;
      receiptsCopy[id].projectedDays = difference + 1;
      setReceipts(receiptsCopy);
    } else {
      if (field === 'dailyCharge' && receiptsCopy[id].projectedDays) {
        receiptsCopy[id].projectedAmount = receiptsCopy[id].projectedDays * value;
        getTotalProjectedAmount(receiptsCopy);
      }
      receiptsCopy[id][field] = value;
      setReceipts(receiptsCopy);
    }
  };

  const setDebouncedValue = debounce(r => {
    setReceiptField(r.id, r.field, r.value, r.receipts);
  }, 2000);

  const debouncedSetReceiptField = useCallback(value => setDebouncedValue(value), []);

  const handleRadioButtonChange = event => {
    setSingleOrShared(event.target.value);
  };

  const handleCancel = async () => (hasChanges ? setShowCancelDialog(true) : navigate(assignmentLineOfBusinessItems.to));


  const handleVerifyConfirm = async () => {
    const clientIsFF = await checkClientFlatFee(form?.customerId);
    if (clientIsFF && form?.flatFeeBilling) {
      await confirmCreateExpense({ isFF: true });
    }

    if (clientIsFF && !form?.flatFeeBilling) {
      setShowFFDialog(true);
    }
    if (!clientIsFF && form?.flatFeeBilling) {
      setShowNonFFDialog(true);
    }
    if (!clientIsFF && !form?.flatFeeBilling) {
      await confirmCreateExpense({
        route: assignmentLineOfBusinessItems.to,
      });
    }
  };

  const getTotalToBeBilledColor = () => {
    let color = 'transparent';
    if (singleOrShared === 'shared') {
      if (
        totalToBeBilled === form?.totalReceiptAmount?.toString() &&
        form?.totalReceiptAmount?.toString() !== '0' &&
        form?.totalReceiptAmount?.toString() !== '0.00'
      ) {
        color = '#caf982';
      } else {
        color = '#eec6bd';
      }
    }

    return color;
  };

  const setReceiptFields = (id, fields) => {
    const receiptsCopy = [...receipts];

    fields.forEach(field => {
      receiptsCopy[id][(field?.fieldName)] = field.fieldValue;
    });
    setReceipts(receiptsCopy);
  };

  const handleCreateExpense = async () => {
    preventMultipleSave(setSaveDisabled);
    if (fieldsAreValid()) {
      if (form?.status === 'Non-billable') {
        await confirmCreateExpense({ route: '' });
      } else if (
        (receiptsHaveFiles() && singleOrShared === 'single') ||
        (receiptsHaveFiles() && singleOrShared === 'shared' && form.documentName)
      ) {
        await handleVerifyConfirm();
      } else {
        setShowReceiptFilesDialog(true);
      }
    }
  };

  const clientOptions = clients?.map(({ CUST_ID, CUST_NAME }) => ({
    label: `${CUST_NAME} - ${CUST_ID}`,
    value: CUST_ID,
  }));

  const branchOptions = data?.branches?.map(({ Branch_Code, Branch_Name }) => ({
    label: `${Branch_Name} - ${Branch_Code}`,
    value: Branch_Code,
  }));

  const handleAddReceipt = () => {
    if (singleOrShared === 'shared') {
      setSharedReceipts([...sharedReceipts, { markup: '0' }]);
    } else {
      setReceipts([...receipts, { markup: '0' }]);
    }
  };

  const handleDeleteRow = async id => {
    if (singleOrShared === 'shared') {
      const receiptsCopy = sharedReceipts.filter((r, index) => index !== id);
      setSharedReceipts(receiptsCopy);
    } else {
      if (receipts[id].documentName) {
        await deleteExpenseFile(receipts[id].documentName);
      }
      const receiptsCopy = receipts.filter((r, index) => index !== id);
      setReceipts(receiptsCopy);
    }
    getTotalProjectedAmount(receipts);
  };

  const setReceiptFile = async ({ id, fileName, fileSize }) => {
    const receiptsCopy = [...receipts];
    receiptsCopy[id].uploadedDate = new Date().toISOString();
    receiptsCopy[id].documentName = fileName;
    receiptsCopy[id].documentSize = Math.round(fileSize);
    receiptsCopy[id].uploadedByName = currentUser?.name;

    const receiptsErrorsCopy = [...receiptsErrors];

    if (receiptsErrorsCopy[id]?.uploadedDate) {
      delete receiptsErrorsCopy[id].uploadedDate;
    }

    if (receiptsErrorsCopy[id]?.documentName) {
      delete receiptsErrorsCopy[id].documentName;
    }

    if (receiptsErrorsCopy[id]?.uploadedByName) {
      delete receiptsErrorsCopy[id].uploadedByName;
    }

    if (receiptsErrorsCopy.length) {
      receiptsErrorsCopy.splice(id, 1);
    }

    setReceiptsErrors(receiptsErrorsCopy);
    setReceipts(receiptsCopy);
  };

  const setFormReceipt = async ({ fileName, fileSize }) => {
    const formCopy = { ...form };
    formCopy.uploadedDate = new Date().toISOString();
    formCopy.documentName = fileName;
    formCopy.documentSize = Math.round(fileSize);
    formCopy.uploadedByName = currentUser?.name;

    const formErrorsCopy = {
      ...errors,
    };

    if (formErrorsCopy?.uploadedDate) delete formErrorsCopy.uploadedDate;

    if (formErrorsCopy?.documentName) delete formErrorsCopy.documentName;

    if (formErrorsCopy?.uploadedByName) {
      delete formErrorsCopy.uploadedByName;
    }

    setErrors(formErrorsCopy);
    setForm(formCopy);
  };

  const customStyles = width => ({
    control: provided => ({
      ...provided,
      width: width,
      height: '30px',
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: '160px',
    }),
  });

  const deleteDocumentFromReceipt = async id => {
    const receiptsCopy = [...receipts];

    await deleteExpenseFile(receiptsCopy[id].documentName);

    if (receiptsCopy[id]?.uploadedDate) {
      delete receiptsCopy[id].uploadedDate;
    }

    if (receiptsCopy[id]?.documentName) {
      delete receiptsCopy[id].documentName;
    }

    if (receiptsCopy[id]?.uploadedByName) {
      delete receiptsCopy[id].uploadedByName;
    }

    setReceipts(receiptsCopy);
  };

  const deleteDocumentFromForm = async () => {
    const formCopy = { ...form };

    await deleteExpenseFile(formCopy.documentName);

    if (formCopy?.uploadedDate) delete formCopy.uploadedDate;

    if (formCopy?.documentName) delete formCopy.documentName;

    if (formCopy?.uploadedByName) delete formCopy.uploadedByName;

    setForm(formCopy);
  };

  const setCurrentStateRef = val => {
    currentStateRef.current = val;
  };

  return (
    <Form>
      <Row
        style={{
          margin: '16px 0 0 0',
        }}
        className="top-row">
        <div
          style={{
            width: '40%',
          }}
          className="top-col">
          <h1 className="title">Hotel Expense</h1>
        </div>
        <div className="top-col">
          <h1 className="label">Code</h1> <h1 className="top-value">{form?.code}</h1>
        </div>
        <div className="top-col">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '225px',
            }}>
            <h1 className="label">Date</h1>
            <h1 className="top-value">{newDateFormatted}</h1>
          </div>
          {(form?.status === 'Unbilled' ||
            form.status === 'Non-billable' ||
            form.status === 'Ready to Bill' ||
            form.status === 'Flat Fee Billing') && (
              <Form.Group
                controlId="flatFeeBilling"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginTop: '25px',
                }}>
                <Form.Label>Flat Fee Billing</Form.Label>
                <Checkbox
                  style={{
                    color: 'red',
                    height: 0,
                    width: 0,
                    marginTop: '5px',
                  }}
                  onChange={event => {
                    setField('flatFeeBilling', event.target.checked);
                  }}
                  checked={!!form?.flatFeeBilling}
                  defaultValue={false}
                />
              </Form.Group>
            )}
        </div>
      </Row>
      <Row
        className="top-row"
        style={{
          justifyContent: 'space-between',
        }}>
        <div
          className="top-col"
          style={{
            width: '40%',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <h1
            className="label"
            style={{
              marginLeft: '15px',
              marginTop: '10px',
            }}>
            Single Driver or Shared Invoice?
          </h1>
          <div className="radio-container">
            <div className="radio-group">
              <Radio
                style={{
                  color: '#da0f2e',
                  transform: 'scale(1)',
                }}
                labe="Single Drive"
                value="single"
                onChange={handleRadioButtonChange}
                checked={singleOrShared === 'single'}
                disabled={true}
              />
              <span
                className="label"
                style={{
                  marginLeft: '5px',
                }}>
                Single Driver
              </span>
            </div>
            <div className="radio-group">
              <Radio
                style={{
                  color: '#da0f2e',
                  transform: 'scale(1)',
                }}
                title="Shared Invoice"
                value="shared"
                onChange={handleRadioButtonChange}
                checked={singleOrShared === 'shared'}
                disabled={true}
              />
              <span
                className="label"
                style={{
                  marginLeft: '5px',
                }}>
                Shared Invoice
              </span>
            </div>
          </div>
        </div>
        <div
          className="top-col"
          style={{
            width: '31%',
          }}
        />
        {singleOrShared === 'shared' && (
          <div
            className="top-col"
            style={{
              width: '29%',
            }}>
            <Form.Group controlId="status">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Status
              </Form.Label>
              <Form.Control
                style={{
                  width: '225px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="status"
                value={form?.status}
                readOnly
                disabled
              />
            </Form.Group>
            <Form.Group
              controlId="nonBillable"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}>
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Non-billable
              </Form.Label>

              <Checkbox
                style={{
                  color: form?.flatFeeBilling ? 'gray' : 'red',
                  height: 0,
                  width: 0,
                  marginTop: '5px',
                }}
                onChange={event => {
                  setField('nonBillable', event.target.checked);
                }}
                checked={form?.nonBillable}
                defaultValue={false}
                disabled={!!form?.flatFeeBilling}
              />
            </Form.Group>
          </div>
        )}
      </Row>
      {singleOrShared === 'single' && (
        <ExpenseForm
          customStyles={customStyles}
          setField={setField}
          errors={errors}
          form={form}
          formHasChanged={formHasChanged}
          setForm={setForm}
        />
      )}
      {singleOrShared === 'shared' && (
        <Row
          className="top-row"
          style={{
            margin: '5px 0 0 0',
          }}>
          <div
            className="top-col"
            style={{
              width: '40%',
            }}>
            <Form.Group controlId="customer">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                <div className="required">Customer Name & Customer Number</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  styles={customStyles('385px')}
                  defaultInputValue={form?.selectedCustomer}
                  isDisabled={isLoading && !isBilledOrTransmitted(form)}
                  options={clientOptions}
                  isClearable
                  onChange={(event, action) => {
                    if (action.action === 'clear') {
                      setField([
                        {
                          fieldName: 'customerId',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'customerName',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'selectedCustomer',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'branch',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'branchName',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'branchSelection',
                          fieldValue: '',
                        },
                      ]);
                    }
                    if (action.action === 'select-option') {
                      setField([
                        {
                          fieldName: 'customerId',
                          fieldValue: event.value,
                        },
                        {
                          fieldName: 'customerName',
                          fieldValue: event.label.split(' -').shift(),
                        },
                        {
                          fieldName: 'selectedCustomer',
                          fieldValue: event.label,
                        },
                      ]);
                    }
                  }}
                  value={clientOptions?.filter(option => option.value === form?.customerId)}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingCustomers}
                />
              </div>

              {errors?.customerId && <div className="error">{errors?.customerId}</div>}
            </Form.Group>
          </div>
          <div className="top-col">
            <Form.Group controlId="branch">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                <div className="required">Client Branch</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  styles={customStyles('225px')}
                  defaultInputValue={form?.branchSelection}
                  options={branchOptions}
                  isDisabled={(isLoading && !isBilledOrTransmitted(form)) || !form?.customerId}
                  onChange={(event, action) => {
                    if (action.action === 'select-option') {
                      setField([
                        {
                          fieldName: 'branch',
                          fieldValue: event.value,
                        },
                        {
                          fieldName: 'branchName',
                          fieldValue: event.label.split(' -').shift(),
                        },
                        {
                          fieldName: 'branchSelection',
                          fieldValue: event.label,
                        },
                      ]);
                    }
                    if (action.action === 'clear') {
                      setField([
                        {
                          fieldName: 'branch',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'branchName',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'branchSelection',
                          fieldValue: '',
                        },
                      ]);
                    }
                  }}
                  isClearable
                  value={branchOptions?.filter(option => option.value === form?.branch)}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingBranches}
                />
              </div>

              {errors?.branch && <div className="error">{errors?.branch}</div>}
            </Form.Group>
          </div>
          <div className="top-col">
            <Form.Group controlId="costCenter">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Cost Center
              </Form.Label>
              <Form.Control
                style={{
                  width: '225px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="costCenter"
                value={
                  location.pathname.includes('mobile')
                    ? process.env.REACT_APP_MDS_COST_CENTER
                    : process.env.REACT_APP_DMS_COST_CENTER
                }
                readOnly
                disabled
              />
            </Form.Group>
          </div>
        </Row>
      )}
      {singleOrShared === 'shared' && (
        <div>
          <Row className="top-row" style={{ margin: '5px 0 0 0', alignItems: 'start' }}>
            <div
              className="top-col"
              style={{
                width: '364px',
                justifyContent: 'flex-start',
              }}>
              <Form.Group controlId="hotel">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">Hotel</div>
                </Form.Label>

                <div className="dropdown-spinner-container">
                  <Select
                    styles={customStyles('300px')}
                    options={formHotelOptions}
                    onChange={event => {
                      setField([
                        {
                          fieldName: 'expenseHotelId',
                          fieldValue: event.value,
                        },

                        {
                          fieldName: 'selectedHotel',
                          fieldValue: event.label,
                        },
                      ]);
                    }}
                    defaultInputValue={form?.selectedHotel}
                  />
                </div>
                {errors?.expenseHotelId && <div className="error">{errors?.expenseHotelId}</div>}
              </Form.Group>
            </div>
            <div
              className="top-col"
              style={{
                width: '284px',
              }}>
              <Form.Group controlId="hotelConfirmationNumber">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">Hotel Confirmation Number</div>
                </Form.Label>
                <Form.Control
                  key="hotelConfirmationNumber"
                  style={{
                    width: '225px',
                    height: '30px',
                    paddingLeft: 10,
                  }}
                  type="input"
                  name="hotelConfirmationNumber"
                  defaultValue={form?.hotelConfirmationNumber}
                  onBlur={event => {
                    setField('hotelConfirmationNumber', event.target.value);
                  }}
                  isInvalid={!!errors?.hotelConfirmationNumber}
                />
                <Form.Control.Feedback type="invalid">{errors?.hotelConfirmationNumber}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div
              className="top-col"
              style={{
                width: '284px',
              }}>
              <Form.Group controlId="hotelPhoneNumber">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">Hotel Phone Number</div>
                </Form.Label>
                <Form.Control
                  key="hotelPhoneNumber"
                  style={{
                    width: '225px',
                    height: '30px',
                    paddingLeft: 10,
                  }}
                  type="input"
                  name="hotelPhoneNumber"
                  onBlur={event => {
                    const str = event.target.value?.replace(/\D/, '');
                    setField('hotelPhoneNumber', formatPhoneNumber(str));
                  }}
                  onChange={event => {
                    setFormPhoneNumber(formatPhoneNumber(event.target.value));
                  }}
                  value={formattedPhoneNumber || form?.hotelPhoneNumber}
                  isInvalid={!!errors?.hotelPhoneNumber}
                />
                <Form.Control.Feedback type="invalid">{errors?.hotelPhoneNumber}</Form.Control.Feedback>
              </Form.Group>
            </div>
            {!form?.documentName && (
              <div
                className="top-col"
                style={{
                  width: '200px',
                }}>
                <Form.Group
                  controlId="receipt"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <Form.Label
                    style={{
                      marginTop: '15px',
                    }}>
                    <div className="required">Receipt</div>
                  </Form.Label>

                  <Form.Control
                    name="receipt"
                    as="span"
                    style={{
                      border: 'none',
                      outline: 'none',
                      paddingLeft: 0,
                      height: 'auto',
                    }}>
                    <ExpenseReceiptUploader
                      func={uploadExpenseFile}
                      setReceiptFile={setFormReceipt}
                      uploadStart={() => setUploading(true)}
                      uploadEnd={() => setUploading(false)}
                      uploadError={() => {
                        setUploading(false);
                        setUploadError('Upload failed. Please ensure you have a network connection and try again.');
                      }}
                      expenseId={id}
                    />
                  </Form.Control>
                </Form.Group>
              </div>
            )}
          </Row>

          {form?.documentName && (
            <DocumentRow
              deleteDocumentFromReceipt={deleteDocumentFromForm}
              receipt={form}
              disabled={isBilledOrTransmitted(form) || form.status === 'Voided'}
            />
          )}
          <Row
            className="top-row"
            style={{
              margin: '5px 0 0 0',
            }}>
            <div
              className="top-col"
              style={{
                width: '364px',
                justifyContent: 'flex-start',
              }}>
              <Form.Group controlId="hotelStreetAddress">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">Hotel Street Address</div>
                </Form.Label>
                <Form.Control
                  key="$hotelStreetAddress"
                  style={{
                    width: '300px',
                    height: '30px',
                    paddingLeft: 10,
                  }}
                  type="input"
                  name="hotelStreetAddress"
                  defaultValue={form?.hotelStreetAddress}
                  onBlur={event => {
                    setField('hotelStreetAddress', event.target.value);
                  }}
                  isInvalid={!!errors?.hotelStreetAddress}
                />
                <Form.Control.Feedback type="invalid">{errors?.hotelStreetAddress}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div
              className="top-col"
              style={{
                width: '284px',
              }}>
              <Form.Group controlId="expenseHotelStateId">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required"> State</div>
                </Form.Label>
                <div className="dropdown-spinner-container">
                  <Select
                    styles={customStyles('225px')}
                    options={formStateOptions}
                    onChange={event => {
                      handleStateChange([
                        {
                          fieldName: 'expenseHotelStateId',
                          fieldValue: event.value,
                        },

                        {
                          fieldName: 'selectedState',
                          fieldValue: event.label,
                        },
                      ]);
                    }}
                    defaultInputValue={form.selectedState}
                  />
                </div>
                {errors?.expenseHotelStateId && <div className="error">{errors?.expenseHotelStateId}</div>}
              </Form.Group>
            </div>
            <div
              className="top-col"
              style={{
                width: '284px',
              }}>
              <Form.Group controlId="expenseHotelCityId">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required"> City</div>
                </Form.Label>
                <div className="dropdown-spinner-container">
                  <Select
                    isDisabled={!form?.selectedState || isLoadingCities}
                    onFocus={() => {
                      setCurrentStateRef(form?.expenseHotelStateId);
                      setFormCityInput(inputSave);
                      setSave('');
                    }}
                    onInputChange={(value, action) => {
                      if (action.action === 'input-change') {
                        setFormCityInput(value);
                      }
                    }}
                    styles={customStyles('225px')}
                    options={formCityOptions}
                    placeholder="Select a City"
                    inputValue={formCityInput || inputSave}
                    onChange={event => {
                      setField([
                        {
                          fieldName: 'expenseHotelCityId',
                          fieldValue: event.value,
                        },
                        {
                          fieldName: 'selectedCity',
                          fieldValue: event.label,
                        },
                      ]);
                      setSave(event.label);
                      setFormCityInput(event.label);
                    }}
                  />
                </div>
                {errors?.expenseHotelCityId && <div className="error">{errors?.expenseHotelCityId}</div>}
              </Form.Group>
            </div>
            <div
              className="top-col"
              style={{
                width: '284px',
              }}>
              <Form.Group controlId="totalReceiptAmount">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div>Total Receipt Amount</div>
                </Form.Label>
                <InputGroup
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '150px',
                  }}>
                  <span
                    style={{
                      position: 'absolute',
                      left: '10px',
                      zIndex: 10000,
                    }}>
                    $
                  </span>
                  <Form.Control
                    key="$formtotalReceiptAmount"
                    style={{
                      width: '150px',
                      height: '30px',
                      padding: 0,
                      paddingLeft: '25px',
                      backgroundColor: getTotalToBeBilledColor(),
                    }}
                    type="input"
                    name="totalReceiptAmount"
                    defaultValue={form?.totalReceiptAmount}
                    onBlur={event => {
                      setField('totalReceiptAmount', event.target.value);
                    }}
                    isInvalid={!!errors?.totalReceiptAmount}
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">{errors?.totalReceiptAmount}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
        </div>
      )}
      <div className="gray-wrapper">
        {singleOrShared === 'shared'
          ? sharedReceipts?.map((receipt, index) => (
            <SharedReceipt
              receipt={receipt}
              id={index}
              key={index}
              sharedReceipts={sharedReceipts}
              getTotalProjectedAmount={getTotalProjectedAmount}
              setSharedReceipts={setSharedReceipts}
              sharedReceiptErrors={sharedReceiptErrors}
              setSharedReceiptsErrors={setSharedReceiptsErrors}
              receiptsErrors={receiptsErrors}
              handleDeleteRow={handleDeleteRow}
              form={form}
              expenseId={id}
            />
          ))
          : receipts?.map((receipt, index) => (
            <Receipt
              receipt={receipt}
              id={index}
              key={index}
              setReceiptFields={setReceiptFields}
              formData={formData}
              setSave={setSave}
              isSettingState={isSettingState}
              isSettingCity={isSettingCity}
              receipts={receipts}
              handleDeleteRow={handleDeleteRow}
              customStyles={customStyles}
              receiptsErrors={receiptsErrors}
              setReceiptField={setReceiptField}
              debouncedSetReceiptField={debouncedSetReceiptField}
              inputSave={inputSave}
              deleteDocumentFromReceipt={deleteDocumentFromReceipt}
              setReceiptFile={setReceiptFile}
              setUploading={setUploading}
              setUploadError={setUploadError}
              form={form}
              isCR={false}
              hotelOptions={formHotelOptions}
              expenseId={id}
            />
          ))}
        <Row
          className="top-row"
          style={{
            alignItems: 'flex-start',
          }}>
          {/* remove button temporary  */}
          {/* <div onClick={handleAddReceipt} className="another-receipt">
              {!isPartitionExpense && <>Add Another {singleOrShared === 'shared' ? 'Driver' : 'Receipt'}</>}
            </div> */}
          <div className='another-receipt'></div>
          <div
            className="top-col"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}>
            <Form.Group controlId="startDate">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Start Date
              </Form.Label>
              <Form.Control
                style={{
                  width: '150px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="startDate"
                value={form?.startDate?.substring(0, 10)}
                readOnly
                disabled
              />
            </Form.Group>
            <Form.Group controlId="endDate">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                End Date
              </Form.Label>
              <Form.Control
                style={{
                  width: '150px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="endDate"
                value={form?.endDate?.substring(0, 10)}
                readOnly
                disabled
              />
            </Form.Group>
          </div>
          <Form.Group controlId="totalToBeBilled">
            <Form.Label
              style={{
                marginTop: '15px',
              }}>
              Total To Be Billed
            </Form.Label>
            <InputGroup
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '150px',
                opacity: '0.7',
                backgroundColor: getTotalToBeBilledColor(),
              }}>
              <span
                style={{
                  position: 'absolute',
                  left: '10px',
                  zIndex: 10000,
                }}>
                $
              </span>
              <Form.Control
                style={{
                  width: '225px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: getTotalToBeBilledColor(),
                  marginLeft: '20px',
                }}
                as="input"
                name="location"
                value={totalToBeBilled}
                readOnly
                disabled
              />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="totalProjectedAmount">
            <Form.Label
              style={{
                marginTop: '15px',
              }}>
              Total Projected Amount
            </Form.Label>
            <InputGroup
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '150px',
                opacity: '0.7',
              }}>
              <span
                style={{
                  position: 'absolute',
                  left: '10px',
                  zIndex: 10000,
                }}>
                $
              </span>
              <Form.Control
                style={{
                  width: '225px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  marginLeft: '20px',
                }}
                as="input"
                name="location"
                readOnly
                disabled
                value={
                  form?.totalProjectedAmount
                    ? parseFloat(form?.totalProjectedAmount).toFixed(2)
                    : parseFloat(0).toFixed(2)
                }
              />
            </InputGroup>
          </Form.Group>
        </Row>
        <Row
          className="top-row"
          style={{
            margin: '5px 0 0 0',
            alignItems: 'flex-start',
          }}>
          <div
            className="top-col"
            style={{
              width: '70%',
            }}>
            <Form.Group controlId="notes">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Notes
              </Form.Label>
              <Form.Control
                style={{
                  width: '533px',
                  height: '75px',
                }}
                as="textarea"
                name="notes"
                defaultValue={form?.notes}
                onBlur={event => {
                  setField('notes', event.target.value);
                }}
              />
            </Form.Group>
          </div>
          <div
            className="top-col"
            style={{
              alignItems: 'flex-start',
            }}>
            <Form.Group controlId="auditKey">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                Audit Key
              </Form.Label>
              <Form.Control
                style={{
                  width: '200px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  opacity: '0.7',
                }}
                as="input"
                name="location"
                value={auditKey}
                readOnly
                disabled
              />
            </Form.Group>
          </div>
        </Row>
      </div>
      {(isCreatingItems || isLoading) && (
        <div className="overlay-box">
          <Spinner className="spinner" animation="border" />
        </div>
      )}

      <Row
        className="top-row"
        style={{
          margin: '50px 0 0 0',
          justifyContent: 'center',
        }}>
        <Button
          disabled={saveDisabled}
          className="button-text"
          style={{
            width: '140px',
            marginRight: '20px',
          }}
          onClick={handleCreateExpense}>
          <span>SAVE</span>
        </Button>
        <Button
          onClick={handleCancel}
          className="button-secondary"
          style={{
            width: '140px',
          }}>
          <span>CANCEL</span>
        </Button>
      </Row>
    </Form>
  );
};

export default FormContent;
