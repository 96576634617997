import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';

import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useBeforeunload } from 'react-beforeunload';
import { Button } from 'react-bootstrap';
import { MdWeb } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import EditFormContent from './EditFormContent';
import { getCityById, getFormData, getStateById, getHotelById, getAudit } from './operations';
import { Breadcrumbs, ExpenseHistoryTable, ExpensesWindow, ModalConfirmation, SubHeader } from '../../../../components';
import './styles.scoped.css';
import { resetData } from '../../../../components/ExpenseForm/expenseFormSlice';
import { formatIsoToDate } from '../../../../utils';
import { isBilledOrTransmitted } from '../../../../utils/utilities';
import { getUserInfo } from '../../../DriverQualification/ViewDQF/operations';
import { getCalculatedVariance, receiptsHaveReceiptAmount, FlatFeeModal } from '../expensesUtils';
import {
  deleteExpenseFile,
  getClientBranches,
  getClients,
  getExpense,
  updateExpense,
  updateExpenseItem,
  deleteExpense,
  deleteExpenseItem,
  createExpenseItem,
  createExpense,
  checkClientFlatFee,
} from '../operations';
import { dateToTimeZero } from '../TransferToUltraStaff/utils';

dayjs.extend(customParseFormat);

const requiredReceiptFields = {
  single: [
    'expenseItemHotelId',
    'hotelConfirmationNumber',
    'hotelPhoneNumber',
    'hotelStreetAddress',
    'expenseItemHotelStateId',
    'expenseItemHotelCityId',
    'checkInDate',
    'lastNightOfStay',
    'dailyCharge',
    'receiptAmount',
    'markup',
  ],
  shared: [
    'hotelDriverId',
    'hotelDriverName',
    'hotelAssignmentId',
    'checkInDate',
    'lastNightOfStay',
    'dailyCharge',
    'receiptAmount',
    'markup',
  ],
};

const requiredFields = {
  single: ['driverId', 'driverName', 'assignmentId', 'status', 'customerId', 'customerName', 'branch'],
  shared: [
    'customerId',
    'customerName',
    'branch',
    'expenseHotelId',
    'expenseHotelStateId',
    'expenseHotelCityId',
    'hotelConfirmationNumber',
    'hotelPhoneNumber',
    'hotelStreetAddress',
  ],
};

const EditHotel = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const assignmentLineOfBusiness = useRef('');
  const dispatch = useDispatch();

  const [singleOrShared, setSingleOrShared] = useState('single');
  const [formData, setFormData] = useState();
  const [auditKey, setAuditKey] = useState('--');
  const [currentUser, setCurrentUser] = useState('');
  const [totalToBeBilled, setTotalToBeBilled] = useState('0.00');
  const [receipts, setReceipts] = useState([{ markup: '0', receiptAmount: '0.0' }]);
  const [clients, setClients] = useState([]);
  const [branches, setBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const formSettedRef = useRef(false);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [isLoadingBranches, setIsLoadingBranches] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [receiptModified, setReceiptModified] = useState(false);
  const [showReceiptModified, setShowReceiptModified] = useState(false);
  const [deletedReceipts, setDeletedReceipts] = useState([]);
  const [deletedReceiptDocs, setDeletedReceiptDocs] = useState([]);
  const [expenseDate, setExpenseDate] = useState('');
  const [showReceiptFilesDialog, setShowReceiptFilesDialog] = useState(false);
  const [showConfirmRTB, setShowConfirmRTB] = useState(false);
  const [showCanPressRTB, setShowCanPressRTB] = useState(false);
  const [errorModalOpened, setErrorModalOpened] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState();
  const hasSetReceiptOptions = useRef(false);
  const [expenseHistoryData, setExpenseHistoryData] = useState([]);
  const { user } = useSelector(state => state.admin);
  const { clientMenu } = useSelector(state => state.expenseEntry);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [assignmentLineOfBusinessItems, setAssignmentLineOfBusinessItems] = useState({});

  const getAssignmentLineOfBusinessItems = () => {
    clientMenu?.menuItems?.map(i => {
      if (location.pathname.includes(i.to)) {
        setAssignmentLineOfBusinessItems(i);
      }
    })
  };

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      document.querySelector('div.root').style.background = '#D0CED0';
      document.querySelector('span.wrap').style.background = '#ffff';
      document.querySelector('.left-title').style.color = '#ffff';
      document.querySelector('.right-title').style.color = '#66CCFF';
    }
    return () => {
      document.querySelector('div.root').style.background = '#ffff';
    }
  }, []);

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      assignmentLineOfBusiness.current = 'DMS';
    }
    if (location.pathname.includes('mobile-driver-solutions')) {
      assignmentLineOfBusiness.current = 'MDS';
    }
  }, [])

  useLayoutEffect(() => {
    if (assignmentLineOfBusiness.current) {
      handleGetData();
    }
  }, [assignmentLineOfBusiness.current]);

  useEffect(() => {
    if (uploadError) {
      // eslint-disable-next-line no-undef
      alert(uploadError);
      setUploadError(null);
    }
  }, [uploadError]);

  useEffect(
    () => () => {
      dispatch(resetData());
    },
    [],
  );

  const [sharedReceipts, setSharedReceipts] = useState([{ markup: '0' }]);
  const [sharedReceiptErrors, setSharedReceiptsErrors] = useState([{}]);
  const hasSetFormOptions = useRef(false);
  const [isReadyToBill, setIsReadyToBill] = useState(false);
  const [showFFDialog, setShowFFDialog] = useState(false);
  const [showNonFFDialog, setShowNonFFDialog] = useState(false);
  const isVerifyingFF = useRef(false);

  const [form, setForm] = useState({
    code: 'XH',
    status: 'Unbilled',
    lob: assignmentLineOfBusiness,
    nonBillable: false,
    lob: assignmentLineOfBusiness.current,
  });

  const checkReceiptsErrors = () => {
    const allErrors = [...receiptsErrors];

    allErrors.forEach((receiptErrors, scopedId) => {
      const receiptErrorsCopy = { ...receiptErrors };
      requiredReceiptFields[singleOrShared].forEach(field => {
        if (receipts?.[scopedId][field]) {
          delete receiptErrorsCopy[field];
          if (!Object.keys(receiptErrorsCopy).length) {
            allErrors.splice(scopedId, 1);
          } else {
            allErrors[scopedId] = receiptErrorsCopy;
          }
        }
      });

      setReceiptsErrors(allErrors);
    });
  };

  useEffect(() => {
    checkReceiptsErrors();
  }, [receipts]);

  const confirmationText = (
    <p>
      Click Confirm to update the status to &quot;Ready to Bill&quot; and break this expense into separate Driver
      expenses for transfer to UltraStaff. This cannot be undone.
      <br />
      <br />
      Click Cancel to close this message and leave the shared expense status as &quot;Unbilled&quot;.
    </p>
  );
  const canPressRTBText = (
    <p>
      Receipt is Ready to Bill.
      <br />
      <br />
      Click the READY TO BILL button to move the receipt forward in the process or click CANCEL to save and exit this
      receipt.
    </p>
  );

  useBeforeunload(event => {
    if (hasChanges) {
      event.preventDefault();
    }
  });

  const navigateCancelAction = () => {
    if (receiptModified && !hasChanges) {
      setShowReceiptModified(true);
      setReceiptModified(false);
    }
    else {
      setReceiptModified(false);
      navigate(`${assignmentLineOfBusinessItems.to}/search-expenses`);
    }
  };
  const [errors, setErrors] = useState();
  const [receiptsErrors, setReceiptsErrors] = useState([]);

  const getClientsToSelect = async () => {
    try {
      const newClients = await getClients(`clients?lob=${assignmentLineOfBusiness.current}`);
      setClients(newClients);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getBranchToSelect = async () => {
    try {
      const newBranches = await getClientBranches();
      setBranches(newBranches);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const setReceiptOptions = () => {
    if (formData?.hotels?.length && Object.keys(receipts?.[0]).length) {
      const receiptsCopy = [...receipts];
      hasSetReceiptOptions.current = true;
      receiptsCopy.forEach(r => {
        const hotel = formData?.hotels?.filter(aHotel => aHotel.id === r.expenseItemHotelId)?.[0]?.name;
        r.selectedHotel = hotel;
      });
      setReceipts(receiptsCopy);
    }
  };

  const setCurrentForm = async exp => {
    setSingleOrShared(exp?.sharedInvoice === true ? 'shared' : 'single');
    if (exp.status === 'Flat Fee Billing') {
      isVerifyingFF.current = true;
    }
    if (exp?.sharedInvoice === true) {
      Promise.all([
        getStateById(exp?.expenseHotelStateId),
        getHotelById(exp?.expenseHotelId),
        getCityById(exp?.expenseHotelCityId),
      ]).then(([state, hotel, city]) => {
        setForm({
          costCenter: exp?.costCenter,
          totalProjectedAmount: exp?.totalProjectedAmount,
          code: exp?.code,
          status: exp?.status,
          nonBillable: exp?.nonBillable,
          customerId: exp?.customerId,
          customerName: exp?.customerName,
          selectedCustomer: `${exp?.customerName} - ${exp?.customerId}`,
          branch: exp?.branch,
          startDate: formatIsoToDate(exp?.startDate),
          endDate: formatIsoToDate(exp?.endDate),
          branchName: exp?.branchName,
          branchSelection: `${exp?.branch} - ${exp?.branchName}`,
          totalToBeBilled: exp?.totalToBeBilled,
          notes: exp?.notes,
          auditKey: exp?.auditKey,
          totalReceiptAmount: exp?.totalReceiptAmount.toFixed(2),
          expenseHotelId: exp?.expenseHotelId,
          expenseHotelStateId: exp?.expenseHotelStateId,
          expenseHotelCityId: exp?.expenseHotelCityId,
          documentName: exp?.documentName,
          documentSize: exp?.documentSize,
          uploadedByName: exp?.uploadedByName,
          uploadedDate: exp?.uploadedDate,
          hotelConfirmationNumber: exp?.hotelConfirmationNumber,
          hotelPhoneNumber: exp?.hotelPhoneNumber,
          hotelStreetAddress: exp?.hotelStreetAddress,
          selectedState: state,
          selectedCity: city,
          selectedHotel: hotel,
          sharedInvoice: exp?.sharedInvoice,
          flatFeeBilling: exp.status === 'Flat Fee Billing' ? 'Flat Fee Billing' : exp?.flatFeeBilling,
          invoiceDate: exp?.invoiceDate ? dateToTimeZero(exp?.invoiceDate) : null,
          invoiceNumber: exp?.invoiceNumber,
          amountBilled: exp?.amountBilled,
          variance:
            exp?.variance ||
            getCalculatedVariance({
              amountBilled: exp?.amountBilled,
              totalToBeBilled: exp?.totalToBeBilled,
            }),
          statementDate: exp?.statementDate ? formatIsoToDate(exp?.statementDate, 'YYYY-MM-DD') : null,
          expenseReportAppropriationNbr: exp?.expenseReportAppropriationNbr,
          originalExpenseId: exp?.originalExpenseId,
          correctionExpenseId: exp?.correctionExpenseId,
          rebilledExpenseId: exp?.rebilledExpenseId,
          lob: assignmentLineOfBusiness.current,
        });
      });
    } else {
      setForm({
        costCenter: exp?.costCenter,
        totalProjectedAmount: exp?.totalProjectedAmount,
        code: exp?.code,
        driverId: exp?.driverId,
        driverName: exp?.driverName,
        selectedDriver: `${exp?.driverName} - ${exp?.driverId}`,
        assignmentId: exp?.assignmentId,
        startDate: formatIsoToDate(exp?.startDate),
        endDate: formatIsoToDate(exp?.endDate),
        status: exp?.status,
        nonBillable: exp?.nonBillable,
        customerId: exp?.customerId,
        customerName: exp?.customerName,
        selectedCustomer: `${exp?.customerName} - ${exp?.customerId}`,
        branch: exp?.branch,
        branchName: exp?.branchName,
        branchSelection: `${exp?.branch} - ${exp?.branchName}`,
        totalToBeBilled: exp?.totalToBeBilled,
        notes: exp?.notes,
        auditKey: exp?.auditKey,
        invoiceDate: exp?.invoiceDate ? dateToTimeZero(exp?.invoiceDate) : null,
        invoiceNumber: exp?.invoiceNumber,
        amountBilled: exp?.amountBilled,
        variance:
          exp?.variance ||
          getCalculatedVariance({
            amountBilled: exp?.amountBilled,
            totalToBeBilled: exp?.totalToBeBilled,
          }),
        statementDate: exp?.statementDate ? formatIsoToDate(exp?.statementDate, 'YYYY-MM-DD') : null,
        expenseReportAppropriationNbr: exp?.expenseReportAppropriationNbr,
        originalExpenseId: exp?.originalExpenseId,
        correctionExpenseId: exp?.correctionExpenseId,
        rebilledExpenseId: exp?.rebilledExpenseId,
        flatFeeBilling: exp?.flatFeeBilling,
        sharedInvoice: exp?.sharedInvoice,
        lob: assignmentLineOfBusiness.current,
      });
    }

    setExpenseDate(exp?.createdAt);

    if (exp?.expenseItem.items) {
      const expenseItemsCopy = [...(exp?.expenseItem.items || [])];
      expenseItemsCopy.forEach(async receipt => {
        receipt.checkInDate = formatIsoToDate(receipt?.checkInDate, 'YYYY-MM-DD');
        receipt.lastNightOfStay = formatIsoToDate(receipt?.lastNightOfStay, 'YYYY-MM-DD');

        if (exp?.sharedInvoice === true) {
          receipt.selectedDriver = `${receipt?.hotelDriverName} - ${receipt?.hotelDriverId}`;
        }
      });
      if (exp?.sharedInvoice === true) {
        setSharedReceipts(expenseItemsCopy);
      } else {
        setReceipts(expenseItemsCopy);
      }
    }
  };

  const handleGetData = async () => {
    setIsLoading(true);
    setIsLoadingCustomers(true);
    setIsLoadingBranches(true);
    Promise.all([getClientsToSelect(), getBranchToSelect()]).finally(() => {
      setIsLoadingCustomers(false);
      setIsLoadingBranches(false);
      setIsLoading(false);
    });
  };

  const handleSetForm = async () => {
    if (id) {
      try {
        const expense = await getExpense(id);
        if (expense) {
          setCurrentForm(expense);
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const getAndSetFormData = async () => {
    const newFormData = await getFormData();
    if (newFormData) {
      setFormData(newFormData);
    }
  };

  const handleSettingForm = () => {
    formSettedRef.current = true;
  };

  useEffect(() => {
    if (formData && branches?.length && formSettedRef.current === false) {
      handleSettingForm();
      handleSetForm();
    }
  }, [branches, formData]);

  useEffect(() => {
    if (hasSetReceiptOptions.current === false) {
      if (receipts?.length && receipts[0].expenseItemHotelId !== undefined) setReceiptOptions();
    }
  }, [formData?.hotels, receipts]);

  const handleVerifyConfirm = async () => {
    const clientIsFF = await checkClientFlatFee(form?.customerId);

    if (clientIsFF && form?.flatFeeBilling) {
      confirmEditExpense({ isFF: true });
    }
    if (clientIsFF && !form?.flatFeeBilling) {
      setShowFFDialog(true);
    }
    if (!clientIsFF && form?.flatFeeBilling) {
      setShowNonFFDialog(true);
    }
    if (!clientIsFF && !form?.flatFeeBilling) {
      if (singleOrShared === 'single') {
        confirmEditExpense({
          route: assignmentLineOfBusinessItems.to,
        });
      } else if (
        form.code !== 'XHSR' &&
        (singleOrShared === 'shared' &&
          (totalToBeBilled === form?.totalReceiptAmount?.toString() &&
            form?.totalReceiptAmount?.toString() !== '0' &&
            form?.totalReceiptAmount?.toString() !== '0.00'))
      ) {
        setShowCanPressRTB(true);
      }
    }
  };

  const fieldsAreValid = () => {
    let formErrorsCopy = {
      ...errors,
    };

    requiredFields[singleOrShared].forEach(field => {
      if (form[field] === undefined || form[field] === null || form[field] === '' || form[field] === '--') {
        formErrorsCopy = {
          ...formErrorsCopy,
          [field]: 'Please provide a valid value.',
        };
      }
    });

    let allReceipts = [...receipts];

    if (singleOrShared === 'shared') {
      allReceipts = [...sharedReceipts];
    }

    let receiptsErrorsCopy = receiptsErrors.length ? [...receiptsErrors] : allReceipts.map(() => ({}));

    if (singleOrShared === 'shared' && sharedReceiptErrors.length) {
      receiptsErrorsCopy = [...sharedReceiptErrors];
    }

    for (let index = 0; index < allReceipts.length; index++) {
      const r = allReceipts[index];
      for (let j = 0; j < requiredReceiptFields[singleOrShared].length; j++) {
        const rf = requiredReceiptFields[singleOrShared][j];
        if (rf === 'markup') {
          if (r[rf] === '') {
            receiptsErrorsCopy[index] = {
              ...receiptsErrorsCopy[index],
              [rf]: 'Please provide a valid value.',
            };
          }
        } else if (r[rf] === undefined || r[rf] === null || r[rf] === '' || r[rf] === '--') {
          receiptsErrorsCopy[index] = {
            ...(receiptsErrorsCopy[index] || {}),
            [rf]: 'Please provide a valid value.',
          };
        }
      }
    }

    if (singleOrShared === 'shared') {
      setSharedReceiptsErrors(receiptsErrorsCopy);
    } else {
      setReceiptsErrors(receiptsErrorsCopy);
    }
    setErrors(formErrorsCopy);

    const checkErrors = [...receiptsErrorsCopy];
    for (let index = checkErrors.length - 1; index >= 0; index--) {
      const checkErrorsIndex = checkErrors[index];
      if (!Object.keys(checkErrorsIndex).length) {
        checkErrors.splice(index, 1);
      }
    }

    return !checkErrors.length && !Object.keys(formErrorsCopy).length;
  };

  const bulkUpdateExpenseItem = async items => {
    Promise.all(items.map(item => updateExpenseItem(item))).then(() => { });
  };

  const bulkCreateExpenseItem = async items => {
    Promise.all(items.map(item => createExpenseItem(item))).then(() => { });
  };

  const bulkDeleteReceipts = async items => {
    Promise.all(items.map(item => deleteExpenseFile(item)));
  };

  const bulkDeleteExpenseItems = async items => {
    Promise.all(items.map(item => deleteExpenseItem(item.id)));
  };

  const handleDeleteExpense = async () => {
    try {
      setIsLoading(true);
      await deleteExpense(id);
      let allReceipts = [...receipts];

      if (singleOrShared === 'shared') {
        allReceipts = [...sharedReceipts];
      }
      await bulkDeleteExpenseItems(allReceipts);
      const receiptDocs = [];

      allReceipts?.forEach(r => {
        receiptDocs.push(r?.documentName);
      });
      if (receiptDocs.length) {
        bulkDeleteReceipts(receiptDocs);
      }
    } catch (error) {
      console.log('error deleting expense', error);
      setIsLoading(false);
    }
    navigate(assignmentLineOfBusinessItems.to);
  };

  const receiptsHaveFiles = () => {
    let haveFiles = true;

    if (singleOrShared === 'single') {
      const allReceipts = [...receipts];

      allReceipts.forEach(r => {
        if (!r?.documentName) {
          haveFiles = false;
        }
      });
    } else if (!form?.documentName) {
      haveFiles = false;
    }
    return haveFiles;
  };

  const getReceiptStatus = (isBillable, alreadyRTB) => {
    let status = form?.status;
    if (!isBilledOrTransmitted(form)) {
      if ((form?.status !== 'Non-billable' && form?.status !== 'Flat Fee Billing') || isBillable) {
        if (
          ((singleOrShared === 'single' && receiptsHaveFiles()) ||
            (singleOrShared === 'shared' &&
              form?.documentName &&
              totalToBeBilled === form?.totalReceiptAmount?.toString() &&
              form?.totalReceiptAmount?.toString() !== '0' &&
              form?.totalReceiptAmount?.toString() !== '0.00')) &&
          fieldsAreValid() &&
          !alreadyRTB &&
          receiptsHaveReceiptAmount(singleOrShared === 'single' ? receipts : sharedReceipts)
        ) {
          status = 'Ready to Bill';
        } else {
          status = 'Unbilled';
        }
      }
    }

    return status;
  };

  const handleSetReadyToBill = async () => {
    setIsLoading(true);

    for (let i = 0; i < sharedReceipts.length; i++) {
      const receipt = sharedReceipts[i];

      const expenseId = `${id}.${i + 1}`;

      const expenseItem = {
        ...receipt,
      };
      expenseItem.expenseExpenseItemId = expenseId;
      delete expenseItem.id;
      delete expenseItem.selectedDriver;
      delete expenseItem.selectedCityState;
      delete expenseItem.createdAt;
      delete expenseItem.updatedAt;
      expenseItem.checkInDate = dateToTimeZero(receipt?.checkInDate);
      expenseItem.lastNightOfStay = dateToTimeZero(receipt?.lastNightOfStay);
      if (!expenseItem?.receiptAmount) {
        expenseItem.receiptAmount = 0.0;
        expenseItem.expenseTotal = 0.0;
      }

      const date1 = new Date('01/01/1900');
      const date2 = new Date(expenseItem?.lastNightOfStay);
      const Difference_In_Time = date2.getTime() - date1.getTime();
      const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      const customDate = Difference_In_Days.toFixed(0).toString();

      const expense = {
        id: id,
        code: 'XHS',
        sharedInvoice: true,
        status: 'Ready to Bill',
        nonBillable: form?.nonBillable,
        customerId: form?.customerId,
        customerName: form?.customerName,
        assignmentId: receipt?.hotelAssignmentId,
        driverId: receipt?.hotelDriverId,
        driverName: receipt?.hotelDriverName,
        branch: form?.branch,
        branchName: form?.branchName,
        costCenter: parseInt(form?.costCenter),
        startDate: dateToTimeZero(expenseItem?.checkInDate),
        endDate: dateToTimeZero(expenseItem?.lastNightOfStay),
        totalToBeBilled: expenseItem?.expenseTotal,
        notes: form?.notes,
        lob: form?.lob,
        auditKey: `${form?.customerId}${totalToBeBilled}${customDate}`,
        documentName: form?.documentName,
        documentSize: form?.documentSize,
        uploadedByName: form?.uploadedByName,
        uploadedDate: form?.uploadedDate,
        totalReceiptAmount: form?.totalReceiptAmount ? form?.totalReceiptAmount : 0.0,
        expenseHotelId: form?.expenseHotelId,
        expenseHotelStateId: form?.expenseHotelStateId,
        expenseHotelCityId: form?.expenseHotelCityId,
        hotelConfirmationNumber: form?.hotelConfirmationNumber,
        hotelPhoneNumber: form?.hotelPhoneNumber,
        hotelStreetAddress: form?.hotelStreetAddress,
        totalProjectedAmount: expenseItem?.projectedAmount,
        variance:
          form?.variance ||
          getCalculatedVariance({
            amountBilled: form?.amountBilled,
            totalToBeBilled: expenseItem?.expenseTotal,
          }),
        updatedBy: user.id,
      };

      await createExpense(expense);
      await createExpenseItem(expenseItem);
    }

    await handleDeleteExpense();
  };

  const confirmEditExpense = async ({
    route = assignmentLineOfBusinessItems.to,
    isFF = false,
    isBillable = false,
    alreadyRTB = false,
    rebilledShared = false,
  }) => {
    if (fieldsAreValid()) {
      setIsLoading(true);
      let expense;
      if (singleOrShared === 'shared') {
        expense = {
          id: id,
          code: isFF ? 'XFFBHS' : rebilledShared ? 'XHSR' : 'XHS',
          status: isFF ? 'Flat Fee Billing' : getReceiptStatus(isBillable, alreadyRTB),
          nonBillable: isFF ? true : isBillable ? false : form?.nonBillable,
          customerId: form?.customerId,
          customerName: form?.customerName,
          branch: form?.branch,
          branchName: form?.branchName,
          costCenter: parseInt(form?.costCenter),
          startDate: form?.startDate ? dateToTimeZero(form?.startDate) : null,
          endDate: form?.endDate ? dateToTimeZero(form?.endDate) : null,
          totalToBeBilled: totalToBeBilled,
          notes: form?.notes,
          lob: form?.lob,
          auditKey: auditKey,
          sharedInvoice: singleOrShared === 'shared',
          documentName: form?.documentName || null,
          documentSize: form?.documentSize || null,
          uploadedByName: form?.uploadedByName || null,
          uploadedDate: form?.uploadedDate || null,
          totalReceiptAmount: form?.totalReceiptAmount ? form?.totalReceiptAmount : 0.0,
          expenseHotelId: form?.expenseHotelId,
          expenseHotelStateId: form?.expenseHotelStateId,
          expenseHotelCityId: form?.expenseHotelCityId,
          hotelConfirmationNumber: form?.hotelConfirmationNumber,
          hotelPhoneNumber: form?.hotelPhoneNumber,
          hotelStreetAddress: form?.hotelStreetAddress,
          totalProjectedAmount: form?.totalProjectedAmount,
          flatFeeBilling: isFF,
          variance:
            form?.variance ||
            getCalculatedVariance({
              amountBilled: form?.amountBilled,
              totalToBeBilled: totalToBeBilled,
            }),
          updatedBy: user.id,
        };
      } else {
        expense = {
          id: id,
          code: isFF ? 'XFFBH' : 'XH',
          driverId: form?.driverId,
          driverName: form?.driverName,
          assignmentId: form?.assignmentId,
          status: isFF ? 'Flat Fee Billing' : getReceiptStatus(isBillable, alreadyRTB),
          nonBillable: isFF ? true : isBillable ? false : form?.nonBillable,
          customerId: form?.customerId,
          customerName: form?.customerName,
          branch: form?.branch,
          costCenter: parseInt(form?.costCenter),
          startDate: form?.startDate ? dateToTimeZero(form?.startDate) : null,
          endDate: form?.endDate ? dateToTimeZero(form?.endDate) : null,
          totalToBeBilled: totalToBeBilled,
          notes: form?.notes,
          lob: assignmentLineOfBusiness.current,
          auditKey: auditKey,
          sharedInvoice: singleOrShared === 'shared',
          totalProjectedAmount: form?.totalProjectedAmount,
          invoiceDate: form?.invoiceDate ? dateToTimeZero(form?.invoiceDate) : null,
          invoiceNumber: form?.invoiceNumber,
          amountBilled: form?.amountBilled,
          variance:
            form?.variance ||
            getCalculatedVariance({
              amountBilled: form?.amountBilled,
              totalToBeBilled: totalToBeBilled,
            }),
          statementDate: form?.statementDate ? dateToTimeZero(form?.statementDate) : null,
          expenseReportAppropriationNbr: form?.expenseReportAppropriationNbr,
          flatFeeBilling: isFF,
          updatedBy: user.id,
        };
      }

      try {
        setIsLoading(true);

        if (singleOrShared === 'shared') {
          expense.driverId = sharedReceipts[0]?.hotelDriverId;
          expense.driverName = sharedReceipts[0]?.hotelDriverName;
          expense.assignmentId = sharedReceipts[0]?.hotelAssignmentId;
        }

        await updateExpense(expense);
        let receiptsCopy = [...receipts];
        if (singleOrShared === 'shared') {
          receiptsCopy = [...sharedReceipts];
        }

        receiptsCopy.forEach(receipt => {
          delete receipt.createdAt;
          delete receipt.updatedAt;
          delete receipt.__typename;
          receipt.checkInDate = receipt?.checkInDate ? dateToTimeZero(receipt?.checkInDate) : null;
          receipt.lastNightOfStay = receipt?.lastNightOfStay ? dateToTimeZero(receipt?.lastNightOfStay) : null;
        });

        if (deletedReceiptDocs.length) {
          await bulkDeleteReceipts(deletedReceiptDocs);
        }

        if (deletedReceipts) {
          await bulkDeleteExpenseItems(deletedReceipts);
        }

        const receiptsToUpdate = [];
        const receiptsToCreate = [];

        receiptsCopy.forEach(rci => {
          const { __typename, ...r } = rci;

          if (!r?.receiptAmount) {
            r.receiptAmount = 0.0;
            r.expenseTotal = 0.0;
          }

          if (singleOrShared === 'shared') {
            delete r.selectedDriver;
          } else {
            delete r.selectedState;
            delete r.selectedCity;
            delete r.selectedCityState;
            delete r.selectedHotel;
            delete r.selectedCities;
          }
          if (r?.id) {
            receiptsToUpdate.push(r);
          } else {
            const receipt = {
              ...r,
            };
            receipt.expenseExpenseItemId = id;
            receiptsToCreate.push(receipt);
          }
        });

        if (receiptsToUpdate?.length) {
          await bulkUpdateExpenseItem(receiptsToUpdate);
        }
        if (receiptsToCreate?.length) {
          await bulkCreateExpenseItem(receiptsToCreate);
        }
        setIsLoading(false);

        navigate(route || assignmentLineOfBusinessItems.to);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    } else {
      setErrorModalOpened(true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (
      Object.keys(form).length > 4 &&
      !isVerifyingFF.current &&
      singleOrShared === 'shared' &&
      !isLoading &&
      form?.documentName
    ) {
      if ((form.status === 'Unbilled' || form.status === 'Flat Fee Billing') && fieldsAreValid()) {
        isVerifyingFF.current = true;

        handleVerifyConfirm();
      }
    }
  }, [form, isLoading]);

  const calculateTotal = () => {
    let allReceipts = [...receipts];

    if (singleOrShared === 'shared') {
      allReceipts = [...sharedReceipts];
    }

    let total = parseFloat(
      allReceipts?.reduce((a, b) => parseFloat(a) + (parseFloat(b?.expenseTotal) || 0), 0),
    ).toFixed(2);
    if (id?.includes('CR')) total = -Math.abs(total);
    setTotalToBeBilled(total);
  };

  const getStartAndEndDate = () => {
    let startDate = '';
    let endDate = '';
    const emptyDate = '--';

    let allReceipts = [...receipts];

    if (singleOrShared === 'shared') {
      allReceipts = [...sharedReceipts];
    }

    allReceipts.forEach(r => {
      if (!startDate.length && r?.checkInDate) {
        startDate = r?.checkInDate;
        endDate = r?.lastNightOfStay;
      } else {
        if (r?.checkInDate && r?.checkInDate < startDate) {
          startDate = r?.checkInDate;
        }
        if (r?.lastNightOfStay && r?.lastNightOfStay > endDate) {
          endDate = r?.lastNightOfStay;
        }
      }
    });

    const emptyDates = {
      startDate: emptyDate,
      endDate: emptyDate,
    };
    const newDates = {
      startDate: formatIsoToDate(startDate),
      endDate: formatIsoToDate(endDate),
    };
    if (!!startDate && !!endDate) {
      setForm({
        ...form,
        ...newDates,
      });
    } else {
      setForm({
        ...form,
        ...emptyDates,
      });
    }
  };

  const calculateAuditKey = () => {
    let customDate = '';
    if (
      form?.startDate?.length > 2 &&
      form?.endDate?.length > 2 &&
      ((form?.driverId && singleOrShared === 'single') || (singleOrShared === 'shared' && form?.customerId)) &&
      totalToBeBilled !== '0.00'
    ) {
      const startDate = dayjs('1899-12-31');
      const endDate = formatIsoToDate(form.startDate);
      const differenceInDays = (dayjs(endDate)?.diff(startDate, 'day') || 0) + 1;
      customDate = differenceInDays.toFixed(0).toString();
      if (singleOrShared === 'shared') {
        if (form?.customerId) {
          setAuditKey(`${form?.customerId}${totalToBeBilled}${customDate}`);
        }
      } else if (form?.driverId) {
        setAuditKey(`${form?.driverId}${Math.abs(totalToBeBilled)}${customDate}`);
      }
    }
  };

  useEffect(() => {
    calculateTotal();
    getStartAndEndDate();
  }, [receipts, sharedReceipts, singleOrShared]);

  useEffect(() => {
    calculateAuditKey();
  }, [form, totalToBeBilled, singleOrShared]);

  const getAndSetUserInfo = async () => {
    const { email } = (await Auth.currentSession()).getIdToken().payload;
    const user = await getUserInfo(email);
    setCurrentUser(user);
    const audits = await getAudit(id);
    setExpenseHistoryData(audits);
  };

  useEffect(() => {
    if (assignmentLineOfBusiness.current) {
      getAndSetFormData();
      getAndSetUserInfo();
      getAssignmentLineOfBusinessItems();
    }
  }, [assignmentLineOfBusiness.current]);

  const setFormOptions = () => {
    if (formData?.hotels && form?.expenseHotelId) {
      const formCopy = {
        ...form,
      };
      hasSetFormOptions.current = true;
      const hotel = formData?.hotels?.filter(scopedHotel => scopedHotel.id === formCopy.expenseHotelId)?.[0]?.name;
      if (hotel) {
        formCopy.selectedHotel = hotel;
        setForm(formCopy);
      }
    }
  };

  useEffect(() => {
    if (hasSetFormOptions.current === false) {
      setFormOptions();
    }
  }, [formData?.hotels, form?.expenseHotelId]);

  return (
    <div>
      <SubHeader
        text="Expenses"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Expenses?"
        emailAddress={process.env.REACT_APP_EXPENSE_CONTACT_EMAIL}
      />
      <Breadcrumbs
        leftTitle={assignmentLineOfBusinessItems.text}
        rightTitle={'Edit Hotel'}
        route={assignmentLineOfBusinessItems.to}
      />
      <ExpensesWindow
        windowContent={
          <EditFormContent
            user={user}
            formData={formData}
            form={form}
            singleOrShared={singleOrShared}
            errors={errors}
            isLoading={isLoading}
            isLoadingCustomers={isLoadingCustomers}
            isLoadingBranches={isLoadingBranches}
            setUploading={setUploading}
            setUploadError={setUploadError}
            sharedReceipts={sharedReceipts}
            receipts={receipts}
            totalToBeBilled={totalToBeBilled}
            auditKey={auditKey}
            setSharedReceipts={setSharedReceipts}
            sharedReceiptErrors={sharedReceiptErrors}
            setSharedReceiptsErrors={setSharedReceiptsErrors}
            receiptsErrors={receiptsErrors}
            deleteExpenseFile={deleteExpenseFile}
            setForm={setForm}
            expenseDate={expenseDate}
            id={id}
            isReadyToBill={isReadyToBill}
            setShowConfirmRTB={setShowConfirmRTB}
            handleDeleteExpense={handleDeleteExpense}
            hasChanges={hasChanges}
            setHasChanges={setHasChanges}
            setErrors={setErrors}
            setReceipts={setReceipts}
            setShowCancelDialog={setShowCancelDialog}
            navigateCancelAction={navigateCancelAction}
            setSingleOrShared={setSingleOrShared}
            setIsLoading={setIsLoading}
            fieldsAreValid={fieldsAreValid}
            confirmEditExpense={confirmEditExpense}
            receiptsHaveFiles={receiptsHaveFiles}
            handleVerifyConfirm={handleVerifyConfirm}
            setShowReceiptFilesDialog={setShowReceiptFilesDialog}
            setErrorModalOpened={setErrorModalOpened}
            clients={clients}
            branches={branches}
            deletedReceipts={deletedReceipts}
            setDeletedReceipts={setDeletedReceipts}
            deletedReceiptDocs={deletedReceiptDocs}
            setDeletedReceiptDocs={setDeletedReceiptDocs}
            currentUser={currentUser}
            setReceiptsErrors={setReceiptsErrors}
            setReceiptModified={setReceiptModified}
            setShowReceiptModified={setShowReceiptModified}
            setShowDeleteConfirmation={setShowDeleteConfirmation}
            assignmentLineOfBusinessItems={assignmentLineOfBusinessItems}
          />
        }
        windowTitle="Edit Hotel"
      />

      <div className='table-container'>
        <ExpenseHistoryTable
          data={expenseHistoryData}
        />
      </div>

      <ModalConfirmation
        title="Receipt attachment has been modified"
        body="Click Save to complete the update"
        primaryButton
        primaryButtonTitle="Save"
        optionalClose
        secondaryButtonTitle="Leave Page"
        onClick={async () => {
          await confirmEditExpense({
            route: assignmentLineOfBusinessItems.to,
          });
          setShowReceiptModified(false);
        }}
        secondaryOnClick={() => {
          setShowReceiptModified(false);
          navigateCancelAction();
        }}
        isOpen={showReceiptModified}
      />

      <ModalConfirmation
        title="Cancel?"
        body="Changes you made will be lost"
        primaryButtonTitle="Stay on Page"
        optionalClose
        secondaryButtonTitle="Leave Page"
        onClick={() => {
          setShowCancelDialog(false);
        }}
        secondaryOnClick={() => {
          setShowCancelDialog(false);
          navigateCancelAction();
        }}
        isOpen={showCancelDialog}
      />
      <ModalConfirmation
        primaryButton
        title="Please Confirm!"
        body={confirmationText}
        primaryButtonTitle="Confirm"
        optionalClose
        secondaryButtonTitle="Cancel"
        onClick={async () => {
          await handleSetReadyToBill();
        }}
        secondaryOnClick={() => {
          setShowConfirmRTB(false);
        }}
        isOpen={showConfirmRTB}
      />
      <ModalConfirmation
        title="Please Note!"
        body="Please attach the receipt when available or this expense will not be billed"
        primaryButton
        primaryButtonTitle="OK"
        onClick={async () => {
          setShowReceiptFilesDialog(false);
          await confirmEditExpense({
            isFF: form?.flatFeeBilling,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        isOpen={showReceiptFilesDialog}
      />
      <ModalConfirmation
        primaryButton
        // title={'Please Confirm'}
        body={canPressRTBText}
        primaryButtonTitle="OK"
        onClick={async () => {
          setShowCanPressRTB(false);
          if (isReadyToBill || form?.code === 'XHSR') {
            confirmEditExpense({
              route: assignmentLineOfBusinessItems.to,
              alreadyRTB: true,
            });
          } else {
            setIsReadyToBill(true);
          }
        }}
        isOpen={showCanPressRTB}
      />

      <ModalConfirmation
        title="Confirmation"
        body="Deletion is permanent. Click Confirm to delete expense. Click Cancel to return."
        primaryButtonTitle="CONFIRM"
        primaryButton
        optionalClose
        secondaryButtonTitle="CANCEL"
        onClick={() => {
          setShowDeleteConfirmation(false);
          handleDeleteExpense();
        }}
        secondaryOnClick={() => {
          setShowDeleteConfirmation(false);
        }}
        isOpen={showDeleteConfirmation}
      />
      <FlatFeeModal
        showDialog={showFFDialog}
        clientIsFlatFee
        setShowDialog={setShowFFDialog}
        ffAction={async () => {
          await confirmEditExpense({
            isFF: true,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        billableAction={async () => {
          if (singleOrShared === 'single') {
            await confirmEditExpense({
              isBillable: true,
              route: assignmentLineOfBusinessItems.to,
            });
          } else {
            setShowCanPressRTB(true);
          }
        }}
      />
      <FlatFeeModal
        showDialog={showNonFFDialog}
        clientIsFlatFee={false}
        setShowDialog={setShowNonFFDialog}
        ffAction={async () => {
          await confirmEditExpense({
            isFF: true,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        billableAction={async () => {
          if (singleOrShared === 'single') {
            await confirmEditExpense({
              isBillable: true,
              route: assignmentLineOfBusinessItems.to,
            });
          } else {
            setShowCanPressRTB(true);
          }
        }}
        cancelAction={() => { }}
      />
      {errorModalOpened && (
        <div className="error-modal-main-background" onClick={() => setErrorModalOpened(false)}>
          <div className="error-modal-background">
            <p
              style={{
                marginBottom: 24,
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 20,
              }}>
              Oops!
            </p>
            <p style={{ marginBottom: 24, textAlign: 'center' }}>
              It seems like you missed filling in some required fields in the form. Please review the form and provide
              the necessary details to proceed.
            </p>
            <Button className="button-text" style={{ width: '140px' }} onClick={() => setErrorModalOpened(false)}>
              <span>CLOSE</span>
            </Button>
          </div>
        </div>
      )}
      {uploading && <div className="overlay-box" />}
    </div>
  );
};

export default EditHotel;
