import React, { useEffect, useRef, useState } from 'react';

import { API, Storage, graphqlOperation } from 'aws-amplify';
import { Button, Container, Col, Form, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { FaFilePdf } from 'react-icons/fa';
import { MdHome } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { getSkillsFromDriver, hasActiveUsers } from './operations';
import { Breadcrumbs, ModalConfirmation, SubHeader } from '../../../components';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import useIsMobileScreen from '../../../hooks/useCheckIsMobile';
import { formatIsoToDate } from '../../../utils';
import PrevCommentsTable from '../CorporateDQTable';
import './styles.scoped.css';

function tabsBorderRadius() {
  // eslint-disable-next-line no-undef
  document.querySelectorAll('.rounded-top-left').forEach(elem => {
    elem.style.borderTopLeftRadius = '15px';
  });
  // eslint-disable-next-line no-undef
  document.querySelectorAll('.rounded-top-right').forEach(elem => {
    elem.style.borderTopRightRadius = '15px';
  });
}

const CorporateDQ = () => {
  const { id } = useParams();
  const [dq, setDQ] = useState({});
  const [dqfId, setDqfId] = useState();
  const [driverSkillSummary, setDriverSkillSummary] = useState('');
  const [dqOwner, setDqOwner] = useState();
  const [dqPdfDocument, setDqPdfDocument] = useState('');
  const [dqPdfKey, setDqPdfKey] = useState(0);
  const [pdfError, setPdfError] = useState();
  const [isCommentChange, setIsCommentChange] = useState('');
  const [hasOwnership, setHasOwnership] = useState(false);
  const [ownershipButton, setOwnershipButton] = useState({
    disable: false,
    label: '',
  });
  const [reload, setReload] = useState(false);
  const [driverSkills, setDriverSkills] = useState([]);
  const [show, setShow] = useState(false); // for error modal
  const [responseSuccessShow, setResponseSuccessShow] = useState(false);
  const [sentSuccessShow, setSentSuccessShow] = useState(false);
  const [activeUserAlert, setActiveUserAlert] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector(state => state.admin.user.id);
  const roleId = useSelector(state => state.driverQualification.roleId);
  const { tierData } = useSelector(state => state.newRequest);
  const dqOwnerFullName = useSelector(state => state.admin.user.name);
  const isMobile = useIsMobileScreen();
  const hiddenFileInput = useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const [loading, setLoading] = useState(false);

  const handleChange = e => {
    const file = e.target.files[0];
    putPDF(file);
  };

  useEffect(() => {
    setDriverSkillSummary(dq?.driverSkillSummary);
  }, [dq?.driverSkillSummary]);

  useEffect(() => {
    tabsBorderRadius();
  }, [dq]);

  const getSkills = async driverId => {
    const driver = await getSkillsFromDriver(driverId);
    if (driver?.driverSkills?.length) {
      setDriverSkills(driver?.driverSkills);
    }
  };

  useEffect(() => {
    if (dq?.driverId) {
      getSkills(dq.driverId);
    }
  }, [dq?.driverId]);

  useEffect(() => {
    if (dqPdfKey) getPDF(dqPdfKey);
  }, [dqPdfKey, reload]);

  useEffect(() => {
    if (id && dqOwnerFullName) getDQ(id);
  }, [reload, dqOwner, dqOwnerFullName]);

  const putPDF = async pdf => {
    try {
      const result = await Storage.put(`dqf/${dqfId}.pdf`, pdf, {
        level: 'public',
        contentType: 'application/pdf',
      });
      await updateDQDocName(id, pdf?.name, userId);
      if (result) {
        dispatch(setDQ({ ...dq, dqDocumentName: result.key }), setDqPdfKey(result.key));
      }
    } catch (error) {
      console.log('ERROR UPLOAD PDF', error);
    }
  };

  const createQuestion = async (anId, createUser, text, clientApproverName, aDqOwnerFullName) => {
    const create = {
      driverQualificationQuestionsId: anId,
      user: createUser,
      text: text,
      status: 'In-Progress',
      createUserFullName: clientApproverName || aDqOwnerFullName,
      type: 'Sent',
    };
    try {
      await API.graphql(graphqlOperation(mutations.createQuestions, { input: create }));
      return true;
    } catch (e) {
      console.log(`Update Status Error: ${JSON.stringify(e)}`);
      return false;
    }
  };

  const updateDQOwner = async (anId, dqSpecialistUser, aDqOwnerFullName, aUserId) => {
    const input = {
      id: anId,
      dqSpecialistEmail: dqSpecialistUser,
      dqOwnerFullName: aDqOwnerFullName,
      updateUser: aUserId,
    };
    try {
      await API.graphql(graphqlOperation(mutations.updateDriverQualification, { input: input }));
      return true;
    } catch (e) {
      console.log(`Update Status Error: ${JSON.stringify(e)}`);
      return false;
    }
  };

  const updateDQStatus = async (anId, status, aUserId, clientApproverName) => {
    const update = {
      id: anId,
      status: status,
      dqClientApproverUser: aUserId,
      dqClientApproverUserFullName: clientApproverName,
      driverSkillSummary: driverSkillSummary,
    };

    try {
      await API.graphql(
        graphqlOperation(mutations.updateDriverQualification, {
          input: update,
        }),
      );
      return true;
    } catch (e) {
      console.log('Update Status Error:', e);
      return false;
    }
  };

  const updateDQDocName = async (anId, docName) => {
    const update = {
      id: anId,
      dqDocumentName: docName,
    };
    try {
      await API.graphql(
        graphqlOperation(mutations.updateDriverQualification, {
          input: update,
        }),
      );
      return true;
    } catch (e) {
      console.log(`Update Status Error: ${JSON.stringify(e)}`);
      return false;
    }
  };

  const getDQByID = async anId => {
    try {
      const response = await API.graphql({
        query: queries.getDriverQualification,
        variables: { id: anId },
      });
      return response.data.getDriverQualification;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  };

  const getDQ = async anId => {
    const response = await getDQByID(anId);
    if (response) {
      setLoading(true);
      setDqPdfKey(`${response.id}.pdf`);
      setDQ(response);
      setDqfId(response.id);
      setDqOwner(response.dqOwnerFullName);
      setLoading(false);
      if (!response.dqOwnerFullName) {
        setOwnershipButton({
          ...ownershipButton,
          disable: false,
          label: 'Take Ownership',
        });
      }

      if (response?.dqOwnerFullName) {
        setHasOwnership(true);
        if (roleId === '3' || roleId === '10') {
          return response?.dqOwnerFullName !== dqOwnerFullName
            ? setOwnershipButton({
                ...ownershipButton,
                disable: false,
                label: 'Take Ownership',
              })
            : setOwnershipButton({
                ...ownershipButton,
                disable: true,
                label: 'Ownership Taken',
              });
        }
        setOwnershipButton({
          ...ownershipButton,
          disable: true,
          label: 'Ownership Taken',
        });
      }
    }
    return undefined;
  };

  const getPDF = async pdf => {
    const config = {
      expires: process.env.REACT_APP_AMPLIFY_STORAGE_GET_EXPIRES,
      level: 'public',
      download: false,
    };

    await Storage.get(`dqf/${pdf}`, config)
      .then(response => {
        fetch(response).then(result => {
          if (result.status === 200) {
            setDqPdfDocument(result.url);
            setPdfError(false);
            setReload(true);
          } else {
            setPdfError(true);
          }
        });
      })
      .catch(err => console.log('This is the error', err));
  };

  useEffect(() => {
    if (dq?.id > 0) getPDF(`${dq?.id}.pdf`);
  }, [dq, reload]);

  const handleOwnerClick = async () => {
    const success = await updateDQOwner(id, userId, dqOwnerFullName);
    if (success) {
      setReload(!reload);
      if (dq?.status === 'Requested') {
        setDQ({
          ...dq,
          dqSpecialistEmail: userId,
          status: 'Assembling',
        });
        await updateDQStatus(id, 'Assembling');
      } else {
        setDQ({
          ...dq,
          dqSpecialistEmail: userId,
        });
      }
    } else {
      console.log('Error with setting DQ owner on backend');
    }
  };

  async function handleRespond() {
    const success1 = await createQuestion(id, userId, isCommentChange, dqOwnerFullName);
    let success2;
    if (success1) {
      success2 = await updateDQStatus(id, 'Sent');
    }

    //Create a question and updated a DQ status
    if (success1 && success2) {
      setIsCommentChange('');
      //Show modal if both things are true
      setResponseSuccessShow(true);
    } else {
      setShow(true);
    }
  }

  const handleSendToCustomer = async () => {
    // check for active users
    if (await hasActiveUsers(dq.customerId)) {
      if (dq?.status === 'Assembling') {
        setDQ({ ...dq, status: 'Sent' });
        const newDqStatus = 'Sent';
        const success = await updateDQStatus(id, newDqStatus);
        if (success) {
          setDQ({ ...dq, status: 'Sent' });
          setSentSuccessShow(true);
        } else {
          setShow(true);
        }
      }
    } else {
      setActiveUserAlert(true);
    }
  };

  //Any time the questions are updated run a useEffect that get's the DQ,
  //SET state to empty on Modal Close
  const clearFields = () => {
    setSentSuccessShow(false);
    setDQ();
    setDqPdfDocument('');
    setDqPdfKey();
    setIsCommentChange(null);
    setPdfError();
    setShow(false);
    setResponseSuccessShow(false);
    setSentSuccessShow(false);
    setReload(!reload);
  };

  const getAllSkills = () => {
    let skills = '';
    driverSkills?.forEach(item => {
      skills = skills.concat(`${item?.skillName}: ${item?.skillDescription}, `);
    });
    return skills.substring(0, skills.length - 2);
  };

  const userCanEdit = () =>
    hasOwnership &&
    userId === `${dq?.dqSpecialistEmail}` &&
    (dq?.status === 'Assembling' ||
      dq?.status === 'Information Requested' ||
      dq?.status === 'Client Review' ||
      dq?.status === 'Sent');

  return (
    <div style={{ width: '90%' }} className="m-auto">
      <SubHeader
        text="Centerline Driver Qualification"
        btnSmall={<MdHome size={24} style={{ color: '#fff' }} />}
        btnLarge="Requested DQs"
        btnLargeStyle={{ fontWeight: 700 }}
        smallAction={() => navigate('../driver-qualification', { replace: true })}
        largeAction={() => navigate('../driver-qualification/requested', { replace: true })}
        btnInfo
        emailAddress={process.env.REACT_APP_DQF_CONTACT_EMAIL}
        infoText="Need help with your driver qualification?"
      />
      <Breadcrumbs
        leftTitle="DQF Home"
        midItemAction={() => navigate(`/driver-qualification/${dq?.status.replace(/\s+/g, '-')}`)}
        middleTitle={dq?.status !== undefined ? `${dq?.status} Files` : ''}
        route={'/driver-qualification'}
        rightTitle={id && `File ${id}\u00A0`}
      />
      <Container className="vh-100 pb-5 pt-3" fluid>
        <Tabs fill activeKey="pdf">
          <Tab
            eventKey="pdf"
            title="View DQF"
            tabClassName="border border-primary rounded-top-left rounded-top-right bg-primary text-white active">
            <div className="h-100 p-4 border border-primary">
              <Form>
                <Form.Group>
                  <Row style={{ alignItems: 'center', marginBottom: '30px' }}>
                    <Col
                      style={{
                        color: '#c8102e',
                        fontSize: isMobile ? '16px' : '24px',
                        marginBottom: isMobile ? '20px' : '0',
                      }}
                      className="font-weight-bold h4">
                      {dq?.name}
                    </Col>
                    <Button
                      style={{
                        width: '140px',
                        height: '40px',
                        fontWeight: 700,
                        fontSize: 13,
                      }}
                      disabled={ownershipButton.disable}
                      onClick={() => handleOwnerClick()}>
                      {ownershipButton.label}
                    </Button>
                    <Col
                      className="font-weight-bold black-header"
                      style={{
                        display: 'flex',
                        justifyContent: 'right',
                      }}>
                      {dq?.id}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col>Driver </Col>
                        <Col className="text-secondary">{dq?.driverName}</Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>Generated by</Col>
                        <Col className="text-secondary">{dq?.createUserFullName}</Col>
                      </Row>
                      <br />
                    </Col>
                    <Col>
                      <Row>
                        <Col>Job Order</Col>
                        <Col className="text-secondary">{dq?.jobId}</Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>Job Start Date</Col>
                        <Col className="text-secondary">{formatIsoToDate(dq?.jobStartDate)}</Col>
                      </Row>
                      {!hasOwnership ? (
                        <>
                          <br />
                          <Row>
                            <Col>DQF Specialist</Col>
                            <Col className="text-secondary">No Current Owner</Col>
                          </Row>
                          {dq?.tierDescription && (
                            <>
                              <br />
                              <Row>
                                <Col>Tier Desc.</Col>
                                <Col className="text-secondary">{dq?.tierDescription}</Col>
                              </Row>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <br />
                          <Row>
                            <Col>DQF Specialist</Col>
                            <Col className="text-secondary">{dqOwner}</Col>
                          </Row>
                        </>
                      )}
                    </Col>
                    <Col>
                      <Row>
                        <Col>Status</Col>
                        <Col className="text-secondary">{dq?.status}</Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>Created</Col>
                        <Col className="text-secondary">{formatIsoToDate(dq?.createdAt)}</Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>Branch</Col>
                        <Col className="text-secondary">{dq?.branch}</Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>Tier</Col>
                        <Col className="text-secondary">
                          {tierData?.filter(tier => tier.value === dq?.tier)?.[0]?.label}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <Col>
                      <Row>
                        <Col>City</Col>
                        <Col className="text-secondary">{dq?.locationCity?.name}</Col>
                      </Row>
                    </Col>

                    <Col>
                      <Row>
                        <Col>State</Col>
                        <Col className="text-secondary">{dq?.locationState?.name}</Col>
                      </Row>
                    </Col>

                    <Col />
                  </Row>
                  <Row>
                    <Col>Summary</Col>
                  </Row>
                  <Row>
                    <Col>
                      <textarea
                        defaultValue={driverSkillSummary || dq?.driverSkillSummary}
                        value={driverSkillSummary}
                        onChange={event => setDriverSkillSummary(event.target.value)}
                        className="summary-input"
                        style={{
                          pointerEvents: userCanEdit() ? 'all' : 'none',
                          paddingLeft: userCanEdit() ? '0px' : '16px',
                          border: !userCanEdit() && 'none',
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>Skills</Col>
                  </Row>
                  <Row>
                    <Col className="scroll-list">
                      <div className="gray-text" style={{ paddingLeft: '16px' }}>
                        {getAllSkills()}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-1 mb-3 mt-3">
                    <Col className="font-weight-bold">Driver Qualification Document</Col>
                  </Row>
                  {pdfError && <div className="p-2" style={{ color: '#c8102e', fontWeight: 500 }} />}
                  {!pdfError &&
                    dqPdfDocument &&
                    (isMobile ? (
                      <>
                        <span>
                          <a href={dqPdfDocument} data-ajax="false" target="_blank" rel="noreferrer">
                            <FaFilePdf size={60} /> View Document
                          </a>
                        </span>
                      </>
                    ) : (
                      <iframe title="pdfDocument" src={dqPdfDocument} height="600" width="100%" />
                    ))}
                  {/*
               If DQ is Claimed
               AND only if the DQ Owner is the currently logged in user
               can the owner upload a PDF
                 */}
                  {userCanEdit() && process.env.REACT_APP_DQF_VERSION == 2 && (
                    <Row className="p-3">
                      <Button className="mr-3" onClick={handleClick}>
                        Choose File
                      </Button>
                      <Button
                        onClick={() => {
                          navigate(`../driver-qualification/assemble-new-dqf/${id}`, { replace: true });
                        }}>
                        Assemble File
                      </Button>
                      <input
                        type="file"
                        accept="application/pdf"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: 'none' }}
                      />
                    </Row>
                  )}
                  {userCanEdit() && process.env.REACT_APP_DQF_VERSION == 1 && (
                    <input
                      type="file"
                      accept="application/pdf"
                      className="p-3"
                      onChange={e => {
                        const file = e.target.files[0];
                        putPDF(file);
                      }}
                    />
                  )}
                  {dq?.questions && (
                    <Row className="padding-row">
                      <Col>
                        <PrevCommentsTable handleSetShowInParent={setShow} />
                      </Col>
                    </Row>
                  )}
                  <Row className="p-4 d-flex justify-content-center">
                    {hasOwnership && dq?.dqDocumentName && (
                      <Button className="w-50" onClick={() => handleSendToCustomer()}>
                        Send to Customer
                      </Button>
                    )}
                    {hasOwnership && dq?.status === 'Information Requested' && (
                      <Row style={{ width: '100%' }}>
                        <Row className="p-1">
                          <Col className="font-weight-bold">Respond to Client</Col>
                        </Row>
                        <Row style={{ width: '100%' }}>
                          <Col>
                            <Form.Group>
                              <Form.Control
                                className="border border-dark"
                                as="textarea"
                                rows={6}
                                value={isCommentChange}
                                onChange={e => {
                                  setIsCommentChange(e.target.value);
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Button
                            className="w-50"
                            style={{
                              marginLeft: '25%',
                              marginRight: '25%',
                            }}
                            disabled={!isCommentChange}
                            onClick={() => handleRespond('Sent')}>
                            Respond
                          </Button>
                        </Row>
                      </Row>
                    )}
                    {show && (
                      <ModalConfirmation
                        title="Error"
                        body="There was an error updating the Driver Qualification. Please contact support."
                        primaryButtonTitle="Close"
                        onClick={() => {
                          setShow(false);
                        }}
                        isOpen={show}
                        primaryButton
                      />
                    )}
                    {responseSuccessShow && (
                      <ModalConfirmation
                        title="Success!"
                        body="Your response was sent."
                        primaryButtonTitle="Close"
                        onClick={() => {
                          //Inside the modal when "Close" is pressed clear the fields
                          // setResponseSuccessShow(false);
                          clearFields();
                        }}
                        isOpen={responseSuccessShow}
                        primaryButton
                      />
                    )}
                    {sentSuccessShow && (
                      <ModalConfirmation
                        title="Success!"
                        body="The Driver Qualification has been sent."
                        primaryButtonTitle="Close"
                        onClick={() => {
                          setSentSuccessShow(false);
                          navigate('/driver-qualification', {
                            replace: true,
                          });
                        }}
                        isOpen={sentSuccessShow}
                        primaryButton
                      />
                    )}
                    {activeUserAlert && (
                      <ModalConfirmation
                        title="Unable to send file."
                        body="There are no active users for this client."
                        primaryButtonTitle="Close"
                        onClick={() => {
                          setActiveUserAlert(false);
                        }}
                        isOpen
                        primaryButton
                      />
                    )}
                  </Row>
                </Form.Group>
              </Form>
            </div>
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
};

export default CorporateDQ;
