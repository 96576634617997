import React from 'react';

import { Container, Col, Row } from 'react-bootstrap';

import QuoteFieldCol from './quoteFieldCol';
import GeneratedDriversQuote from '../GenerateQuote/Drivers/generatedDriversQuote';
import QuoteField from '../GenerateQuote/quoteField';

const DriverQuoteCard = ({ quote }) => {
  const {
    created,
    custId,
    doubleEndorsement,
    expires,
    hazmat,
    location,
    nightShift,
    numberDelay,
    numberDropHook,
    numberOfDrivers,
    numberOther,
    numberStop,
    otr,
    quoteName,
    quoteUserId,
    region,
    startDate,
    team,
    totalAnnualHoursOt,
    totalAnnualHoursRegular,
    totalAnnualMilage,
    touch,
    union,
  } = quote;
  const showRegion = region && region.length;
  const showTotalAnnualMilage = totalAnnualMilage > 0;
  const showTotalAnnualHours = totalAnnualHoursRegular + totalAnnualHoursOt > 0;
  const showNumberDriversPerYear = numberDropHook + numberDelay + numberStop + numberOther > 0;
  return (
    <Container className="p-4" fluid>
      <Row>
        <Col>
          <h4 className="text-primary font-weight-bold">{quoteName}</h4>
        </Col>
      </Row>
      <Row>
        <QuoteFieldCol label="Created:" value={created} />
        <QuoteFieldCol label="Expires:" value={expires} />
        <QuoteFieldCol label="Generated by:" value={`${quoteUserId}, ${custId}`} />
      </Row>
      <Row>
        <Col className="d-flex flex-column">
          <h5>Dedicated Model, Class A Quote</h5>
          <Row className="d-flex text-nowrap">
            <QuoteFieldCol label="Location (State):" value={location} />
            {showRegion && (
              <Col className="col-auto">
                <p>
                  Region:
                  <span className="p-1 text-muted">{region}</span>
                </p>
              </Col>
            )}
            <QuoteFieldCol label="Union:" value={union} />
            <QuoteFieldCol label="Number of Drivers:" value={numberOfDrivers} />
            {showTotalAnnualMilage && (
              <QuoteFieldCol label="Total Annual Mileage (Per Driver):" value={totalAnnualMilage} />
            )}
            {showTotalAnnualHours && (
              <>
                <QuoteFieldCol
                  label="Total Annual Hours (Regular Hours, Per Driver):"
                  value={totalAnnualHoursRegular}
                />
                <QuoteFieldCol label="Total Annual Hours (Overtime Hours, Per Driver):" value={totalAnnualHoursOt} />
              </>
            )}
          </Row>
        </Col>
      </Row>
      {showNumberDriversPerYear && (
        <Row>
          <Col className="d-flex flex-column">
            <h5>Total Number per Driver per Year</h5>
            <Row>
              <QuoteFieldCol label="Drop & Hook (1/3 Hr):" value={numberDropHook} />
              <QuoteFieldCol label="Delay:" value={numberDelay} />
              <QuoteFieldCol label="Stop:" value={numberStop} />
              <QuoteFieldCol label="Other assessorial:" value={numberOther} />
            </Row>
          </Col>
        </Row>
      )}
      <Row>
        <Col className="d-flex flex-column">
          <h5>Pay Surcharges</h5>
          <QuoteField label="Hazmat:" value={hazmat} />
          <QuoteField label="Double Endorsement:" value={doubleEndorsement} />
          <QuoteField label="Away from home - OTR:" value={otr} />
          <QuoteField label="Night Shift:" value={nightShift} />
          <QuoteField label="Touch? (vs. No Touch):" value={touch} />
          <QuoteField label="Team Driver:" value={team} />
          <QuoteField labelClass="h5" label="Start Date:" value={startDate} />
        </Col>
      </Row>
      <Row>
        <Col>
          <hr className="ml-n3 mr-n3 mt-3 mb-3 border border-dark" />
        </Col>
      </Row>
      <GeneratedDriversQuote className="p-0" showSave={false} quote={quote} />
    </Container>
  );
};

export default DriverQuoteCard;
