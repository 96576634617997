import { formatIsoToDate } from '../../../utils';

export const getCsvData = data => {
  const csvData = [
    [
      'Client Name',
      'Client Number',
      'Client BillTo Number',
      'Branch',
      'LOB',
      'Contact Name',
      'Activity Date',
      'Consultant',
      'Call Reason Code',
      'Activity Description',
      'Last Contact Date',
      'Followup Date',
      'Note Date',
      'Note Time',
      'Note',
    ],
  ];
  let i;
  for (let i = 0; i < data.length; i += 1) {
    csvData.push([
      `${data[i].Client_Name || ''}`,
      `${data[i].Client_Number || ''}`,
      `${data[i].Client_BillTo_Number || ''}`,
      `${data[i].branch || ''}`,
      `${data[i].LOB || ''}`,
      `${data[i].Contact_Name}`,
      `${formatIsoToDate(data[i].Activity_Date) || ''}`,
      `${data[i].Consultant || ''}`,
      `${data[i].Call_Reason_Code || ''}`,
      `${data[i].Activity_Description || ''}`,
      `${formatIsoToDate(data[i].Last_Contact_Date) || ''}`,
      `${formatIsoToDate(data[i].Followup_Date) || ''}`,
      `${formatIsoToDate(data[i].Note_Date) || ''}`,
      `${data[i].Note_Time || ''}`,
      `${data[i].Note || ''}`,
    ]);
  }

  return csvData;
};

export default getCsvData;