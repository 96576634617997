import dayjs from 'dayjs';

export const formatIsoToDate = (iso, format) => {
  if (!iso) {
    return '';
  }
  if (typeof iso !== 'string') {
    return dayjs(iso).format(format || 'MM/DD/YYYY');
  }
  return dayjs(iso.substring(0, 10)).format(format || 'MM/DD/YYYY');
};

export const newDateFormatted = dayjs().format('MM/DD/YYYY');
export const newDateFormattedYearFirst = dayjs().format('YYYY-MM-DD');

export const money = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
