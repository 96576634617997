import React, { useEffect, useState } from 'react';

import { Row, Form } from 'react-bootstrap';

const InvoiceDataRow = ({
  invoiceDate,
  invoiceNumber,
  amountBilled,
  variance,
  statementDate,
  expenseReportAppropriationNbr,
  setField,
}) => {
  const [eraValue, setEraValue] = useState(expenseReportAppropriationNbr);
  const [statementDateValue, setStatementDateValue] = useState(statementDate);

  useEffect(() => {
    setStatementDateValue(statementDate);
  }, [statementDate]);

  useEffect(() => {
    setEraValue(expenseReportAppropriationNbr);
  }, [expenseReportAppropriationNbr]);

  return (
    <Row
      style={{
        width: '100%',
        margin: 0,
        paddingLeft: '20px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        pointerEvents: 'all',
      }}>
      <Form.Group controlId="invoiceDate">
        <Form.Label style={{ marginTop: '15px' }}>Invoice Date</Form.Label>
        <Form.Control
          style={{
            height: '30px',
            padding: 0,
            paddingLeft: '5px',
            border: '0px',
            backgroundColor: 'white',
          }}
          as="input"
          name="invoiceDate"
          value={invoiceDate?.substring(0, 10) || undefined}
          readOnly
          disabled
        />
      </Form.Group>
      <Form.Group controlId="invoiceNumber">
        <Form.Label style={{ marginTop: '15px' }}>Invoice Number</Form.Label>
        <Form.Control
          style={{
            height: '30px',
            padding: 0,
            paddingLeft: '5px',
            border: '0px',
            backgroundColor: 'white',
          }}
          as="input"
          name="invoiceNumber"
          value={invoiceNumber}
          readOnly
          disabled
        />
      </Form.Group>
      <Form.Group controlId="amountBilled">
        <Form.Label style={{ marginTop: '15px' }}>Amount Billed</Form.Label>
        <Form.Control
          style={{
            height: '30px',
            padding: 0,
            paddingLeft: '5px',
            border: '0px',
            backgroundColor: 'white',
          }}
          as="input"
          name="amountBilled"
          value={amountBilled || undefined}
          readOnly
          disabled
        />
      </Form.Group>
      <Form.Group controlId="variance">
        <Form.Label style={{ marginTop: '15px' }}>Variance</Form.Label>
        <Form.Control
          style={{
            height: '30px',
            padding: 0,
            paddingLeft: '5px',
            border: '0px',
            backgroundColor: 'white',
          }}
          as="input"
          name="variance"
          value={variance || undefined}
          readOnly
          disabled
        />
      </Form.Group>
      <Form.Group controlId="statementDate">
        <Form.Label style={{ marginTop: '15px' }}>Statement Date</Form.Label>
        <Form.Control
          style={{
            height: '30px',
          }}
          type="date"
          value={statementDateValue?.substring(0, 10) || undefined}
          onBlur={event => {
            setField('statementDate', event.target.value);
          }}
          onChange={event => {
            setStatementDateValue(event.target.value);
          }}
          as="input"
          name="statementDate"
        />
      </Form.Group>
      <Form.Group controlId="expenseReportAppropriationNbr" style={{ marginLeft: '50px' }}>
        <Form.Label style={{ marginTop: '15px' }}>Expense Report Appropriation</Form.Label>
        <Form.Control
          style={{
            height: '30px',
          }}
          value={eraValue || undefined}
          onBlur={event => {
            setField('expenseReportAppropriationNbr', event.target.value);
          }}
          onChange={event => {
            setEraValue(event.target.value);
          }}
          as="input"
          name="expenseReportAppropriationNbr"
          maxLength={30}
        />
      </Form.Group>
    </Row>
  );
};

export default InvoiceDataRow;
