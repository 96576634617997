import { useNavigate } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./styles.scoped.css";

const Breadcrumbs = ({
    leftTitle,
    rightTitle,
    middleTitle,
    midItemAction,
    route,
    style,
    color,
}) => {
    const navigate = useNavigate();
    return (
        <div className="navigation-header" style={{ ...style }}>
            <div onClick={() => navigate(route)} className="left-title">
                {leftTitle}
            </div>
            {middleTitle && (
                <div
                    onClick={midItemAction}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    {color ? <FontAwesomeIcon icon={faChevronRight} color="#ffff" size="lg" style={{ paddingRight: '8px' }} />
                        :
                        <img
                            style={{ width: '12px', height: '18px', marginRight: '8px' }}
                            src={require('../../assets/arrow-right.png')}
                        />}
                    <div className={middleTitle && rightTitle ? "left-title" : "right-title"} style={{ color: color ? color : null }}>{middleTitle}</div>
                </div>
            )}
            {rightTitle && (
                <>
                    {color ? <FontAwesomeIcon icon={faChevronRight} color={color} size="lg" />
                        :
                        <img
                            style={{ width: '12px', height: '18px' }}
                            src={require('../../assets/arrow-right.png')}
                        />}
                    <div className="right-title">{rightTitle}</div>
                </>
            )}
        </div>
    );
};

export default Breadcrumbs;