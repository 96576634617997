import React, { useEffect, useMemo, useState } from 'react';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { Radio } from '@mui/material';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import './styles.scoped.css';
import { TablePagination } from '../../../components';

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const columnClassName = 'align-middle text-nowrap';
export const invoiceColumnsCostumer = [
  {
    Header: 'Customer Number',
    accessor: 'Client_Id',
    className: columnClassName,
  },
  {
    Header: 'Customer Name',
    accessor: 'Client_Name',
    className: columnClassName,
  },
  {
    Header: 'Type',
    accessor: 'Bill_Type',
    className: columnClassName,
  },
  {
    Header: 'Invoice #',
    accessor: 'Invoice_ID',
    className: columnClassName,
  },
  {
    Header: 'Date',
    accessor: 'Invoice_Date',
    className: columnClassName,
  },
  {
    Header: 'Discount',
    accessor: 'Discount',
    className: columnClassName,
  },
  {
    Header: 'Tax',
    accessor: 'Tax',
    className: columnClassName,
  },
  {
    Header: 'Total',
    accessor: 'Total',
    className: columnClassName,
  },
  {
    Header: 'Paid',
    accessor: 'Paid',
    className: columnClassName,
    Cell: instance => (instance.value === 1 ? 'Yes' : 'No'),
  },
  {
    Header: 'Aging',
    accessor: 'Aging',
    className: columnClassName,
  },
];
export const invoiceColumns = [
  {
    Header: 'Customer Number',
    accessor: 'Client_Id',
    className: columnClassName,
  },
  {
    Header: 'Customer Name',
    accessor: 'Client_Name',
    className: columnClassName,
  },
  {
    Header: 'Type',
    accessor: 'Invoice_Type',
    className: columnClassName,
  },
  {
    Header: 'Invoice #',
    accessor: 'Invoice_ID',
    className: columnClassName,
  },
  {
    Header: 'Date',
    accessor: 'Invoice_Date',
    className: columnClassName,
  },
  {
    Header: 'Discount',
    accessor: 'Discount_Amount',
    className: columnClassName,
  },
  {
    Header: 'Tax',
    accessor: 'Tax_Amount',
    className: columnClassName,
  },
  {
    Header: 'Total',
    accessor: 'Total_Amount',
    className: columnClassName,
  },
  {
    Header: 'Paid',
    accessor: 'Paid',
    className: columnClassName,
  },
  {
    Header: 'Aging',
    accessor: 'Aging',
    className: columnClassName,
  },
  {
    Header: 'Job',
    accessor: 'Job_ID',
    className: columnClassName,
  },
  {
    Header: 'Driver Number',
    accessor: 'Driver_ID',
    className: columnClassName,
  },
  {
    Header: 'Driver Name',
    accessor: 'Driver_Name',
    className: columnClassName,
  },
  {
    Header: 'Position',
    accessor: 'Position_Code',
    className: columnClassName,
  },
  {
    Header: 'Description',
    accessor: 'Position_Description',
    className: columnClassName,
  },
  {
    Header: 'Pay Code',
    accessor: 'Pay_Code',
    className: columnClassName,
  },
  {
    Header: 'Description',
    accessor: 'Pay_Code_Description',
    className: columnClassName,
  },
  {
    Header: 'Hours',
    accessor: 'Hours',
    className: columnClassName,
  },
  {
    Header: 'Bill Amount',
    accessor: 'Bill Amount',
    className: columnClassName,
  },
  {
    Header: 'Start Date',
    accessor: 'Start_Date',
    className: columnClassName,
  },
  {
    Header: 'End Date',
    accessor: 'End_Date',
    className: columnClassName,
  },
];

const InvoiceTable = ({ data, loading, rowSelected, isCustomer }) => {
  const memoData = useMemo(() => data, [data]);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    rowSelected(data[selectedRow]);
  }, [data, rowSelected, selectedRow]);

  const invoiceColumnsToUse = useMemo(
    () =>
      isCustomer
        ? [
            {
              id: 'select',
              Cell: ({ row }) => (
                <div className="px-1">
                  <Radio
                    style={{
                      color: '#da0f2e',
                      transform: 'scale(1)',
                    }}
                    onChange={() => setSelectedRow(row.id)}
                    checked={selectedRow === row.id}
                  />
                </div>
              ),
            },
            ...invoiceColumnsCostumer,
          ]
        : invoiceColumns,
    [selectedRow, isCustomer],
  );

  const tableInstance = useTable(
    {
      columns: invoiceColumnsToUse,
      data: memoData,
      initialState: {
        pageSize: 200,
        sortBy: [
          {
            id: 'CreatedAt',
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>
          {column.canSort
            ? getSortedIcon(column.isSorted, column.isSortedDesc)
            : null}
        </span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}
      >
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading && <Spinner animation="border" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default InvoiceTable;
