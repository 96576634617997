import React from 'react';
import { Spinner, Button, Form, Col, Row } from 'react-bootstrap';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ClericalQuoteDocument from '../GenerateQuote/Clerical/clericalPDF';
import DriversQuoteDocument from '../GenerateQuote/Drivers/driversPDF';

const SaveDownloadForm = ({ quote, isSaved, onSaveClicked }) => {
  const [quoteName, setQuoteName] = React.useState(quote.quoteName || '');
  const saveQuote = async () => {
    await onSaveClicked(quoteName);
  };
  const quoteDocument =
    quote.quoteType?.toLowerCase() === 'clerical' ? (
      <ClericalQuoteDocument quote={{ ...quote, quoteName }} />
    ) : (
      <DriversQuoteDocument quote={{ ...quote, quoteName }} />
    );

  const SaveButton = () => (
    <Button
      disabled={quoteName.length === 0 || saveQuote.isLoading}
      className="col-sm-5"
      onClick={() => saveQuote()}
      block
    >
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        hidden={!saveQuote.isLoading}
      />
      <span>{!saveQuote.isLoading && 'SAVE'}</span>
    </Button>
  );

  const DownloadButton = () => (
    <PDFDownloadLink
      document={quoteDocument}
      fileName={`quote_${quote.quoteId}.pdf`}
    >
      {({ loading, error }) => (
        <Button disabled={error || loading} className="col-sm-5" block>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            hidden={!loading}
          />
          <span>{!loading && 'DOWNLOAD'}</span>
        </Button>
      )}
    </PDFDownloadLink>
  );

  return (
    <>
      {isSaved ? (
        <DownloadButton />
      ) : (
        <>
          <Row>
            <Col>
              <Form>
                <Form.Group controlId="formAdminNumber">
                  <Form.Label>Name your quote</Form.Label>
                  <Form.Row>
                    <Col md>
                      <Form.Control
                        className="col-sm-5"
                        value={quoteName}
                        onKeyPress={e => {
                          e.key === 'Enter' && e.preventDefault();
                        }}
                        onChange={event => setQuoteName(event.target.value)}
                      />
                    </Col>
                  </Form.Row>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col className="mt-2 mb-2">
              <SaveButton />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default SaveDownloadForm;
