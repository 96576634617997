import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import logo from '../../../assets/centerline_logo_black_text.png';

const bootstrapStyles = {
  // bootstrap styles
  body: {
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
    marginBottom: 10,
  },
  h1: {
    fontSize: 36,
  },
  h2: {
    fontSize: 28,
  },
  h3: {
    fontSize: 24,
  },
  h4: {
    fontSize: 20,
  },
  h5: {
    fontSize: 16,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
};
// Create styles
export const styles = StyleSheet.create({
  ...bootstrapStyles,
  container: {
    padding: 10,
    flexGrow: 1,
  },
  page: {
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  title: {
    marginRight: 'auto',
    color: '#c8102e',
  },
  logo: {
    width: 230,
    height: 60,
  },
  formField: {
    fontSize: 12,
    color: '#333333',
    marginRight: 10,
  },
  formTitle: {
    fontSize: 12,
    color: '#333333',
  },
  formValue: {
    fontSize: 12,
    color: '#D0CED0',
  },
  formSection: {
    marginBottom: 10,
  },
  divider: {
    borderTop: 1,
    borderColor: '#333333',
  },
});

export const getTitle = title => {
  return (
    <View style={[styles.column]}>
      <Text style={styles.h5}>{title}</Text>
    </View>
  );
};

export const getFormField = (label, value) => {
  return (
    <View style={[styles.column, styles.formField]}>
      <Text>
        {label && `${label}: `}
        <Text style={styles.formValue}>{value}</Text>
      </Text>
    </View>
  );
};

export const getSection = (title, data = [], style) => {
  return (
    <View style={[styles.section, style]}>
      <View style={[styles.row]}>{getTitle(title)}</View>
      {data
        .filter(value => value)
        .map(({ label, value }) => (
          <View key={label} style={[styles.row]}>
            {getFormField(label, value)}
          </View>
        ))}
    </View>
  );
};

export const getHeader = (quoteName, quoteId) => {
  return (
    <>
      <View style={styles.row}>
        <Image source={logo} style={styles.logo} />
      </View>
      <View style={styles.row}>
        <View style={[styles.column, styles.center, styles.h4, styles.title]}>
          <Text>{quoteName}</Text>
        </View>
      </View>
      <View style={styles.row}>
        {getTitle('Quote *')}
        <View style={{ marginHorizontal: 'auto' }} />
        {getTitle(`#${quoteId}`)}
      </View>
      <View style={[styles.divider, { marginBottom: 10 }]} />
    </>
  );
};

export const getFooter = (daysValid, page, pages, quoteType) => {
  let { driverDisclaimer } = '';
  if (quoteType === 'Driver') {
    driverDisclaimer =
      '\n* Based on 50 hours workweek or 2,350 miles per week minimum.\n* Annual based on hours/miles net of PTO .';
  }
  return (
    <>
      <View style={[styles.row, { marginTop: 'auto' }]}>
        <Text style={styles.formValue}>
          * Quote is valid for {daysValid} days. If {daysValid} days crosses a
          calendar year, this is an Estimate only, not a firm Quote.
          {driverDisclaimer}
        </Text>
      </View>
      <View style={(styles.row, { marginTop: 'auto' })}>
        <View style={{ textAlign: 'right' }}>
          <Text style={styles.formValue}>
            Page {page} of {pages}
          </Text>
        </View>
      </View>
    </>
  );
};
