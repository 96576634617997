import React, { useEffect, useMemo, useState } from 'react';

import { Row, Form, Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import RiskClaimsValidationTable, { riskClaimsValidationColumns } from './RiskClaimsValidationTable';
import Excel from '../../../assets/excel.png';
import { Breadcrumbs, EdgeReportingWindow, SubHeader, ReportLoader } from '../../../components';
import { newDateFormatted } from '../../../utils';
import { formatPhoneNumber } from '../../../utils/utilities';
import { getStates } from '../../DriverQualification/ListDQ/NewRequest/operations';
import { getCitiesFiltered } from '../../ExpenseEntry/DriverSolutions/Hotel/operations';
import getCSVData from '../csv';
import { generateReport, getReportData, removeReportData, userHasAccess } from '../operations';
import { customStyles, formatSSNO } from '../utils';

import './styles.scoped.css';

const RiskClaimsValidation = () => {
  const navigate = useNavigate();
  const {
    clientMenu: { menuItems },
  } = useSelector(state => state.edgeReporting);
  const { user } = useSelector(state => state.admin);
  const [data, setData] = useState([]);
  const [form, setForm] = useState({});
  const [loadingResults, setLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [connectionState, setConnectionState] = useState();
  const [locationData, setLocationData] = useState();
  const [locationState, setLocationState] = useState({});
  const [locationCity, setLocationCity] = useState({});
  const [loadingCities, setLoadingCities] = useState(false);

  const viewFullPii = useMemo(() => user?.appAccess?.items?.find(item => item.appId.id === '4')?.roleId?.viewFullPii, [
    user,
  ]);

  useEffect(() => {
    if (menuItems) {
      // eslint-disable-next-line no-undef
      if (!userHasAccess(user, menuItems.find(i => i.to === window.location.pathname)?.id)) navigate('/');
    }
  }, [menuItems]);

  useEffect(() => {
    getAndSetFormData();
  }, []);

  useEffect(() => {
    if (locationState.stateId) {
      handleStateChange();
    }
  }, [locationState.stateId]);

  const clearCityData = () => {
    setLocationCity({
      cityId: '',
      cityName: '',
      isValid: true,
    });
    setLocationData({
      ...locationData,
      cities: [],
    });
  };

  const getAndSetFormData = async () => {
    const states = await getStates();
    if (states) {
      setLocationData({ ...locationData, states: states });
    }
  };

  let currentCities = [];
  let prevToken = '';

  const getCitiesBatch = async (nextToken = null) => {
    if (prevToken !== nextToken) {
      prevToken = nextToken;
      const data = await getCitiesFiltered({
        state: locationState.stateId,
        token: nextToken,
      });
      const token = data?.nextToken;

      if (data?.items) {
        const items = data?.items;
        currentCities = currentCities.concat(items);
      }
      if (token) {
        getCitiesBatch(token);
      } else {
        setLocationCity({
          cityId: '',
          cityName: '',
          isValid: true,
        });
        setLocationData({ ...locationData, cities: currentCities });
      }
    }
  };

  const handleStateChange = async () => {
    setLoadingCities(true);
    await getCitiesBatch();
    setLoadingCities(false);
  };

  const stateOptions = locationData?.states
    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(({ name, id }) => ({
      label: name,
      value: id,
    }));

  const fileName = `edge-reporting-risk-claims-validation-${newDateFormatted.toString()}.csv`;

  const handleViewResults = async () => {
    setLoadingResults(true);
    const {
      EmplLastName = '',
      EmplFirstName = '',
      SSNO = '',
      State = '',
      City = '',
      ZipCode = '',
      EmplPhone = '',
      EmpId = 0,
    } = form;
    try {
      const response = await generateReport({
        endpoint: 'risk-claims-validation-report',
        payload: {
          EmplLastName,
          EmplFirstName,
          SSNO,
          State,
          City,
          ZipCode,
          EmplPhone,
          EmpId,
          viewFullPii,
        },
      });
      if (response.location && response.location !== '') {
        const reportData = await getReportData(response.location);
        if (reportData?.length) {
          await removeReportData(response.location);
          setLoadingResults(false);
          setData(reportData);
          setShowResults(true);
        }
      } else {
        setLoadingResults(false);
        setLoadingResults(false);
        // eslint-disable-next-line no-undef
        alert('No data found matching your criteria.');
      }
    } catch (error) {
      console.log(error);
      setLoadingResults(false);
    }
  };

  const RiskClaimsValidationForm = ({ setForm, form }) => {
    const handleChange = e => {
      const _form = {
        ...form,
        [e.target.name]: e.target.name === 'EmplPhone' ? formatPhoneNumber(e.target.value) : e.target.value,
      };
      setForm(_form);
    };

    return (
      <Form
        noValidate
        onSubmit={() => { }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 20,
        }}>
        <div className="filters-row">
          <div className="filter-row">
            <Form.Group as={Row} controlId="EmplLastName" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Driver Last Name</Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                name="EmplLastName"
                defaultValue={form?.EmplLastName}
                onChange={handleChange}
              />
            </Form.Group>
          </div>

          <div className="filter-row">
            <Form.Group as={Row} controlId="EmplFirstName" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Driver First Name</Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                name="EmplFirstName"
                defaultValue={form?.EmplFirstName}
                onChange={handleChange}
              />
            </Form.Group>
          </div>

          <div className="filter-row">
            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Driver Number</Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                name="EmpId"
                value={form?.EmpId}
                onChange={handleChange}
              />
            </Form.Group>
          </div>

          <div className="filter-row">
            <Form.Group as={Row} controlId="SSNO" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>SSN</Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                name="SSNO"
                value={formatSSNO(form?.SSNO)}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="filter-row">
            <Form.Group as={Row} controlId="locationState">
              <Form.Label style={{ width: 290 }}>State</Form.Label>
              <Form.Group>
                <div className="dropdown-spinner-container">
                  <Select
                    isClearable
                    styles={customStyles('360px')}
                    options={stateOptions}
                    onChange={e => {
                      const _form = { ...form, State: e?.value || '' };
                      setForm(_form);
                    }}
                    value={stateOptions?.filter(state => state.value === form.State)}
                  />
                </div>
              </Form.Group>
            </Form.Group>
          </div>
          <div className="filter-row">
            <Form.Group as={Row} controlId="locationState">
              <Form.Label style={{ width: 290 }}>City</Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                }}
                as="input"
                name="City"
                value={form?.City}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="filter-row">
            <Form.Group as={Row} controlId="EmplFirstName" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Zip/Postal Code</Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                name="ZipCode"
                defaultValue={form?.ZipCode}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="filter-row">
            <Form.Group as={Row} controlId="EmplFirstName" style={{ alignItems: 'center' }}>
              <Form.Label style={{ width: '290px' }}>Driver Phone</Form.Label>
              <Form.Control
                style={{
                  width: '360px',
                  height: '30px',
                  marginRight: '20px',
                }}
                as="input"
                name="EmplPhone"
                value={form?.EmplPhone}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
        </div>
        <div className="buttons-row">
          <Button onClick={() => handleViewResults()} style={{ marginRight: '20px', width: '100px' }}>
            <span>VIEW</span>
          </Button>

          <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
            <span>CANCEL</span>
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <>
      <SubHeader
        text="EDGE Reporting"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Risk Claims Validation report?"
        emailAddress={process.env.REACT_APP_EDGE_REPORTING_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle="Reporting Home"
          middleTitle={showResults && 'Risk Claims Validation'}
          midItemAction={() => setShowResults(false)}
          rightTitle={showResults ? 'Viewing Report' : 'Risk Claims Validation'}
          route="/edge-reporting"
        />
        {!!data?.length && showResults && (
          <CSVLink filename={fileName} data={getCSVData(data, riskClaimsValidationColumns)}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
        )}
      </div>
      {loadingResults && <ReportLoader didAbort={() => setLoadingResults(false)} />}
      {showResults ? (
        <>
          <RiskClaimsValidationTable data={data} loading={loadingResults} />
          <div className="buttons-row">
            <Button onClick={() => setShowResults(false)} style={{ marginRight: '20px', width: '100px' }}>
              <span>BACK</span>
            </Button>

            <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
              <span>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <EdgeReportingWindow
          containerStyle={{
            border: '2px solid #343a40',
          }}
          windowContent={RiskClaimsValidationForm({ form, setForm })}
          windowTitle="Criteria"
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )}
    </>
  );
};

export default RiskClaimsValidation;
