import { createSlice } from '@reduxjs/toolkit';

const selectDocumentSlice = createSlice({
    name: 'selectDocumentDetails',
    initialState: {
        rows: [],
        selectedDocuments: [],
        submitChecklist: false,
    },
    reducers: {
        addRow: (state, { payload }) => {
            state.rows = [...state.rows, payload];
        },
        addSelectedDocuments: (state, { payload }) => {
            state.selectedDocuments = [...state.selectedDocuments, payload];
        },
        clearRows: (state, { payload }) => {
            state.rows = [];
        },
        clearSelectedDocuments: (state) => {
            state.selectedDocuments = [];
        },
        clearSubmitChecklist: (state, { payload }) => {
            state.submitChecklist = payload;
        },
        removeSelectedDocuments: (state, { payload }) => {
            state.selectedDocuments = [
                ...state.selectedDocuments.filter(item => item.documentName !== payload.documentName),
            ];
        },
        setSubmitChecklist: (state, { payload }) => {
            state.submitChecklist = payload;
        },
        updatePages: (state, { payload }) => {
            state.rows = state.rows.map(thing =>
                thing.id === payload.id
                    ? { ...thing, pages: payload.pages }
                    : thing,
            );

            state.selectedDocuments = state.selectedDocuments.map(thing =>
                thing.id === payload.id
                    ? { ...thing, pages: payload.pages }
                    : thing,
            );
        },
        updateOrder: (state, { payload }) => {
            let rowsCopy = JSON.parse(JSON.stringify(state.rows));

            rowsCopy = rowsCopy.map(item =>
                item.order?.toString() === payload.order.toString() &&
                    item.order != null
                    ? { ...item, order: null }
                    : item,
            );
            rowsCopy = rowsCopy.map(item => {
                return item.id === payload.id
                    ? { ...item, order: parseInt(payload.order) }
                    : item;
            });

            state.rows = rowsCopy.sort(
                (a, b) => parseInt(a?.order) - parseInt(b?.order),
            );

            let selectedDocumentsCopy = JSON.parse(JSON.stringify(state.selectedDocuments));

            selectedDocumentsCopy = selectedDocumentsCopy.map(item =>
                item.order?.toString() === payload.order.toString() &&
                    item.order != null
                    ? { ...item, order: null }
                    : item,
            );

            selectedDocumentsCopy = selectedDocumentsCopy.map(item => {
                return item.id === payload.id
                    ? { ...item, order: parseInt(payload.order) }
                    : item;
            });

            state.selectedDocuments = selectedDocumentsCopy.sort(
                (a, b) => parseInt(a?.order) - parseInt(b?.order),
            );
        },
    },
});

export default selectDocumentSlice.reducer;

export const {
    addRow,
    addSelectedDocuments,
    clearRows,
    clearSubmitChecklist,
    clearSelectedDocuments,
    removeSelectedDocuments,
    setSubmitChecklist,
    updateOrder,
    updatePages,
} = selectDocumentSlice.actions;
