import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';

import { Auth } from 'aws-amplify';
import { useBeforeunload } from 'react-beforeunload';
import { MdWeb } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import EditFormContent from './EditFormContent';
import { getCarRentals, getAudit } from './operations';
import { Breadcrumbs, ExpenseHistoryTable, ExpensesWindow, ModalConfirmation, SubHeader } from '../../../../components';
import { resetData } from '../../../../components/ExpenseForm/expenseFormSlice';
import { formatIsoToDate } from '../../../../utils';
import { isBilledOrTransmitted } from '../../../../utils/utilities';
import { getUserInfo } from '../../../DriverQualification/ViewDQF/operations';
import {
  calculateAuditKey,
  getCalculatedVariance,
  handleCreateVoidExpense,
  handleRebillExpense,
  handleVoidAndRebillExpense,
  receiptsHaveReceiptAmount,
  FlatFeeModal,
} from '../expensesUtils';
import {
  deleteExpenseFile,
  getExpense,
  updateExpense,
  updateExpenseItem,
  deleteExpense,
  deleteExpenseItem,
  createExpenseItem,
  checkClientFlatFee,
} from '../operations';
import { dateToTimeZero, preventMultipleSave } from '../TransferToUltraStaff/utils';

import './styles.scoped.css';

const EditCar = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useSelector(state => state.admin);
  const { clientMenu } = useSelector(state => state.expenseEntry);
  const dispatch = useDispatch();

  const [carRentals, setCarRentals] = useState([]);
  const [auditKey, setAuditKey] = useState('--');
  const [currentUser, setCurrentUser] = useState('');
  const [totalToBeBilled, setTotalToBeBilled] = useState('0.00');
  const [receipts, setReceipts] = useState([{ markup: '0', receiptAmount: '0.0' }]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [deletedReceipts, setDeletedReceipts] = useState([]);
  const [deletedReceiptDocs, setDeletedReceiptDocs] = useState([]);
  const [expenseDate, setExpenseDate] = useState('');
  const [showReceiptFilesDialog, setShowReceiptFilesDialog] = useState(false);
  const [receiptModified, setReceiptModified] = useState(false);
  const [showReceiptModified, setShowReceiptModified] = useState(false);
  const hasSetForm = useRef(false);
  const hasSetReceiptOptions = useRef(false);
  const rebilled = useRef(false);
  const [showFFDialog, setShowFFDialog] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [showNonFFDialog, setShowNonFFDialog] = useState(false);
  const formHasChanged = useRef({
    drivers: false,
    assignments: false,
    clients: false,
    branches: false,
  });
  const [expenseHistoryData, setExpenseHistoryData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [assignmentLineOfBusinessItems, setAssignmentLineOfBusinessItems] = useState({});

  const [form, setForm] = useState({
    code: 'XC',
    status: 'Unbilled',
    nonBillable: false,
  });

  const getAssignmentLineOfBusinessItems = () => {
    clientMenu?.menuItems?.map(i => {
      if (location.pathname.includes(i.to)) {
        setAssignmentLineOfBusinessItems(i);
      }
    });
  };

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      document.querySelector('div.root').style.background = '#D0CED0';
      document.querySelector('span.wrap').style.background = '#ffff';
      document.querySelector('.left-title').style.color = '#ffff';
      document.querySelector('.right-title').style.color = '#66CCFF';
    }
    return () => {
      document.querySelector('div.root').style.background = '#ffff';
    }
  }, []);

  useEffect(() => {
    if (uploadError) {
      // eslint-disable-next-line no-undef
      alert(uploadError);
      setUploadError(null);
    }
  }, [uploadError]);

  useEffect(
    () => () => {
      dispatch(resetData());
    },
    [],
  );

  const requiredReceiptFields = [
    'expenseItemCarRentalCompanyId',
    'carRentalContractNumber',
    'carRentalPickUpLocation',
    'carRentalReturnLocation',
    'carRentalPickUpDate',
    'carRentalReturnDate',
    'markup',
    'receiptAmount',
  ];

  const checkReceiptsErrors = () => {
    const allErrors = [...receiptsErrors];

    allErrors.forEach((receiptErrors, scopedId) => {
      const receiptErrorsCopy = { ...receiptErrors };
      requiredReceiptFields.forEach(field => {
        if (receipts?.[scopedId][field]) {
          delete receiptErrorsCopy[field];
          if (!Object.keys(receiptErrorsCopy).length) {
            allErrors.splice(scopedId, 1);
          } else {
            allErrors[scopedId] = receiptErrorsCopy;
          }
        }
      });

      setReceiptsErrors(allErrors);
    });
  };

  useEffect(() => {
    checkReceiptsErrors();
  }, [receipts]);

  useBeforeunload(event => {
    if (hasChanges) {
      event.preventDefault();
    }
  });

  const navigateCancelAction = () => {
    if (receiptModified && !hasChanges) {
      setShowReceiptModified(true);
      setReceiptModified(false);
    }
    else {
      setReceiptModified(false);
      navigate(`${assignmentLineOfBusinessItems.to}/search-expenses`);
    }
  };

  const [errors, setErrors] = useState();
  const [receiptsErrors, setReceiptsErrors] = useState([]);

  const setCurrentForm = exp => {
    setForm({
      costCenter: exp?.costCenter,
      code: exp?.code,
      driverId: exp?.driverId,
      driverName: exp?.driverName,
      selectedDriver: `${exp?.driverName} - ${exp?.driverId}`,
      assignmentId: exp?.assignmentId,
      status: exp?.status,
      nonBillable: exp?.nonBillable,
      customerId: exp?.customerId,
      customerName: exp?.customerName,
      selectedCustomer: `${exp?.customerName} - ${exp?.customerId}`,
      branch: exp?.branch,
      branchName: exp?.branchName,
      branchSelection: `${exp?.branch} - ${exp?.branchName}`,
      startDate: exp?.startDate,
      endDate: exp?.endDate,
      totalToBeBilled: exp?.totalToBeBilled,
      notes: exp?.notes,
      auditKey: exp?.auditKey,
      totalProjectedAmount: exp?.totalProjectedAmount,
      invoiceDate: exp?.invoiceDate ? dateToTimeZero(exp?.invoiceDate) : null,
      invoiceNumber: exp?.invoiceNumber,
      amountBilled: exp?.amountBilled,
      variance:
        exp?.variance ||
        getCalculatedVariance({
          amountBilled: exp?.amountBilled,
          totalToBeBilled: exp?.totalToBeBilled,
        }),
      statementDate: exp?.statementDate ? formatIsoToDate(exp?.statementDate, 'YYYY-MM-DD') : null,
      expenseReportAppropriationNbr: exp?.expenseReportAppropriationNbr,
      originalExpenseId: exp?.originalExpenseId,
      correctionExpenseId: exp?.correctionExpenseId,
      rebilledExpenseId: exp?.rebilledExpenseId,
      flatFeeBilling: exp?.flatFeeBilling,
      lob: exp?.lob,
    });
    setExpenseDate(exp?.createdAt);
    if (exp?.expenseItem.items) {
      const expenseItemsCopy = [...(exp?.expenseItem.items || [])];
      expenseItemsCopy.forEach(receipt => {
        receipt.carRentalPickUpDate = formatIsoToDate(receipt?.carRentalPickUpDate, 'YYYY-MM-DD');
        receipt.carRentalReturnDate = formatIsoToDate(receipt?.carRentalReturnDate, 'YYYY-MM-DD');
      });

      setReceipts(expenseItemsCopy);
    }
  };

  const handleSetForm = async () => {
    if (id) {
      try {
        hasSetForm.current = true;
        const expense = await getExpense(id);
        console.log('expense: ', expense);
        if (expense) {
          setCurrentForm(expense);
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  useEffect(() => {
    if (carRentals.length && hasSetForm.current === false) {
      handleSetForm();
    }
  }, [carRentals]);

  const setReceiptOptions = () => {
    if (carRentals && Object.keys(receipts?.[0]).length) {
      const receiptsCopy = [...receipts];
      hasSetReceiptOptions.current = true;
      receiptsCopy.forEach(c => {
        const car = carRentals?.filter(aCar => aCar.id === c.expenseItemCarRentalCompanyId)?.[0]?.name;
        c.selectedCar = car;
      });
      setReceipts(receiptsCopy);
    }
  };

  useEffect(() => {
    if (hasSetReceiptOptions.current === false) {
      if (receipts?.length) setReceiptOptions();
    }
  }, [carRentals, receipts]);

  const fieldsAreValid = () => {
    let formErrorsCopy = { ...errors };
    const receiptsErrorsCopy = [...receiptsErrors];
    const requiredFields = [
      'code',
      'driverId',
      'driverName',
      'assignmentId',
      'status',
      'customerId',
      'customerName',
      'branch',
    ];

    requiredFields.forEach(field => {
      if (!form[field] || form[field] === '' || form[field] === '--') {
        formErrorsCopy = {
          ...formErrorsCopy,
          [field]: 'Please provide a valid value.',
        };
      }
    });

    receipts.forEach((r, index) => {
      requiredReceiptFields.forEach(rf => {
        if (rf === 'markup' || rf === 'receiptAmount') {
          if (r[rf] === '') {
            receiptsErrorsCopy[index] = {
              ...receiptsErrorsCopy[index],
              [rf]: 'Please provide a valid value.',
            };
          }
        } else if (!r[rf] || r[rf] === '' || r[rf] === '--') {
          receiptsErrorsCopy[index] = {
            ...receiptsErrorsCopy[index],
            [rf]: 'Please provide a valid value.',
          };
        }
      });
    });

    setReceiptsErrors(receiptsErrorsCopy);
    setErrors(formErrorsCopy);

    return !receiptsErrorsCopy.length && !Object.keys(formErrorsCopy).length;
  };

  const bulkUpdateExpenseItem = async items => {
    Promise.all(items.map(item => updateExpenseItem(item))).then(() => { });
  };

  const bulkCreateExpenseItem = async items => {
    Promise.all(items.map(item => createExpenseItem(item))).then(() => { });
  };

  const bulkDeleteReceipts = async items => {
    Promise.all(items.map(item => deleteExpenseFile(item)));
  };

  const bulkDeleteExpenseItems = async items => {
    Promise.all(items.map(item => deleteExpenseItem(item.id)));
  };

  const receiptsHaveFiles = () => {
    let haveFiles = true;
    receipts.forEach(r => {
      if (!r?.documentName) {
        haveFiles = false;
      }
    });
    return haveFiles;
  };

  const getReceiptStatus = billable => {
    let status = form?.status;
    if (!isBilledOrTransmitted(form)) {
      if ((form?.status !== 'Non-billable' && form?.status !== 'Flat Fee Billing') || billable) {
        if (receiptsHaveFiles() && fieldsAreValid() && receiptsHaveReceiptAmount(receipts)) {
          status = 'Ready to Bill';
        } else {
          status = 'Unbilled';
        }
      }
    }

    return status;
  };

  const confirmEditExpense = async ({ route = assignmentLineOfBusinessItems.to, isFF = false, isBillable = false }) => {
    setIsLoading(true);
    if (fieldsAreValid()) {
      const expense = {
        id: id,
        code: isFF ? 'XFFBC' : isBillable ? 'XC' : form?.code,
        driverId: form?.driverId,
        driverName: form?.driverName,
        assignmentId: form?.assignmentId,
        status: isFF ? 'Flat Fee Billing' : getReceiptStatus(isBillable),
        nonBillable: isFF || (isBillable ? false : form?.nonBillable),
        customerId: form?.customerId,
        customerName: form?.customerName,
        branch: form?.branch,
        branchName: form?.branchName,
        costCenter: parseInt(form?.costCenter),
        startDate: form?.startDate ? dateToTimeZero(form?.startDate) : null,
        endDate: form?.endDate ? dateToTimeZero(form?.endDate) : null,
        totalToBeBilled: totalToBeBilled,
        notes: form?.notes,
        auditKey: auditKey,
        invoiceDate: form?.invoiceDate ? dateToTimeZero(form?.invoiceDate) : null,
        invoiceNumber: form?.invoiceNumber,
        amountBilled: form?.amountBilled,
        variance:
          form?.variance ||
          getCalculatedVariance({
            amountBilled: form?.amountBilled,
            totalToBeBilled: totalToBeBilled,
          }),
        statementDate: form?.statementDate ? dateToTimeZero(form?.statementDate) : null,
        expenseReportAppropriationNbr: form?.expenseReportAppropriationNbr,
        flatFeeBilling: isFF,
        updatedBy: user.id,
        lob: form?.lob,
      };
      try {
        setIsLoading(true);

        await updateExpense(expense);
        const receiptsCopy = [...receipts];

        receiptsCopy.forEach(receipt => {
          delete receipt.createdAt;
          delete receipt.updatedAt;
          delete receipt.selectedCar;
          delete receipt.__typename;
          receipt.carRentalPickUpDate = dateToTimeZero(receipt?.carRentalPickUpDate);
          receipt.carRentalReturnDate = dateToTimeZero(receipt?.carRentalReturnDate);

          if (!receipt?.receiptAmount) {
            receipt.receiptAmount = 0.0;
            receipt.expenseTotal = 0.0;
          }
        });

        if (deletedReceiptDocs.length) {
          await bulkDeleteReceipts(deletedReceiptDocs);
        }

        if (deletedReceipts) {
          await bulkDeleteExpenseItems(deletedReceipts);
        }

        const receiptsToUpdate = [];
        const receiptsToCreate = [];
        receiptsCopy.forEach(r => {
          if (r?.id) {
            receiptsToUpdate.push(r);
          } else {
            const receipt = { ...r };
            receipt.expenseExpenseItemId = id;
            receiptsToCreate.push(receipt);
          }
        });

        if (receiptsToUpdate?.length) {
          await bulkUpdateExpenseItem(receiptsToUpdate);
        }
        if (receiptsToCreate?.length) {
          await bulkCreateExpenseItem(receiptsToCreate);
        }
        setIsLoading(false);
        navigate(route || -1);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  const calculateTotal = () => {
    let total = parseFloat(receipts?.reduce((a, b) => parseFloat(a) + (parseFloat(b?.expenseTotal) || 0), 0)).toFixed(
      2,
    );
    if (id.includes('CR')) total = -Math.abs(total);
    setTotalToBeBilled(total);
  };

  const getStartAndEndDate = () => {
    let startDate = '';
    let endDate = '';
    const emptyDate = '--';

    receipts.forEach(r => {
      if (!startDate.length && r?.carRentalPickUpDate) {
        startDate = r?.carRentalPickUpDate;
        endDate = r?.carRentalReturnDate;
      } else {
        if (r?.carRentalPickUpDate && r?.carRentalPickUpDate < startDate) {
          startDate = r?.carRentalPickUpDate;
        }
        if (r?.carRentalReturnDate && r?.carRentalReturnDate > endDate) {
          endDate = r?.carRentalReturnDate;
        }
      }
    });

    const emptyDates = { startDate: emptyDate, endDate: emptyDate };
    const newDates = {
      startDate: formatIsoToDate(startDate),
      endDate: formatIsoToDate(endDate),
    };
    if (!!startDate && !!endDate) {
      setForm({ ...form, ...newDates });
    } else {
      setForm({ ...form, ...emptyDates });
    }
  };

  useEffect(() => {
    calculateTotal();
    getStartAndEndDate();
    getAssignmentLineOfBusinessItems();
  }, [receipts]);

  useEffect(() => {
    calculateAuditKey(form, totalToBeBilled, setAuditKey);
  }, [form]);

  const getAndSetUserInfo = async () => {
    const { email } = (await Auth.currentSession()).getIdToken().payload;
    const user = await getUserInfo(email);
    setCurrentUser(user);
    const audits = await getAudit(id);
    setExpenseHistoryData(audits);
  };

  const getAndSetCarRentals = async () => {
    const resp = await getCarRentals();
    setCarRentals(resp.items);
  };

  const handleDeleteExpense = async () => {
    try {
      setIsLoading(true);
      await deleteExpense(id);
      await bulkDeleteExpenseItems(receipts);
      const receiptDocs = [];
      receipts?.forEach(r => {
        receiptDocs.push(r?.documentName);
      });
      if (receiptDocs.length) {
        bulkDeleteReceipts(receiptDocs);
      }
    } catch (error) {
      console.log('error deleting expense', error);
      setIsLoading(false);
    }
    navigate(assignmentLineOfBusinessItems.to);
  };

  useEffect(() => {
    getAndSetCarRentals();
    getAndSetUserInfo();
  }, []);

  return (
    <div>
      <SubHeader
        text="Expenses"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Expenses?"
        emailAddress={process.env.REACT_APP_EXPENSE_CONTACT_EMAIL}
      />
      <Breadcrumbs
        leftTitle={assignmentLineOfBusinessItems.text}
        rightTitle={'Edit Car'}
        route={assignmentLineOfBusinessItems.to}
      />
      <ExpensesWindow
        windowContent={
          <EditFormContent
            user={user}
            expenseDate={expenseDate}
            form={form}
            id={id}
            errors={errors}
            setForm={setForm}
            rebilled={rebilled}
            formHasChanged={formHasChanged}
            receipts={receipts}
            totalToBeBilled={totalToBeBilled}
            auditKey={auditKey}
            isLoading={isLoading}
            saveDisabled={saveDisabled}
            carRentals={carRentals}
            currentUser={currentUser}
            receiptsErrors={receiptsErrors}
            setReceiptsErrors={setReceiptsErrors}
            setReceipts={setReceipts}
            setUploading={setUploading}
            setUploadError={setUploadError}
            isCR={id.includes('CR')}
            deletedReceipts={deletedReceipts}
            setDeletedReceipts={setDeletedReceipts}
            deletedReceiptDocs={deletedReceiptDocs}
            setDeletedReceiptDocs={setDeletedReceiptDocs}
            hasChanges={hasChanges}
            setHasChanges={setHasChanges}
            setErrors={setErrors}
            setShowCancelDialog={setShowCancelDialog}
            navigateCancelAction={navigateCancelAction}
            checkClientFlatFee={checkClientFlatFee}
            confirmEditExpense={confirmEditExpense}
            setShowFFDialog={setShowFFDialog}
            setShowNonFFDialog={setShowNonFFDialog}
            setIsLoading={setIsLoading}
            deleteExpense={deleteExpense}
            bulkDeleteExpenseItems={bulkDeleteExpenseItems}
            bulkDeleteReceipts={bulkDeleteReceipts}
            preventMultipleSave={preventMultipleSave}
            setSaveDisabled={setSaveDisabled}
            handleCreateVoidExpense={handleCreateVoidExpense}
            handleRebillExpense={handleRebillExpense}
            handleVoidAndRebillExpense={handleVoidAndRebillExpense}
            fieldsAreValid={fieldsAreValid}
            receiptsHaveFiles={receiptsHaveFiles}
            setShowReceiptFilesDialog={setShowReceiptFilesDialog}
            setReceiptModified={setReceiptModified}
            setShowReceiptModified={setShowReceiptModified}
            setShowDeleteConfirmation={setShowDeleteConfirmation}
            assignmentLineOfBusinessItems={assignmentLineOfBusinessItems}
          />
        }
        windowTitle="Edit Car"
      />

      <div className='table-container'>
        <ExpenseHistoryTable
          data={expenseHistoryData}
        />
      </div>


      <ModalConfirmation
        title="Receipt attachment has been modified"
        body="Click Save to complete the update"
        primaryButton
        primaryButtonTitle="Save"
        optionalClose
        secondaryButtonTitle="Leave Page"
        onClick={async () => {
          await confirmEditExpense({
            route: assignmentLineOfBusinessItems.to,
          });
          setShowReceiptModified(false);
        }}
        secondaryOnClick={() => {
          setShowReceiptModified(false);
          navigateCancelAction();
        }}
        isOpen={showReceiptModified}
      />

      <ModalConfirmation
        title="Cancel?"
        body="Changes you made will be lost"
        primaryButtonTitle="Stay on Page"
        optionalClose
        secondaryButtonTitle="Leave Page"
        onClick={() => {
          setShowCancelDialog(false);
        }}
        secondaryOnClick={() => {
          setShowCancelDialog(false);
          navigateCancelAction();
        }}
        isOpen={showCancelDialog}
      />
      <ModalConfirmation
        title="Please Note!"
        body="Please attach the receipt when available or this expense will not be billed"
        primaryButton
        primaryButtonTitle="OK"
        onClick={async () => {
          setShowReceiptFilesDialog(false);
          await confirmEditExpense({
            isFF: form?.flatFeeBilling,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        isOpen={showReceiptFilesDialog}
      />

      <ModalConfirmation
        title="Confirmation"
        body="Deletion is permanent. Click Confirm to delete expense. Click Cancel to return."
        primaryButtonTitle="CONFIRM"
        primaryButton
        optionalClose
        secondaryButtonTitle="CANCEL"
        onClick={() => {
          setShowDeleteConfirmation(false);
          handleDeleteExpense();
        }}
        secondaryOnClick={() => {
          setShowDeleteConfirmation(false);
        }}
        isOpen={showDeleteConfirmation}
      />

      <FlatFeeModal
        showDialog={showFFDialog}
        clientIsFlatFee
        setShowDialog={setShowFFDialog}
        ffAction={async () => {
          await confirmEditExpense({
            isFF: true,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        billableAction={async () => {
          await confirmEditExpense({
            isBillable: true,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        cancelAction={() => { }}
      />
      <FlatFeeModal
        showDialog={showNonFFDialog}
        clientIsFlatFee={false}
        setShowDialog={setShowNonFFDialog}
        ffAction={async () => {
          await confirmEditExpense({
            isFF: true,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        billableAction={async () => {
          await confirmEditExpense({
            isBillable: true,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        cancelAction={() => { }}
      />
      {uploading && <div className="overlay-box" />}
    </div>
  );
};

export default EditCar;
