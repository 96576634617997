import React from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Button, Modal } from 'react-bootstrap';

import { createExpenseItem, createExpense, updateExpense } from '../DriverSolutions/operations';
import { dateToTimeZero } from './TransferToUltraStaff/utils';
import { formatIsoToDate } from '../../../utils';

dayjs.extend(customParseFormat);

export const handleCreateVoidExpense = async ({
  id,
  expense,
  receipts,
  auditKey,
  totalToBeBilled,
  setIsLoading,
  setForm,
  navigate,
  expenseType,
  isRebilling,
  updatedBy,
  route,
}) => {
  setIsLoading(true);

  const voidedExpense = { ...expense };
  voidedExpense.originalExpenseId = id;
  voidedExpense.id = `${id}CR`;
  voidedExpense.status = 'Ready to Bill';
  voidedExpense.updatedBy = updatedBy;
  delete voidedExpense.selectedDriver;

  if (['XHS', 'XHSR', 'XFFBHS'].includes(voidedExpense?.code)) {
    delete voidedExpense.selectedCity;
    delete voidedExpense.selectedHotel;
    delete voidedExpense.selectedState;
    voidedExpense.driverId = receipts[0]?.hotelDriverId;
    voidedExpense.driverName = receipts[0]?.hotelDriverName;
    voidedExpense.assignmentId = receipts[0]?.hotelAssignmentId;
  }

  delete voidedExpense?.invoiceDate;
  delete voidedExpense?.invoiceNumber;
  delete voidedExpense?.amountBilled;
  delete voidedExpense?.variance;
  delete voidedExpense?.selectedCustomer;
  delete voidedExpense?.branchSelection;
  voidedExpense.startDate = dateToTimeZero(expense?.startDate);
  voidedExpense.endDate = dateToTimeZero(expense?.endDate);
  if (expense?.statementDate) {
    voidedExpense.statementDate = dateToTimeZero(expense?.statementDate);
  }
  if (voidedExpense.isVoided !== undefined) {
    delete voidedExpense.isVoided;
  }
  if (voidedExpense.isVoidedRebilled !== undefined) {
    delete voidedExpense.isVoidedRebilled;
  }
  voidedExpense.totalToBeBilled = -Math.abs(totalToBeBilled);
  voidedExpense.auditKey = auditKey;
  console.log(JSON.stringify(voidedExpense));
  const resp = await createExpense(voidedExpense);

  const receiptsCopy = receipts?.map(receipt => {
    const receiptCopy = { ...receipt };
    if (!receiptCopy?.receiptAmount) {
      receiptCopy.receiptAmount = 0.0;
      receiptCopy.expenseTotal = 0.0;
    }
    delete receiptCopy.id;
    delete receiptCopy.createdAt;
    delete receiptCopy.updatedAt;

    // logic to change fields depending on expenseType
    if (expenseType === 'airline') {
      delete receiptCopy.selectedAirline;
      delete receiptCopy.selectedDepartureAirport;
      delete receiptCopy.selectedArrivalAirport;
      receiptCopy.flightDate = dateToTimeZero(receiptCopy?.flightDate);
    }

    if (expenseType === 'hotel') {
      receiptCopy.checkInDate = dateToTimeZero(receiptCopy?.checkInDate);
      receiptCopy.lastNightOfStay = dateToTimeZero(receiptCopy?.lastNightOfStay);
      delete receiptCopy.selectedState;
      delete receiptCopy.selectedCity;
      delete receiptCopy.selectedHotel;
      delete receiptCopy.selectedCities;
      delete receiptCopy.selectedDriver;
      if (!receiptCopy?.totalReceiptAmount) {
        receiptCopy.totalReceiptAmount = 0.0;
      }
    }

    if (expenseType === 'car') {
      delete receiptCopy.selectedCar;
      receiptCopy.carRentalPickUpDate = dateToTimeZero(receiptCopy?.carRentalPickUpDate);
      receiptCopy.carRentalReturnDate = dateToTimeZero(receiptCopy?.carRentalReturnDate);
      if (!receiptCopy?.totalReceiptAmount) {
        receiptCopy.totalReceiptAmount = 0.0;
      }
      if (!receiptCopy?.receiptAmount) {
        receiptCopy.receiptAmount = 0.0;
        receiptCopy.expenseTotal = 0.0;
      }
      if (!receiptCopy?.originalReceiptAmount) {
        receiptCopy.originalReceiptAmount = 0.0;
      }
    }

    if (expenseType === 'other') {
      receiptCopy.otherExpenseDate = dateToTimeZero(receiptCopy?.otherExpenseDate);
    }
    receiptCopy.expenseExpenseItemId = resp.id;
    return receiptCopy;
  });

  await bulkCreateExpenseItem(receiptsCopy);

  const updatedExpense = { id: id };
  updatedExpense.correctionExpenseId = `${id}CR`;
  updatedExpense.status = 'Voided';
  updatedExpense.updatedBy = updatedBy;
  if (expense?.statementDate) {
    updatedExpense.statementDate = dateToTimeZero(expense?.statementDate);
  }

  updatedExpense.expenseReportAppropriationNbr = expense?.expenseReportAppropriationNbr;
  await updateExpense(updatedExpense);

  setForm({
    ...expense,
    correctionExpenseId: `${id}CR`,
    status: 'Voided',
  });

  setIsLoading(false);
  if (!isRebilling) {
    navigate(route);
  }

};

const bulkCreateExpenseItem = async items => {
  items.forEach(item => createExpenseItem(item));
};

export const handleRebillExpense = async ({ id, expense, receipts, setIsLoading, navigate, expenseType, route }) => {
  setIsLoading(true);
  const updatedExpense = { id: id };
  updatedExpense.statementDate = dateToTimeZero(expense?.statementDate);
  updatedExpense.expenseReportAppropriationNbr = expense?.expenseReportAppropriationNbr;
  await updateExpense(updatedExpense);
  setIsLoading(false);
  navigate(`${route}/${expenseType}`, {
    state: { expenseId: id, expense: expense, receipts: receipts },
  });
};

export const handleVoidAndRebillExpense = async ({
  id,
  expense,
  receipts,
  auditKey,
  totalToBeBilled,
  setIsLoading,
  setForm,
  navigate,
  expenseType,
  updatedBy,
  route,
}) => {
  await handleCreateVoidExpense({
    id,
    expense,
    receipts,
    auditKey,
    totalToBeBilled,
    setIsLoading,
    setForm,
    navigate,
    expenseType,
    isRebilling: true,
    updatedBy,
    route,
  });

  await handleRebillExpense({
    id,
    expense,
    receipts,
    setIsLoading,
    navigate,
    expenseType,
    updatedBy,
    route,
  });
};

const clientFFModalBody = (
  <p>
    Click Flat Fee to update the status to `&quot;`Flat Fee Billing`&quot;` and save as a non-billable expense.
    <br />
    <br />
    Click Cancel to close this message and exit without saving any edits.
    <br />
    <br />
    Click Billable to create an expense that will still be billed despite the Client being on Flat Fee Billing.
  </p>
);

const clientNonFFModalBody = (
  <p>
    Click Flat Fee to continue saving as a Flat Fee - non-billable expense.
    <br />
    <br />
    Click Cancel to close this message and exit without saving any edits.
    <br />
    <br />
    Click Billable to change the expense to billable and move to ready to bill.
  </p>
);

export const FlatFeeModal = ({
  clientIsFlatFee,
  showDialog,
  setShowDialog,
  billableAction,
  ffAction,
  cancelAction,
}) => (
  <Modal show={showDialog} style={{ zIndex: 100000 }}>
    <Modal.Header>
      <Modal.Title>{clientIsFlatFee ? 'Client on Flat Fee!' : 'Client not on Flat Fee Billing'}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{clientIsFlatFee ? clientFFModalBody : clientNonFFModalBody}</Modal.Body>
    <Modal.Footer style={{ justifyContent: 'center' }}>
      <Button
        variant={clientIsFlatFee ? 'secondary' : 'primary'}
        onClick={async () => {
          setShowDialog(false);
          await billableAction();
        }}>
        BILLABLE
      </Button>
      <Button
        variant="secondary"
        onClick={async () => {
          setShowDialog(false);
          await cancelAction();
        }}>
        CANCEL
      </Button>

      <Button
        variant={clientIsFlatFee ? 'primary' : 'secondary'}
        onClick={async () => {
          setShowDialog(false);
          await ffAction();
        }}>
        FLAT FEE
      </Button>
    </Modal.Footer>
  </Modal>
);

export const calculateAuditKey = (form, totalToBeBilled, setAuditKey) => {
  let customDate = '';
  if (form?.startDate?.length > 2 && form?.endDate?.length > 2 && form?.driverId && totalToBeBilled !== '0.00') {
    const startDate = dayjs('1899-12-31');
    const endDate = formatIsoToDate(form.startDate);

    const differenceInDays = (dayjs(endDate)?.diff(startDate, 'day') || 0) + 1;

    customDate = differenceInDays.toFixed(0).toString();

    setAuditKey(`${form?.driverId}${totalToBeBilled}${customDate}`);
  }
};

export const getCalculatedVariance = ({ amountBilled, totalToBeBilled }) => {
  let variance;
  if (amountBilled) {
    variance = parseFloat(amountBilled) - parseFloat(totalToBeBilled);
  } else if (totalToBeBilled) {
    variance = parseFloat(-totalToBeBilled);
  } else {
    variance = parseFloat(0);
  }
  return variance;
};

export const receiptsHaveReceiptAmount = receipts => receipts.every(receipt => receipt.receiptAmount);

export const customSortByCreatedAt = (rows, sortDescending, accessor) => {
  // Get a copy of the rows to sort
  const sortedRows = [...rows];

  // Define a custom compare function that treats the dates as milliseconds
  const compare = (a, b) => {
    const aDate = new Date(a[accessor]).getTime();
    const bDate = new Date(b[accessor]).getTime();
    if (aDate < bDate) return -1;
    if (aDate > bDate) return 1;
    return 0;
  };

  // Sort the rows using the custom compare function
  sortedRows.sort(compare);

  // Reverse the order if sorting in descending order
  if (sortDescending) {
    sortedRows.reverse();
  }

  return sortedRows;
};


