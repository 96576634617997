import React, { useMemo, useEffect } from 'react';

import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { formatIsoToDate } from '../../utils';
import { getExpenseType } from '../../utils/utilities';
import TablePagination from '../TablePagination';

import './styles.scoped.css';

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const datetime = (a, b, { accessor }) => {
  const aDate = new Date(a.createdAt).getTime();
  const bDate = new Date(b.createdAt).getTime();

  if (aDate < bDate) {
    return -1;
  }
  if (aDate > bDate) {
    return 1;
  }
  return 0;
};

const ResultsTable = ({ data, loading, getBatch, to }) => {
  const columnClassName = 'align-middle text-wrap ';
  const memoData = useMemo(() => data, [data]);

  const EditExpenseButton = expense => {
    const thisExpense = expense.data[parseInt(expense.cell.row.id)];

    const expenseType = getExpenseType(thisExpense);
    return (
      <Link to={`${to}/edit-${expenseType}/${thisExpense.id}`}>
        <MdEdit />
      </Link>
    );
  };

  useEffect(() => {
    getBatch();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Expense ID',
        accessor: 'id',
        className: columnClassName,
      },
      {
        Header: 'Driver Name & Number',
        accessor: 'driverNameNumber',
        className: columnClassName,
      },
      {
        Header: 'Assignment / Contract Act.',
        accessor: 'assignmentId',
        className: columnClassName,
      },
      {
        Header: 'Customer Name & Number',
        accessor: 'customerNameNumber',
        className: columnClassName,
      },
      {
        Header: 'Date Entered',
        accessor: 'createdAt',
        className: columnClassName,
        Cell: instance => `${formatIsoToDate(instance.value) || ''}`,
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        className: columnClassName,
        Cell: instance => `${formatIsoToDate(instance.value) || ''}`,
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        className: columnClassName,
        Cell: instance => `${formatIsoToDate(instance.value) || ''}`,
      },
      {
        Header: 'Pay Week Ending',
        accessor: 'payWeekEnding',
        className: columnClassName,
        Cell: instance => `${formatIsoToDate(instance.value) || ''}`,
      },
      {
        Header: 'Pay Code',
        accessor: 'code',
        className: columnClassName,
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: columnClassName,
      },
      {
        Header: 'Bill Amount',
        accessor: 'totalToBeBilled',
        className: columnClassName,
      },
      {
        Header: '',
        accessor: 'editExpense',
        className: columnClassName,
        Cell: instance => EditExpenseButton(instance),
      },
    ],
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data: memoData,
      initialState: {
        sortBy: [
          {
            id: 'createdAt',
            desc: true,
          },
        ],
      },
      sortTypes: {
        datetime,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setFilter,
    setGlobalFilter,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>{column.canSort ? getSortedIcon(column.isSorted, column.isSortedDesc) : null}</span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}>
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  function filter(event, columnName) {
    const filterColumn = columns.find(column => column.Header === columnName);
    if (filterColumn) {
      setFilter(filterColumn.accessor, event.target.value);
    } else {
      setGlobalFilter(event.target.value);
    }
  }

  return (
    <Container fluid className="table-container">
      <Row className="align-items-center">
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row style={{ border: '1px solid #343a40', borderRadius: '10px', backgroundColor: 'white' }}>
        <Col>
          <div
            style={{
              color: "red",
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '10px',
            }}>
            {loading && <Spinner animation="border" />}
          </div>
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default ResultsTable;
