import React from 'react';
import './styles.scoped.css';

const Tabs = ({ styles, tabs, activeTab, currentContent, setTab }) => {
  return (
    <div className="tabs-general-container" style={styles}>
      <div className="tabs-switcher">
        <div
          onClick={() => setTab(tabs[0].toLowerCase())}
          className={
            activeTab === tabs[0].toLowerCase() ? 'active-tab' : 'inactive-tab'
          }
        >
          {tabs[0]}
        </div>
        <div
          onClick={() => setTab(tabs[1].toLowerCase())}
          className={
            activeTab === tabs[1].toLowerCase() ? 'active-tab' : 'inactive-tab'
          }
        >
          {tabs[1]}
        </div>
      </div>
      <div className="current-content-container">{currentContent()}</div>
    </div>
  );
};

export default Tabs;
