import React, { useEffect, useState, useMemo } from 'react';

import { Web, Home } from '@mui/icons-material';
import { Button, Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import Modal from './Modal';
import {
  createTableContent,
  deleteTableContent,
  getStateOptions,
  getTableContent,
  updateTableContent,
} from './operations';
import { buildColumns, getSortIcon } from './utils';
import { Breadcrumbs, SubHeader, Footer, TableFilter, TablePagination } from '../../components';

import './styles.scoped.css';

const TableContent = ({ type }) => {
  const navigation = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statesLoading, setStatesLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(null);
  const [selectedState, setSelectedState] = useState();
  const [stateOptions, setStateOptions] = useState([]);

  const refreshTableContent = () => {
    if ((hasState && selectedState) || !hasState) {
      setLoading(true);
      getTableContent({ type, selectedState })
        .then(response => setData(response))
        .finally(() => setLoading(false));
    }
  };

  const hasId = useMemo(() => type === 'Airlines' || type === 'Airports', [type]);

  const hasState = useMemo(() => type === 'Cities', [type]);

  useEffect(() => {
    if (hasState) {
      setStatesLoading(true);
      getStateOptions()
        .then(response =>
          setStateOptions(
            response
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map(({ name, id }) => ({
                label: name,
                value: id,
              })),
          ),
        )
        .finally(() => setStatesLoading(false));
    }
    refreshTableContent();
  }, []);

  useEffect(() => {
    if (selectedState) {
      refreshTableContent();
    }
  }, [selectedState]);

  const columns = useMemo(() => buildColumns({ hasState, hasId, setOpenEditModal }), [
    hasState,
    hasId,
    setOpenEditModal,
  ]);

  const tableInstance = useTable(
    {
      columns,
      data: data,
      initialState: {
        sortBy: [
          {
            id: hasId ? 'id' : 'name',
            desc: false,
          },
        ],
        pageSize: 25,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const onDelete = async tableId => {
    const result = await deleteTableContent(type, tableId);
    if (result?.errorMessage) {
      throw new Error(result.errorMessage);
    } else {
      return true;
    }
  };

  const onCreate = async newTableContent => {
    const result = await createTableContent(type, newTableContent);
    if (result?.errorMessage) {
      throw new Error(result.errorMessage);
    } else {
      return true;
    }
  };

  const onUpdate = async newTableContent => {
    const result = await updateTableContent(type, newTableContent);
    if (result?.errorMessage) {
      throw new Error(result.errorMessage);
    } else {
      return true;
    }
  };

  const onCancel = mustRefresh => {
    setOpenCreateModal(null);
    setOpenEditModal(null);
    if (mustRefresh) {
      refreshTableContent();
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setFilter,
    setGlobalFilter,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>
          {column.canSort && column.id !== 'editExpense' ? getSortIcon(column.isSorted, column.isSortedDesc) : null}
        </span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}>
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  const filter = (event, columnName) => {
    const filterColumn = columns.find(column => column.Header === columnName);
    if (filterColumn) {
      setFilter(filterColumn.accessor, event.target.value);
    } else {
      setGlobalFilter(event.target.value);
    }
  };

  return (
    <>
      <SubHeader
        btnSmall={<Web sx={{ color: '#fff', fontSize: 30 }} />}
        btnSmall2={<Home sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigation('/dashboard')}
        small2Action={() => navigation(-1)}
        text="Admin Portal"
      />
      <Breadcrumbs leftTitle="Table Contents" rightTitle={type} route={'/Table-contents'} />
      <div className="plusContainer">
        <Button className="plus" onClick={() => setOpenCreateModal({})} disabled={loading}>
          +
        </Button>
      </div>
      <div
        className="wrap"
        style={{
          marginTop: -60,
        }}>
        <Container fluid>
          {hasState && (
            <Row style={{ marginBottom: 24, marginTop: 12 }}>
              <Col style={{ flex: 0, minWidth: 160, marginTop: 6, fontWeight: 'bold' }}>Select a State:</Col>
              <Col style={{ flex: 0 }}>
                <Select
                  styles={{
                    control: provided => ({
                      ...provided,
                      width: '225px',
                      height: '30px',
                    }),
                    menuList: provided => ({
                      ...provided,
                      maxHeight: '200px',
                    }),
                  }}
                  options={stateOptions}
                  isLoading={statesLoading}
                  onChange={event => {
                    setSelectedState(event);
                  }}
                  value={selectedState}
                />
              </Col>
              <Col style={{ flex: 1 }} />
            </Row>
          )}
          {!loading && ((hasState && !!selectedState) || !hasState) && (
            <Row>
              <Col style={{ padding: 0 }}>
                <TableFilter columns={columns} onSearchChange={filter} />
              </Col>
              <Col className="d-flex justify-content-end" style={{ padding: 0 }}>
                <TablePagination tableInstance={tableInstance} />
              </Col>
            </Row>
          )}
          <Row>
            <Col className="border border-light rounded shadow-sm">
              {!loading &&
                ((hasState && !!selectedState) || !hasState ? (
                  <Table hover responsive borderless {...getTableProps()}>
                    <thead className="text-nowrap">{tableHeaders}</thead>
                    <tbody {...getTableBodyProps()}>{tableRows}</tbody>
                  </Table>
                ) : (
                  <Col style={{ fontSize: 16, color: 'red', padding: 24, textAlign: 'center' }}>
                    Please select a State.
                  </Col>
                ))}
              {loading && (
                <Spinner animation="border" size="lg" style={{ margin: 40, marginLeft: '45vw', color: '#da0f2e' }} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
      {!!openEditModal && (
        <Modal
          tableContent={openEditModal}
          type={type}
          onDelete={onDelete}
          onSave={onUpdate}
          onCancel={onCancel}
          stateOptions={stateOptions}
          isEdit
          hasId={hasId}
          hasState={hasState}
        />
      )}
      {!!openCreateModal && (
        <Modal
          tableContent={openCreateModal}
          type={type}
          onDelete={onDelete}
          onSave={onCreate}
          onCancel={onCancel}
          stateOptions={stateOptions}
          isEdit={false}
          hasId={hasId}
          hasState={hasState}
        />
      )}
      <Footer />
    </>
  );
};

export default TableContent;
