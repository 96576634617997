import React, { useState, useEffect } from 'react';
import { Row, Form, Spinner } from 'react-bootstrap';
import {
  getAssignments,
  getDrivers,
} from '../../features/ExpenseEntry/DriverSolutions/operations';
import Select from 'react-select';
import './styles.scoped.css';
import { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setDriversAssignmentsData,
  setReceiptDataLoading,
  setReceiptData,
  setReceiptsInitialData,
} from './expenseFormSlice';
//usar set receiptData

const DriverAssignmentsForm = ({
  setReceiptField,
  setReceiptFields,
  sharedReceiptErrors,
  receipt,
  id,
  billedOrTransmitted,
  customerId,
  setForm,
}) => {
  const [isLoadingDrivers, setIsLoadingDrivers] = useState(false);
  const [isLoadingAssignments, setIsLoadingAssignments] = useState(false);
  const {
    driversAssignmentsData,
    receiptDataLoading,
    receiptsData,
    receiptsInitialData,
    form,
  } = useSelector(state => state.expenseForm);
  const dispatch = useDispatch();
  const assignmentOptionsChanged = useRef(false);

  const getDriversToSelect = async query => {
    try {
      return await getDrivers(query);
    } catch (error) {
      console.log('error: ', error);
      setIsLoadingDrivers(false);
    }
  };

  const getAssignmentsToSelect = async query => {
    try {
      return await getAssignments(query);
    } catch (error) {
      console.log('error: ', error);
      setIsLoadingAssignments(false);
    }
  };

  const handleGetData = async () => {
    if (
      !driversAssignmentsData?.[id]?.hasSetInitialData &&
      !billedOrTransmitted &&
      !receiptDataLoading
      && form?.lob
    ) {
      dispatch(setReceiptDataLoading(true));
      setIsLoadingDrivers(true);
      setIsLoadingAssignments(true);

      let drivers = await getDriversToSelect(getDriversQuery());
      let assignments = await getAssignmentsToSelect(getAssignmentsQuery());

      setIsLoadingDrivers(false);
      setIsLoadingAssignments(false);

      let newData = {
        assignments: assignments,
        drivers: drivers,
        hasSetInitialData: true,
      };

      dispatch(setReceiptsInitialData(newData));

      dispatch(setDriversAssignmentsData({ id: id, data: { ...newData } }));
      dispatch(setReceiptDataLoading(false));
    }
  };

  //ASSIGNMENT CHANGE FLOW

  useEffect(() => {
    handleAssignmentFlow();
  }, [receipt?.hotelAssignmentId]);

  const getDriversQuery = () => {
    return receipt?.hotelAssignmentId
      ? `?assignmentId=${receipt?.hotelAssignmentId}`
      : undefined;
  };

  const handleAssignmentFlow = async () => {
    if (
      !receiptDataLoading &&
      receipt?.hotelAssignmentId !== receiptsData?.[id]?.hotelAssignmentId &&
      !billedOrTransmitted
    ) {
      //call get drivers when assignment
      dispatch(setReceiptDataLoading(true));
      setIsLoadingDrivers(true);
      dispatch(
        setReceiptData({
          id: id,
          data: {
            ...receiptsData[id],
            hotelAssignmentId: receipt.hotelAssignmentId,
          },
        }),
      );

      let drivers = await getDriversToSelect(getDriversQuery());

      let newData = {
        drivers: drivers,
      };
      dispatch(
        setDriversAssignmentsData({
          id: id,
          data: { ...driversAssignmentsData[id], ...newData },
        }),
      );
      setIsLoadingDrivers(false);
      dispatch(setReceiptDataLoading(false));
    }
  };

  //DRIVER CHANGE FLOW

  useEffect(() => {
    if (!billedOrTransmitted) {
      handleDriverFlow();
    }
  }, [receipt?.hotelDriverId]);

  const getAssignmentsQuery = () => {
    let query;
    if (receipt?.hotelDriverId && customerId) {
      query = `?driverId=${receipt?.hotelDriverId}&clientId=${customerId}`;
    } else if (receipt?.hotelDriverId) {
      query = `?driverId=${receipt?.hotelDriverId}`;
    } else if (customerId) {
      query = `?clientId=${customerId}`;
    }
    return query;
  };

  const handleDriverFlow = async () => {
    if (
      !receiptDataLoading &&
      receipt?.hotelDriverId !== receiptsData[id]?.hotelDriverId
    ) {
      //call get drivers when assignment
      setIsLoadingAssignments(true);
      dispatch(setReceiptDataLoading(true));
      dispatch(
        setReceiptData({
          id: id,
          data: {
            ...receiptsData[id],
            hotelDriverId: receipt.hotelDriverId,
          },
        }),
      );
      let assignments = await getAssignmentsToSelect(getAssignmentsQuery());

      let newData = {
        assignments: assignments,
      };
      dispatch(
        setDriversAssignmentsData({
          id: id,
          data: { ...driversAssignmentsData[id], ...newData },
        }),
      );
      assignmentOptionsChanged.current = true;
      setIsLoadingAssignments(false);
      dispatch(setReceiptDataLoading(false));
    }
  };

  //Get initial data

  const getReceiptData = async () => {
    if (!receiptsInitialData?.length) {
      await handleGetData();
    }
  };

  useEffect(() => {
    getReceiptData();
  }, []);

  const driverOptions = (
    (driversAssignmentsData?.[id]?.drivers
      ? driversAssignmentsData?.[id]?.drivers
      : receiptsInitialData?.drivers) || []
  ).map(({ APPL_FIRSTNAME, APPL_LASTNAME, APPL_ID }) => ({
    label: `${APPL_FIRSTNAME} ${APPL_LASTNAME} - ${APPL_ID}`,
    value: APPL_ID,
  }));

  const assignmentsOptions = (
    (driversAssignmentsData?.[id]
      ? driversAssignmentsData?.[id]?.assignments
      : receiptsInitialData?.assignments) || []
  ).map(({ ASS_ID }) => ({
    label: ASS_ID,
    value: ASS_ID,
  }));

  const customStyles = width => {
    return {
      control: (provided, state) => ({
        ...provided,
        width: width,
        height: '30px',
      }),
      menuList: (provided, state) => ({
        ...provided,
        maxHeight: '200px',
      }),
      menu: (provided, state) => ({
        ...provided,
        zIndex: 100000,
      }),
    };
  };

  return (
    <Row
      className="top-row"
      style={{
        margin: '5px 0 0 0',
      }}
    >
      <div
        className="top-col"
        style={{
          width: '40%',
        }}
      >
        <div className="below-container">
          <div
            className="below-container"
            style={{
              justifyContent: 'flex-start',
              width: '300px',
            }}
          >
            <Form.Group controlId="driverNameNumber">
              <Form.Label style={{ marginTop: '15px' }}>
                <div className="required"> Driver Name & Driver Number</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  isDisabled={receiptDataLoading}
                  defaultInputValue={receipt?.selectedDriver}
                  styles={customStyles('385px')}
                  options={driverOptions}
                  isClearable={true}
                  onChange={(event, action) => {
                    if (action.action === 'clear') {
                      setReceiptFields(id, [
                        {
                          fieldName: 'hotelDriverId',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'hotelDriverName',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'selectedDriver',
                          fieldValue: '',
                        },
                      ]);
                    }
                    if (action.action === 'select-option') {
                      setReceiptFields(id, [
                        {
                          fieldName: 'hotelDriverId',
                          fieldValue: event.value,
                        },
                        {
                          fieldName: 'hotelDriverName',
                          fieldValue: event.label.split(' -').shift(),
                        },
                        {
                          fieldName: 'selectedDriver',
                          fieldValue: event.label,
                        },
                      ]);
                    }
                  }}
                  value={driverOptions?.filter(
                    option => option.value === receipt?.hotelDriverId,
                  )}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingDrivers}
                />
              </div>
              {sharedReceiptErrors[id]?.hotelDriverId && (
                <div className="error">
                  {sharedReceiptErrors[id]?.hotelDriverId}
                </div>
              )}
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="top-col">
        <Form.Group controlId="assignmentId">
          <Form.Label style={{ marginTop: '15px' }}>
            <div className="required"> Assignment / Contract Activity</div>
          </Form.Label>
          <div className="dropdown-spinner-container">
            <Select
              isDisabled={receiptDataLoading}
              isClearable={true}
              onChange={(event, action) => {
                if (action.action === 'clear')
                  setReceiptField(id, 'hotelAssignmentId', '');
                if (action.action === 'select-option') {
                  setReceiptField(id, 'hotelAssignmentId', event.value);
                  console.log('event', event.assignment_LineOfBusiness)
                }
              }}
              value={assignmentsOptions?.filter(
                option => option.value === receipt?.hotelAssignmentId,
              )}
              styles={customStyles('225px')}
              defaultInputValue={receipt?.hotelAssignmentId}
              options={assignmentsOptions}
            />
            <Spinner
              as="span"
              animation="border"
              size="sm"
              aria-hidden="true"
              className="dropdown-spinner"
              hidden={!isLoadingAssignments}
            />
          </div>
          {sharedReceiptErrors[id]?.hotelAssignmentId && (
            <div className="error">
              {sharedReceiptErrors[id]?.hotelAssignmentId}
            </div>
          )}
        </Form.Group>
      </div>
    </Row>
  );
};

export default DriverAssignmentsForm;
