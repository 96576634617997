import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';

import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { reset, setForm } from './searchExpensesFormSlice';
import { getExpensesFiltered, getHotels, getStates } from './operations';
import SearchExpensesForm from './SearchExpensesForm';
import { getSearchExpensesCsvData } from './utils';
import Excel from '../../../../assets/excel.png';
import { Breadcrumbs, SubHeader, ExpensesWindow, ResultsTable } from '../../../../components';
import { newDateFormatted } from '../../../../utils';
import { customSortByCreatedAt } from '../expensesUtils';
import { getClientBranches, getClients, getDrivers } from '../operations';
import { getCitiesFiltered } from '../../DriverSolutions/Hotel/operations';
import { getFormData as getFormAirlineData } from '../../DriverSolutions/Airline/operations';
import { getCarRentals } from '../Car/operations'

import './styles.scoped.css';

const SearchExpenses = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { clientMenu } = useSelector(state => state.expenseEntry);
  const { attachedOptions, codeOptions, form, statuses } = useSelector(state => state.searchExpensesForm);

  const [airlines, setAirlines] = useState([]);
  const [branches, setBranches] = useState([]);
  const [carRentals, setcarRentals] = useState([]);
  const [clients, setClients] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [isReceiptAttached, setIsReceiptAttached] = useState('');
  const [isLoadingAirlines, setIsLoadingAirlines] = useState(false);
  const [isLoadingBranches, setIsLoadingBranches] = useState(false);
  const [isLoadingCarRentals, setIsLoadingCarRentals] = useState(false);
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [isLoadingDrivers, setIsLoadingDrivers] = useState(false);
  const [isLoadingHotels, setIsLoadingHotels] = useState(false);
  const [isLoadingStates, setIsLoadingStates] = useState(false);
  const [loadingResults, setLoadingResults] = useState(false);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedCities, setSelectedCities] = useState();
  const [errors, setErrors] = useState();
  const [assignmentLineOfBusinessItems, setAssignmentLineOfBusinessItems] = useState({});

  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);

  const getAssignmentLineOfBusinessItems = () => {
    clientMenu?.menuItems?.map(i => {
      if (location.pathname.includes(i.to)) {
        setAssignmentLineOfBusinessItems(i);
      }
    })
  };

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      document.querySelector('div.root').style.background = '#D0CED0';
      document.querySelector('span.wrap').style.background = '#ffff';
      document.querySelector('.right-title').style.color = '#66CCFF';
      document.querySelector('.left-title').style.color = '#ffff';
    }
    return () => {
      document.querySelector('div.root').style.background = '#ffff';
    }
  }, []);

  const getDriversToSelect = async () => {
    try {
      const newDrivers = await getDrivers(`drivers?lob=${assignmentLineOfBusinessItems.lob}`);
      setDrivers(newDrivers);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getClientsToSelect = async () => {
    try {
      const newClients = await getClients(`clients?lob=${assignmentLineOfBusinessItems.lob}`);
      setClients(newClients);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getHotelsToSelect = async () => {
    try {
      const newHotels = await getHotels();
      setHotels(newHotels);
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const getStatesToSelect = async () => {
    try {
      const newStates = await getStates();
      setStates(newStates);
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const getAirlineToSelect = async () => {
    try {
      const newAirlines = await getFormAirlineData();
      setAirlines(newAirlines?.airlines);
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const getCarRentalsToSelect = async () => {
    try {
      const carRentals = await getCarRentals();
      setcarRentals(carRentals?.items);
    } catch (error) {
      console.log('error: ', error)
    }
  }

  const getBranchToSelect = async () => {
    try {
      const newBranches = await getClientBranches();
      setBranches(newBranches);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleGetData = async () => {
    // Drivers
    setIsLoadingDrivers(true);
    getDriversToSelect()
      .catch(error => console.log('error', error))
      .finally(() => setIsLoadingDrivers(false));

    // Customers
    setIsLoadingCustomers(true);
    getClientsToSelect()
      .catch(error => console.log('error', error))
      .finally(() => setIsLoadingCustomers(false));

    // Branches
    setIsLoadingBranches(true);
    getBranchToSelect()
      .catch(error => console.log('error', error))
      .finally(() => setIsLoadingBranches(false));

    // Airlines
    setIsLoadingAirlines(true);
    getAirlineToSelect()
      .catch(error => console.log('error', error))
      .finally(() => setIsLoadingAirlines(false));

    // Car Rentals
    setIsLoadingCarRentals(true);
    getCarRentalsToSelect()
      .catch(error => console.log('error', error))
      .finally(() => setIsLoadingCarRentals(false));

    // Hotels 
    setIsLoadingHotels(true);
    getHotelsToSelect()
      .catch(error => console.log('error', error))
      .finally(() => {
        setIsLoadingHotels(false);
      });

    // States
    setIsLoadingStates(true);
    getStatesToSelect()
      .catch(error => console.log('error', error))
      .finally(() => {
        setIsLoadingStates(false);
      });
  };

  useEffect(() => {
    if (Object.keys(assignmentLineOfBusinessItems).length > 0) {
      handleGetData();
    }
  }, [assignmentLineOfBusinessItems]);

  useEffect(() => {
    getAssignmentLineOfBusinessItems();
  }, [])

  const setField = (fields, value) => {
    if (Array.isArray(fields)) {
      const itemsToAdd = {};
      const newErrors = { ...errors };
      fields.forEach(field => {
        itemsToAdd[(field?.fieldName)] = field?.fieldValue;
        if (errors && !!errors[field.fieldName]) delete newErrors[(field?.fieldName)];
      });
      dispatch(
        setForm({
          ...form,
          ...itemsToAdd
        })
      )
      setErrors({
        ...newErrors
      });
    } else {
      dispatch(
        setForm({
          ...form,
          [fields]: value,
        })
      )
      if (errors && !!errors[fields]) {
        setErrors({
          ...errors,
          [fields]: null,
        });
      };
    }
  };

  const fieldsAreValid = () => {
    let valid = true;
    const newErrors = {};

    const requiredExpenseFields = [
      'enteredStartDate',
      'enteredEndDate',
      'payWeekEndingStart',
      'payWeekEndingEnd',
      'receiptStartDate',
      'receiptEndDate',
      'lob',
    ];

    Object.keys(form).forEach((key, index) => {
      requiredExpenseFields.forEach((rf) => {
        if (rf === key) {
          const removedEnding = rf.replace("Ending", "__"); // Remove Ending word to prevent error removing End word
          let theOtherKey = removedEnding;
          if (removedEnding.includes("Start")) {
            // Replace Start with End and put Ending word back
            theOtherKey = removedEnding.replace("Start", "End").replace("__", "Ending");
          } else {
            // Replace End with Start and put Ending word back
            theOtherKey = removedEnding.replace("End", "Start").replace("__", "Ending");
          }
          if ((form[key] === '' || form[key] === undefined) && (form[theOtherKey] !== '' && form[theOtherKey] !== undefined)) {
            newErrors[key] = 'Please provide a valid value.';
            valid = false;
          }
        }
      });
    });

    setErrors(newErrors);

    return valid;
  };

  const formatExpenses = expenses => {
    if (expenses) {
      const formattedExpenses = [...expenses];
      formattedExpenses?.forEach(e => {
        if (e?.driverId && e?.driverName) {
          e.driverNameNumber = `${e?.driverName} - ${e?.driverId}`;
        }
        e.customerNameNumber = `${e?.customerName} - ${e?.customerId}`;
      });
      return formattedExpenses;
    }
    return [];

  }

  const formatFilters = () => {
    let fields = [];

    if (!showAdvancedSearch) {
      fields = [
        "status",
        "code",
        "customerId",
        "driverId",
        "lob",
      ];
    } else {
      fields = [
        "status",
        "code",
        "costCenter",
        "branch",
        "customerId",
        "driverId",
        "notes",
        "assignmentId",
        "endDate",
        "createdAt",
        "payWeekEnding",
        "documentName",
        "expenseItemHotelId",
        "expenseItemHotelStateId",
        "expenseItemHotelCityId",
        "expenseItemAirLineId",
        "expenseItemCarRentalCompanyId",
        "isReceiptAttached",
        "lob",
      ];
    }

    const filters = {};

    fields.forEach((f) => {
      if (form?.[f]) {
        filters[f] = form?.[f];
      }
    });

    if (assignmentLineOfBusinessItems?.lob) {
      filters.lob = `${assignmentLineOfBusinessItems.lob}`;
    }

    if (form?.receiptStartDate && form?.receiptEndDate) {
      const startDate =
        dayjs(form?.receiptStartDate)
          .startOf('day')
          .toISOString();

      const endDate = dayjs(form?.receiptEndDate)
        .startOf('day')
        .toISOString();
      filters.endDate = `[\"${dayjs.utc(startDate).format("YYYY-MM-DD") + "T00:00:00.000Z"}\", \"${dayjs.utc(endDate).format("YYYY-MM-DD") + "T00:00:00.000Z"}\"]`;
    }

    if (form?.enteredStartDate && form?.enteredEndDate) {
      // Set the start date to the beginning of the day
      const startDate = dayjs(form?.enteredStartDate)
        .startOf('day')
        .toISOString();
      // Set the end date to the end of the day
      const endDate = dayjs(form?.enteredEndDate)
        .endOf('day')
        .toISOString();
      filters.createdAt = `[\"${dayjs.utc(startDate).format()}\", \"${dayjs.utc(endDate).format()}\"]`;
    }

    if (form?.payWeekEndingStart && form?.payWeekEndingEnd) {
      const payWeekEndingStart = dayjs(form?.payWeekEndingStart)
        .startOf('day')
        .toISOString();
      const payWeekEndingEnd = dayjs(form?.payWeekEndingEnd)
        .endOf('day')
        .toISOString();
      filters.payWeekEnding = `[\"${dayjs(payWeekEndingStart).format().split('T')[0]}\", \"${dayjs(payWeekEndingEnd).format().split('T')[0]}\"]`;
    }

    if (form?.isReceiptAttached === 'attached') {
      filters.documentName = `{ \"attributeExists\": ${true} }`;
    }

    if (form?.isReceiptAttached === 'not-attached') {
      filters.documentName = `{ \"attributeExists\": ${false} }`;
    }


    return filters;
  };

  const getFilteredExpenses = async token => {

    const filters = formatFilters();

    const expenses = await getExpensesFiltered(
      filters,
      token,
    );

    const formattedExpenses = formatExpenses(expenses?.list);

    return {
      list: formattedExpenses,
      tokenResult: expenses?.lastEvaluatedKey
    }
  }

  let newExpenses = [];

  const getBatch = async (lastEvaluatedKey) => {
    if (prevToken !== lastEvaluatedKey) {
      prevToken = lastEvaluatedKey;
      setLoadingResults(true);
      const { list: expenses, tokenResult } = await getFilteredExpenses(lastEvaluatedKey);

      newExpenses = newExpenses.concat(expenses);
      if (tokenResult) {
        getBatch(tokenResult);
      } else {
        customSortByCreatedAt(newExpenses, true, 'createdAt');
        setExpenses([...newExpenses]);
        setLoadingResults(false);
      }
    }
  };

  let currentCities = [];
  let prevToken = '';

  const getCitiesBatch = async (nextToken = null) => {
    setIsLoadingCities(true);
    if (prevToken !== nextToken) {
      prevToken = nextToken;
      const aData = await getCitiesFiltered({
        state: form?.expenseItemHotelStateId,
        token: nextToken,
      });
      const token = aData?.nextToken;

      if (aData?.items) {
        const items = aData?.items;
        currentCities = currentCities.concat(items);
      }
      if (token) {
        getCitiesBatch(token);
      } else {
        setField([
          {
            fieldName: 'expenseItemHotelCityId',
            fieldValue: '',
          }
        ])
      };
      setSelectedCities({ selectedCities, cities: currentCities });
    }
    setIsLoadingCities(false);
  };

  const handleStateChange = async () => {
    setIsLoadingCities(true);
    await getCitiesBatch();
    setIsLoadingCities(false);
  };

  const handleSearch = async () => {
    if (fieldsAreValid()) {
      setLoadingResults(true);
      const { list: expenses } = await getFilteredExpenses(null);
      setExpenses(expenses);
      setShowResults(true);
    }
    setLoadingResults(false);
  };

  const fileName = `expense-tracker-search-expenses-${newDateFormatted.toString()}.csv`;

  useEffect(() => {
    if (form?.expenseItemHotelStateId) {
      handleStateChange();
    }
  }, [form?.expenseItemHotelStateId])

  useEffect(() => {
    dispatch(reset());
    setErrors([]);
  }, [location]);

  return (
    <>
      <SubHeader
        text="Expenses"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Expenses?"
        emailAddress={process.env.REACT_APP_EXPENSE_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle={assignmentLineOfBusinessItems.text}
          middleTitle={showResults && 'Search Expenses'}
          midItemAction={() => {
            setShowResults(false);
            dispatch(reset());
          }}
          rightTitle={showResults ? 'Search Results' : 'Search Expenses'}
          route={assignmentLineOfBusinessItems.to}
          color={assignmentLineOfBusinessItems.lob === 'DMS' && '#ffff'}
        />
        {showResults ?
          <CSVLink filename={fileName} data={getSearchExpensesCsvData(expenses)}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
          :
          <div style={{ marginRight: '35px' }}>
            <Button
              style={{ fontSize: '14px', marginTop: '20px', minWidth: "115px", padding: "16px", textAlign: "center", marginRight: "8px" }}
              onClick={() => handleSearch()}>
              SEARCH
            </Button>
            <Button className="btn button-secondary"
              style={{ fontSize: '14px', marginTop: '20px', minWidth: "115px", padding: "16px", textAlign: "center", marginRight: "8px" }}
              onClick={() => setShowAdvancedSearch(showAdvancedSearch => !showAdvancedSearch)}
            >{showAdvancedSearch ? 'SIMPLE' : 'ADVANCED'}
            </Button>
            <Button
              className='btn btn-secondary'
              style={{ fontSize: '14px', textAlign: "center", marginTop: '20px', maxWidth: "116px" }}
              onClick={() => {
                dispatch(
                  reset()
                );
                setErrors([]);
              }}>
              RESET FILTERS
            </Button>
          </div>}
      </div >

      {showResults ? (
        <>
          <ResultsTable data={!loadingResults ? expenses : []} getBatch={getBatch} loading={loadingResults} to={assignmentLineOfBusinessItems.to} />
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Button className="button-secondary" style={{ marginTop: '20px' }}>
              <span onClick={() => {
                setExpenses([]);
                setShowResults(false);
                dispatch(
                  reset()
                );
              }}>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <ExpensesWindow
          containerStyle={{
            border: '2px solid #343a40',
          }}
          windowContent={
            <SearchExpensesForm
              airlines={airlines}
              attachedOptions={attachedOptions}
              branches={branches}
              clients={clients}
              carRentals={carRentals}
              costOptions={codeOptions}
              drivers={drivers}
              errors={errors}
              form={form}
              setErrors={setErrors}
              getCitiesFiltered={getCitiesFiltered}
              handleSearch={handleSearch}
              handleStateChange={handleStateChange}
              hotels={hotels}
              isLoadingAirlines={isLoadingAirlines}
              isLoadingBranches={isLoadingBranches}
              isLoadingCarRentals={isLoadingCarRentals}
              isLoadingCities={isLoadingCities}
              setIsLoadingCities={setIsLoadingCities}
              isLoadingCustomers={isLoadingCustomers}
              isLoadingDrivers={isLoadingDrivers}
              isLoadingHotels={isLoadingHotels}
              isReceiptAttached={isReceiptAttached}
              isLoadingStates={isLoadingStates}
              setExpenses={setExpenses}
              setForm={setForm}
              setField={setField}
              setIsReceiptAttached={setIsReceiptAttached}
              setLoadingResults={setLoadingResults}
              selectedCities={selectedCities}
              setShowResults={setShowResults}
              showAdvancedSearch={showAdvancedSearch}
              states={states}
              statuses={statuses}
            />
          }
          windowTitle="Search"
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )
      }
    </>
  );
};

export default SearchExpenses;
