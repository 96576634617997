import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

import './styles.scoped.css';

function removeScreenReaderText() {
  document.querySelectorAll('.sr-only').forEach(elem => elem.remove());
}

removeScreenReaderText();

export default function TablePagination({ tableInstance }) {
  const {
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = tableInstance;

  const items = [];
  const lowerRange = Math.min(
    Math.max(0, pageIndex - 2),
    Math.max(0, pageCount - 5),
  );
  const upperRange = Math.min(lowerRange + 4, pageCount - 1);

  for (let number = lowerRange; number <= upperRange; number += 1) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === pageIndex}
        onClick={() => gotoPage(number)}
      >
        {number + 1}
      </Pagination.Item>,
    );
  }

  return (
    <Pagination>
      <Pagination.First
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      />
      <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />
      {items}
      <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
      <Pagination.Last
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      />
    </Pagination>
  );
}
