import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';

import { generateReport, getReportData, removeReportData, getList } from '../operations';
import ClientContactActivityForm from './ClientContactActivityForm';
import { getCsvData } from './utils.js';
import Excel from '../../../assets/excel.png';
import { formatIsoToDate, newDateFormatted } from '../../../utils';
import { Breadcrumbs, SubHeader, EdgeReportingWindow, ReportLoader } from '../../../components';
import ErrorModal from '../../../components/ErrorModal';

import './styles.scoped.css';
import ClientContactActivityTable from './ClientContactActivityTable';

const ClientContactActivity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [form, setForm] = useState({})
  const [activityTypes, setActivityTypes] = useState([]);
  const [ownerCodes, setOwnerCodes] = useState([]);
  const [loginOwnerCodes, setLoginOwnerCodes] = useState([]);
  const [lineOfBusinesses, setLineOfBusinesses] = useState([]);
  const [isLoadingOwnerCodes, setIsLoadingOwnerCodes] = useState(false);
  const [isLoadingLoginOwnerCodes, setIsLoadingLoginOwnerCodes] = useState(false);
  const [isLoadingActivityTypes, setIsLoadingActivityTypes] = useState(false);
  const [isLoadingLineOfBusinesses, setIsLoadingLineOfBusinesses] = useState(false);
  const [loadingResults, setLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [errors, setErrors] = useState();
  const [showError, setShowError] = useState(false);

  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);

  const getOwnerCodesToSelect = async () => {
    try {
      const newOwnerCodes = await getList(`/pccodes`);
      setOwnerCodes(newOwnerCodes);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getLoginOwnerCodesToSelect = async () => {
    try {
      const newLoginOwnerCodes = await getList(`/pccodes`);
      setLoginOwnerCodes(newLoginOwnerCodes);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getActivityTypeToSelect = async () => {
    try {
      const newActivityTypes = await getList(`/calltypes`);
      setActivityTypes(newActivityTypes);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getLineOfBusinessToSelect = async () => {
    try {
      const newLineofBusinesses = await getList(`/line-of-business`);
      setLineOfBusinesses(newLineofBusinesses);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleGetData = async () => {

    // Owner Codes
    setIsLoadingOwnerCodes(true);
    getOwnerCodesToSelect()
      .catch(error => console.log('error', error))
      .finally(() => setIsLoadingOwnerCodes(false));

    // Login Owner Codes
    setIsLoadingLoginOwnerCodes(true);
    getLoginOwnerCodesToSelect()
      .catch(error => console.log('error', error))
      .finally(() => setIsLoadingLoginOwnerCodes(false));

    // Activity Types
    setIsLoadingActivityTypes(true);
    getActivityTypeToSelect()
      .catch(error => console.log('error', error))
      .finally(() => setIsLoadingActivityTypes(false));

    // Line Of Business
    setIsLoadingLineOfBusinesses(true);
    getLineOfBusinessToSelect()
      .catch(error => console.log('error', error))
      .finally(() => setIsLoadingLineOfBusinesses(false));

  };

  useEffect(() => {
    handleGetData();
  }, []);

  const activityTypeOptions = useMemo(
    () =>
      activityTypes.map(({ REF_CODE, REF_DESCRIPTION }) => ({
        label: REF_DESCRIPTION,
        value: REF_CODE,
      })), [activityTypes]
  );

  const lobOptions = useMemo(
    () =>
      lineOfBusinesses.map(({ LineOfBusinessCode, LineOfBusinessDesc }) => ({
        label: LineOfBusinessDesc,
        value: LineOfBusinessCode,
      })),
    [lineOfBusinesses]
  );

  const ownerCodeOptions = useMemo(
    () =>
      ownerCodes.map(({ REF_CODE, REF_DESCRIPTION }) => ({
        label: REF_DESCRIPTION,
        value: REF_CODE,
      })),
    [ownerCodes]
  );

  const loginOwnerCodeOptions = useMemo(
    () =>
      ownerCodes.map(({ REF_CODE, REF_DESCRIPTION }) => ({
        label: REF_DESCRIPTION,
        value: REF_CODE,
      })),
    [ownerCodes]
  );

  const navigateCancelAction = () => {
    navigate(`/edge-reporting`);
  }

  const fileName = `edge-reporting-client-contact-activity-${newDateFormatted.toString()}.csv`;

  const handleViewResults = () => {
    if (
      !(
        dayjs(form?.ActivityTodate).isAfter(form?.ActivityFromdate) ||
        dayjs(form?.ActivityFromdate).isSame(form?.ActivityFromdate)
      )
    ) {
      alert('Invalid date range. Please double check and try again.');
      return;
    }
    setLoadingResults(true);
    Promise.all([
      generateReport({
        endpoint: 'client-contact-activity',
        payload: {
          ActivityFromdate: form?.ActivityFromdate ? formatIsoToDate(form?.ActivityFromdate, 'YYYY-MM-DD') : '',
          ActivityTodate: form?.ActivityTodate ? formatIsoToDate(form?.ActivityTodate, 'YYYY-MM-DD') : '',
          LastContactFromdate: form?.LastContactFromdate ? formatIsoToDate(form?.LastContactFromdate, 'YYYY-MM-DD') : '',
          LastContactTodate: form?.LastContactTodate ? formatIsoToDate(form?.LastContactTodate, 'YYYY-MM-DD') : '',
          FollowupFromdate: form?.FollowupFromdate ? formatIsoToDate(form?.FollowupFromdate, 'YYYY-MM-DD') : '',
          FollowupTodate: form?.FollowupTodate ? formatIsoToDate(form?.FollowupTodate, 'YYYY-MM-DD') : '',
          OwnerCodes: form?.OwnerCodes ?? '',
          LoginOwnerCode: form?.LoginOwnerCode ?? '',
          ActivityType: form?.ActivityType ?? '',
          FromClientName: form?.FromClientName ?? '',
          ToClientName: form?.ToClientName ?? '',
          FromClientID: form?.FromClientID ?? '',
          ToClientID: form?.ToClientID ?? '',
          FromClientBillToID: form?.FromClientBillToID ?? '',
          ToClientBillToID: form?.ToClientBillToID ?? '',
          LOB: form?.LOB ?? '',
        }
      })
    ])
      .then(values => {
        values.forEach((response, index) => {
          if (response.location) {
            getReportData(response.location).then(async reportData => {
              if (reportData?.length) {
                await removeReportData(response.location);
                if (index === 0) {
                  setData(reportData);
                } else {
                  setDataExport(reportData);
                }
                setShowResults(true);
              }
            });
          } else if (response.statusCode !== 404) {
            if (index === 0) {
              setData(response);
            } else {
              setDataExport(response);
            }
            setShowResults(true);
          } else {
            // eslint-disable-next-line no-undef
            alert('No data found matching your criteria.');
          }
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoadingResults(false);
      });
  }

  useEffect(() => {
    setErrors([]);
  }, [location]);

  return (
    <>
      <SubHeader
        text="Edge Reporting"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Critera?"
        emailAddress={process.env.REACT_APP_EXPENSE_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle={'Reporting Home'}
          middleTitle={showResults && 'Client Contact Activity'}
          midItemAction={() => {
            setShowResults(false);
          }}
          rightTitle={showResults ? 'Viewing Report' : 'Client Contact Activity'}
          route={'/edge-reporting'}
        />
        {!!data?.length && showResults && (
          <CSVLink filename={fileName} data={getCsvData(data)}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
        )}
      </div >
      {loadingResults && <ReportLoader didAbort={() => setLoadingResults(false)} />}
      {showError && <ErrorModal dismiss={() => setShowError(false)} error="No data found for report." />}
      {showResults ? (
        <>
          <ClientContactActivityTable data={!loadingResults ? data : []} loading={loadingResults} to={''} />
          <div className="buttons-row">
            <Button onClick={() => setShowResults(false)} style={{ marginRight: '20px', width: '100px' }}>
              <span>BACK</span>
            </Button>

            <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
              <span>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <EdgeReportingWindow
          containerStyle={{
            border: '2px solid #343a40',
          }}
          windowContent={
            <ClientContactActivityForm
              activityTypeOptions={activityTypeOptions}
              lobOptions={lobOptions}
              ownerCodeOptions={ownerCodeOptions}
              loginOwnerCodeOptions={loginOwnerCodeOptions}
              isLoadingOwnerCodes={isLoadingOwnerCodes}
              isLoadingLoginOwnerCodes={isLoadingLoginOwnerCodes}
              isLoadingActivityTypes={isLoadingActivityTypes}
              isLoadingLineOfBusinesses={isLoadingLineOfBusinesses}
              errors={errors}
              setErrors={setErrors}
              navigateCancelAction={navigateCancelAction}
              setForm={setForm}
              setLoadingResults={setLoadingResults}
              setShowResults={setShowResults}
              form={form}
              handleViewResults={handleViewResults}
            />
          }
          windowTitle="Criteria"
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )
      }
    </>
  );
};

export default ClientContactActivity;
