import React, { useMemo, useCallback, useEffect, useState } from 'react';

import { Checkbox } from '@mui/material';
import _debounce from 'lodash/debounce';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { MdRemoveRedEye } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { setRTBCurrentIds } from '../../features/ExpenseEntry/expensesEntrySlice';
import { formatIsoToDate } from '../../utils';
import { getExpenseType } from '../../utils/utilities';
import TableFilterRTB from '../TableFilterRTB';
import TablePagination from '../TablePagination';

import './styles.scoped.css';

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const EditExpenseButton = (assignmentLineOfBusinessItems, expense) => {
  const thisExpense = expense.data[parseInt(expense.cell.row.id)];

  const expenseType = getExpenseType(thisExpense);
  return (
    <Link to={`${assignmentLineOfBusinessItems?.to}/edit-${expenseType}/${thisExpense.id}`}>
      <MdRemoveRedEye size={24} />
    </Link>
  );
};

const ExpenseSelector = ({ expense, handleSelectExpense, rowChecked }) => (
  <Checkbox
    style={{
      color: '#da0f2e',
      height: 0,
      width: 0,
    }}
    onChange={() => {
      handleSelectExpense(expense);
    }}
    checked={!!rowChecked}
  />
);

const SelectAllExpenses = (handleSelectAll, isChecked) => (
  <Checkbox
    style={{
      color: '#da0f2e',
      height: 0,
      width: 0,
    }}
    checked={isChecked}
    onChange={handleSelectAll}
    defaultValue={false}
  />
);

const ReadyToBillTable = ({
  data,
  isOpenAssignments,
  loading,
  handleSelectExpense,
  handleSelectAll,
  selectedExpenses,
  assignmentLineOfBusinessItems
}) => {
  const dispatch = useDispatch();
  const columnClassName = 'align-middle text-nowrap';
  const memoData = useMemo(() => data, [data]);
  const [isChecked, setIsChecked] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);

  const checkIfAllSelected = () => {
    const isAllSelected = selectedExpenses?.length === data.length;
    setIsChecked(isAllSelected);
  };

  useEffect(() => {
    checkIfAllSelected();
  }, [selectedExpenses]);

  const OpenAssignmentsColumns = useMemo(
    () => [
      {
        Header: !hasFilters ? SelectAllExpenses(handleSelectAll, isChecked) : <div />,
        accessor: 'selectExpense',
        className: columnClassName,
        disableSortBy: true,
        Cell: instance =>
          ExpenseSelector({
            expense: data.find(e => e.id == instance.row.original.id),
            handleSelectExpense: handleSelectExpense,
            rowChecked: selectedExpenses.find(se => se.id == instance.row.original.id),
          }),
      },
      {
        Header: 'Date Entered',
        accessor: 'createdAt',
        className: columnClassName,
        Cell: instance => formatIsoToDate(instance.value),
      },
      {
        Header: 'Pay Code',
        accessor: 'code',
        className: columnClassName,
      },
      {
        Header: 'Driver Name & Number',
        accessor: 'driverNameNumber',
        className: columnClassName,
      },
      {
        Header: 'Assignment / Contract Activity',
        accessor: 'assignmentId',
        className: columnClassName,
      },
      {
        Header: 'Customer Name & Number',
        accessor: 'customerNameNumber',
        className: columnClassName,
      },
      {
        Header: 'Client Branch',
        accessor: 'branch',
        className: columnClassName,
      },
      {
        Header: 'Cost Center',
        accessor: 'costCenter',
        className: columnClassName,
      },
      {
        Header: 'Expense Date',
        accessor: 'startDate',
        className: columnClassName,
        Cell: instance => formatIsoToDate(instance.value),
      },
      {
        Header: 'Receipt Amount',
        accessor: 'totalReceiptAmount',
        className: columnClassName,
      },
      {
        Header: 'Total To Be Billed',
        accessor: 'totalToBeBilled',
        className: columnClassName,
      },
      {
        Header: '',
        accessor: 'editExpense',
        className: columnClassName,
        Cell: instance => EditExpenseButton(assignmentLineOfBusinessItems, instance),
      },
    ],
    [isChecked, selectedExpenses, hasFilters],
  );

  const ClosedAssignmentsColumns = useMemo(
    () => [
      {
        Header: 'Date Entered',
        accessor: 'createdAt',
        className: columnClassName,
        Cell: instance => formatIsoToDate(instance.value),
      },
      {
        Header: 'Pay Code',
        accessor: 'code',
        className: columnClassName,
      },
      {
        Header: 'Driver Name & Number',
        accessor: 'driverNameNumber',
        className: columnClassName,
      },
      {
        Header: 'Assignment / Contract Activity',
        accessor: 'assignmentId',
        className: columnClassName,
      },
      {
        Header: 'Customer Name & Number',
        accessor: 'customerNameNumber',
        className: columnClassName,
      },
      {
        Header: 'Client Branch',
        accessor: 'branch',
        className: columnClassName,
      },
      {
        Header: 'Cost Center',
        accessor: 'costCenter',
        className: columnClassName,
      },
      {
        Header: 'Expense Date',
        accessor: 'startDate',
        className: columnClassName,
        Cell: instance => formatIsoToDate(instance.value),
      },
      {
        Header: 'Receipt Amount',
        accessor: 'totalReceiptAmount',
        className: columnClassName,
      },
      {
        Header: 'Total To Be Billed',
        accessor: 'totalToBeBilled',
        className: columnClassName,
      },
      {
        Header: '',
        accessor: 'editExpense',
        className: columnClassName,
        Cell: instance => EditExpenseButton(assignmentLineOfBusinessItems, instance),
      },
    ],
    [isChecked],
  );

  const tableInstance = useTable(
    {
      columns: isOpenAssignments ? OpenAssignmentsColumns : ClosedAssignmentsColumns,
      data: memoData,
      initialState: {
        sortBy: [
          {
            id: 'CreatedAt',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const filterTableInstanceData = () => {
    const newData = tableInstance.rows.reduce((result, current) => {
      if (current?.original?.id) {
        result.push(current?.original?.id);
      }
      return result;
    }, []);
    dispatch(setRTBCurrentIds(newData));
  };

  const debouncedFilter = useCallback(_debounce(filterTableInstanceData, 1000));

  useEffect(() => {
    if (tableInstance?.rows.length) {
      debouncedFilter();
    }
  }, [tableInstance?.flatRows]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setFilter,
    setGlobalFilter,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>{column.canSort ? getSortedIcon(column.isSorted, column.isSortedDesc) : null}</span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}>
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  const filterOptions = [
    {
      Header: 'Driver Name',
      accessor: 'driverNameNumber',
    },
    {
      Header: 'Driver Number',
      accessor: 'driverNameNumber',
    },

    {
      Header: 'Customer Name',
      accessor: 'customerNameNumber',
    },

    {
      Header: 'Customer Number',
      accessor: 'customerNameNumber',
    },
    {
      Header: 'Expense Date',
      accessor: 'startDate',
      Cell: instance => formatIsoToDate(instance.value),
    },
  ];

  const filter = (value, columnName) => {
    setHasFilters(value?.length > 0);

    const filterColumn = filterOptions.find(column => column.accessor === columnName);
    if (filterColumn) {
      setFilter(filterColumn.accessor, value);
    } else if (columnName === 'Expense Date') {
      setFilter('startDate', value);
    } else {
      setGlobalFilter(value);
    }
  };
  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col md={6} className="mr-auto">
          <TableFilterRTB className="mb-3" columns={filterOptions} onSearchChange={filter} />
        </Col>
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row style={{ backgroundColor: 'white' }}>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {loading && <Spinner animation="border" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ReadyToBillTable;
