import React, { useMemo } from 'react';

import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import TablePagination from '../TablePagination';

import './styles.scoped.css';

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const ReportingTable = ({ data, loading }) => {
  const columnClassName = 'align-middle text-nowrap';
  const memoData = useMemo(() => data, [data]);

  const columns = useMemo(
    () => [
      {
        Header: 'Line of Business',
        accessor: 'lineOfBusiness',
        className: columnClassName,
      },
      {
        Header: 'Report Name',
        accessor: 'reportName',
        className: columnClassName,
      },
      {
        Header: 'Owner',
        accessor: 'owner',
        className: columnClassName,
      },
      {
        Header: 'Scheduled',
        accessor: 'scheduled',
        className: columnClassName,
      },
      {
        Header: '',
        accessor: 'actions',
        className: columnClassName,
      },
    ],
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data: memoData,
      initialState: {
        sortBy: [
          {
            id: 'CreatedAt',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>{column.canSort ? getSortedIcon(column.isSorted, column.isSortedDesc) : null}</span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}>
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row style={{ border: '1px solid #343a40', borderRadius: '10px' }}>
        <Col>
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {loading && <Spinner animation="border" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ReportingTable;
