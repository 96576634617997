import React, { useEffect, useState } from 'react';

import { Storage } from 'aws-amplify';
import { Col, Row, Spinner } from 'react-bootstrap';
import { FaFilePdf } from 'react-icons/fa';

import useIsMobileScreen from '../../../hooks/useCheckIsMobile';

const NewDocument = ({ id, dq, loadingAutoselect }) => {
  const isMobile = useIsMobileScreen();
  const [pdfError, setPdfError] = useState(false);
  const [dqPdfDocument, setDqPdfDocument] = useState('');

  const getPDF = async pdf => {
    const config = {
      expires: process.env.REACT_APP_AMPLIFY_STORAGE_GET_EXPIRES,
      level: 'public',
      download: false,
    };
    await Storage.get(`dqf-assembling/${pdf}`, config)
      .then(response => {
        fetch(response).then(result => {
          if (result.status === 200) {
            setDqPdfDocument(result.url);
            setPdfError(false);
          } else {
            setPdfError(true);
          }
        });
      })
      .catch(err => console.log('This is the error', err));
  };

  useEffect(() => {
    if (dq) {
      getPDF(`${id}.pdf`);
    }
  }, [id, dq]);

  return (
    <>
      <Row className="pt-1 mb-3 mt-3">
        <Col className="font-weight-bold">New Driver Qualification Document</Col>
      </Row>

      {!loadingAutoselect && pdfError && (
        <div className="p-2" style={{ color: '#c8102e', fontWeight: 500 }}>
          There is no PDF to show.
        </div>
      )}
      {!loadingAutoselect &&
        !pdfError &&
        dqPdfDocument &&
        (isMobile ? (
          <>
            <span>
              <a href={dqPdfDocument} data-ajax="false" target="_blank" rel="noreferrer">
                <FaFilePdf size={60} /> View Document
              </a>
            </span>
          </>
        ) : (
          <iframe src={dqPdfDocument} height="600" width="100%" title="dqPdfDcument" />
        ))}

      {loadingAutoselect && <Spinner animation="border" />}
    </>
  );
};

export default NewDocument;
