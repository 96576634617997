import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import PropTypes from 'prop-types';

export default function CommonTablePagination({ className, tableInstance }) {
    const {
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: {
            pageIndex,
        },
    } = tableInstance;

    const items = [];
    const lowerRange = Math.min(Math.max(0, pageIndex - 2), Math.max(0, pageCount - 5));
    const upperRange = Math.min(lowerRange + 4, pageCount - 1);

    for (let number = lowerRange; number <= upperRange; number += 1) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === pageIndex}
                onClick={() => gotoPage(number)}
                className="page-quote"
            >
                {number + 1}
            </Pagination.Item>,
        );
    }

    return (
        <Pagination className={className}>
            <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
            <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />
            {items}
            <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
            <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
        </Pagination>
    );
}

CommonTablePagination.propTypes = {
    className: PropTypes.string,
    tableInstance: PropTypes.shape({
        canPreviousPage: PropTypes.bool,
        canNextPage: PropTypes.bool,
        pageCount: PropTypes.number.isRequired,
        gotoPage: PropTypes.func,
        nextPage: PropTypes.func,
        previousPage: PropTypes.func,
        state: PropTypes.shape({
            pageIndex: PropTypes.number.isRequired,
        }),
    }),
};

CommonTablePagination.defaultProps = {
    className: null,
    tableInstance: {},
};
