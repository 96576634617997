import React, { useState, useEffect, useRef, useMemo } from 'react';

import { Checkbox, Tooltip } from '@mui/material';
import { Row, Form, Spinner } from 'react-bootstrap';
import { MdInfo } from 'react-icons/md';
import './styles.scoped.css';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Select from 'react-select';

import { setData, setIsLoading, setFormData } from './expenseFormSlice';
import {
  getAssignments,
  getClientBranches,
  getClients,
  getDrivers,
} from '../../features/ExpenseEntry/DriverSolutions/operations';
import { DisableClickOverlay, isBilledOrTransmitted } from '../../utils/utilities';

const getDriversToSelect = async query => {
  try {
    return await getDrivers(query);
  } catch (error) {
    console.log('error: ', error);
    return undefined;
  }
};

const getAssignmentsToSelect = async query => {
  try {
    return await getAssignments(query);
  } catch (error) {
    console.log('error: ', error);
    return undefined;
  }
};

const getClientsToSelect = async query => {
  try {
    return await getClients(query);
  } catch (error) {
    console.log('error: ', error);
    return undefined;
  }
};

const getBranchesToSelect = async query => {
  try {
    return await getClientBranches(query);
  } catch (error) {
    console.log('error: ', error);
    return undefined;
  }
};

const ExpenseForm = ({ setField, errors, setForm, form, rebilled, formHasChanged }) => {
  const { id } = useParams();

  const location = useLocation();
  const [isLoadingDrivers, setIsLoadingDrivers] = useState(false);
  const [isLoadingAssignments, setIsLoadingAssignments] = useState(false);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [isLoadingBranches, setIsLoadingBranches] = useState(false);
  const { data, isLoading, formData } = useSelector(state => state.expenseForm);
  const dispatch = useDispatch();
  const clientOptionsChanged = useRef(false);
  const assignmentOptionsChanged = useRef(false);

  const headerIsDisabled = useMemo(
    () => isBilledOrTransmitted(form) || id?.includes('CR') || form?.status === 'Voided' || form?.isVoidedRebilled === true,
    [isBilledOrTransmitted, form?.status, id, form?.isVoidedRebilled],
  );

  const driverOptions = useMemo(() => {
    if (headerIsDisabled) {
      return [{ label: `${form?.driverName} - ${form?.driverId}`, value: form?.driverId }];
    }
    return data?.drivers?.map(({ APPL_FIRSTNAME, APPL_LASTNAME, APPL_ID }) => ({
      label: `${APPL_FIRSTNAME} ${APPL_LASTNAME} - ${APPL_ID}`,
      value: APPL_ID,
    }));
  }, [data?.drivers, headerIsDisabled, form?.driverName, form?.driverId]);

  const assignmentsOptions = useMemo(() => {
    if (headerIsDisabled) {
      return [{ label: `${form?.assignmentId}`, value: form?.assignmentId }];
    }
    return data?.assignments?.map(({ ASS_ID, Assignment_LineOfBusiness }) => ({
      label: `${ASS_ID}`,
      value: ASS_ID,
      Assignment_LineOfBusiness: Assignment_LineOfBusiness
    }));
  }, [data?.assignments, headerIsDisabled, form?.assignmentId, form?.lob]);

  const clientOptions = useMemo(() => {
    if (headerIsDisabled) {
      return [{ label: `${form?.customerName} - ${form?.customerId}`, value: form?.customerId }];
    }
    return data?.clients?.map(({ CUST_ID, CUST_NAME }) => ({
      label: `${CUST_NAME} - ${CUST_ID}`,
      value: CUST_ID,
    }));
  }, [data?.clients, headerIsDisabled, form?.customerName, form?.customerId]);

  const branchOptions = useMemo(() => {
    if (headerIsDisabled) {
      return [{ label: `${form?.branchName} - ${form?.branch}`, value: form?.branch }];
    }
    return data?.branches?.map(({ Branch_Code, Branch_Name }) => ({
      label: `${Branch_Name} - ${Branch_Code}`,
      value: Branch_Code,
    }));
  }, [data?.branches, headerIsDisabled, form?.branchName, form?.branch]);

  const handleGetData = () => {
    if (!data?.drivers.length && !headerIsDisabled && form?.lob) {
      dispatch(setIsLoading(true));
      setIsLoadingDrivers(true);
      setIsLoadingAssignments(true);
      setIsLoadingCustomers(true);
      setIsLoadingBranches(true);
      Promise.all([
        getDriversToSelect(getDriversQuery()),
        getAssignmentsToSelect(getAssignmentsQuery()),
        getClientsToSelect(getClientsQuery()),
        getBranchesToSelect(getBranchesQuery()),
      ]).then(([drivers, assignments, clients, branches]) => {
        setIsLoadingDrivers(false);
        setIsLoadingAssignments(false);
        setIsLoadingCustomers(false);
        setIsLoadingBranches(false);
        dispatch(
          setData({
            drivers,
            assignments,
            clients,
            branches,
          }),
        );
        dispatch(setIsLoading(false));
      });
    }
  };

  const handleAssignmentFlow = async () => {
    if (!isLoading && form?.assignmentId !== formData?.assignmentId && !headerIsDisabled) {
      formHasChanged.current.assignments = false;
      setIsLoadingDrivers(true);
      setIsLoadingCustomers(true);
      dispatch(setIsLoading(true));
      dispatch(
        setFormData({
          ...formData,
          assignmentId: form.assignmentId,
        }),
      );
      Promise.all([getDrivers(getDriversQuery()), getClients(getClientsQuery())]).then(([drivers, clients]) => {
        const newData = {
          drivers: drivers,
          clients: clients,
        };
        dispatch(setData({ ...data, ...newData }));
        clientOptionsChanged.current = true;
        setIsLoadingDrivers(false);
        setIsLoadingCustomers(false);
        dispatch(setIsLoading(false));
      });
    }
  };

  useEffect(() => {
    if (formHasChanged.current.assignments) {
      handleAssignmentFlow();
    }
  }, [form?.assignmentId]);

  const getDriversQuery = () => {
    let query;

    if (form?.lob) {
      query = `drivers?lob=${form?.lob}`;
    }
    if (form?.assignmentId && form?.lob) {
      query = `drivers?assignmentId=${form?.assignmentId}&lob=${form?.lob}`
    }

    return query;
  };

  const getClientsQuery = () => {
    let query;

    if (form?.lob) {
      query = `clients?lob=${form?.lob}`;
    }
    if (form?.assignmentId && form?.lob) {
      query = `clients?assignmentId=${form?.assignmentId}&lob=${form?.lob}`;
    }
    if (form?.assignmentId && form?.lob) {
      query = `clients?assignmentId=${form?.assignmentId}&lob=${form?.lob}`;
    }

    return query;
  };

  const getAssignmentsQuery = () => {
    let query;

    if (form?.lob) {
      query = `assignments?lob=${form?.lob}`;
    }
    if (form?.driverId !== 1 && form?.driverId !== undefined && form?.lob) {
      query = `assignments?driverId=${form?.driverId}&lob=${form?.lob}`;
    }
    if (!form?.driverId && form?.customerId && form?.lob) {
      query = `assignments?clientId=${form?.customerId}&lob=${form?.lob}`;
    }
    if (form?.driverId !== 1 && form?.driverId !== undefined && form?.customerId !== undefined && form?.lob) {
      query = `assignments?driverId=${form?.driverId}&clientId=${form?.customerId}&lob=${form?.lob}`;
    }

    return query;
  };

  const getBranchesQuery = () => (form?.customerId ? `?clientId=${form?.customerId}&lob=${form?.lob}` : undefined);

  const handleDriverFlow = async () => {
    if (!isLoading && form?.driverId !== formData?.driverId) {
      formHasChanged.current.drivers = false;

      setIsLoadingAssignments(true);
      dispatch(setIsLoading(true));
      dispatch(
        setFormData({
          ...formData,
          driverId: form.driverId,
        }),
      );

      const newData = {
        assignments: await getAssignmentsToSelect(getAssignmentsQuery()),
      };
      dispatch(setData({ ...data, ...newData }));
      assignmentOptionsChanged.current = true;
      setIsLoadingAssignments(false);
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    if (!headerIsDisabled && formHasChanged.current.drivers) {
      handleDriverFlow();
    }
  }, [form?.driverId]);

  useEffect(() => {
    if (!headerIsDisabled && formHasChanged.current.clients) {
      handleClientFlow();
    }
  }, [form?.customerId]);

  const handleClientFlow = async () => {
    if (!isLoading && form?.customerId !== formData?.customerId) {
      formHasChanged.current.clients = false;
      setIsLoadingAssignments(true);
      setIsLoadingBranches(true);

      dispatch(setIsLoading(true));
      dispatch(
        setFormData({
          ...formData,
          customerId: form.customerId,
        }),
      );
      Promise.all([getAssignmentsToSelect(getAssignmentsQuery()), getBranchesToSelect(getBranchesQuery())]).then(
        ([assignments, branches]) => {
          const newData = {
            assignments,
            branches,
          };
          dispatch(setData({ ...data, ...newData }));
          assignmentOptionsChanged.current = true;
          setIsLoadingAssignments(false);
          setIsLoadingBranches(false);
          dispatch(setIsLoading(false));
        },
      );
    }
  };

  useEffect(() => {
    if (form?.lob) {
      handleGetData();
    }
  }, [form?.lob]);

  const customStyles = width => ({
    control: provided => ({
      ...provided,
      width: width,
      height: '30px',
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: '200px',
    }),
    menu: provided => ({
      ...provided,
      zIndex: 100000,
    }),
  });

  const getTopComponent = () => {
    if (isBilledOrTransmitted(form)) {
      return (
        <Form.Group
          controlId="void"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <Form.Label
            style={{
              marginTop: '15px',
            }}>
            Void
          </Form.Label>

          <Checkbox
            style={{
              color: 'red',
              height: 0,
              width: 0,
              marginTop: '5px',
              pointerEvents: 'all',
            }}
            onChange={event => {
              setField([
                {
                  fieldName: 'isVoidedRebilled',
                  fieldValue: event.target.checked ? false : form?.isVoidedRebilled,
                },
                {
                  fieldName: 'isVoided',
                  fieldValue: event.target.checked,
                },
              ]);
            }}
            checked={!!form?.isVoided}
            defaultValue={false}
          />
        </Form.Group>
      );
    }
    if (form?.correctionExpenseId && form?.status === 'Voided') {
      return (
        <Form.Group controlId="correctionExpenseId">
          <Form.Label style={{ marginTop: '15px' }}>Correction Expense ID</Form.Label>
          <Form.Control
            style={{
              height: '30px',
              padding: 0,
              border: '0px',
              backgroundColor: 'white',
              opacity: '0.7',
            }}
            as="input"
            name="status"
            onChange={() => { }}
            value={form?.correctionExpenseId}
            readOnly
            disabled
          />
        </Form.Group>
      );
    }
    if (!isBilledOrTransmitted(form) && !id?.includes('CR')) {
      return (
        <Form.Group
          controlId="nonBillable"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <Form.Label
            style={{
              marginTop: '15px',
            }}>
            Non-billable
          </Form.Label>

          <Checkbox
            style={{
              color: form?.flatFeeBilling ? 'gray' : 'red',
              height: 0,
              width: 0,
              marginTop: '5px',
            }}
            onChange={event => {
              setField('nonBillable', event.target.checked);
            }}
            disabled={!!form?.flatFeeBilling}
            checked={form?.nonBillable}
            defaultValue={false}
          />
        </Form.Group>
      );
    }
    return undefined;
  };

  const getBottomComponent = () => {
    if (form?.originalExpenseId) {
      return (
        <Form.Group controlId="originalExpenseId">
          <Form.Label style={{ marginTop: '15px' }}>Original Expense ID</Form.Label>
          <Form.Control
            style={{
              height: '30px',
              padding: 0,
              border: '0px',
              backgroundColor: 'white',
              opacity: '0.7',
            }}
            as="input"
            name="status"
            onChange={() => { }}
            value={form?.originalExpenseId}
            readOnly
            disabled
          />
        </Form.Group>
      );
    }

    if (form?.rebilledExpenseId) {
      return (
        <Form.Group controlId="rebilledExpenseId">
          <Form.Label style={{ marginTop: '15px' }}>Rebilled Expense ID</Form.Label>
          <Form.Control
            style={{
              height: '30px',
              padding: 0,
              border: '0px',
              backgroundColor: 'white',
              opacity: '0.7',
            }}
            as="input"
            name="status"
            onChange={() => { }}
            value={form?.rebilledExpenseId}
            readOnly
            disabled
          />
        </Form.Group>
      );
    }

    if (isBilledOrTransmitted(form)) {
      return (
        <Form.Group
          controlId="voidAndRebill"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <Form.Label
            style={{
              marginTop: '15px',
            }}>
            Void and Rebill
          </Form.Label>

          <Checkbox
            style={{
              color: 'red',
              height: 0,
              width: 0,
              marginTop: '5px',
              pointerEvents: 'all',
            }}
            onChange={event => {
              setField([
                {
                  fieldName: 'isVoidedRebilled',
                  fieldValue: event.target.checked,
                },
                {
                  fieldName: 'isVoided',
                  fieldValue: event.target.checked ? false : form?.isVoided,
                },
              ]);
            }}
            checked={!!form?.isVoidedRebilled}
          />
        </Form.Group>
      );
    }

    if (form?.correctionExpenseId && !form?.rebilledExpenseId) {
      return (
        <Form.Group
          controlId="voidAndRebill"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <Form.Label
            style={{
              marginTop: '15px',
            }}>
            Rebill
          </Form.Label>

          <Checkbox
            style={{
              color: 'red',
              height: 0,
              width: 0,
              marginTop: '5px',
              pointerEvents: 'all',
            }}
            onChange={() => {
              rebilled.current = !rebilled.current;
            }}
          />
        </Form.Group>
      );
    }

    return undefined;
  };

  return (
    <div>
      <Row className="top-row" style={{ margin: '5px 0 0 0' }}>
        <div className="top-col" style={{ width: '40%' }}>
          <DisableClickOverlay isDisabled={headerIsDisabled}>
            <Form.Group controlId="driverNameNumber">
              <Form.Label style={{ marginTop: '15px' }}>
                <div className="required"> Driver Name & Driver Number</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  isDisabled={isLoading && !isBilledOrTransmitted(form)}
                  styles={customStyles('385px')}
                  options={driverOptions}
                  isClearable
                  onChange={(event, action) => {
                    if (action.action === 'clear') {
                      formHasChanged.current.drivers = true;
                      setField([
                        {
                          fieldName: 'driverId',
                          fieldValue: 1,
                        },
                        {
                          fieldName: 'driverName',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'selectedDriver',
                          fieldValue: '',
                        },
                      ]);
                    }
                    if (action.action === 'select-option') {
                      formHasChanged.current.drivers = true;
                      setField([
                        {
                          fieldName: 'driverId',
                          fieldValue: event.value,
                        },
                        {
                          fieldName: 'driverName',
                          fieldValue: event.label.split(' -').shift(),
                        },
                        {
                          fieldName: 'selectedDriver',
                          fieldValue: event.label,
                        },
                      ]);
                    }
                  }}
                  value={driverOptions?.find(option => option.value === form?.driverId)}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingDrivers}
                />
              </div>
              {errors?.driverName && <div className="error">{errors?.driverName}</div>}
            </Form.Group>
          </DisableClickOverlay>
        </div>

        <div className="top-col">
          <DisableClickOverlay isDisabled={headerIsDisabled}>
            <Form.Group controlId="assignmentId">
              <Form.Label style={{ marginTop: '15px' }}>
                <div className="required"> Assignment / Contract Activity</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  isDisabled={isLoading && !isBilledOrTransmitted(form)}
                  isClearable
                  onChange={(event, action) => {
                    if (action.action === 'clear') {
                      formHasChanged.current.assignments = true;
                      setField('assignmentId', '');
                    }
                    if (action.action === 'select-option') {
                      setForm({
                        ...form,
                        lob: event?.Assignment_LineOfBusiness
                      });
                      formHasChanged.current.assignments = true;
                      setField('assignmentId', event.value);
                    }
                  }}
                  value={assignmentsOptions?.find(option => option.value === form?.assignmentId)}
                  styles={customStyles('225px')}
                  options={assignmentsOptions}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingAssignments}
                />
              </div>
              {errors?.assignmentId && <div className="error">{errors?.assignmentId}</div>}
            </Form.Group>
          </DisableClickOverlay>
        </div>
        <div className="top-col">
          <Form.Group controlId="status">
            <Form.Label style={{ marginTop: '15px' }}>Status</Form.Label>
            <Form.Control
              style={{
                width: '225px',
                height: '30px',
                padding: 0,
                border: '0px',
                backgroundColor: 'white',
                opacity: '0.7',
              }}
              as="input"
              name="status"
              onChange={() => { }}
              value={form?.status}
              readOnly
              disabled
            />
          </Form.Group>
          {getTopComponent()}
        </div>
      </Row>
      <Row className="top-row" style={{ margin: '5px 0 0 0' }}>
        <div className="top-col" style={{ width: '40%' }}>
          <DisableClickOverlay isDisabled={headerIsDisabled}>
            <Form.Group controlId="customer">
              <Form.Label style={{ marginTop: '15px' }}>
                <div className="required">Customer Name & Customer Number</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  isDisabled={isLoading && !isBilledOrTransmitted(form)}
                  styles={customStyles('385px')}
                  options={clientOptions}
                  onChange={(event, action) => {
                    if (action.action === 'clear') {
                      formHasChanged.current.clients = true;
                      setField([
                        {
                          fieldName: 'customerId',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'customerName',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'selectedCustomer',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'branch',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'branchName',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'branchSelection',
                          fieldValue: '',
                        },
                      ]);
                    }
                    if (action.action === 'select-option') {
                      formHasChanged.current.clients = true;
                      setField([
                        {
                          fieldName: 'customerId',
                          fieldValue: event.value,
                        },
                        {
                          fieldName: 'customerName',
                          fieldValue: event.label.split(' -').shift(),
                        },
                        {
                          fieldName: 'selectedCustomer',
                          fieldValue: event.label,
                        },
                      ]);
                    }
                  }}
                  isClearable
                  value={clientOptions?.find(option => option.value === form?.customerId)}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingCustomers}
                />
              </div>

              {errors?.customerId && <div className="error">{errors?.customerId}</div>}
            </Form.Group>
          </DisableClickOverlay>
        </div>
        <div className="top-col">
          <DisableClickOverlay isDisabled={headerIsDisabled}>
            <Form.Group controlId="branch">
              <Form.Label
                style={{
                  marginTop: '15px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '5px',
                }}>
                <div className="required">Client Branch</div>
                <Tooltip title="Please select the Customer first." arrow placement="top">
                  <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <MdInfo color="red" sx={{ color: '#da0f2e', fontSize: 30 }} />
                  </div>
                </Tooltip>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  isDisabled={
                    (isLoading && !isBilledOrTransmitted(form)) ||
                    !form?.customerId ||
                    !clientOptions?.find(option => option.value === form?.customerId)
                  }
                  styles={customStyles('225px')}
                  options={branchOptions}
                  onChange={(event, action) => {
                    if (action.action === 'select-option') {
                      formHasChanged.current.branches = true;
                      setField([
                        {
                          fieldName: 'branch',
                          fieldValue: event.value,
                        },
                        {
                          fieldName: 'branchName',
                          fieldValue: event.label.split(' -').shift(),
                        },
                        {
                          fieldName: 'branchSelection',
                          fieldValue: event.label,
                        },
                      ]);
                    }
                    if (action.action === 'clear') {
                      formHasChanged.current.branches = true;
                      setField([
                        {
                          fieldName: 'branch',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'branchName',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'branchSelection',
                          fieldValue: '',
                        },
                      ]);
                    }
                  }}
                  isClearable
                  value={branchOptions?.find(option => option.value === form?.branch)}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingBranches}
                />
              </div>

              {errors?.branch && <div className="error">{errors?.branch}</div>}
            </Form.Group>
          </DisableClickOverlay>
        </div>
        <div className="top-col">
          <Form.Group controlId="costCenter">
            <Form.Label style={{ marginTop: '15px' }}>Cost Center</Form.Label>
            <Form.Control
              style={{
                width: '225px',
                height: '30px',
                padding: 0,
                border: '0px',
                backgroundColor: 'white',
                opacity: '0.7',
              }}
              as="input"
              name="costCenter"
              onChange={() => { }}
              value={
                location.pathname.includes('mobile')
                  ? process.env.REACT_APP_MDS_COST_CENTER
                  : process.env.REACT_APP_DMS_COST_CENTER
              }
              readOnly
              disabled
            />
          </Form.Group>
          {getBottomComponent()}
        </div>
      </Row>
    </div>
  );
};

export default ExpenseForm;
