import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Container, Form, Button, Spinner } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import getCSVData from './csv';
import { getExpensesReadyToTransfer, transferToUltraStaff } from './operations';
import { handleRemoveChecked } from './transferUtils';
import { TransferResultsBody, dateToTimeZero } from './utils';
import Excel from '../../../../assets/excel.png';
import { Breadcrumbs, ModalConfirmation, SubHeader, TransferToUltraStaffTable } from '../../../../components';
import { transferToUltraStaffColumns } from '../../../../components/TransferToUltraStaffTable';
import { newDateFormatted } from '../../../../utils';
import { expenseCodes } from '../../../../utils/utilities';
import { getClients, getDrivers, getAssignments } from '../operations';

import './styles.scoped.css';

const TransferToUltraStaff = () => {
  const navigate = useNavigate();
  const { clientMenu } = useSelector(state => state.expenseEntry);
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedExpenses, setSelectedExpenses] = useState([]);
  const selectingAll = useRef(false);
  const expensesData = useRef([]);
  const expensesPushResult = useRef([]);
  const isSearching = useRef(false);
  const assignmentLineOfBusiness = useRef('');
  const [assignmentLineOfBusinessItems, setAssignmentLineOfBusinessItems] = useState({});

  const prevToken = useRef('');

  const [drivers, setDrivers] = useState([]);
  const [clients, setClients] = useState([]);
  const [assignments, setAssignments] = useState([]);

  const [isLoadingDrivers, setIsLoadingDrivers] = useState(false);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [isLoadingAssignments, setIsLoadingAssignments] = useState(false);

  const [hasFilters, setHasFilters] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSelectionError, setShowSelectionError] = useState(false);
  const [showPushedExpensesResult, setShowPushedExpensesResult] = useState(false);

  const [form, setForm] = useState({});

  const getAssignmentLineOfBusinessItems = () => {
    clientMenu?.menuItems?.map(i => {
      if (location.pathname.includes(i.to)) {
        setAssignmentLineOfBusinessItems(i);
      }
    })
  };

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      document.querySelector('div.root').style.background = '#D0CED0';
      document.querySelector('span.wrap').style.background = '#ffff';
      document.querySelector('.left-title').style.color = '#ffff';
      document.querySelector('.right-title').style.color = '#66CCFF';
    }
    return () => {
      document.querySelector('div.root').style.background = '#ffff';
    }
  }, []);

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      assignmentLineOfBusiness.current = 'DMS';
    }
    if (location.pathname.includes('mobile-driver-solutions')) {
      assignmentLineOfBusiness.current = 'MDS';
    }
  }, [])

  const getDriversToSelect = async () => {
    try {
      const newDrivers = await getDrivers(`drivers?lob=${assignmentLineOfBusiness.current}`);
      setDrivers(newDrivers);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getAssignmentsToSelect = async () => {
    try {
      const newAssignments = await getAssignments(`assignments?lob=${assignmentLineOfBusiness.current}`);
      setAssignments(newAssignments);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getClientsToSelect = async () => {
    try {
      const newClients = await getClients(`clients?lob=${assignmentLineOfBusiness.current}`);
      setClients(newClients);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleGetData = () => {
    // Drivers
    setIsLoadingDrivers(true);
    getDriversToSelect()
      .catch(error => console.log('error', error))
      .finally(() => setIsLoadingDrivers(false));

    //Assignments
    setIsLoadingAssignments(true);
    getAssignmentsToSelect()
      .catch(error => console.log('error', error))
      .finally(() => setIsLoadingAssignments(false));

    //Customers
    setIsLoadingCustomers(true);
    getClientsToSelect()
      .catch(error => console.log('error', error))
      .finally(() => setIsLoadingCustomers(false));
  };

  const customStyles = width => ({
    control: provided => ({
      ...provided,
      width: width,
      height: '30px',
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: '200px',
    }),
  });

  const setField = (fields, value) => {
    if (Array.isArray(fields)) {
      const itemsToAdd = {};
      fields.forEach(field => {
        itemsToAdd[(field?.fieldName)] = field.fieldValue;
      });
      setForm({ ...form, ...itemsToAdd });
    } else {
      setForm({
        ...form,
        [fields]: value,
      });
    }
  };

  const driverOptions = drivers?.map(({ APPL_FIRSTNAME, APPL_LASTNAME, APPL_ID }) => ({
    label: `${APPL_FIRSTNAME} ${APPL_LASTNAME} - ${APPL_ID}`,
    value: APPL_ID,
  }));

  const clientOptions = clients?.map(({ CUST_ID, CUST_NAME }) => ({
    label: `${CUST_NAME} - ${CUST_ID}`,
    value: CUST_ID,
  }));

  const assignmentsOptions = assignments?.map(({ ASS_ID }) => ({
    label: ASS_ID,
    value: ASS_ID,
  }));

  const codeOptions = expenseCodes?.map(({ label, value }) => ({
    label: label,
    value: value,
  }));

  useLayoutEffect(() => {
    handleGetData();
    getAssignmentLineOfBusinessItems();
  }, []);

  const formatExpenses = exp => {
    const formattedExpenses = [...exp];

    formattedExpenses?.forEach(e => {
      e.driverNameNumber = `${e?.driverName} - ${e?.driverId}`;
      e.customerNameNumber = `${e?.customerName} - ${e?.customerId}`;
    });

    return formattedExpenses;
  };

  const handleSearch = () => {
    if (isSearching.current === false) {
      isSearching.current = true;
      const filters = formatFilters();
      prevToken.current = '';
      expensesData.current = [];
      getBatch({
        lob: assignmentLineOfBusiness.current,
        nextToken: null,
        selectedFilters: filters,
      });
    }
  };

  const getBatch = async ({ nextToken, selectedFilters, lob }) => {
    if (prevToken.current !== nextToken) {
      prevToken.current = nextToken;

      const data = await getExpensesReadyToTransfer({
        lob: lob,
        token: nextToken,
        filters: selectedFilters,
      });
      const items = data?.list;
      const token = data?.lastEvaluatedKey;
      expensesData.current = expensesData.current.concat(items);
      if (token) {
        getBatch({ nextToken: token, selectedFilters: selectedFilters, lob: assignmentLineOfBusiness.current });
      } else {
        const formattedExpenses = formatExpenses(expensesData.current);
        setExpenses(formattedExpenses);
        setLoading(false);
        isSearching.current = false;
      }
    }
  };

  const handleSelectExpense = expense => {
    setSelectedExpenses(prevSelected => {
      if (prevSelected.find(exp => exp.id === expense.id)) {
        return prevSelected.filter(exp => exp.id !== expense.id);
      }
      return [...prevSelected, expense];
    });
  };

  const handleSelectAll = () => {
    if (selectedExpenses.length === expenses.length) {
      setSelectedExpenses([]);
    } else {
      setSelectedExpenses([...expenses]);
    }
  };

  const formatFilters = () => {
    const fields = ['driverId', 'customerId', 'code', 'assignmentId'];
    const filters = {};
    fields.forEach(f => {
      if (form?.[f]) {
        if (f === 'driverId' || f === 'customerId' || f === 'assignmentId') {
          filters[f] = parseInt(form?.[f]);
        } else {
          filters[f] = form?.[f];
        }
      }
    });

    if (form?.startDate) {
      filters.startDate = dateToTimeZero(form?.startDate);
    }

    if (form?.endDate) {
      filters.endDate = dateToTimeZero(form?.endDate);
    }

    if (assignmentLineOfBusiness.current)
      filters.lob = assignmentLineOfBusinessItems.lob;

    setHasFilters(Object.keys(filters).length > 0);

    return filters;
  };

  useEffect(() => {
    setLoading(true);
    getBatch({
      lob: assignmentLineOfBusiness.current,
      nextToken: null,
      selectedFilters: {},
    });
  }, [assignmentLineOfBusiness.current]);

  const handleResetForm = () => {
    expensesData.current = [];
    setForm({});
    // eslint-disable-next-line no-undef
    document.getElementById('startDate').value = '';
    // eslint-disable-next-line no-undef
    document.getElementById('endDate').value = '';
    prevToken.current = '';
    setLoading(true);
    setHasFilters(false);
    getBatch({
      lob: assignmentLineOfBusiness.current,
      nextToken: null,
      selectedFilters: {},
    });
  };

  const checkTransferToUltraStaff = () => {
    if (selectedExpenses.length) {
      setShowConfirmation(true);
    } else {
      setShowSelectionError(true);
    }
  };

  const handleExpensePushed = result => {
    expensesPushResult.current = [...expensesPushResult.current, result];
  };

  const bulkTransferToUltraStaff = async () => {
    Promise.all(
      selectedExpenses.map(async exp => {
        try {
          const result = await transferToUltraStaff(exp, handleExpensePushed);
          return result;
        } catch (e) {
          console.log(e);
          return undefined;
        }
      }),
    ).then(() => {
      expensesData.current = [];
      selectingAll.current = false;
      setSelectedExpenses([]);
      prevToken.current = '';
      setShowPushedExpensesResult(true);
    });
  };

  const fileName = `ultra-staff-${newDateFormatted.toString()}.csv`;

  return (
    <>
      <SubHeader
        text="Expenses"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Expenses?"
        emailAddress={process.env.REACT_APP_EXPENSE_CONTACT_EMAIL}
      />

      <Breadcrumbs
        leftTitle={assignmentLineOfBusinessItems.text}
        rightTitle="Transfer To UltraStaff"
        route={assignmentLineOfBusinessItems.to}
        color={assignmentLineOfBusiness.current === 'DMS' && '#ffff'}
      />

      {!!expenses?.length && (
        <CSVLink filename={fileName} data={getCSVData(expenses, transferToUltraStaffColumns)}>
          <img
            src={Excel}
            alt="export-to-excel"
            style={{
              padding: '20px 30px 0 0 ',
              cursor: 'pointer',
              position: 'absolute',
              top: 136,
              right: 0,
            }}
          />
        </CSVLink>
      )}
      <h1 className="subtitle">Select filters and click search</h1>
      <div className="filter-row">
        <div className="filter-container">
          <div className="column">
            <Form.Group controlId="driveroptions">
              <Form.Label style={{ marginTop: '15px' }}>
                <div className="required"> Driver Name & Driver Number</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  styles={customStyles('385px')}
                  options={driverOptions}
                  onChange={event => {
                    setField([
                      {
                        fieldName: 'driverId',
                        fieldValue: event.value,
                      },
                      {
                        fieldName: 'driverName',
                        fieldValue: event.label.split(' -').shift(),
                      },
                      {
                        fieldName: 'selectedDriver',
                        fieldValue: event.label,
                      },
                    ]);
                  }}
                  value={driverOptions.filter(opt => opt.label === form?.selectedDriver)}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingDrivers}
                />
              </div>
            </Form.Group>
          </div>
          <div className="column">
            <Form.Group controlId="assignmentoptions">
              <Form.Label style={{ marginTop: '15px' }}>
                <div className="required">Assignment / Contract Activity</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  styles={customStyles('225px')}
                  options={assignmentsOptions}
                  onChange={event => {
                    setField([
                      {
                        fieldName: 'assignmentId',
                        fieldValue: event.value,
                      },
                    ]);
                  }}
                  value={assignmentsOptions.filter(opt => opt.label === form?.assignmentId)}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingAssignments}
                />
              </div>
            </Form.Group>
          </div>
          <div className="column">
            <Form.Group style={{}}>
              <Form.Label style={{ marginTop: '15px' }}>Start Date</Form.Label>
              <Form.Control
                id="startDate"
                style={{ width: '150px', height: '30px' }}
                name="startDate"
                type="date"
                as="input"
                value={form?.startDate?.substring(0, 10)}
                onChange={event => {
                  setField('startDate', event.target.value);
                }}
              />
            </Form.Group>
          </div>
        </div>
        <Button className="button-text" onClick={handleSearch}>
          <span>SEARCH</span>
        </Button>
      </div>
      <div className="filter-row">
        <div className="filter-container">
          <div className="column">
            <Form.Group controlId="customer">
              <Form.Label style={{ marginTop: '15px' }}>
                <div className="required">Customer Name & Customer Number</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  styles={customStyles('385px')}
                  options={clientOptions}
                  onChange={event =>
                    setField([
                      {
                        fieldName: 'customerId',
                        fieldValue: event.value,
                      },
                      {
                        fieldName: 'customerName',
                        fieldValue: event.label.split(' -').shift(),
                      },
                      {
                        fieldName: 'selectedCustomer',
                        fieldValue: event.label,
                      },
                    ])
                  }
                  value={clientOptions.filter(opt => opt.label === form?.selectedCustomer)}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingCustomers}
                />
              </div>
            </Form.Group>
          </div>
          <div className="column" style={{ width: '225px' }}>
            <Form.Group controlId="paycode">
              <Form.Label style={{ width: '160px', marginTop: '15px' }}>Code</Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  styles={customStyles('150px')}
                  options={codeOptions}
                  onChange={event => {
                    setField('code', event.value);
                  }}
                  value={codeOptions.filter(opt => opt.label === form?.code)}
                />
              </div>
            </Form.Group>
          </div>
          <div className="column">
            <Form.Group style={{ marginBottom: 0 }}>
              <Form.Label>End Date</Form.Label>
              <Form.Control
                id="endDate"
                style={{ width: '150px', height: '30px' }}
                name="endDate"
                type="date"
                as="input"
                value={form?.endDate?.substring(0, 10)}
                onChange={event => {
                  setField('endDate', event.target.value);
                }}
              />
            </Form.Group>
          </div>
        </div>
        <Button className="button-secondary" onClick={handleResetForm}>
          <span>RESET FILTERS</span>
        </Button>
      </div>
      <div className="gray-divider" />
      <Container className="wrap" fluid>
        <Container className="wrap" fluid>
          {loading ? (
            <div className="h-100 d-flex justify-content-center p-5">
              <Spinner animation="border" />
            </div>
          ) : (
            <TransferToUltraStaffTable
              data={expenses}
              handleSelectExpense={handleSelectExpense}
              handleSelectAll={handleSelectAll}
              selectedExpenses={selectedExpenses}
              hasFilters={hasFilters}
            />
          )}
        </Container>
      </Container>
      <ModalConfirmation
        title="Confirmation"
        body="I approve these expenses to be transferred to UltraStaff for billing the client."
        primaryButtonTitle="CONFIRM"
        primaryButton
        optionalClose
        secondaryButtonTitle="CANCEL"
        onClick={() => {
          setShowConfirmation(false);
          setLoading(true);
          bulkTransferToUltraStaff();
        }}
        secondaryOnClick={() => {
          setShowConfirmation(false);
        }}
        isOpen={showConfirmation}
      />
      <ModalConfirmation
        title="Selection Error"
        body="Please Select at least one record."
        primaryButtonTitle="OK"
        primaryButton
        onClick={() => {
          setShowSelectionError(false);
        }}
        isOpen={showSelectionError}
      />
      {showPushedExpensesResult && (
        <ModalConfirmation
          title={expensesPushResult.current.length ? 'Transfer To UltraStaff errors.' : 'Success!'}
          body={
            expensesPushResult.current.length ? (
              <TransferResultsBody results={expensesPushResult.current} />
            ) : (
              'All approved items have been transferred'
            )
          }
          primaryButtonTitle="OK"
          primaryButton
          onClick={() => {
            expensesPushResult.current = [];
            setShowPushedExpensesResult(false);
            getBatch({
              nextToken: null,
              selectedFilters: {},
            });
          }}
          isOpen={showPushedExpensesResult}
        />
      )}

      <div className="buttons-container">
        <Button className="button-text" onClick={checkTransferToUltraStaff}>
          <span>TRANSFER TO ULTRASTAFF</span>
        </Button>
        <div className="action-buttons-container">
          <Button
            className="button-secondary"
            style={{ marginRight: '20px' }}
            onClick={() => handleRemoveChecked(selectedExpenses, navigate, assignmentLineOfBusinessItems)}>
            <span>REMOVE CHECKED</span>
          </Button>

          <Button className="button-secondary" onClick={() => navigate(assignmentLineOfBusinessItems.to)}>
            <span>CANCEL</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default TransferToUltraStaff;
