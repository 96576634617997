import React, { Children } from 'react';

const AuthContainer = ({ children, currentIndex, nextStep }) => {

    const currentChild = Children.toArray(children)[currentIndex];

    if (React.isValidElement(currentChild)) {
        return React.cloneElement(currentChild, { nextStep })
    }

    return currentChild;
};

export default AuthContainer;