import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { getNationalPushNotifications } from './operations'
import { PushNotificationsHeader, NationalPushNotificationsTable, SubHeader, Footer } from '../../../components';
import { Button, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Home } from '@mui/icons-material';
import "./styles.scoped.css";

const NationalPushNotifications = () => {
  const navigate = useNavigate();
  const { statuses } = useSelector(state => state.pushNotifications);
  const [form, setForm] = useState({});
  const [isSearching, setIsSearching] = useState(false);
  const [notifications, setNotifications] = useState([]);

  let nextToken = null;
  let prevToken = null;

  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);

  const formFilters = () => {
    const filters = {};

    if (form) {
      filters.type = { eq: 'National' };
    }

    if (form?.status !== undefined && form?.status !== "" && form?.status !== null) {
      filters.status = { eq: form?.status };
    }

    if (form?.createdAtStartDate && form?.createdAtEndDate) {
      const startDate = dayjs(form?.createdAtStartDate)
        .startOf('day')
        .toISOString();
      // Set the end date to the end of the day
      const endDate = dayjs(form?.createdAtEndDate)
        .endOf('day')
        .toISOString();
      filters.createdAt = { between: [`${dayjs.utc(startDate).format()}`, `${dayjs.utc(endDate).format()}`] };
    }

    return filters;
  }

  const getFilteredNotifications = async (token) => {
    const filters = formFilters();
    const response = await getNationalPushNotifications(
      filters,
      token,
    );

    return {
      list: response?.items,
      tokenResult: response?.nextToken,
    };
  }

  const getBatch = async (nextToken = undefined) => {
    if (prevToken !== nextToken) {
      prevToken = nextToken;

      const response = await getFilteredNotifications(nextToken);
      const items = response?.list;
      const token = response?.tokenResult;

      if (token !== null) {
        getBatch(token);
        if (items) {
          setNotifications([items, ...items]);
        }
      } else {
        setIsSearching(false);
      }
    }
  }

  const handleSearch = async () => {
    setIsSearching(true);
    const response = await getFilteredNotifications(nextToken);

    if (Array.isArray(response?.list) && response?.list.length > 0) {
      setNotifications([...response?.list]);
      setIsSearching(false);
    } else {
      getBatch(response?.tokenResult);
    }

  }

  useEffect(() => {
    handleSearch();
  }, [])

  return (
    <>
      <SubHeader
        text={"Push Notifications"}
        btnSmall={<Home sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigate('/push-notifications')}
      />

      <div className="wrap">
        <PushNotificationsHeader
          form={form}
          setForm={setForm}
          setIsSearching={setIsSearching}
          setNotifications={setNotifications}
          route={`add-national-push-notification`}
          statuses={statuses}
          title={"National Push Notifications"}
          handleSearch={handleSearch}
        />

        {Array.isArray(notifications) && !isSearching ? (
          <NationalPushNotificationsTable
            data={notifications}
            editRoute={'/push-notifications/national-push-notifications/edit-national-push-notification'}
          />
        ) : (
          <div className="h-100 d-flex justify-content-center p-5">
            <Spinner animation="border" />
          </div>
        )
        }

        <Row className="justify-content-center">
          <Button
            className="button-secondary"
            onClick={() => { navigate('/push-notifications') }}
          >
            Cancel
          </Button>
        </Row>

      </div>

      <Footer />
    </>
  );
}

export default NationalPushNotifications;
