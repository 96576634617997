import React from 'react';
import { customStyles } from '../utils';
import Select from 'react-select';

import { Button, Form, Row, Spinner } from 'react-bootstrap';

import './styles.scoped.css';

const ClientContactActivityForm = ({
  form,
  errors,
  setForm,
  lobOptions,
  navigateCancelAction,
  activityTypeOptions,
  ownerCodeOptions,
  handleViewResults,
  loginOwnerCodeOptions,
  isLoadingOwnerCodes,
  isLoadingActivityTypes,
  isLoadingLoginOwnerCodes,
  isLoadingLineOfBusinesses,
}) => {

  const handleCancel = async () => { navigateCancelAction() };

  return (
    <Form noValidate onSubmit={() => { }}>
      <>
        <Row className="top-row">
          <div
            className="top-col"
            style={{
              width: '65%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row}>
              <Form.Label style={{ fontWeight: 'bold', width: '250px' }}>Dates</Form.Label>
            </Form.Group>
          </div>
          <div
            className="top-col"
            style={{
              width: '35%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row}>
              <Form.Label style={{ fontWeight: 'bold', width: '160px' }}>Consultants</Form.Label>
            </Form.Group>
          </div>
        </Row>
        <Row className="top-row">
          <div
            className="top-col"
            style={{
              width: '65%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row} controlId="ActivityFromdate">
              <Form.Label style={{ width: '250px' }}><div className="required">Activity Date</div></Form.Label>
              <div style={{ flexDirection: 'column' }}>
                <Form.Control
                  style={{
                    width: '210px',
                    height: '30px',
                    marginRight: '20px',
                  }}
                  as="input"
                  type="date"
                  name="ActivityFromdate"
                  value={form?.ActivityFromdate ? form?.ActivityFromdate?.substring(0, 10) : ''}
                  onChange={event => {
                    setForm({
                      ...form,
                      ActivityFromdate: event.target.value
                    });
                  }}
                  isInvalid={!!errors?.ActivityFromdate}
                />
                <Form.Control.Feedback type="invalid">{errors?.ActivityFromdate}</Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group as={Row} controlId="ActivityTodate">
              <Form.Label
                style={{
                  width: '20px',
                  marginLeft: '30px',
                  marginRight: '20px',
                }}>
                To
              </Form.Label>
              <div style={{ flexDirection: 'column' }}>
                <Form.Control
                  style={{
                    width: '210px',
                    height: '30px',
                  }}
                  as="input"
                  type="date"
                  name="ActivityToDate"
                  value={form?.ActivityTodate ? form?.ActivityTodate?.substring(0, 10) : ''}
                  onChange={event => {
                    setForm({
                      ...form,
                      ActivityTodate: event.target.value
                    });
                  }}
                  isInvalid={!!errors?.ActivityTodate}
                />
                <Form.Control.Feedback type="invalid">{errors?.ActivityTodate}</Form.Control.Feedback>
              </div>
            </Form.Group>
          </div>
          <div
            className="top-col"
            style={{
              width: '35%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row} controlId="OwnerCodes">
              <Form.Label style={{ width: '160px' }}>Owner Code</Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  isDisabled={isLoadingOwnerCodes}
                  isMulti
                  closeMenuOnSelect={false}
                  styles={customStyles('365px')}
                  options={ownerCodeOptions}
                  defaultValue={form?.OwnerCodes?.split(',').map(code =>
                    ownerCodeOptions.find(option => option.value === code),
                  )}
                  onChange={selectedOptions => {
                    const selectedCodes = selectedOptions.map(option => option.value);
                    setForm({
                      ...form,
                      OwnerCodes: selectedCodes.join(',')
                    });
                  }}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingOwnerCodes}
                />
              </div>
            </Form.Group>
          </div>
        </Row>

        <Row className="top-row">
          <div
            className="top-col"
            style={{
              width: '65%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row} controlId="LastContactFromdate">
              <Form.Label style={{ width: '250px' }}>Last Contact Date</Form.Label>
              <div style={{ flexDirection: 'column' }}>
                <Form.Control
                  style={{
                    width: '210px',
                    height: '30px',
                    marginRight: '20px',
                  }}
                  as="input"
                  type="date"
                  name="LastContactFromdate"
                  value={form?.LastContactFromdate ? form?.LastContactFromdate?.substring(0, 10) : ''}
                  onChange={event => {
                    setForm({
                      ...form,
                      LastContactFromdate: event.target.value
                    });
                  }}
                  isInvalid={!!errors?.LastContactFromdate}
                />
                <Form.Control.Feedback type="invalid">{errors?.LastContactFromdate}</Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group as={Row} controlId="LastContactTodate">
              <Form.Label
                style={{
                  width: '20px',
                  marginLeft: '30px',
                  marginRight: '20px',
                }}>
                To
              </Form.Label>
              <div style={{ flexDirection: 'column' }}>
                <Form.Control
                  style={{
                    width: '210px',
                    height: '30px',
                  }}
                  as="input"
                  type="date"
                  name="LastContactTodate"
                  value={form?.LastContactTodate ? form?.LastContactTodate?.substring(0, 10) : ''}
                  onChange={event => {
                    setForm({
                      ...form,
                      LastContactTodate: event.target.value
                    });
                  }}
                  isInvalid={!!errors?.LastContactTodate}
                />
                <Form.Control.Feedback type="invalid">{errors?.LastContactTodate}</Form.Control.Feedback>
              </div>
            </Form.Group>
          </div>
          <div
            className="top-col"
            style={{
              width: '35%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row} controlId="LoginOwnerCode">
              <Form.Label style={{ width: '160px' }}>Login Owner Code</Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  isDisabled={isLoadingLoginOwnerCodes}
                  isMulti
                  closeMenuOnSelect={false}
                  styles={customStyles('365px')}
                  options={ownerCodeOptions}
                  defaultValue={form?.loginOwnerCode?.split(',').map(code =>
                    loginOwnerCodeOptions.find(option => option.value === code),
                  )}
                  onChange={selectedOptions => {
                    const selectedCodes = selectedOptions.map(option => option.value);
                    setForm({
                      ...form,
                      LoginOwnerCode: selectedCodes.join(','),
                    });
                  }}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingLoginOwnerCodes}
                />
              </div>
            </Form.Group>
          </div>
        </Row>

        <Row className="top-row">
          <div
            className="top-col"
            style={{
              width: '65%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row} controlId="FollowupFromdate">
              <Form.Label style={{ width: '250px' }}>Followup Date</Form.Label>
              <div style={{ flexDirection: 'column' }}>
                <Form.Control
                  style={{
                    width: '210px',
                    height: '30px',
                    marginRight: '20px',
                  }}
                  as="input"
                  type="date"
                  name="FollowupFromdate"
                  value={form?.FollowupFromdate ? form?.FollowupFromdate?.substring(0, 10) : ''}
                  onChange={event => {
                    setForm({
                      ...form,
                      FollowupFromdate: event.target.value
                    });
                  }}
                  isInvalid={!!errors?.FollowFromdate}
                />
                <Form.Control.Feedback type="invalid">{errors?.FollowupFromdate}</Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group as={Row} controlId="FollowupTodate">
              <Form.Label
                style={{
                  width: '20px',
                  marginLeft: '30px',
                  marginRight: '20px',
                }}>
                To
              </Form.Label>
              <div style={{ flexDirection: 'column' }}>
                <Form.Control
                  style={{
                    width: '210px',
                    height: '30px',
                  }}
                  as="input"
                  type="date"
                  name="FollowupTodate"
                  value={form?.FollowupTodate ? form?.FollowupTodate?.substring(0, 10) : ''}
                  onChange={event => {
                    setForm({
                      ...form,
                      FollowupTodate: event.target.value
                    });
                  }}
                  isInvalid={!!errors?.FollowupTodate}
                />
                <Form.Control.Feedback type="invalid">{errors?.followupTodate}</Form.Control.Feedback>
              </div>
            </Form.Group>
          </div>
          <div
            className="top-col"
            style={{
              width: '35%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row} controlId="ActivityType">
              <Form.Label style={{ width: '160px' }}>Activity Type</Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  isDisabled={isLoadingActivityTypes}
                  isMulti
                  closeMenuOnSelect={false}
                  styles={customStyles('365px')}
                  options={activityTypeOptions}
                  defaultValue={form?.ActivityType?.split(',').map(activityType =>
                    activityTypeOptions.find(option => option.value === activityType),
                  )}
                  onChange={selectedOptions => {
                    const selectedActivityTypes = selectedOptions.map(option => option.value);
                    setForm({
                      ...form,
                      ActivityType: selectedActivityTypes.join(','),
                    });
                  }}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingActivityTypes}
                />
              </div>
            </Form.Group>
          </div>
        </Row>

        <Row className="top-row">
          <div
            className="top-col"
            style={{
              width: '65%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row}>
              <Form.Label style={{ fontWeight: 'bold', width: '250px' }}>Details</Form.Label>
            </Form.Group>
          </div>
        </Row>

        <Row className="top-row">
          <div
            className="top-col"
            style={{
              width: '65%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row} controlId="FromClientName">
              <Form.Label style={{ width: '250px' }}>Client Name</Form.Label>
              <div style={{ flexDirection: 'column' }}>
                <Form.Control
                  style={{
                    width: '210px',
                    height: '30px',
                    marginRight: '20px',
                  }}
                  as="input"
                  name="FromClientName"
                  defaultValue={form?.FromClientName}
                  onChange={event => {
                    setForm({
                      ...form,
                      FromClientName: event.target.value
                    });
                  }}
                  isInvalid={!!errors?.FromClientName}
                />
                <Form.Control.Feedback type="invalid">{errors?.FromClientName}</Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group as={Row} controlId="ToClientName">
              <Form.Label
                style={{
                  width: '20px',
                  marginLeft: '30px',
                  marginRight: '20px',
                }}>
                To
              </Form.Label>
              <div style={{ flexDirection: 'column' }}>
                <Form.Control
                  style={{
                    width: '210px',
                    height: '30px',
                    marginRight: '20px',
                  }}
                  as="input"
                  name="ToClientName"
                  defaultValue={form?.ToClientName}
                  onChange={event => {
                    setForm({
                      ...form,
                      ToClientName: event.target.value
                    });
                  }}
                  isInvalid={!!errors?.ToClientName}
                />
                <Form.Control.Feedback type="invalid">{errors?.ToClientName}</Form.Control.Feedback>
              </div>
            </Form.Group>
          </div>
        </Row>

        <Row className="top-row">
          <div
            className="top-col"
            style={{
              width: '65%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row} controlId="FromClientID">
              <Form.Label style={{ width: '250px' }}>Client Number</Form.Label>
              <div style={{ flexDirection: 'column' }}>
                <Form.Control
                  style={{
                    width: '210px',
                    height: '30px',
                    marginRight: '20px',
                  }}
                  as="input"
                  name="FrmClientID"
                  type="number"
                  defaultValue={form?.FromClientID}
                  onChange={event => {
                    setForm({
                      ...form,
                      FromClientID: event.target.value
                    }

                    );
                  }}
                  isInvalid={!!errors?.FromClientName}
                />
                <Form.Control.Feedback type="invalid">{errors?.ToClientID}</Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group as={Row} controlId="ToClientID">
              <Form.Label
                style={{
                  width: '20px',
                  marginLeft: '30px',
                  marginRight: '20px',
                }}>
                To
              </Form.Label>
              <div style={{ flexDirection: 'column' }}>
                <Form.Control
                  style={{
                    width: '210px',
                    height: '30px',
                  }}
                  as="input"
                  name="ToClientID"
                  type="number"
                  defaultValue={form?.ToClientID}
                  onChange={event => {
                    setForm({
                      ...form,
                      ToClientID: event.target.value
                    });
                  }}
                  isInvalid={!!errors?.ToClientID}
                />
                <Form.Control.Feedback type="invalid">{errors?.ToClientID}</Form.Control.Feedback>
              </div>
            </Form.Group>
          </div>
        </Row>

        <Row className="top-row">
          <div
            className="top-col"
            style={{
              width: '65%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row} controlId="FromClientBillToID">
              <Form.Label style={{ width: '250px' }}>Client BillTo Number</Form.Label>
              <div style={{ flexDirection: 'column' }}>
                <Form.Control
                  style={{
                    width: '210px',
                    height: '30px',
                    marginRight: '20px',
                  }}
                  as="input"
                  type="number"
                  name="FromClientBillToID"
                  defaultValue={form?.FromClientBillToID}
                  onChange={event => {
                    setForm({
                      ...form,
                      FromClientBillToID: event.target.value
                    });
                  }}
                  isInvalid={!!errors?.FromClientBillToID}
                />
                <Form.Control.Feedback type="invalid">{errors?.FromClientBillToID}</Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group as={Row} controlId="ToClientBillToID">
              <Form.Label
                style={{
                  width: '20px',
                  marginLeft: '30px',
                  marginRight: '20px',
                }}>
                To
              </Form.Label>
              <div style={{ flexDirection: 'column' }}>
                <Form.Control
                  style={{
                    width: '210px',
                    height: '30px',
                  }}
                  as="input"
                  type="number"
                  name="ToClientBillToID"
                  defaultValue={form?.ToClientBillToID}
                  onChange={event => {
                    setForm({
                      ...form,
                      ToClientBillToID: event.target.value
                    });
                  }}
                  isInvalid={!!errors?.ToClientBillToID}
                />
                <Form.Control.Feedback type="invalid">{errors?.ToClientBillToID}</Form.Control.Feedback>
              </div>
            </Form.Group>
          </div>
        </Row>

        <Row className="top-row">
          <div
            className="top-col"
            style={{
              width: '100%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row} controlId="LOB">
              <Form.Label style={{ width: '250px' }}>Line Of Business</Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  isDisabled={isLoadingLineOfBusinesses}
                  isMulti
                  closeMenuOnSelect={false}
                  styles={customStyles('365px')}
                  options={lobOptions}
                  defaultValue={form?.lobOptions?.split(',').map(lob =>
                    lobOptions.find(option => option.value === lob),
                  )}
                  onChange={selectedOptions => {
                    const lobs = selectedOptions.map(option => option.value);
                    setForm({
                      ...form,
                      LOB: lobs.join(','),
                    });
                  }}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingLineOfBusinesses}
                />
              </div>
            </Form.Group>
          </div>
        </Row>

        <div className="buttons-row">
          <Button
            disabled={!form?.ActivityFromdate || !form?.ActivityTodate}
            onClick={handleViewResults}
            style={{ marginRight: '20px', width: '100px' }}>
            <span>VIEW</span>
          </Button>

          <Button style={{ width: '100px' }} onClick={() => { handleCancel() }} className="button-secondary">
            <span>CANCEL</span>
          </Button>
        </div>

      </>
    </Form>
  );
};

export default ClientContactActivityForm;
