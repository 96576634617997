import { API } from 'aws-amplify';

import { getHotelFormData } from '../../../../graphql/customQueries';
import { listCities, getState, getHotel, getCity, listAuditbyObjectNamebyObjectId } from '../../../../graphql/queries';

export const getFormData = async () => {
  try {
    const response = await API.graphql({
      query: getHotelFormData,
      variables: {
        limit: 10000,
      },
    });
    return mapFormData(response.data);
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

const mapFormData = data => ({
  hotels: data?.listHotels?.items,
  states: data?.listStates?.items,
});

export const getCitiesFiltered = async ({ state, token }) => {
  try {
    const response = await API.graphql({
      query: listCities,
      variables: {
        filter: { stateCityId: { eq: state } },
        nextToken: token,
        limit: 100000,
      },
    });
    return response.data.listCities;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getCityById = async id => {
  try {
    const response = await API.graphql({
      query: getCity,
      variables: { id: id },
    });

    return response.data.getCity.name;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
export const getHotelById = async id => {
  try {
    const response = await API.graphql({
      query: getHotel,
      variables: { id: id },
    });
    return response.data.getHotel.name;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getStateById = async id => {
  try {
    const response = await API.graphql({
      query: getState,
      variables: { id: id },
    });
    return response.data.getState?.name;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getAudit = async (id) => {
  try {
    const response = await API.graphql({
      query: listAuditbyObjectNamebyObjectId,
      variables: {
        objectName: "Expense",
        objectId: { eq: id },
        limit: 1000
      },
    })
    return response?.data?.listAuditbyObjectNamebyObjectId?.items;
  } catch (e) {
    console.log('error', e)
  }
}

