import React, { useEffect } from 'react';

import { Home } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getItems } from './operations';
import { Breadcrumbs, SubHeader, Footer, ChecklistTable } from '../../../../components';
import './styles.scoped.css';

const ChecklistTemplates = () => {
  const navigation = useNavigate();
  const { listData } = useSelector(state => state.checklistTemplates);

  useEffect(() => {
    getItems();
  }, []);

  return (
    <>
      <SubHeader
        text="Centerline Driver Qualification"
        btnSmall={<Home sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigation('/driver-qualification')}
      />
      <Breadcrumbs leftTitle="DQF Home" middleTitle="Checklist Template" route="/driver-qualification" />
      <div className="wrap">
        <p className="title">Checklist Templates</p>
        <ChecklistTable data={listData} />
      </div>
      <Footer />
    </>
  );
};
export default ChecklistTemplates;
