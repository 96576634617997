export const customStyles = width => {
  return {
    control: (provided, state) => ({
      ...provided,
      width: width,
      minHeight: '30px',
    }),
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: '200px',
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: '#da0f2e',
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: '#ffffff',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: '#ffffff',
    }),
  };
};

export const formatSocial = social => {
  var val = social?.replace(/\D/g, '');
  val = val?.replace(/^(\d{3})/, '$1-');
  val = val?.replace(/-(\d{2})/, '-$1-');
  val = val?.replace(/(\d)-(\d{4}).*/, '$1-$2');
  return val;
};

export const formatSSNO = value => {
  if (!value) return value;
  const SSNO = value.replace(/[^\d]/g, '');
  const firstPart = SSNO.slice(0, 3);
  const secondPart = SSNO.slice(3, 5);
  const thirdPart = SSNO.slice(5);
  return `${firstPart}${secondPart ? '-' : ''}${secondPart}${thirdPart ? '-' : ''}${thirdPart}`;
};
