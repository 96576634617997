import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';

import { Auth } from 'aws-amplify';
import { useBeforeunload } from 'react-beforeunload';
import { MdWeb } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import FormContent from './FormContent';
import { getFormData } from './operations';
import { Breadcrumbs, ExpensesWindow, ModalConfirmation, SubHeader } from '../../../../components';
import { resetData } from '../../../../components/ExpenseForm/expenseFormSlice';
import { formatIsoToDate } from '../../../../utils';
import { getUserInfo } from '../../../DriverQualification/ViewDQF/operations';
import { calculateAuditKey, FlatFeeModal, receiptsHaveReceiptAmount } from '../expensesUtils';
import {
  createExpense,
  createExpenseItem,
  deleteExpenseFile,
  getExpenseId,
  updateExpense,
  checkClientFlatFee,
} from '../../DriverSolutions/operations';
import { dateToTimeZero } from '../TransferToUltraStaff/utils';

import './styles.scoped.css';

const Airline = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.admin);
  const [expenseId, setExpenseId] = useState(null);
  const { clientMenu } = useSelector(state => state.expenseEntry);

  const [formData, setFormData] = useState();
  const [auditKey, setAuditKey] = useState('--');
  const [currentUser, setCurrentUser] = useState('');
  const [totalToBeBilled, setTotalToBeBilled] = useState('0.00');
  const [receipts, setReceipts] = useState([{ markup: '0', receiptAmount: '0.0' }]);
  const [isCreatingItems, setIsCreatingItems] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showReceiptFilesDialog, setShowReceiptFilesDialog] = useState(false);
  const hasSetRebillData = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showFFDialog, setShowFFDialog] = useState(false);
  const [showNonFFDialog, setShowNonFFDialog] = useState(false);
  const hasSetReceiptOptions = useRef(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const formHasChanged = useRef({
    drivers: false,
    assignments: false,
    clients: false,
    branches: false,
  });
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState();
  const [assignmentLineOfBusinessItems, setAssignmentLineOfBusinessItems] = useState({});
  const assignmentLineOfBusiness = useRef('');
  const [form, setForm] = useState({
    code: 'XA',
    status: 'Unbilled',
    nonBillable: false,
    checkedForUltraStaff: false,
    inv: 'I',
    payType: 'F',
    billUnits: '1',
    payAmt: 0.0,
    ok: 'T',
    lob: assignmentLineOfBusiness.current,
  });

  const getAssignmentLineOfBusinessItems = () => {
    clientMenu?.menuItems?.map(i => {
      if (location.pathname.includes(i.to)) {
        setAssignmentLineOfBusinessItems(i);
      }
    })
  };

  const getId = async () => {
    const id = await getExpenseId();
    setExpenseId(id);
  }

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      document.querySelector('div.root').style.background = '#D0CED0';
      document.querySelector('span.wrap').style.background = '#ffff';
      document.querySelector('.left-title').style.color = '#ffff';
      document.querySelector('.right-title').style.color = '#66CCFF';
    }
    return () => {
      document.querySelector('div.root').style.background = '#ffff';
    }
  }, []);

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      assignmentLineOfBusiness.current = 'DMS';
    }
    if (location.pathname.includes('mobile-driver-solutions')) {
      assignmentLineOfBusiness.current = 'MDS';
    }
  }, [])

  useEffect(() => {
    if (expenseId !== '' || expenseId !== null) {
      getId();
    }
  }, [])

  useEffect(() => {
    if (Object.keys(clientMenu).length > 0 && form?.lob === '') {
      getAssignmentLineOfBusinessItems();
    }
  }, [clientMenu, form?.lob])

  useEffect(() => {
    if (uploadError) {
      // eslint-disable-next-line no-undef
      alert(uploadError);
      setUploadError(null);
    }
  }, [uploadError]);

  useEffect(
    () => () => {
      dispatch(resetData());
    },
    [],
  );

  const requiredReceiptFields = [
    'expenseItemAirLineId',
    'expenseItemDepartureAirPortId',
    'expenseItemArrivalAirPortId',
    'flightDate',
    'paidThrough',
    'markup',
    'receiptAmount'
  ];

  const checkReceiptsErrors = () => {
    const allErrors = [...receiptsErrors];

    allErrors.forEach((receiptErrors, id) => {
      const receiptErrorsCopy = {
        ...receiptErrors,
      };
      requiredReceiptFields.forEach(field => {
        if (receipts?.[id][field]) {
          delete receiptErrorsCopy[field];
          if (!Object.keys(receiptErrorsCopy).length) {
            allErrors.splice(id, 1);
          } else {
            allErrors[id] = receiptErrorsCopy;
          }
        }
      });

      setReceiptsErrors(allErrors);
    });
  };

  useEffect(() => {
    checkReceiptsErrors();
  }, [receipts]);

  useBeforeunload(event => {
    if (hasChanges) {
      event.preventDefault();
    }
  });

  const setReceiptOptions = () => {
    if (formData?.airlines?.length && formData?.airports?.length && Object.keys(receipts?.[0]).length) {
      const receiptsCopy = [...receipts];
      hasSetReceiptOptions.current = true;
      receiptsCopy.forEach(r => {
        const airline = formData?.airlines?.filter(anAirline => anAirline.id === r.expenseItemAirLineId)?.[0]?.name;
        r.selectedAirline = airline;
        const departureAirport = formData?.airports?.filter(
          airport => airport.id === r.expenseItemDepartureAirPortId,
        )?.[0]?.name;
        r.selectedDepartureAirport = departureAirport;

        const arrivalAirport = formData?.airports?.filter(airport => airport.id === r.expenseItemArrivalAirPortId)?.[0]
          ?.name;
        r.selectedArrivalAirport = arrivalAirport;
        r.flightDate = formatIsoToDate(r?.flightDate, 'YYYY-MM-DD');
      });
      setReceipts(receiptsCopy);
    }
  };

  useEffect(() => {
    if (hasSetReceiptOptions.current === false && location?.state?.expense) {
      if (receipts?.length) setReceiptOptions();
    }
  }, [formData?.airlines, formData?.airports, receipts]);

  //for rebill, check if expenses and receipts are passed
  useEffect(() => {
    if ((form, location?.state?.expense && location?.state.receipts && !hasSetRebillData.current)) {
      hasSetRebillData.current = true;
      setIsLoading(true);
      const rebilledExpense = {
        ...location?.state?.expense,
        originalExpenseId: location?.state?.expenseId,
        code: `${form?.code}R`,
        status: 'Unbilled',
        statementDate: dateToTimeZero(location?.state?.expense?.statementDate),
      };
      delete rebilledExpense.id;

      setTimeout(() => {
        setForm(rebilledExpense);
        setIsLoading(false);
      }, 5000);

      const receiptsCopy = [...(location?.state?.receipts || [])];
      receiptsCopy.forEach(receipt => {
        delete receipt.id;
        delete receipt.expenseExpenseItemId;
      });

      setReceipts(receiptsCopy);
    }
  }, [location?.state, form]);

  const bulkDeleteReceipts = async items => {
    Promise.all(items.map(item => deleteExpenseFile(item)));
  };

  const removeReceiptDocs = async () => {
    const receiptDocs = [];
    receipts?.forEach(r => {
      receiptDocs.push(r?.documentName);
    });
    if (receiptDocs.length) {
      await bulkDeleteReceipts(receiptDocs);
    }
  };

  const [receiptsErrors, setReceiptsErrors] = useState([]);

  const bulkCreateAirlineExpenseItem = async (items, route) => {
    Promise.all(items.map(item => createExpenseItem(item))).then(() => {
      setIsCreatingItems(false);
      if (!location?.state?.expenseId) {
        setTimeout(() => {
          navigate(route || -1);
        }, 1000);
      }
    });
  };

  const receiptsHaveFiles = () => {
    let haveFiles = true;
    receipts.forEach(r => {
      if (!r?.documentName) {
        haveFiles = false;
      }
    });
    return haveFiles;
  };

  const receiptsHaveRequiredFields = () => {
    let hasRequiredFields = true;
    receipts.forEach(r => {
      if (!r?.receiptAmount || r?.receiptAmount === 0.0) {
        hasRequiredFields = false;
      }
    });
    return hasRequiredFields;
  };

  const getReceiptStatus = () => {
    let status = form?.status;
    if (form?.status !== 'Non-billable' && form?.status !== 'Flat Fee Billing') {
      if (receiptsHaveFiles() && receiptsHaveRequiredFields() && receiptsHaveReceiptAmount(receipts)) {
        status = 'Ready to Bill';
      } else {
        status = 'Unbilled';
      }
    }

    return status;
  };

  const confirmCreateExpense = async ({ route = '', isFF = false }) => {
    setIsCreatingItems(true);
    const costCenter = location.pathname.includes('mobile')
      ? process.env.REACT_APP_MDS_COST_CENTER
      : process.env.REACT_APP_DMS_COST_CENTER;
    const expense = {
      id: expenseId,
      code: isFF ? 'XFFBA' : form?.code,
      driverId: form?.driverId,
      driverName: form?.driverName,
      assignmentId: form?.assignmentId,
      status: isFF ? 'Flat Fee Billing' : getReceiptStatus(),
      nonBillable: isFF || form?.nonBillable,
      customerId: form?.customerId,
      customerName: form?.customerName,
      branch: form?.branch,
      branchName: form?.branchName,
      costCenter: parseInt(costCenter),
      startDate: form?.startDate ? dateToTimeZero(form?.startDate) : null,
      endDate: form?.endDate ? dateToTimeZero(form?.endDate) : null,
      totalToBeBilled: totalToBeBilled,
      notes: form?.notes,
      auditKey: auditKey,
      flatFeeBilling: isFF,
      variance: -totalToBeBilled,
      lob: form?.lob,
      updatedBy: user.id,
    };
    if (location?.state?.expenseId) {
      expense.originalExpenseId = location?.state?.expenseId;
    }
    try {
      const resp = await createExpense(expense);
      if (resp) {
        const receiptsCopy = [...receipts];

        receiptsCopy.forEach(receipt => {
          if (!receipt?.receiptAmount) {
            receipt.receiptAmount = 0.0;
            receipt.expenseTotal = 0.0;
          }
          String(receipt.receiptAmount);
          delete receipt.createdAt;
          delete receipt.updatedAt;
          delete receipt.selectedAirline;
          delete receipt.selectedDepartureAirport;
          delete receipt.selectedArrivalAirport;
          receipt.expenseExpenseItemId = resp.id;
          receipt.flightDate = receipt?.flightDate ? dateToTimeZero(receipt?.flightDate) : null;
        });
        await bulkCreateAirlineExpenseItem(receiptsCopy, route);
        if (location?.state?.expenseId) {
          await updateExpense({
            id: location?.state?.expenseId,
            rebilledExpenseId: resp?.id,
          });
          navigate(assignmentLineOfBusinessItems.to);
        }
      }
      setIsCreatingItems(false);
    } catch (error) {
      setIsCreatingItems(false);
      console.log(error);

      // eslint-disable-next-line no-undef
      alert('There was an error, please try again later');
    }

    setIsCreatingItems(false);
  };

  const getAndSetFormData = async () => {
    const newFormData = await getFormData();
    if (newFormData) {
      setFormData(newFormData);
    }
  };

  const calculateTotal = () => {
    setTotalToBeBilled(
      parseFloat(receipts?.reduce((a, b) => parseFloat(a) + (parseFloat(b?.expenseTotal) || 0), 0)).toFixed(2),
    );
  };

  const getStartAndEndDate = () => {
    let startDate = '';
    let endDate = '';
    const emptyDate = '--';

    receipts.forEach(r => {
      if (!startDate.length && r?.flightDate) {
        startDate = r?.flightDate;
        endDate = r?.flightDate;
      } else {
        if (r?.flightDate && r?.flightDate < startDate) {
          startDate = r?.flightDate;
        }
        if (r?.flightDate && r?.flightDate > endDate) {
          endDate = r?.flightDate;
        }
      }
    });

    const emptyDates = {
      startDate: emptyDate,
      endDate: emptyDate,
    };
    const newDates = {
      startDate: formatIsoToDate(startDate),
      endDate: formatIsoToDate(endDate),
    };
    if (!!startDate && !!endDate) {
      setForm({
        ...form,
        ...newDates,
      });
    } else {
      setForm({
        ...form,
        ...emptyDates,
      });
    }
  };

  useEffect(() => {
    calculateTotal();
    getStartAndEndDate();
    if (Object.keys(receipts[0]) && !hasChanges) {
      setHasChanges(true);
    }
  }, [receipts]);

  useEffect(() => {
    calculateAuditKey(form, totalToBeBilled, setAuditKey);
  }, [form]);

  const getAndSetUserInfo = async () => {
    const { email } = (await Auth.currentSession()).getIdToken().payload;
    const user = await getUserInfo(email);
    setCurrentUser(user);
  };

  useEffect(() => {
    if (assignmentLineOfBusiness.current !== '' && form?.lob === '') {
      setForm({
        ...form,
        lob: assignmentLineOfBusiness.current
      })
    }
  }, [form])

  useEffect(() => {
    getAndSetFormData();
    getAndSetUserInfo();
  }, []);

  return (
    <div>
      <SubHeader
        text="Expenses"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Expenses?"
        emailAddress={process.env.REACT_APP_EXPENSE_CONTACT_EMAIL}
      />
      <Breadcrumbs
        leftTitle={assignmentLineOfBusinessItems.text}
        rightTitle="Add Airline"
        route={assignmentLineOfBusinessItems.to}
        color={assignmentLineOfBusinessItems.lob === 'DMS' && '#ffff'}
      />
      <ExpensesWindow
        windowContent={
          <FormContent
            id={expenseId}
            hasChanges={hasChanges}
            setShowCancelDialog={setShowCancelDialog}
            setHasChanges={setHasChanges}
            setForm={setForm}
            form={form}
            setSaveDisabled={setSaveDisabled}
            confirmCreateExpense={confirmCreateExpense}
            receiptsHaveFiles={receiptsHaveFiles}
            setShowReceiptFilesDialog={setShowReceiptFilesDialog}
            setReceipts={setReceipts}
            receipts={receipts}
            totalToBeBilled={totalToBeBilled}
            auditKey={auditKey}
            isCreatingItems={isCreatingItems}
            isLoading={isLoading}
            saveDisabled={saveDisabled}
            receiptsErrors={receiptsErrors}
            setUploading={setUploading}
            setUploadError={setUploadError}
            formHasChanged={formHasChanged}
            checkClientFlatFee={checkClientFlatFee}
            setShowFFDialog={setShowFFDialog}
            setShowNonFFDialog={setShowNonFFDialog}
            requiredReceiptFields={requiredReceiptFields}
            setReceiptsErrors={setReceiptsErrors}
            formData={formData}
            deleteExpenseFile={deleteExpenseFile}
            currentUser={currentUser}
            assignmentLineOfBusinessItems={assignmentLineOfBusinessItems}
          />
        }
        windowTitle="Add Airline"
      />
      <ModalConfirmation
        title="Cancel?"
        body="Changes you made will be lost"
        primaryButton
        primaryButtonTitle="Stay on Page"
        optionalClose
        secondaryButtonTitle="Leave Page"
        onClick={() => {
          setShowCancelDialog(false);
        }}
        secondaryOnClick={async () => {
          setShowCancelDialog(false);
          await removeReceiptDocs();
          navigate(assignmentLineOfBusinessItems.to);
        }}
        isOpen={showCancelDialog}
      />
      <ModalConfirmation
        title="Please Note!"
        body="Please attach the receipt when available or this expense will not be billed"
        primaryButton
        primaryButtonTitle="OK"
        onClick={async () => {
          setShowReceiptFilesDialog(false);
          await confirmCreateExpense({
            isFF: form?.flatFeeBilling,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        isOpen={showReceiptFilesDialog}
      />

      <FlatFeeModal
        showDialog={showFFDialog}
        clientIsFlatFee
        setShowDialog={setShowFFDialog}
        ffAction={async () => {
          await confirmCreateExpense({
            isFF: true,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        billableAction={async () => {
          await confirmCreateExpense({
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        cancelAction={async () => { }}
      />
      <FlatFeeModal
        showDialog={showNonFFDialog}
        clientIsFlatFee={false}
        setShowDialog={setShowNonFFDialog}
        ffAction={async () => {
          await confirmCreateExpense({
            isFF: true,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        billableAction={async () => {
          await confirmCreateExpense({
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        cancelAction={() => { }}
      />
      {uploading && <div className="overlay-box" />}
    </div>
  );
};

export default Airline;
