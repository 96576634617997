import { createSlice } from '@reduxjs/toolkit';

const standardDocumentsSlice = createSlice({
    name: 'standardDocuments',
    initialState: {
        listData: []
    },
    reducers: {
        setList: (state, { payload }) => {
            state.listData = payload;
        },
    },
});

export default standardDocumentsSlice.reducer;

export const { setList } = standardDocumentsSlice.actions;

