import React from 'react';

const QuoteField = ({ label, labelClass, value }) => {
  return (
    <p>
      <span className={labelClass}>{label}</span>
      <span className="p-1 text-muted">{value}</span>
    </p>
  );
};

export default QuoteField;
