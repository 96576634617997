import React from 'react';
import {
  MdNoteAdd, MdBuildCircle, MdFolderShared, MdForwardToInbox, MdHourglassTop, MdQuestionAnswer,
  MdCheckCircleOutline, MdHighlightOff, MdListAlt, MdFileCopy, MdManageAccounts
} from "react-icons/md";
import './styles.scoped.css';

const DriverQualificationMenuItem = ({ text, icon, onPress }) => {
  const getIcon = () => {
    switch (icon) {
      case 'note_add':
        return <MdNoteAdd size={36} color='#da0f2e' />;
      case 'build_circle':
        return <MdBuildCircle size={36} color='#da0f2e' />;
      case 'folder_shared':
        return <MdFolderShared size={36} color='#da0f2e' />;
      case 'forward_to_inbox':
        return <MdForwardToInbox size={36} color='#da0f2e' />;
      case 'hourglass_top':
        return <MdHourglassTop size={36} color='#da0f2e' />;
      case 'question_answer':
        return <MdQuestionAnswer size={36} color='#da0f2e' />;
      case 'check_circle_outline':
        return <MdCheckCircleOutline size={36} color='#da0f2e' />;
      case 'highlight_off':
        return <MdHighlightOff size={36} color='#da0f2e' />;
      case 'list_alt':
        return <MdListAlt size={36} color='#da0f2e' />;
      case 'file_copy':
        return <MdFileCopy size={36} color='#da0f2e' />;
      case 'manage_accounts':
        return <MdManageAccounts size={36} color='#da0f2e' />;
      default:
        break;
    }
  }

  return (
    <div className="menu-item" key={text} onClick={onPress}>
      {getIcon()}
      <span className="menu-text">{text}</span>
    </div>
  )
};

export default DriverQualificationMenuItem;
