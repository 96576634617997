import React, { useMemo } from 'react';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { money } from '../../../utils';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import './styles.scoped.css';
import { TablePagination } from '../../../components';

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const AMCTable = ({ data, loading, isAMC }) => {
  const columnClassName = 'align-middle text-nowrap';
  const memoData = useMemo(() => data, [data]);

  const amcColumns = useMemo(
    () =>
      isAMC
        ? [
            {
              Header: 'AMC Code',
              accessor: 'AccountMasterCode',
              className: columnClassName,
            },
            {
              Header: 'BillTo Number',
              accessor: 'Customer_BillToNumber',
              className: columnClassName,
            },
            {
              Header: 'AsgnBranch Code Detail',
              accessor: 'AsgnBranch_Code_Detail',
              className: columnClassName,
            },
            {
              Header: 'Invoice Number',
              accessor: 'Invoice_Number',
              className: columnClassName,
            },
            {
              Header: 'Invoice Date',
              accessor: 'Invoice_Date',
              className: columnClassName,
            },
            {
              Header: 'Check Number',
              accessor: 'Check_Number',
              className: columnClassName,
            },
            {
              Header: 'Check Date',
              accessor: 'Check_Date',
              className: columnClassName,
            },

            {
              Header: 'Void',
              accessor: 'void',
              className: columnClassName,
            },

            {
              Header: 'Invoice Type',
              accessor: 'Invoice_Type',
              className: columnClassName,
            },
            {
              Header: 'Transaction Type',
              accessor: 'Transaction_Type',
              className: columnClassName,
            },
            {
              Header: 'Pay Code Type',
              accessor: 'Pay_Code_Type',
              className: columnClassName,
            },
            {
              Header: 'Bill Hours',
              accessor: 'Bill_Hours',
              className: columnClassName,
            },
            {
              Header: 'Bill Rate',
              accessor: 'Bill_Rate',
              className: columnClassName,
            },
            {
              Header: 'Pay Hours',
              accessor: 'Pay_Hours',
              className: columnClassName,
            },
            {
              Header: 'Pay Rate',
              accessor: 'Pay_Rate',
              className: columnClassName,
            },
            {
              Header: 'US FICA Burden',
              accessor: 'US_FICA_Burden',
              className: columnClassName,
            },
            {
              Header: 'Job ID',
              accessor: 'Job_Id',
              className: columnClassName,
            },
            {
              Header: 'ASgn PC',
              accessor: 'ASS_PC',
              className: columnClassName,
            },
            {
              Header: 'Period Start Date',
              accessor: 'Period_StartDate',
              className: columnClassName,
            },
            {
              Header: 'Period End Date',
              accessor: 'Period_EndDate',
              className: columnClassName,
            },
            {
              Header: 'Job Position',
              accessor: 'Job_Position',
              className: columnClassName,
            },
            {
              Header: 'PO Number',
              accessor: 'PO_Number',
              className: columnClassName,
            },
            {
              Header: 'BillTo Name',
              accessor: 'T_INVOICE_COMPANY_NAME',
              className: columnClassName,
            },
            {
              Header: 'Applicant Name',
              accessor: 'Applicant_Name',
              className: columnClassName,
            },
            {
              Header: 'AsgnLOB Detail',
              accessor: 'AsgnLineOfBusiness_Detail',
              className: columnClassName,
            },
            {
              Header: 'Tax Amount',
              accessor: 'TaxAmt',
              className: columnClassName,
            },
            {
              Header: 'Discount Amount',
              accessor: 'DisAmt',
              className: columnClassName,
            },
            {
              Header: 'Bill Amount',
              accessor: 'Bill_Amount',
              className: columnClassName,
            },
            {
              Header: 'Pay Amount',
              accessor: 'Pay_Amount',
              className: columnClassName,
            },
            {
              Header: 'Applicant Empl Number',
              accessor: 'Applicant_Id',
              className: columnClassName,
            },
            {
              Header: 'Branch Name',
              accessor: 'AsgnBranch_Name',
              className: columnClassName,
            },
            {
              Header: 'Bill Department',
              accessor: 'Customer_BillToDepartment',
              className: columnClassName,
            },

            {
              Header: 'Customer ID',
              accessor: 'CUST_ID',
              className: columnClassName,
            },
            {
              Header: 'Assgn Start Date',
              accessor: 'ASS_STARTDATE',
              className: columnClassName,
            },
            {
              Header: 'Assgn End Date',
              accessor: 'ASS_ENDDATE',
              className: columnClassName,
            },
            {
              Header: 'Customer City',
              accessor: 'CUST_CITY',
              className: columnClassName,
            },
            {
              Header: 'Customer State',
              accessor: 'CUST_STATE',
              className: columnClassName,
            },
            {
              Header: 'Customer ZIP',
              accessor: 'CUST_ZIP',
              className: columnClassName,
            },
            {
              Header: 'Customer SPC',
              accessor: 'CUST_SALESPC',
              className: columnClassName,
            },
          ]
        : [
            {
              Header: 'Branch',
              accessor: 'Branch',
              className: columnClassName,
            },
            {
              Header: 'Branch Name',
              accessor: 'BranchName',
              className: columnClassName,
            },
            {
              Header: 'Customer Name',
              accessor: 'CustomerName',
              className: columnClassName,
            },
            {
              Header: 'Customer Number',
              accessor: 'CustomerNumber',
              className: columnClassName,
            },

            {
              Header: 'Year',
              accessor: 'YR',
              className: columnClassName,
            },
            {
              Header: 'January',
              accessor: 'M1',
              className: columnClassName,
              Cell: instance => money.format(instance.value),
            },
            {
              Header: 'February',
              accessor: 'M2',
              className: columnClassName,
              Cell: instance => money.format(instance.value),
            },
            {
              Header: 'March',
              accessor: 'M3',
              className: columnClassName,
              Cell: instance => money.format(instance.value),
            },
            {
              Header: 'April',
              accessor: 'M4',
              className: columnClassName,
              Cell: instance => money.format(instance.value),
            },
            {
              Header: 'May',
              accessor: 'M5',
              className: columnClassName,
              Cell: instance => money.format(instance.value),
            },
            {
              Header: 'June',
              accessor: 'M6',
              className: columnClassName,
              Cell: instance => money.format(instance.value),
            },
            {
              Header: 'July',
              accessor: 'M7',
              className: columnClassName,
              Cell: instance => money.format(instance.value),
            },
            {
              Header: 'August',
              accessor: 'M8',
              className: columnClassName,
              Cell: instance => money.format(instance.value),
            },
            {
              Header: 'September',
              accessor: 'M9',
              className: columnClassName,
              Cell: instance => money.format(instance.value),
            },
            {
              Header: 'October',
              accessor: 'M10',
              className: columnClassName,
              Cell: instance => money.format(instance.value),
            },
            {
              Header: 'November',
              accessor: 'M11',
              className: columnClassName,
              Cell: instance => money.format(instance.value),
            },
            {
              Header: 'December',
              accessor: 'M12',
              className: columnClassName,
              Cell: instance => money.format(instance.value),
            },
            {
              Header: 'Totals',
              accessor: 'Bill_Amount',
              className: columnClassName,
              Cell: instance => money.format(instance.value),
            },
          ],
    [memoData],
  );

  const tableInstance = useTable(
    {
      columns: amcColumns,
      data: memoData,
      initialState: {
        pageSize: 200,
        sortBy: [
          {
            id: 'CreatedAt',
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>
          {column.canSort
            ? getSortedIcon(column.isSorted, column.isSortedDesc)
            : null}
        </span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}
      >
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  return (
    <Container fluid style={{ marginBottom: '20px' }}>
      <Row className="align-items-center">
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading && <Spinner animation="border" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AMCTable;
