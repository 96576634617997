import React from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import './styles.scoped.css';

const SelectorList = ({ data = [], onSelect, value, onChange, disabled, ...rest }) => (
  <Dropdown className="drop" onSelect={onChange} {...rest}>
    <Dropdown.Toggle variant="custom" style={{ width: '100%', overflow: 'hidden' }} disabled={disabled}>
      {value}
    </Dropdown.Toggle>
    <Dropdown.Menu>
      {data.map(({ label, value }) => (
        <Dropdown.Item key={value} eventKey={value} onSelect={onSelect}>
          {label}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

export default SelectorList;
