import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Checkbox } from '@mui/material';
import { Container, Button, Spinner } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getOpenAssignments } from './operations';
import Excel from '../../../../assets/excel.png';
import { Breadcrumbs, ModalConfirmation, ReadyToBillTable, SubHeader } from '../../../../components';
import { formatIsoToDate, newDateFormatted } from '../../../../utils';
import { getExpensesCsvData } from '../../../../utils/utilities';
import { checkReadyToFlag, checkOptions, handleFlagChecked } from '../TransferToUltraStaff/transferUtils';

import './styles.scoped.css';

const ReadyToBillOpen = () => {
  const navigate = useNavigate();
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirmFlagging, setShowConfirmFlagging] = useState(false);
  const [isReadyToFlag, setIsReadyToFlag] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([false, false, false]);
  const { clientMenu, readyToBillCurrentIds } = useSelector(state => state.expenseEntry);
  const [selectedExpenses, setSelectedExpenses] = useState([]);

  const userId = useSelector(state => state.admin.user.id);
  const appAccess = useSelector(state => state.admin?.user?.appAccess);
  const [currentExpenseRole, setCurrentExpenseRole] = useState('');
  const assignmentLineOfBusiness = useRef('');
  const [assignmentLineOfBusinessItems, setAssignmentLineOfBusinessItems] = useState({});

  const getAssignmentLineOfBusinessItems = () => {
    clientMenu?.menuItems?.map(i => {
      if (location.pathname.includes(i.to)) {
        setAssignmentLineOfBusinessItems(i);
      }
    })
  };

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      document.querySelector('div.root').style.background = '#D0CED0';
      document.querySelector('span.wrap').style.background = '#ffff';
      document.querySelector('.left-title').style.color = '#ffff';
      document.querySelector('.right-title').style.color = '#66CCFF';
    }
    return () => {
      document.querySelector('div.root').style.background = '#ffff';
    }
  }, []);

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      assignmentLineOfBusiness.current = 'DMS';
    }
    if (location.pathname.includes('mobile-driver-solutions')) {
      assignmentLineOfBusiness.current = 'MDS';
    }
  }, [])

  useEffect(() => {
    const access = appAccess?.items?.filter(app => app.appId?.name === 'Expense Entry')?.[0];
    if (access && access.roleId.name) {
      setCurrentExpenseRole(access.roleId.name);
    }
  }, [appAccess]);

  const handleSelectExpense = expense => {
    setSelectedExpenses(prevSelected => {
      if (prevSelected.find(exp => exp.id === expense.id)) {
        return prevSelected.filter(exp => exp.id !== expense.id);
      }
      return [...prevSelected, expense];
    });
  };

  const handleSelectAll = () => {
    if (selectedExpenses.length === expenses.length) {
      setSelectedExpenses([]);
    } else {
      setSelectedExpenses([...expenses]);
    }
  };

  const handleDoneFlagging = async () => {
    if (currentExpenseRole === 'Expense Manager') {
      navigate(`${assignmentLineOfBusinessItems.to}/transfer-to-ultrastaff`);
    } else {
      navigate(assignmentLineOfBusinessItems.to);
    }
  };

  const bodyConfirmFlagging = (
    <div>
      <p>I have reviewed (please check the boxes)</p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '20px',
          alignItems: 'flex-start',
        }}>
        {checkOptions.map(item => (
          <div className="check-item">
            <Checkbox
              id={item.id}
              style={{
                color: '#da0f2e',
              }}
              className="checkbox"
              onChange={() => {
                const newCheckboxesValues = [...selectedCheckboxes];
                newCheckboxesValues[item.id] = !selectedCheckboxes[item.id];
                setSelectedCheckboxes(newCheckboxesValues);
              }}
              checked={selectedCheckboxes[item.id]}
              defaultValue={false}
            />
            <div> {item.text}</div>
          </div>
        ))}
      </div>
      <p style={{ marginTop: '15px' }}>And confirm the expenses are ready to send to Ultrastaff</p>
    </div>
  );

  useEffect(() => {
    checkReadyToFlag(selectedCheckboxes, setIsReadyToFlag);
  }, [selectedCheckboxes]);

  const formatExpenses = exp => {
    const formattedExpenses = exp.length ? [...exp] : [];
    if (formattedExpenses.length) {
      formattedExpenses?.forEach(e => {
        e.driverNameNumber = `${e?.driverName} - ${e?.driverId}`;
        e.customerNameNumber = `${e?.customerName} - ${e?.customerId}`;
        e.startDate = formatIsoToDate(e.startDate);
        e.createdAt = formatIsoToDate(e.createdAt);
      });
    }

    return formattedExpenses;
  };

  let expensesData = [];
  let prevToken = '';

  const getBatch = async (lob = assignmentLineOfBusiness.current, nextToken = null) => {
    if (prevToken !== nextToken) {
      prevToken = nextToken;

      const data = await getOpenAssignments(lob, nextToken);
      const items = data?.list;
      const token = data?.lastEvaluatedKey;
      if (items) {
        expensesData = expensesData.concat(items);
      }
      if (token) {
        getBatch(assignmentLineOfBusiness.current, token);
      } else {
        const formattedExpenses = formatExpenses(expensesData);

        setExpenses(formattedExpenses);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    if (assignmentLineOfBusiness) {
      getAssignmentLineOfBusinessItems();
      getBatch();
    }
  }, [assignmentLineOfBusiness.current]);

  const fileName = `expense-tracker-open-assignments-${newDateFormatted.toString()}.csv`;

  return (
    <>
      <SubHeader
        text="Expenses"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Expenses?"
        emailAddress={process.env.REACT_APP_EXPENSE_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>

        <Breadcrumbs
          leftTitle={assignmentLineOfBusinessItems.text}
          rightTitle="Ready to Bill - Open Timecards"
          route={assignmentLineOfBusinessItems.to}
          color={assignmentLineOfBusinessItems.lob === "DMS" && '#ffff'}
        />

        {expenses?.length && readyToBillCurrentIds?.length ? (
          <CSVLink filename={fileName} data={getExpensesCsvData(expenses, readyToBillCurrentIds)}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
        ) : null}
      </div>

      <Container className="wrap" fluid>
        {loading ? (
          <div className="h-100 d-flex justify-content-center p-5">
            <Spinner animation="border" />
          </div>
        ) : (
          <ReadyToBillTable
            data={expenses}
            isOpenAssignments
            handleSelectExpense={handleSelectExpense}
            handleSelectAll={handleSelectAll}
            selectedExpenses={selectedExpenses}
            assignmentLineOfBusinessItems={assignmentLineOfBusinessItems}
          />
        )}
      </Container>
      <div className="buttons-container">
        <div className="action-buttons-container">
          <Button className="button-text" style={{ marginRight: '20px' }} onClick={() => setShowConfirmFlagging(true)}>
            <span>FLAG CHECKED FOR ULTRASTAFF</span>
          </Button>
          <Button className="button-text" onClick={handleDoneFlagging}>
            <span>DONE FLAGGING</span>
          </Button>
        </div>
        <Button onClick={() => navigate(assignmentLineOfBusinessItems.to)} className="button-secondary">
          <span>CANCEL</span>
        </Button>
        <ModalConfirmation
          title="Confirmation"
          body={bodyConfirmFlagging}
          primaryButton={isReadyToFlag}
          primaryButtonTitle="Confirm"
          optionalClose
          secondaryButtonTitle="Cancel"
          onClick={async () => {
            await handleFlagChecked(selectedExpenses, navigate, userId);
            setSelectedExpenses([]);
            setSelectedCheckboxes([false, false, false]);
            setShowConfirmFlagging(false);
            await getBatch();
          }}
          secondaryOnClick={async () => {
            setSelectedCheckboxes([false, false, false]);
            setShowConfirmFlagging(false);
          }}
          isOpen={showConfirmFlagging}
        />
      </div>
    </>
  );
};

export default ReadyToBillOpen;
