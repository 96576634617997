import React from 'react';
import { Link } from 'react-router-dom';
import { faEye, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdEdit } from 'react-icons/md';
import dayjs from 'dayjs';
import {
  Col,
  Container,
  Row,
  Table
} from 'react-bootstrap';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import TableFilter from '../TableFilter';
import TablePagination from '../TablePagination';
import './styles.scoped.css';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

function getSortIcon(isSorted, isSortedDesc) {
  let iconProp = faSort;
  if (isSorted) {
    iconProp = isSortedDesc ? faSortDown : faSortUp;
  }
  return <FontAwesomeIcon icon={iconProp} className="ml-1 fa-sm" />;
}

export default function LOBPushNotificationsTable({
  data,
}) {
  const columnClassName = 'align-middle text-nowrap';
  const memoData = React.useMemo(() => data, [data]);
  const skipPageResetRef = React.useRef();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        className: columnClassName,
        Cell: instance => <>{`${dayjs.utc(instance?.row?.original?.createdAt).local().format('MM/DD/YYYY')}`}</>
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
        className: columnClassName,
      },
      {
        Header: 'LOB',
        accessor: 'lob',
        className: columnClassName,
      },
      {
        Header: 'Subject',
        accessor: 'subject',
        className: columnClassName,
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: columnClassName,
      },
      {
        Header: 'Approved By',
        accessor: 'approvedBy',
        className: columnClassName,
      },
      {
        Header: 'Sent On',
        accessor: 'sentOn',
        className: columnClassName,
        Cell: instance => <>{instance?.value !== null ? `${dayjs.utc(instance?.row?.original?.sentOn).local().format('MM/DD/YYYY')}` : ''}</>
      },
      {
        accessor: 'Sent On',
        className: columnClassName,
        Cell: instance => <>
          {instance?.row?.original?.status === 'Sent' ?
            <Link to={`/push-notifications/lob-push-notifications/view-lob-push-notification/${instance?.row?.original?.id}`}>
              <FontAwesomeIcon icon={faEye} className="icon-color fa-md" />
            </Link>
            :
            <Link to={`/push-notifications/lob-push-notifications/edit-lob-push-notification/${instance?.row?.original?.id}`}>
              <MdEdit color="#da0f2e" className="fa-md" />
            </Link>}
        </>,
      },
    ],
    [])
    ;

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'createdAt',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setFilter,
    setGlobalFilter,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>{column.canSort ? getSortIcon(column.isSorted, column.isSortedDesc) : null}</span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}>
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  function filter(event, columnName) {
    const filterColumn = columns.find(column => column.Header === columnName);
    if (filterColumn) {
      setFilter(filterColumn.accessor, event.target.value);
    } else {
      setGlobalFilter(event.target.value);
    }
  }

  return (
    <Container fluid>
      <Row className='header'>
        <Col className="d-flex justify-content-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row className='table-row'>
        <Col className="table-body">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
        </Col>
      </Row>
    </Container >
  );
}
