import { Row, Form } from 'react-bootstrap';
import './styles.scoped.css';

export const FourCeroOneKForm = ({ form, handleChange }) => {
  return (
    <div className="filters-container">
      <span>
        Enter valid RunDate. Date must be a{' '}
        <span style={{ fontWeight: 700 }}>Monday</span> and will return data for
        the prior week's payroll period
      </span>
      <div
        className="filter-row"
        style={{
          marginTop: '30px',
        }}
      >
        <Form.Group
          as={Row}
          controlId="FromMondayDate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '160px' }}>
            <div>Monday Date </div>
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            type="date"
            name="MondayDate"
            value={form?.MondayDate}
            onChange={handleChange}
            onBlur={null}
          />
        </Form.Group>
      </div>
    </div>
  );
};
