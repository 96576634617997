import React, { useEffect, useMemo, useState } from 'react';

import { Form, Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ClientSearchTable from './ClientSearchTable';
import getCSVData from './csv';
import { ClientSearchForm } from './Form';
import Excel from '../../../assets/excel.png';
import { Breadcrumbs, EdgeReportingWindow, SubHeader, ReportLoader } from '../../../components';
import ErrorModal from '../../../components/ErrorModal';
import { formatIsoToDate, newDateFormatted } from '../../../utils';
import { userHasAccess, getReportData, removeReportData, getList, generateReport } from '../operations';

import './styles.scoped.css';

const CSForm = ({
  form,
  setForm,
  isLoadingClientStatuses,
  isLoadingContactStatuses,
  clientStatusOptions,
  contactStatusOptions,
  handleViewResults,
  navigate,
}) => {
  const handleChange = e => {
    const _form = { ...form, [e.target.name]: e.target.value };
    setForm(_form);
  };
  return (
    <Form
      noValidate
      onSubmit={() => { }}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <ClientSearchForm
        form={form}
        setForm={setForm}
        handleChange={handleChange}
        isLoadingClientStatuses={isLoadingClientStatuses}
        isLoadingContactStatuses={isLoadingContactStatuses}
        clientStatusOptions={clientStatusOptions}
        contactStatusOptions={contactStatusOptions}
      />

      <div className="buttons-row">
        <Button onClick={() => handleViewResults()} style={{ marginRight: '20px', width: '100px' }}>
          <span>VIEW</span>
        </Button>

        <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
          <span>CANCEL</span>
        </Button>
      </div>
    </Form>
  );
};

const ClientSearch = () => {
  const navigate = useNavigate();
  const {
    clientMenu: { menuItems },
  } = useSelector(state => state.edgeReporting);
  const { user } = useSelector(state => state.admin);
  const [data, setData] = useState([]);
  const [clientStatuses, setClientStatuses] = useState([]);
  const [contactStatuses, setContactStatuses] = useState([]);
  const [form, setForm] = useState({});
  const [loadingResults, setLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isLoadingClientStatuses, setIsLoadingClientStatuses] = useState(false);
  const [isLoadingContactStatuses, setIsLoadingContactStatuses] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (menuItems) {
      // eslint-disable-next-line no-undef
      if (!userHasAccess(user, menuItems.find(i => i.to === window.location.pathname)?.id)) navigate('/');
    }
  }, [menuItems]);

  const handleGetClientStatuses = async () => {
    setIsLoadingClientStatuses(true);
    const cliS = await getList('client-status');
    setClientStatuses(cliS);
    setIsLoadingClientStatuses(false);
  };

  const handleGetContactStatuses = async () => {
    setIsLoadingContactStatuses(true);
    const conS = await getList('client-contact-status');
    setContactStatuses(conS);
    setIsLoadingContactStatuses(false);
  };

  useEffect(() => {
    handleGetClientStatuses();
    handleGetContactStatuses();
  }, []);

  const clientStatusOptions = useMemo(
    () =>
      clientStatuses.map(({ REF_CODE, REF_DESCRIPTION }) => ({
        label: REF_DESCRIPTION,
        value: REF_CODE,
      })),
    [clientStatuses],
  );

  const contactStatusOptions = useMemo(
    () =>
      contactStatuses.map(({ REF_CODE, REF_DESCRIPTION }) => ({
        label: REF_DESCRIPTION,
        value: REF_CODE,
      })),
    [contactStatuses],
  );

  const fileName = `edge-reporting-client-search-${newDateFormatted.toString()}.csv`;

  const handleViewResults = async () => {
    setLoadingResults(true);
    Promise.all([
      generateReport({
        endpoint: 'client-search-report',
        payload: {
          LastBilledFromdate: form?.LastBilledFromdate ? formatIsoToDate(form?.LastBilledFromdate, 'YYYY-MM-DD') : '',
          LastBilledTodate: form?.LastBilledTodate ? formatIsoToDate(form?.LastBilledTodate, 'YYYY-MM-DD') : '',
          CustomerStatus: form?.CustomerStatus ?? '',
          ContactStatus: form?.ContactStatus ?? '',
          CustomerNumber: form?.CustomerNumber ?? '',
          CustomerName: form?.CustomerName ?? '',
        }
      })
    ])
      .then(values => {
        values.forEach((response, index) => {
          if (response.location) {
            getReportData(response.location).then(async reportData => {
              if (reportData?.length) {
                await removeReportData(response.location);
                if (index === 0) {
                  setData(reportData);
                } else {
                  setDataExport(reportData);
                }
                setShowResults(true);
              }
            });
          } else if (response.statusCode !== 404) {
            if (index === 0) {
              setData(response);
            } else {
              setDataExport(response);
            }
            setShowResults(true);
          } else {
            // eslint-disable-next-line no-undef
            alert('No data found matching your criteria.');
          }
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoadingResults(false);
      });
  }
  return (
    <>
      <SubHeader
        text="EDGE Reporting"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Client Search report?"
        emailAddress={process.env.REACT_APP_EDGE_REPORTING_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle="Reporting Home"
          middleTitle={showResults && 'Client Search'}
          midItemAction={() => setShowResults(false)}
          rightTitle={showResults ? 'Viewing Report' : 'Client Search'}
          route="/edge-reporting"
        />
        {!!data?.length && showResults && (
          <CSVLink filename={fileName} data={getCSVData(data)}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
        )}
      </div>
      {loadingResults && <ReportLoader didAbort={() => setLoadingResults(false)} />}
      {showError && <ErrorModal dismiss={() => setShowError(false)} error="No data found for report." />}
      {showResults ? (
        <>
          <ClientSearchTable data={data} loading={loadingResults} />
          <div className="buttons-row">
            <Button onClick={() => setShowResults(false)} style={{ marginRight: '20px', width: '100px' }}>
              <span>BACK</span>
            </Button>

            <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
              <span>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <EdgeReportingWindow
          containerStyle={{
            border: '2px solid #343a40',
          }}
          windowContent={CSForm({
            form,
            setForm,
            isLoadingClientStatuses,
            isLoadingContactStatuses,
            clientStatusOptions,
            contactStatusOptions,
            handleViewResults,
            navigate,
          })}
          windowTitle="Criteria"
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )}
    </>
  );
};

export default ClientSearch;
