import React from 'react';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import './styles.scoped.css';
import { TablePagination } from '../../../components';

const columnClassName = 'align-middle text-nowrap';

const casColumns = [
  {
    Header: 'Company Number',
    accessor: 'CompanyName',
    className: columnClassName,
    Cell: <>100</>
  },
  {
    Header: 'Employee Number #',
    accessor: 'EmployeeNumber',
    className: columnClassName,
  },
  {
    Header: 'Last Name',
    accessor: 'LastName',
    className: columnClassName,
  },
  {
    Header: 'First Name',
    accessor: 'FirstName',
    className: columnClassName,
  },
  {
    Header: 'Middle Name',
    accessor: 'MiddleName',
    className: columnClassName,
  },
  {
    Header: 'Suffix',
    accessor: 'Suffix',
    className: columnClassName,
  },
  {
    Header: 'Address Line 1',
    accessor: 'APPL_ADDRESS1',
    className: columnClassName,
  },
  {
    Header: 'Address Line 2',
    accessor: 'APPL_ADDRESS2',
    className: columnClassName,
  },
  {
    Header: 'City',
    accessor: 'APPL_CITY',
    className: columnClassName,
  },
  {
    Header: 'State',
    accessor: 'APPL_STATE',
    className: columnClassName,
  },
  {
    Header: 'ZIP',
    accessor: 'APPL_POSTCODE',
    className: columnClassName,
  },
  {
    Header: 'Email',
    accessor: 'EmailAddress',
    className: columnClassName,
  },
  {
    Header: 'Home Phone',
    accessor: 'NUM_HOMEPHONE',
    className: columnClassName,
  },
  {
    Header: 'Work Phone',
    accessor: 'NUM_WORKPHONE',
    className: columnClassName,
  },
  {
    Header: 'Extension',
    accessor: 'NUM_WORKEXTEN',
    className: columnClassName,
  },
  {
    Header: 'SMS',
    accessor: 'SMS',
    className: columnClassName,
  },
  {
    Header: 'Email',
    accessor: 'Email',
    className: columnClassName,
  },
  {
    Header: 'Hours Worked',
    accessor: 'HOURS_WORKED',
    className: columnClassName,
  },
  {
    Header: 'Hire Date',
    accessor: 'HireDate',
    className: columnClassName,
  },
  {
    Header: 'Termination Date',
    accessor: 'TerminationDate',
    className: columnClassName,
  },
  {
    Header: 'Test Code',
    accessor: 'Test_Code',
    className: columnClassName,
  },
  {
    Header: 'Completed Date',
    accessor: 'Test_Date',
    className: columnClassName,
  }, {
    Header: 'Expiration Date',
    accessor: 'Test_Expiration_Date',
    className: columnClassName,
  },
];

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const DriverSexualHarassmentTrainingTable = ({ data, loading }) => {
  const tableInstance = useTable(
    {
      columns: casColumns,
      data,
      initialState: {
        pageSize: 200,
        sortBy: [
          {
            id: 'CreatedAt',
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>
          {column.canSort
            ? getSortedIcon(column.isSorted, column.isSortedDesc)
            : null}
        </span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}
      >
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading && <Spinner animation="border" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DriverSexualHarassmentTrainingTable;
