import { formatIsoToDate } from '../../utils';

const headers = [
  { name: 'User', accessor: 'id' },
  { name: 'User_Name', accessor: 'name' },
  { name: 'Status', accessor: 'status' },
  { name: 'Created', accessor: 'createdAt', isDate: true },
  { name: 'Last_Login', accessor: 'lastLogin', isDate: true },
  { name: 'Application', accessor: 'appAccess' },
  { name: 'Role(s)', accessor: 'appAccess' },
];

const getCSVData = (data = []) => {
  const csvData = [headers.map(({ name }) => name)];

  for (let i = 0; i < data.length; i++) {

    // Users with no access
    if (data[i].appAccess.items.length == 0) {
      let dataRow = [];
      for (let k = 0; k < headers.length; k++) {
        if (headers[k].name === 'Application') {
          dataRow.push('None');
        } else if (headers[k].name === 'Role(s)') {
          dataRow.push('None');
        } else if (headers[k].isDate) {
          dataRow.push(formatIsoToDate(data[i][headers[k].accessor]));
        } else {
          dataRow.push(data[i][headers[k].accessor]);
        }
      }
      csvData.push(dataRow);

    }

    for (let j = 0; j < data[i].appAccess.items.length; j++) {
      let dataRow = [];
      for (let k = 0; k < headers.length; k++) {
        if (headers[k].name === 'Application') {
          dataRow.push(data[i].appAccess.items[j].appId.name);
        } else if (headers[k].name === 'Role(s)') {
          dataRow.push(data[i].appAccess.items[j].roleId.name);
        } else if (headers[k].isDate) {
          dataRow.push(formatIsoToDate(data[i][headers[k].accessor]));
        } else {
          dataRow.push(data[i][headers[k].accessor]);
        }
      }
      csvData.push(dataRow);
    }
  }
  return csvData;
};

export default getCSVData;
