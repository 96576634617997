import React, { useEffect, useState } from 'react';

const PasswordRules = ({ password, newPassword, passwordCharCount, passwordMatch, setInvalidPassword }) => {
    const [upperCaseLetterError, setUpperCaseLetterError] = useState(false);
    const [lowerCaseLetterError, setLowerCaseError] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasSpecialChar, setSpecialChar] = useState(false);
    const [hasWhiteSpace, setHasWhiteSpace] = useState(false);

    const validatePassword = (password, passwordMatch, newPassword) => {
        setUpperCaseLetterError(/[A-Z]/.test(password));
        setLowerCaseError(/[a-z]/.test(password));
        setHasNumber(/\d/.test(password));
        setSpecialChar(/[!@#$%^&*]/.test(password));
        setHasWhiteSpace(/\s/g.test(password));

        setInvalidPassword(
            /[A-Z]/.test(password) &&
            /[a-z]/.test(password) &&
            /\d/.test(password) &&
            /[!@#$%^&*]/.test(password) &&
            !/\s/g.test(password) &&
            password &&
            password === newPassword &&
            passwordMatch,
            (passwordCharCount > 8),
        );
    };

    useEffect(() => {
        validatePassword(password, passwordMatch, newPassword);
    }, [password, newPassword, passwordMatch])

    return (
        <>
            <div>
                {lowerCaseLetterError ? (
                    <span className="text-success">&#x2713; </span>
                ) : (
                    <span className="text-danger">&#x58; </span>
                )}
                <span className={`forgot-password-error ${lowerCaseLetterError ? 'text-success' : 'text-danger'}`}>
                    Password must contain a lower case letter
                    <br />
                </span>
            </div>
            <div>
                {upperCaseLetterError ? (
                    <span className="text-success">&#x2713; </span>
                ) : (
                    <span className="text-danger">&#x58; </span>
                )}
                <span className={`forgot-password-error ${upperCaseLetterError ? 'text-success' : 'text-danger'}`}>
                    Password must contain a upper case letter
                    <br />
                </span>
            </div>
            <div>
                {hasNumber ? (
                    <span className="text-success">&#x2713; </span>
                ) : (
                    <span className="text-danger">&#x58; </span>
                )}
                <span className={`forgot-password-error ${hasNumber ? 'text-success' : 'text-danger'}`}>
                    Password must contain a number
                    <br />
                </span>
            </div>
            <div>
                {passwordCharCount > 7 ? (
                    <span className="text-success">&#x2713; </span>
                ) : (
                    <span className="text-danger">&#x58; </span>
                )}
                <span className={`forgot-password-error ${passwordCharCount > 7 ? 'text-success' : 'text-danger'}`}>
                    Password must contain at least 8 characters
                    <br />
                </span>
            </div>
            <div>
                {passwordMatch ? (
                    <span className="text-success">&#x2713; </span>
                ) : (
                    <span className="text-danger">&#x58; </span>
                )}
                <span
                    className={`forgot-password-error ${passwordMatch ? 'text-success' : 'text-danger'
                        }`}>
                    Password must match
                    <br />
                </span>
            </div>
            <div>
                {hasSpecialChar ? (
                    <span className="text-success">&#x2713; </span>
                ) : (
                    <span className="text-danger ">&#x58; </span>
                )}
                <span className={`forgot-password-error ${hasSpecialChar ? 'text-success' : 'text-danger'}`}>
                    Password must contain a special character or a space
                </span>
            </div>
            <div>
                {hasWhiteSpace ? (
                    <span className="text-danger">&#x58; </span>
                ) : (
                    <span className="text-success">&#x2713; </span>
                )}
                <span className={`forgot-password-error ${hasWhiteSpace ? 'text-danger' : 'text-success'}`}>
                    Password must not contain a leading or trailing space
                    <br />
                </span>
            </div>
        </>
    )
}

export default PasswordRules;