/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listExpensesReadyToBillOpenAssignments = /* GraphQL */ `
  query ListExpensesReadyToBillOpenAssignments(
    $limit: Int
    $lob: String
    $lastEvaluatedKey: String
  ) {
    listExpensesReadyToBillOpenAssignments(
      limit: $limit
      lob: $lob
      lastEvaluatedKey: $lastEvaluatedKey
    ) {
      list {
        id
        createdAt
        code
        driverId
        driverName
        assignmentId
        customerId
        customerName
        branch
        costCenter
        startDate
        endDate
        inv
        payType
        payAmt
        billUnits
        ok
        payWeekEnding
        totalToBeBilled
        totalReceiptAmount
        documentName
        documentSize
        expenseItem {
          documentName
          documentSize
          __typename
        }
        __typename
      }
      lastEvaluatedKey
      __typename
    }
  }
`;
export const listExpensesReadyToBillClosedAssignments = /* GraphQL */ `
  query ListExpensesReadyToBillClosedAssignments(
    $limit: Int
    $lob: String
    $lastEvaluatedKey: String
  ) {
    listExpensesReadyToBillClosedAssignments(
      limit: $limit
      lob: $lob
      lastEvaluatedKey: $lastEvaluatedKey
    ) {
      list {
        id
        createdAt
        code
        driverId
        driverName
        assignmentId
        customerId
        customerName
        branch
        costCenter
        startDate
        endDate
        inv
        payType
        payAmt
        billUnits
        ok
        payWeekEnding
        totalToBeBilled
        totalReceiptAmount
        documentName
        documentSize
        expenseItem {
          documentName
          documentSize
          __typename
        }
        __typename
      }
      lastEvaluatedKey
      __typename
    }
  }
`;
export const listExpensesReadyToBillTransferToUltraStaff = /* GraphQL */ `
  query ListExpensesReadyToBillTransferToUltraStaff(
    $limit: Int
    $lob: String
    $driverId: Int
    $assignmentId: Int
    $code: String
    $customerId: Int
    $startDate: AWSDateTime
    $endDate: AWSDateTime
    $lastEvaluatedKey: String
  ) {
    listExpensesReadyToBillTransferToUltraStaff(
      limit: $limit
      lob: $lob
      driverId: $driverId
      assignmentId: $assignmentId
      code: $code
      customerId: $customerId
      startDate: $startDate
      endDate: $endDate
      lastEvaluatedKey: $lastEvaluatedKey
    ) {
      list {
        id
        createdAt
        code
        driverId
        driverName
        assignmentId
        customerId
        customerName
        branch
        costCenter
        startDate
        endDate
        inv
        payType
        payAmt
        billUnits
        ok
        payWeekEnding
        totalToBeBilled
        totalReceiptAmount
        documentName
        documentSize
        expenseItem {
          documentName
          documentSize
          __typename
        }
        __typename
      }
      lastEvaluatedKey
      __typename
    }
  }
`;
export const report = /* GraphQL */ `
  query Report($input: ReportInput!) {
    report(input: $input) {
      id
      status
      statusCode
      statusDescription
      location
      __typename
    }
  }
`;
export const listClientDriverQualificationsByStatus = /* GraphQL */ `
  query ListClientDriverQualificationsByStatus(
    $limit: Int
    $lastEvaluatedKey: String
    $userId: String
    $status: String
  ) {
    listClientDriverQualificationsByStatus(
      limit: $limit
      lastEvaluatedKey: $lastEvaluatedKey
      userId: $userId
      status: $status
    ) {
      list {
        id
        driverId
        driverName
        locationCity
        locationState
        status
        dqOwnerFullName
        dqClientApproverUserFullName
        name
        createdAt
        updatedAt
        __typename
      }
      lastEvaluatedKey
      __typename
    }
  }
`;
export const listExpenseSearch = /* GraphQL */ `
  query ListExpenseSearch(
    $limit: Int
    $lob: String
    $status: String
    $code: String
    $branch: Int
    $costCenter: Int
    $customerId: Int
    $driverId: Int
    $notes: String
    $assignmentId: Int
    $endDate: String
    $createdAt: String
    $payWeekEnding: String
    $documentName: String
    $expenseItemHotelId: String
    $expenseItemHotelStateId: String
    $expenseItemHotelCityId: String
    $expenseItemAirLineId: String
    $expenseItemCarRentalCompanyId: String
    $lastEvaluatedKey: String
  ) {
    listExpenseSearch(
      limit: $limit
      lob: $lob
      status: $status
      code: $code
      branch: $branch
      costCenter: $costCenter
      customerId: $customerId
      driverId: $driverId
      notes: $notes
      assignmentId: $assignmentId
      endDate: $endDate
      createdAt: $createdAt
      payWeekEnding: $payWeekEnding
      documentName: $documentName
      expenseItemHotelId: $expenseItemHotelId
      expenseItemHotelStateId: $expenseItemHotelStateId
      expenseItemHotelCityId: $expenseItemHotelCityId
      expenseItemAirLineId: $expenseItemAirLineId
      expenseItemCarRentalCompanyId: $expenseItemCarRentalCompanyId
      lastEvaluatedKey: $lastEvaluatedKey
    ) {
      list {
        id
        createdAt
        code
        driverId
        driverName
        assignmentId
        customerId
        customerName
        branch
        costCenter
        startDate
        endDate
        invoiceDate
        payWeekEnding
        totalToBeBilled
        totalReceiptAmount
        status
        updatedAt
        statementDate
        expenseReportAppropriationNbr
        variance
        nonBillable
        invoiceNumber
        totalProjectedAmount
        expenseItem {
          projectedAmount
          dailyCharge
          projectedDays
          paidThrough
          __typename
        }
        __typename
      }
      lastEvaluatedKey
      __typename
    }
  }
`;
export const getDriversCountForPushNotification = /* GraphQL */ `
  query GetDriversCountForPushNotification(
    $type: String
    $status: String
    $lob: String
    $branch: String
    $recipientStatus: String
  ) {
    getDriversCountForPushNotification(
      type: $type
      status: $status
      lob: $lob
      branch: $branch
      recipientStatus: $recipientStatus
    ) {
      responseCode
      responseMessage
      driverCount
      __typename
    }
  }
`;
export const verifyRoute = /* GraphQL */ `
  query VerifyRoute($route: String, $roleId: Int) {
    verifyRoute(route: $route, roleId: $roleId) {
      authorized
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      customerId
      status
      type
      lastLogin
      clientName
      updateDriverQualifications
      StandardDocuments {
        items {
          id
          for
          forName
          category
          type
          customerId
          driverId
          documentName
          accountMasterCode
          createdAt
          updatedAt
          userStandardDocumentsId
          __typename
        }
        nextToken
        __typename
      }
      Audit {
        items {
          id
          event
          eventTime
          objectName
          objectId
          objectData
          createdAt
          updatedAt
          userAuditId
          __typename
        }
        nextToken
        __typename
      }
      appAccess {
        items {
          id
          createdAt
          updatedAt
          userAppAccessId
          applicationUserAppAccessId
          applicationRoleUserAppAccessId
          __typename
        }
        nextToken
        __typename
      }
      cellPhone
      passwordChangeDate
      preferredMFA
      lastResetMFA
      customers {
        items {
          id
          customerId
          active
          createdAt
          updatedAt
          userCustomersId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        customerId
        status
        type
        lastLogin
        clientName
        updateDriverQualifications
        StandardDocuments {
          nextToken
          __typename
        }
        Audit {
          nextToken
          __typename
        }
        appAccess {
          nextToken
          __typename
        }
        cellPhone
        passwordChangeDate
        preferredMFA
        lastResetMFA
        customers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserCustomer = /* GraphQL */ `
  query GetUserCustomer($id: ID!) {
    getUserCustomer(id: $id) {
      id
      customerId
      active
      createdAt
      updatedAt
      userCustomersId
      __typename
    }
  }
`;
export const listUserCustomers = /* GraphQL */ `
  query ListUserCustomers(
    $filter: ModelUserCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerId
        active
        createdAt
        updatedAt
        userCustomersId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDriverQualification = /* GraphQL */ `
  query GetDriverQualification($id: ID!) {
    getDriverQualification(id: $id) {
      id
      tier
      customerId
      name
      driverId
      driverName
      status
      jobId
      jobStartDate
      createEmail
      updateUser
      dqSpecialistUser
      dqSpecialistEmail
      createUserFullName
      dqOwnerFullName
      dqClientApproverUser
      dqClientApproverUserFullName
      dqDocumentName
      questions {
        items {
          id
          user
          text
          createUserFullName
          type
          status
          createdAt
          updatedAt
          driverQualificationQuestionsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      tierDescription
      branch
      driverSkillSummary
      locationState {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locationCity {
        id
        state {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        name
        status
        createdAt
        updatedAt
        stateCityId
        __typename
      }
      driverQualificationChecklistDocuments {
        items {
          id
          item
          source
          category
          type
          order
          documentName
          createdAt
          updatedAt
          driverQualificationDriverQualificationChecklistDocumentsId
          __typename
        }
        nextToken
        __typename
      }
      accountMasterCode
      checkListLevel
      updatedAt
      driverQualificationLocationStateId
      driverQualificationLocationCityId
      __typename
    }
  }
`;
export const listDriverQualifications = /* GraphQL */ `
  query ListDriverQualifications(
    $filter: ModelDriverQualificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDriverQualifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tier
        customerId
        name
        driverId
        driverName
        status
        jobId
        jobStartDate
        createEmail
        updateUser
        dqSpecialistUser
        dqSpecialistEmail
        createUserFullName
        dqOwnerFullName
        dqClientApproverUser
        dqClientApproverUserFullName
        dqDocumentName
        questions {
          nextToken
          __typename
        }
        createdAt
        tierDescription
        branch
        driverSkillSummary
        locationState {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        locationCity {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        driverQualificationChecklistDocuments {
          nextToken
          __typename
        }
        accountMasterCode
        checkListLevel
        updatedAt
        driverQualificationLocationStateId
        driverQualificationLocationCityId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listDriverQualificationsByStatusByCustomerIdAndCreatedBy = /* GraphQL */ `
  query ListDriverQualificationsByStatusByCustomerIdAndCreatedBy(
    $status: String!
    $customerIdCreatedAt: ModelDriverQualificationByStatusbycustomerIdbycreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDriverQualificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDriverQualificationsByStatusByCustomerIdAndCreatedBy(
      status: $status
      customerIdCreatedAt: $customerIdCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tier
        customerId
        name
        driverId
        driverName
        status
        jobId
        jobStartDate
        createEmail
        updateUser
        dqSpecialistUser
        dqSpecialistEmail
        createUserFullName
        dqOwnerFullName
        dqClientApproverUser
        dqClientApproverUserFullName
        dqDocumentName
        questions {
          nextToken
          __typename
        }
        createdAt
        tierDescription
        branch
        driverSkillSummary
        locationState {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        locationCity {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        driverQualificationChecklistDocuments {
          nextToken
          __typename
        }
        accountMasterCode
        checkListLevel
        updatedAt
        driverQualificationLocationStateId
        driverQualificationLocationCityId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDriverQualificationChecklistDocuments = /* GraphQL */ `
  query GetDriverQualificationChecklistDocuments($id: ID!) {
    getDriverQualificationChecklistDocuments(id: $id) {
      id
      item
      source
      category
      type
      order
      documentName
      driverQualificationSelectedDocuments {
        items {
          id
          item
          source
          category
          type
          pages
          order
          documentName
          for
          customerId
          driverId
          documentAutoSelected
          createdAt
          updatedAt
          driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      driverQualificationDriverQualificationChecklistDocumentsId
      __typename
    }
  }
`;
export const listDriverQualificationChecklistDocuments = /* GraphQL */ `
  query ListDriverQualificationChecklistDocuments(
    $filter: ModelDriverQualificationChecklistDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDriverQualificationChecklistDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        source
        category
        type
        order
        documentName
        driverQualificationSelectedDocuments {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        driverQualificationDriverQualificationChecklistDocumentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDriverQualificationSelectedDocuments = /* GraphQL */ `
  query GetDriverQualificationSelectedDocuments($id: ID!) {
    getDriverQualificationSelectedDocuments(id: $id) {
      id
      item
      source
      category
      type
      pages
      order
      documentName
      for
      customerId
      driverId
      documentAutoSelected
      createdAt
      updatedAt
      driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId
      __typename
    }
  }
`;
export const listDriverQualificationSelectedDocuments = /* GraphQL */ `
  query ListDriverQualificationSelectedDocuments(
    $filter: ModelDriverQualificationSelectedDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDriverQualificationSelectedDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        source
        category
        type
        pages
        order
        documentName
        for
        customerId
        driverId
        documentAutoSelected
        createdAt
        updatedAt
        driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAtsDriverDocuments = /* GraphQL */ `
  query GetAtsDriverDocuments($id: ID!) {
    getAtsDriverDocuments(id: $id) {
      id
      atsDriverId
      category
      documentName
      driverId
      source
      type
      expirationDate
      atsDateCreated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAtsDriverDocuments = /* GraphQL */ `
  query ListAtsDriverDocuments(
    $filter: ModelAtsDriverDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAtsDriverDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        atsDriverId
        category
        documentName
        driverId
        source
        type
        expirationDate
        atsDateCreated
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAtsDriverDocumentsByDriverIdBysourceByCategoryByType = /* GraphQL */ `
  query ListAtsDriverDocumentsByDriverIdBysourceByCategoryByType(
    $driverId: Int!
    $sourceCategoryType: ModelAtsDriverDocumentsByDriverIdBysourceByCategoryByTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAtsDriverDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAtsDriverDocumentsByDriverIdBysourceByCategoryByType(
      driverId: $driverId
      sourceCategoryType: $sourceCategoryType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        atsDriverId
        category
        documentName
        driverId
        source
        type
        expirationDate
        atsDateCreated
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStandardDocuments = /* GraphQL */ `
  query GetStandardDocuments($id: ID!) {
    getStandardDocuments(id: $id) {
      id
      for
      forName
      category
      type
      customerId
      driverId
      documentName
      accountMasterCode
      createdAt
      updatedAt
      userStandardDocumentsId
      __typename
    }
  }
`;
export const listStandardDocuments = /* GraphQL */ `
  query ListStandardDocuments(
    $filter: ModelStandardDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStandardDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        for
        forName
        category
        type
        customerId
        driverId
        documentName
        accountMasterCode
        createdAt
        updatedAt
        userStandardDocumentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listStandardDocumentsBySubjectByCategoryByType = /* GraphQL */ `
  query ListStandardDocumentsBySubjectByCategoryByType(
    $for: String!
    $customerIdDriverIdCategoryType: ModelStandardDocumentsBySubjectByCategoryByTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStandardDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStandardDocumentsBySubjectByCategoryByType(
      for: $for
      customerIdDriverIdCategoryType: $customerIdDriverIdCategoryType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        for
        forName
        category
        type
        customerId
        driverId
        documentName
        accountMasterCode
        createdAt
        updatedAt
        userStandardDocumentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listStandardDocumentsBySubjectByaccountMasterCodeByCategoryByType = /* GraphQL */ `
  query ListStandardDocumentsBySubjectByaccountMasterCodeByCategoryByType(
    $for: String!
    $accountMasterCodeCustomerIdDriverIdCategoryType: ModelStandardDocumentsBySubjectByaccountMasterCodeByCategoryByTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStandardDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStandardDocumentsBySubjectByaccountMasterCodeByCategoryByType(
      for: $for
      accountMasterCodeCustomerIdDriverIdCategoryType: $accountMasterCodeCustomerIdDriverIdCategoryType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        for
        forName
        category
        type
        customerId
        driverId
        documentName
        accountMasterCode
        createdAt
        updatedAt
        userStandardDocumentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestions = /* GraphQL */ `
  query GetQuestions($id: ID!) {
    getQuestions(id: $id) {
      id
      user
      text
      createUserFullName
      type
      driverQualification {
        id
        tier
        customerId
        name
        driverId
        driverName
        status
        jobId
        jobStartDate
        createEmail
        updateUser
        dqSpecialistUser
        dqSpecialistEmail
        createUserFullName
        dqOwnerFullName
        dqClientApproverUser
        dqClientApproverUserFullName
        dqDocumentName
        questions {
          nextToken
          __typename
        }
        createdAt
        tierDescription
        branch
        driverSkillSummary
        locationState {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        locationCity {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        driverQualificationChecklistDocuments {
          nextToken
          __typename
        }
        accountMasterCode
        checkListLevel
        updatedAt
        driverQualificationLocationStateId
        driverQualificationLocationCityId
        __typename
      }
      status
      createdAt
      updatedAt
      driverQualificationQuestionsId
      __typename
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        text
        createUserFullName
        type
        driverQualification {
          id
          tier
          customerId
          name
          driverId
          driverName
          status
          jobId
          jobStartDate
          createEmail
          updateUser
          dqSpecialistUser
          dqSpecialistEmail
          createUserFullName
          dqOwnerFullName
          dqClientApproverUser
          dqClientApproverUserFullName
          dqDocumentName
          createdAt
          tierDescription
          branch
          driverSkillSummary
          accountMasterCode
          checkListLevel
          updatedAt
          driverQualificationLocationStateId
          driverQualificationLocationCityId
          __typename
        }
        status
        createdAt
        updatedAt
        driverQualificationQuestionsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAudit = /* GraphQL */ `
  query GetAudit($id: ID!) {
    getAudit(id: $id) {
      id
      event
      eventTime
      user {
        id
        name
        customerId
        status
        type
        lastLogin
        clientName
        updateDriverQualifications
        StandardDocuments {
          nextToken
          __typename
        }
        Audit {
          nextToken
          __typename
        }
        appAccess {
          nextToken
          __typename
        }
        cellPhone
        passwordChangeDate
        preferredMFA
        lastResetMFA
        customers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      objectName
      objectId
      objectData
      createdAt
      updatedAt
      userAuditId
      __typename
    }
  }
`;
export const listAudits = /* GraphQL */ `
  query ListAudits(
    $filter: ModelAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event
        eventTime
        user {
          id
          name
          customerId
          status
          type
          lastLogin
          clientName
          updateDriverQualifications
          cellPhone
          passwordChangeDate
          preferredMFA
          lastResetMFA
          createdAt
          updatedAt
          __typename
        }
        objectName
        objectId
        objectData
        createdAt
        updatedAt
        userAuditId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAuditbyObjectNamebyObjectId = /* GraphQL */ `
  query ListAuditbyObjectNamebyObjectId(
    $objectName: String!
    $objectId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditbyObjectNamebyObjectId(
      objectName: $objectName
      objectId: $objectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event
        eventTime
        user {
          id
          name
          customerId
          status
          type
          lastLogin
          clientName
          updateDriverQualifications
          cellPhone
          passwordChangeDate
          preferredMFA
          lastResetMFA
          createdAt
          updatedAt
          __typename
        }
        objectName
        objectId
        objectData
        createdAt
        updatedAt
        userAuditId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApplication = /* GraphQL */ `
  query GetApplication($id: ID!) {
    getApplication(id: $id) {
      id
      name
      roles {
        items {
          id
          name
          usedBy
          applicationUsedBy
          viewFullPii
          createdAt
          updatedAt
          applicationRolesId
          __typename
        }
        nextToken
        __typename
      }
      userAppAccess {
        items {
          id
          createdAt
          updatedAt
          userAppAccessId
          applicationUserAppAccessId
          applicationRoleUserAppAccessId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApplications = /* GraphQL */ `
  query ListApplications(
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        roles {
          nextToken
          __typename
        }
        userAppAccess {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApplicationRole = /* GraphQL */ `
  query GetApplicationRole($id: ID!) {
    getApplicationRole(id: $id) {
      id
      name
      usedBy
      userAppAccess {
        items {
          id
          createdAt
          updatedAt
          userAppAccessId
          applicationUserAppAccessId
          applicationRoleUserAppAccessId
          __typename
        }
        nextToken
        __typename
      }
      applicationUsedBy
      viewFullPii
      createdAt
      updatedAt
      applicationRolesId
      __typename
    }
  }
`;
export const listApplicationRoles = /* GraphQL */ `
  query ListApplicationRoles(
    $filter: ModelApplicationRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicationRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        usedBy
        userAppAccess {
          nextToken
          __typename
        }
        applicationUsedBy
        viewFullPii
        createdAt
        updatedAt
        applicationRolesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserApplicationAccess = /* GraphQL */ `
  query GetUserApplicationAccess($id: ID!) {
    getUserApplicationAccess(id: $id) {
      id
      userId {
        id
        name
        customerId
        status
        type
        lastLogin
        clientName
        updateDriverQualifications
        StandardDocuments {
          nextToken
          __typename
        }
        Audit {
          nextToken
          __typename
        }
        appAccess {
          nextToken
          __typename
        }
        cellPhone
        passwordChangeDate
        preferredMFA
        lastResetMFA
        customers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      appId {
        id
        name
        roles {
          nextToken
          __typename
        }
        userAppAccess {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      roleId {
        id
        name
        usedBy
        userAppAccess {
          nextToken
          __typename
        }
        applicationUsedBy
        viewFullPii
        createdAt
        updatedAt
        applicationRolesId
        __typename
      }
      createdAt
      updatedAt
      userAppAccessId
      applicationUserAppAccessId
      applicationRoleUserAppAccessId
      __typename
    }
  }
`;
export const listUserApplicationAccesses = /* GraphQL */ `
  query ListUserApplicationAccesses(
    $filter: ModelUserApplicationAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicationAccesses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId {
          id
          name
          customerId
          status
          type
          lastLogin
          clientName
          updateDriverQualifications
          cellPhone
          passwordChangeDate
          preferredMFA
          lastResetMFA
          createdAt
          updatedAt
          __typename
        }
        appId {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        roleId {
          id
          name
          usedBy
          applicationUsedBy
          viewFullPii
          createdAt
          updatedAt
          applicationRolesId
          __typename
        }
        createdAt
        updatedAt
        userAppAccessId
        applicationUserAppAccessId
        applicationRoleUserAppAccessId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      ApplicationId
      Event
      UserId
      ObjectId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ApplicationId
        Event
        UserId
        ObjectId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocumentSubject = /* GraphQL */ `
  query GetDocumentSubject($id: ID!) {
    getDocumentSubject(id: $id) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDocumentSubjects = /* GraphQL */ `
  query ListDocumentSubjects(
    $filter: ModelDocumentSubjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentSubjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocumentSource = /* GraphQL */ `
  query GetDocumentSource($id: ID!) {
    getDocumentSource(id: $id) {
      id
      name
      category {
        items {
          id
          name
          createdAt
          updatedAt
          documentSourceCategoryId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDocumentSources = /* GraphQL */ `
  query ListDocumentSources(
    $filter: ModelDocumentSourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentSources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        category {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocumentCategory = /* GraphQL */ `
  query GetDocumentCategory($id: ID!) {
    getDocumentCategory(id: $id) {
      id
      name
      type {
        items {
          id
          Name
          For
          createdAt
          updatedAt
          documentCategoryTypeId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      documentSourceCategoryId
      __typename
    }
  }
`;
export const listDocumentCategories = /* GraphQL */ `
  query ListDocumentCategories(
    $filter: ModelDocumentCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        documentSourceCategoryId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocumentType = /* GraphQL */ `
  query GetDocumentType($id: ID!) {
    getDocumentType(id: $id) {
      id
      Name
      For
      createdAt
      updatedAt
      documentCategoryTypeId
      __typename
    }
  }
`;
export const listDocumentTypes = /* GraphQL */ `
  query ListDocumentTypes(
    $filter: ModelDocumentTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        For
        createdAt
        updatedAt
        documentCategoryTypeId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChecklist = /* GraphQL */ `
  query GetChecklist($id: ID!) {
    getChecklist(id: $id) {
      id
      tier
      clientId
      clientName
      checklistDocumentName
      accountMasterCode
      checkListLevel
      items {
        items {
          id
          item
          source
          category
          type
          pages
          order
          createdAt
          updatedAt
          checklistItemsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChecklists = /* GraphQL */ `
  query ListChecklists(
    $filter: ModelChecklistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChecklists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tier
        clientId
        clientName
        checklistDocumentName
        accountMasterCode
        checkListLevel
        items {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listChecklistByClientIdTier = /* GraphQL */ `
  query ListChecklistByClientIdTier(
    $clientId: Int!
    $tier: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChecklistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChecklistByClientIdTier(
      clientId: $clientId
      tier: $tier
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tier
        clientId
        clientName
        checklistDocumentName
        accountMasterCode
        checkListLevel
        items {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listChecklistBycheckListLevelAccountMasterCodeClientIdTier = /* GraphQL */ `
  query ListChecklistBycheckListLevelAccountMasterCodeClientIdTier(
    $checkListLevel: String!
    $accountMasterCodeClientIdTier: ModelChecklistBycheckListLevelbyAccountMasterCodebyClientIdTierCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChecklistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChecklistBycheckListLevelAccountMasterCodeClientIdTier(
      checkListLevel: $checkListLevel
      accountMasterCodeClientIdTier: $accountMasterCodeClientIdTier
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tier
        clientId
        clientName
        checklistDocumentName
        accountMasterCode
        checkListLevel
        items {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChecklistItems = /* GraphQL */ `
  query GetChecklistItems($id: ID!) {
    getChecklistItems(id: $id) {
      id
      item
      source
      category
      type
      pages
      order
      createdAt
      updatedAt
      checklistItemsId
      __typename
    }
  }
`;
export const listChecklistItems = /* GraphQL */ `
  query ListChecklistItems(
    $filter: ModelChecklistItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChecklistItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        item
        source
        category
        type
        pages
        order
        createdAt
        updatedAt
        checklistItemsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAirLine = /* GraphQL */ `
  query GetAirLine($id: ID!) {
    getAirLine(id: $id) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAirLines = /* GraphQL */ `
  query ListAirLines(
    $filter: ModelAirLineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAirLines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAirPort = /* GraphQL */ `
  query GetAirPort($id: ID!) {
    getAirPort(id: $id) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAirPorts = /* GraphQL */ `
  query ListAirPorts(
    $filter: ModelAirPortFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAirPorts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHotel = /* GraphQL */ `
  query GetHotel($id: ID!) {
    getHotel(id: $id) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHotels = /* GraphQL */ `
  query ListHotels(
    $filter: ModelHotelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHotels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCarRental = /* GraphQL */ `
  query GetCarRental($id: ID!) {
    getCarRental(id: $id) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCarRentals = /* GraphQL */ `
  query ListCarRentals(
    $filter: ModelCarRentalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarRentals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getState = /* GraphQL */ `
  query GetState($id: ID!) {
    getState(id: $id) {
      id
      name
      city {
        items {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStates = /* GraphQL */ `
  query ListStates(
    $filter: ModelStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCity = /* GraphQL */ `
  query GetCity($id: ID!) {
    getCity(id: $id) {
      id
      state {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      name
      status
      createdAt
      updatedAt
      stateCityId
      __typename
    }
  }
`;
export const listCities = /* GraphQL */ `
  query ListCities(
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        state {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        name
        status
        createdAt
        updatedAt
        stateCityId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExpense = /* GraphQL */ `
  query GetExpense($id: ID!) {
    getExpense(id: $id) {
      id
      code
      driverId
      driverName
      assignmentId
      status
      nonBillable
      customerId
      customerName
      branch
      branchName
      costCenter
      startDate
      endDate
      totalToBeBilled
      notes
      auditKey
      invoiceDate
      invoiceNumber
      amountBilled
      variance
      statementDate
      expenseReportAppropriationNbr
      expenseItem {
        items {
          id
          flightDate
          paidThrough
          receiptAmount
          markup
          expenseTotal
          documentName
          documentSize
          uploadedByName
          uploadedDate
          hotelConfirmationNumber
          hotelPhoneNumber
          hotelStreetAddress
          checkInDate
          lastNightOfStay
          dailyCharge
          totalReceiptAmount
          carRentalContractNumber
          carRentalPickUpLocation
          carRentalReturnLocation
          carRentalPickUpDate
          carRentalReturnDate
          originalReceiptAmount
          otherExpenseName
          otherExpenseDescription
          otherExpenseDate
          hotelDriverId
          hotelDriverName
          hotelAssignmentId
          projectedDays
          projectedAmount
          createdAt
          updatedAt
          expenseExpenseItemId
          expenseItemAirLineId
          expenseItemDepartureAirPortId
          expenseItemArrivalAirPortId
          expenseItemHotelId
          expenseItemHotelStateId
          expenseItemHotelCityId
          expenseItemCarRentalCompanyId
          __typename
        }
        nextToken
        __typename
      }
      sharedInvoice
      createdAt
      hotel {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      hotelConfirmationNumber
      hotelPhoneNumber
      hotelStreetAddress
      hotelState {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      hotelCity {
        id
        state {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        name
        status
        createdAt
        updatedAt
        stateCityId
        __typename
      }
      documentName
      documentSize
      uploadedByName
      uploadedDate
      totalReceiptAmount
      totalProjectedAmount
      checkedForUltraStaff
      inv
      payType
      payAmt
      billUnits
      ok
      payWeekEnding
      originalExpenseId
      correctionExpenseId
      rebilledExpenseId
      flatFeeBilling
      lob
      confirmedBy
      updatedBy
      updatedAt
      __typename
    }
  }
`;
export const listExpenses = /* GraphQL */ `
  query ListExpenses(
    $filter: ModelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        driverId
        driverName
        assignmentId
        status
        nonBillable
        customerId
        customerName
        branch
        branchName
        costCenter
        startDate
        endDate
        totalToBeBilled
        notes
        auditKey
        invoiceDate
        invoiceNumber
        amountBilled
        variance
        statementDate
        expenseReportAppropriationNbr
        expenseItem {
          nextToken
          __typename
        }
        sharedInvoice
        createdAt
        hotel {
          id
          name
          status
          createdAt
          updatedAt
          __typename
        }
        hotelConfirmationNumber
        hotelPhoneNumber
        hotelStreetAddress
        hotelState {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        hotelCity {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        documentName
        documentSize
        uploadedByName
        uploadedDate
        totalReceiptAmount
        totalProjectedAmount
        checkedForUltraStaff
        inv
        payType
        payAmt
        billUnits
        ok
        payWeekEnding
        originalExpenseId
        correctionExpenseId
        rebilledExpenseId
        flatFeeBilling
        lob
        confirmedBy
        updatedBy
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExpenseItem = /* GraphQL */ `
  query GetExpenseItem($id: ID!) {
    getExpenseItem(id: $id) {
      id
      expense {
        id
        code
        driverId
        driverName
        assignmentId
        status
        nonBillable
        customerId
        customerName
        branch
        branchName
        costCenter
        startDate
        endDate
        totalToBeBilled
        notes
        auditKey
        invoiceDate
        invoiceNumber
        amountBilled
        variance
        statementDate
        expenseReportAppropriationNbr
        expenseItem {
          nextToken
          __typename
        }
        sharedInvoice
        createdAt
        hotel {
          id
          name
          status
          createdAt
          updatedAt
          __typename
        }
        hotelConfirmationNumber
        hotelPhoneNumber
        hotelStreetAddress
        hotelState {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        hotelCity {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        documentName
        documentSize
        uploadedByName
        uploadedDate
        totalReceiptAmount
        totalProjectedAmount
        checkedForUltraStaff
        inv
        payType
        payAmt
        billUnits
        ok
        payWeekEnding
        originalExpenseId
        correctionExpenseId
        rebilledExpenseId
        flatFeeBilling
        lob
        confirmedBy
        updatedBy
        updatedAt
        __typename
      }
      airLine {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      departureAirPort {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      arrivalAirPort {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      flightDate
      paidThrough
      receiptAmount
      markup
      expenseTotal
      documentName
      documentSize
      uploadedByName
      uploadedDate
      hotel {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      hotelConfirmationNumber
      hotelPhoneNumber
      hotelStreetAddress
      hotelState {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      hotelCity {
        id
        state {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        name
        status
        createdAt
        updatedAt
        stateCityId
        __typename
      }
      checkInDate
      lastNightOfStay
      dailyCharge
      totalReceiptAmount
      carRentalCompany {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      carRentalContractNumber
      carRentalPickUpLocation
      carRentalReturnLocation
      carRentalPickUpDate
      carRentalReturnDate
      originalReceiptAmount
      otherExpenseName
      otherExpenseDescription
      otherExpenseDate
      hotelDriverId
      hotelDriverName
      hotelAssignmentId
      projectedDays
      projectedAmount
      createdAt
      updatedAt
      expenseExpenseItemId
      expenseItemAirLineId
      expenseItemDepartureAirPortId
      expenseItemArrivalAirPortId
      expenseItemHotelId
      expenseItemHotelStateId
      expenseItemHotelCityId
      expenseItemCarRentalCompanyId
      __typename
    }
  }
`;
export const listExpenseItems = /* GraphQL */ `
  query ListExpenseItems(
    $filter: ModelExpenseItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpenseItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        expense {
          id
          code
          driverId
          driverName
          assignmentId
          status
          nonBillable
          customerId
          customerName
          branch
          branchName
          costCenter
          startDate
          endDate
          totalToBeBilled
          notes
          auditKey
          invoiceDate
          invoiceNumber
          amountBilled
          variance
          statementDate
          expenseReportAppropriationNbr
          sharedInvoice
          createdAt
          hotelConfirmationNumber
          hotelPhoneNumber
          hotelStreetAddress
          documentName
          documentSize
          uploadedByName
          uploadedDate
          totalReceiptAmount
          totalProjectedAmount
          checkedForUltraStaff
          inv
          payType
          payAmt
          billUnits
          ok
          payWeekEnding
          originalExpenseId
          correctionExpenseId
          rebilledExpenseId
          flatFeeBilling
          lob
          confirmedBy
          updatedBy
          updatedAt
          __typename
        }
        airLine {
          id
          name
          status
          createdAt
          updatedAt
          __typename
        }
        departureAirPort {
          id
          name
          status
          createdAt
          updatedAt
          __typename
        }
        arrivalAirPort {
          id
          name
          status
          createdAt
          updatedAt
          __typename
        }
        flightDate
        paidThrough
        receiptAmount
        markup
        expenseTotal
        documentName
        documentSize
        uploadedByName
        uploadedDate
        hotel {
          id
          name
          status
          createdAt
          updatedAt
          __typename
        }
        hotelConfirmationNumber
        hotelPhoneNumber
        hotelStreetAddress
        hotelState {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        hotelCity {
          id
          name
          status
          createdAt
          updatedAt
          stateCityId
          __typename
        }
        checkInDate
        lastNightOfStay
        dailyCharge
        totalReceiptAmount
        carRentalCompany {
          id
          name
          status
          createdAt
          updatedAt
          __typename
        }
        carRentalContractNumber
        carRentalPickUpLocation
        carRentalReturnLocation
        carRentalPickUpDate
        carRentalReturnDate
        originalReceiptAmount
        otherExpenseName
        otherExpenseDescription
        otherExpenseDate
        hotelDriverId
        hotelDriverName
        hotelAssignmentId
        projectedDays
        projectedAmount
        createdAt
        updatedAt
        expenseExpenseItemId
        expenseItemAirLineId
        expenseItemDepartureAirPortId
        expenseItemArrivalAirPortId
        expenseItemHotelId
        expenseItemHotelStateId
        expenseItemHotelCityId
        expenseItemCarRentalCompanyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTATQuiz = /* GraphQL */ `
  query GetTATQuiz($id: ID!) {
    getTATQuiz(id: $id) {
      id
      livesOfPotentialVictims
      isTraffickingInTheUntiedStates
      whereTraffickingHappen
      victimsNeedAidInTheirRecovery
      humanTraffickingIsExploitation
      traffickersGroomPotentialVictims
      vulnerabilityFactors
      minorsSellingSexIsProstitution
      redFlagsToWatchOutFor
      crimesInProgress
      certificate
      status
      attempts
      employeeFullName
      emailNotificationStatus
      emailNotificationDateTime
      firstName
      lastName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTATQuizs = /* GraphQL */ `
  query ListTATQuizs(
    $filter: ModelTATQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTATQuizs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        livesOfPotentialVictims
        isTraffickingInTheUntiedStates
        whereTraffickingHappen
        victimsNeedAidInTheirRecovery
        humanTraffickingIsExploitation
        traffickersGroomPotentialVictims
        vulnerabilityFactors
        minorsSellingSexIsProstitution
        redFlagsToWatchOutFor
        crimesInProgress
        certificate
        status
        attempts
        employeeFullName
        emailNotificationStatus
        emailNotificationDateTime
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPushNotification = /* GraphQL */ `
  query GetPushNotification($id: ID!) {
    getPushNotification(id: $id) {
      id
      type
      subject
      message
      url
      status
      lob
      branch
      recipientStatus
      approvedBy
      approvedAt
      sentOn
      createdAt
      createdBy
      updatedAt
      __typename
    }
  }
`;
export const listPushNotifications = /* GraphQL */ `
  query ListPushNotifications(
    $filter: ModelPushNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPushNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        subject
        message
        url
        status
        lob
        branch
        recipientStatus
        approvedBy
        approvedAt
        sentOn
        createdAt
        createdBy
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listByTypeByCreatedAt = /* GraphQL */ `
  query ListByTypeByCreatedAt(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPushNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByTypeByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        subject
        message
        url
        status
        lob
        branch
        recipientStatus
        approvedBy
        approvedAt
        sentOn
        createdAt
        createdBy
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
