import React, { useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { FaQuestion } from 'react-icons/fa';
import useIsMobileScreen from '../../hooks/useCheckIsMobile';
import './styles.scoped.css';

const SubHeader = ({
  text = '',
  btnSmall = null,
  btnSmall2 = null,
  btnLarge = null,
  btnInfo = false,
  smallAction,
  small2Action,
  largeAction,
  emailAddress = '',
  infoText = '',
  btnLargeStyle = {},
}) => {
  const isMobile = useIsMobileScreen();

  const popover = (
    <Popover id="popover-help">
      <Popover.Title as="h3" className="text-center text-md-right">
        {infoText}
      </Popover.Title>
      <Popover.Content>
        {emailAddress ? (
          <a href={`mailto:${process.env.REACT_APP_DQF_CONTACT_EMAIL}`}>
            Email: {emailAddress}
          </a>
        ) : (
          <div>{emailAddress}</div>
        )}
      </Popover.Content>
    </Popover>
  );

  return (
    <span className={`wrap ${isMobile ? 'mobile' : ''}`}>
      <h1 className={isMobile ? 'mobile' : ''}>{text}</h1>
      <span className="buttons-group">
        {btnSmall && (
          <div
            className={`btn-small ${isMobile ? 'mobile' : ''}`}
            onClick={smallAction}
          >
            {btnSmall}
          </div>
        )}
        {btnSmall2 && (
          <div
            className={`btn-small ${isMobile ? 'mobile' : ''}`}
            onClick={small2Action}
          >
            {btnSmall2}
          </div>
        )}
        {btnLarge && (
          <div
            className="btn-large"
            style={btnLargeStyle}
            onClick={largeAction}
          >
            {btnLarge}
          </div>
        )}
        {btnInfo && (
          <OverlayTrigger
            rootClose
            transition
            trigger="click"
            placement="bottom"
            overlay={popover}
          >
            <Button
              variant="secondary"
              className={`${isMobile ? 'mobile' : ''} info`}
              style={{ marginRight: 5 }}
            >
              <FaQuestion
                style={{
                  color: 'white',
                }}
              />
            </Button>
          </OverlayTrigger>
        )}
      </span>
    </span>
  );
};
export default SubHeader;
