import React, { useEffect, useState } from 'react';

import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Web, Home } from '@mui/icons-material';
import { Divider, Tooltip } from '@mui/material';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import CustomerItem from './CustomerItem';
import { getClient, getUserData, updateUser, createUserCustomer, deleteUserCustomer } from './operations';
import styles from './styles';
import { SubHeader, InputBox, ModalConfirmation, Footer } from '../../../components';
import useIsMobileScreen from '../../../hooks/useCheckIsMobile';
import getRandomId from '../../../utils/getRandomId';
import { getListDuplicates } from '../../../utils/listDuplicates';

import './styles.scoped.css';

const UpdateClientUser = () => {
  const navigation = useNavigate();
  const { id, user } = useParams();
  const [clientId, setClientId] = useState();
  const [clientData, setClientData] = useState();
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedContact, setSelectedContact] = useState();
  const [userData, setUserData] = useState();
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const isMobile = useIsMobileScreen();
  const [customers, setCustomers] = useState([]);

  const fetchClient = async () => {
    if (!clientId || clientId === '') return;
    setLoading(true);
    setSelectedContact(null);
    const response = await getClient(clientId);
    if (response.response_code === 200) {
      const status = response.response_body?.clientStatus?.split(' ')[0];
      const statuses = process.env.REACT_APP_CLIENT_STATUSES.split(',');
      if (statuses.includes(status)) {
        setClientData(response.response_body);
      } else {
        setTitle('Invalid');
        setMessage('Client not active in Ultra Staff');
      }
    } else {
      setClientId(null);
      setSelectedContact(null);
    }
    setLoading(false);
  };

  const fetchUserData = async () => {
    setLoading(true);
    const newUserData = await getUserData(user);
    if (newUserData.customers.items?.length) {
      Promise.all(newUserData.customers.items.map(item => getClient(item.customerId))).then(responses => {
        const newCustomers = responses
          .map((response, index) => ({
            customerId: newUserData.customers.items[index].customerId,
            clientName: response.response_body.clientName,
            clientStatus: response.response_body.clientStatus,
            active: newUserData.customers.items[index].active,
            isValid: true,
            id: newUserData.customers.items[index].id,
            error: '',
          }))
          .sort((a, b) => Number(a.customerId) - Number(b.customerId));
        setCustomers(newCustomers);
        setUserData(newUserData);
        setLoading(false);
      });
    } else {
      setCustomers([
        { customerId: '', clientName: '', clientStatus: '', active: false, isValid: false, id: 0, error: '' },
      ]);
      setUserData(newUserData);
      setLoading(false);
    }
  };

  const update = async () => {
    const erroredCustomer = customers.find(c => !!c.error);
    if (erroredCustomer) {
      setTitle('Invalid');
      setMessage(`Please, check clients. There is an error: ${erroredCustomer.error}`);
    } else {
      setSaveLoading(true);
      try {
        const response = await updateUser({
          id: userData?.id,
          customerId: clientId,
          clientName: clientData?.clientName,
          name: `${selectedContact?.firstName} ${selectedContact?.lastName}`,
          cellPhone: selectedContact?.smsOptIn === true && selectedContact?.cellPhone ? selectedContact?.cellPhone?.replace(/\D/g, '') : '',
        });
        if (response?.errors?.length) {
          setSaveLoading(false);
          // eslint-disable-next-line no-undef
          alert(response?.errors[0].message);
        } else {
          // Delete old list of customers and Add updated list of costumers
          Promise.all([
            ...(userData?.customers?.items?.map(({ id: anId }) => deleteUserCustomer({ id: anId })) || []),
            ...customers.map(customer =>
              createUserCustomer({
                active: customer.active,
                customerId: customer.customerId,
                userCustomersId: user,
              }),
            ),
          ]).finally(() => {
            setSaveLoading(false);
            setTitle('Success');
            setMessage('The user has been successfully updated.');
          });
        }
      } catch (error) {
        setSaveLoading(false);
        console.log('error: ', error);
      }
    }
  };

  const newRow = () => {
    setCustomers([
      ...customers,
      {
        customerId: '',
        clientName: '',
        clientStatus: '',
        active: false,
        isValid: false,
        id: getRandomId(),
        error: '',
      },
    ]);
  };

  useEffect(() => {
    if (id) setClientId(id);
  }, [id]);

  useEffect(() => {
    if (clientId) fetchClient();
  }, [clientId]);

  useEffect(() => {
    if (clientData && user) {
      const contact = clientData.clientContacts.find(item => item.emailAddress === user);
      if (contact) {
        setSelectedContact(contact);
      } else {
        setTitle('Not Found');
        setMessage('Client contact not found in Ultra Staff');
      }
    }
  }, [clientData, user]);

  useEffect(() => {
    if (selectedContact) fetchUserData();
  }, [selectedContact]);

  useEffect(() => {
    const duplicatedValues = getListDuplicates(customers, 'customerId');
    const newList = [...customers];
    let shouldUpdate = false;
    for (let i = 0; i < customers.length; i++) {
      const customer = customers[i];
      const newErrorValue = duplicatedValues.find(dv => dv.customerId === customer.customerId)
        ? 'Client Already Assigned'
        : '';
      // If customer error was Client Already Assigned or empty and now it's different, update the error
      if ((customer.error === 'Client Already Assigned' || !customer.error) && customer.error !== newErrorValue) {
        shouldUpdate = true;
        const indexToChange = newList.findIndex(c => c.id === customer.id);
        newList[indexToChange] = {
          ...newList[indexToChange],
          isValid: !newErrorValue,
          error: newErrorValue,
        };
      }
    }
    if (shouldUpdate) setCustomers(newList);
  }, [customers]);

  return (
    <div>
      <SubHeader
        btnSmall={<Web sx={{ color: '#fff', fontSize: 30 }} />}
        btnSmall2={<Home sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigation('/dashboard')}
        small2Action={() => navigation(-1)}
        text="Admin Portal"
      />
      <div className="wrap">
        <div className="inner" style={{ width: isMobile ? '100%' : '70%' }}>
          <div className="bar">Update Client User</div>
          <div className="content">
            <Form>
              <Row>
                <Form.Label style={{ margin: 15, marginBottom: 30 }}>
                  Information displayed is from Ultra Staff. Update Ultra Staff first then click refresh{' '}
                  <FontAwesomeIcon icon={faRedo} className="fa-sm" /> below to display changes in DQF. Click Save to
                  retain those updates.
                </Form.Label>
              </Row>
              <Row>
                <Col>
                  <Form.Label>User</Form.Label>
                  <div className="label">{user}</div>
                </Col>
                <Col>
                  <Form.Label>Client Name</Form.Label>
                  <div className="label">{clientData?.clientName}</div>
                </Col>
                <Col>
                  <Form.Label>Client Status</Form.Label>
                  <div className="label">{clientData?.clientStatus}</div>
                </Col>
                <div style={{ width: 96 }} />
              </Row>
              {selectedContact && (
                <Row>
                  <Col style={{ padding: 0 }}>
                    <div className="box" style={{ padding: 0 }}>
                      <Row style={{ padding: 16 }}>
                        <Col>
                          <InputBox disabled title="First Name" value={selectedContact?.firstName} />
                        </Col>
                        <Col>
                          <InputBox disabled title="Last Name" value={selectedContact?.lastName} />
                          <InputBox
                            disabled
                            style={styles.marginTop}
                            title="Ultra Staff Contact Status"
                            value={selectedContact?.status}
                          />
                        </Col>
                        <Col>
                          <InputBox
                            disabled
                            title="Cell Phone"
                            value={selectedContact?.smsOptIn === true ? selectedContact?.cellPhone : ''}
                            error={
                              !!selectedContact &&
                              !!userData &&
                              selectedContact?.cellPhone?.replace(/\D/g, '') !== userData?.cellPhone?.replace(/\D/g, '')
                            }
                            errorMessage={selectedContact?.smsOptIn === true ? `${selectedContact?.cellPhone} does not match ${userData?.cellPhone}` : ''}
                          />
                          <InputBox
                            disabled
                            style={styles.marginTop}
                            title="SMS Opt-in"
                            value={selectedContact?.smsOptIn ? 'Yes' : 'No'}
                            errorMessage="SMS Opt In Not Set"
                            error={typeof selectedContact?.smsOptIn !== 'boolean'}
                          />
                        </Col>
                        <div style={{ width: 96 }} />
                      </Row>
                      <Divider />
                      {loading && (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 152,
                          }}>
                          <Spinner animation="border" size="xl" />
                        </div>
                      )}
                      {!loading && !!customers.length && (
                        <Row style={{ padding: 16, paddingBottom: 0 }}>
                          <Col>
                            <Col>
                              <Form.Label style={{ paddingLeft: 40 }}>Client ID</Form.Label>
                            </Col>
                          </Col>
                          <Col>
                            <Col>
                              <Form.Label>Client Name</Form.Label>
                            </Col>
                          </Col>
                          <Col>
                            <Col>
                              <Form.Label>Client Status</Form.Label>
                            </Col>
                          </Col>
                          <div style={{ width: 96 }}>
                            <Form.Label>Active</Form.Label>
                          </div>
                        </Row>
                      )}
                      {!loading &&
                        customers.map(customer => (
                          <CustomerItem
                            customer={customer}
                            customers={customers}
                            setCustomers={setCustomers}
                            key={customer.id}
                          />
                        ))}
                      <Row style={{ padding: 16 }}>
                        <Col>
                          {!loading && (
                            <Button
                              disabled={!!customers.find(customer => !customer.isValid)}
                              style={styles.plus}
                              onClick={newRow}>
                              +
                            </Button>
                          )}
                        </Col>
                        <Col />
                        <Col />
                        <div style={{ width: 96 }} />
                      </Row>
                      <Row style={{ padding: 16 }}>
                        <Col />
                        <Col>
                          <Button
                            className="w-100"
                            style={styles.buttonPrimary}
                            onClick={update}
                            disabled={saveLoading}>
                            {saveLoading ? 'Saving...' : 'Save'}
                          </Button>
                        </Col>
                        <Col />
                        <div style={{ width: 96, display: 'flex', justifyContent: 'center' }}>
                          <Tooltip title="Refresh data from Ultra Staff" arrow>
                            <Button style={{ height: 40, alignSelf: 'flex-end' }} onClick={fetchClient}>
                              <FontAwesomeIcon icon={faRedo} className="fa-lg" />
                            </Button>
                          </Tooltip>
                        </div>
                      </Row>
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        </div>
      </div>
      <ModalConfirmation
        title={title}
        body={message}
        primaryButtonTitle="Add Another"
        secondaryButtonTitle="Close"
        isOpen={title.length > 0}
        onClick={() => {
          setClientId('');
          setTitle('');
        }}
        secondaryOnClick={() => {
          if (title === 'Invalid') {
            setTitle('');
            setMessage('');
          } else {
            navigation(-1);
          }
        }}
        optionalClose
      />
      <Footer />
    </div>
  );
};

export default UpdateClientUser;
