import React, { useMemo } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import './styles.scoped.css';
import { TablePagination } from '../../../components';

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const columnClassName = 'align-middle text-nowrap';
export const riskClaimsValidationColumns = [
  {
    Header: 'Appl#',
    accessor: 'APPL_ID',
    className: columnClassName,
  },
  {
    Header: 'Pay Service ID',
    accessor: 'Pay_Svc_Id',
    className: columnClassName,
  },
  {
    Header: 'Appl Last Name',
    accessor: 'Last_Name',
    className: columnClassName,
  },
  {
    Header: 'Appl First Name',
    accessor: 'First_Name',
    className: columnClassName,
  },
  {
    Header: 'Status',
    accessor: 'Status',
    className: columnClassName,
  },
  {
    Header: 'SS#',
    accessor: 'SSN',
    className: columnClassName,
  },
  {
    Header: 'DOB',
    accessor: 'DOB',
    className: columnClassName,
  },
  {
    Header: 'Address Line 1',
    accessor: 'Address_Line_1',
    className: columnClassName,
  },
  {
    Header: 'Address Line 2',
    accessor: 'Address_Line_2',
    className: columnClassName,
  },
  {
    Header: 'City',
    accessor: 'City',
    className: columnClassName,
  },
  {
    Header: 'State',
    accessor: 'State',
    className: columnClassName,
  },
  {
    Header: 'ZIP',
    accessor: 'Zip',
    className: columnClassName,
  },
  {
    Header: 'Country',
    accessor: 'Country',
    className: columnClassName,
  },
  {
    Header: 'Home Phone',
    accessor: 'Home_Phone',
    className: columnClassName,
  },
  {
    Header: 'Cell Phone',
    accessor: 'Cell_Phone',
    className: columnClassName,
  },
  {
    Header: 'Work Phone',
    accessor: 'Work_Phone',
    className: columnClassName,
  },
  {
    Header: 'Email',
    accessor: 'E_Mail',
    className: columnClassName,
  },
  {
    Header: 'Hire Date',
    accessor: 'HireDate',
    className: columnClassName,
  },
  {
    Header: 'Rehire Date',
    accessor: 'ReHireDate',
    className: columnClassName,
  },
  {
    Header: 'Termination Date',
    accessor: 'TerminationDate',
    className: columnClassName,
  },
];

const RiskClaimsValidationTable = ({ data, loading }) => {
  const memoData = useMemo(() => data, [data]);

  const tableInstance = useTable(
    {
      columns: riskClaimsValidationColumns,
      data: memoData,
      initialState: {
        pageSize: 200,
        sortBy: [
          {
            id: 'CreatedAt',
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>
          {column.canSort
            ? getSortedIcon(column.isSorted, column.isSortedDesc)
            : null}
        </span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}
      >
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading && <Spinner animation="border" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RiskClaimsValidationTable;
