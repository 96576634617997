import {
  createChecklistDocument,
  dqSelectRest,
  getDQByID,
  getListCheckListBycheckListLevelAccountMasterCodeClientIdTier,
  updateChecklistDocuments,
} from './operations';
import {
  createDocuments,
  getAtsDriverDocuments,
  getStandardDocuments,
  updateDocuments,
} from '../SelectDocumentDetails/operations';
import { Storage } from 'aws-amplify';

const selectDocumentsAndAutoSelect = async ({
  id,
  dq,
  setError,
  tierData,
  setDQ,
  setLoadingAutoselect,
  types,
  sources,
  categories,
}) => {
  const [newRows, checklistName] = await addDocuments({ id, dq, setError, tierData });
  await autoSelectDocuments({ newRows, dq, types, sources, categories, checklistName });
  const newDQ = await getDQByID(id);
  setDQ(newDQ);
  setLoadingAutoselect(false);
};

const addDocuments = ({ id, dq, setError, tierData }) =>
  getListCheckListBycheckListLevelAccountMasterCodeClientIdTier(
    dq.checkListLevel,
    dq.accountMasterCode,
    dq.customerId,
    dq.tier,
  ).then(response => {
    if (!response) {
      const tier = tierData.find(t => t.value === dq.tier)?.label;
      setError({
        show: true,
        text: `Client Checklist Not found. ${dq.customerId ? `Client: ${dq.customerId}` : ''} ${tier || ''}`,
      });
      return [[], ''];
    }
    const documentsToCreate = response.items.items.filter(
      item =>
        !dq.driverQualificationChecklistDocuments.items.find(
          dqcdi =>
            dqcdi.category === item.category &&
            dqcdi.item === item.item &&
            dqcdi.order === item.order &&
            dqcdi.source === item.source &&
            dqcdi.type === item.type,
        ),
    );
    return Promise.all(
      documentsToCreate.map(documentToCreate =>
        createChecklistDocument({
          ...documentToCreate,
          id,
        }),
      ),
    ).then(() =>
      getDQByID(id).then(newDQ => [
        [...(newDQ?.driverQualificationChecklistDocuments?.items?.sort((a, b) => a.order - b.order) || [])],
        response.checklistDocumentName,
      ]),
    );
  });

const autoSelectDocuments = async ({ newRows, dq, types, sources, categories, checklistName }) => {
  // WE WHERE DOING THIS BEFORE: Get the auto selected documents and the not selected documents for auto selecting them
  // const autoSelectedOrNotSelectedDocuments = newRows.filter(
  //   document =>
  //     (document.documentName &&
  //       document?.driverQualificationSelectedDocuments?.items?.find(item => item.documentAutoSelected)) ||
  //     !document.documentName,
  // );

  // Get the documents that are not selected:
  const notSelectedDocuments = newRows.filter(document => !document.documentName);
  // If there is something to auto select...
  if (notSelectedDocuments.length) {
    // Select the documents separatelly
    await Promise.all(
      notSelectedDocuments.map(document =>
        autoSelectDocument({
          document,
          dq,
          types,
          sources,
          categories,
          checklistName,
        }),
      ),
    );
    // Grab all the selected documents and attach them to the DQ
    const newDQ = await getDQByID(dq.id);
    const allSelectedDocuments =
      newDQ?.driverQualificationChecklistDocuments?.items
        ?.filter(item => !!item.documentName)
        .sort((a, b) => a.order - b.order) || [];
    await dqSelectRest(
      allSelectedDocuments.map(doc => ({
        documentName: `public/dqf-assembling/${doc.documentName}`,
        IncludePages: 'All',
      })),
      `public/dqf-assembling/${dq.id}.pdf`,
    );
  } else {
    // Check if the document is attached to the DQ
    const config = {
      expires: process.env.REACT_APP_AMPLIFY_STORAGE_GET_EXPIRES,
      level: 'public',
      download: false,
    };
    const response = await Storage.get(`dqf-assembling/${dq.id}.pdf`, config);
    const result = await fetch(response);
    if (result.status !== 200) {
      // If there is no document attached, grab all the selected documents and attach them to the DQ
      const newDQ = await getDQByID(dq.id);
      const allSelectedDocuments =
        newDQ?.driverQualificationChecklistDocuments?.items
          ?.filter(item => !!item.documentName)
          .sort((a, b) => a.order - b.order) || [];
      await dqSelectRest(
        allSelectedDocuments.map(doc => ({
          documentName: `public/dqf-assembling/${doc.documentName}`,
          IncludePages: 'All',
        })),
        `public/dqf-assembling/${dq.id}.pdf`,
      );
      return [];
    }
    return [];
  }
  return [];
};

const autoSelectDocument = async ({ document, dq, types, sources, categories, checklistName }) => {
  const type = types.find(typ => typ.id === document.type)?.Name;
  const source = sources.find(sou => sou.id === document.source)?.name;
  const category = categories.find(cat => cat.id === document.category)?.name;

  let allDocuments = [];
  if (document.category === '0') {
    allDocuments = [{ ...document, documentName: checklistName }];
  }

  if (source && type && (source === 'Centerline' || source === 'Tenstreet' || document.category === '0')) {
    const For = types?.find(item => item.Name === type)?.For;
    const fetchingDocumentsFunction = source === 'Centerline' ? getStandardDocuments : getAtsDriverDocuments;
    let documentsToFetch = [];

    if (source === 'Centerline') {
      if (For === 'Client' || For === 'All Client') {
        documentsToFetch = [[category, 0, 0, 'All Client', type], [category, dq?.customerId, 0, For, type]];
      }
      if (For === 'Driver') {
        documentsToFetch = [[category, 0, dq?.driverId, For, type]];
      }
    } else if (source === 'Tenstreet') {
      documentsToFetch = [[category, dq?.driverId, source, type]];
    }
    const documentsFetched = await Promise.all(documentsToFetch.map(doc => fetchingDocumentsFunction(...doc)));
    allDocuments = [...allDocuments, ...documentsFetched.reduce((acc, item) => [...acc, ...item], [])]
      .filter(doc => doc.documentName)
      .sort((a, b) => Number(b.id) - Number(a.id));

    if (allDocuments.length !== 0) {
      const latestDocument = allDocuments[0];
      const response = await dqSelectRest(
        [
          {
            documentName: `public/${
              document.category === '0' ? '' : source === 'Centerline' ? 'standard-documents/' : 'ats-documents/'
            }${document.category === '0' ? checklistName : latestDocument?.documentName}`,
            IncludePages: 'All',
          },
        ],
        `public/dqf-assembling/${dq.id}-${document.source}-${document.category}-${document.type}.pdf`,
      );
      if (response?.response_code === 200) {
        await updateChecklistAndCreateOrUploadDocument({ document, dq, item: latestDocument, For });
      }
    }
  }
};

const updateChecklistAndCreateOrUploadDocument = ({ document, dq, item, For }) =>
  updateChecklistDocuments({
    documentName: `${dq.id}-${document.source}-${document.category}-${document.type}.pdf`,
    id: document.id,
  }).then(() => {
    const createOrUploadFunction = item.driverQualificationDriverQualificationChecklistDocumentsId
      ? updateDocuments
      : createDocuments;
    return createOrUploadFunction({
      id: item.driverQualificationDriverQualificationChecklistDocumentsId ? item.id : undefined,
      customerId: dq.customerId,
      documentName: item?.documentName,
      documentAutoSelected: true,
      driverId: dq.driverId,
      driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId: document.id,
      for: For,
      item: document.item,
      source: document.source,
      pages: (item.driverQualificationDriverQualificationChecklistDocumentsId && item?.pages) || 'All',
      category: document.category,
      type: document.type,
      order: item.order || 1,
    });
  });

export default selectDocumentsAndAutoSelect;
