import React, { useMemo } from "react";

import Select from 'react-select';
import { Col, Button, Form, Spinner, Row } from 'react-bootstrap';
import { Checkbox } from '@mui/material';
import "./styles.scoped.css";

import dayjs from 'dayjs';

const ViewFormContent = ({
    handleCancel,
    messageCharCount,
    messageCharLimit,
    customStyles,
    recipientStatuses,
    form,
    branches,
    lobs,
    headerTitle,
    subjectCharCount,
    subjectCharLimit,
    isLoadingBranches,
    isLoadingLOBS,
    isLoadingRecipientStatus,
    convertBranchArrayToObject,
    convertLOBSArrayToObject,
    convertRecipientStatusArrayToObject,
}) => {

    const utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);


    const driverStatusesOptions = useMemo(
        () =>
            recipientStatuses?.map(({ REF_CODE, REF_DESCRIPTION }) => ({
                label: REF_DESCRIPTION,
                value: REF_CODE,
                key: REF_CODE,
            })),
        [recipientStatuses],
    );

    const branchesOptions = useMemo(
        () =>
            branches?.map(({ BranchName, Branch }) => ({
                label: BranchName,
                value: Branch,
                key: Branch,
            })),
        [branches],
    );

    const lobsOptions = useMemo(
        () =>
            lobs?.map(({ LineOfBusinessCode, LineOfBusinessDesc }) => ({
                label: LineOfBusinessCode,
                value: LineOfBusinessDesc,
                key: LineOfBusinessCode,
            })),
        [lobs],
    );

    return (
        <div className="wrap">
            <Row>
                <Col>
                    <div className="window-header">
                        <span>{headerTitle}</span>
                    </div>
                    <div className="window-container">
                        <div style={{ marginTop: '30px' }}>
                            <Row className="mb-3 ml-2">
                                <Col className="col-2">
                                    <Form.Label>
                                        <div className="required">Branch</div>
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <div className="dropdown-spinner-container">
                                        <Select
                                            options={branchesOptions}
                                            isMulti
                                            styles={customStyles('15%')}
                                            isDisabled={true}
                                            value={convertBranchArrayToObject(form?.branch)}
                                        />
                                        <div className="dropdown-spinner-container">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                aria-hidden="true"
                                                className="dropdown-spinner"
                                                hidden={!isLoadingBranches}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-2">
                                    <Form.Label>
                                        <div className="required">LOB</div>
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <div className="dropdown-spinner-container">
                                        <Select
                                            value={convertLOBSArrayToObject(form?.lob)}
                                            styles={customStyles('15%')}
                                            options={lobsOptions}
                                            isMulti
                                            isDisabled={true}
                                        />
                                        <div className="dropdown-spinner-container">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                aria-hidden="true"
                                                className="dropdown-spinner"
                                                hidden={!isLoadingLOBS}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-3 ml-2">
                                <Col className="col-2">
                                    <Form.Label>
                                        <span className="required">Subject</span>
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        key="subject"
                                        style={{
                                            width: "35%"
                                        }}
                                        type="input"
                                        maxLength={subjectCharLimit}
                                        name="subject"
                                        value={form?.subject}
                                        disabled
                                    />
                                    <span style={{ fontSize: '14px' }}>{`${subjectCharCount}/${subjectCharLimit} characters remaining.`}</span>
                                </Col>
                            </Row>
                        </div>

                        <Row className="mb-3 ml-2">
                            <Col className="col-2">
                                <Form.Label>
                                    <span className="required">Message</span>
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    style={{
                                        width: '50%',
                                        height: '75px',
                                    }}
                                    as="textarea"
                                    maxLength={messageCharLimit}
                                    name="message"
                                    value={form?.message}
                                    disabled
                                />
                                <span style={{ fontSize: '14px' }}>{`${messageCharCount}/${messageCharLimit} characters remaining.`}</span>
                            </Col>
                        </Row>

                        <Row className="mb-3 ml-2">
                            <Col className="col-2">
                                <Form.Label>
                                    <div>URL</div>
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    key="subject"
                                    style={{
                                        width: '35%',
                                        height: '30px',
                                    }}
                                    type="input"
                                    name="url"
                                    value={form?.url}
                                    disabled
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3 ml-2">
                            <Col className="col-2">
                                <Form.Label>
                                    <div className="required">Recipient Status</div>
                                </Form.Label>
                            </Col>
                            <Col>
                                <div className="dropdown-spinner-container">
                                    <Select
                                        options={driverStatusesOptions}
                                        isMulti
                                        styles={customStyles('300px')}
                                        isDisabled={true}
                                        value={convertRecipientStatusArrayToObject(form?.recipientStatus)}
                                    />
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        aria-hidden="true"
                                        className="dropdown-spinner"
                                        hidden={!isLoadingRecipientStatus}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-3 ml-2">
                            <Col className="col-2"></Col>
                            <Col className="row align-items-center">
                                <Checkbox
                                    checked={form?.status === "Sent" ? true : false}
                                    style={{
                                        color: 'red',
                                        height: 0,
                                        marginLeft: '15px',
                                        marginRight: '15px',
                                        width: 0
                                    }}
                                    disabled
                                />
                                <span><strong>Approved to send </strong>(Checking this box and clicking Save will send the Notification.)</span>
                                {form?.createdBy && form?.sentOn && <span style={{ fontSize: '12px', width: '100%', marginLeft: '15px' }}>{`By ${form?.createdBy.split(' ').slice(-1).join('')}, ${form?.createdBy.split(' ').slice(0, -1).join('')} and sent on ${dayjs.utc(form?.sentOn).format('MM/DD/YYYY')}`}</span>}

                            </Col>
                        </Row>
                    </div>
                </Col >
            </Row>
            <Row className="justify-content-center mt-5">
                <Button
                    className="button-secondary ml-4"
                    onClick={() => { handleCancel() }}
                >Cancel
                </Button>
            </Row>
        </div >
    )
}

export default ViewFormContent;