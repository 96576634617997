import React, { useMemo, useState, useEffect } from 'react';

import { Row, Form, InputGroup } from 'react-bootstrap';
import { MdWarning } from 'react-icons/md';
import Select from 'react-select';

import { ExpenseReceiptUploader } from '../../../../components';
import { DisableClickOverlay, isBilledOrTransmitted } from '../../../../utils/utilities';
import DocumentRow from '../documentRow';
import { uploadExpenseFile } from '../../DriverSolutions/operations';

import './styles.scoped.css';

const Receipt = ({
  receipt,
  id,
  isCR,
  receipts,
  receiptsErrors,
  customStyles,
  setUploading,
  setUploadError,
  form,
  handleDeleteRow,
  carRentalOptions,
  setReceiptFields,
  setReceiptField,
  debouncedSetReceiptField,
  setReceiptFile,
  deleteDocumentFromReceipt,
  expenseId,
}) => {

  const [receiptAmountValue, setReceiptAmountValue] = useState(0);
  const [markupValue, setMarkupValue] = useState(0);
  const [dailyChargeValue, setDailyChargeValue] = useState(0);

  const handleReceiptAmountBlur = () => {
    if (receiptAmountValue === '') {
      setReceiptAmountValue(0);
    }
  };

  const handleMarkupBlur = () => {
    if (markupValue === '') {
      setMarkupValue(0);
    }
  };

  const handleDailyChargBlur = () => {
    if (dailyChargeValue === '') {
      setDailyChargeValue(0);
    }
  };

  useEffect(() => {
    if (receipt.receiptAmount !== undefined && receipt.receiptAmount !== null) {
      setReceiptAmountValue(receipt.receiptAmount);
    } else {
      setReceiptAmountValue(0);
    }
  }, [receipt.receiptAmount]);

  useEffect(() => {
    if (receipt.markup !== undefined && receipt.markup !== null) {
      setMarkupValue(receipt.markup);
    } else {
      setMarkupValue(0);
    }
  }, [receipt.markup]);

  useEffect(() => {
    if (receipt.dailyCharge !== undefined && receipt.dailyCharge !== null) {
      setDailyChargeValue(receipt.dailyCharge);
    } else {
      setDailyChargeValue(0);
    }
  }, [receipt.dailyCharge]);

  const customCarRentalOptions = useMemo(() => {
    const result = carRentalOptions || [];
    if (
      receipt.expenseItemCarRentalCompanyId &&
      receipt.selectedCar &&
      !result.find(cro => cro.value === receipt.expenseItemCarRentalCompanyId)
    ) {
      result.push({
        label: receipt.selectedCar,
        value: receipt.expenseItemCarRentalCompanyId,
      });
      result?.sort((a, b) => (a.label > b.label ? 1 : -1));
    }
    return result;
  }, [carRentalOptions, receipt.expenseItemCarRentalCompanyId, receipt.selectedCar]);

  return (
    <div className="receipt-wrapper">
      {!receipt.documentName && (
        <div className="warning-container">
          <MdWarning size={36} title="Receipt document was not attached" color="#da0f2e" />
        </div>
      )}
      <DisableClickOverlay isDisabled={isBilledOrTransmitted(form) || isCR || form?.status === 'Voided'}>
        {receipts.length > 1 && (
          <div onClick={() => handleDeleteRow(id)} className="delete-row">
            Delete Row
          </div>
        )}

        <Row className="top-row" style={{ margin: '5px 0 0 0' }}>
          <div className="top-col" style={{ width: '40%', justifyContent: 'flex-start' }}>
            <div className="below-container">
              <Form.Group controlId="expenseItemCarRentalCompanyId">
                <Form.Label style={{ marginTop: '15px' }}>
                  <div className="required">Car Rental Company</div>
                </Form.Label>
                <div className="dropdown-spinner-container">
                  <Select
                    styles={customStyles('300px')}
                    options={customCarRentalOptions}
                    onChange={event => {
                      setReceiptFields(id, [
                        {
                          fieldName: 'expenseItemCarRentalCompanyId',
                          fieldValue: event.value,
                        },
                        {
                          fieldName: 'selectedCar',
                          fieldValue: event.label,
                        },
                      ]);
                    }}
                    value={customCarRentalOptions?.find(car => car.value === receipt.expenseItemCarRentalCompanyId)}
                  />
                </div>
                {receiptsErrors[id]?.expenseItemCarRentalCompanyId && (
                  <div className="error">{receiptsErrors[id]?.expenseItemCarRentalCompanyId}</div>
                )}
              </Form.Group>
            </div>
          </div>
          <div className="top-col">
            <Form.Group controlId="carRentalContractNumber">
              <Form.Label style={{ marginTop: '15px' }}>
                <div className="required"> Rental Contract Number</div>
              </Form.Label>
              <Form.Control
                key={`${id}carRentalContractNumber`}
                style={{
                  width: '300px',
                  height: '30px',
                  paddingLeft: 10,
                }}
                type="input"
                name="carRentalContractNumber"
                defaultValue={receipt?.carRentalContractNumber}
                onBlur={event => {
                  setReceiptField(id, 'carRentalContractNumber', event.target.value, receipts);
                }}
                isInvalid={!!receiptsErrors[id]?.carRentalContractNumber}
              />
              <Form.Control.Feedback type="invalid">
                {receiptsErrors[id]?.carRentalContractNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </Row>
        <Row className="top-row" style={{ margin: '5px 0 0 0' }}>
          <div className="top-col" style={{ width: '40%', justifyContent: 'flex-start' }}>
            <div className="below-container">
              <Form.Group controlId="carRentalPickUpLocation">
                <Form.Label style={{ marginTop: '15px' }}>
                  <div className="required"> Pick Up Location</div>
                </Form.Label>
                <Form.Control
                  key={`${id}carRentalPickUpLocation`}
                  style={{
                    width: '300px',
                    height: '30px',
                    paddingLeft: 10,
                  }}
                  type="input"
                  name="carRentalPickUpLocation"
                  defaultValue={receipt?.carRentalPickUpLocation}
                  onChange={event => {
                    debouncedSetReceiptField({
                      id: id,
                      field: 'carRentalPickUpLocation',
                      value: event.target.value,
                      receipts: receipts,
                    });
                  }}
                  isInvalid={!!receiptsErrors[id]?.carRentalPickUpLocation}
                />
                <Form.Control.Feedback type="invalid">
                  {receiptsErrors[id]?.carRentalPickUpLocation}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="top-col">
            <Form.Group controlId="carRentalReturnLocation">
              <Form.Label style={{ marginTop: '15px' }}>
                <div className="required"> Return Location</div>
              </Form.Label>
              <Form.Control
                key={`${id}carRentalReturnLocation`}
                style={{
                  width: '300px',
                  height: '30px',
                  paddingLeft: 10,
                }}
                type="input"
                name="carRentalReturnLocation"
                defaultValue={receipt?.carRentalReturnLocation}
                onChange={event => {
                  debouncedSetReceiptField({
                    id: id,
                    field: 'carRentalReturnLocation',
                    value: event.target.value,
                    receipts: receipts,
                  });
                }}
                isInvalid={!!receiptsErrors[id]?.carRentalReturnLocation}
              />
              <Form.Control.Feedback type="invalid">
                {receiptsErrors[id]?.carRentalReturnLocation}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </Row>

        <Row className="top-row" style={{ margin: '0' }}>
          <div className="top-col" style={{ width: '40%', justifyContent: 'flex-start' }}>
            <div className="below-container">
              <div className="below-container" style={{ justifyContent: 'flex-start', width: '300px' }}>
                <Form.Group controlId="carRentalPickUpDate">
                  <Form.Label style={{ marginTop: '15px' }}>
                    <div className="required">Pick Up Date</div>
                  </Form.Label>
                  <Form.Control
                    style={{ width: '150px', height: '30px' }}
                    name="carRentalPickUpDate"
                    type="date"
                    as="input"
                    defaultValue={receipt?.carRentalPickUpDate}
                    onChange={event => {
                      debouncedSetReceiptField({
                        id: id,
                        field: 'carRentalPickUpDate',
                        value: event.target.value,
                        receipts: receipts,
                      });
                    }}
                    isInvalid={!!receiptsErrors[id]?.carRentalPickUpDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {receiptsErrors[id]?.carRentalPickUpDate}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="top-col" style={{ justifyContent: 'flex-start' }}>
            <div className="below-container" style={{ justifyContent: 'flex-start', width: '300px' }}>
              <Form.Group controlId="carRentalReturnDate">
                <Form.Label style={{ marginTop: '15px' }}>
                  <div className="required">Return Date</div>
                </Form.Label>
                <Form.Control
                  style={{ width: '150px', height: '30px' }}
                  name="carRentalReturnDate"
                  type="date"
                  as="input"
                  defaultValue={receipt?.carRentalReturnDate}
                  onChange={event => {
                    debouncedSetReceiptField({
                      id: id,
                      field: 'carRentalReturnDate',
                      value: event.target.value,
                      receipts: receipts,
                    });
                  }}
                  isInvalid={!!receiptsErrors[id]?.carRentalReturnDate}
                />
                <Form.Control.Feedback type="invalid">{receiptsErrors[id]?.carRentalReturnDate}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="top-col">
            <Form.Group controlId="projectedDays">
              <Form.Label style={{ marginTop: '15px' }}>Projected Days</Form.Label>
              <Form.Control
                style={{
                  width: '225px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  color: 'gray',
                  paddingLeft: '5px',
                }}
                as="input"
                name="projectedDays"
                readOnly
                disabled
                value={receipt?.projectedDays ? receipt?.projectedDays : '0'}
              />
            </Form.Group>
          </div>
        </Row>
        <Row className="top-row" style={{ margin: '5px 0 0 0' }}>
          <div className="top-col" style={{ width: '40%', justifyContent: 'flex-start' }} />
          <div className="top-col" style={{ justifyContent: 'flex-start' }}>
            <div className="below-container" style={{ justifyContent: 'flex-start', width: '300px' }}>
              <Form.Group controlId="dailyCharge">
                <Form.Label style={{ marginTop: '15px' }}>
                  <div className="required">Daily Charge</div>
                </Form.Label>
                <InputGroup
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '150px',
                  }}>
                  <span
                    style={{
                      position: 'absolute',
                      left: '10px',
                      zIndex: 10000,
                    }}>
                    $
                  </span>
                  <Form.Control
                    key={`${id}dailyCharge`}
                    style={{
                      width: '150px',
                      height: '30px',
                      padding: 0,
                      paddingLeft: '25px',
                    }}
                    type="number"
                    step="0.01"
                    min="0"
                    name="dailyCharge"
                    value={dailyChargeValue}
                    onChange={event => {
                      setDailyChargeValue(event.target.value)
                      debouncedSetReceiptField({
                        id: id,
                        field: 'dailyCharge',
                        value: event.target.value,
                        receipts: receipts,
                      });
                    }}
                    onBlur={handleDailyChargBlur}
                    isInvalid={!!receiptsErrors[id]?.dailyCharge}
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">{receiptsErrors[id]?.dailyCharge}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="top-col">
            <Form.Group controlId="expenseTotal">
              <Form.Label style={{ marginTop: '15px' }}>Projected Amount</Form.Label>
              <InputGroup
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '150px',
                  opacity: '0.7',
                }}>
                <span
                  style={{
                    position: 'absolute',
                    left: '10px',
                    zIndex: 10000,
                  }}>
                  $
                </span>
                <Form.Control
                  style={{
                    width: '225px',
                    height: '30px',
                    padding: 0,
                    border: '0px',
                    backgroundColor: 'white',
                    marginLeft: '20px',
                  }}
                  as="input"
                  name="location"
                  readOnly
                  disabled
                  value={
                    receipt?.projectedAmount
                      ? parseFloat(receipt?.projectedAmount).toFixed(2)
                      : parseFloat(0).toFixed(2)
                  }
                />
              </InputGroup>
            </Form.Group>
          </div>
        </Row>
        <Row className="top-row" style={{ margin: '5px 0 0 0', alignItems: 'start' }}>
          <div className="top-col" style={{ width: '40%', justifyContent: 'flex-start' }}>
            <div className="below-container" style={{ justifyContent: 'flex-end' }}>
              <div
                className="below-container"
                style={{
                  justifyContent: 'space-between',
                  width: '300px',
                }}>
                <Form.Group controlId="receiptAmount">
                  <Form.Label style={{ marginTop: '15px' }}>
                    <div>Receipt Amount</div>
                  </Form.Label>
                  <InputGroup style={{ display: 'flex', alignItems: 'center' }}>
                    <span
                      style={{
                        position: 'absolute',
                        left: '10px',
                        zIndex: 10000,
                      }}>
                      $
                    </span>
                    <Form.Control
                      key={`${id}receiptAmount`}
                      style={{
                        width: '150px',
                        height: '30px',
                        padding: 0,
                        paddingLeft: '25px',
                      }}
                      type="number"
                      step="0.01"
                      min="0"
                      name="receiptAmount"
                      value={receiptAmountValue}
                      onChange={event => {
                        setReceiptAmountValue(event.target.value)
                        debouncedSetReceiptField({
                          id: id,
                          field: 'receiptAmount',
                          value: Number(event.target.value),
                          receipts: receipts,
                        });
                      }}
                      onBlur={handleReceiptAmountBlur}
                      isInvalid={!!receiptsErrors[id]?.receiptAmount}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="markup">
                  <Form.Label style={{ marginTop: '15px' }}>
                    <div className="required">Markup</div>
                  </Form.Label>
                  <InputGroup style={{ display: 'flex', alignItems: 'center' }}>
                    <span
                      style={{
                        position: 'absolute',
                        right: '10px',
                        zIndex: 10000,
                      }}>
                      %
                    </span>
                    <Form.Control
                      style={{
                        width: '75px',
                        height: '30px',
                        padding: 0,
                        paddingLeft: '10px',
                      }}
                      type="number"
                      step="1"
                      min="0"
                      name="markup"
                      value={markupValue}
                      onChange={event => {
                        setMarkupValue(event.target.value)
                        debouncedSetReceiptField({
                          id: id,
                          field: 'markup',
                          value: event.target.value,
                          receipts: receipts,
                        });
                      }}
                      onBlur={handleMarkupBlur}
                      isInvalid={!!receiptsErrors[id]?.markup}
                    />
                  </InputGroup>

                  <Form.Control.Feedback type="invalid">{receiptsErrors[id]?.markup}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="top-col">
            <Form.Group controlId="expenseTotal">
              <Form.Label style={{ marginTop: '15px' }}>Expense Total</Form.Label>
              <InputGroup
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '150px',
                  opacity: '0.7',
                }}>
                <span
                  style={{
                    position: 'absolute',
                    left: '10px',
                    zIndex: 10000,
                  }}>
                  $
                </span>
                <Form.Control
                  style={{
                    width: '225px',
                    height: '30px',
                    padding: 0,
                    border: '0px',
                    backgroundColor: 'white',
                    marginLeft: '20px',
                  }}
                  as="input"
                  name="location"
                  readOnly
                  disabled
                  value={
                    receipt?.expenseTotal ? parseFloat(receipt?.expenseTotal).toFixed(2) : parseFloat(0).toFixed(2)
                  }
                />
              </InputGroup>
            </Form.Group>
          </div>
          {!receipt?.documentName && expenseId ? (
            <div className="top-col">
              <Form.Group controlId="receipt" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label style={{ marginTop: '15px' }}>
                  <div>Receipt</div>
                </Form.Label>

                <Form.Control
                  name="receipt"
                  as="span"
                  style={{
                    border: 'none',
                    outline: 'none',
                    paddingLeft: 0,
                    height: 'auto',
                  }}>
                  <ExpenseReceiptUploader
                    func={uploadExpenseFile}
                    setReceiptFile={setReceiptFile}
                    receiptId={id}
                    uploadStart={() => setUploading(true)}
                    uploadEnd={() => setUploading(false)}
                    uploadError={() => {
                      setUploading(false);
                      setUploadError('Upload failed. Please ensure you have a network connection and try again.');
                    }}
                    expenseId={expenseId}
                  />
                </Form.Control>
              </Form.Group>
            </div>
          ) : null
          }
        </Row>
      </DisableClickOverlay>
      {receipt?.documentName && (
        <DocumentRow
          id={id}
          deleteDocumentFromReceipt={deleteDocumentFromReceipt}
          receipt={receipt}
          disabled={isBilledOrTransmitted(form) || form.status === 'Voided' || isCR}
        />
      )}
      <div className="gray-divider-light" />
    </div>
  );
};

export default Receipt;
