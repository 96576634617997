import { Row, Form, Spinner } from 'react-bootstrap';
import { customStyles } from '../utils';
import Select from 'react-select';
import './styles.scoped.css';

export const ContractActivityForm = ({
  form,
  setForm,
  isLoadingBranches,
  isLoadingCAS,
  isLoadingLOB,
  lobOptions,
  casOptions,
  branchOptions,
  handleChange,
}) => {
  return (
    <div className="filters-container">
      <div
        className="filter-row"
        style={{
          marginTop: '30px',
        }}
      >
        <Form.Group
          as={Row}
          controlId="FromPerEnddate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>
            <div className="required">Contract Act. Start Date </div>
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            type="date"
            name="FromPerEnddate"
            value={form?.FromPerEnddate}
            onChange={handleChange}
            onBlur={null}
          />
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="ToPerEnddate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label
            style={{
              width: '20px',
              marginLeft: '30px',
              marginRight: '20px',
            }}
          >
            To
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
            }}
            as="input"
            type="date"
            name="ToPerEnddate"
            value={form?.ToPerEnddate}
            onChange={handleChange}
            onBlur={null}
          />
        </Form.Group>
      </div>
      <p style={{ fontWeight: 700 }}>
        One or more of the following to refine (not required)
      </p>
      <div className="filter-row">
        <Form.Group as={Row} style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>
            Contract Activity Status
          </Form.Label>
          <Select
            isDisabled={isLoadingCAS}
            isMulti
            closeMenuOnSelect={false}
            styles={customStyles('365px')}
            options={casOptions}
            defaultValue={form?.CAS?.split(',').map(code =>
              casOptions.find(option => option.value === code),
            )}
            onChange={selectedOptions => {
              const selectedCodes = selectedOptions.map(option => option.value);
              setForm({
                ...form,
                CAS: selectedCodes.join(','),
              });
            }}
          />
          <Spinner
            as="span"
            animation="border"
            size="sm"
            aria-hidden="true"
            className="dropdown-spinner"
            hidden={!isLoadingCAS}
          />
        </Form.Group>
      </div>

      <div className="filter-row">
        <Form.Group as={Row} style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>
            Contract Activity LOB
          </Form.Label>
          <Select
            isDisabled={isLoadingLOB}
            isMulti
            closeMenuOnSelect={false}
            styles={customStyles('365px')}
            options={lobOptions}
            defaultValue={form?.LOB?.split(',').map(code =>
              lobOptions.find(option => option.value === code),
            )}
            onChange={selectedOptions => {
              const selectedCodes = selectedOptions.map(option => option.value);
              setForm({
                ...form,
                LOB: selectedCodes.join(','),
              });
            }}
          />
          <Spinner
            as="span"
            animation="border"
            size="sm"
            aria-hidden="true"
            className="dropdown-spinner"
            hidden={!isLoadingLOB}
          />
        </Form.Group>
      </div>
      <div className="filter-row">
        <Form.Group as={Row} style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>
            Contract Act. Branch Number
          </Form.Label>
          <Select
            isDisabled={isLoadingBranches}
            isMulti
            closeMenuOnSelect={false}
            styles={customStyles('365px')}
            options={branchOptions}
            defaultValue={form?.branches
              ?.split(',')
              .map(code =>
                branchOptions.find(option => String(option.value) === code),
              )}
            onChange={selectedOptions => {
              const selectedCodes = selectedOptions.map(option => option.value);
              setForm({
                ...form,
                branches: selectedCodes.join(','),
              });
            }}
          />
          <Spinner
            as="span"
            animation="border"
            size="sm"
            aria-hidden="true"
            className="dropdown-spinner"
            hidden={!isLoadingBranches}
          />
        </Form.Group>
      </div>
      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="FromEmplLastName"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Driver Last Name</Form.Label>
          <Form.Control
            style={{
              width: '360px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            name="FromEmplLastName"
            defaultValue={form?.FromEmplLastName}
            onChange={handleChange}
          />
        </Form.Group>
      </div>

      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="FromEmplFirstName"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Driver First Name</Form.Label>
          <Form.Control
            style={{
              width: '360px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            name="FromEmplFirstName"
            defaultValue={form?.FromEmplFirstName}
            onChange={handleChange}
          />
        </Form.Group>
      </div>

      <div className="filter-row">
        <Form.Group as={Row} style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>Driver Number</Form.Label>
          <Form.Control
            style={{
              width: '360px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            name="FromEmplID"
            value={form?.FromEmplID}
            onChange={handleChange}
            type="number"
          />
        </Form.Group>
      </div>
    </div>
  );
};
