import React from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Row, Form, InputGroup } from 'react-bootstrap';

import DriverAssignmentsForm from '../../../../components/ExpenseForm/driverAssignmentsForm';
import { isBilledOrTransmitted } from '../../../../utils/utilities';

import './styles.scoped.css';

dayjs.extend(customParseFormat);

const SharedReceipt = ({
  receipt,
  id,
  sharedReceipts,
  getTotalProjectedAmount,
  setSharedReceipts,
  sharedReceiptErrors,
  setSharedReceiptsErrors,
  receiptsErrors,
  handleDeleteRow,
  form,
  setForm,
}) => {
  const setSharedReceiptField = (scopedId, field, value) => {
    const receiptsCopy = [...sharedReceipts];
    if (field === 'receiptAmount') {
      if (receiptsCopy[scopedId]?.markup) {
        const multiplier = (1 + parseFloat(receiptsCopy[scopedId]?.markup).toFixed(2) / 100).toFixed(2);

        receiptsCopy[scopedId].expenseTotal = parseFloat(value) * multiplier;
      } else {
        receiptsCopy[scopedId].expenseTotal = value;
      }
    }
    if (field === 'markup' && receiptsCopy[scopedId]?.receiptAmount) {
      const multiplier = (1 + parseFloat(value).toFixed(2) / 100).toFixed(2);

      receiptsCopy[scopedId].expenseTotal = parseFloat(receiptsCopy[scopedId]?.receiptAmount) * multiplier;
    }

    if (
      (field === 'checkInDate' && receiptsCopy[scopedId]?.lastNightOfStay) ||
      (field === 'lastNightOfStay' && receiptsCopy[scopedId]?.checkInDate)
    ) {
      let difference;
      if (field === 'checkInDate') {
        difference = dayjs(receiptsCopy[scopedId]?.lastNightOfStay)?.diff(value, 'day') || 0;
      }
      if (field === 'lastNightOfStay') {
        difference = dayjs(value)?.diff(receiptsCopy[scopedId]?.checkInDate, 'day') || 0;
      }
      if (difference && receiptsCopy[scopedId]?.dailyCharge) {
        receiptsCopy[scopedId].projectedAmount = (difference + 1) * receiptsCopy[scopedId].dailyCharge;
        getTotalProjectedAmount(receiptsCopy);
      }
      receiptsCopy[scopedId][field] = value;
      receiptsCopy[scopedId].projectedDays = difference + 1;
      setSharedReceipts(receiptsCopy);
    } else {
      if (field === 'dailyCharge') {
        receiptsCopy[scopedId].projectedAmount = receiptsCopy[scopedId].projectedDays * value;
        getTotalProjectedAmount(receiptsCopy);
      }
      receiptsCopy[scopedId][field] = value;
      setSharedReceipts(receiptsCopy);
    }

    if (sharedReceiptErrors && !!sharedReceiptErrors[scopedId]?.[field]) {
      const errorsCopy = {
        ...sharedReceiptErrors[scopedId],
      };
      delete errorsCopy[field];
      const allErrors = [...sharedReceiptErrors];
      if (!Object.keys(errorsCopy).length) {
        allErrors.splice(scopedId, 1);
      } else {
        allErrors[scopedId] = errorsCopy;
      }
      setSharedReceiptsErrors(allErrors);
    }
  };

  const setSharedReceiptFields = (scopedId, fields) => {
    const receiptsCopy = [...sharedReceipts];
    const errorsCopy = {
      ...sharedReceiptErrors[scopedId],
    };
    fields.forEach(field => {
      receiptsCopy[scopedId][(field?.fieldName)] = field.fieldValue;
      if (receiptsErrors && !!receiptsErrors[scopedId]?.[field]) {
        delete errorsCopy[field.fieldName];
        const allErrors = [...receiptsErrors];
        if (!Object.keys(errorsCopy).length) {
          allErrors.splice(scopedId, 1);
        } else {
          allErrors[scopedId] = errorsCopy;
        }
        setSharedReceiptsErrors(allErrors);
      }
    });
    setSharedReceipts(receiptsCopy);
  };

  return (
    <div className="receipt-wrapper">
      {sharedReceipts.length > 1 && (
        <div onClick={() => handleDeleteRow(id)} className="delete-row">
          Delete Row
        </div>
      )}
      <DriverAssignmentsForm
        setReceiptField={setSharedReceiptField}
        setReceiptFields={setSharedReceiptFields}
        sharedReceiptErrors={sharedReceiptErrors}
        receipt={receipt}
        id={id}
        billedOrTransmitted={isBilledOrTransmitted(form)}
        customerId={form?.customerId}
        form={form}
        setForm={setForm}
      />

      <Row className="top-row" style={{ margin: '0' }}>
        <div
          className="top-col"
          style={{
            width: '40%',
            justifyContent: 'flex-start',
          }}>
          <div className="below-container">
            <div
              className="below-container"
              style={{
                justifyContent: 'flex-start',
                width: '300px',
              }}>
              <Form.Group controlId="checkInDate">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">Check In Date</div>
                </Form.Label>
                <Form.Control
                  style={{
                    width: '150px',
                    height: '30px',
                  }}
                  name="checkInDate"
                  type="date"
                  as="input"
                  defaultValue={receipt?.checkInDate}
                  onBlur={event => {
                    setSharedReceiptField(id, 'checkInDate', event.target.value);
                  }}
                  isInvalid={!!sharedReceiptErrors[id]?.checkInDate}
                />
                <Form.Control.Feedback type="invalid">{sharedReceiptErrors[id]?.checkInDate}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        </div>
        <div
          className="top-col"
          style={{
            justifyContent: 'flex-start',
          }}>
          <div
            className="below-container"
            style={{
              justifyContent: 'flex-start',
              width: '300px',
            }}>
            <Form.Group controlId="lastNightOfStay">
              <Form.Label
                style={{
                  marginTop: '15px',
                }}>
                <div className="required">Last Night of Stay</div>
              </Form.Label>
              <Form.Control
                style={{
                  width: '150px',
                  height: '30px',
                }}
                name="lastNightOfStay"
                type="date"
                as="input"
                defaultValue={receipt?.lastNightOfStay}
                onBlur={event => {
                  setSharedReceiptField(id, 'lastNightOfStay', event.target.value);
                }}
                isInvalid={!!sharedReceiptErrors[id]?.lastNightOfStay}
              />
              <Form.Control.Feedback type="invalid">{sharedReceiptErrors[id]?.lastNightOfStay}</Form.Control.Feedback>
            </Form.Group>
          </div>
          <Form.Group
            controlId="projectedDays"
            style={{
              marginLeft: '75px',
            }}>
            <Form.Label
              style={{
                marginTop: '15px',
              }}>
              Projected Days
            </Form.Label>
            <Form.Control
              style={{
                width: '225px',
                height: '30px',
                padding: 0,
                border: '0px',
                backgroundColor: 'white',
                color: 'gray',
                paddingLeft: '5px',
              }}
              as="input"
              name="projectedDays"
              disabled
              readOnly
              value={receipt?.projectedDays ? receipt?.projectedDays : '0'}
            />
          </Form.Group>
        </div>
        <div className="top-col">
          <Form.Group controlId="expenseTotal">
            <Form.Label
              style={{
                marginTop: '15px',
              }}>
              Projected Amount
            </Form.Label>
            <InputGroup
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '150px',
                opacity: '0.7',
              }}>
              <span
                style={{
                  position: 'absolute',
                  left: '10px',
                  zIndex: 10000,
                }}>
                $
              </span>
              <Form.Control
                style={{
                  width: '225px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  marginLeft: '20px',
                }}
                as="input"
                name="location"
                readOnly
                disabled
                value={
                  receipt?.projectedAmount ? parseFloat(receipt?.projectedAmount).toFixed(2) : parseFloat(0).toFixed(2)
                }
              />
            </InputGroup>
          </Form.Group>
        </div>
      </Row>

      <Row
        className="top-row"
        style={{
          margin: '5px 0 0 0',
        }}>
        <div
          className="top-col"
          style={{
            width: '40%',
            justifyContent: 'flex-start',
          }}>
          <div
            className="below-container"
            style={{
              justifyContent: 'flex-end',
            }}>
            <div
              className="below-container"
              style={{
                justifyContent: 'space-between',
                width: '300px',
              }}>
              {' '}
              <Form.Group controlId="dailyCharge">
                <Form.Label
                  style={{
                    marginTop: '15px',
                  }}>
                  <div className="required">Daily Charge</div>
                </Form.Label>
                <InputGroup
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '150px',
                  }}>
                  <span
                    style={{
                      position: 'absolute',
                      left: '10px',
                      zIndex: 10000,
                    }}>
                    $
                  </span>
                  <Form.Control
                    key={`${id}dailyCharge`}
                    style={{
                      width: '150px',
                      height: '30px',
                      padding: 0,
                      paddingLeft: '25px',
                    }}
                    type="input"
                    name="dailyCharge"
                    defaultValue={receipt?.dailyCharge}
                    onBlur={event => {
                      setSharedReceiptField(id, 'dailyCharge', event.target.value);
                    }}
                    isInvalid={!!sharedReceiptErrors[id]?.dailyCharge}
                  />
                </InputGroup>
                {sharedReceiptErrors[id]?.dailyCharge && (
                  <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                    {sharedReceiptErrors[id]?.dailyCharge}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="top-col">
          <Form.Group controlId="receiptAmount">
            <Form.Label
              style={{
                marginTop: '15px',
              }}>
              <div className="required">Receipt Amount</div>
            </Form.Label>
            <InputGroup
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <span
                style={{
                  position: 'absolute',
                  left: '10px',
                  zIndex: 10000,
                }}>
                $
              </span>
              <Form.Control
                key={`${id}receiptAmount`}
                style={{
                  width: '150px',
                  height: '30px',
                  padding: 0,
                  paddingLeft: '25px',
                }}
                type="input"
                name="receiptAmount"
                defaultValue={receipt?.receiptAmount}
                onBlur={event => {
                  setSharedReceiptField(id, 'receiptAmount', event.target.value);
                }}
                isInvalid={!!sharedReceiptErrors[id]?.receiptAmount}
              />
            </InputGroup>

            {sharedReceiptErrors[id]?.receiptAmount && (
              <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                {sharedReceiptErrors[id]?.receiptAmount}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group
            controlId="markup"
            style={{
              marginLeft: '75px',
            }}>
            <Form.Label
              style={{
                marginTop: '15px',
              }}>
              <div className="required">Markup</div>
            </Form.Label>
            <InputGroup
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <span
                style={{
                  position: 'absolute',
                  right: '10px',
                  zIndex: 10000,
                }}>
                %
              </span>
              <Form.Control
                style={{
                  width: '75px',
                  height: '30px',
                  padding: 0,
                  paddingLeft: '10px',
                }}
                type="input"
                name="markup"
                defaultValue={receipt?.markup}
                onBlur={event => {
                  setSharedReceiptField(id, 'markup', event.target.value);
                }}
                isInvalid={!!sharedReceiptErrors[id]?.markup}
              />
            </InputGroup>
            <Form.Control.Feedback type="invalid">{sharedReceiptErrors[id]?.markup}</Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="top-col">
          <Form.Group controlId="expenseTotal">
            <Form.Label
              style={{
                marginTop: '15px',
              }}>
              Expense Total
            </Form.Label>
            <InputGroup
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '150px',
                opacity: '0.7',
              }}>
              <span
                style={{
                  position: 'absolute',
                  left: '10px',
                  zIndex: 10000,
                }}>
                $
              </span>
              <Form.Control
                style={{
                  width: '225px',
                  height: '30px',
                  padding: 0,
                  border: '0px',
                  backgroundColor: 'white',
                  marginLeft: '20px',
                }}
                as="input"
                name="location"
                readOnly
                disabled
                value={receipt?.expenseTotal ? parseFloat(receipt?.expenseTotal).toFixed(2) : parseFloat(0).toFixed(2)}
              />
            </InputGroup>

            <Form.Control.Feedback type="invalid" />
          </Form.Group>
        </div>
      </Row>
      <div className="gray-divider-light" />
    </div>
  );
};

export default SharedReceipt;
