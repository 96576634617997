import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { Button, Container, Form, Row } from 'react-bootstrap';
import '../styles.scoped.css';

const SignIn = ({ nextStep, setRequiredAttributes, userData }) => {
    const passwordRef = useRef(null);
    const usernameRef = useRef(null);
    const [error, setErrorMessage] = useState('');

    const corporateLogin = async () => {
        Auth.federatedSignIn({ customProvider: 'Centerline' }).catch((e) => {
            console.log('e', e);
            setErrorMessage(e.message);
        })
    };

    const signIn = (payload) => {
        Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
        Auth.signIn(payload?.username, payload?.password)
            .then(user => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    nextStep({
                        ...userData,
                        nextStep: 'NewPassword',
                        newPassword: '',
                        password: '',
                        user: user,
                    });
                }

                if (user.challengeName === 'CUSTOM_CHALLENGE') {
                    if (user.challengeParam.customMfaChallenge === 'PICK_MFA_FACTOR') {
                        setRequiredAttributes(JSON.parse(user.challengeParam.publicChallengeParameters));
                        nextStep({
                            ...userData,
                            nextStep: 'MFACode',
                            user: user,
                        });
                    }
                }
            })
            .catch(e => {
                console.log('e: ', e);
                if (e.message === 'Username cannot be empty') {
                    setErrorMessage('Username cannot be empty');
                }
                if (e.code === 'NotAuthorizedException') {
                    setErrorMessage(e.code);
                }
                if (e.code === 'PasswordResetRequiredException') {
                    Auth.forgotPassword(userData?.username).then((user) => {
                        if (user) {
                            nextStep({
                                ...userData,
                                CodeDeliveryDetails: user.CodeDeliveryDetails,
                                nextStep: 'ForgotPasswordSubmit',
                                newPassword: '',
                                password: '',
                                user: user,
                                username: userData?.username,
                            });
                        }
                    }).catch((e) => {
                        console.log('error', e);
                        setErrorMessage(e.code);
                    })
                }
            });
    };

    return (
        <Container className="wrap">
            <div className="container-border">
                <div className="container">
                    <img style={{ width: '70%', height: '75px' }} src={require('../../../assets/cl_login.jpg')} />
                    <Row className="d-flex justify-content-center pt-5">
                        <div className="signInCooporateID">
                            <div>Sign in with your corporate ID</div>
                            <Button type="button" onClick={corporateLogin} className="btn btn-block">
                                Centerline
                            </Button>
                        </div>
                        <div>
                            <div className="divider" />
                            <p className="divider-text">or</p>
                            <div className="divider" />
                        </div>
                        <div className="signInEmailPassword">
                            <Form.Label>Sign in with your email or password</Form.Label>
                            <Form.Group>
                                {error === 'NotAuthorizedException' && (
                                    <p className="border border-danger p-2 text-danger">Incorrect username and password</p>
                                )}
                                {error === 'Username cannot be empty' && (
                                    <p className="border border-danger p-2 text-danger">Username cannot be empty</p>
                                )}
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    ref={usernameRef}
                                    as="input"
                                    name="Username"
                                    type="email"
                                    defaultValue={userData?.username}
                                    placeholder="name@host.com"
                                    onChange={() => {
                                        setErrorMessage('');
                                        if (navigator.userAgent.indexOf("Edg") !== -1) {
                                            // Edge-specific code here
                                            if (usernameRef.current.value) {
                                                nextStep({
                                                    ...userData,
                                                    username: usernameRef.current.value,
                                                });
                                            }
                                        }
                                    }}
                                    onBlur={event => {
                                        nextStep({
                                            ...userData,
                                            username: event.target.value,
                                        });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    ref={passwordRef}
                                    as="input"
                                    name="Password"
                                    defaultValue={userData?.password}
                                    placeholder="Password"
                                    type="password"
                                    onChange={() => {
                                        setErrorMessage('');
                                        if (navigator.userAgent.indexOf("Edg") !== -1) {
                                            // Edge-specific code here
                                            if (usernameRef.current.value) {
                                                nextStep({
                                                    ...userData,
                                                    username: usernameRef.current.value,
                                                });
                                            }
                                        }
                                    }}
                                    onBlur={event => {
                                        nextStep({
                                            ...userData,
                                            password: event.target.value,
                                        });
                                    }}
                                />
                                <Link
                                    className="link"
                                    onClick={() => {
                                        nextStep({
                                            ...userData,
                                            nextStep: 'ForgotPassword'
                                        });
                                    }}>
                                    Forgot your password?
                                </Link>
                            </Form.Group>
                            <Button
                                className="btn btn-block"
                                onClick={() => {
                                    signIn(userData);
                                }}>
                                Sign In
                            </Button>
                        </div>
                    </Row>
                </div>
            </div>
        </Container>
    );
}

export default SignIn;