// import { setLoading, setCurrentQuote } from './driversSlice';
import { Auth } from 'aws-amplify';

import { setSelectedQuote, setLoading } from './viewQuoteSlice';
import quoteApi from '../../../utils/quoteAPi';

export const getQuote = async (dispatch, quoteId) => {
  dispatch(setLoading(true));
  const body = {
    request: 'GetQuote',
    custId: 'UPS',
    quoteId,
  };
  await quoteApi
    .post('', body, {
      headers: {
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
    })
    .then(resp => {
      const { statusCode, body: scopedBody } = resp.data;
      if (statusCode === 200) {
        dispatch(setSelectedQuote(scopedBody?.items[0]));
      }
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 1000);
    });
  dispatch(setLoading(false));
};
