import React, { useCallback, useEffect, useState } from 'react';

import { Home } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { API, Storage } from 'aws-amplify';
import { Row, Col, Spinner, Container, Button } from 'react-bootstrap';
import { MdInfo } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  getAtsDriverDocuments,
  getCategory,
  getSource,
  getStandardDocuments,
  getType,
  getTypes,
  getDriverChecklistDocuments,
  updateDriverQualificationChecklistDocument,
  updateDocuments,
  createDocuments,
} from './operations';
import {
  addRow,
  addSelectedDocuments,
  clearRows,
  clearSelectedDocuments,
  updateOrder,
  updatePages,
  removeSelectedDocuments,
  setSubmitChecklist,
  clearSubmitChecklist,
} from './selectDocumentSlice';
import { SubHeader, Footer, ModalConfirmation, SelectDocumentRow } from '../../../components';
import { getDQByID } from '../AssembleNewDQF/operations';

import './styles.scoped.css';

const SelectDocumentDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, driverQualificationChecklistDocumentId } = useParams();
  const [category, setCategory] = useState('');
  const [categoryNum, setCategoryNum] = useState(0);
  const [customerId, setCustomerId] = useState('');
  const [driverId, setDriverId] = useState('');
  const [For, setFor] = useState('');
  const [source, setSource] = useState('');
  const [sourceNum, setSourceNum] = useState(0);
  const [type, setType] = useState('');
  const [typeNum, setTypeNum] = useState(0);
  const [driverQualificationChecklistDocuments, setDriverQualificationChecklistsDocuments] = useState({});
  const [errors, setErrors] = useState({ show: false, text: '', redirect: false });
  const [pdfDocument, setPdfDocument] = useState('');
  const [pdfError, setPdfError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModel] = useState(false);
  const [overflowActive, setOverflowActive] = useState(false);
  const { rows, selectedDocuments, submitChecklist } = useSelector(state => state.selectDocumentDetails);

  const getLists = useCallback(async () => {
    let rowss = [];
    driverQualificationChecklistDocuments?.driverQualificationSelectedDocuments?.items.forEach((item, index) => {
      dispatch(
        addSelectedDocuments({
          id: item.id,
          documentName: item.documentName,
          customerId: 0,
          driverId: driverId,
          driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId:
            item.driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId,
          expirationDate: item.expirationDate,
          category: category,
          source: source,
          type: type,
          pages: item.pages || '',
          order: item.order || index + 1,
          atsDateCreated: item.atsDateCreated || item.createdAt,
        }),
      );
    });

    if (source === 'Centerline') {
      if (For === 'Client' || For === 'All Client') {
        const standardDocuments = await getStandardDocuments(category, 0, 0, 'All Client', type);
        const documents = await getStandardDocuments(category, customerId, 0, For, type);
        rowss = driverQualificationChecklistDocuments?.driverQualificationSelectedDocuments?.items.concat(
          standardDocuments,
          documents,
        );

        [
          ...(driverQualificationChecklistDocuments?.driverQualificationSelectedDocuments?.items || []),
          ...standardDocuments,
          ...documents,
        ]
          ?.filter((item, index) => index === rowss.findIndex(obj => item.documentName === obj.documentName))
          .forEach(item => {
            dispatch(
              addRow({
                id: item?.id,
                documentName: item.documentName,
                customerId: customerId,
                driverId: 0,
                driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId: null,
                expirationDate:
                  item.expirationDate ||
                  standardDocuments.find(doc => doc.documentName === item.documentName)?.expirationDate ||
                  documents.find(doc => doc.documentName === item.documentName)?.expirationDate,
                category: category,
                source: source,
                type: type,
                pages: item.pages || '',
                order: item.order || undefined,
                atsDateCreated:
                  item.atsDateCreated ||
                  standardDocuments.find(doc => doc.documentName === item.documentName)?.atsDateCreated ||
                  documents.find(doc => doc.documentName === item.documentName)?.atsDateCreated ||
                  item.createdAt,
              }),
            );
          });
      }

      if (For === 'Driver') {
        const standardDocuments = await getStandardDocuments(category, 0, driverId, For, type);
        rowss = driverQualificationChecklistDocuments?.driverQualificationSelectedDocuments?.items?.concat(
          standardDocuments,
        );

        driverQualificationChecklistDocuments?.driverQualificationSelectedDocuments?.items
          ?.concat(standardDocuments)
          ?.filter((item, index) => index === rowss?.findIndex(obj => item.documentName === obj.documentName))
          ?.forEach(item => {
            dispatch(
              addRow({
                id: item?.id,
                documentName: item?.documentName,
                customerId: 0,
                driverId: driverId,
                driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId: null,
                expirationDate:
                  item?.expirationDate ||
                  standardDocuments.find(doc => doc.documentName === item.documentName)?.expirationDate,
                category: category,
                source: source,
                type: type,
                pages: item?.pages || '',
                order: item?.order || undefined,
                atsDateCreated:
                  item.atsDateCreated ||
                  standardDocuments.find(doc => doc.documentName === item.documentName)?.atsDateCreated ||
                  item.createdAt,
              }),
            );
          });
      }
    }

    if (source === 'Tenstreet') {
      const driverDocuments = await getAtsDriverDocuments(category, driverId, source, type);
      rowss = driverQualificationChecklistDocuments?.driverQualificationSelectedDocuments?.items?.concat(
        driverDocuments,
      );

      driverQualificationChecklistDocuments?.driverQualificationSelectedDocuments?.items
        ?.concat(driverDocuments)
        ?.filter((item, index) => index === rowss.findIndex(obj => item?.documentName === obj?.documentName))
        .forEach(item => {
          dispatch(
            addRow({
              id: item?.id,
              documentName: item?.documentName,
              customerId: 0,
              driverId: driverId,
              driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId: null,
              expirationDate:
                item?.expirationDate ||
                driverDocuments.find(doc => doc.documentName === item.documentName)?.expirationDate,
              category: category,
              source: source,
              type: type,
              pages: item?.pages || '',
              order: undefined || item?.order,
              atsDateCreated:
                item.atsDateCreated ||
                driverDocuments.find(doc => doc.documentName === item.documentName)?.atsDateCreated ||
                item.createdAt,
            }),
          );
        });
    }
  }, [customerId, driverId, For, source]);

  const onCheckboxChange = row => {
    if (!selectedDocuments.find(item => item?.id === row?.id)) {
      dispatch(addSelectedDocuments(row));
    } else {
      dispatch(removeSelectedDocuments(row));
    }
  };

  const getPDF = async id => {
    const config = {
      expires: process.env.REACT_APP_AMPLIFY_STORAGE_GET_EXPIRES,
      level: 'public',
      download: false,
    };

    await Storage.get(`dqf-assembling/${id}`, config)
      .then(response => {
        fetch(response).then(result => {
          if (result.status === 200) {
            setPdfDocument(result.url);
            setPdfError(false);
          } else {
            setPdfError(true);
          }
        });
      })
      .catch(err => console.log('This is the error', err));
  };

  const previewSelectDocuments = async data => {
    const previewDocuments = [];
    let error = '';

    data?.map(item => {
      if (item.source === 'Centerline') {
        previewDocuments.push({
          documentName: `public/standard-documents/${item.documentName}`,
          IncludePages: item.pages,
        });
      }
      if (item.source === 'Tenstreet') {
        previewDocuments.push({
          documentName: `public/ats-documents/${item.documentName}`,
          IncludePages: item.pages,
        });
      }
    });

    try {
      const response = await API.post('centerlineportal', 'dqassemblement', {
        body: {
          outputDocument: `public/dqf-assembling/${id}-${sourceNum}-${categoryNum}-${typeNum}.pdf`,
          inputDocuments: previewDocuments,
        },
      });
      if (response?.response_code === 200) {
        getPDF(`${id}-${sourceNum}-${categoryNum}-${typeNum}.pdf`);
        dispatch(setSubmitChecklist(true));
      }
    } catch (e) {
      console.log('error', e);
      dispatch(setSubmitChecklist(false));
      if (e?.response?.data?.response_code === 400) {
        error = e?.response?.data?.response_message;
        setErrors({
          show: true,
          text: JSON.stringify(error.message),
        });
      }
    }
  };

  const updateChecklistDocuments = async items => {
    try {
      const response = await updateDriverQualificationChecklistDocument(items);
      return response;
    } catch (e) {
      console.log('error', e);
    }
  };

  const onPreview = async () => {
    let previewErrors = !selectedDocuments?.length;
    selectedDocuments.forEach(item => {
      if (item.pages === '' || item.order === null || item.order === undefined) {
        previewErrors = true;
      }
    });

    if (!previewErrors) {
      previewSelectDocuments(selectedDocuments);
      dispatch(setSubmitChecklist(true));
    } else {
      setErrors({ show: true, text: 'Pages and order are required.' });
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    for (let i = 0; i < selectedDocuments.length; i++) {
      const item = selectedDocuments[i];
      await updateChecklistDocuments({
        documentName: `${id}-${sourceNum}-${categoryNum}-${typeNum}.pdf`,
        id: driverQualificationChecklistDocumentId,
      });

      if (item.driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId !== null) {
        await updateDocuments({
          id: item?.id,
          customerId: customerId,
          documentName: item?.documentName,
          documentAutoSelected: false,
          driverId: driverId,
          driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId: driverQualificationChecklistDocumentId,
          for: For,
          item: driverQualificationChecklistDocuments?.item,
          source: driverQualificationChecklistDocuments?.source,
          pages: item?.pages,
          category: driverQualificationChecklistDocuments?.category,
          type: driverQualificationChecklistDocuments?.type,
          order: item?.order,
        });
      } else {
        await createDocuments({
          customerId: customerId,
          documentName: item?.documentName,
          documentAutoSelected: false,
          driverId: driverId,
          driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId: driverQualificationChecklistDocumentId,
          for: For,
          item: driverQualificationChecklistDocuments?.item,
          source: driverQualificationChecklistDocuments?.source,
          pages: item?.pages,
          category: driverQualificationChecklistDocuments?.category,
          type: driverQualificationChecklistDocuments?.type,
          order: item?.order,
        });
      }
    }

    setLoading(false);
    dispatch(setSubmitChecklist(false));
    setSuccessModel(true);
    // window.location.reload();
    // navigate(`../driver-qualification/assemble-new-dqf/${id}`);
  };

  const getIds = async () => {
    try {
      const response = await getDQByID(id);
      setCustomerId(response?.customerId);
      setDriverId(response?.driverId);
      return response;
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      const requestObj = await getDriverChecklistDocuments(driverQualificationChecklistDocumentId);
      setCategoryNum(requestObj?.category);
      setSourceNum(requestObj?.source);
      setTypeNum(requestObj?.type);
      const typee = await getType(requestObj?.type);
      const sourcee = await getSource(requestObj?.source);
      const categoryy = await getCategory(requestObj?.category);

      if (!!categoryy && !!sourcee && !!typee) {
        setType(typee);
        setSource(sourcee);
        setCategory(categoryy);
      } else {
        setErrors({
          show: true,
          text: 'There was an error with Checklist Documents. Please contact support.',
          redirect: true,
        });
      }

      const types = await getTypes();
      const Forr = types?.find(item => item.Name === typee)?.For;
      setFor(Forr);
      setLoading(false);
      setDriverQualificationChecklistsDocuments(requestObj);
    };

    getIds().catch(e => console.log('error', e));
    getData().catch(e => console.log('error', e));
  }, [driverQualificationChecklistDocumentId]);

  useEffect(() => {
    if (customerId && driverId && For && source) {
      dispatch(clearRows());
      dispatch(clearSelectedDocuments());
      dispatch(clearSubmitChecklist(false));
      getLists();
    }
  }, [customerId, driverId, For, source]);

  useEffect(() => {
    if (id && categoryNum && sourceNum && typeNum) {
      getPDF(`${id}-${sourceNum}-${categoryNum}-${typeNum}.pdf`);
    }
  }, [id, categoryNum, sourceNum, typeNum]);

  const sortedRows = rows?.slice()?.sort((a, b) => (a.id < b.id ? 1 : -1));

  return (
    <>
      <SubHeader
        text="Centerline Driver Qualification"
        btnSmall={<Home sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigate('/driver-qualification')}
      />
      {loading ? (
        <div className="h-100 d-flex justify-content-center p-5">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div className="wrap">
            <div className="inner">
              <div className="bar">Select Document</div>
              <div className="content">
                <Col className="d-flex justify-content-between">
                  <div>
                    <span>Item</span>
                    <span className="text-secondary pl-4">{driverQualificationChecklistDocuments?.item}</span>
                  </div>
                  <div>
                    <span>Source</span>
                    <span className="text-secondary pl-4">{source}</span>
                  </div>
                  <div>
                    <span>Category</span>
                    <span className="text-secondary pl-4">{category}</span>
                  </div>
                  <div>
                    <span>Type</span>
                    <span className="text-secondary pl-4">{type}</span>
                  </div>
                </Col>
                <Col>
                  <Row className="pt-1 mb-3 mt-3">
                    <Col className="font-weight-bold">Preview Document</Col>
                  </Row>
                  <Row>
                    {pdfError ||
                      (!pdfDocument && (
                        <Col>
                          <div className="p-2" style={{ color: '#c8102e', fontWeight: 500 }}>
                            There is no PDF to show.
                          </div>
                        </Col>
                      ))}
                  </Row>
                </Col>
                <Row>
                  <Col>{pdfDocument && <embed src={pdfDocument} width="100%" height="600" />}</Col>
                </Row>
                {Array.isArray(sortedRows) && (
                  <>
                    <Col>
                      <Row className="pt-1 mb-3 mt-3">
                        <Col className="font-weight-bold">Available Documents</Col>
                      </Row>
                      <Container fluid>
                        <Row className="headers">
                          <span style={{ width: '10%' }} className="table-titles">
                            Entered
                          </span>
                          <span style={{ width: '10%' }} className="table-titles">
                            Expiration
                          </span>
                          <div style={{ width: '20%' }} className="table-titles">
                            {overflowActive ? (
                              <Tooltip title="Display full document name." arrow placement="top-end">
                                <>
                                  <span style={{ marginRight: '2px' }}>Document</span>
                                  <MdInfo
                                    color="red"
                                    style={{ paddingBottom: '5px' }}
                                    sx={{ color: '#da0f2e', fontSize: 30 }}
                                  />
                                </>
                              </Tooltip>
                            ) : (
                              <span style={{ width: '20%' }}>Document</span>
                            )}
                          </div>
                          <span style={{ width: '10%' }} className="table-titles">
                            Source
                          </span>
                          <span style={{ width: '10%' }} className="table-titles">
                            Category
                          </span>
                          <span style={{ width: '15%' }} className="table-titles">
                            Type
                          </span>
                          <span style={{ width: '6%' }} className="table-titles">
                            Select
                          </span>
                          <span style={{ width: '9%' }} className="table-titles">
                            Pages
                          </span>
                          <span style={{ width: '10%' }} className="table-titles">
                            Order
                          </span>
                        </Row>
                        {sortedRows.map((item, index) => (
                          <SelectDocumentRow
                            key={index}
                            row={item}
                            selectedDocuments={selectedDocuments}
                            onCheckboxChange={row => {
                              onCheckboxChange(row);
                            }}
                            onOrderChange={val =>
                              dispatch(
                                updateOrder({
                                  id: item.id,
                                  order: val,
                                }),
                              )
                            }
                            onPagesChange={e =>
                              dispatch(
                                updatePages({
                                  id: item.id,
                                  pages: e.target.value,
                                }),
                              )
                            }
                            setOverflowActive={setOverflowActive}
                          />
                        ))}
                        <Row style={{ marginTop: '25px', flexDirection: 'row', justifyContent: 'center' }}>
                          <span>
                            <Button className="button" onClick={onSubmit} disabled={!submitChecklist}>
                              Add Document
                            </Button>
                            <Button className="button" onClick={onPreview} disabled={!selectedDocuments?.length}>
                              Preview
                            </Button>
                          </span>
                        </Row>

                        <Row className="p-4 d-flex justify-content-center">
                          {successModal && (
                            <ModalConfirmation
                              isOpen
                              title="Success"
                              primaryButtonTitle="Ok"
                              secondaryButtonTitle={null}
                              body="Checklist Document successfully attached."
                              onClick={() => navigate(`/driver-qualification/assemble-new-dqf/${id}`)}
                              primaryButton
                            />
                          )}
                          {errors.show && (
                            <ModalConfirmation
                              title="Error"
                              body={errors.text}
                              primaryButtonTitle="Close"
                              onClick={() =>
                                errors.redirect
                                  ? navigate(`/driver-qualification/assemble-new-dqf/${id}`)
                                  : setErrors({})
                              }
                              isOpen={errors.show}
                              primaryButton
                            />
                          )}
                        </Row>
                      </Container>
                    </Col>
                  </>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default SelectDocumentDetails;
