import { API } from 'aws-amplify';

import { setList } from './checklistTemplatesSlice';
import store from '../../../../app/store';
import { listChecklists } from '../../../../graphql/queries';

export const getItems = async () => {
  try {
    const response = await API.graphql({
      query: listChecklists,
    });
    store.dispatch(setList(response.data?.listChecklists?.items));
  } catch (e) {
    console.log(e);
  }
};
