import React from 'react';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import './styles.scoped.css';
import { TablePagination } from '../../../components';
import { money } from '../../../utils';

const columnClassName = 'align-middle text-nowrap';
export const salesTaxColumns = [
  {
    Header: 'Tax Code Detail',
    accessor: 'TaxCode_Detail',
    className: columnClassName,
  },
  {
    Header: 'Branch',
    accessor: 'Branch',
    className: columnClassName,
  },
  {
    Header: 'LOB',
    accessor: 'LOB',
    className: columnClassName,
  },
  {
    Header: 'Customer BillTo Number',
    accessor: 'Customer_BillToNumber',
    className: columnClassName,
  },
  {
    Header: 'Customer Name',
    accessor: 'Customer_Name',
    className: columnClassName,
  },
  {
    Header: 'Invoice Number',
    accessor: 'Invoice_Number',
    className: columnClassName,
  },
  {
    Header: 'Invoice Date',
    accessor: 'Invoice_Date',
    className: columnClassName,
  },
  {
    Header: 'Assgn Period Ending Date',
    accessor: 'Assignment_PeriodEndingDate',
    className: columnClassName,
  },
  {
    Header: 'Tax Code Amount Detail',
    accessor: 'TaxCodeAmount_Detail',
    className: columnClassName,
  },
  {
    Header: 'Tax Description',
    accessor: 'TAX_DESCRIPTION',
    className: columnClassName,
  },
  {
    Header: 'Tax Percent',
    accessor: 'Tax_Percent',
    className: columnClassName,
  },
  {
    Header: 'Transaction Type',
    accessor: 'Transaction_Type',
    className: columnClassName,
  },
  {
    Header: 'Invoice Type',
    accessor: 'Invoice_Type',
    className: columnClassName,
  },
  {
    Header: 'Bill Amount',
    accessor: 'Bill_Amount',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Gross Invoice Amount',
    accessor: 'Gross_Invoice_Amount',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Net Invoice Amount',
    accessor: 'Net_Invoice_Amount',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Start Date',
    accessor: 'start_date',
    className: columnClassName,
  },
  {
    Header: 'End Date',
    accessor: 'end_date',
    className: columnClassName,
  },
  {
    Header: 'Applicant Name',
    accessor: 'Applicant_Name',
    className: columnClassName,
  },
  {
    Header: 'Work Taxable Amt Round',
    accessor: 'Work_Taxable_Amt_Round',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
  {
    Header: 'Work Taxable Amount',
    accessor: 'Work_Taxable_Amt',
    className: columnClassName,
    Cell: instance => money.format(instance.value),
  },
];

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const SalesTaxTable = ({ data, loading }) => {
  const tableInstance = useTable(
    {
      columns: salesTaxColumns,
      data,
      initialState: {
        pageSize: 200,
        sortBy: [
          {
            id: 'CreatedAt',
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>
          {column.canSort
            ? getSortedIcon(column.isSorted, column.isSortedDesc)
            : null}
        </span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}
      >
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading && <Spinner animation="border" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SalesTaxTable;
