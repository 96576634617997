export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      customerId
      status
      type
      lastLogin
      clientName
      updateDriverQualifications
      StandardDocuments {
        items {
          id
          for
          type
          documentName
          customerId
          driverId
        }
        nextToken
      }
      appAccess {
        items {
          id
          createdAt
          updatedAt
          userAppAccessId
          applicationUserAppAccessId
          applicationRoleUserAppAccessId
          appId {
            id
            name
          }
          roleId {
            id
            name
            usedBy
            applicationUsedBy
            viewFullPii
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const getDriverQualificationRoleId = /* GraphQL */ `
  query GetAppAccessId($id: ID!) {
    getUser(id: $id) {
      appAccess(filter: { applicationUserAppAccessId: { eq: "2" } }) {
        items {
          appId {
            id
            name
          }
          roleId {
            id
            name
            usedBy
          }
        }
      }
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query ListUsers($limit: Int, $nextToken: String) {
    listUsers(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        customerId
        status
        type
        lastLogin
        clientName
        updateDriverQualifications
        appAccess {
          items {
            appId {
              id
              name
            }
            roleId {
              id
              name
              updatedAt
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listApplications = /* GraphQL */ `
  query listApplications($id: ID!, $nextToken: String, $limit: Int) {
    listUserApplicationAccesses(filter: { userAppAccessId: { eq: $id } }, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        appId {
          id
          name
        }
      }
    }
  }
`;

export const getAllApplications = /* GraphQL */ `
  query listApplications {
    listApplications {
      items {
        name
        id
        roles {
          items {
            userAppAccess {
              items {
                applicationUserAppAccessId
                applicationRoleUserAppAccessId
              }
            }
            name
            id
            usedBy
          }
        }
      }
    }
  }
`;

export const getUserApps = /* GraphQL */ `
  query GetUserApps($id: ID!) {
    getUser(id: $id) {
      id
      appAccess {
        nextToken
        items {
          id
          roleId {
            id
            name
            usedBy
          }
          appId {
            id
            name
          }
        }
      }
    }
  }
`;

export const getAirlineFormData = /* GraphQL */ `
  query ListAirLines($nextToken: String, $limit: Int) {
    listAirLines(nextToken: $nextToken, limit: $limit) {
      items {
        id
        name
        status
        createdAt
        updatedAt
      }
      nextToken
    }
    listAirPorts(nextToken: $nextToken, limit: $limit) {
      items {
        id
        name
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getHotelFormData = /* GraphQL */ `
  query ListHotels($nextToken: String, $limit: Int) {
    listHotels(nextToken: $nextToken, limit: $limit) {
      items {
        id
        name
        status
        createdAt
        updatedAt
      }
      nextToken
    }
    listStates(nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listExpenses = /* GraphQL */ `
  query ListExpenses($filter: ModelExpenseFilterInput, $limit: Int, $nextToken: String) {
    listExpenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        driverId
        driverName
        assignmentId
        status
        nonBillable
        customerId
        customerName
        branch
        costCenter
        startDate
        endDate
        totalToBeBilled
        totalReceiptAmount
        notes
        auditKey
        invoiceDate
        invoiceNumber
        amountBilled
        variance
        statementDate
        expenseReportAppropriationNbr
        totalProjectedAmount
        payWeekEnding
        expenseItem {
          items {
            id
            expense {
              id
              code
              driverId
              driverName
              assignmentId
              status
              nonBillable
              customerId
              customerName
              branch
              costCenter
              startDate
              endDate
              totalToBeBilled
              notes
              auditKey
              invoiceDate
              invoiceNumber
              amountBilled
              variance
              statementDate
              expenseReportAppropriationNbr
              createdAt
              updatedAt
            }
            airLine {
              id
              name
              status
              createdAt
              updatedAt
            }
            departureAirPort {
              id
              name
              status
              createdAt
              updatedAt
            }
            arrivalAirPort {
              id
              name
              status
              createdAt
              updatedAt
            }
            flightDate
            paidThrough
            receiptAmount
            markup
            expenseTotal
            documentName
            uploadedByName
            uploadedDate
            hotel {
              id
              name
              status
              createdAt
              updatedAt
            }
            hotelConfirmationNumber
            hotelPhoneNumber
            hotelStreetAddress
            hotelState {
              id
              name
              createdAt
              updatedAt
            }
            hotelCity {
              id
              createdAt
              updatedAt
              stateCityId
            }
            checkInDate
            lastNightOfStay
            dailyCharge
            totalReceiptAmount
            carRentalCompany {
              id
              name
              status
              createdAt
              updatedAt
            }
            carRentalContractNumber
            carRentalPickUpLocation
            carRentalReturnLocation
            carRentalPickUpDate
            carRentalReturnDate
            originalReceiptAmount
            otherExpenseName
            otherExpenseDescription
            otherExpenseDate
            hotelDriverId
            hotelDriverName
            hotelAssignmentId
            projectedDays
            projectedAmount
            createdAt
            updatedAt
            expenseExpenseItemId
            expenseItemAirLineId
            expenseItemDepartureAirPortId
            expenseItemArrivalAirPortId
            expenseItemHotelId
            expenseItemHotelStateId
            expenseItemHotelCityId
            expenseItemCarRentalCompanyId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listExpenseItemsIdsFiltered = /* GraphQL */ `
  query ListExpenseItems($filter: ModelExpenseItemFilterInput, $limit: Int, $nextToken: String) {
    listExpenseItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        expense {
          id
        }
      }
      nextToken
    }
  }
`;

export const listOpenAssignments = /* GraphQL */ `
  query ListExpenseItems(
    $lob: String
    $lastEvaluatedKey: String
    ) {
    listExpensesReadyToBillOpenAssignments(
      lob: $lob
      lastEvaluatedKey: $lastEvaluatedKey
      ) {
      lastEvaluatedKey
      list {
        assignmentId
        billUnits
        branch
        code
        costCenter
        createdAt
        customerId
        customerName
        driverId
        driverName
        endDate
        id
        inv
        ok
        payAmt
        payType
        payWeekEnding
        startDate
        totalReceiptAmount
        totalToBeBilled
      }
    }
  }
`;

export const listClosedAssignments = /* GraphQL */ `
  query ListExpenseItems(
    $lob: String
    $lastEvaluatedKey: String
    ) {
    listExpensesReadyToBillClosedAssignments(
      lob: $lob
      lastEvaluatedKey: $lastEvaluatedKey
      ) {
      lastEvaluatedKey
      list {
        assignmentId
        billUnits
        branch
        code
        costCenter
        createdAt
        customerId
        customerName
        driverId
        driverName
        endDate
        id
        inv
        ok
        payAmt
        payType
        payWeekEnding
        totalToBeBilled
        totalReceiptAmount
        startDate
      }
    }
  }
`;
export const listExpensesReadyToTransfer = /* GraphQL */ `
  query ListExpenseItems(
    $limit: Int
    $driverId: Int
    $assignmentId: Int
    $code: String
    $customerId: Int
    $startDate: AWSDateTime
    $endDate: AWSDateTime
    $lastEvaluatedKey: String
    $lob: String
  ) {
    listExpensesReadyToBillTransferToUltraStaff(
      limit: $limit
      driverId: $driverId
      assignmentId: $assignmentId
      code: $code
      customerId: $customerId
      startDate: $startDate
      endDate: $endDate
      lastEvaluatedKey: $lastEvaluatedKey
      lob: $lob
    ) {
      lastEvaluatedKey
      list {
        assignmentId
        billUnits
        branch
        code
        costCenter
        createdAt
        customerId
        customerName
        driverId
        driverName
        documentName
        documentSize
        endDate
        id
        inv
        ok
        payAmt
        payType
        payWeekEnding
        startDate
        totalToBeBilled
        totalReceiptAmount
        expenseItem {
          documentName
          documentSize
        }
      }
    }
  }
`;
export const listActiveUsers = /* GraphQL */ `
  query ListActiveUsers($customerId: Int!, $status: Boolean!, $nextToken: String) {
    listUsers(filter: { status: { eq: "Active" } }, limit: 100, nextToken: $nextToken) {
      items {
        customers(filter: { customerId: { eq: $customerId }, active: { eq: $status } }) {
          nextToken
          items {
            customerId
            active
          }
        }
      }
      nextToken
    }
  }
`;

export const getReport = /* GraphQL */ `
  query GetReport($reportName: ID!, $criteria: String!) {
    report(input: { reportName: $reportName, criteria: $criteria }) {
      id
      location
      status
      statusCode
      statusDescription
    }
  }
`;

export const getReportResult = /* GraphQL */ `
  subscription($id: ID!) {
    onReportResult(id: $id) {
      id
      location
      status
      statusCode
      statusDescription
    }
  }
`;

export const getListChecklistBycheckListLevelAccountMasterCodeClientIdTier = /* GraphQL */ `
  query ListChecklistBycheckListLevelAccountMasterCodeClientIdTier(
    $checkListLevel: String!
    $accountMasterCodeClientIdTier: ModelChecklistBycheckListLevelbyAccountMasterCodebyClientIdTierCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChecklistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChecklistBycheckListLevelAccountMasterCodeClientIdTier(
      checkListLevel: $checkListLevel
      accountMasterCodeClientIdTier: $accountMasterCodeClientIdTier
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        items {
          items {
            category
            item
            order
            pages
            source
            type
          }
        }
        checklistDocumentName
      }
    }
  }
`;

export const getListChecklistByClientIdTier = /* GraphQL */ `
  query ListChecklistByClientIdTier(
    $clientId: Int!
    $tier: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChecklistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChecklistByClientIdTier(
      clientId: $clientId
      tier: $tier
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        items {
          items {
            category
            item
            order
            pages
            source
            type
          }
        }
        checklistDocumentName
      }
    }
  }
`;

export const listExpenseSearch = /* GraphQL */ `
  query ListExpenseSearch(
    $limit: Int
    $status: String
    $code: String
    $branch: Int
    $costCenter: Int
    $customerId: Int
    $driverId: Int
    $notes: String
    $assignmentId: Int
    $endDate: String
    $createdAt: String
    $payWeekEnding: String
    $documentName: String
    $expenseItemHotelId: String
    $expenseItemHotelStateId: String
    $expenseItemHotelCityId: String
    $expenseItemAirLineId: String
    $expenseItemCarRentalCompanyId: String
    $lastEvaluatedKey: String
    $lob: String
  ) {
    listExpenseSearch(
      limit: $limit
      status: $status
      code: $code
      branch: $branch
      costCenter: $costCenter
      customerId: $customerId
      driverId: $driverId
      notes: $notes
      assignmentId: $assignmentId
      endDate: $endDate
      createdAt: $createdAt
      payWeekEnding: $payWeekEnding
      documentName: $documentName
      expenseItemHotelId: $expenseItemHotelId
      expenseItemHotelStateId: $expenseItemHotelStateId
      expenseItemHotelCityId: $expenseItemHotelCityId
      expenseItemAirLineId: $expenseItemAirLineId
      expenseItemCarRentalCompanyId: $expenseItemCarRentalCompanyId
      lastEvaluatedKey: $lastEvaluatedKey
      lob: $lob
    ) {
      list {
        id
        createdAt
        code
        driverId
        driverName
        assignmentId
        customerId
        customerName
        branch
        costCenter
        startDate
        endDate
        invoiceDate
        payWeekEnding
        totalToBeBilled
        totalReceiptAmount
        status
        updatedAt
        statementDate
        expenseReportAppropriationNbr
        variance
        nonBillable
        invoiceNumber
        totalProjectedAmount
        expenseItem {
          projectedAmount
          dailyCharge
          projectedDays
          paidThrough
          hotel {
            name
            __typename
          }
          carRental {
            name
            __typename
          }
          airline {
            name
            __typename
          }
          __typename
        }
        __typename
      }
      lastEvaluatedKey
      __typename
    }
  }
`;

export const getDriverQualification = /* GraphQL */ `
  query GetDriverQualification($id: ID!) {
    getDriverQualification(id: $id) {
      id
      tier
      customerId
      name
      driverId
      driverName
      status
      jobId
      jobStartDate
      createEmail
      updateUser
      dqSpecialistUser
      dqSpecialistEmail
      createUserFullName
      dqOwnerFullName
      dqClientApproverUser
      dqClientApproverUserFullName
      dqDocumentName
      questions {
        items {
          id
          user
          text
          createUserFullName
          type
          status
          createdAt
          updatedAt
          driverQualificationQuestionsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      tierDescription
      branch
      driverSkillSummary
      locationState {
        id
        name
        city {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locationCity {
        id
        state {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        name
        status
        createdAt
        updatedAt
        stateCityId
        __typename
      }
      driverQualificationChecklistDocuments {
        items {
          id
          item
          source
          category
          type
          order
          documentName
          driverQualificationSelectedDocuments {
            items {
              id
              item
              source
              category
              type
              pages
              order
              documentName
              for
              customerId
              driverId
              documentAutoSelected
              createdAt
              updatedAt
              driverQualificationChecklistDocumentsDriverQualificationSelectedDocumentsId
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          driverQualificationDriverQualificationChecklistDocumentsId
          __typename
        }
        nextToken
        __typename
      }
      accountMasterCode
      checkListLevel
      updatedAt
      driverQualificationLocationStateId
      driverQualificationLocationCityId
      __typename
    }
  }
`;