import React from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

export default function CommonTableFilter({ className, columns, onSearchChange }) {
    const [filterBy, setFilterBy] = React.useState();
    const dropdownOptions = columns.map(({ Header }) => (
        <Dropdown.Item key={Header} eventKey={Header} onSelect={setFilterBy}>
            {Header}
        </Dropdown.Item>
    ));
    return (
        <InputGroup className={className}>
            <DropdownButton
                as={InputGroup.Prepend}
                title={(
                    <span>
                        <FontAwesomeIcon icon={faSearch} className="fa-lg" />
                        {' '}
                        {filterBy}
                    </span>
                )}
            >
                <Dropdown.Item eventKey="" onSelect={setFilterBy}>All</Dropdown.Item>
                {dropdownOptions}
            </DropdownButton>
            <FormControl onChange={(event) => {
                onSearchChange(event, filterBy);
            }}
            />
        </InputGroup>
    );
}

CommonTableFilter.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape({
        Header: PropTypes.string.isRequired,
    })),
    onSearchChange: PropTypes.func.isRequired,
};

CommonTableFilter.defaultProps = {
    columns: [],
    className: null,
};
