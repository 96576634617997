import { Row, Form, Spinner } from 'react-bootstrap';
import { customStyles } from '../utils';
import Select from 'react-select';
import './styles.scoped.css';

export const ClientSearchForm = ({
  form,
  setForm,
  handleChange,
  isLoadingClientStatuses,
  isLoadingContactStatuses,
  clientStatusOptions,
  contactStatusOptions,
}) => {
  return (
    <div className="filters-container">
      <div className="filter-row">
        <Form.Group as={Row} style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>Client Status</Form.Label>
          <Select
            isDisabled={isLoadingClientStatuses}
            isMulti
            closeMenuOnSelect={false}
            styles={customStyles('365px')}
            options={clientStatusOptions}
            defaultValue={form?.CustomerStatus?.split(',').map(code =>
              clientStatusOptions.find(option => option.value === code),
            )}
            onChange={selectedOptions => {
              const selectedCodes = selectedOptions.map(option => option.value);
              setForm({
                ...form,
                CustomerStatus: selectedCodes.join(','),
              });
            }}
          />
          <Spinner
            as="span"
            animation="border"
            size="sm"
            aria-hidden="true"
            className="dropdown-spinner"
            hidden={!isLoadingClientStatuses}
          />
        </Form.Group>
      </div>

      <div className="filter-row">
        <Form.Group as={Row} style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>Contact Status</Form.Label>
          <Select
            isDisabled={isLoadingContactStatuses}
            isMulti
            closeMenuOnSelect={false}
            styles={customStyles('365px')}
            options={contactStatusOptions}
            defaultValue={form?.ContactStatus?.split(',').map(code =>
              contactStatusOptions.find(option => option.value === code),
            )}
            onChange={selectedOptions => {
              const selectedCodes = selectedOptions.map(option => option.value);
              setForm({
                ...form,
                ContactStatus: selectedCodes.join(','),
              });
            }}
          />
          <Spinner
            as="span"
            animation="border"
            size="sm"
            aria-hidden="true"
            className="dropdown-spinner"
            hidden={!isLoadingContactStatuses}
          />
        </Form.Group>
      </div>

      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="CustomerName"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Customer Name</Form.Label>
          <Form.Control
            style={{
              width: '360px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            name="CustomerName"
            defaultValue={form?.CustomerName}
            onChange={handleChange}
          />
        </Form.Group>
      </div>

      <div className="filter-row">
        <Form.Group
          as={Row}
          controlId="CustomerNumber"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>Customer Number</Form.Label>
          <Form.Control
            style={{
              width: '360px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            name="CustomerNumber"
            defaultValue={form?.CustomerNumber}
            onChange={handleChange}
            type="number"
          />
        </Form.Group>
      </div>

      <div
        className="filter-row"
        style={{
          marginTop: '30px',
        }}
      >
        <Form.Group
          as={Row}
          controlId="LastBilledFromdate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>
            <div>Last Billed</div>
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            type="date"
            name="LastBilledFromdate"
            value={form?.LastBilledFromdate}
            onChange={handleChange}
            onBlur={null}
          />
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="LastBilledTodate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label
            style={{
              width: '20px',
              marginLeft: '30px',
              marginRight: '20px',
            }}
          >
            To
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
            }}
            as="input"
            type="date"
            name="LastBilledTodate"
            value={form?.LastBilledTodate}
            onChange={handleChange}
            onBlur={null}
          />
        </Form.Group>
      </div>
    </div>
  );
};
