import React from 'react';

import { Button, Modal, Spinner } from 'react-bootstrap';

const ModalConfirmation = ({
  title,
  body,
  primaryButtonTitle,
  secondaryButtonTitle,
  onClick,
  secondaryOnClick,
  isOpen = false,
  primaryButton = false,
  optionalClose = false,
  bodyContent = true,
  isLoading = false,
}) => (
  <Modal show={isOpen} style={{ zIndex: 100000 }}>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    {bodyContent && <Modal.Body>{body}</Modal.Body>}
    <Modal.Footer>
      <Spinner
        as="span"
        animation="border"
        size="sm"
        aria-hidden="true"
        className="spinner"
        hidden={!isLoading}
      />
      {primaryButton && (
        <Button variant="primary" onClick={onClick}>
          {primaryButtonTitle}
        </Button>
      )}
      {optionalClose && (
        <Button variant="secondary" onClick={secondaryOnClick}>
          {secondaryButtonTitle}
        </Button>
      )}
    </Modal.Footer>
  </Modal>
);

export default ModalConfirmation;
