import React, { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Navbar, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import logo from '../../assets/centerline_logo.png';
import avatar from '../../assets/icon-avatar.png';
import useIsMobileScreen from '../../hooks/useCheckIsMobile';
import Modal from '../Modal';
import './styles.scoped.css';

const Header = () => {
  const { signOut, user } = useAuthenticator(context => [context.signOut]);
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobileScreen();
  const [modalVisible, setModalVisible] = useState(false);
  const isPasswordLess = location.pathname.includes('pw-less') || location.pathname.includes('view-dqf');

  const logOut = () => {
    signOut();
    setModalVisible(false);
    navigate('/');
  };

  return (
    <>
      <Navbar className={`navbar ${isMobile && 'mobile'}`}>
        <img src={logo} alt="logo" height={35} onClick={() => navigate('/dashboard')} />
        {process.env.REACT_APP_ENV_MESSAGE && (
          <div
            style={{
              color: '#da0f2e',
              fontSize: isMobile ? 12 : '1.8rem',
              fontWeight: 700,
              width: '33%',
              textAlign: 'center',
            }}>
            {process.env.REACT_APP_ENV_MESSAGE}
          </div>
        )}
        <Row className={`header-right ${isMobile && 'mobile'}`}>
          <span style={{ marginRight: authStatus === 'unauthenticated' ? '16px' : null }}>
            Service Center · <a href="tel:888-213-7483">(888) 213-7483</a>· Open 24/7
          </span>
          {authStatus === 'authenticated' && (
            <img
              src={avatar}
              alt="avatar"
              width={isMobile ? 20 : 40}
              height={isMobile ? 20 : 40}
              onClick={() => !isPasswordLess && setModalVisible(true)}
            />
          )}
        </Row>
      </Navbar >
      <Modal
        isOpen={modalVisible}
        title="Sign Out?"
        onClick={logOut}
        secondaryOnClick={() => setModalVisible(false)}
        primaryButtonTitle="Sign Out"
        secondaryButtonTitle="Cancel"
        optionalClose
        bodyContent={false}
      />
    </>
  );
};

export default Header;
