import React, { useEffect } from 'react';

import { Col, Row, Spinner } from 'react-bootstrap';

import { getCategories, getSources, getTypes } from './operations';
import { IncludeChecklistDocumentsTable } from '../../../components';

const ChecklistDocuments = ({
  id,
  rows,
  dataLoading,
  loadingAutoselect,
  setDataLoading,
  types,
  setTypes,
  sources,
  setSources,
  categories,
  setCategories,
}) => {
  useEffect(() => {
    Promise.all([getCategories(), getSources(), getTypes()]).then(
      ([fetchedCategories, fetchedSources, fetchedTypes]) => {
        setCategories(fetchedCategories);
        setSources(fetchedSources);
        setTypes(fetchedTypes);
        setDataLoading(false);
      },
    );
  }, []);

  return (
    <>
      <Row className="pt-1 mb-3 mt-3">
        <Col className="font-weight-bold">Include Checklist Documents</Col>
      </Row>
      {dataLoading || loadingAutoselect ? (
        <div className="d-flex justify-content-center p-5">
          <p className="mr-4">Autoassembling...</p>
          <Spinner animation="border" />
        </div>
      ) : (
        <Row>
          <Col>
            <IncludeChecklistDocumentsTable
              data={rows}
              categories={categories}
              sources={sources}
              types={types}
              id={id}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ChecklistDocuments;
