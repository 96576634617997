import React, { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaSearch } from 'react-icons/fa';

import { formatIsoToDate } from '../../utils';

const TableFilterRTB = ({ className, columns, onSearchChange }) => {
  const [filterBy, setFilterBy] = useState();
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (dayjs(value).isValid()) {
      onSearchChange(formatIsoToDate(value), filterBy?.accessor);
    } else {
      onSearchChange('', filterBy?.accessor);
    }
  }, [value]);

  const dropdownOptions = columns.map(({ Header, accessor }) => (
    <Dropdown.Item
      key={accessor}
      eventKey={Header}
      onSelect={() => setFilterBy({ header: Header, accessor: accessor })}>
      {Header}
    </Dropdown.Item>
  ));

  return (
    <InputGroup className={className} style={{ flexWrap: 'noWrap' }}>
      <DropdownButton
        as={InputGroup.Prepend}
        title={
          <span>
            <FaSearch /> {filterBy?.header}
          </span>
        }>
        <Dropdown.Item eventKey="" onSelect={setFilterBy}>
          All
        </Dropdown.Item>
        {dropdownOptions}
      </DropdownButton>
      {filterBy?.accessor === 'startDate' ? (
        <DatePicker
          selected={value}
          onChange={date => setValue(date)}
          dateFormat="MM/dd/yyyy"
          onInputChange={inputValue => {
            setValue(inputValue);
          }}
          customInput={<input style={{ width: '100%', height: '40px', paddingLeft: '10px' }} />}
        />
      ) : (
        <FormControl
          type="input"
          onChange={event => {
            onSearchChange(event.target.value, filterBy?.accessor);
          }}
        />
      )}
    </InputGroup>
  );
};

export default TableFilterRTB;
