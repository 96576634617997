import { createSlice } from "@reduxjs/toolkit";

const appAccessSlice = createSlice({
    name: "appAccess",
    initialState: { user: null },
    reducers: {
        setEditingUser: (state, { payload }) => {
            state.user = payload;
        },
    },
});

export default appAccessSlice.reducer;

export const { setEditingUser } = appAccessSlice.actions;