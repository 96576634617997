import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { getCurrencyString, getDaysBetween } from '../../../../utils/utilities';
import {
  getFooter,
  getFormField,
  getHeader,
  getSection,
  getTitle,
  styles,
} from '../quoteDocument';

const getCostGroup = (
  title,
  costLabel,
  payLabel,
  billLabel,
  cost,
  pay,
  bill,
  multiplier,
) => {
  if (cost + bill + multiplier === 0) {
    return null;
  }
  return (
    <>
      <View style={styles.row}>
        <View style={[styles.column, { marginBottom: 2 }]}>
          <Text style={styles.formTitle}>{`${title} `}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={[styles.column, { marginRight: 10 }]}>
          <Text style={styles.formValue}>
            {`${costLabel} ${getCurrencyString(cost)}`}
          </Text>
        </View>
        {pay > 0 && (
          <View style={[styles.column, { marginRight: 10 }]}>
            <Text style={styles.formValue}>
              {`${payLabel} ${getCurrencyString(pay)}`}
            </Text>
          </View>
        )}
        <View style={[styles.column, { marginRight: 10 }]}>
          <Text style={styles.formValue}>
            {bill > 0 && `${billLabel} ${getCurrencyString(bill)}`}
          </Text>
        </View>
        <View style={[styles.column]}>
          <Text style={styles.formValue}>
            {multiplier > 0 && `Multiplier ${multiplier}`}
          </Text>
        </View>
      </View>
    </>
  );
};

export const ClericalQuoteDocument = ({ quote }) => {
  const {
    admin,
    created,
    custId,
    expires,
    location,
    manager,
    quoteId,
    quoteName,
    quoteUserId,
    region,
    startDate,
    supervisor,
    totalCost,
    totalCostAdmin,
    adminWages,
    adminMultiplier,
    totalCostManager,
    managerWages,
    annualBillingCostManager,
    managerMultiplier,
    totalCostSupervisor,
    supervisorWages,
    annualBillingCostSupervisor,
    supervisorMultiplier,
  } = quote;
  const daysValid = getDaysBetween(new Date(), new Date(expires));

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          {getHeader(quoteName, quoteId)}
          <View style={styles.row}>
            {getFormField('Created', created)}
            {getFormField('Expires', expires)}
          </View>
          <View style={styles.row}>
            {getFormField('Generated by', `${quoteUserId}, ${custId}`)}
          </View>
          <View style={styles.row}>
            {getSection('Clerical Quote', [
              {
                label: 'Location (State)',
                value: location, // Location Value Placeholder
              },
              region && {
                label: 'Region',
                value: region, // Region Value Placeholder
              },
            ])}
          </View>
          <View style={styles.row}>
            {getSection('Positions', [
              {
                label: 'Manager',
                value: manager, // Manager Placeholder
              },
              {
                label: 'Admin',
                value: admin, // Admin Placeholder
              },
              {
                label: 'Supervisor',
                value: supervisor, // Supervisor Placeholder
              },
              {
                label: 'Start Date',
                value: startDate, // Start Date Placeholder
              },
            ])}
          </View>
        </View>
        <View style={styles.divider} />
        <View style={styles.container}>
          <View style={styles.row}>
            <View style={styles.column}>
              {getCostGroup(
                'Manager',
                'Total Cost / Week',
                'Annual Pay',
                'Annual Billing',
                totalCostManager,
                managerWages,
                annualBillingCostManager,
                managerMultiplier,
              )}
              {getCostGroup(
                'Admin',
                'Total Cost / Hour',
                'Hourly Pay',
                '',
                totalCostAdmin,
                adminWages,
                0,
                adminMultiplier,
              )}
              {getCostGroup(
                'Supervisor',
                'Total Cost / Week',
                'Annual Pay',
                'Annual Billing',
                totalCostSupervisor,
                supervisorWages,
                annualBillingCostSupervisor,
                supervisorMultiplier,
              )}
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.h5}>Total Cost (Annual Estimate)</Text>
              <Text style={styles.formValue}>
                {getCurrencyString(totalCost)}
              </Text>
            </View>
          </View>
          {getFooter(daysValid, 1, 1, 'Clerical')}
        </View>
      </Page>
    </Document>
  );
};

export default ClericalQuoteDocument;
