import React, { useEffect, useState } from 'react';

import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Web, Home } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Row, Col, Form, Dropdown, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setClientData } from './addClientUserSlice';
import { createNewUser, getClient, getAllUsers, createUserCustomer } from './operations';
import styles from './styles';
import { SubHeader, InputBox, ModalConfirmation, Footer } from '../../../components';
import useIsMobileScreen from '../../../hooks/useCheckIsMobile';

import './styles.scoped.css';

const AddClientUser = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const clientData = useSelector(state => state.addClientUser.clientData);
  const [clientId, setClientId] = useState();
  const [loading, setLoading] = useState(false);
  const [nullResult, setNullResult] = useState(false);
  const [selectedContact, setSelectedContact] = useState();
  const [errors, setErrors] = useState([]);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const isMobile = useIsMobileScreen();

  useEffect(() => {
    getAllUsers();
    dispatch(setClientData({}));
  }, []);

  useEffect(() => {
    if (clientId === '') {
      dispatch(setClientData({}));
      setSelectedContact(null);
    }
  }, [clientId]);

  const fetchClient = async () => {
    if (!clientId || clientId === '') return;
    setLoading(true);
    setNullResult(false);
    setSelectedContact(null);
    setErrors([]);
    const response = await getClient(clientId);
    if (response.response_code === 200) {
      const status = response.response_body?.clientStatus?.split(' ')[0];
      const statuses = process.env.REACT_APP_CLIENT_STATUSES.split(',');
      if (statuses.includes(status)) {
        dispatch(setClientData(response.response_body));
      } else {
        setTitle('Invalid');
        setMessage('Client not active in Ultra Staff');
      }
    } else {
      setNullResult(true);
      setClientId(null);
      setSelectedContact(null);
    }
    setLoading(false);
  };

  const add = async () => {
    if (
      !selectedContact?.firstName ||
      !selectedContact?.lastName ||
      !selectedContact?.emailAddress
    ) {
      setTitle('Invalid');
      setMessage('Cannot add until issues are corrected in Ultra Staff');
      return;
    }
    const startsWithOne = selectedContact?.cellPhone.substring(0, 1) === '1';
    const contactStatus = selectedContact.status?.split(' ')[0];
    const contactStatuses = process.env.REACT_APP_CONTACT_STATUSES.split(',');
    if (contactStatuses.includes(contactStatus)) {
      try {
        const response = await createNewUser({
          id: selectedContact?.emailAddress,
          name: `${selectedContact?.firstName} ${selectedContact?.lastName}`,
          customerId: clientId,
          status: 'Invited',
          type: 'Client',
          lastLogin: new Date().toISOString(),
          clientName: clientData.clientName,
          cellPhone: selectedContact?.smsOptIn === true && selectedContact?.cellPhone ? `+${startsWithOne ? '' : '1'}${selectedContact?.cellPhone?.replace(/\D/g, '')}` : '',
        });

        if (response.errors?.length) {
          alert(response.errors[0].message);
        } else {
          const activateResponse = await createUserCustomer({
            active: true,
            customerId: clientId,
            userCustomersId: selectedContact?.emailAddress,
          });
          if (activateResponse.errors?.length) {
            alert(activateResponse.errors[0].message);
          } else {
            setTitle('Success');
            setMessage('The user has been successfully added.');
          }
        }
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      setTitle('Invalid');
      setMessage('Client contact not active in Ultra Staff');
    }
  };

  return (
    <div>
      <SubHeader
        btnSmall={<Web sx={{ color: '#fff', fontSize: 30 }} />}
        btnSmall2={<Home sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigation('/dashboard')}
        small2Action={() => navigation(-1)}
        text="Admin Portal"
      />
      <div className="wrap">
        <div className="inner" style={{ width: isMobile ? '100%' : '70%' }}>
          <div className="bar">Add Client User</div>
          <div className="content">
            <Form>
              <Row>
                <Col>
                  <InputBox
                    loading={loading}
                    title="Client ID"
                    value={clientId}
                    span
                    onChange={e => setClientId(e.target.value)}
                    onBlur={fetchClient}
                    errorMessage="No client found"
                    error={nullResult || errors.includes('clientId')}
                  />
                </Col>
                <Col>
                  <Form.Label>Client Name</Form.Label>
                  <div className="label">{clientData.clientName}</div>
                </Col>
                <Col>
                  <Form.Label>Client Status</Form.Label>
                  <div className="label">{clientData.clientStatus}</div>
                </Col>
              </Row>
              <div className="box">
                <Row>
                  <Col>
                    <Form.Label>Existing Contacts</Form.Label>
                    <Dropdown
                      onSelect={eventKey => {
                        setSelectedContact(clientData?.clientContacts[parseInt(eventKey)]);
                        setErrors([]);
                      }}>
                      <Dropdown.Toggle style={styles.dropdownStyle}>
                        {!selectedContact ? 'Select...' : `${selectedContact.firstName} ${selectedContact.lastName}`}
                      </Dropdown.Toggle>
                      {clientData?.clientContacts?.length ? (
                        <Dropdown.Menu>
                          {clientData?.clientContacts?.map((item, i) => (
                            <Dropdown.Item eventKey={`${i}`} key={item.lastName}>
                              {`${item.firstName} ${item.lastName}`}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      ) : null}
                    </Dropdown>
                    {errors.includes('existing') && <div className="error">choose contact</div>}
                  </Col>

                  <Col>
                    {selectedContact && (
                      <>
                        <InputBox disabled title="First Name" value={selectedContact?.firstName || ''} />
                        <InputBox
                          disabled
                          style={styles.marginTop}
                          title="Email"
                          value={selectedContact?.emailAddress || ''}
                        />
                        <InputBox
                          disabled
                          style={styles.marginTop}
                          title="Status"
                          value={selectedContact?.status || ''}
                        />
                      </>
                    )}
                  </Col>
                  <Col>
                    {selectedContact && (
                      <>
                        <InputBox disabled title="Last Name" value={selectedContact?.lastName || ''} />
                        <InputBox
                          disabled
                          style={styles.marginTop}
                          title="Cell Phone"
                          value={selectedContact?.smsOptIn === true ? selectedContact?.cellPhone : ''}
                          errorMessage="Cell Phone Number not found"
                          error={!selectedContact?.cellPhone}
                        />

                        <InputBox
                          disabled
                          style={styles.marginTop}
                          title="SMS Opt-in"
                          value={selectedContact?.smsOptIn ? 'Yes' : 'No'}
                          errorMessage="SMS Opt In Not Set"
                          error={typeof selectedContact?.smsOptIn !== 'boolean'}
                        />
                      </>
                    )}
                  </Col>
                </Row>
                {selectedContact && (
                  <Row>
                    <Col />
                    <Col>
                      <div style={styles.center}>
                        <Button className="w-100" style={styles.buttonPrimary} onClick={add}>
                          Add
                        </Button>
                      </div>
                    </Col>
                    <Col>
                      <div style={{ display: 'flex', height: '100%', justifyContent: 'flex-end' }}>
                        <Tooltip title="Refresh data from Ultra Staff" arrow>
                          <Button style={{ height: 40, alignSelf: 'flex-end', marginBottom: 10 }} onClick={fetchClient}>
                            <FontAwesomeIcon icon={faRedo} className="fa-lg" />
                          </Button>
                        </Tooltip>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
      {errors.includes('existing-user') && (
        <ModalConfirmation
          title="An error occured:"
          body="The email you are attempting to add is already in the system."
          primaryButtonTitle="Close"
          isOpen
          primaryButton
          onClick={() => {
            setErrors([]);
          }}
        />
      )}
      <ModalConfirmation
        title={title}
        body={message}
        primaryButtonTitle="Add Another"
        secondaryButtonTitle="Close"
        isOpen={title.length > 0}
        onClick={() => {
          setClientId('');
          setTitle('');
        }}
        secondaryOnClick={() => {
          if (title === 'Invalid') {
            setTitle('');
            setMessage('');
          } else {
            navigation(-1);
          }
        }}
        optionalClose
      />
      <Footer />
    </div>
  );
};

export default AddClientUser;
