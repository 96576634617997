import React from 'react';

import { Auth } from 'aws-amplify';

import { formatIsoToDate } from '.';

const getDaysBetween = (date1, date2) => {
  // To calculate the time difference of two dates
  const timeDifference = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  return Math.ceil(timeDifference / (1000 * 3600 * 24));
};

const getCurrencyString = (number = 0) =>
  number.toLocaleString('us-US', {
    style: 'currency',
    currency: 'USD',
  });

// const isAuthenticated = (async () => {
//   try {
//     await Auth.currentSession();
//     return true;
//   } catch (error) {
//     return false;
//   }
// },
// []);

const answerCustomChallenge = async email => {
  const cognitoUser = await Auth.signIn(email);

  // eslint-disable-next-line no-undef
  localStorage.setItem(
    'cognitoUser',
    JSON.stringify({
      username: email,
      session: cognitoUser.Session,
    }),
  );

  return cognitoUser;
};

const formatPhoneNumber = value => {
  if (!value) return value;
  const phoneNumber = value?.replace(/[^\d]/g, '');
  const firstPart = phoneNumber.slice(0, 1);
  const secondPart = phoneNumber.slice(1, 4);
  const thirdPart = phoneNumber.slice(4, 7);
  const fourthPart = phoneNumber.slice(7);
  return `${firstPart}${secondPart ? '-' : ''}${secondPart}${thirdPart ? '-' : ''}${thirdPart}${
    fourthPart ? '-' : ''
  }${fourthPart}`;
};

export const expenseCodes = [
  { label: 'XA', value: 'XA' },
  { label: 'XAR', value: 'XAR' },
  { label: 'XFFBA', value: 'XFFBA' },
  { label: 'XC', value: 'XC' },
  { label: 'XCR', value: 'XCR' },
  { label: 'XFFBC', value: 'XFFBC' },
  { label: 'XX', value: 'XX' },
  { label: 'XXR', value: 'XXR' },
  { label: 'XFFBX', value: 'XFFBX' },
  { label: 'XH', value: 'XH' },
  { label: 'XHR', value: 'XHR' },
  { label: 'XFFBH', value: 'XFFBH' },
  { label: 'XFFBHS', value: 'XFFBHS' },
  { label: 'XHS', value: 'XHS' },
  { label: 'XHSR', value: 'XHSR' },
];

const getExpenseType = expense => {
  let expenseType = '';
  if (expense.code === 'XA' || expense.code === 'XAR' || expense.code === 'XFFBA') {
    expenseType = 'airline';
  }
  if (expense.code === 'XC' || expense.code === 'XCR' || expense.code === 'XFFBC') {
    expenseType = 'car';
  }
  if (expense.code === 'XX' || expense.code === 'XXR' || expense.code === 'XFFBX') {
    expenseType = 'other';
  }
  if (
    expense.code === 'XH' ||
    expense.code === 'XHR' ||
    expense.code === 'XHS' ||
    expense.code === 'XFFBH' ||
    expense.code === 'XFFBHS' ||
    expense.code === 'XHSR'
  ) {
    expenseType = 'hotel';
  }

  return expenseType;
};

const getExpensesCsvData = (expenses, currentIds) => {
  const csvData = [
    [
      'Date Entered',
      'Pay Code',
      'Driver Name & Number',
      'Assignment / Contract Activity',
      'Customer Name & Number',
      'Client Branch',
      'Cost Center',
      'Expense Date',
      'Receipt Amount',
      'Total To Be Billed',
    ],
  ];
  let i;
  for (i = 0; i < expenses.length; i += 1) {
    if (currentIds.includes(expenses[i].id)) {
      csvData.push([
        `${formatIsoToDate(expenses[i].createdAt)}`,
        `${expenses[i].code}`,
        `${expenses[i].driverNameNumber}`,
        `${expenses[i].assignmentId}`,
        `${expenses[i].customerNameNumber}`,
        `${expenses[i].branch}`,
        `${expenses[i].costCenter}`,
        `${formatIsoToDate(expenses[i].startDate)}`,
        `$${expenses[i].totalReceiptAmount}`,
        `${expenses[i].totalToBeBilled}`,
      ]);
    }
  }
  return csvData;
};

const DisableClickOverlay = ({ children, isDisabled }) => (
  <div
    style={
      isDisabled
        ? {
          position: 'relative',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: 'none',
          opacity: 0.5,
        }
        : {}
    }>
    {children}
  </div>
);

const isBilledOrTransmitted = form => form?.status === 'Billed' || form?.status === 'Transmitted';

export {
  getDaysBetween,
  getCurrencyString,
  answerCustomChallenge,
  formatPhoneNumber,
  getExpenseType,
  getExpensesCsvData,
  DisableClickOverlay,
  isBilledOrTransmitted,
};
