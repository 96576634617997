import React from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';

import { FaSearch } from 'react-icons/fa'

import './styles.scoped.css';

const TableFilter = ({ className, columns, onSearchChange }) => {
    const [filterBy, setFilterBy] = React.useState();
    const dropdownOptions = columns.map(({ Header }) => (
      <Dropdown.Item key={Header} eventKey={Header} onSelect={setFilterBy}>
        {Header}
      </Dropdown.Item>
    ));
    return (
      <InputGroup className={className}>
        <DropdownButton
          as={InputGroup.Prepend}
          title={(
            <span>
              <FaSearch />
              {' '}
              {filterBy}
            </span>
          )}
        >
          <Dropdown.Item eventKey="" onSelect={setFilterBy}>All</Dropdown.Item>
          {dropdownOptions}
        </DropdownButton>
        <FormControl onChange={(event) => {
          onSearchChange(event, filterBy);
        }}
        />
      </InputGroup>
    );
  }

  export default TableFilter;
