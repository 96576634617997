export const createExpense = /* GraphQL */ `
  mutation CreateExpense(
    $input: CreateExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    createExpense(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createExpenseItem = /* GraphQL */ `
  mutation CreateExpenseItem(
    $input: CreateExpenseItemInput!
    $condition: ModelExpenseItemConditionInput
  ) {
    createExpenseItem(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateExpense = /* GraphQL */ `
  mutation UpdateExpense(
    $input: UpdateExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    updateExpense(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateExpenseItem = /* GraphQL */ `
  mutation UpdateExpenseItem(
    $input: UpdateExpenseItemInput!
    $condition: ModelExpenseItemConditionInput
  ) {
    updateExpenseItem(input: $input, condition: $condition) {
      id
    }
  }
`;
