import { createSlice } from '@reduxjs/toolkit';

import { newDateFormattedYearFirst } from '../../../../utils';

const driversSlice = createSlice({
  name: 'quoteEngineDrivers',
  initialState: {
    form: {
      location: '',
      union: false,
      region: '',
      numberOfDrivers: 0,
      totalAnnualMilage: 0,
      totalAnnualHoursRegular: 0,
      totalAnnualHoursOt: 0,
      numberDropHook: 0,
      numberDelay: 0,
      numberStop: 0,
      numberOther: 0,
      startDate: newDateFormattedYearFirst,
      hazmat: false,
      doubleEndorsement: false,
      awayFromHome: false,
      nightShift: false,
      touchNoTouch: false,
      teamDriver: false,
    },
    errors: {},
    currentQuote: null,
    loading: false,
  },
  reducers: {
    setForm: (state, { payload }) => {
      state.form = payload;
    },
    setErrors: (state, { payload }) => {
      state.errors = payload;
    },
    setCurrentQuote: (state, { payload }) => {
      state.currentQuote = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

export default driversSlice.reducer;

export const { setErrors, setForm, setCurrentQuote, setLoading } = driversSlice.actions;
