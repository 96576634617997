import React, { useState, useEffect } from 'react';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import { Button, Container, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { FaFilePdf } from 'react-icons/fa';
import { MdHome } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { ModalConfirmation, SubHeader } from '../../../components';
import { getUser } from '../../../graphql/customQueries';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import useIsMobileScreen from '../../../hooks/useCheckIsMobile';
import { formatIsoToDate } from '../../../utils';
import { getSkillsFromDriver } from '../CorporateDQ/operations';
import DQQuestionsTable from '../DQQuestionsTable';

import './styles.scoped.css';

function tabsBorderRadius() {
  document.querySelectorAll('.rounded-top-left').forEach(elem => (elem.style.borderTopLeftRadius = '25px'));
  document.querySelectorAll('.rounded-top-right').forEach(elem => (elem.style.borderTopRightRadius = '25px'));
}

const ClientDQ = () => {
  const { id } = useParams();
  const [dq, setDQ] = useState({});
  const [driverSkills, setDriverSkills] = useState([]);
  const [dqPdfDocument, setDqPdfDocument] = useState('');
  const [status, setStatus] = useState('');
  const [pdfError, setPdfError] = useState();
  const [isCommentChange, setIsCommentChange] = useState('');
  const [show, setShow] = useState(false); // for error modal
  const [requestSuccessShow, setRequestSuccessShow] = useState();
  const [disable, setDisable] = useState(true);
  const [modalBodyAction, setModalBodyAction] = useState('');
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [showApprovedModalConfirmation, setShowApprovedModalConfirmation] = useState(false);
  const [clientApproverName, setClientApproverName] = useState('');
  const isMobile = useIsMobileScreen();

  const userEmail = useSelector(state => state.admin.user.id);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) getDQ(id);
    if (userEmail) getUserById(userEmail);
    tabsBorderRadius();
  }, []);

  const getSkills = async driverId => {
    const driver = await getSkillsFromDriver(driverId);
    if (driver?.driverSkills?.length) {
      setDriverSkills(driver?.driverSkills);
    }
  };

  useEffect(() => {
    getSkills(dq.driverId);
  }, [dq.driverId]);

  const createQuestion = async (id, createUser, text, clientApproverName, type) => {
    const create = {
      driverQualificationQuestionsId: id,
      user: createUser,
      text: text,
      status: 'In-progress',
      createUserFullName: clientApproverName,
      type: type,
    };
    try {
      const response = await API.graphql(graphqlOperation(mutations.createQuestions, { input: create }));
      return true;
    } catch (e) {
      console.log(`Update Status Error: ${JSON.stringify(e)}`);
      return false;
    }
  };

  const getDQByID = async id => {
    try {
      const response = await API.graphql({
        query: queries.getDriverQualification,
        variables: { id: id },
      });
      return response.data.getDriverQualification;
    } catch (e) {
      console.log(e);
    }
  };

  const getUserById = async userEmail => {
    try {
      const response = await API.graphql({
        query: getUser,
        variables: { id: userEmail },
      });
      return response?.data?.getUser;
    } catch (e) {
      console.log(e);
    }
  };

  const updateDQStatus = async (id, status, userEmail, clientApproverName) => {
    const update = {
      id: id,
      status: status,
      dqClientApproverUser: userEmail,
      dqClientApproverUserFullName: clientApproverName,
    };
    try {
      const response = await API.graphql(
        graphqlOperation(mutations.updateDriverQualification, {
          input: update,
        }),
      );
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const getDQ = async id => {
    const response = await getDQByID(id);
    const result = await getUserById(userEmail);
    if (response && result) {
      setClientApproverName(result?.name);
      setStatus(response?.status);
      if (result.type === 'Reviewer') {
        //If user is Reviewer show read only
        setDQ(response);
        return;
      }
      if (response.status === 'Sent') {
        setDisable(false);
        setDQ({ ...response, status: 'Client Review' });
        const success = await updateDQStatus(id, 'Client Review', userEmail, result?.name);
      } else {
        setDisable(true);
        setDQ(response);
      }
      if (response.status === 'Client Review') setDisable(false);
    }
  };

  const getPDF = async pdf => {
    const config = {
      expires: process.env.REACT_APP_AMPLIFY_STORAGE_GET_EXPIRES,
      level: 'public',
      download: false,
    };
    await Storage.get(`dqf/${pdf}`, config)
      .then(response => {
        fetch(response).then(result => {
          if (result.status === 200) {
            setDqPdfDocument(result.url);
            setPdfError(false);
          } else {
            setPdfError(true);
          }
        });
      })
      .catch(e => console.log('This is the error', e));
  };

  useEffect(() => {
    if (dq?.id > 0) getPDF(`${dq?.id}.pdf`);
  }, [dq]);

  const handleInformationRequested = async () => {
    let success;
    try {
      if (dq?.status === 'Client Review') {
        setDQ({ ...dq, status: 'Information Requested' });
      }
      success = await updateDQStatus(id, 'Information Requested');
      const type = 'Information Requested';
      if (success) {
        success = await createQuestion(id, userEmail, isCommentChange, clientApproverName, type);
      }
      if (success) {
        setRequestSuccessShow(true);
        setDisable(true);
        setIsCommentChange('');
      } else {
        setShow(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleRejectRequest = async () => {
    let success;
    setStatus('Rejected');
    try {
      if (dq?.status === 'Client Review') {
        setDQ({ ...dq, status: 'Rejected' });
        success = await updateDQStatus(id, 'Rejected');
      }
      const type = 'Rejected';
      if (success) {
        success = await createQuestion(id, userEmail, isCommentChange, clientApproverName, type);
      }
      if (success) {
        setDisable(true);
        setIsCommentChange('');
      } else {
        setShow(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleApprovedRequest = async () => {
    let success;
    try {
      if (dq?.status === 'Client Review') {
        setDQ({ ...dq, status: 'Approved' });
        success = await updateDQStatus(id, 'Approved');
      }
      if (success) {
        setDisable(true);
      } else {
        setShow(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAllSkills = () => {
    let skills = '';
    driverSkills?.forEach(item => {
      skills = skills.concat(`${item?.skillName}: ${item?.skillDescription}, `);
    });
    return skills.substring(0, skills.length - 2);
  };

  return (
    <>
      <SubHeader
        text="Centerline Driver Qualification File"
        btnSmall={<MdHome size={28} color="#FFF" />}
        btnInfo
        smallAction={() => navigate('../driver-qualification', { replace: true })}
        emailAddress={process.env.REACT_APP_DQF_CONTACT_EMAIL}
        infoText="Need help with your driver qualification?"
      />
      <Container className="pt-3 pb-5" fluid>
        <Row className="h-100 px-md-5">
          <Col className="px-lg-5">
            <Tabs fill activeKey="pdf">
              <Tab
                eventKey="pdf"
                title="View DQF"
                tabClassName="border border-primary rounded-top-left rounded-top-right bg-primary text-white active">
                <div className="p-4 border border-primary">
                  <Form>
                    <Form.Group>
                      <Row>
                        <Col
                          style={{
                            color: '#c8102e',
                          }}
                          className="font-weight-bold h4">
                          {dq?.name}
                        </Col>
                        <Col className="font-weight-bold black-header d-flex justify-content-end">{dq?.id}</Col>
                      </Row>

                      <Row>
                        <Col>
                          <Row>
                            <Col>Driver</Col>
                            <Col className="text-secondary">{dq?.driverName}</Col>
                          </Row>
                          <br />
                          <Row>
                            <Col>Generated by</Col>
                            <Col className="text-secondary">{dq?.createUserFullName}</Col>
                          </Row>
                          <br />
                        </Col>
                        <Col>
                          <Row>
                            <Col>Job Order</Col>
                            <Col className="text-secondary">{dq?.jobId}</Col>
                          </Row>
                          <br />
                          <Row>
                            <Col>Client Approver</Col>
                            <Col className="text-secondary">{clientApproverName}</Col>
                          </Row>
                          <br />
                          <Row>
                            <Col>DQF Specialist</Col>
                            <Col className="text-secondary">
                              {dq?.dqOwnerFullName ? dq.dqOwnerFullName : 'No Current Owner'}
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col>Status</Col>
                            <Col className="text-secondary">{dq?.status}</Col>
                          </Row>
                          <br />
                          <Row>
                            <Col>Created</Col>
                            <Col className="text-secondary">{formatIsoToDate(dq?.createdAt)}</Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
                        <Col>
                          <Row>
                            <Col>City</Col>
                            <Col className="text-secondary">{dq?.locationCity?.name}</Col>
                          </Row>
                        </Col>

                        <Col>
                          <Row>
                            <Col>State</Col>
                            <Col className="text-secondary">{dq?.locationState?.name}</Col>
                          </Row>
                        </Col>

                        <Col />
                      </Row>
                      <Row>
                        <Col>Summary</Col>
                      </Row>
                      <Row>
                        <Col>
                          {dq?.driverSkillSummary ? (
                            <textarea
                              defaultValue={dq?.driverSkillSummary}
                              className="summary-input gray-text"
                              style={{
                                pointerEvents: 'none',
                                paddingLeft: '16px',
                                border: 'none',
                              }}
                            />
                          ) : (
                            <div className="empty-div" />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>Skills</Col>
                      </Row>
                      <Row>
                        <Col className="scroll-list">
                          <div className="gray-text" style={{ paddingLeft: '16px' }}>
                            {getAllSkills()}
                          </div>
                        </Col>
                      </Row>
                      <Row className="pt-3">
                        <Col className="font-weight-bold">Driver Qualification Document</Col>
                      </Row>

                      {pdfError && <div style={{ color: '#c8102e', fontWeight: 500 }}>There is no PDF to show.</div>}
                      {!pdfError &&
                        dqPdfDocument &&
                        (isMobile ? (
                          <span>
                            <a href={dqPdfDocument} data-ajax="false" target="_blank" rel="noreferrer">
                              <FaFilePdf size={60} /> View Document
                            </a>
                          </span>
                        ) : (
                          <iframe src={dqPdfDocument} height="600" width="100%" />
                        ))}

                      <Row className="pt-3">
                        <Col className="font-weight-bold pb-1 pt-4">Previous Comments</Col>
                      </Row>

                      {dq.questions && (
                        <Row className="pt-2">
                          <Col>
                            <DQQuestionsTable data={dq.questions.items} />
                          </Col>
                        </Row>
                      )}

                      <Row className="padding-row">
                        <Col className="font-weight-bold pb-1 pt-4">
                          Comments should be entered when selecting Reject or Request More Information
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Control
                              as="textarea"
                              rows={6}
                              value={isCommentChange || ''}
                              onChange={e => {
                                setIsCommentChange(e.target.value);
                              }}
                              disabled={disable}
                              className="border border-dark"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="p-3">
                        <Col>
                          <Button
                            className="w-100"
                            onClick={() => {
                              setModalBodyAction('approve');
                              setShowApprovedModalConfirmation(true);
                            }}
                            disabled={disable}>
                            <span>{dq.status !== 'Approved' ? 'Approve' : 'Approved'}</span>
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            className="w-100"
                            onClick={() => {
                              handleInformationRequested();
                            }}
                            disabled={!isCommentChange || disable}>
                            <span>Request More Info</span>
                          </Button>
                        </Col>

                        <Col>
                          <Button
                            className="w-100"
                            onClick={() => {
                              setModalBodyAction('reject');
                              setShowModalConfirmation(true);
                            }}
                            disabled={!isCommentChange || disable}>
                            <span>Reject</span>
                          </Button>
                        </Col>

                        {show && (
                          <ModalConfirmation
                            title="Error"
                            body="There was an error updating the Driver Qualification. Please contact support."
                            primaryButtonTitle="Close"
                            onClick={() => {
                              setShow(false);
                            }}
                            isOpen={show}
                            primaryButton
                          />
                        )}
                        {requestSuccessShow && (
                          <ModalConfirmation
                            title="Success!"
                            body="Information Requested successfully."
                            primaryButtonTitle="Close"
                            primaryButton
                            onClick={() => {
                              setRequestSuccessShow(false);
                              navigate('/driver-qualification', {
                                replace: true,
                              });
                            }}
                            isOpen={requestSuccessShow}
                          />
                        )}
                        {showModalConfirmation && (
                          <ModalConfirmation
                            title="Confirmation Required:"
                            body={`Are you sure you want to ${modalBodyAction} this DQF?`}
                            primaryButtonTitle="Yes"
                            primaryButton
                            optionalClose
                            secondaryButtonTitle="No"
                            onClick={() => {
                              setShowModalConfirmation(false);
                              handleRejectRequest();
                              navigate('/driver-qualification', {
                                replace: true,
                              });
                            }}
                            secondaryOnClick={() => {
                              setShowModalConfirmation(false);
                            }}
                            isOpen={showModalConfirmation}
                          />
                        )}
                        {showApprovedModalConfirmation && (
                          <ModalConfirmation
                            title="Confirmation Required:"
                            body={`Are you sure you want to ${modalBodyAction} this DQF?`}
                            primaryButtonTitle="Yes"
                            primaryButton
                            optionalClose
                            secondaryButtonTitle="No"
                            onClick={() => {
                              setShowApprovedModalConfirmation(false);
                              handleApprovedRequest();
                              navigate('/driver-qualification', {
                                replace: true,
                              });
                            }}
                            secondaryOnClick={() => {
                              setShowApprovedModalConfirmation(false);
                            }}
                            isOpen={showApprovedModalConfirmation}
                          />
                        )}
                      </Row>
                    </Form.Group>
                  </Form>
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ClientDQ;
