import React, { useEffect, useState, useRef } from "react";

import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { deleteBranchPushNotification, getCountForPushNotification, getBranchPushNotification, updateBranchPushNotification, sendPushNotification } from "./operations";
import { Breadcrumbs, ModalConfirmation, SubHeader, Footer } from '../../../components'
import { getList } from './operations';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Home } from '@mui/icons-material';
import FormContent from './FormContent';
import "./styles.scoped.css";

const editBranchPushNotifications = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector(state => state.admin?.user);
    const { id } = useParams();
    const [branches, setBranches] = useState([]);
    const [lobs, setLOBs] = useState([]);
    const [recipientStatuses, setRecipientStatuses] = useState([]);
    const [messageCharCount, setMessageCharCount] = useState(0);
    const messageCharLimit = 150;
    const [subjectCharCount, setSubjectCharCount] = useState(0);
    const subjectCharLimit = 50;
    const [isLoadingBranches, setIsLoadingBranches] = useState(false);
    const [isLoadingRecipientStatus, setIsLoadingRecipientStatus] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showDriversCountModal, setShowDriversCountModal] = useState(false);
    const [isLoadingNotifiedNotifications, setIsLoadingNotifiedNotifications] = useState(false);
    const [driversToBeNotified, setDriversToBeNotifed] = useState(0);
    const [driversNotified, setDriversNotifed] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(false);
    const [isLoadingLOBS, setIsLoadingLOBs] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showExecutionTimeoutException, setShowExecutionTimeoutException] = useState(false);
    const [form, setForm] = useState({
        type: 'Branch',
        subject: '',
        message: '',
        url: '',
        status: 'Pending',
        lob: null,
        branch: null,
        recipientStatus: '',
        approvedBy: user?.name,
        approvedAt: null,
        createdBy: user?.name,
        createdAt: dayjs().utc().format(),
        sentOn: null,
    });
    const hasLOBSelected = useRef();
    const hasBranchSelected = useRef();

    const utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);

    const handleGetRecipientStatuses = async () => {
        setIsLoadingRecipientStatus(true);
        try {
            const responses = await getList('driver-status');
            setRecipientStatuses(responses);
            return responses;
        } catch (e) {
            console.log('e', e)
        }
        setIsLoadingRecipientStatus(false);
    }

    const getNotification = async (id) => {
        try {
            const response = await getBranchPushNotification(id);
            setForm({
                ...form,
                id: response?.id,
                type: response?.type,
                subject: response?.subject,
                message: response?.message,
                url: response?.url,
                status: response?.status,
                lob: response?.lob,
                branch: response?.branch,
                recipientStatus: response?.recipientStatus,
                approvedBy: response?.name,
                approvedAt: response?.approvedAt,
                sentOn: response?.sentOn,
                createdBy: response?.createdBy,
                createdAt: response?.createdAt
            });
        } catch (e) {
            console.log('e', e);
        }
    }

    const removeBranchPushNotification = async () => {
        try {
            const response = await deleteBranchPushNotification(id);
            return response;
        } catch (e) {
            console.log('e', e);
        }
    }

    const handleGetBranches = async () => {
        setIsLoadingBranches(true);
        try {
            const responses = await getList('branches');
            setBranches(responses);
            return responses;
        } catch (e) {
            console.log('e', e)

        }
        setIsLoadingBranches(false);
    };

    const getBranches = async (query) => {
        if (Array.isArray(hasLOBSelected?.current?.props?.value) && !hasLOBSelected.current.props.value.length) {
            setIsLoadingLOBs(true);
            try {
                if (query) {
                    const responses = await getList(`line-of-business?Branch=${query}`);
                    setLOBs(responses);
                }
            } catch (e) {
                console.log('e', e)
            }
            setIsLoadingLOBs(false);
        }
    }

    const handleGetLOB = async () => {
        setIsLoadingLOBs(true);
        try {
            const responses = await getList('line-of-business');
            setLOBs(responses);
        } catch (e) {
            console.log('e', e)

        }
        setIsLoadingLOBs(false);
    };

    const getLOBs = async (query) => {
        if (Array.isArray(hasBranchSelected?.current?.props?.value) && !hasBranchSelected.current.props.value.length) {
            setIsLoadingBranches(true);
            try {
                if (query) {
                    const responses = await getList(`branches?LOB=${query}`);
                    if (responses) {
                        setBranches(responses);
                    }
                }
            } catch (e) {
                console.log('e', e);
            }
            setIsLoadingBranches(false);
        }
    }

    const customStyles = width => ({
        control: provided => ({
            ...provided,
            width: width,
            height: '100%',
            minWidth: '300px',
        }),
        menuList: provided => ({
            ...provided,
            maxHeight: '200px',
        }),
        menu: provided => ({
            ...provided,
            zIndex: 100000,
        }),
        multiValue: (provided, state) => ({
            ...provided,
            backgroundColor: '#da0f2e',
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
            color: '#ffffff',
        }),
        multiValueRemove: (provided, state) => ({
            ...provided,
            color: '#ffffff',
        }),
    });

    const fieldsAreValid = () => {
        let valid = true;
        let formErrorsCopy = { ...errors };

        const requiredFields = [
            'branch',
            'lob',
            'message',
            'recipientStatus',
            'subject',
        ];

        if (!form?.branch) {
            formErrorsCopy = {
                ...formErrorsCopy,
                ['branch']: 'Please provide a valid value.',
            };
            valid = false;
        } else {
            formErrorsCopy = {
                ...formErrorsCopy,
                ['branch']: '',
            };
            valid = true;
        }

        if (!form?.lob) {
            formErrorsCopy = {
                ...formErrorsCopy,
                ['lob']: 'Please provide a valid value.',
            };
            valid = false;
        } else {
            formErrorsCopy = {
                ...formErrorsCopy,
                ['lob']: '',
            };
            valid = true;
        }

        if (!form?.recipientStatus) {
            formErrorsCopy = {
                ...formErrorsCopy,
                ['recipientStatus']: 'Please provide a valid value.',
            };
            valid = false;
        } else {
            formErrorsCopy = {
                ...formErrorsCopy,
                ['recipientStatus']: '',
            };
            valid = true;
        }

        requiredFields.forEach(field => {
            if (!form[field] || form[field] === '') {
                formErrorsCopy = {
                    ...formErrorsCopy,
                    [field]: 'Please provide a valid value.',
                };
                valid = false;
            }
        });

        setErrors(formErrorsCopy);

        return valid;
    }

    const handleCheckbox = (e) => {
        if (e.target.checked === true) {
            setForm({
                ...form,
                status: 'Sent',
                sentOn: dayjs().utc().format(),
                approvedBy: user.name,
                approvedAt: dayjs().utc().format(),
            });
        }
        else {
            setForm({
                ...form,
                status: 'Pending'
            });
        }
    }

    const confirmBranchHNotification = async () => {
        setIsLoading(true);

        if (fieldsAreValid()) {

            if (form?.status === 'Pending') {
                try {
                    await updateBranchPushNotification(form);
                    navigate(-1);
                } catch (e) {
                    setShowErrorModal(true);
                    console.log('error', e);
                }
            }

            if (form?.status === 'Sent') {
                try {
                    const response = await getDriversToBeNotifiedForPushNotifications(form);
                    return response;
                } catch (e) {
                    console.log('error', e);
                }
            }
        };

        setIsLoading(false);
    }

    const getDriversToBeNotifiedForPushNotifications = async (form) => {
        try {
            const response = await getCountForPushNotification(form);
            if (response?.responseCode === 200) {
                setIsLoading(false);
                setIsLoadingNotifiedNotifications(false);
                setDriversToBeNotifed(response?.driverCount);
                setShowDriversCountModal(true);
            }

            if (response?.responseCode === 404) {
                setIsLoading(false);
                setIsLoadingNotifiedNotifications(false);
                setDriversToBeNotifed(response?.driverCount);
                setShowDriversCountModal(true);
                setForm({
                    ...form,
                    status: 'Pending',
                });
            }

            return response;
        } catch (e) {
            setShowErrorModal(true);
            console.log('error', e);
        }
    }
    const sendBulkPushNotification = async (form) => {

        setIsLoadingNotifiedNotifications(true);

        try {
            const response = await sendPushNotification(form);

            if (response?.responseCode === 200 && response?.driverCount > 0) {
                setShowDriversCountModal(false);
                setDriversNotifed(response?.driverCount);
                setIsLoadingNotifiedNotifications(false);
                setShowConfirmationModal(true);
                await updateBranchPushNotification(form);
            }

            if (response?.responseCode === 404 && response?.responseMessage !== 'Success') {
                setDriversToBeNotifed(response?.driverCount);
                setShowDriversCountModal(true);
                setIsLoadingNotifiedNotifications(false);
                setForm({
                    ...form,
                    status: 'Pending',
                });
            }

            if (response?.errors !== undefined && response?.errors[0]?.errorType === 'Lambda:ExecutionTimeoutException') {
                setIsLoading(false);
                setIsLoadingNotifiedNotifications(false);
                setShowConfirmationModal(false);
                setShowExecutionTimeoutException(true);
            }

            return response;
        } catch (e) {
            console.log('error', e)
        }
    }

    const setField = (fields, value) => {
        if (Array.isArray(fields)) {
            const itemsToEdit = {};
            const newErrors = { ...errors };
            fields.forEach(field => {
                itemsToEdit[(field?.fieldName)] = field?.fieldValue;
                if (errors && !!errors[field.fieldName]) delete newErrors[(field?.fieldName)];
            });
            setForm({
                ...form,
                ...itemsToEdit
            })
            setErrors({
                ...newErrors
            });
        } else {
            setForm({
                ...form,
                [fields]: value,
            })
            if (errors && !!errors[fields]) {
                setErrors({
                    ...errors,
                    [fields]: null,
                });
            };
        }
    };

    const convertBranchArrayToObject = (items) => {
        let arr = [];
        let branch = {};

        if (items) {
            String(items).split(', ')?.forEach(i => {
                branch = branches?.find(
                    option => String(option.Branch) === i
                ),
                    arr.push({
                        label: `${branch?.Branch} - ${branch?.BranchName}`, value: branch?.Branch
                    })
            })
        };

        return arr;
    }

    const convertLOBSArrayToObject = (items) => {
        const arr = [];
        if (items) {
            items.split(',').forEach(i => {
                arr.push({ label: i, value: i })
            });
        };
        return arr;
    }

    const convertRecipientStatusArrayToObject = (items) => {
        let arr = [];
        let recipientStatus = {};

        if (items) {
            String(items).split(', ')?.forEach(i => {
                recipientStatus = recipientStatuses?.find(
                    option => String(option.REF_CODE) === i
                ),
                    arr.push({
                        label: `${recipientStatus?.REF_DESCRIPTION}`, value: recipientStatus?.REF_CODE
                    })
            })
        };
        return arr;
    }


    const handleCancel = () => {
        setForm({});
        setDriversToBeNotifed(0);
        navigate(-1);
    }

    useEffect(() => {
        if (form?.subject || form?.message) {
            setSubjectCharCount(form?.subject.trim().length);
            setMessageCharCount(form?.message.trim().length);
        }
    }, [form?.subject, form?.message])

    useEffect(() => {
        if (id) {
            getNotification(id);
        }
    }, [id])

    useEffect(() => {
        if (form?.branch !== '' && form?.lob !== '' || form?.branch !== null && form?.lob !== null) {
            getBranches(form?.branch);
            getLOBs(form?.lob);
        }
    }, [form?.branch, form?.lob])

    useEffect(() => {
        if (form?.branch === '' && form?.lob === '') {
            handleGetBranches();
            handleGetLOB();
        }
    }, [form?.branch, form?.lob])

    useEffect(() => {
        handleGetBranches();
        handleGetLOB();
        handleGetRecipientStatuses();
    }, [])

    return (
        <>
            <SubHeader
                text={"Push Notifications"}
                btnSmall={<Home sx={{ color: '#fff', fontSize: 30 }} />}
                smallAction={() => navigate(`/push-notifications`)}
            />

            <div style={{ marginLeft: 60 }}>
                <Breadcrumbs
                    leftTitle="PN Home"
                    middleTitle={"Branch Push Notifications"}
                    midItemAction={() => navigate(`../push-notifications/branch-push-notifications`, { replace: true })}
                    rightTitle={`${location.pathname.includes('edit') ? 'Edit Branch Notification' : 'View Branch Notification'}`}
                    route="/push-notifications/branch-push-notifications"
                    style={{ marginLeft: 30 }}
                />
            </div>

            <FormContent
                confirmBranchNotification={confirmBranchHNotification}
                customStyles={customStyles}
                location={location}
                errors={errors}
                branches={branches}
                lobs={lobs}
                getLOBs={getLOBs}
                getBranches={getBranches}
                isLoadingBRANCHS={isLoadingBranches}
                headerTitle={`${'Edit Branch Push Notification'}`}
                handleCheckbox={handleCheckbox}
                recipientStatuses={recipientStatuses}
                isLoadingRecipientStatus={isLoadingRecipientStatus}
                isLoadingLOBS={isLoadingLOBS}
                form={form}
                isLoading={isLoading}
                setForm={setForm}
                messageCharCount={messageCharCount}
                messageCharLimit={messageCharLimit}
                setField={setField}
                setMessageCharCount={setMessageCharCount}
                setSubjectCharCount={setSubjectCharCount}
                subjectCharCount={subjectCharCount}
                subjectCharLimit={subjectCharLimit}
                handleCancel={handleCancel}
                setShowDeleteModal={setShowDeleteModal}
                convertBranchArrayToObject={convertBranchArrayToObject}
                convertLOBSArrayToObject={convertLOBSArrayToObject}
                convertRecipientStatusArrayToObject={convertRecipientStatusArrayToObject}
                hasBranchSelected={hasBranchSelected}
                hasLOBSelected={hasLOBSelected}
            />

            <ModalConfirmation
                title={'Send Confirmation'}
                body={`This will be sent to ${driversToBeNotified} drivers`}
                isOpen={showDriversCountModal}
                primaryButton={driversToBeNotified > 0 ? true : false}
                optionalClose
                primaryButtonTitle={'Send'}
                secondaryButtonTitle={'Cancel'}
                isLoading={isLoadingNotifiedNotifications}
                onClick={async () => {
                    sendBulkPushNotification(form);
                }}
                secondaryOnClick={() => {
                    setShowDriversCountModal(false);
                }}
            />

            <ModalConfirmation
                title={'Please Confirm'}
                body={'Are you sure you want to permanently delete this push notification?'}
                isOpen={showDeleteModal}
                primaryButton
                optionalClose
                primaryButtonTitle={'OK'}
                secondaryButtonTitle={'Cancel'}
                onClick={() => {
                    setShowDeleteModal(false);
                    removeBranchPushNotification(id);
                    navigate(-1);
                }}
                secondaryOnClick={() => { setShowDeleteModal(false) }}
            />

            <ModalConfirmation
                title="Error"
                body="There was an error with the push notification. Please contact support."
                primaryButtonTitle="Close"
                onClick={() => {
                    setShowErrorModal(false);
                    navigate(-1);
                }}
                isOpen={showErrorModal}
                primaryButton
            />

            <ModalConfirmation
                title="Error"
                body="Execution Timeout Exception."
                primaryButtonTitle="Try Again"
                secondaryButtonTitle={'Cancel'}
                optionalClose
                onClick={() => {
                    setShowExecutionTimeoutException(false);
                    sendBulkPushNotification(form);
                }}
                secondaryOnClick={() => {
                    setForm({
                        ...form,
                        status: 'Pending',
                    });
                    setShowExecutionTimeoutException(false);
                }}
                isOpen={showExecutionTimeoutException}
                primaryButton
            />

            <ModalConfirmation
                title={'Send Confirmation'}
                body={`This will be sent to ${driversToBeNotified} drivers`}
                isOpen={showDriversCountModal}
                primaryButton={driversToBeNotified > 0 ? true : false}
                optionalClose
                primaryButtonTitle={'Send'}
                secondaryButtonTitle={'Cancel'}
                isLoading={isLoadingNotifiedNotifications}
                onClick={async () => {
                    sendBulkPushNotification(form);
                }}
                secondaryOnClick={() => {
                    setForm({
                        ...form,
                        status: 'Pending'
                    });
                    setShowDriversCountModal(false);
                }}
            />

            <ModalConfirmation
                title={'Send Confirmation'}
                body={`This was sent to ${driversNotified} drivers`}
                isOpen={showConfirmationModal}
                primaryButton={driversNotified > 0 ? true : false}
                primaryButtonTitle={'Ok'}
                onClick={async () => {
                    setShowConfirmationModal(false);
                    navigate(-1);
                }}
            />

            <Footer />
        </>
    );
}

export default editBranchPushNotifications;   