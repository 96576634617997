/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import TableFilter from './CommonTableFilter';
import TablePagination from './CommonTablePagination';
import { updateUser, updateUserRole } from './operations';
import { formatIsoToDate } from '../../utils';
import Modal from '../Modal';

function getSortIcon(isSorted, isSortedDesc) {
  let iconProp = faSort;
  if (isSorted) {
    iconProp = isSortedDesc ? faSortDown : faSortUp;
  }
  return <FontAwesomeIcon icon={iconProp} className="ml-1 fa-sm" />;
}

export default function CommonTable({
  data,
  handleSetData,
  handleViewApps,
  addUser,
  showInviteButton = false,
  dataType = 'Client',
}) {
  const columnClassName = 'align-middle text-nowrap';
  const memoData = React.useMemo(() => data, [data]);
  const [modalObj, setModalObj] = useState();
  const skipPageResetRef = React.useRef();
  const [clientId, setClient] = useState('');

  const statusButton = instance => {
    const thisRow = instance.data[parseInt(instance.cell.row.id)];
    const date = new Date(thisRow.updatedAt);
    date.setDate(date.getDate() + 7);
    const today = new Date();
    let text;
    switch (thisRow.status) {
      case 'Active':
        text = 'Disable';
        break;
      case 'Disabled':
        text = 'Enable';
        break;
      case 'In-Progress':
        text = today > date ? 'Invite' : null;
        break;
      case 'Invitation Expired':
        text = 'Invite';
        break;
      default:
        break;
    }

    return text ? (
      <Button onClick={() => statusModal(text, thisRow)} style={{ width: 80 }}>
        {text}
      </Button>
    ) : null;
  };

  const appsButton = instance => {
    const thisRow = instance.data[parseInt(instance.cell.row.id)];
    let appList = '';
    instance.value.items.forEach(item => {
      appList += `${item.appId.name}, `;
    });
    return (
      <Button
        style={{ width: 180, whiteSpace: 'normal', wordWrap: 'break-word' }}
        onClick={() => handleViewApps(thisRow)}>
        {appList === '' ? 'None' : appList.slice(0, -2)}
      </Button>
    );
  };

  const roleSelector = instance => {
    const thisRow = instance.data[parseInt(instance.cell.row.id)];
    return thisRow.status !== 'Active' ? (
      <span>{instance.value}</span>
    ) : (
      <Dropdown
        onSelect={type => {
          if (type !== instance.value) {
            statusModal('Toggle', thisRow);
          }
        }}>
        <Dropdown.Toggle>{instance.value}</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey="Approver">Approver</Dropdown.Item>
          <Dropdown.Item eventKey="Reviewer">Reviewer</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const statusModal = (text, row) => {
    switch (text) {
      case 'Enable':
        setModalObj({
          title: `Do you want to enable ${row.id}?`,
          action: 'Enable',
          row,
        });
        break;
      case 'Disable':
        setModalObj({
          title: `Do you want to disable ${row.id}?`,
          action: 'Disable',
          row,
        });
        break;
      case 'Invite':
        setModalObj({
          title: `Do you want to re-invite ${row.id}?`,
          action: 'Invite',
          row,
        });
        break;
      case 'Invitation Expired':
        setModalObj({
          title: `Do you want to re-invite ${row.id}?`,
          action: 'Invite',
          row,
        });
        break;
      case 'Toggle':
        setModalObj({
          title: `Do you want to toggle the Role of ${row.id}?`,
          action: 'Toggle',
          row,
        });
        break;
      default:
        setModalObj(null);
        break;
    }
  };

  const changeStatusExpired = instance => {
    const thisRow = instance.data[parseInt(instance.cell.row.id)];
    const today = new Date();
    const dateInvited = new Date(thisRow.createdAt);

    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
    const utc2 = Date.UTC(dateInvited.getFullYear(), dateInvited.getMonth(), dateInvited.getDate());
    const difference = (utc1 - utc2) / _MS_PER_DAY;
    return <>{thisRow.status === 'In-Progress' && difference > 7 ? 'Invitation Expired' : thisRow.status}</>;
  };

  const columns = React.useMemo(
    () =>
      dataType === 'Client'
        ? [
            {
              Header: 'Client ID',
              accessor: 'customerId',
              className: columnClassName,
            },
            {
              Header: 'Client Name',
              accessor: 'clientName',
              className: columnClassName,
            },
            {
              Header: 'Status',
              accessor: 'status',
              className: columnClassName,
              Cell: instance => changeStatusExpired(instance),
            },
            {
              Header: 'User',
              accessor: 'id',
              className: columnClassName,
              Cell: instance => (
                <Link to={`/client-users/update/${instance.row.original.customerId}/${instance.value}`}>
                  {instance.value}
                </Link>
              ),
            },
            {
              Header: 'User Name',
              accessor: 'name',
              className: columnClassName,
            },
            {
              Header: 'Apps',
              accessor: 'appAccess',
              className: columnClassName,
              Cell: instance => appsButton(instance),
            },
            {
              Header: 'Created',
              accessor: 'createdAt',
              className: columnClassName,
              Cell: instance => <>{`${formatIsoToDate(instance.value)}`}</>,
            },
            {
              Header: 'Last Login',
              accessor: 'lastLogin',
              className: columnClassName,
              Cell: instance => <>{`${formatIsoToDate(instance.value)}`}</>,
            },
            {
              Header: '',
              accessor: 'buttons',
              className: columnClassName,
              disableSortBy: true,
              Cell: instance => statusButton(instance),
            },
          ]
        : [
            {
              Header: 'Status',
              accessor: 'status',
              className: columnClassName,
              Cell: instance => changeStatusExpired(instance),
            },
            {
              Header: 'User',
              accessor: 'id',
              className: columnClassName,
            },
            {
              Header: 'User Name',
              accessor: 'name',
              className: columnClassName,
            },
            {
              Header: 'Apps',
              accessor: 'appAccess',
              className: columnClassName,
              Cell: instance => appsButton(instance),
            },
            {
              Header: 'Created',
              accessor: 'createdAt',
              className: columnClassName,
              Cell: instance => <>{`${formatIsoToDate(instance.value)}`}</>,
            },
            {
              Header: 'Last Login',
              accessor: 'lastLogin',
              className: columnClassName,
              Cell: instance => <>{`${formatIsoToDate(instance.value)}`}</>,
            },
            {
              Header: '',
              accessor: 'buttons',
              className: columnClassName,
              disableSortBy: true,
              Cell: instance => statusButton(instance),
            },
          ],
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data: memoData,
      initialState: {
        sortBy: [
          {
            id: 'id',
            desc: true,
          },
        ],
      },
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
      autoResetGlobalFilter: !skipPageResetRef.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setFilter,
    setGlobalFilter,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>{column.canSort ? getSortIcon(column.isSorted, column.isSortedDesc) : null}</span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}>
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  function filter(event, columnName) {
    const filterColumn = columns.find(column => column.Header === columnName);
    if (filterColumn) {
      setFilter(filterColumn.accessor, event.target.value);
    } else {
      setGlobalFilter(event.target.value);
    }
  }

  const modalAction = async (action, row) => {
    const values = {
      Disable: 'Disabled',
      Enable: 'Active',
      Invite: 'Invite Resent',
    };
    const toggle = { Approver: 'Reviewer', Reviewer: 'Approver' };
    const update =
      action === 'Toggle' ? await updateUserRole(row.id, toggle[row.role]) : await updateUser(row.id, values[action]);
    skipPageResetRef.current = true;
    await handleSetData();
    setModalObj(null);
    skipPageResetRef.current = false;
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <TableFilter columns={columns} onSearchChange={filter} />
          </Col>
          <Col className="d-flex justify-content-center">
            <TablePagination tableInstance={tableInstance} />
          </Col>
          <Col className="text-right">{showInviteButton && <Button onClick={addUser}>Invite New User</Button>}</Col>
        </Row>
        <Row>
          <Col className="border border-light rounded shadow-sm">
            <Table hover responsive borderless {...getTableProps()}>
              <thead className="text-nowrap">{tableHeaders}</thead>
              <tbody {...getTableBodyProps()}>{tableRows}</tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={!!modalObj}
        title={modalObj?.title}
        onClick={() => modalAction(modalObj?.action, modalObj?.row)}
        secondaryOnClick={() => setModalObj(null)}
        primaryButtonTitle={modalObj?.action}
        secondaryButtonTitle="Close"
        optionalClose
        bodyContent={false}
      />
    </>
  );
}

CommonTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      role: PropTypes.string,
      status: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
};

CommonTable.defaultProps = {
  data: [],
};
