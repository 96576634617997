import React, { useEffect, useMemo, useState } from "react";

import dayjs from 'dayjs';
import { getNationalPushNotification } from "./operations";
import { useSelector } from 'react-redux';
import { Breadcrumbs, SubHeader, Footer } from '../../../components'
import { getList } from './operations';
import { useNavigate, useParams } from 'react-router-dom';
import { Home } from '@mui/icons-material';
import ViewFormContent from './ViewFormContent';
import "./styles.scoped.css";

const viewNationalPushNotifications = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useSelector(state => state.admin?.user);
    const [recipientStatuses, setRecipientStatuses] = useState([]);
    const [isLoadingRecipientStatus, setIsLoadingRecipientStatus] = useState(false);
    const [messageCharCount, setMessageCharCount] = useState(0);
    const messageCharLimit = 150;
    const [subjectCharCount, setSubjectCharCount] = useState(0);
    const subjectCharLimit = 50;
    const [form, setForm] = useState({
        type: 'National',
        subject: '',
        message: '',
        url: '',
        status: 'Pending',
        lob: null,
        branch: null,
        recipientStatus: '',
        approvedBy: '',
        approvedAt: null,
        createdBy: '',
        createdAt: null,
        sentOn: null,
    });

    const utc = require('dayjs/plugin/utc')
    dayjs.extend(utc)

    const handleGetRecipientStatuses = async () => {
        setIsLoadingRecipientStatus(true);
        try {
            const responses = await getList('driver-status');
            setRecipientStatuses(responses);
        } catch (e) {
            console.log('e')
        }
        setIsLoadingRecipientStatus(false);
    }

    const customStyles = width => ({
        control: provided => ({
            ...provided,
            width: width,
            height: '100%',
            minWidth: '300px',
        }),
        menuList: provided => ({
            ...provided,
            maxHeight: '200px',
        }),
        menu: provided => ({
            ...provided,
            zIndex: 100000,
        }),
    });

    const getNotification = async (id) => {
        try {
            const response = await getNationalPushNotification(id);
            setForm({
                ...form,
                id: response?.id,
                type: response?.type,
                subject: response?.subject,
                message: response?.message,
                url: response?.url,
                status: response?.status,
                lob: response?.lob,
                branch: response?.branch,
                recipientStatus: response?.recipientStatus,
                approvedBy: response?.name,
                approvedAt: response?.approvedAt,
                sentOn: response?.sentOn,
                createdBy: response?.createdBy,
                createdAt: response?.createdAt
            });
        } catch (e) {
            console.log('e', e);
        }

    }

    const handleCancel = () => {
        setForm({});
        navigate(-1);
    }

    const handleCheckbox = (e) => {
        if (e.target.checked === true) {
            setForm({
                ...form,
                status: 'Sent',
                sentOn: dayjs().utc().format(),
                approvedBy: user.name,
                approvedAt: dayjs().utc().format(),
            });
        }
        if (e.target.checked !== true) {
            setForm({
                ...form,
                status: 'Pending'
            });
        }
    }

    useEffect(() => {
        if (form?.subject || form?.message) {
            setSubjectCharCount(form?.subject.trim().length);
            setMessageCharCount(form?.message.trim().length);
        }
    }, [form?.subject, form?.message])

    useEffect(() => {
        if (id) {
            getNotification(id);
        }
    }, [id])

    useEffect(() => {
        handleGetRecipientStatuses();
    }, [])

    return (
        <>
            <SubHeader
                text={"Push Notifications"}
                btnSmall={<Home sx={{ color: '#fff', fontSize: 30 }} />}
                smallAction={() => navigate(`/push-notifications`)}
            />

            <div style={{ marginLeft: 60 }}>
                <Breadcrumbs
                    leftTitle="PN Home"
                    middleTitle={"National Push Notifications"}
                    midItemAction={() => navigate(`../push-notifications/national-push-notifications`, { replace: true })}
                    rightTitle={`${location.pathname.includes('edit') ? 'Edit National Notification' : 'View National Notification'}`}
                    route="/push-notifications/national-push-notifications"
                    style={{ marginLeft: 30 }}
                />
            </div>

            <ViewFormContent
                customStyles={customStyles}
                handleCancel={handleCancel}
                handleCheckbox={handleCheckbox}
                headerTitle={`${location.pathname.includes('edit') ? 'Edit National Notification' : 'View National Notification'}`}
                recipientStatuses={recipientStatuses}
                isLoadingRecipientStatus={isLoadingRecipientStatus}
                form={form}
                messageCharCount={messageCharCount}
                messageCharLimit={messageCharLimit}
                setMessageCharCount={setMessageCharCount}
                setSubjectCharCount={setSubjectCharCount}
                subjectCharCount={subjectCharCount}
                subjectCharLimit={subjectCharLimit}
            />

            <Footer />
        </>
    );
}

export default viewNationalPushNotifications;   