import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';

import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useBeforeunload } from 'react-beforeunload';
import { MdWeb } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import FormContent from './FormContent';
import { getFormData } from './operations';
import { Breadcrumbs, ExpensesWindow, ModalConfirmation, SubHeader } from '../../../../components';
import './styles.scoped.css';
import {
  resetData,
  resetDriversAssignmentsData,
  setClientFormData,
  setData,
  setIsLoadingClientForm,
} from '../../../../components/ExpenseForm/expenseFormSlice';
import { formatIsoToDate } from '../../../../utils';
import { isBilledOrTransmitted } from '../../../../utils/utilities';
import { getUserInfo } from '../../../DriverQualification/ViewDQF/operations';
import { FlatFeeModal, receiptsHaveReceiptAmount } from '../expensesUtils';
import {
  createExpense,
  createExpenseItem,
  deleteExpenseFile,
  getClientBranches,
  getClients,
  getExpenseId,
  updateExpense,
} from '../../DriverSolutions/operations';
import { dateToTimeZero, preventMultipleSave } from '../TransferToUltraStaff/utils';

dayjs.extend(customParseFormat);

const Hotel = () => {
  const idRef = useRef('');
  const [singleOrShared, setSingleOrShared] = useState('single');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.admin);
  const [expenseId, setExpenseId] = useState(null);
  const { clientMenu } = useSelector(state => state.expenseEntry);
  const { data, clientFormData } = useSelector(state => state.expenseForm);

  const [formData, setFormData] = useState();
  const [auditKey, setAuditKey] = useState('--');
  const [currentUser, setCurrentUser] = useState('');
  const [totalToBeBilled, setTotalToBeBilled] = useState('0.00');
  const [receipts, setReceipts] = useState([{ markup: '0', receiptAmount: '0.0', dailyCharge: '0.0' }]);
  const [sharedReceipts, setSharedReceipts] = useState([{ markup: '0' }]);
  const [sharedReceiptErrors, setSharedReceiptsErrors] = useState([{}]);
  const [clients, setClients] = useState([]);
  const [isCreatingItems, setIsCreatingItems] = useState(false);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [isLoadingBranches, setIsLoadingBranches] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showReceiptFilesDialog, setShowReceiptFilesDialog] = useState(false);
  const currentStateRef = useRef('');
  const hasSetRebillData = useRef(false);
  const isSettingState = useRef([]);
  const isSettingCity = useRef([]);
  const formHasChanged = useRef({
    drivers: false,
    assignments: false,
    clients: false,
    branches: false,
  });
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState();
  const [assignmentLineOfBusinessItems, setAssignmentLineOfBusinessItems] = useState({});
  const assignmentLineOfBusiness = useRef('');

  const getAssignmentLineOfBusinessItems = () => {
    clientMenu?.menuItems?.map(i => {
      if (location.pathname.includes(i.to)) {
        setAssignmentLineOfBusinessItems(i);
      }
    })
  };

  const getId = async () => {
    const id = await getExpenseId();
    setExpenseId(id);
  }

  useEffect(() => {
    if (uploadError) {
      // eslint-disable-next-line no-undef
      alert(uploadError);
      setUploadError(null);
    }
  }, [uploadError]);

  useEffect(
    () => () => {
      dispatch(resetData());
    },
    [],
  );

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      document.querySelector('div.root').style.background = '#D0CED0';
      document.querySelector('span.wrap').style.background = '#ffff';
      document.querySelector('.left-title').style.color = '#ffff';
      document.querySelector('.right-title').style.color = '#66CCFF';
    }
    return () => {
      document.querySelector('div.root').style.background = '#ffff';
    }
  }, []);

  useLayoutEffect(() => {
    if (location.pathname.includes('driver-management-services')) {
      assignmentLineOfBusiness.current = 'DMS';
    }
    if (location.pathname.includes('mobile-driver-solutions')) {
      assignmentLineOfBusiness.current = 'MDS';
    }
  }, [])

  useEffect(() => {
    if (assignmentLineOfBusiness.current && form?.lob === '') {
      setForm({
        ...form,
        lob: assignmentLineOfBusiness.current
      })
    }
  }, [assignmentLineOfBusiness.current])


  useEffect(() => {
    if (expenseId !== '' || expenseId !== null) {
      getId();
    }
  }, [])

  const hasSetReceiptOptions = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showFFDialog, setShowFFDialog] = useState(false);
  const [showNonFFDialog, setShowNonFFDialog] = useState(false);
  const [form, setForm] = useState({
    code: 'XH',
    status: 'Unbilled',
    nonBillable: false,
    checkedForUltraStaff: false,
    lob: '',
    inv: 'I',
    payType: 'F',
    billUnits: '1',
    payAmt: 0.0,
    ok: 'T',
  });

  const checkReceiptsErrors = () => {
    let allErrors = [...receiptsErrors];
    let receiptsCopy = [...receipts];

    let requiredReceiptFields = [
      'expenseItemHotelId',
      'hotelConfirmationNumber',
      'hotelPhoneNumber',
      'hotelStreetAddress',
      'expenseItemHotelStateId',
      'expenseItemHotelCityId',
      'checkInDate',
      'lastNightOfStay',
      'dailyCharge',
      'markup',
      'receiptAmount',
    ];

    if (singleOrShared === 'shared') {
      allErrors = [...sharedReceiptErrors];
      requiredReceiptFields = [
        'hotelDriverId',
        'hotelDriverName',
        'hotelAssignmentId',
        'checkInDate',
        'lastNightOfStay',
        'dailyCharge',
        'markup',
        'receiptAmount',
      ];
      receiptsCopy = [...sharedReceipts];
    }

    allErrors.forEach((receiptErrors, id) => {
      const receiptErrorsCopy = { ...receiptErrors };
      requiredReceiptFields.forEach(field => {
        if (receiptsCopy?.[id][field]) {
          delete receiptErrorsCopy[field];
          if (!Object.keys(receiptErrorsCopy).length) {
            allErrors.splice(id, 1);
          } else {
            allErrors[id] = receiptErrorsCopy;
          }
        }
      });
      if (singleOrShared === 'shared') {
        setSharedReceiptsErrors(allErrors);
      } else {
        setReceiptsErrors(allErrors);
      }
    });
  };

  useEffect(() => {
    checkReceiptsErrors();
  }, [receipts, sharedReceipts]);

  useBeforeunload(event => {
    if (hasChanges) {
      event.preventDefault();
    }
  });

  const bulkDeleteReceipts = async items => {
    Promise.all(items.map(item => deleteExpenseFile(item)));
  };

  const removeReceiptDocs = async () => {
    const receiptDocs = [];
    let allReceipts = [...receipts];
    if (singleOrShared === 'shared') {
      allReceipts = [...sharedReceipts];
    }
    allReceipts?.forEach(r => {
      receiptDocs.push(r?.documentName);
    });
    if (receiptDocs.length) {
      await bulkDeleteReceipts(receiptDocs);
    }
  };

  const setReceiptOptions = () => {
    if (formData?.hotels?.length && Object.keys(receipts?.[0]).length) {
      const receiptsCopy = [...receipts];
      hasSetReceiptOptions.current = true;
      receiptsCopy.forEach(r => {
        const selectedHotel = formData?.hotels?.filter(hotel => hotel.id === r?.expenseItemHotelId)?.[0]?.name;
        r.selectedHotel = selectedHotel;
        r.checkInDate = formatIsoToDate(r?.checkInDate, 'YYYY-MM-DD');
        r.lastNightOfStay = formatIsoToDate(r?.lastNightOfStay, 'YYYY-MM-DD');
      });
      setReceipts(receiptsCopy);
    }
  };

  useEffect(() => {
    if (hasSetReceiptOptions.current === false && location?.state?.expense) {
      if (receipts?.length) setReceiptOptions();
    }
  }, [formData?.hotels, receipts]);

  //for rebill, check if expenses and receipts are passed
  useEffect(() => {
    if ((form, location?.state?.expense && location?.state.receipts && !hasSetRebillData.current)) {
      hasSetRebillData.current = true;
      setIsLoading(true);
      const rebilledExpense = {
        ...location?.state?.expense,
        originalExpenseId: location?.state?.expenseId,
        code: `${location?.state?.expense?.code}R`,
        status: 'Unbilled',
        statementDate: dateToTimeZero(location?.state?.expense?.statementDate),
      };
      delete rebilledExpense.id;

      setTimeout(() => {
        setForm(rebilledExpense);
        setIsLoading(false);
        if (location?.state?.expense?.sharedInvoice) {
          setSingleOrShared('shared');
        }
      }, 5000);

      const receiptsCopy = [...[location?.state?.receipts[0] || []]];
      receiptsCopy.forEach(receipt => {
        delete receipt.id;
        delete receipt.expenseExpenseItemId;
      });

      if (location?.state?.expense?.sharedInvoice) {
        setSharedReceipts(receiptsCopy);
      } else {
        setReceipts(receiptsCopy);
      }
    }
  }, [location?.state, form]);

  const [errors, setErrors] = useState();
  const [receiptsErrors, setReceiptsErrors] = useState([]);

  const getClientsToSelect = async (query) => {
    try {
      setIsLoadingCustomers(true);
      const newClients = await getClients(query);
      setClients(newClients);
      setIsLoadingCustomers(false);
    } catch (error) {
      console.log('error: ', error);
      setIsLoadingCustomers(false);
    }
  };

  const getBranchToSelect = async query => {
    try {
      return await getClientBranches(query);
    } catch (error) {
      console.log('error: ', error);
      setIsLoadingBranches(false);
      return undefined;
    }
  };

  const handleGetData = async () => {
    setIsCreatingItems(true);
    await getClientsToSelect(`clients?lob=${assignmentLineOfBusiness.current}`);
    await getBranchToSelect();
    setIsCreatingItems(false);
  };


  useEffect(() => {
    if (assignmentLineOfBusiness.current) {
      handleGetData();
      getAssignmentLineOfBusinessItems();
    }
  }, [assignmentLineOfBusiness.current])

  const fieldsAreValid = () => {
    let formErrorsCopy = {
      ...errors,
    };
    let receiptsErrorsCopy = [...receiptsErrors];
    if (singleOrShared === 'shared') {
      receiptsErrorsCopy = [...sharedReceiptErrors];
    }
    let requiredFields = ['driverId', 'driverName', 'assignmentId', 'status', 'customerId', 'customerName', 'branch'];

    if (singleOrShared === 'shared') {
      requiredFields = [
        'customerId',
        'customerName',
        'branch',
        'expenseHotelId',
        'expenseHotelStateId',
        'expenseHotelCityId',
        'hotelConfirmationNumber',
        'hotelPhoneNumber',
        'hotelStreetAddress',
      ];
    }

    requiredFields.forEach(field => {
      if (!form[field] || form[field] === '' || form[field] === '--') {
        formErrorsCopy = {
          ...formErrorsCopy,
          [field]: 'Please provide a valid value.',
        };
      }
    });

    let requiredReceiptFields = [
      'expenseItemHotelId',
      'hotelConfirmationNumber',
      'hotelPhoneNumber',
      'hotelStreetAddress',
      'expenseItemHotelStateId',
      'expenseItemHotelCityId',
      'checkInDate',
      'lastNightOfStay',
      'dailyCharge',
      'markup',
      'receiptAmount',
    ];

    if (singleOrShared === 'shared') {
      requiredReceiptFields = [
        'hotelDriverId',
        'hotelDriverName',
        'hotelAssignmentId',
        'checkInDate',
        'lastNightOfStay',
        'dailyCharge',
        'markup',
        'receiptAmount',
      ];
    }

    let allReceipts = [...receipts];

    if (singleOrShared === 'shared') {
      allReceipts = [...sharedReceipts];
    }

    allReceipts.forEach((r, index) => {
      requiredReceiptFields.forEach(rf => {
        if (rf === 'dailyCharge' || rf === 'receiptAmount' || rf === 'markup') {
          if (r[rf] === '') {
            receiptsErrorsCopy[index] = {
              ...receiptsErrorsCopy[index],
              [rf]: 'Please provide a valid value.',
            };
          }
        } else if (!r[rf] || r[rf] === '' || r[rf] === '--') {
          receiptsErrorsCopy[index] = {
            ...receiptsErrorsCopy[index],
            [rf]: 'Please provide a valid value.',
          };
        }
      });
      if (receiptsErrorsCopy[index] && !Object.keys(receiptsErrorsCopy[index]).length) {
        receiptsErrorsCopy.splice(index, 1);
      }
    });

    if (singleOrShared === 'shared') {
      setSharedReceiptsErrors(receiptsErrorsCopy);
    } else {
      setReceiptsErrors(receiptsErrorsCopy);
    }
    setErrors(formErrorsCopy);
    return !receiptsErrorsCopy.length && !Object.keys(formErrorsCopy).length;
  };

  const bulkCreateExpenseItem = async (items, route) => {
    Promise.all(items.map(item => createExpenseItem(item))).then(() => {
      setIsCreatingItems(false);
      if (singleOrShared !== 'shared' || (!location?.state?.expenseId && singleOrShared === 'single')) {
        setTimeout(() => {
          navigate(route || assignmentLineOfBusinessItems.to);
        }, 1000);
      }
    });
  };

  const receiptsHaveFiles = () => {
    let haveFiles = true;
    if (singleOrShared === 'single') {
      const allReceipts = [...receipts];

      allReceipts.forEach(r => {
        if (!r?.documentName) {
          haveFiles = false;
        }
      });
    } else if (!form?.documentName) {
      haveFiles = false;
    }
    return haveFiles;
  };

  const receiptsHaveRequiredFields = () => {
    let hasRequiredFields = true;
    receipts.forEach(r => {
      if ((!r?.receiptAmount || r?.receiptAmount === 0.0) && singleOrShared === 'single') {
        hasRequiredFields = false;
      }

      if ((!r?.receiptAmount || r?.receiptAmount === 0.0) && singleOrShared === 'shared') {
        hasRequiredFields = false;
      }
    });

    if ((!form?.totalReceiptAmount || form?.totalReceiptAmount === 0.0) && singleOrShared === 'shared') {
      hasRequiredFields = false;
    }

    return hasRequiredFields;
  };

  const getReceiptStatus = () => {
    let status = form?.status;
    if (form?.status !== 'Non-billable' && form?.status !== 'Flat Fee Billing') {
      if (
        receiptsHaveFiles() &&
        receiptsHaveRequiredFields() &&
        singleOrShared === 'single' &&
        receiptsHaveReceiptAmount(receipts)
      ) {
        status = 'Ready to Bill';
      } else {
        status = 'Unbilled';
      }
    }

    return status;
  };

  const confirmCreateExpense = async ({ isFF = false }) => {
    if (fieldsAreValid()) {
      setIsCreatingItems(true);
      const id = await getExpenseId();
      idRef.current = id;
      const costCenter = location.pathname.includes('mobile')
        ? process.env.REACT_APP_MDS_COST_CENTER
        : process.env.REACT_APP_DMS_COST_CENTER;
      let expense;

      if (singleOrShared === 'shared') {
        expense = {
          id: expenseId,
          code: isFF ? 'XFFBHS' : location?.state?.expense ? form?.code : 'XHS',
          status: isFF ? 'Flat Fee Billing' : getReceiptStatus(),
          nonBillable: isFF || form?.nonBillable,
          customerId: form?.customerId,
          customerName: form?.customerName,
          branch: form?.branch,
          branchName: form?.branchName,
          costCenter: parseInt(costCenter),
          startDate: form?.startDate ? dateToTimeZero(form?.startDate) : null,
          endDate: form?.endDate ? dateToTimeZero(form?.endDate) : null,
          totalToBeBilled: totalToBeBilled,
          notes: form?.notes,
          auditKey: auditKey,
          sharedInvoice: true,
          documentName: form?.documentName,
          documentSize: form?.documentSize,
          uploadedByName: form?.uploadedByName,
          uploadedDate: form?.uploadedDate,
          totalReceiptAmount: form?.totalReceiptAmount ? form?.totalReceiptAmount : 0.0,
          expenseHotelId: form?.expenseHotelId,
          expenseHotelStateId: form?.expenseHotelStateId,
          expenseHotelCityId: form?.expenseHotelCityId,
          hotelConfirmationNumber: form?.hotelConfirmationNumber,
          hotelPhoneNumber: form?.hotelPhoneNumber,
          hotelStreetAddress: form?.hotelStreetAddress,
          totalProjectedAmount: form?.totalProjectedAmount,
          flatFeeBilling: isFF,
          variance: -totalToBeBilled,
          lob: form?.lob,
          updatedBy: user.id
        };
      } else {
        expense = {
          id: expenseId,
          code: isFF ? 'XFFBH' : form?.code,
          driverId: form?.driverId,
          driverName: form?.driverName,
          assignmentId: form?.assignmentId,
          status: isFF ? 'Flat Fee Billing' : getReceiptStatus(),
          nonBillable: isFF || form?.nonBillable,
          customerId: form?.customerId,
          customerName: form?.customerName,
          branch: form?.branch,
          branchName: form?.branchName,
          costCenter: parseInt(costCenter),
          startDate: form?.startDate ? dateToTimeZero(form?.startDate) : null,
          endDate: form?.endDate ? dateToTimeZero(form?.endDate) : null,
          totalToBeBilled: totalToBeBilled,
          notes: form?.notes,
          auditKey: auditKey,
          sharedInvoice: false,
          totalProjectedAmount: form?.totalProjectedAmount,
          flatFeeBilling: isFF,
          variance: -totalToBeBilled,
          lob: form?.lob,
          updatedBy: user.id
        };
      }
      if (location?.state?.expenseId) {
        expense.originalExpenseId = location?.state?.expenseId;
      }
      if (singleOrShared === 'shared') {
        expense.driverId = sharedReceipts[0]?.hotelDriverId;
        expense.driverName = sharedReceipts[0]?.hotelDriverName;
        expense.assignmentId = sharedReceipts[0]?.hotelAssignmentId;
      }
      try {
        const resp = await createExpense(expense);
        if (resp) {
          let receiptsCopy = [...receipts];
          if (singleOrShared === 'shared') {
            receiptsCopy = [...sharedReceipts];
          }
          receiptsCopy.forEach(receipt => {
            receipt.expenseExpenseItemId = resp.id;

            if (!receipt?.receiptAmount) {
              receipt.receiptAmount = 0.0;
              receipt.expenseTotal = 0.0;
            }
            String(receipt.dailyCharge);
            String(receipt.receiptAmount);
            receipt.checkInDate = receipt?.checkInDate ? dateToTimeZero(receipt?.checkInDate) : null;
            receipt.lastNightOfStay = receipt?.lastNightOfStay ? dateToTimeZero(receipt?.lastNightOfStay) : null;

            if (singleOrShared === 'shared') {
              delete receipt.selectedDriver;
            } else {
              delete receipt.id;
              delete receipt.selectedState;
              delete receipt.selectedCity;
              delete receipt.selectedCityState;
              delete receipt.selectedHotel;
              delete receipt.selectedCities;
            }
            delete receipt.createdAt;
            delete receipt.updatedAt;
          });
          await bulkCreateExpenseItem(receiptsCopy);
          if (location?.state?.expenseId) {
            await updateExpense({
              id: location?.state?.expenseId,
              rebilledExpenseId: resp?.id,
            });
            navigate(assignmentLineOfBusinessItems.to);
          }
        }
        setIsCreatingItems(false);
        if (singleOrShared === 'shared' && form?.documentName) {
          navigate(`${assignmentLineOfBusinessItems.to}/edit-hotel/${idRef.current}`);
        } else {
          navigate(assignmentLineOfBusinessItems.to);
        }
      } catch (error) {
        setIsCreatingItems(false);
        console.log(error);

        // eslint-disable-next-line no-undef
        alert('There was an error, please try again later');
      }
    }
    setIsCreatingItems(false);
  };

  const calculateTotal = () => {
    let allReceipts = [...receipts];

    if (singleOrShared === 'shared') {
      allReceipts = [...sharedReceipts];
    }

    setTotalToBeBilled(
      parseFloat(allReceipts?.reduce((a, b) => parseFloat(a) + (parseFloat(b?.expenseTotal) || 0), 0)).toFixed(2),
    );
  };

  const getStartAndEndDate = () => {
    let startDate = '';
    let endDate = '';
    const emptyDate = '--';

    let allReceipts = [...receipts];

    if (singleOrShared === 'shared') allReceipts = [...sharedReceipts];

    allReceipts.forEach(r => {
      if (!startDate.length && r?.checkInDate) {
        startDate = r?.checkInDate;
        endDate = r?.lastNightOfStay;
      } else {
        if (r?.checkInDate && r?.checkInDate < startDate) {
          startDate = r?.checkInDate;
        }
        if (r?.lastNightOfStay && r?.lastNightOfStay > endDate) {
          endDate = r?.lastNightOfStay;
        }
      }
    });

    const emptyDates = {
      startDate: emptyDate,
      endDate: emptyDate,
    };
    const newDates = {
      startDate: formatIsoToDate(startDate),
      endDate: formatIsoToDate(endDate),
    };
    if (!!startDate && !!endDate) {
      setForm({
        ...form,
        ...newDates,
      });
    } else {
      setForm({
        ...form,
        ...emptyDates,
      });
    }
  };

  const calculateAuditKey = () => {
    let customDate = '';
    if (
      form?.startDate?.length > 2 &&
      form?.endDate?.length > 2 &&
      ((form?.driverId && singleOrShared === 'single') || (singleOrShared === 'shared' && form?.customerId)) &&
      totalToBeBilled !== '0.00'
    ) {
      const startDate = dayjs('1899-12-31');
      const endDate = formatIsoToDate(form.startDate);

      const differenceInDays = (dayjs(endDate)?.diff(startDate, 'day') || 0) + 1;

      customDate = differenceInDays.toFixed(0).toString();
      if (singleOrShared === 'shared') {
        if (form?.customerId) {
          setAuditKey(`${form?.customerId}${totalToBeBilled}${customDate}`);
        }
      } else if (form?.driverId) {
        setAuditKey(`${form?.driverId}${totalToBeBilled}${customDate}`);
      }
    }
  };

  useEffect(() => {
    calculateTotal();
    getStartAndEndDate();
    if (Object.keys(receipts[0]) && !hasChanges) {
      setHasChanges(true);
    }
  }, [receipts, sharedReceipts, singleOrShared]);

  useEffect(() => {
    calculateAuditKey();
  }, [form, totalToBeBilled, singleOrShared]);

  const handleClientFlow = async () => {
    if (form?.customerId !== clientFormData?.customerId) {
      dispatch(setIsLoadingClientForm(true));
      setIsLoadingBranches(true);
      dispatch(
        setClientFormData({
          ...clientFormData,
          customerId: form.customerId,
        }),
      );

      const branches = await getBranchToSelect(`?clientId=${form?.customerId}`);

      const newData = {
        branches: branches,
      };
      dispatch(setData({ ...data, ...newData }));
      dispatch(setIsLoadingClientForm(false));
      dispatch(resetDriversAssignmentsData());
      setIsLoadingBranches(false);
    }
  };

  useEffect(() => {
    if (!isBilledOrTransmitted(form)) {
      handleClientFlow();
    }
  }, [form?.customerId]);

  const getAndSetUserInfo = async () => {
    const { email } = (await Auth.currentSession()).getIdToken().payload;
    const user = await getUserInfo(email);
    setCurrentUser(user);
  };

  const getAndSetFormData = async () => {
    const newFormData = await getFormData();
    if (newFormData) {
      setFormData(newFormData);
    }
  };

  useEffect(() => {
    getAndSetFormData();
    getAndSetUserInfo();
    getAssignmentLineOfBusinessItems();
  }, []);

  return (
    <div>
      <SubHeader
        text="Expenses"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Expenses?"
        emailAddress={process.env.REACT_APP_EXPENSE_CONTACT_EMAIL}
      />
      <Breadcrumbs
        leftTitle={assignmentLineOfBusinessItems.text}
        rightTitle="Add Hotel"
        route={assignmentLineOfBusinessItems.to}
        color={assignmentLineOfBusinessItems.lob === 'DMS' && '#ffff'}
      />
      <ExpensesWindow
        windowContent={
          <FormContent
            id={expenseId}
            formData={formData}
            form={form}
            singleOrShared={singleOrShared}
            errors={errors}
            formHasChanged={formHasChanged}
            isLoading={isLoading}
            isLoadingCustomers={isLoadingCustomers}
            isLoadingBranches={isLoadingBranches}
            location={location}
            setUploading={setUploading}
            setUploadError={setUploadError}
            sharedReceipts={sharedReceipts}
            receipts={receipts}
            totalToBeBilled={totalToBeBilled}
            auditKey={auditKey}
            isCreatingItems={isCreatingItems}
            saveDisabled={saveDisabled}
            setSharedReceipts={setSharedReceipts}
            sharedReceiptErrors={sharedReceiptErrors}
            setSharedReceiptsErrors={setSharedReceiptsErrors}
            receiptsErrors={receiptsErrors}
            isSettingState={isSettingState}
            isSettingCity={isSettingCity}
            hasChanges={hasChanges}
            setHasChanges={setHasChanges}
            setForm={setForm}
            setErrors={setErrors}
            setReceipts={setReceipts}
            setSingleOrShared={setSingleOrShared}
            setShowCancelDialog={setShowCancelDialog}
            confirmCreateExpense={confirmCreateExpense}
            setShowFFDialog={setShowFFDialog}
            setShowNonFFDialog={setShowNonFFDialog}
            preventMultipleSave={preventMultipleSave}
            setSaveDisabled={setSaveDisabled}
            fieldsAreValid={fieldsAreValid}
            receiptsHaveFiles={receiptsHaveFiles}
            setShowReceiptFilesDialog={setShowReceiptFilesDialog}
            clients={clients}
            data={data}
            deleteExpenseFile={deleteExpenseFile}
            currentUser={currentUser}
            setReceiptsErrors={setReceiptsErrors}
            currentStateRef={currentStateRef}
            assignmentLineOfBusinessItems={assignmentLineOfBusinessItems}
          />
        }
        windowTitle="Add Hotel"
      />
      <ModalConfirmation
        title="Cancel?"
        body="Changes you made will be lost"
        primaryButton
        primaryButtonTitle="Stay on Page"
        optionalClose
        secondaryButtonTitle="Leave Page"
        onClick={() => {
          setShowCancelDialog(false);
        }}
        secondaryOnClick={async () => {
          setShowCancelDialog(false);
          await removeReceiptDocs();
          navigate(assignmentLineOfBusinessItems.to);
        }}
        isOpen={showCancelDialog}
      />
      <ModalConfirmation
        title="Please Note!"
        body="Please attach the receipt when available or this expense will not be billed"
        primaryButton
        primaryButtonTitle="OK"
        onClick={async () => {
          setShowReceiptFilesDialog(false);
          await confirmCreateExpense({
            isFF: form?.flatFeeBilling,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        isOpen={showReceiptFilesDialog}
      />
      <FlatFeeModal
        showDialog={showFFDialog}
        clientIsFlatFee
        setShowDialog={setShowFFDialog}
        ffAction={async () => {
          await confirmCreateExpense({
            isFF: true,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        billableAction={async () => {
          await confirmCreateExpense({
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        cancelAction={async () => { }}
      />
      <FlatFeeModal
        showDialog={showNonFFDialog}
        clientIsFlatFee={false}
        setShowDialog={setShowNonFFDialog}
        ffAction={async () => {
          await confirmCreateExpense({
            isFF: true,
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        billableAction={async () => {
          await confirmCreateExpense({
            route: assignmentLineOfBusinessItems.to,
          });
        }}
        cancelAction={() => { }}
      />
      {uploading && <div className="overlay-box" />}
    </div>
  );
};

export default Hotel;
