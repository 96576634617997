import React from 'react';

import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from 'react-table';

import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import './styles.scoped.css';
import { TablePagination } from '../../../components';

const columnClassName = 'align-middle text-nowrap';

const casColumns = [
    {
        Header: 'Client Name',
        accessor: 'Client_Name',
        className: columnClassName,
    },
    {
        Header: 'Client Number',
        accessor: 'Client_Number',
        className: columnClassName,
    },
    {
        Header: 'Client BillTo Number',
        accessor: 'Client_BillTo_Number',
        className: columnClassName,
    },
    {
        Header: 'Branch',
        accessor: 'branch',
        className: columnClassName,
    },
    {
        Header: 'LOB',
        accessor: 'LOB',
        className: columnClassName,
    },
    {
        Header: 'Contact Name',
        accessor: 'Contact_Name',
        className: columnClassName,
    },
    {
        Header: 'Activity Date',
        accessor: 'Activity_Date',
        className: columnClassName,
    },
    {
        Header: 'Consultant',
        accessor: 'Consultant',
        className: columnClassName,
    },
    {
        Header: 'Call Reason Code',
        accessor: 'Call_Reason_Code',
        className: columnClassName,
    },
    {
        Header: 'Activity_Description',
        accessor: 'Activity_Description',
        className: columnClassName,
    },
    {
        Header: 'Last Contact Date',
        accessor: 'Last_Contact_Date',
        className: columnClassName,
    },
    {
        Header: 'Followup Date',
        accessor: 'Followup_Date',
        className: columnClassName,
    },
    {
        Header: 'Note Date',
        accessor: 'Note_Date',
        className: columnClassName,
    },
    {
        Header: 'Note Time',
        accessor: 'Note_Time',
        className: columnClassName,
    },
    {
        Header: 'Note',
        accessor: 'Note',
        className: columnClassName,
    },
];

function getSortedIcon(isSorted, isSortedDesc) {
    let iconProp = <FaSort />;
    if (isSorted) {
        iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
    }
    return iconProp;
}

const ClientContactActivityTable = ({ data, loading }) => {
    const tableInstance = useTable(
        {
            columns: casColumns,
            data,
            initialState: {
                pageSize: 200,
                sortBy: [
                    {
                        id: 'CreatedAt',
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
    } = tableInstance;

    const tableHeaders = headerGroups.map(headerGroup => {
        const headers = headerGroup.headers.map(column => (
            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                    {column.canSort
                        ? getSortedIcon(column.isSorted, column.isSortedDesc)
                        : null}
                </span>
            </th>
        ));
        return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
    });

    const tableRows = page.map(row => {
        prepareRow(row);
        const cells = row.cells.map(cell => (
            <td
                {...cell.getCellProps({
                    className: cell.column.className,
                    style: cell.column.style,
                })}
            >
                {cell.render('Cell')}
            </td>
        ));

        return <tr {...row.getRowProps()}>{cells}</tr>;
    });

    return (
        <Container fluid>
            <Row className="align-items-center">
                <Col className="d-flex justify-content-center justify-content-md-end">
                    <TablePagination tableInstance={tableInstance} />
                </Col>
            </Row>
            <Row>
                <Col className="border border-light rounded shadow-sm">
                    <Table hover responsive borderless {...getTableProps()}>
                        <thead className="text-nowrap">{tableHeaders}</thead>
                        <tbody {...getTableBodyProps()}>{tableRows}</tbody>
                    </Table>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {loading && <Spinner animation="border" />}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ClientContactActivityTable;

