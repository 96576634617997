import { setLoading, setCurrentQuote } from './clericalSlice';
import { Auth } from 'aws-amplify';
import quoteApi from '../../../../utils/quoteAPi';

export const generateClericalData = async (dispatch, formData) => {
  dispatch(setLoading(true));
  const body = {
    request: 'GenerateClericalQuote',
    custId: 'UPS',
    quoteUserId: (await Auth.currentSession()).getIdToken().payload.email,
    ...formData,
  };
  await quoteApi
    .post('', body, {
      headers: {
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
    })
    .then(resp => {
      dispatch(setCurrentQuote(resp?.data?.body));
      dispatch(setLoading(false));
    });
};
