/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';

import { Container, Col, Row, Table, Button } from 'react-bootstrap';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import TableFilter from './CommonTableFilter';
import TablePagination from './CommonTablePagination';

function getSortIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const renderButton = ({ instance, id }) => {
  const isSelected =
    instance?.row?.original?.documentName &&
    !instance?.row?.original?.driverQualificationSelectedDocuments?.items?.find(item => item.documentAutoSelected);
  const isAuto =
    instance?.row?.original?.documentName &&
    instance?.row?.original?.driverQualificationSelectedDocuments?.items?.find(item => item.documentAutoSelected);
  const isSelect = !instance?.row?.original?.documentName;
  let textToShow = '';
  let backgroundColor = '';
  let borderColor = '';
  let color = '';
  if (isSelected) {
    textToShow = 'Selected';
    backgroundColor = '#fff';
    borderColor = '#6c757d';
    color = '#6c757d';
  } else if (isAuto) {
    textToShow = 'Auto Selected';
    backgroundColor = '#ccc';
    borderColor = '#ccc';
    color = '#fff';
  } else if (isSelect) {
    textToShow = 'Select';
    backgroundColor = '#da0f2e';
    borderColor = '#da0f2e';
    color = '#fff';
  }

  return (
    <div
      style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      {instance?.row?.original?.category === '0' ? (
        <p style={{ color, minWidth: '30%', margin: 0 }}>{textToShow}</p>
      ) : (
        <Link to={`/driver-qualification/select-document/${id}/${instance?.row?.original?.id}`}>
          <Button
            variant="primary"
            style={{
              backgroundColor,
              borderColor,
              color,
              minWidth: '30%',
            }}>
            {textToShow}
          </Button>
        </Link>
      )}
    </div>
  );
};

export default function IncludeChecklistDocumentsTable({ data, categories, sources, types, id }) {
  const columnClassName = 'align-middle text-nowrap';
  const columns = useMemo(
    () => [
      {
        Header: 'Item',
        accessor: 'item',
        className: columnClassName,
      },
      {
        Header: 'Source',
        accessor: 'source',
        className: columnClassName,
        Cell: instance => sources.find(sourcess => sourcess.id?.toString() === instance.value.toString())?.name,
      },
      {
        Header: 'Category',
        accessor: 'category',
        className: columnClassName,
        Cell: instance =>
          categories.find(categoriess => categoriess.id?.toString() === instance.value.toString())?.name,
      },
      {
        Header: 'Type',
        accessor: 'type',
        className: columnClassName,
        Cell: instance => types.find(typess => typess.id?.toString() === instance.value.toString())?.Name,
      },
      {
        Header: 'Select Documents',
        accessor: '',
        className: columnClassName,
        Cell: instance => renderButton({ instance, id }),
      },
    ],
    [categories, sources, types, data, id],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'createdAt',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setFilter,
    setGlobalFilter,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>{column.canSort ? getSortIcon(column.isSorted, column.isSortedDesc) : null}</span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}>
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  function filter(event, columnName) {
    const filterColumn = columns.find(column => column.Header === columnName);
    if (filterColumn) {
      setFilter(filterColumn.accessor, event.target.value);
    } else {
      setGlobalFilter(event.target.value);
    }
  }

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col md={6} className="mt-4">
          <TableFilter className="mb-3" columns={columns} onSearchChange={filter} />
        </Col>
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>

      {getTableBodyProps ? (
        <Row>
          <Col className="border border-light rounded shadow-sm">
            <Table hover responsive borderless {...getTableProps()}>
              <thead className="text-nowrap">{tableHeaders}</thead>
              <tbody {...getTableBodyProps()}>{tableRows}</tbody>
            </Table>
          </Col>
        </Row>
      ) : (
        <Row style={{ color: '#c8102e', fontWeight: 500 }}>
          <Col style={{ color: '#c8102e', fontWeight: 500 }}>There are no comments to show.</Col>
        </Row>
      )}
    </Container>
  );
}
