import { API, graphqlOperation } from 'aws-amplify';

import {
  createDriverQualificationSelectedDocuments,
  updateDriverQualificationChecklistDocuments,
  updateDriverQualificationSelectedDocuments,
} from '../../../graphql/mutations';
import {
  getDriverQualificationChecklistDocuments,
  getDriverQualificationSelectedDocuments,
  listChecklistByClientIdTier,
  listDocumentCategories,
  listDocumentSources,
  listDocumentTypes,
  listAtsDriverDocumentsByDriverIdBysourceByCategoryByType,
  listStandardDocumentsBySubjectByCategoryByType,
} from '../../../graphql/queries';

export const createDriverQualificationSelectedDocument = async payload => {
  try {
    const body = await API.graphql(
      graphqlOperation(createDriverQualificationSelectedDocuments, {
        input: payload,
      }),
    );
    return body;
  } catch (e) {
    console.log('GraphQL createDriverQualificationChecklistDocuments Error:', e);
  }
};

export const getCategory = async category => {
  try {
    const response = await API.graphql({
      query: listDocumentCategories,
    });
    return response?.data?.listDocumentCategories?.items?.find(item => item.id === category)?.name;
  } catch (e) {
    console.log(e);
  }
};

export const getCategories = async () => {
  try {
    const response = await API.graphql({
      query: listDocumentCategories,
    });
    return response?.data?.listDocumentCategories?.items;
  } catch (e) {
    console.log(e);
  }
};

export const getChecklistByClientIdTier = async clientId => {
  try {
    const response = await API.graphql({
      query: listChecklistByClientIdTier,
      variables: { clientId: clientId },
    });
    return response?.data?.listChecklistByClientIdTier?.items?.length;
  } catch (e) {
    console.log(`Error: ${JSON.stringify(e)}`);
    return e;
  }
};

export const getDriverChecklistDocuments = async id => {
  try {
    const response = await API.graphql({
      query: getDriverQualificationChecklistDocuments,
      variables: { id },
    });
    return response?.data?.getDriverQualificationChecklistDocuments;
  } catch (e) {
    console.log(e);
  }
};

export const getSelectedDriverQualificationDocuments = async id => {
  try {
    const response = await API.graphql({
      query: getDriverQualificationSelectedDocuments,
      variables: { id },
    });
    return response?.data?.getDriverQualification?.driverQualificationSelectedDocuments;
  } catch (e) {
    console.log(e);
  }
};

export const getAtsDriverDocuments = async (category, driverId, source, type) => {
  try {
    const response = await API.graphql({
      query: listAtsDriverDocumentsByDriverIdBysourceByCategoryByType,
      variables: {
        driverId,
        sourceCategoryType: {
          eq: {
            category: category,
            source: source,
            type: type,
          },
        },
      },
    });
    return response?.data?.listAtsDriverDocumentsByDriverIdBysourceByCategoryByType?.items;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getStandardDocuments = async (category, customerId, driverId, For, type) => {
  try {
    const response = await API.graphql({
      query: listStandardDocumentsBySubjectByCategoryByType,
      variables: {
        for: For,
        customerIdDriverIdCategoryType: {
          eq: {
            category: category,
            customerId: customerId,
            driverId: driverId,
            type: type,
          },
        },
      },
    });
    return response?.data?.listStandardDocumentsBySubjectByCategoryByType?.items;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getSource = async source => {
  try {
    const response = await API.graphql({
      query: listDocumentSources,
    });
    return response?.data?.listDocumentSources?.items?.find(item => item.id === source)?.name;
  } catch (e) {
    console.log(e);
  }
};

export const getSources = async () => {
  try {
    const response = await API.graphql({
      query: listDocumentSources,
    });
    return response?.data?.listDocumentSources?.items;
  } catch (e) {
    console.log(e);
  }
};

export const getType = async type => {
  try {
    const response = await API.graphql({
      query: listDocumentTypes,
    });
    return response?.data?.listDocumentTypes?.items?.find(item => item.id === type)?.Name;
  } catch (e) {
    console.log(e);
  }
};

export const getTypes = async () => {
  try {
    const response = await API.graphql({
      query: listDocumentTypes,
    });
    return response?.data?.listDocumentTypes?.items;
  } catch (e) {
    console.log(e);
  }
};

export const updateDriverQualificationSelectedDocument = async payload => {
  try {
    const body = await API.graphql(
      graphqlOperation(updateDriverQualificationSelectedDocuments, {
        input: payload,
      }),
    );
    return body;
  } catch (e) {
    console.log('GraphQL updateDriverQualificationSelectedDocuments Error:', e);
  }
};

export const updateDriverQualificationChecklistDocument = async payload => {
  try {
    const body = await API.graphql(
      graphqlOperation(updateDriverQualificationChecklistDocuments, {
        input: payload,
      }),
    );
    return body;
  } catch (e) {
    console.log('GraphQL updateDriverQualificationSelectedDocuments Error:', e);
  }
};

export const createDocuments = async items => {
  try {
    const response = await createDriverQualificationSelectedDocument(items);
    return response;
  } catch (e) {
    console.log('error', e);
  }
};

export const updateDocuments = async items => {
  try {
    const response = await updateDriverQualificationSelectedDocument(items);
    return response;
  } catch (e) {
    console.log('error', e);
  }
};
