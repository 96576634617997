import React from 'react';

import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import store from './app/store';
import awsExports from './aws-exports';
import reportWebVitals from './reportWebVitals';

awsExports.oauth.redirectSignIn = process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_IN;
awsExports.oauth.redirectSignOut = process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_OUT;

Amplify.configure(awsExports);

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Authenticator.Provider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Authenticator.Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
