import { formatIsoToDate } from '../../../utils';

export const getExportCSVData = (data = []) => {
  const csvData = [
    [
      'GL ACCT',
      'INVOICE NUMBER',
      'Description',
      'Comments',
      'Amount',
      'Check Date',
      'First Name',
      'Last Name',
      'CaseNumber',
      'Company Name',
      'AP Number',
      'AP Site',
      'AP State',
      'SSNO',
      'Transaction Code',
      'WC Code Description',
      'Branch',
      'LOB',
    ],
  ];
  let i;
  for (i = 0; i < data.length; i += 1) {
    csvData.push([
      `${data[i].GL_ACCT}`,
      `${data[i].INVOICE_NUMBER}`,
      `${data[i].Description}`,
      `${data[i].Comments}`,
      `${data[i].Amount}`,
      `${formatIsoToDate(data[i].Check_Date)}`,
      `${data[i].First_Name}`,
      `${data[i].Last_Name}`,
      `${data[i].CaseNumber}`,
      `${data[i].Company_Name}`,
      `${data[i].AP_Number}`,
      `${data[i].AP_Site}`,
      `${data[i].AP_State}`,
      `${data[i].SSNO}`,
      `${data[i].Transaction_Code}`,
      `${data[i].Transaction_Description}`,
      `${data[i].Branch}`,
      `${data[i].LOB}`,
    ]);
  }
  return csvData;
};

export const getCSVData = data => {
  const csvData = [
    [
      'Driver Number',
      'Driver Name',
      'Code',
      'Description',
      'Type',
      'Driver Amount',
      'Employer Amount',
      'Ded%',
      'Check#',
      'Check Date',
      'Period End Date',
      'Batch #',
      'Branch',
    ],
  ];
  let i;
  for (i = 0; i < data.length; i += 1) {
    csvData.push([
      `${data[i].Employee_ID}`,
      `${data[i].Empl_Name}`,
      `${data[i].Transaction_Code}`,
      `${data[i].Transaction_Descirption}`,
      `${data[i].Transaction_Type}`,
      `${data[i].Transaction_Amount}`,
      `${data[i].Ded_Amount_Employer}`,
      `${data[i].Deduction_Percent}`,
      `${data[i].check_ID}`,
      `${formatIsoToDate(data[i].check_Date)}`,
      `${formatIsoToDate(data[i].End_Date_PE)}`,
      `${data[i].Batch_Number}`,
      `${data[i].Branch}`,
    ]);
  }
  return csvData;
};
