import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { getUser, getAllApplications } from '../../graphql/customQueries';
import { createUser, updateUser } from '../../graphql/mutations';
import { addUser } from '../AdminPortal/adminSlice';
import { setAllApps } from '../Dashboard/dashboardSlice';

import MFACode from './MFACode';
import VerifyMFACode from './VerifyMFACode';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordSubmit from './ForgotPasswordSubmit';
import NewPassword from './NewPassword';
import SignIn from './SignIn';
import Authenticator from './AuthContainer';

import { Spinner } from 'react-bootstrap';
import './styles.scoped.css';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { route } = useAuthenticator(context => [context.route]);
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const [requiredAttributes, setRequiredAttributes] = useState([]);
  const [userData, setUserData] = useState({
    code: '',
    nextStep: 'SignIn',
    newPassword: '',
    password: '',
    verifyCode: '',
    username: '',
  });

  let from = location.state?.from?.pathname || '/dashboard';

  const getAuthUser = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const currentUserObj = currentUser.signInUserSession.idToken.payload;
    if (currentUserObj) {
      const { email } = currentUserObj;
      // update the lastLogin timestamp on every signin
      updateThisUser(email, null);
      const user = await getThisUser(email);
      if (user) {
        const { status, type, id } = user;
        if (status === 'Disabled') {
          return;
        }
        // any client with invited status who logs in becomes active
        if (type === 'Client' && (status === 'Invited' || status === 'Invite Resent' || status === 'Expired')) {
          user.status = 'Active';
          updateThisUser(id, 'Active');
        }
        if (currentUserObj['custom:role_admin']) {
          user.roles = currentUserObj['custom:role_admin']?.replace(/\[|\]/g, '').split(', ');
        } else {
          user.roles = [];
        }
        dispatch(addUser(user));
      } else {
        // insert the user into the User object with type as “Corporate”
        createCorpUser({ currentUserObj });
      }
    }
  };

  const updateThisUser = async (id, status) => {
    const update = status
      ? {
        id,
        status,
        lastLogin: new Date().toISOString(),
        passwordChangeDate: new Date().toISOString().slice(0, 10),
      }
      : {
        id,
        lastLogin: new Date().toISOString(),
      };
    try {
      await API.graphql(graphqlOperation(updateUser, { input: update }));
      return true;
    } catch (e) {
      console.log('Update Status Error: ' + JSON.stringify(e));
      return false;
    }
  };

  const createCorpUser = async ({ currentUserObj }) => {
    const { given_name, family_name, email } = currentUserObj;
    try {
      const response = await API.graphql(
        graphqlOperation(createUser, {
          input: {
            name: `${given_name} ${family_name}`,
            status: 'Active',
            type: 'Corporate',
            id: email,
            lastLogin: new Date().toISOString(),
          },
        }),
      );
      getAuthUser();
    } catch (e) {
      console.log('e: ', e);
    }
  };

  const getThisUser = async id => {
    try {
      const response = await API.graphql({
        query: getUser,
        variables: { id },
      });
      return response.data.getUser;
    } catch (e) {
      console.log(e);
    }
  };

  const getApps = async () => {
    try {
      const response = await API.graphql({
        query: getAllApplications,
      });
      dispatch(setAllApps(response?.data?.listApplications?.items));
    } catch (e) {
      console.log(e);
    }
  };

  const nextStep = (stepData) => {
    setUserData({ ...userData, ...stepData });
    return stepData;
  }


  useEffect(() => {
    if (route === 'authenticated') {
      getAuthUser();
      getApps();
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <>
      {authStatus === 'unauthenticated' && (
        <Authenticator
          currentIndex={0}
          nextStep={nextStep}
        >
          {userData?.nextStep === 'SignIn' &&
            <SignIn
              nextStep={nextStep}
              setRequiredAttributes={setRequiredAttributes}
              userData={userData}
            />
          }
          {userData?.nextStep === 'MFACode' && requiredAttributes &&
            <MFACode
              nextStep={nextStep}
              requiredAttributes={requiredAttributes}
              setRequiredAttributes={setRequiredAttributes}
              userData={userData}
            />
          }
          {userData?.nextStep === 'VerifyCode' &&
            <VerifyMFACode
              nextStep={nextStep}
              userData={userData}
            />
          }
          {userData?.nextStep === 'NewPassword' &&
            <NewPassword
              nextStep={nextStep}
              userData={userData}
              setRequiredAttributes={setRequiredAttributes}
            />
          }
          {userData?.nextStep === 'ForgotPassword' &&
            <ForgotPassword
              nextStep={nextStep}
              userData={userData}
            />
          }
          {userData?.nextStep === 'ForgotPasswordSubmit' &&
            <ForgotPasswordSubmit
              nextStep={nextStep}
              userData={userData}
              setRequiredAttributes={setRequiredAttributes}
            />
          }
        </Authenticator>
      )}

      {authStatus === 'configuring' && (
        <div className="wrap">
          <Spinner animation="border" />
        </div>
      )}
    </>
  );
}

export default Login;