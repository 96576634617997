import React, { useEffect, useState } from 'react';

import { Radio } from '@mui/material';
import dayjs from 'dayjs';
import { Form, Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MdWeb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AMCTable from './AMCTable';
import { getCSVAMC, getCSVMY } from './csv';
import { MasterCodeForm, MonthYearForm } from './Forms';
import Excel from '../../../assets/excel.png';
import { Breadcrumbs, EdgeReportingWindow, SubHeader, ReportLoader } from '../../../components';
import { newDateFormatted } from '../../../utils';
import { generateReport, getList, getReportData, removeReportData, userHasAccess } from '../operations';

import './styles.scoped.css';

const ClientBillingAMCMMYR = () => {
  const navigate = useNavigate();
  const {
    clientMenu: { menuItems },
  } = useSelector(state => state.edgeReporting);
  const { user } = useSelector(state => state.admin);
  const [data, setData] = useState([]);
  const [years, setYears] = useState([]);
  const [branches, setBranches] = useState([]);
  const [amcCodes, setAmcCodes] = useState([]);
  const [payCodes, setPayCodes] = useState([]);
  const [LOB, setLOB] = useState([]);

  const [loadingResults, setLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [viewEnabled, setViewEnabled] = useState(false);

  const [isMasterCode, setIsMasterCode] = useState(true);
  const [masterCodeForm, setMasterCodeForm] = useState({
    FromPerEnddate: '',
    ToPerEnddate: '',
    AMC: '',
    BillToName: '',
    BillToNumber: 0,
    PayCode: '',
  });
  const [monthYearForm, setMonthYearForm] = useState({
    Year: '',
    AMC: '',
    BranchNumber: '',
    BillToName: '',
    BillToNumber: 0,
    LineOfBusiness: '',
    PayCode: '',
  });

  const [isLoadingBranches, setIsLoadingBranches] = useState(false);
  const [isLoadingAmcCodes, setIsLoadingAmcCodes] = useState(false);
  const [isLoadingPayCodes, setIsLoadingPayCodes] = useState(false);
  const [isLoadingLOB, setIsLoadingLOB] = useState(false);

  const handleGetBranches = async () => {
    setIsLoadingBranches(true);
    const branches = await getList('clients/branches');
    setBranches(branches);
    setIsLoadingBranches(false);
  };

  const handleGetAmcCodes = async () => {
    setIsLoadingAmcCodes(true);
    const amcCodes = await getList('amcs');
    setAmcCodes(amcCodes);
    setIsLoadingAmcCodes(false);
  };

  const handleGetPayCodes = async () => {
    setIsLoadingPayCodes(true);
    const payCodes = await getList('pay-codes');
    setPayCodes(payCodes);
    setIsLoadingPayCodes(false);
  };

  const handleGetLOB = async () => {
    setIsLoadingLOB(true);
    const lob = await getList('line-of-business');
    setLOB(lob);
    setIsLoadingLOB(false);
  };

  const handleSetYears = () => {
    const currentYear = dayjs().year();
    const yearsArray = [];
    for (let year = 2000; year <= currentYear; year++) {
      yearsArray.push(year);
    }
    setYears(yearsArray);
  };

  useEffect(() => {
    if (menuItems) {
      if (!userHasAccess(user, menuItems.find(i => i.to === window.location.pathname)?.id)) {
        navigate('/');
      }
    }
  }, [menuItems]);

  useEffect(() => {
    handleSetYears();
    handleGetBranches();
    handleGetAmcCodes();
    handleGetPayCodes();
    handleGetLOB();
  }, []);

  useEffect(() => {
    if (isMasterCode) {
      setViewEnabled(
        !!masterCodeForm?.FromPerEnddate &&
        !!masterCodeForm.ToPerEnddate &&
        (dayjs(masterCodeForm.FromPerEnddate).isBefore(masterCodeForm.ToPerEnddate) ||
          dayjs(masterCodeForm.FromPerEnddate).isSame(masterCodeForm.ToPerEnddate)),
      );
    } else {
      setViewEnabled(!!monthYearForm?.Year);
    }
  }, [masterCodeForm, monthYearForm, isMasterCode]);

  const yearOptions = years.map(year => ({
    label: year.toString(),
    value: year.toString(),
  }));

  const branchOptions = branches.map(({ Branch_Code, Branch_Name }) => ({
    label: `${Branch_Name} - ${Branch_Code}`,
    value: Branch_Code,
  }));

  const amcOptions = amcCodes.map(({ Code, Description }) => ({
    label: Description,
    value: Code,
  }));

  const payCodeOptions = payCodes.map(({ Pay_Code, Pay_Code_Description }) => ({
    label: Pay_Code_Description,
    value: Pay_Code,
  }));
  const lobOptions = LOB.map(({ LineOfBusinessCode, LineOfBusinessDesc }) => ({
    label: LineOfBusinessDesc,
    value: LineOfBusinessCode,
  }));

  const fileName = `edge-reporting-client-billing-amc-${newDateFormatted.toString()}.csv`;

  const handleViewResults = async () => {
    setLoadingResults(true);

    try {
      const response = isMasterCode
        ? await generateReport({
          endpoint: 'client-billing-report-amc',
          payload: masterCodeForm,
        })
        : await generateReport({
          endpoint: 'client-billing-report-amc-month-year',
          payload: monthYearForm,
        });
      if (response.location && response.location !== '') {
        const reportData = await getReportData(response.location);
        if (reportData?.length) {
          await removeReportData(response.location);
          setLoadingResults(false);
          setData(reportData);
          setShowResults(true);
        }
      } else {
        setLoadingResults(false);
        alert('No data found matching your criteria.');
      }
    } catch (error) {
      console.log(error);
      setLoadingResults(false);
      alert('No data found matching your criteria.');
    }
  };

  const AMCForm = ({
    masterCodeForm,
    monthYearForm,
    setMasterCodeForm,
    setMonthYearForm,
    isLoadingAmcCodes,
    isLoadingBranches,
    isLoadingPayCodes,
    isLoadingLOB,
  }) => {
    const handleChange = e => {
      if (isMasterCode) {
        const _form = { ...masterCodeForm, [e.target.name]: e.target.value };
        setMasterCodeForm(_form);
      } else {
        const _form = { ...monthYearForm, [e.target.name]: e.target.value };
        setMonthYearForm(_form);
      }
    };
    return (
      <Form
        noValidate
        onSubmit={() => { }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <p style={{ marginTop: '20px' }}>Select to search by AMC Code or AMC by Month/Year</p>
        <div className="radio-container">
          <div className="radio-group">
            <Radio
              style={{
                color: '#da0f2e',
                transform: 'scale(1)',
              }}
              onChange={() => setIsMasterCode(true)}
              checked={isMasterCode}
            />
            <span>Acct Master Code</span>
          </div>
          <div className="radio-group">
            <Radio
              style={{
                color: '#da0f2e',
                transform: 'scale(1)',
              }}
              onChange={() => setIsMasterCode(false)}
              checked={!isMasterCode}
            />
            <span>AMC Month/Year</span>
          </div>
        </div>
        {isMasterCode ? (
          <MasterCodeForm
            form={masterCodeForm}
            setForm={setMasterCodeForm}
            handleChange={handleChange}
            amcOptions={amcOptions}
            payCodeOptions={payCodeOptions}
            isLoadingAmcCodes={isLoadingAmcCodes}
            isLoadingPayCodes={isLoadingPayCodes}
          />
        ) : (
          <MonthYearForm
            yearOptions={yearOptions}
            amcOptions={amcOptions}
            payCodeOptions={payCodeOptions}
            branchOptions={branchOptions}
            lobOptions={lobOptions}
            isLoadingLOB={isLoadingLOB}
            isLoadingAmcCodes={isLoadingAmcCodes}
            isLoadingPayCodes={isLoadingPayCodes}
            form={monthYearForm}
            setForm={setMonthYearForm}
            isLoadingBranches={isLoadingBranches}
            handleChange={handleChange}
          />
        )}
        <div className="buttons-row">
          <Button
            disabled={!viewEnabled}
            onClick={() => handleViewResults()}
            style={{ marginRight: '20px', width: '100px' }}>
            <span>VIEW</span>
          </Button>

          <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
            <span>CANCEL</span>
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <>
      <SubHeader
        text="EDGE Reporting"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo
        infoText="Need help with your Client Billing by AMC & MM/YY?"
        emailAddress={process.env.REACT_APP_EDGE_REPORTING_CONTACT_EMAIL}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}>
        <Breadcrumbs
          leftTitle="Reporting Home"
          middleTitle={showResults && 'Client Billing by AMC & MM/YY'}
          midItemAction={() => setShowResults(false)}
          rightTitle={
            showResults
              ? `Viewing Report ${isMasterCode ? '(AMC Only)' : '(AMC MM/YY)'}`
              : 'Client Billing by AMC & MM/YY'
          }
          route="/edge-reporting"
        />
        {!!data?.length && showResults && (
          <CSVLink filename={fileName} data={isMasterCode ? getCSVAMC(data) : getCSVMY(data)}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
        )}
      </div>
      {loadingResults && <ReportLoader didAbort={() => setLoadingResults(false)} />}
      {showResults ? (
        <>
          <AMCTable data={data} loading={loadingResults} isAMC={isMasterCode} />
          <div className="buttons-row">
            <Button onClick={() => setShowResults(false)} style={{ marginRight: '20px', width: '100px' }}>
              <span>BACK</span>
            </Button>

            <Button style={{ width: '100px' }} onClick={() => navigate('/edge-reporting')} className="button-secondary">
              <span>CANCEL</span>
            </Button>
          </div>
        </>
      ) : (
        <EdgeReportingWindow
          containerStyle={{
            border: '2px solid #343a40',
          }}
          windowContent={AMCForm({
            masterCodeForm,
            monthYearForm,
            setMasterCodeForm,
            setMonthYearForm,
            isLoadingAmcCodes,
            isLoadingBranches,
            isLoadingPayCodes,
            isLoadingLOB,
          })}
          windowTitle="Criteria"
          headerStyle={{ backgroundColor: '#343a40' }}
        />
      )}
    </>
  );
};

export default ClientBillingAMCMMYR;
