import { API, graphqlOperation } from 'aws-amplify';

import {
  createChecklist,
  deleteChecklist,
  createChecklistItems,
  deleteChecklistItems,
  updateChecklist,
  updateChecklistItems,
} from '../../../../graphql/mutations';
import {
  listDocumentSources,
  listDocumentCategories,
  listDocumentTypes,
  getChecklist,
  listChecklistByClientIdTier,
} from '../../../../graphql/queries';

export const getAMC = async code => {
  try {
    const path = `amc/${code}`;
    const response = await API.get('centerlineportal', path);
    return response;
  } catch (e) {
    return e;
  }
};

export const createChecklistItem = async payload => {
  try {
    const response = await API.graphql(graphqlOperation(createChecklistItems, { input: payload }));
    return response.data?.createChecklistItems;
  } catch (e) {
    console.log(`Error: ${JSON.stringify(e)}`);
    return e;
  }
};

export const removeChecklist = async id => {
  try {
    const response = await API.graphql(
      graphqlOperation(deleteChecklist, {
        input: {
          id: id,
        },
      }),
    );
    return response;
  } catch (e) {
    console.log('Error ', +JSON.stringify(e));
    return e;
  }
};

export const removeChecklistItems = async id => {
  try {
    const response = await API.graphql(
      graphqlOperation(deleteChecklistItems, {
        input: {
          id: id,
        },
      }),
    );
    return response;
  } catch (e) {
    console.log('Error ', +JSON.stringify(e));
    return e;
  }
};

export const editChecklistItem = async payload => {
  try {
    const response = await API.graphql(graphqlOperation(updateChecklistItems, { input: payload }));
    return response.data?.updateChecklistItems;
  } catch (e) {
    console.log(`Error: ${JSON.stringify(e)}`);
    return e;
  }
};

export const editChecklist = async payload => {
  try {
    const response = await API.graphql(graphqlOperation(updateChecklist, { input: payload }));
    return response.data?.updateChecklist;
  } catch (e) {
    console.log(`Error: ${JSON.stringify(e)}`);
    return e;
  }
};

export const newChecklist = async payload => {
  try {
    const response = await API.graphql(graphqlOperation(createChecklist, { input: payload }));
    return response.data?.createChecklist;
  } catch (e) {
    console.log(`Error: ${JSON.stringify(e)}`);
    return e;
  }
};

export const getList = async id => {
  try {
    const response = await API.graphql({
      query: getChecklist,
      variables: { id },
    });
    return response.data?.getChecklist;
  } catch (e) {
    console.log(`Error: ${JSON.stringify(e)}`);
    return e;
  }
};

export const getCategories = async id => {
  try {
    const response = await API.graphql({
      query: listDocumentCategories,
      variables: {
        filter: { documentSourceCategoryId: { eq: id } },
      },
    });

    return response.data?.listDocumentCategories?.items?.sort((a, b) => a.name.localeCompare(b.name));
  } catch (e) {
    console.log(e);
  }
};

export const getSources = async () => {
  try {
    const response = await API.graphql({
      query: listDocumentSources,
    });

    return response.data?.listDocumentSources?.items?.sort((a, b) => a.name.localeCompare(b.name));
  } catch (e) {
    console.log(e);
  }
};

export const getTypes = async id => {
  try {
    const response = await API.graphql({
      query: listDocumentTypes,
      variables: {
        filter: { documentCategoryTypeId: { eq: id } },
      },
    });
    return response.data?.listDocumentTypes?.items?.sort((a, b) => a.Name.localeCompare(b.Name));
  } catch (e) {
    console.log(e);
  }
};

export const getClient = async id => {
  try {
    const path = `clients/${id}`;
    const response = await API.get('centerlineportal', path);
    return response;
  } catch (e) {
    return e;
  }
};

export const getChecklistByClientIdTier = async (clientId, tier) => {
  try {
    const response = await API.graphql({
      query: listChecklistByClientIdTier,
      variables: {
        clientId: clientId,
        tier: {
          eq: tier,
        },
      },
    });
    return response.data?.listChecklistByClientIdTier?.items?.length;
  } catch (e) {
    console.log(`Error: ${JSON.stringify(e)}`);
    return e;
  }
};
