import { Container, Row, Col } from 'react-bootstrap';
import React, { useState } from 'react';
import QuoteField from '../quoteField';
import { getCurrencyString, getDaysBetween } from '../../../../utils/utilities';
import SaveDownloadForm from '../saveDownloadForm';
import DriverRatesField from '../driverRatesField';
import { saveQuote } from '../operations';
import { useDispatch } from 'react-redux';

const GeneratedDriversQuote = ({ className, quote, showSave }) => {
  const {
    delayBill,
    delayMarkUp,
    delayPay,
    doubleEndorsementSurcharge,
    dropHookBill,
    dropHookMarkUp,
    dropHookPay,
    estPayPerDriver,
    expires,
    hazmatSurcharge,
    hourlyRateBill,
    hourlyRateMarkUp,
    hourlyRatePay,
    milageRateBill,
    milageRateMarkUp,
    milageRatePay,
    nightShiftSurcharge,
    otherBill,
    otherMarkUp,
    otherPay,
    otRateBill,
    otRateMarkUp,
    otRatePay,
    otrSurcharge,
    perDiemMileBill,
    perDiemMileMarkUp,
    perDiemMilePay,
    quoteId,
    stopBill,
    stopMarkUp,
    stopPay,
    teamSurcharge,
    totalCost,
    totalSurcharges,
    touchSurcharge,
  } = quote;
  const daysValid = getDaysBetween(new Date(), new Date(expires));
  const [isSaved, setIsSaved] = useState(!showSave);
  const dispatch = useDispatch();

  const onSaveClicked = async quoteName => {
    const success = await saveQuote(dispatch, quoteName, quoteId);
    setIsSaved(success);
    return success;
  };

  return (
    <Container className={className} fluid>
      <Row>
        <Col>
          <h5>Quote*</h5>
        </Col>
        {isSaved && (
          <Col className="text-sm-right">
            <h5>{`#${quoteId}`}</h5>
          </Col>
        )}
      </Row>
      <Row>
        <Col className="d-flex flex-column">
          <h5>Pay Surcharges</h5>
          {hazmatSurcharge > 0 && (
            <QuoteField
              label="Hazmat:"
              value={`${getCurrencyString(hazmatSurcharge)} / Hr`}
            />
          )}
          {doubleEndorsementSurcharge > 0 && (
            <QuoteField
              label="Double Endorsement:"
              value={`${getCurrencyString(doubleEndorsementSurcharge)} / Hr`}
            />
          )}
          {otrSurcharge > 0 && (
            <QuoteField
              label="Away from home - OTR:"
              value={`${getCurrencyString(otrSurcharge)} / Hr`}
            />
          )}
          {nightShiftSurcharge > 0 && (
            <QuoteField
              label="Night Shift:"
              value={`${getCurrencyString(nightShiftSurcharge)} / Hr`}
            />
          )}
          {touchSurcharge > 0 && (
            <QuoteField
              label="Touch? (vs. No Touch):"
              value={`${getCurrencyString(touchSurcharge)} / Hr`}
            />
          )}
          {teamSurcharge > 0 && (
            <QuoteField
              label="Team Driver:"
              value={`${getCurrencyString(teamSurcharge)} / Hr`}
            />
          )}
          <QuoteField
            labelClass="h5"
            label="Total Pay Surcharges:"
            value={`${getCurrencyString(totalSurcharges)} / Hr`}
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex flex-column">
          <DriverRatesField
            label="Hourly Rate (CA & WA required):"
            value={{
              pay: hourlyRatePay,
              bill: hourlyRateBill,
              markUp: hourlyRateMarkUp,
            }}
          />
          <DriverRatesField
            label="OT Rate:"
            value={{ pay: otRatePay, bill: otRateBill, markUp: otRateMarkUp }}
          />
          <DriverRatesField
            label="Mileage Rate:"
            value={{
              pay: milageRatePay,
              bill: milageRateBill,
              markUp: milageRateMarkUp,
            }}
          />
          <DriverRatesField
            label="Per Diem / Mile:"
            value={{
              pay: perDiemMilePay,
              bill: perDiemMileBill,
              markUp: perDiemMileMarkUp,
            }}
          />
          <DriverRatesField
            label="Drop & Hook (1/3 Hr):"
            value={{
              pay: dropHookPay,
              bill: dropHookBill,
              markUp: dropHookMarkUp,
            }}
          />
          <DriverRatesField
            label="Delay:"
            value={{ pay: delayPay, bill: delayBill, markUp: delayMarkUp }}
          />
          <DriverRatesField
            label="Stop:"
            value={{ pay: stopPay, bill: stopBill, markUp: stopMarkUp }}
          />
          <DriverRatesField
            label="Other assessorial (Load & Unload):"
            value={{ pay: otherPay, bill: otherBill, markUp: otherMarkUp }}
          />
          <h5>Total Cost (Annual Estimate)</h5>
          <p className="text-muted">{getCurrencyString(totalCost)}</p>
          <h5>Estimated Annual Pay per Driver (Excluding Per Diem)</h5>
          <p className="text-muted">{getCurrencyString(estPayPerDriver)}</p>
        </Col>
      </Row>
      {showSave && (
        <Row>
          <Col>
            <SaveDownloadForm
              quote={quote}
              onSaveClicked={onSaveClicked}
              isSaved={isSaved}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <span className="small">
            {`* Quote is valid for ${daysValid} days. 
            If ${daysValid} days crosses a calendar year, 
            this is an Estimate only, not a firm Quote.`}
          </span>
          <br />
          <span className="small">
            * Based on 50 hours workweek or 2,350 miles per week minimum.
          </span>
          <br />
          <span className="small">
            * Annual based on hours/miles net of PTO.
          </span>
          <br />
          <span className="small">
            ** Quotes must be saved and submitted as PDF to be considered valid.
            Screen prints will not be accepted.
          </span>
        </Col>
      </Row>
    </Container>
  );
};

export default GeneratedDriversQuote;
