import { Row, Form, Spinner } from 'react-bootstrap';
import { customStyles } from '../utils';
import Select from 'react-select';
import './styles.scoped.css';

export const SalesTaxForm = ({
  form,
  setForm,
  handleChange,
  isLoadingTaxCodes,
  taxCodeOptions,
}) => {
  return (
    <div className="filters-container">
      <div
        className="filter-row"
        style={{
          marginTop: '30px',
        }}
      >
        <Form.Group
          as={Row}
          controlId="FromPerEnddate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label style={{ width: '290px' }}>
            <div className="required">Period End Date </div>
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
              marginRight: '20px',
            }}
            as="input"
            type="date"
            name="FromPerEnddate"
            value={form?.FromPerEnddate}
            onChange={handleChange}
            onBlur={null}
          />
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="ToPerEnddate"
          style={{ alignItems: 'center' }}
        >
          <Form.Label
            style={{
              width: '20px',
              marginLeft: '30px',
              marginRight: '20px',
            }}
          >
            To
          </Form.Label>
          <Form.Control
            style={{
              width: '150px',
              height: '30px',
            }}
            as="input"
            type="date"
            name="ToPerEnddate"
            value={form?.ToPerEnddate}
            onChange={handleChange}
            onBlur={null}
          />
        </Form.Group>
      </div>

      <div className="filter-row">
        <Form.Group as={Row} style={{ alignItems: 'center' }}>
          <Form.Label style={{ width: '290px' }}>Tax Code</Form.Label>
          <Select
            isDisabled={isLoadingTaxCodes}
            isMulti
            closeMenuOnSelect={false}
            styles={customStyles('365px')}
            options={taxCodeOptions}
            defaultValue={form?.TaxCode?.split(',').map(code =>
              taxCodeOptions.find(option => option.value === code),
            )}
            onChange={selectedOptions => {
              const selectedCodes = selectedOptions.map(option => option.value);
              setForm({
                ...form,
                TaxCode: selectedCodes.join(','),
              });
            }}
          />
          <Spinner
            as="span"
            animation="border"
            size="sm"
            aria-hidden="true"
            className="dropdown-spinner"
            hidden={!isLoadingTaxCodes}
          />
        </Form.Group>
      </div>
    </div>
  );
};
