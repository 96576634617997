import React, { useMemo } from 'react';
import Select from 'react-select';

import { Form, Row, Spinner } from 'react-bootstrap';

import './styles.scoped.css';
import { expenseCodes } from '../../../../utils/utilities';

const SearchExpensesForm = ({
  airlines,
  attachedOptions,
  branches,
  carRentals,
  clients,
  drivers,
  errors,
  form,
  hotels,
  isLoadingAirlines,
  isLoadingBranches,
  isLoadingCarRentals,
  isLoadingCities,
  isLoadingCustomers,
  isLoadingDrivers,
  isLoadingHotels,
  setField,
  selectedCities,
  showAdvancedSearch,
  states,
  statuses,
}) => {
  const costOptions = [process.env.REACT_APP_MDS_COST_CENTER, process.env.REACT_APP_DMS_COST_CENTER];

  const statusOptions = statuses?.map(({ label, value }) => ({
    label: label,
    value: value,
  }));

  const codeOptions = expenseCodes?.map(({ label, value }) => ({
    label: label,
    value: value,
  }));

  const costCenterOptions = costOptions?.map(cost => ({
    label: cost,
    value: cost,
  }));

  const receiptAttachedOptions = attachedOptions?.map(({ label, value }) => ({
    label: label,
    value: value
  }));

  const formHotelOptions = useMemo(
    () =>
      hotels
        ?.filter(h => h.status === 'Active')
        ?.sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(({ name, id: scopedId }) => ({
          label: name,
          value: scopedId,
        })),
    [hotels],
  );

  const formStateOptions = useMemo(
    () =>
      states?.sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(({ name, id }) => ({
          label: name,
          value: id,
        })));

  const formCityOptions = selectedCities?.cities
    ?.filter(h => h.status === 'Active')
    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(({ name, id: scopedId }) => ({
      label: name,
      value: scopedId,
    }));

  const driverOptions = drivers?.map(({ APPL_FIRSTNAME, APPL_LASTNAME, APPL_ID }) => ({
    label: `${APPL_FIRSTNAME} ${APPL_LASTNAME} - ${APPL_ID}`,
    value: APPL_ID,
  }));

  const clientOptions = clients?.map(({ CUST_ID, CUST_NAME }) => ({
    label: `${CUST_NAME} - ${CUST_ID}`,
    value: CUST_ID,
  }));

  const branchOptions = branches?.map(({ Branch_Code, Branch_Name }) => ({
    label: Branch_Name,
    value: Branch_Code,
  }));

  const airlineOptions =
    airlines?.sort((a, b) => (a.name > b.name ? 1 : -1))
      .map(({ id, name }) => ({
        label: name,
        value: id,
      }))

  const carRentalOptions =
    carRentals?.sort((a, b) => (a.name > b.name ? 1 : -1)).map(({ id, name }) => ({
      label: name,
      value: id
    }));

  const customStyles = width => ({
    control: provided => ({
      ...provided,
      width: width,
      height: '30px',
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: '200px',
    }),
  });

  return (
    <Form noValidate onSubmit={() => { }}>
      <div className="filters-row">
        <Row className="top-row">
          <div
            className="top-col"
            style={{
              width: '65%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row} controlId="driverNameNumber">
              <Form.Label style={{ marginTop: '15px', width: '290px' }}>
                <div> Driver Name & Driver Number</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  styles={customStyles('483px')}
                  options={driverOptions}
                  isClearable
                  onChange={(event, action) => {
                    if (action.action === 'clear') {
                      setField([
                        {
                          fieldName: 'driverId',
                          fieldValue: null,
                        },
                        {
                          fieldName: 'driverName',
                          fieldValue: null,
                        },
                        {
                          fieldName: 'selectedDriver',
                          fieldValue: '',
                        },
                      ]);
                    };
                    if (action.action === 'select-option') {
                      setField([
                        {
                          fieldName: 'driverId',
                          fieldValue: event.value,
                        },
                        {
                          fieldName: 'driverName',
                          fieldValue: event.label.split(' -').shift(),
                        },
                        {
                          fieldName: 'selectedDriver',
                          fieldValue: event.label,
                        },
                      ]);
                    }
                  }
                  }
                  value={driverOptions?.filter(state => state.value === form?.driverId)}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingDrivers}
                />
              </div>
            </Form.Group>
          </div>
          <div
            className="top-col"
            style={{
              width: '35%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row} controlId="formDriver">
              <Form.Label style={{ width: '160px', marginTop: '15px' }}>Code</Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  styles={customStyles('225px')}
                  options={codeOptions}
                  isClearable
                  onChange={(event, action) => {
                    if (action.action === 'clear') {
                      setField('code', '');
                    };
                    if (action.action === 'select-option') {
                      setField('code', event.value);
                    };
                  }}
                  value={codeOptions?.filter(state => state.value === form?.code)}
                />
              </div>
            </Form.Group>
          </div>
        </Row>
        <Row className="top-row">
          <div
            className="top-col"
            style={{
              width: '65%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row} controlId="customer">
              <Form.Label style={{ marginTop: '15px', width: '290px' }}>
                <div>Customer Name & Customer Number</div>
              </Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  styles={customStyles('483px')}
                  isClearable
                  options={clientOptions}
                  onChange={(event, action) => {
                    if (action.action === 'clear') {
                      setField([
                        {
                          fieldName: 'customerId',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'customerName',
                          fieldValue: '',
                        },
                        {
                          fieldName: 'selectedCustomer',
                          fieldValue: '',
                        },
                      ])
                    };
                    if (action.action === 'select-option') {
                      setField([
                        {
                          fieldName: 'customerId',
                          fieldValue: event.value,
                        },
                        {
                          fieldName: 'customerName',
                          fieldValue: event.label.split(' -').shift(),
                        },
                        {
                          fieldName: 'selectedCustomer',
                          fieldValue: event.label,
                        },
                      ])
                    }
                  }}
                  value={clientOptions?.filter(state => state.value === form?.customerId)}
                />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                  className="dropdown-spinner"
                  hidden={!isLoadingCustomers}
                />
              </div>
            </Form.Group>
          </div>
          <div
            className="top-col"
            style={{
              width: '35%',
              marginTop: '30px',
              paddingLeft: '15px',
            }}>
            <Form.Group as={Row} controlId="formDriver">
              <Form.Label style={{ width: '160px' }}>Status</Form.Label>
              <div className="dropdown-spinner-container">
                <Select
                  styles={customStyles('225px')}
                  options={statusOptions}
                  isClearable
                  onChange={(event, action) => {
                    if (action.action === 'clear') {
                      setField('status', '');
                    };
                    if (action.action === 'select-option') {
                      setField('status', event.value);
                    };
                  }}
                  value={statusOptions?.filter(state => state.value === form?.status)}
                />
              </div>
            </Form.Group>
          </div>
        </Row>
        {showAdvancedSearch &&
          <>
            <Row className="top-row">
              <div
                className="top-col"
                style={{
                  width: '65%',
                  marginTop: '30px',
                  paddingLeft: '15px',
                }}>
                <Form.Group as={Row} controlId="enteredStartDate">
                  <Form.Label style={{ width: '290px' }}>Entered Date Range</Form.Label>
                  <div style={{ flexDirection: 'column' }}>
                    <Form.Control
                      style={{
                        width: '210px',
                        height: '30px',
                        marginRight: '20px',
                      }}
                      as="input"
                      type="date"
                      name="enteredStartDate"
                      value={form?.enteredStartDate ? form?.enteredStartDate?.substring(0, 10) : ''}
                      onChange={event => {
                        setField('enteredStartDate', event.target.value);
                      }}
                      isInvalid={!!errors?.enteredStartDate}
                    />
                    <Form.Control.Feedback type="invalid">{errors?.enteredStartDate}</Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group as={Row} controlId="enteredEndDate">
                  <Form.Label
                    style={{
                      width: '20px',
                      marginLeft: '30px',
                      marginRight: '20px',
                    }}>
                    To
                  </Form.Label>
                  <div style={{ flexDirection: 'column' }}>
                    <Form.Control
                      style={{
                        width: '210px',
                        height: '30px',
                      }}
                      as="input"
                      type="date"
                      name="enteredEndDate"
                      value={form?.enteredEndDate ? form?.enteredEndDate?.substring(0, 10) : ''}
                      onChange={event => {
                        setField('enteredEndDate', event.target.value);
                      }}
                      isInvalid={!!errors?.enteredEndDate}
                    />
                    <Form.Control.Feedback type="invalid">{errors?.enteredEndDate}</Form.Control.Feedback>
                  </div>
                </Form.Group>
              </div>
              <div
                className="top-col"
                style={{
                  width: '35%',
                  marginTop: '30px',
                  paddingLeft: '15px',
                }}>
                <Form.Group as={Row} controlId="isReceiptAttached">
                  <Form.Label style={{ width: '160px' }}>Receipt Attached?</Form.Label>
                  <div className="dropdown-spinner-container">
                    <Select
                      styles={customStyles('225px')}
                      options={receiptAttachedOptions}
                      isClearable
                      onChange={(event, action) => {
                        if (action.action === 'clear') {
                          setField('isReceiptAttached', '');
                        };
                        if (action.action === 'select-option') {
                          setField('isReceiptAttached', event.value);
                        };
                      }}
                      value={receiptAttachedOptions?.filter(state => state.value === form?.isReceiptAttached)}
                    />
                  </div>
                </Form.Group>
              </div>
            </Row>
            <Row className="top-row">
              <div
                className="top-col"
                style={{
                  width: '65%',
                  marginTop: '30px',
                  paddingLeft: '15px',
                }}>
                <Form.Group as={Row} controlId="payWeekEndingStart">
                  <Form.Label style={{ width: '290px' }}>Payroll Week Ending Dates</Form.Label>
                  <div style={{ flexDirection: 'column' }}>
                    <Form.Control
                      style={{
                        width: '210px',
                        height: '30px',
                        marginRight: '20px',
                      }}
                      as="input"
                      type="date"
                      name="payWeekEndingStart"
                      value={form?.payWeekEndingStart ? form?.payWeekEndingStart : ''}
                      onChange={event => {
                        setField('payWeekEndingStart', event.target.value);
                      }}
                      isInvalid={!!errors?.payWeekEndingStart}
                    />
                    <Form.Control.Feedback type="invalid">{errors?.payWeekEndingStart}</Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group as={Row} controlId="payWeekEndingEnd">
                  <Form.Label
                    style={{
                      width: '20px',
                      marginLeft: '30px',
                      marginRight: '20px',
                    }}>
                    To
                  </Form.Label>
                  <div style={{ flexDirection: 'column' }}>
                    <Form.Control
                      style={{
                        width: '210px',
                        height: '30px',
                      }}
                      as="input"
                      type="date"
                      name="payWeekEndingEnd"
                      value={form?.payWeekEndingEnd ? form?.payWeekEndingEnd : ''}
                      onChange={event => {
                        setField('payWeekEndingEnd', event.target.value);
                      }}
                      isInvalid={!!errors?.payWeekEndingEnd}
                    />
                    <Form.Control.Feedback type="invalid">{errors?.payWeekEndingEnd}</Form.Control.Feedback>
                  </div>
                </Form.Group>
              </div>
              <div
                className="top-col"
                style={{
                  width: '35%',
                  marginTop: '30px',
                  paddingLeft: '15px',
                }}>
                <Form.Group as={Row} controlId="formDriver">
                  <Form.Label style={{ width: '160px', marginTop: '15px' }}>Client Branch</Form.Label>
                  <div className="dropdown-spinner-container">
                    <Select
                      styles={customStyles('225px')}
                      options={branchOptions}
                      isClearable
                      onChange={(event, action) => {
                        if (action.action === 'clear') {
                          setField([
                            {
                              fieldName: 'branch',
                              fieldValue: '',
                            },
                            {
                              fieldName: 'branchSelection',
                              fieldValue: '',
                            },
                          ]);
                        };
                        if (action.action === 'select-option') {
                          setField([
                            {
                              fieldName: 'branch',
                              fieldValue: event.value,
                            },
                            {
                              fieldName: 'branchSelection',
                              fieldValue: event.label,
                            },
                          ]);
                        }
                      }}
                      value={branchOptions?.filter(state => state.value === form?.branch)}
                    />
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      aria-hidden="true"
                      className="dropdown-spinner"
                      hidden={!isLoadingBranches}
                    />
                  </div>
                </Form.Group>
              </div>
            </Row>
            <Row className="top-row">
              <div
                className="top-col"
                style={{
                  width: '65%',
                  marginTop: '30px',
                  paddingLeft: '15px',
                }}>
                <Form.Group as={Row} controlId="receiptStartDate">
                  <Form.Label style={{ width: '290px' }}>Receipt Date Range</Form.Label>
                  <div style={{ flexDirection: 'column' }}>
                    <Form.Control
                      style={{
                        width: '210px',
                        height: '30px',
                        marginRight: '20px',
                      }}
                      as="input"
                      type="date"
                      name="receiptStartDate"
                      value={form?.receiptStartDate ? form?.receiptStartDate?.substring(0, 10) : ''}
                      onChange={(event) => {
                        setField('receiptStartDate', event.target.value);
                      }}
                      onBlur={null}
                      isInvalid={!!errors?.receiptStartDate}
                    />
                    <Form.Control.Feedback type="invalid">{errors?.receiptStartDate}</Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group as={Row} controlId="receiptEndDate">
                  <Form.Label
                    style={{
                      width: '20px',
                      marginLeft: '30px',
                      marginRight: '20px',
                    }}>
                    To
                  </Form.Label>
                  <div style={{ flexDirection: 'column' }}>
                    <Form.Control
                      style={{
                        width: '210px',
                        height: '30px',
                      }}
                      as="input"
                      type="date"
                      name="receiptEndDate"
                      value={form?.receiptEndDate ? form?.receiptEndDate?.substring(0, 10) : ''}
                      onChange={event => {
                        setField('receiptEndDate', event.target.value);
                      }}
                      onBlur={null}
                      isInvalid={!!errors?.receiptEndDate}
                    />
                    <Form.Control.Feedback type="invalid">{errors?.receiptEndDate}</Form.Control.Feedback>
                  </div>
                </Form.Group>
              </div>
              <div
                className="top-col"
                style={{
                  width: '35%',
                  marginTop: '30px',
                  paddingLeft: '15px',
                }}>
                <Form.Group as={Row} controlId="formDriver">
                  <Form.Label style={{ width: '160px', marginTop: '15px' }}>Cost Center</Form.Label>
                  <div className="dropdown-spinner-container">
                    <Select
                      styles={customStyles('225px')}
                      options={costCenterOptions}
                      isClearable
                      onChange={(event, action) => {
                        if (action.action === 'clear') {
                          setField('costCenter', '');
                        };
                        if (action.action === 'select-option') {
                          setField('costCenter', event.value);
                        }
                      }}
                      value={costCenterOptions?.filter(state => state.value === form?.costCenter)}
                    />
                  </div>
                </Form.Group>
              </div>
            </Row>
            <Row className="top-row">
              <div
                className="top-col"
                style={{
                  width: '65%',
                  marginTop: '30px',
                  paddingLeft: '15px',
                }}>
                <Form.Group as={Row} controlId="expenseItemHotelId">
                  <Form.Label style={{ marginTop: '15px', width: '290px' }}>
                    <div>Hotel Name</div>
                  </Form.Label>
                  <div className="dropdown-spinner-container">
                    <Select
                      styles={customStyles('483px')}
                      options={formHotelOptions}
                      isClearable
                      onChange={(event, action) => {
                        if (action.action === 'clear') {
                          setField([
                            {
                              fieldName: 'expenseItemHotelId',
                              fieldValue: '',
                            },
                            {
                              fieldName: 'selectedHotel',
                              fieldValue: ''
                            }
                          ]);
                        };
                        if (action.action === 'select-option') {
                          setField([
                            {
                              fieldName: 'expenseItemHotelId',
                              fieldValue: event.value,
                            },
                            {
                              fieldName: 'selectedHotel',
                              fieldValue: event.label
                            }
                          ]);
                        };
                      }}
                      value={formHotelOptions?.filter(state => state.value === form?.expenseItemHotelId)}
                    />
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      aria-hidden="true"
                      className="dropdown-spinner"
                      hidden={!isLoadingHotels}
                    />
                  </div>
                </Form.Group>
              </div>
              <div
                className="top-col"
                style={{
                  width: '35%',
                  marginTop: '30px',
                  paddingLeft: '15px',
                }}>
                <Form.Group as={Row} controlId="expenseItemHotelStateId">
                  <Form.Label style={{ width: '160px', marginTop: '15px' }}>State</Form.Label>
                  <div className="dropdown-spinner-container">
                    <Select
                      isClearable
                      name="state"
                      styles={customStyles('225px')}
                      options={formStateOptions}
                      onChange={(event, action) => {
                        if (action.action === 'clear') {
                          setField([
                            {
                              fieldName: 'expenseItemHotelStateId',
                              fieldValue: null,
                            },
                            {
                              fieldName: 'expenseItemHotelCityId',
                              fieldValue: null,
                            }
                          ])
                        };
                        if (action.action === 'select-option') {
                          if (action.action === 'select-option') {
                            setField([
                              {
                                fieldName: 'expenseItemHotelStateId',
                                fieldValue: event.value,
                              }
                            ]);
                          };
                        };
                      }}
                      value={formStateOptions?.filter(state => state.value === form?.expenseItemHotelStateId)}
                      isInvalid={!!errors?.expenseItemHotelStateId}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">{errors?.expenseItemHotelStateId}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>
            <Row className="top-row">
              <div
                className="top-col"
                style={{
                  width: '65%',
                  marginTop: '30px',
                  paddingLeft: '15px',
                }}>
                <Form.Group as={Row} controlId="expenseItemAirLineId">
                  <Form.Label style={{ marginTop: '15px', width: '290px' }}>
                    <div>Airline Name</div>
                  </Form.Label>
                  <div className="dropdown-spinner-container">
                    <Select
                      styles={customStyles('483px')}
                      options={airlineOptions}
                      isClearable
                      onChange={(event, action) => {
                        if (action.action === 'clear') {
                          setField([
                            {
                              fieldName: 'expenseItemAirLineId',
                              fieldValue: '',
                            },
                          ]);
                        };
                        if (action.action === 'select-option') {
                          action.option = event.value;
                          setField([
                            {
                              fieldName: 'expenseItemAirLineId',
                              fieldValue: event.value,
                            }
                          ]);
                        };
                      }}
                      value={airlineOptions?.filter(state => state.value === form?.expenseItemAirLineId)}
                    />
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      aria-hidden="true"
                      className="dropdown-spinner"
                      hidden={!isLoadingAirlines}
                    />
                  </div>
                  {errors?.expenseItemAirLineId && <div className="error">{errors?.expenseItemAirLineId}</div>}
                </Form.Group>
              </div>
              <div>
                <Form.Label style={{ marginTop: '15px', width: '290px' }}>
                  <div><em><i>Select a State before the City.</i></em></div>
                </Form.Label>
              </div>
            </Row>
            <Row className="top-row">
              <div
                className="top-col"
                style={{
                  width: '65%',
                  marginTop: '30px',
                  paddingLeft: '15px',
                }}>
                <Form.Group as={Row} controlId="expenseItemCarRentalCompanyId">
                  <Form.Label style={{ marginTop: '15px', width: '290px' }}>
                    <div>Car Rental Company</div>
                  </Form.Label>
                  <div className="dropdown-spinner-container">
                    <Select
                      styles={customStyles('483px')}
                      options={carRentalOptions}
                      isClearable
                      onChange={(event, action) => {
                        if (action.action === 'clear') {
                          setField([
                            {
                              fieldName: 'expenseItemCarRentalCompanyId',
                              fieldValue: '',
                            },
                          ]);
                        };
                        if (action.action === 'select-option') {
                          setField([
                            {
                              fieldName: 'expenseItemCarRentalCompanyId',
                              fieldValue: event.value,
                            },
                          ]);
                        };
                      }}
                      value={carRentalOptions?.filter(state => state.value === form?.expenseItemCarRentalCompanyId)}
                    />
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      aria-hidden="true"
                      className="dropdown-spinner"
                      hidden={!isLoadingCarRentals}
                    />
                  </div>
                </Form.Group>
              </div>
              <div
                className="top-col"
                style={{
                  width: '35%',
                  marginTop: '30px',
                  paddingLeft: '15px',
                }}>
                <Form.Group as={Row} controlId="expenseItemHotelCityId">
                  <Form.Label style={{ width: '160px', marginTop: '15px' }}>City</Form.Label>
                  <div className="dropdown-spinner-container">
                    <Select
                      name="city"
                      isDisabled={!form.expenseItemHotelStateId || isLoadingCities}
                      styles={customStyles('225px')}
                      options={formCityOptions}
                      placeholder="Select a City"
                      onChange={(event, action) => {
                        if (action.action === 'select-option') {
                          action.option = event.value;
                          setField([
                            {
                              fieldName: 'expenseItemHotelCityId',
                              fieldValue: event.value,
                            },
                          ])
                        };
                      }}
                      value={formCityOptions?.filter(city => city.value === form?.expenseItemHotelCityId)}
                      isInvalid={!!errors?.expenseItemHotelCityId}
                    />
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      aria-hidden="true"
                      className="dropdown-spinner"
                      hidden={!isLoadingCities}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">{errors?.expenseItemHotelCityId}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>
            <Row className="top-row">
              <div
                className="top-col"
                style={{
                  width: '65%',
                  marginTop: '30px',
                  paddingLeft: '15px',
                }}>
                <Form.Group as={Row} controlId="notes">
                  <Form.Label style={{ marginTop: '15px', width: '290px' }}>
                    <div>Notes' text search</div>
                  </Form.Label>
                  <div className="dropdown-spinner-container">
                    <Form.Control
                      key="notes"
                      style={{
                        width: '483px',
                        height: '30px',
                        padding: 0,
                      }}
                      type="input"
                      name="notes"
                      defaultValue={form?.notes}
                      onBlur={event => {
                        setField('notes', event.target.value);
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: '290px' }}>
                    <em><i>Searching notes by free text can take longer than <br />expected to return results.</i></em>
                  </div>
                </Form.Group>
              </div>
            </Row>
          </>
        }
      </div>
    </Form>
  );
};

export default SearchExpensesForm;
