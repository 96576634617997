import React from 'react';

import './styles.scoped.css';

const ExpensesWindow = ({ windowContent, windowTitle, headerStyle, containerStyle }) => (
  <div>
    <div className="window-header" style={headerStyle && headerStyle}>
      {windowTitle}
    </div>
    <div className="window-container" style={containerStyle && containerStyle}>
      {windowContent}
    </div>
  </div>
);

export default ExpensesWindow;
