const styles = {
    selectStyle: {
        control: (base, state) => ({
            ...base,
            borderColor: 'black',
        }),
    },
    marginTop: {
        marginTop: 20
    },
    dropdownStyle: {
        backgroundColor: 'white',
        width: '100%',
        borderColor: 'black',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: '200',
        color: 'black',
        marginRight: 20
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
    },
    buttonPrimary: {
        backgroundColor: '#da0f2e',
        marginTop: 40,
        marginBottom: 20
    },
    buttonSecondary: {
        backgroundColor: 'white',
        color: '#da0f2e',
        marginTop: 20,
        marginLeft: 10,
    },
};

export default styles;
