import React, { useEffect, useState } from 'react';

import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { MdHome } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getBatch } from './common';
import { Breadcrumbs, DriverQualificationListTable, SubHeader } from '../../../components';
import Routes from '../../../utils/routes';

const Request = ({ status }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { type, id } = useSelector(state => state.admin.user);

  useEffect(() => {
    if (type && id) {
      getBatch({ type, setData, setLoading, status, userId: id, isFirst: true });
    }
  }, [type, id]);

  return (
    <>
      <SubHeader
        text="Centerline Driver Qualification"
        btnSmall={<MdHome size={28} color="#FFF" />}
        btnInfo
        smallAction={() => navigate('../driver-qualification', { replace: true })}
        emailAddress={process.env.REACT_APP_DQF_CONTACT_EMAIL}
        infoText="Need help with your driver qualification?"
      />
      <Breadcrumbs
        leftTitle="DQF Home"
        rightTitle={`${status} Files`}
        route={'/driver-qualification'}
      />
      <Container className="vh-100 mt-5" fluid>
        {loading ? (
          <div className="h-100 d-flex justify-content-center p-5">
            <Spinner animation="border" />
          </div>
        ) : (
          <DriverQualificationListTable
            data={data}
            Route={type === 'Corporate' ? Routes.CorporateDQ : Routes.ClientDQ}
          />
        )}
      </Container>
    </>
  );
};

export default Request;
