/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ExpensesMenuItem, SubHeader, Footer } from '../../components';
import { MdWeb } from 'react-icons/md';
import { Container, Row } from 'react-bootstrap';
import './styles.scoped.css';

const ExpenseEntry = () => {
  const navigate = useNavigate();
  const appAccess = useSelector(state => state.admin?.user?.appAccess);
  const { clientMenu, subHeader } = useSelector(state => state.expenseEntry);
  const [currentExpenseRole, setCurrentExpenseRole] = useState('');

  const onPress = item => {
    navigate(item.to);
  };

  useEffect(() => {
    const access = appAccess?.items?.filter(app => app.appId?.name === 'Expense Entry')?.[0];
    if (access && access.roleId) {
      setCurrentExpenseRole(access?.roleId?.id);
    }
  }, [appAccess, clientMenu]);

  return (
    <>
      <SubHeader
        text="Expenses"
        btnSmall={<MdWeb color="#fff" size={28} />}
        smallAction={() => navigate('/dashboard')}
        btnInfo={true}
        infoText="Need help with your Expenses?"
        emailAddress={process.env.REACT_APP_EXPENSE_CONTACT_EMAIL}
      />
      <Container className="wrap">
        <h1 className="header">{subHeader}</h1>
        <div key={clientMenu.menuHeader}>

          <Row className="row" key={`${clientMenu.menuHeader}-row`}>
            {currentExpenseRole === '8' || currentExpenseRole === '9' || currentExpenseRole === '32' ?
              clientMenu.menuItems.map(i => (
                i.to.includes('/expense-entry/mobile-driver-solutions') &&
                <ExpensesMenuItem
                  key={i.text}
                  text={i.text}
                  onPress={() => onPress(i)}
                  disabled={i.disabled}
                />))
              :
              null
            }

            {currentExpenseRole === '30' || currentExpenseRole === '31' || currentExpenseRole === '32' ?
              clientMenu.menuItems.map(i => (
                i.to.includes('/expense-entry/driver-management-services') &&
                <ExpensesMenuItem
                  styles={{ backgroundColor: '#53565a', color: '#ffff' }}
                  key={i.text}
                  text={i.text}
                  onPress={() => onPress(i)}
                  disabled={i.disabled}
                />
              ))
              :
              null
            }

            {currentExpenseRole === '' ?
              clientMenu.menuItems.map(i => (
                i.to.includes('/expense-entry/expense-maintenance') &&
                <ExpensesMenuItem
                  styles={{ color: '#da0f2edd' }}
                  key={i.text}
                  text={i.text}
                  onPress={() => onPress(i)}
                  disabled={i.disabled}
                />))
              :
              null
            }

          </Row>

        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ExpenseEntry;
