import { createSlice } from '@reduxjs/toolkit';

const checkListSlice = createSlice({
  name: 'checkList',
  initialState: {
    clientData: {},
    documentCategories: [],
    documentSources: [],
    documentTypes: [],
    rows: [],
    orderSelections: [],
    tierData: [
      {
        label: 'Complete file (Based on client requirements)',
        value: 1,
      },
      {
        label: 'Other',
        value: 2,
      },
    ],
    selectedTier: 1,
  },
  reducers: {
    addRow: (state, { payload }) => {
      state.rows = [...state.rows, payload];
    },
    removeRow: (state, { payload }) => {
      const elementIdToDelete = state.rows.findIndex(item => item.id === payload);

      const firstPart = state.rows.slice(0, elementIdToDelete);
      const lastPart = state.rows.slice(elementIdToDelete + 1).map(item => ({ ...item, order: item.order - 1 }));

      state.rows = [...firstPart, ...lastPart];
    },
    removeRowByProp: (state, { payload }) => {
      const elementIdToDelete = state.rows.findIndex(item => item[payload.prop] === payload.value);

      const firstPart = state.rows.slice(0, elementIdToDelete);
      const lastPart = state.rows.slice(elementIdToDelete + 1).map(item => ({ ...item, order: item.order - 1 }));

      state.rows = [...firstPart, ...lastPart];
    },
    removeClientData: state => {
      state.clientData = {};
    },
    clearRows: (state, { payload }) => {
      state.rows = [];
    },
    setClientData: (state, { payload }) => {
      state.clientData = payload;
    },
    setDocumentCategories: (state, { payload }) => {
      state.documentCategories = payload;
    },
    setDocumentSources: (state, { payload }) => {
      state.documentSources = payload;
    },
    setDocumentTypes: (state, { payload }) => {
      state.documentTypes = payload;
    },
    setTier: (state, { payload }) => {
      state.selectedTier = parseInt(payload);
    },
    updateCategory: (state, { payload }) => {
      state.rows = state.rows.map(thing =>
        thing.checklistItemsId === payload.checklistItemsId ? { ...thing, category: payload.category } : thing,
      );
    },
    updateItem: (state, { payload }) => {
      state.rows = state.rows.map(thing =>
        thing.checklistItemsId === payload.checklistItemsId ? { ...thing, item: payload.item } : thing,
      );
    },
    updateOrder: (state, { payload }) => {
      const rowsCopy = JSON.parse(JSON.stringify(state.rows));

      const previousItem = rowsCopy.find(item => item.checklistItemsId === payload.checklistItemsId);

      rowsCopy.splice(rowsCopy.indexOf(previousItem), 1);
      rowsCopy.splice(parseInt(payload.order) - 1, 0, previousItem);

      state.rows = rowsCopy.map((item, index) => ({ ...item, order: index + 1 }));
    },
    updatePages: (state, { payload }) => {
      state.rows = state.rows.map(thing =>
        thing.checklistItemsId === payload.checklistItemsId ? { ...thing, pages: payload.pages } : thing,
      );
    },
    updateSource: (state, { payload }) => {
      state.rows = state.rows.map(thing =>
        thing.checklistItemsId === payload.checklistItemsId ? { ...thing, source: payload.source } : thing,
      );
    },
    updateType: (state, { payload }) => {
      state.rows = state.rows.map(thing =>
        thing.checklistItemsId === payload.checklistItemsId ? { ...thing, type: payload.type } : thing,
      );
    },
  },
});

export default checkListSlice.reducer;

export const {
  addRow,
  clearRows,
  removeRow,
  removeRowByProp,
  removeClientData,
  setTier,
  setClientData,
  setDocumentCategories,
  setDocumentSources,
  setDocumentTypes,
  updateCategory,
  updateItem,
  updateOrder,
  updatePages,
  updateSource,
  updateType,
} = checkListSlice.actions;
