import React from 'react';

import { Routes as Switch, Route } from 'react-router-dom';

import Private from './Private';
import {
  Dashboard,
  StandardDocument,
  StandardDocuments,
  NotFound,
  AdminPortal,
  DriverQualification,
  NewRequest,
  Login,
  ClientAccess,
  CorporateAccess,
  EditAccess,
  AddClientUser,
  ChecklistTemplates,
  ChecklistDetails,
  UpdateClientUser,
  Request,
  PushNotifications,
} from '../features';
import AssembleNewDQF from '../features/DriverQualification/AssembleNewDQF';
import SelectDocumentDetails from '../features/DriverQualification/SelectDocumentDetails';
import CheckPdf from '../features/EdgeReporting/CheckPdf';
import ClientDQ from '../features/DriverQualification/ClientDQ';
import CorporateDQ from '../features/DriverQualification/CorporateDQ';
import ViewDQF from '../features/DriverQualification/ViewDQF';
import MFAValidation from '../features/DriverQualification/ViewDQF/MFAValidation';
import EdgeReporting from '../features/EdgeReporting';
import FourCeroOneK from '../features/EdgeReporting/401K';
import ARAgingReport from '../features/EdgeReporting/ARAgingReport';
import CheckReconciliation from '../features/EdgeReporting/CheckReconciliation';
import ClientBillingAMCMMYR from '../features/EdgeReporting/ClientBillingAMCMMYR';
import ClientSearch from '../features/EdgeReporting/ClientSearch';
import ContractActivitySearch from '../features/EdgeReporting/ContractActivitySearch';
import DeductionRegister from '../features/EdgeReporting/DeductionRegister';
import DriverSearch from '../features/EdgeReporting/DriverSearch';
import GrossMarginAMC from '../features/EdgeReporting/GrossMarginAMC';
import InvoicePdf from '../features/EdgeReporting/InvoicePdf';
import PayrollCheckRegister from '../features/EdgeReporting/PayrollCheckRegister';
import RiskClaimsValidation from '../features/EdgeReporting/RiskClaimsValidation';
import SAClientRankingAMC from '../features/EdgeReporting/SAClientRankingAMC';
import ClientContactActivity from '../features/EdgeReporting/ClientContactActivity';
import SalesTax from '../features/EdgeReporting/SalesTax';
import WebPortalActivity from '../features/EdgeReporting/WebPortalActivity';
import WorkerCompensation from '../features/EdgeReporting/WorkerCompensation';
import DriverSexualHarassemntTraining from '../features/EdgeReporting/DriverSexualHarassemntTraining'
import ExpenseEntry from '../features/ExpenseEntry';
import DriverSolutions from '../features/ExpenseEntry/DriverSolutions';
import Airline from '../features/ExpenseEntry/DriverSolutions/Airline';
import EditAirline from '../features/ExpenseEntry/DriverSolutions/Airline/editAirline';
import Car from '../features/ExpenseEntry/DriverSolutions/Car';
import EditCar from '../features/ExpenseEntry/DriverSolutions/Car/editCar';
import Hotel from '../features/ExpenseEntry/DriverSolutions/Hotel';
import EditHotel from '../features/ExpenseEntry/DriverSolutions/Hotel/editHotel';
import Other from '../features/ExpenseEntry/DriverSolutions/Other';
import EditOther from '../features/ExpenseEntry/DriverSolutions/Other/editOther';
import ReadyToBillClosed from '../features/ExpenseEntry/DriverSolutions/ReadyToBillClosed';
import ReadyToBillOpen from '../features/ExpenseEntry/DriverSolutions/ReadyToBillOpen';
import SearchExpenses from '../features/ExpenseEntry/DriverSolutions/SearchExpenses';
import TransferToUltraStaff from '../features/ExpenseEntry/DriverSolutions/TransferToUltraStaff';
import QuoteEngine from '../features/QuoteEngine';
import GenerateClerical from '../features/QuoteEngine/GenerateQuote/Clerical';
import GenerateDriversQuote from '../features/QuoteEngine/GenerateQuote/Drivers';
import MyQuotes from '../features/QuoteEngine/MyQuotes';
import ViewQuote from '../features/QuoteEngine/ViewQuote';
import TableContents from '../features/TableContents';
import TableContent from '../features/TableContents/TableContent';
import VerifyDQFAuthLink from '../features/VerifyDQFAuthLink';
import NationalPushNotifications from '../features/PushNotifications/NationalPushNotifications';
import AddNationalPushNotifications from '../features/PushNotifications/NationalPushNotifications/addNationalPushNotification';
import EditNationalPushNotifications from '../features/PushNotifications/NationalPushNotifications/editNationalPushNotification';
import ViewNationalPushNotifications from '../features/PushNotifications/NationalPushNotifications/viewNationalPushNotification';
import LOBPushNotifications from '../features/PushNotifications/LOBPushNotifications';
import AddLOBPushNotifications from '../features/PushNotifications/LOBPushNotifications/addLOBPushNotification';
import EditLOBPushNotifications from '../features/PushNotifications/LOBPushNotifications/editLOBPushNotification';
import ViewLOBPushNotifications from '../features/PushNotifications/LOBPushNotifications/viewLOBPushNotification';
import BranchPushNotifications from '../features/PushNotifications/BranchPushNotifications';
import AddBranchPushNotifications from '../features/PushNotifications/BranchPushNotifications/addBranchPushNotification';
import EditBranchPushNotifications from '../features/PushNotifications/BranchPushNotifications/editBranchPushNotification';
import ViewBranchPushNotifications from '../features/PushNotifications/BranchPushNotifications/viewBranchPushNotification';

const Routes = () => (
  <Switch>
    <Route path="/" exact element={<Login />} />
    <Route path="/verify-pw-less/dqf" element={<VerifyDQFAuthLink />} />
    <Route path="/dashboard" element={<Private Component={Dashboard} />} />
    <Route path="/admin-portal" element={<Private Component={AdminPortal} />} />
    {/* Driver Qualification Routes */}
    <Route
      path="/driver-qualification"
      element={<Private Component={DriverQualification} />}
    />
    <Route
      path="/driver-qualification/assemble-new-dqf/:id"
      element={<Private Component={AssembleNewDQF} />}
    />
    <Route
      path="/driver-qualification/select-document/:id/:driverQualificationChecklistDocumentId"
      element={<Private Component={SelectDocumentDetails} />}
    />
    <Route path="/new-request" element={<Private Component={NewRequest} />} />
    <Route path="/approved" element={<Private Component={Request} params={{ status: 'Approved' }} />} />
    <Route path="/assembling" element={<Private Component={Request} params={{ status: 'Assembling' }} />} />
    <Route path="/client-review" element={<Private Component={Request} params={{ status: 'Client Review' }} />} />
    <Route path="/rejected" element={<Private Component={Request} params={{ status: 'Rejected' }} />} />
    <Route path="/requested" element={<Private Component={Request} params={{ status: 'Requested' }} />} />
    <Route
      path="/information-requested"
      element={<Private Component={Request} params={{ status: 'Information Requested' }} />}
    />
    <Route path="/driver-qualification/clientdq/:id" element={<Private Component={ClientDQ} />} />
    <Route path="/driver-qualification/corporatedq/:id" element={<Private Component={CorporateDQ} />} />
    <Route path="/sent" element={<Private Component={Request} params={{ status: 'Sent' }} />} />
    <Route path="/driver-qualification/new-request" element={<Private Component={NewRequest} />} />
    <Route
      path="/driver-qualification/approved"
      element={<Private Component={Request} params={{ status: 'Approved' }} />}
    />
    <Route
      path="/driver-qualification/assembling"
      element={<Private Component={Request} params={{ status: 'Assembling' }} />}
    />
    <Route
      path="/driver-qualification/client-review"
      element={<Private Component={Request} params={{ status: 'Client Review' }} />}
    />
    <Route
      path="/driver-qualification/rejected"
      element={<Private Component={Request} params={{ status: 'Rejected' }} />}
    />
    <Route
      path="/driver-qualification/requested"
      element={<Private Component={Request} params={{ status: 'Requested' }} />}
    />
    <Route
      path="/driver-qualification/information-requested"
      element={<Private Component={Request} params={{ status: 'Information Requested' }} />}
    />
    <Route path="/driver-qualification/sent" element={<Private Component={Request} params={{ status: 'Sent' }} />} />
    <Route path="/driver-qualification/view-dqf/:id" element={<Private Component={ViewDQF} />} />
    <Route path="/driver-qualification/mfa-validation" element={<Private Component={MFAValidation} />} />
    {/* Management Routes */}
    <Route path="/client-users" element={<Private Component={ClientAccess} />} />
    <Route path="/client-users/add" element={<Private Component={AddClientUser} />} />
    <Route path="/client-users/update/:id/:user" element={<Private Component={UpdateClientUser} />} />
    <Route path="/edit-access/:type/:user" element={<Private Component={EditAccess} />} />
    <Route path="/corporate-users" element={<Private Component={CorporateAccess} />} />
    <Route path="/checklist-template" element={<Private Component={ChecklistTemplates} />} />
    <Route path="/checklist-template/details/:checklistId" element={<Private Component={ChecklistDetails} />} />
    <Route path="/checklist-template/edit-details/:checklistId" element={<Private Component={ChecklistDetails} />} />
    <Route path="/table-contents" element={<Private Component={TableContents} />} />
    <Route
      path="/table-contents/airlines"
      element={<Private Component={TableContent} params={{ type: 'Airlines' }} />}
    />
    <Route
      path="/table-contents/airports"
      element={<Private Component={TableContent} params={{ type: 'Airports' }} />}
    />
    <Route
      path="/table-contents/car-rental-companies"
      element={<Private Component={TableContent} params={{ type: 'Companies' }} />}
    />
    <Route path="/table-contents/cities" element={<Private Component={TableContent} params={{ type: 'Cities' }} />} />
    <Route path="/table-contents/hotels" element={<Private Component={TableContent} params={{ type: 'Hotels' }} />} />
    {/* Standard Document Routes */}
    <Route path="/standard-document/:id" element={<Private Component={StandardDocument} />} />
    <Route path="/standard-document/edit/:id" element={<Private Component={StandardDocument} />} />
    <Route path="/standard-documents" element={<Private Component={StandardDocuments} />} />
    {/* Quote Engine Routes */}
    <Route path="/quote-engine" element={<Private Component={QuoteEngine} />} />
    <Route path="/quote-engine/generate-quote/drivers" element={<Private Component={GenerateDriversQuote} />} />
    <Route path="/quote-engine/generate-quote/clerical" element={<Private Component={GenerateClerical} />} />
    <Route path="/quote-engine/my-quotes" element={<Private Component={MyQuotes} />} />
    <Route path="/quote-engine/view-quote/:quoteId" element={<Private Component={ViewQuote} />} />

    <Route path="/expense-entry" element={<Private Component={ExpenseEntry} />} />

    {/* Expense Entry Routes*/}
    <Route path="/expense-entry/mobile-driver-solutions" element={<Private Component={DriverSolutions} />} />
    <Route path="/expense-entry/mobile-driver-solutions/airline" element={<Private Component={Airline} />} />
    <Route path="/expense-entry/mobile-driver-solutions/edit-airline/:id" element={<Private Component={EditAirline} />} />
    <Route path="/expense-entry/mobile-driver-solutions/hotel" element={<Private Component={Hotel} />} />
    <Route path="/expense-entry/mobile-driver-solutions/edit-hotel/:id" element={<Private Component={EditHotel} />} />
    <Route path="/expense-entry/mobile-driver-solutions/car" element={<Private Component={Car} />} />
    <Route path="/expense-entry/mobile-driver-solutions/edit-car/:id" element={<Private Component={EditCar} />} />
    <Route path="/expense-entry/mobile-driver-solutions/other" element={<Private Component={Other} />} />
    <Route path="/expense-entry/mobile-driver-solutions/edit-other/:id" element={<Private Component={EditOther} />} />
    <Route path="/expense-entry/mobile-driver-solutions/ready-to-bill-open" element={<Private Component={ReadyToBillOpen} />} />
    <Route path="/expense-entry/mobile-driver-solutions/ready-to-bill-closed" element={<Private Component={ReadyToBillClosed} />} />
    <Route path="/expense-entry/mobile-driver-solutions/transfer-to-ultrastaff" element={<Private Component={TransferToUltraStaff} />} />
    <Route path="/expense-entry/mobile-driver-solutions/search-expenses" element={<Private Component={SearchExpenses} />} />

    {/* DMS Expense Entry Routes*/}
    <Route path="/expense-entry/driver-management-services" element={<Private Component={DriverSolutions} />} />
    <Route path="/expense-entry/driver-management-services/airline" element={<Private Component={Airline} />} />
    <Route path="/expense-entry/driver-management-services/edit-airline/:id" element={<Private Component={EditAirline} />} />
    <Route path="/expense-entry/driver-management-services/hotel" element={<Private Component={Hotel} />} />
    <Route path="/expense-entry/driver-management-services/edit-hotel/:id" element={<Private Component={EditHotel} />} />
    <Route path="/expense-entry/driver-management-services/car" element={<Private Component={Car} />} />
    <Route path="/expense-entry/driver-management-services/edit-car/:id" element={<Private Component={EditCar} />} />
    <Route path="/expense-entry/driver-management-services/other" element={<Private Component={Other} />} />
    <Route path="/expense-entry/driver-management-services/edit-other/:id" element={<Private Component={EditOther} />} />
    <Route path="/expense-entry/driver-management-services/ready-to-bill-open" element={<Private Component={ReadyToBillOpen} />} />
    <Route path="/expense-entry/driver-management-services/ready-to-bill-closed" element={<Private Component={ReadyToBillClosed} />} />
    <Route path="/expense-entry/driver-management-services/transfer-to-ultrastaff" element={<Private Component={TransferToUltraStaff} />} />
    <Route path="/expense-entry/driver-management-services/search-expenses" element={<Private Component={SearchExpenses} />} />

    {/* Expense Maintenance */}
    <Route path="/expense-entry/expense-maintenance" element={<Private Component={DriverSolutions} />} />

    {/* Push Notifications Routes*/}
    <Route path="/push-notifications" element={<Private Component={PushNotifications} />} />
    <Route path="/push-notifications/national-push-notifications" element={<Private Component={NationalPushNotifications} />} />
    <Route path="/push-notifications/national-push-notifications/add-national-push-notification" element={<Private Component={AddNationalPushNotifications} />} />
    <Route path="/push-notifications/national-push-notifications/edit-national-push-notification/:id" element={<Private Component={EditNationalPushNotifications} />} />
    <Route path="/push-notifications/national-push-notifications/view-national-push-notification/:id" element={<Private Component={ViewNationalPushNotifications} />} />
    <Route path="/push-notifications/lob-push-notifications" element={<Private Component={LOBPushNotifications} />} />
    <Route path="/push-notifications/lob-push-notifications/add-lob-push-notification" element={<Private Component={AddLOBPushNotifications} />} />
    <Route path="/push-notifications/lob-push-notifications/edit-lob-push-notification/:id" element={<Private Component={EditLOBPushNotifications} />} />
    <Route path="/push-notifications/lob-push-notifications/view-lob-push-notification/:id" element={<Private Component={ViewLOBPushNotifications} />} />
    <Route path="/push-notifications/branch-push-notifications" element={<Private Component={BranchPushNotifications} />} />
    <Route path="/push-notifications/branch-push-notifications/add-branch-push-notification" element={<Private Component={AddBranchPushNotifications} />} />
    <Route path="/push-notifications/branch-push-notifications/edit-branch-push-notification/:id" element={<Private Component={EditBranchPushNotifications} />} />
    <Route path="/push-notifications/branch-push-notifications/view-branch-push-notification/:id" element={<Private Component={ViewBranchPushNotifications} />} />

    {/* EDGE Reporting Routes*/}
    <Route path="/edge-reporting" element={<Private Component={EdgeReporting} />} />
    <Route path="/edge-reporting/deduction-register" element={<Private Component={DeductionRegister} />} />
    <Route path="/edge-reporting/driver-search" element={<Private Component={DriverSearch} />} />
    <Route path="/edge-reporting/driver-sexual-harassment-training" element={<Private Component={DriverSexualHarassemntTraining} />} />
    <Route path="/edge-reporting/client-billing-amc-mm-yr" element={<Private Component={ClientBillingAMCMMYR} />} />
    <Route path="/edge-reporting/contract-activity-search" element={<Private Component={ContractActivitySearch} />} />
    <Route path="/edge-reporting/client-contact-activity" element={<Private Component={ClientContactActivity} />} />
    <Route path="/edge-reporting/401k-report" element={<Private Component={FourCeroOneK} />} />
    <Route path="/edge-reporting/client-search" element={<Private Component={ClientSearch} />} />
    <Route path="/edge-reporting/ar-aging-report" element={<Private Component={ARAgingReport} />} />
    <Route path="/edge-reporting/check-pdf" element={<Private Component={CheckPdf} />} />
    <Route path="/edge-reporting/invoice-pdf" element={<Private Component={InvoicePdf} />} />
    <Route path="/edge-reporting/payroll-check-register" element={<Private Component={PayrollCheckRegister} />} />
    <Route path="/edge-reporting/client-billing-amc-mm-yr" element={<Private Component={ClientBillingAMCMMYR} />} />
    <Route path="/edge-reporting/gross-margin-amc" element={<Private Component={GrossMarginAMC} />} />
    <Route path="/edge-reporting/sales-tax-report" element={<Private Component={SalesTax} />} />
    <Route path="/edge-reporting/sa-client-ranking-amc" element={<Private Component={SAClientRankingAMC} />} />
    <Route path="/edge-reporting/risk-claims-validation" element={<Private Component={RiskClaimsValidation} />} />
    <Route path="/edge-reporting/check-reconciliation" element={<Private Component={CheckReconciliation} />} />
    <Route path="/edge-reporting/worker-compensation" element={<Private Component={WorkerCompensation} />} />
    <Route path="/edge-reporting/web-portal-activity" element={<Private Component={WebPortalActivity} />} />
    {/* Not Found Route */}
    <Route path="*" element={<NotFound />} />
  </Switch>
);

export default Routes;
