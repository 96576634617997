import React, { useEffect, useMemo } from 'react';

import { Home } from '@mui/icons-material';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import getCSVData from './csv';
import { getClientAccess } from './operations';
import Excel from '../../assets/excel.png';
import { SubHeader, Footer, CommonTable as DQTable } from '../../components';
import { newDateFormatted } from '../../utils';

import './styles.scoped.css';

const CorporateAccess = () => {
  const navigation = useNavigate();
  const { listData } = useSelector(state => state.clientAccess);

  useEffect(() => {
    getClientAccess();
  }, []);

  const appAccess = user => {
    navigation(`/edit-access/${user.type}/${user.id}`);
  };

  const fileName = useMemo(() => `corporate-accesses-${newDateFormatted.toString()}.csv`, [dayjs]);

  const listToShow = useMemo(() => listData.filter(item => item.type === 'Corporate'), [listData]);

  return (
    <div>
      <SubHeader
        text="Admin Portal"
        btnSmall={<Home sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigation(-1)}
      />
      {listToShow?.length && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <CSVLink filename={fileName} data={getCSVData(listToShow)}>
            <img src={Excel} alt="export-to-excel" style={{ padding: '20px 30px 0 0 ', cursor: 'pointer' }} />
          </CSVLink>
        </div>
      )}
      <div className="wrap">
        <DQTable
          data={listToShow}
          dataType="Corporate"
          handleSetData={getClientAccess}
          handleViewApps={appAccess}
          addUser={() => navigation('/client-users/add')}
        />
      </div>
      <Footer />
    </div>
  );
};

export default CorporateAccess;
