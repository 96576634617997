/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';

import { Container, Col, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { TableFilter, TablePagination } from '../../components';
import { formatIsoToDate } from '../../utils';

function getSortIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

export default function DQQuestionsTable({ data }) {
  // const columnClassName = 'align-middle text-nowrap';
  const columnTextNoWrap = 'align-middle text-nowrap';
  const columnTextWrap = 'align-middle text-wrap';
  const memoData = useMemo(() => data, [data]);
  const columns = useMemo(
    () => [
      {
        Header: 'User Name',
        accessor: 'createUserFullName',
        className: columnTextNoWrap,
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        className: columnTextNoWrap,
        Cell: instance => <>{`${formatIsoToDate(instance.value)}`}</>,
      },
      {
        Header: 'Type',
        accessor: 'type',
        className: columnTextNoWrap,
        Cell: instance => (
          <div>
            {instance.value === 'Information Requested' && 'Request'}
            {instance.value === 'Rejected' && 'Reject Reason'}
            {instance.value === 'Sent' && 'Response'}
          </div>
        ),
      },
      { Header: 'Text', accessor: 'text', className: columnTextWrap },
    ],
    [],
  );
  const tableInstance = useTable(
    {
      columns,
      data: memoData,
      initialState: {
        sortBy: [
          {
            id: 'createdAt',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setFilter,
    setGlobalFilter,
    // The rest of these things are super handy, too ;)
    // setPageSize,
    // state: { pageSize },
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>{column.canSort ? getSortIcon(column.isSorted, column.isSortedDesc) : null}</span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}>
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  function filter(event, columnName) {
    const filterColumn = columns.find(column => column.Header === columnName);
    if (filterColumn) {
      setFilter(filterColumn.accessor, event.target.value);
    } else {
      setGlobalFilter(event.target.value);
    }
  }

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col md={6} className="mr-auto">
          <TableFilter className="mb-3" columns={columns} onSearchChange={filter} />
        </Col>
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      {data.length > 0 && (
        <Row>
          <Col className="border border-light rounded shadow-sm">
            <Table hover responsive borderless {...getTableProps()}>
              <thead className="text-nowrap">{tableHeaders}</thead>
              <tbody {...getTableBodyProps()}>{tableRows}</tbody>
            </Table>
          </Col>
        </Row>
      )}
      {data.length === 0 && (
        <Row style={{ color: '#c8102e', fontWeight: 500 }}>
          <Col style={{ color: '#c8102e', fontWeight: 500 }}>There are no comments to show.</Col>
        </Row>
      )}
      {/* <Row>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
        </Col>
      </Row> */}
    </Container>
  );
}
