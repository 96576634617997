import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SubHeader } from '../../../components';
import useIsMobileScreen from '../../../hooks/useCheckIsMobile';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { generatePWToken } from './operations';
import './styles.scoped.css';

const MFAValidation = () => {
  const [code, setCode] = useState('');
  const location = useLocation();
  // const { cognitoUser } = useSelector(state => state.viewDQF);

  const isMobile = useIsMobileScreen();

  // const params = new URLSearchParams(location.search);
  // const [email, dqf] = [
  //   params.get('email') || '',
  //   params.get('dqf') || '',
  // ];

  // console.log('EMAIL', email);
  // console.log('COGNITO USER?', cognitoUser);

  const handleVerifyCode = async () => {
    if (code) {
      console.log('CODE', code);
      // const resp = await Auth.sendCustomChallengeAnswer(cognitoUser, code);
      // console.log('SMS VALIDATION RESP', resp);
    }
  };

  return (
    <>
      <SubHeader text={'Centerline Driver Qualification'} />
      <Container className="wrap centered">
        <h2
          style={isMobile ? { fontSize: '16px', width: '90%' } : {}}
          className="title-expiration"
        >
          To safeguard our drivers’ privacy an access code was sent to you via
          SMS to (+1 XXX-XXX-6022). Please enter the code below and click
          Verify.
        </h2>
        <div className="top-wrapper">
          <div className="enter-code-container">
            <div>Enter Code</div>
            <div className="required-field">*</div>
          </div>
          <input className="input" onChange={e => setCode(e.target.value)} />
          <Button className="verify-button" onClick={() => handleVerifyCode()}>
            <span>Verify</span>
          </Button>
        </div>
        <h5 className="send-code-text">
          Didn’t get the code? Click Re-Send to try again
        </h5>
        <Button className="send-code-button">
          <span>Verify</span>
        </Button>
      </Container>
    </>
  );
};

export default MFAValidation;
