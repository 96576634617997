/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Auth } from 'aws-amplify';
import { Button, Spinner } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import TableFilter from './CommonTableFilter';
import TablePagination from './CommonTablePagination';
import { formatIsoToDate } from '../../utils';
import checklistApi from '../../utils/checklistAPI';

function getSortIcon(isSorted, isSortedDesc) {
  let iconProp = faSort;
  if (isSorted) {
    iconProp = isSortedDesc ? faSortDown : faSortUp;
  }
  return <FontAwesomeIcon icon={iconProp} className="ml-1 fa-sm" />;
}

export default function ChecklistTable({ data }) {
  const columnClassName = 'align-middle text-nowrap';
  const memoData = React.useMemo(() => data, [data]);
  const [loading, setLoading] = useState(false);
  const skipPageResetRef = React.useRef();
  const navigation = useNavigate();
  const { tierData } = useSelector(state => state.checkList);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        className: columnClassName,
        Cell: instance => <Link to={`/checklist-template/edit-details/${instance.value}`}>{instance.value}</Link>,
      },
      {
        Header: 'Category',
        accessor: 'category',
        className: columnClassName,
        Cell: () => 'Checklist',
      },
      {
        Header: 'Level',
        accessor: 'checkListLevel',
        className: columnClassName,
        Cell: instance => instance.value,
      },
      {
        Header: 'Type',
        accessor: 'tier',
        className: columnClassName,
        Cell: instance => tierData.find(tier => tier.value?.toString() === instance.value?.toString())?.label,
      },
      {
        Header: 'Client',
        accessor: 'clientId',
        className: columnClassName,
        Cell: instance =>
          instance.value === 0
            ? instance?.row?.original?.accountMasterCode
            : instance.value
              ? `${instance.value} - ${
                data.find(checklist => checklist.clientId?.toString() === instance.value.toString())?.clientName
              }`
              : '',
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        className: columnClassName,
        Cell: instance => formatIsoToDate(instance.value),
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        className: columnClassName,
        Cell: instance => formatIsoToDate(instance.value),
      },
    ],
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data: memoData,
      initialState: {
        sortBy: [
          {
            id: 'id',
            desc: true,
          },
        ],
      },
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
      autoResetGlobalFilter: !skipPageResetRef.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setFilter,
    setGlobalFilter,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>{column.canSort ? getSortIcon(column.isSorted, column.isSortedDesc) : null}</span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}>
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  function filter(event, columnName) {
    const filterColumn = columns.find(column => column.Header === columnName);
    if (filterColumn) {
      setFilter(filterColumn.accessor, event.target.value);
    } else {
      setGlobalFilter(event.target.value);
    }
  }

  const getNewId = async () => {
    setLoading(true);
    const response = await checklistApi.get('/dqf-chklst-ids', {
      headers: {
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
    });
    setLoading(false);
    if (response.data.response_body.id) {
      // go to add page
      navigation(`/checklist-template/details/${response.data.response_body.id}`);
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <TableFilter columns={columns} onSearchChange={filter} />
          </Col>
          <Col className="d-flex justify-content-center">
            <TablePagination tableInstance={tableInstance} />
          </Col>
          <Col className="text-right">
            <Button className="px-5" variant="primary" onClick={getNewId}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Add'}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="border border-light rounded shadow-sm">
            <Table hover responsive borderless {...getTableProps()}>
              <thead className="text-nowrap">{tableHeaders}</thead>
              <tbody {...getTableBodyProps()}>{tableRows}</tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}
