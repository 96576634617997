import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: 'app',
    initialState: {
        storedVersion: '0.0.0'
    },
    reducers: {
        setVersion: (state, { payload }) => {
            state.storedVersion = payload;
        },
    },
});

export default appSlice.reducer;

export const { setVersion } = appSlice.actions;