/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo, useEffect } from 'react';

import { API, graphqlOperation } from 'aws-amplify';
import { Button, Container, Col, Row, Table } from 'react-bootstrap';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import TableFilter from '../../components/TableFilter';
import TablePagination from '../../components/TablePagination';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { formatIsoToDate } from '../../utils';

function getSortIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

export default function CorporateDQTable({ handleSetShowInParent }) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const memoData = useMemo(() => data, [data]);

  const INPROGRESS = 'In-Progress';
  const COMPLETED = 'Completed';

  const updateQuestion = async (id, newStatus) => {
    const update = {
      id: id,
      status: newStatus,
    };
    try {
      const response = await API.graphql(graphqlOperation(mutations.updateQuestions, { input: update }));
      return true;
    } catch (e) {
      console.log('Update Status Error: ', e);
      return false;
    }
  };

  const getDQ = async id => {
    const body = await getDQByID(id);
    if (body) {
      const qs = body.questions.items;
      setData(qs);
    }
  };

  const getDQByID = async id => {
    try {
      const response = await API.graphql({
        query: queries.getDriverQualification,
        variables: { id },
      });
      return response.data.getDriverQualification;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) getDQ(id);
  }, [id]);

  const updateCommentStatus = instance => {
    const handleStatusClick = async (instance, instanceStatus) => {
      let newStatus;
      if (instanceStatus !== null) {
        if (instanceStatus === INPROGRESS) {
          newStatus = COMPLETED;
        } else {
          newStatus = INPROGRESS;
        }

        const success = await updateQuestion(instance.cell.row.original.id, newStatus);
        if (success) {
          getDQ(id);
        } else {
          handleSetShowInParent(true);
        }
      }
    };

    const instanceStatus = instance.cell.row.cells[1].value;
    return (
      <>
        <Button
          style={{
            fontSize: '12px',
            display: 'flex',
            justifyContent: 'center',
          }}
          onClick={() => {
            handleStatusClick(instance, instanceStatus);
          }}>
          Mark
          <br />
          {instanceStatus === INPROGRESS ? COMPLETED : INPROGRESS}
        </Button>
      </>
    );
  };

  const noWrapClassName = 'align-middle text-nowrap';
  const wrapClassName = 'align-middle text-wrap';
  const columns = useMemo(
    () => [
      {
        Header: 'Action',
        accessor: '',
        className: noWrapClassName,
        Cell: instance => updateCommentStatus(instance),
      },
      { Header: 'Status', accessor: 'status', className: noWrapClassName },
      {
        Header: 'User Name',
        accessor: 'createUserFullName',
        className: wrapClassName,
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        className: noWrapClassName,
        Cell: instance => <>{`${formatIsoToDate(instance.value)}`}</>,
      },
      {
        Header: 'Type',
        accessor: 'type',
        className: noWrapClassName,
        Cell: instance => (instance.value === 'Sent' ? 'Response' : instance.value),
      },
      {
        Header: 'Comment',
        accessor: 'text',
        className: wrapClassName,
      },
    ],
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data: memoData,
      initialState: {
        sortBy: [
          {
            id: 'createdAt',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setFilter,
    setGlobalFilter,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>{column.canSort ? getSortIcon(column.isSorted, column.isSortedDesc) : null}</span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}>
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  function filter(event, columnName) {
    const filterColumn = columns.find(column => column.Header === columnName);
    if (filterColumn) {
      setFilter(filterColumn.accessor, event.target.value);
    } else {
      setGlobalFilter(event.target.value);
    }
  }

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col md={6} className="mt-4">
          <TableFilter className="mb-3" columns={columns} onSearchChange={filter} />
        </Col>
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>

      {getTableBodyProps ? (
        <Row>
          <Col className="border border-light rounded shadow-sm">
            <Table hover responsive borderless {...getTableProps()}>
              <thead className="text-nowrap">{tableHeaders}</thead>
              <tbody {...getTableBodyProps()}>{tableRows}</tbody>
            </Table>
          </Col>
        </Row>
      ) : (
        <Row style={{ color: '#c8102e', fontWeight: 500 }}>
          <Col style={{ color: '#c8102e', fontWeight: 500 }}>There are no comments to show.</Col>
        </Row>
      )}
    </Container>
  );
}
