import React from 'react';
import { getCurrencyString } from '../../../utils/utilities';

export const ClericalCostField = ({
  label,
  costLabel,
  payLabel,
  billLabel,
  value,
}) => {
  const { cost, pay, bill, multiplier } = value;
  if (cost + bill + multiplier === 0) {
    return null;
  }

  return (
    <p>
      {label}
      <br />
      <span className="text-muted mr-3">
        {`${costLabel} ${getCurrencyString(cost)}`}
      </span>
      <span className="text-muted mr-3">
        {pay > 0 && `${payLabel} ${getCurrencyString(pay)}`}
      </span>
      {bill > 0 && (
        <span className="text-muted mr-3">
          {`${billLabel} ${getCurrencyString(bill)}`}
        </span>
      )}
      <span className="text-muted mr-3">
        {multiplier > 0 && `Multiplier ${multiplier}`}
      </span>
    </p>
  );
};

export default ClericalCostField;
