import { createSlice } from '@reduxjs/toolkit';

import { newDateFormattedYearFirst } from '../../../../utils';

const clericalSlice = createSlice({
  name: 'quoteEngineClerical',
  initialState: {
    form: {
      location: '',
      region: '',
      manager: 0,
      supervisor: 0,
      admin: 0,
      startDate: newDateFormattedYearFirst,
    },
    errors: {},
  },
  reducers: {
    setForm: (state, { payload }) => {
      state.form = payload;
    },
    setErrors: (state, { payload }) => {
      state.errors = payload;
    },
    setCurrentQuote: (state, { payload }) => {
      state.currentQuote = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

export default clericalSlice.reducer;

export const { setErrors, setForm, setCurrentQuote, setLoading } = clericalSlice.actions;
