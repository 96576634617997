import { API } from 'aws-amplify';

import { listClosedAssignments, listExpenses, listOpenAssignments } from '../../../../graphql/customQueries';

export const getExpensesFiltered = async ({ filter, nextToken }) => {
  try {
    const response = await API.graphql({
      query: listExpenses,
      variables: {
        filter: filter,
        sortDirection: 'DESC',
        nextToken,
      },
    });

    return response.data.listExpenses;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getOpenAssignments = async (lob, token) => {
  try {
    const response = await API.graphql({
      query: listOpenAssignments,
      variables: {
        lob: lob,
        lastEvaluatedKey: token,
      },
    });

    return response.data.listExpensesReadyToBillOpenAssignments;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getClosedAssignments = async (lob, token) => {
  try {
    const response = await API.graphql({
      query: listClosedAssignments,
      variables: {
        lob: lob,
        lastEvaluatedKey: token,
      },
    });

    return response.data.listExpensesReadyToBillClosedAssignments;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
