import { createSlice } from '@reduxjs/toolkit';

const expenseFormSlice = createSlice({
  name: 'expenseForm',
  initialState: {
    data: {
      drivers: [],
      clients: [],
      assignments: [],
      branches: [],
      hasSetInitialData: false,
    },
    isLoading: false,
    formData: {},
    receiptDataLoading: false,
    receiptsData: [],
    clientFormData: {},
    driversAssignmentsData: [],
    receiptsInitialData: {},
    isLoadingClientForm: false,
  },
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
    },
    resetData: state => {
      state.data = {
        drivers: [],
        clients: [],
        assignments: [],
        branches: [],
        hasSetInitialData: false,
      };
      state.isLoading = false;
      state.formData = {};
      state.receiptDataLoading = false;
      state.receiptsData = [];
      state.driversAssignmentsData = [];
      state.receiptsInitialData = {};
      state.isLoadingClientForm = false;
      state.clientFormData = {};
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setFormData: state => {
      state.formData = [];
    },
    setClientFormData: state => {
      state.clientFormData = {};
    },
    setIsLoadingClientForm: (state, { payload }) => {
      state.isLoadingClientForm = payload;
    },
    setDriversAssignmentsData: (state, { payload }) => {
      state.driversAssignmentsData[payload.id] = payload.data;
    },
    resetDriversAssignmentsData: state => {
      state.driversAssignmentsData = [];
    },
    setReceiptData: (state, { payload }) => {
      state.receiptsData[payload.id] = payload.data;
    },
    setReceiptDataLoading: (state, { payload }) => {
      state.receiptDataLoading = payload;
    },
    setReceiptsInitialData: (state, { payload }) => {
      state.receiptsInitialData = payload;
    },
  },
});

export default expenseFormSlice.reducer;

export const {
  setData,
  resetData,
  setIsLoading,
  setFormData,
  setDriversAssignmentsData,
  resetDriversAssignmentsData,
  setReceiptDataLoading,
  setReceiptData,
  setReceiptsInitialData,
  setIsLoadingClientForm,
  setClientFormData,
} = expenseFormSlice.actions;
