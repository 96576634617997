import { graphqlOperation, API } from 'aws-amplify';

import { setAllUsers } from './addClientUserSlice';
import store from '../../../app/store';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';

export const getAllUsers = async () => {
  try {
    const body = await API.graphql({
      query: queries.listUsers,
      variables: { limit: 3400 },
    });
    store.dispatch(setAllUsers(body.data.listUsers.items));
    return body.data.listUsers.items;
  } catch (e) {
    return e;
  }
};

export const getClient = async id => {
  try {
    const path = `clients/${id}`;
    const response = await API.get('centerlineportal', path);
    return response;
  } catch (e) {
    return e;
  }
};

export const createNewUser = async input => {
  try {
    const response = await API.graphql(graphqlOperation(mutations.createUser, { input }));
    return true;
  } catch (e) {
    return e;
  }
};

export const createUserCustomer = async input => {
  try {
    await API.graphql(graphqlOperation(mutations.createUserCustomer, { input }));
    return true;
  } catch (e) {
    console.log(`Update Customers Error: ${JSON.stringify(e)}`);
    return false;
  }
};

export const formatPhoneNumber = value => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const firstPart = phoneNumber.slice(0, 1);
  const secondPart = phoneNumber.slice(1, 4);
  const thirdPart = phoneNumber.slice(4, 7);
  const fourthPart = phoneNumber.slice(7);
  return `${firstPart}${secondPart ? '-' : ''}${secondPart}${thirdPart ? '-' : ''}${thirdPart}${
    fourthPart ? '-' : ''
  }${fourthPart}`;
};
