import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Checkbox } from '@mui/material';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { formatIsoToDate } from '../../utils';
import { getCurrencyString } from '../../utils/utilities';
import TablePagination from '../TablePagination';

import './styles.scoped.css';

const columnClassName = 'align-middle text-nowrap';
export const transferToUltraStaffColumns = [
  {
    Header: 'Pay Week Ending',
    accessor: 'payWeekEnding',
    className: columnClassName,
    Cell: instance => `${formatIsoToDate(instance.value)}`,
  },
  {
    Header: 'Inv.',
    accessor: 'inv',
    className: columnClassName,
  },
  {
    Header: 'Pay Code',
    accessor: 'code',
    className: columnClassName,
  },
  {
    Header: 'Pay Type',
    accessor: 'payType',
    className: columnClassName,
  },
  {
    Header: 'Driver Name & Number',
    accessor: 'driverNameNumber',
    className: columnClassName,
  },
  {
    Header: 'Assignment / Contract Act.',
    accessor: 'assignmentId',
    className: columnClassName,
  },
  {
    Header: 'Customer Name & Number',
    accessor: 'customerNameNumber',
    className: columnClassName,
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    className: columnClassName,
    Cell: instance => `${formatIsoToDate(instance.value)}`,
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
    className: columnClassName,
    Cell: instance => `${formatIsoToDate(instance.value)}`,
  },
  {
    Header: 'Pay Amt',
    accessor: 'payAmt',
    className: columnClassName,
  },
  {
    Header: 'Bill Units',
    accessor: 'billUnits',
    className: columnClassName,
  },
  {
    Header: 'Bill Amount',
    accessor: 'totalToBeBilled',
    className: columnClassName,
    Cell: instance => `${getCurrencyString(instance.value)}`,
  },
  {
    Header: 'OK',
    accessor: 'ok',
    className: columnClassName,
  },
];

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const TransferToUltraStaffTable = ({
  color,
  data,
  loading,
  handleSelectExpense,
  handleSelectAll,
  selectedExpenses,
  hasFilters,
}) => {
  const memoData = useMemo(() => data, [data]);
  const [isChecked, setIsChecked] = useState(false);
  const [calculatedSubtotal, setCalculatedSubtotal] = useState(false);

  const ExpenseSelector = useCallback(
    ({ expense, rowChecked }) => (
      <Checkbox
        style={{
          color: '#da0f2e',
          height: 0,
          width: 0,
        }}
        onChange={() => {
          handleSelectExpense(expense);
        }}
        checked={!!rowChecked}
        defaultValue={false}
      />
    ),
    [handleSelectExpense],
  );

  const SelectAllExpenses = useCallback(
    () => (
      <Checkbox
        style={{
          color: '#da0f2e',
          height: 0,
          width: 0,
        }}
        checked={isChecked}
        onChange={handleSelectAll}
        defaultValue={false}
      />
    ),
    [handleSelectAll, isChecked],
  );

  const checkIfAllSelected = useCallback(() => {
    const isAllSelected = selectedExpenses?.length === data.length;
    setIsChecked(isAllSelected);
  }, [data.length, selectedExpenses?.length]);

  const checkSubtotal = useCallback(() => {
    const total = data.reduce((result, item) => {
      result += item.totalToBeBilled;
      return result;
    }, 0);
    setCalculatedSubtotal(getCurrencyString(total));
  }, [data]);

  useEffect(() => {
    checkSubtotal();
  }, [checkSubtotal, data]);

  useEffect(() => {
    checkIfAllSelected();
  }, [checkIfAllSelected, selectedExpenses]);

  const columns = useMemo(
    () => [
      {
        Header: !hasFilters ? SelectAllExpenses() : <div />,
        accessor: 'selectExpense',
        className: columnClassName,
        disableSortBy: true,
        Cell: instance =>
          ExpenseSelector({
            expense: data.find(e => e.id === instance.row.original.id),
            handleSelectExpense: handleSelectExpense,
            rowChecked: selectedExpenses.find(se => se.id === instance.row.original.id),
          }),
      },
      ...transferToUltraStaffColumns,
    ],
    [hasFilters, SelectAllExpenses, ExpenseSelector, data, handleSelectExpense, selectedExpenses],
  );

  const tableInstance = useTable(
    {
      columns,
      data: memoData,
      initialState: {
        sortBy: [
          {
            id: 'CreatedAt',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>{column.canSort ? getSortedIcon(column.isSorted, column.isSortedDesc) : null}</span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}>
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row style={{ backgroundColor: color }}>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {loading && <Spinner animation="border" />}
          </div>
          <div
            style={{
              width: '100%',
              height: '30px',
              justifyContent: 'flex-end',
              flexDiretion: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <div
              style={{
                width: '400px',
                justifyContent: 'flex-end',
                flexDiretion: 'row',
                display: 'flex',
                alignItems: 'center',
                marginRight: '35px',
              }}>
              <p className="font-strong mr-4">Subtotal</p>
              <p className="font-strong">{calculatedSubtotal}</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TransferToUltraStaffTable;
