import { createSlice } from '@reduxjs/toolkit';

const addClientUserSlice = createSlice({
  name: 'addClientUser',
  initialState: { clientData: {}, allUsers: [] },
  reducers: {
    setClientData: (state, { payload }) => {
      state.clientData = payload;
    },
    setAllUsers: (state, { payload }) => {
      state.allUsers = payload;
    },
  },
});

export default addClientUserSlice.reducer;

export const { setClientData, setAllUsers } = addClientUserSlice.actions;
