import React from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import useIsMobileScreen from '../../../hooks/useCheckIsMobile';
import { formatIsoToDate } from '../../../utils';

const DQInfo = ({ dq, handleOwnerClick }) => {
  const { tierData } = useSelector(state => state.newRequest);
  const isMobile = useIsMobileScreen();
  const { id: userId } = useSelector(state => state.admin.user);

  return (
    <>
      <Row style={{ alignItems: 'center', marginBottom: '30px' }}>
        <Col
          style={{
            color: '#c8102e',
            fontSize: isMobile ? '16px' : '24px',
            marginBottom: isMobile ? '20px' : '0',
          }}
          className="font-weight-bold h4">
          {dq?.name}
        </Col>
        <Button
          style={{
            width: '140px',
            height: '40px',
            fontWeight: 700,
            fontSize: 13,
          }}
          disabled={userId === dq?.dqSpecialistEmail}
          onClick={() => handleOwnerClick()}>
          {userId === dq?.dqSpecialistEmail ? 'Ownership Taken' : 'Take Ownership'}
        </Button>
        <Col
          className="font-weight-bold black-header"
          style={{
            display: 'flex',
            justifyContent: 'right',
          }}>
          {dq?.id}
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col>Driver </Col>
            <Col className="text-secondary">{dq?.driverName}</Col>
          </Row>
          <br />
          <Row>
            <Col>Generated by</Col>
            <Col className="text-secondary">{dq?.createUserFullName}</Col>
          </Row>
          <br />
          <>
            <Row>
              <Col>DQF Specialist</Col>
              <Col className="text-secondary">{dq?.dqOwnerFullName}</Col>
            </Row>
            {dq?.tierDescription && (
              <Row>
                <Col>Tier Desc.</Col>
                <Col className="text-secondary">{dq?.tierDescription}</Col>
              </Row>
            )}
          </>
        </Col>
        <Col>
          <Row>
            <Col>Job Order</Col>
            <Col className="text-secondary">{dq?.jobId}</Col>
          </Row>
          <br />
          <Row>
            <Col>Job Start Date</Col>
            <Col className="text-secondary">{formatIsoToDate(dq?.jobStartDate)}</Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>Status</Col>
            <Col className="text-secondary">{dq?.status}</Col>
          </Row>
          <br />
          <Row>
            <Col>Created</Col>
            <Col className="text-secondary">{formatIsoToDate(dq?.createdAt)}</Col>
          </Row>
          <br />
          <Row>
            <Col>Branch</Col>
            <Col className="text-secondary">{dq?.branch}</Col>
          </Row>
          <br />
          <Row>
            <Col>Tier</Col>
            <Col className="text-secondary">{tierData?.filter(aTier => aTier.value === dq?.tier)?.[0]?.label}</Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DQInfo;
