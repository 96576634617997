import React, { useEffect, useMemo, useState } from 'react';

import { Modal as BootModal, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import Select from 'react-select';

import { variablesDependingOnType } from './utils';

import './styles.scoped.css';

const statusOptions = [{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }];

const Modal = ({ tableContent, type, onDelete, onSave, onCancel, stateOptions, isEdit, hasId, hasState }) => {
  const { id, name, state, status } = tableContent;
  const [form, setForm] = useState({
    id: isEdit ? id : undefined,
    name: isEdit ? name : undefined,
    state: isEdit ? state : undefined,
    status: isEdit ? (status?.toLowerCase() === 'active' ? 'Active' : 'Inactive') : 'Active',
  });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [error, setError] = useState('');
  const [successDelete, setSuccessDelete] = useState('');
  const [successEdit, setSuccessEdit] = useState('');
  const [successSave, setSuccessSave] = useState('');
  const [succeeded, setSucceeded] = useState(false);

  const success = useMemo(() => !!(successDelete || successEdit || successSave), [
    successDelete,
    successEdit,
    successSave,
  ]);

  useEffect(() => {
    if (!succeeded) {
      setSucceeded(success);
    }
  }, [success]);

  const onSaveSuccess = () =>
    isEdit
      ? setSuccessEdit(`${form.name} edited successfully.`)
      : setSuccessSave(`${hasId ? `${form.id} - ` : ''}${form.name} was added to the ${type} list.`);

  const onClickDelete = () => {
    setDeleteLoading(true);
    onDelete({ id: tableContent.id })
      .then(() => setSuccessDelete(`${hasId ? `${form.id} - ` : ''}${form.name} deleted successfully.`))
      .catch(e => setError(e.message))
      .finally(() => setDeleteLoading(false));
  };

  const onClickSave = () => {
    if (successSave) {
      setSuccessSave('');
      setForm({ id: undefined, name: undefined, state: undefined, status: 'Active' });
    } else {
      setSaveLoading(true);
      onSave({ ...form, stateCityId: form.state?.id, state: undefined })
        .then(onSaveSuccess)
        .catch(e => setError(e.message))
        .finally(() => setSaveLoading(false));
    }
  };

  return (
    <BootModal show style={{ marginTop: '25vh' }}>
      <BootModal.Header>
        <BootModal.Title>{`${isEdit ? 'Edit' : 'New'} ${variablesDependingOnType.singular[type]}`}</BootModal.Title>
      </BootModal.Header>
      <BootModal.Body>
        {success ? (
          <Col style={{ fontSize: 16, textAlign: 'center' }}>{successDelete || successEdit || successSave}</Col>
        ) : (
          <Col>
            {hasId && (
              <Row style={{ paddingBottom: 20 }}>
                <Col style={{ maxWidth: '20%', marginTop: 6 }}>Id</Col>
                <Col>
                  <Form.Control
                    type="input"
                    name="id"
                    defaultValue={form.id}
                    onChange={event => {
                      setError('');
                      setForm({ ...form, id: event.target.value });
                    }}
                    disabled={deleteLoading || saveLoading}
                  />
                </Col>
              </Row>
            )}
            <Row style={{ paddingBottom: 20 }}>
              <Col style={{ maxWidth: '20%', marginTop: 6 }}>Name</Col>
              <Col>
                <Form.Control
                  type="input"
                  name="name"
                  defaultValue={form.name}
                  onChange={event => {
                    setError('');
                    setForm({ ...form, name: event.target.value });
                  }}
                  disabled={deleteLoading || saveLoading}
                />
              </Col>
            </Row>
            {hasState && (
              <Row style={{ paddingBottom: 20 }}>
                <Col style={{ maxWidth: '20%', marginTop: 6 }}>State</Col>
                <Col>
                  <Select
                    value={form.state && { label: form.state.name, value: form.state.id }}
                    onChange={event => {
                      setError('');
                      setForm({ ...form, state: { name: event.label, id: event.value } });
                    }}
                    options={stateOptions}
                    isDisabled={deleteLoading || saveLoading}
                  />
                </Col>
              </Row>
            )}
            {isEdit && (
              <Row style={{ paddingBottom: 20 }}>
                <Col style={{ maxWidth: '20%', marginTop: 6 }}>Status</Col>
                <Col>
                  <Select
                    value={statusOptions.filter(option => option.value === form.status)}
                    onChange={event => {
                      setError('');
                      setForm({ ...form, status: event.value });
                    }}
                    options={statusOptions}
                    isDisabled={deleteLoading || saveLoading}
                  />
                </Col>
              </Row>
            )}
            <Row style={{ height: 24, paddingLeft: 14 }}>
              {!!error && <div style={{ color: 'red', fontSize: 14 }}>{error}</div>}
            </Row>
          </Col>
        )}
      </BootModal.Body>
      <BootModal.Footer style={{ padding: '0px 32px 24px 32px', borderTop: 'unset', justifyContent: 'center' }}>
        {!success && isEdit && (
          <Button
            variant="secondary"
            className="secondary-button"
            onClick={onClickDelete}
            disabled={deleteLoading || saveLoading}>
            {deleteLoading ? <Spinner animation="border" size="sm" /> : 'DELETE'}
          </Button>
        )}
        {(!!successSave || !success) && (
          <Button
            variant="primary"
            className="primary-button"
            onClick={onClickSave}
            disabled={deleteLoading || saveLoading}>
            {successSave ? 'CONTINUE' : saveLoading ? <Spinner animation="border" size="sm" /> : 'SAVE'}
          </Button>
        )}
        <Button
          variant="secondary"
          className="secondary-button"
          onClick={() => onCancel(succeeded)}
          disabled={deleteLoading || saveLoading}>
          {success ? 'FINISH' : 'CANCEL'}
        </Button>
      </BootModal.Footer>
    </BootModal>
  );
};

export default Modal;
