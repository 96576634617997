import React from 'react';
import './styles.scoped.css';

const ExpensesMenuItem = ({ text, onPress, description, disabled, styles }) => (
  <div
    className="menu-item"
    style={disabled ? { opacity: '0.3', ...styles } : { ...styles }}
    onClick={() => !disabled && onPress()}
  >
    <div className="text-wrapper">
      <span className="title-text">{text}</span>
    </div>
  </div>
);

export default ExpensesMenuItem;
