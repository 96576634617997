import './styles.scoped.css';

const EdgeReportingWindow = ({
  windowContent,
  windowTitle,
  headerStyle,
  containerStyle,
}) => {
  return (
    <div>
      <div className="window-header" style={headerStyle && headerStyle}>
        {windowTitle}
      </div>
      <div
        className="window-container"
        style={containerStyle && containerStyle}
      >
        {windowContent}
      </div>
    </div>
  );
};

export default EdgeReportingWindow;
