import { graphqlOperation, API } from 'aws-amplify';

import * as mutations from '../../../../graphql/mutations';
import { listChecklistBycheckListLevelAccountMasterCodeClientIdTier, listStates } from '../../../../graphql/queries';

export const createDQ = async (
  driver,
  client,
  job,
  tier,
  dqId,
  id,
  createDqUserName,
  branch,
  locationState,
  locationCity,
  checkListLevel,
) => {
  const startDateAsDateTime = new Date(job.startDate);
  const input = {
    id: dqId,
    tier: tier.value,
    tierDescription: tier.description ? tier.description : '',
    customerId: client.clientId,
    name: client.name,
    driverId: driver.driverId,
    driverName: driver.name,
    status: 'Requested',
    jobId: job.jobOrderId ? job.jobOrderId : null,
    jobStartDate: startDateAsDateTime || null,
    createEmail: id,
    createUserFullName: createDqUserName,
    branch: branch?.branchId ? parseInt(branch?.branchId) : null,
    driverQualificationLocationStateId: locationState.stateId,
    driverQualificationLocationCityId: locationCity.cityId,
    accountMasterCode: client.clientAccountMasterCode,
    checkListLevel,
  };
  try {
    const response = await API.graphql(
      graphqlOperation(mutations.createDriverQualification, {
        input,
      }),
    );
    return response;
  } catch (e) {
    console.log(`Create new DQ Error${e}`);
  }
  return undefined;
};

export const getStates = async () => {
  try {
    const response = await API.graphql({
      query: listStates,
      variables: {
        limit: 10000,
      },
    });

    return response.data?.listStates?.items?.length ? response.data?.listStates?.items : [];
  } catch (e) {
    console.log(e);
  }
  return undefined;
};

export const getClientWithChecklist = async info => {
  try {
    const response = await API.graphql({
      query: listChecklistBycheckListLevelAccountMasterCodeClientIdTier,
      variables: {
        checkListLevel: info?.checkListLevel,
        filter: {
          clientId: { eq: info?.clientId },
          accountMasterCode: info?.accountMasterCode ? { eq: info?.accountMasterCode } : undefined,
        },
      },
    });

    return response.data?.listChecklistBycheckListLevelAccountMasterCodeClientIdTier?.items?.[0];
  } catch (e) {
    console.log(e);
  }
  return undefined;
};
