import { API, Auth } from 'aws-amplify';

import { listExpensesReadyToTransfer } from '../../../../graphql/customQueries';
import { updateExpense } from '../operations';

const getUserEmail = async () => {
  const currentUser = await Auth.currentAuthenticatedUser();
  const currentUserObj = currentUser.signInUserSession.idToken.payload;
  if (currentUserObj) {
    const { email } = currentUserObj;
    return email;
  }
  return undefined;
};

export const transferToUltraStaff = async (expense, handleExpensePushed) => {
  let attachments;

  if (['XHS', 'XHSR', 'XFFBHS'].includes(expense?.code)) {
    attachments = [
      {
        attachFileName: expense?.documentName,
        attachFileExtension: expense?.documentName.split('.').pop(),
        attachFileSize: expense?.documentSize,
      },
    ];
  } else {
    attachments = await Promise.all(
      expense?.expenseItem.map(async expenseItem => ({
        attachFileName: expenseItem.documentName,
        attachFileExtension: expenseItem.documentName.split('.').pop(),
        attachFileSize: expenseItem.documentSize,
      })),
    );
  }

  try {
    const userEmail = await getUserEmail();
    const response = await API.post('centerlineportal', 'expense-ultrastaff', {
      body: {
        assignmentId: expense?.assignmentId.toString(),
        transactionType: expense?.code,
        weekEndingDate: expense?.payWeekEnding,
        startDate: expense?.startDate.split('T')[0],
        endDate: expense?.endDate.split('T')[0],
        totalToBeBilled: expense?.totalToBeBilled.toString(),
        branch: expense?.branch.toString(),
        updateUser: userEmail,
        driverId: expense?.driverId.toString(),
        customerId: expense?.customerId.toString(),
        attachments: attachments,
      },
    });
    if (response?.response_code === 200) {
      await updateExpense({
        id: expense.id,
        status: 'Transmitted',
        payWeekEnding: expense?.payWeekEnding,
        updatedBy: userEmail,
      });
    }
  } catch (e) {
    handleExpensePushed({
      id: expense.id,
      status: e?.response?.data?.message || 'Error',

    });
  }
};

export const getExpensesReadyToTransfer = async ({ token, filters, lob }) => {
  try {
    const response = await API.graphql({
      query: listExpensesReadyToTransfer,
      variables: {
        lob: lob,
        ...filters,
        lastEvaluatedKey: token,
      },
    });

    return response.data.listExpensesReadyToBillTransferToUltraStaff;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
