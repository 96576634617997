import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import GeneratedClericalQuote from '../GenerateQuote/Clerical/generatedClericalQuote';

const ClericalQuoteCard = ({ quote }) => {
  const {
    expires,
    startDate,
    region,
    manager,
    custId,
    created,
    quoteName,
    supervisor,
    location,
    quoteUserId,
    admin,
  } = quote;
  return (
    <Container className="p-4" fluid>
      <Row>
        <Col>
          <h4 className="text-primary font-weight-bold">{quoteName}</h4>
        </Col>
      </Row>
      <Row>
        <Col className="col-auto">
          <p>
            Created:
            <span className="p-1 text-muted">{created}</span>
          </p>
        </Col>
        <Col className="col-auto">
          <p>
            Expires:
            <span className="p-1 text-muted">{expires}</span>
          </p>
        </Col>
        <Col className="col-auto">
          <p>
            Generated by:
            <span className="p-1 text-muted">{`${quoteUserId}, ${custId}`}</span>
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex flex-column">
          <h5>Clerical Quote</h5>
          <p>
            Location (State):
            <span className="p-1 text-muted text-right">{location}</span>
          </p>
          {region && region.length && (
            <p>
              Region:
              <span className="p-1 text-muted">{region}</span>
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="d-flex flex-column">
          <h5>Positions</h5>
          <p>
            Manager:
            <span className="p-1 text-muted">{manager}</span>
          </p>
          <p>
            Admin:
            <span className="p-1 text-muted">{admin}</span>
          </p>
          <p>
            Supervisor:
            <span className="p-1 text-muted">{supervisor}</span>
          </p>
          <p className="mb-0">
            Start Date:
            <span className="p-1 text-muted">{startDate}</span>
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <hr className="ml-n3 mr-n3 mt-3 mb-3 border border-dark" />
        </Col>
      </Row>
      <GeneratedClericalQuote className="p-0" showSave={false} quote={quote} />
    </Container>
  );
};

export default ClericalQuoteCard;
