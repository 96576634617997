import React, { useState } from 'react';

import { Auth } from 'aws-amplify';
import { Button, Container, Form } from 'react-bootstrap';
import '../styles.scoped.css';

const ForgotPassword = ({ nextStep, userData }) => {
    const [error, setErrorMessage] = useState('');


    const forgotPassword = (payload) => {
        Auth.forgotPassword(payload).then((user) => {
            if (user) {
                nextStep({
                    ...userData,
                    code: '',
                    CodeDeliveryDetails: user.CodeDeliveryDetails,
                    nextStep: 'ForgotPasswordSubmit',
                    newPassword: '',
                    password: '',
                    user: user,
                    username: payload,
                });
            }
        }).catch((e) => {
            console.log('error', e);
            if (e.code === 'UserLambdaValidationException') {
                signIn(payload);
            }
            if (e.code === 'LimitExceededException') {
                setErrorMessage(e.code);
            }
        })
    }

    return (
        <Container>
            <div className="password-border">
                <img style={{ width: '370px', marginBottom: '35px' }} src={require('../../../assets/cl_login.jpg')} />
                <div className="pt-3">
                    <h1>
                        Forgot your <br />
                        password?
                    </h1>
                    <Form.Group controlId="Username">
                        {error === 'LimitExceededException' && (
                            <p className="border border-danger m-1 p-2 text-danger">Attempt limit exceeded, please try after some time.</p>
                        )}
                        <Form.Label>
                            <div className="mb-2">
                                Enter your Email below and we will send a <br /> message to reset your password
                            </div>
                        </Form.Label>
                        <Form.Group>
                            <Form.Control
                                as="input"
                                name="username"
                                defaultValue={userData?.Username}
                                placeholder="Email"
                                type="email"
                                onChange={() => {
                                    setErrorMessage('');
                                }}
                                onBlur={event => {
                                    nextStep({
                                        ...userData,
                                        username: event.target.value,
                                    });
                                }}
                            />
                        </Form.Group>
                        <Button
                            disabled={!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(userData?.username)}
                            className="w-100 mt-2"
                            onClick={() => {
                                forgotPassword(userData?.username);
                            }}
                        >
                            Reset my password
                        </Button>
                    </Form.Group>
                </div>
            </div>
        </Container >
    );
}

export default ForgotPassword;