/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { SubHeader } from '../../../components';
import { Home } from '@mui/icons-material';
import QuotesTable from '../../../components/QuoteTable';
import './styles.scoped.css';
import { getMyQuotes } from './operations';

const MyQuotes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { subHeaderText } = useSelector(state => state.quoteEngine);
  const { myQuotes, loading } = useSelector(state => state.myQuotes);

  useEffect(() => {
    getMyQuotes(dispatch);
  }, []);

  return (
    <>
      <SubHeader
        text={subHeaderText}
        btnSmall={<Home sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigate('/quote-engine')}
        btnInfo={true}
        emailAddress={process.env.REACT_APP_QUOTE_ENGINE_CONTACT_EMAIL}
        infoText="Need help with your quote?"
      />
      <Container className="wrap">
        <h1 className="title">{'My Quotes'}</h1>
        <QuotesTable data={myQuotes} loading={loading} />
        <Container />
      </Container>
    </>
  );
};

export default MyQuotes;
