import React, { useMemo } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { TablePagination } from '../../../components';
import './styles.scoped.css';

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const columnClassName = 'align-middle text-nowrap';
export const driverSearchColumnsSummary = [
  {
    Header: 'Branch',
    accessor: 'Branch',
    className: columnClassName,
  },
  {
    Header: 'LOB',
    accessor: 'LOB',
    className: columnClassName,
  },
  {
    Header: 'Appl#',
    accessor: 'APPL_ID',
    className: columnClassName,
  },
  {
    Header: 'Appl Last Name',
    accessor: 'Last_Name',
    className: columnClassName,
  },
  {
    Header: 'Appl First Name',
    accessor: 'First_Name',
    className: columnClassName,
  },
  {
    Header: 'Home Phone',
    accessor: 'NUM_HOMEPHONE',
    className: columnClassName,
  },
  {
    Header: 'Email',
    accessor: 'NUM_EMAILADDRESS',
    className: columnClassName,
  },
  {
    Header: 'Address Line 1',
    accessor: 'APPL_ADDRESS1',
    className: columnClassName,
  },
  {
    Header: 'Address Line 2',
    accessor: 'APPL_ADDRESS2',
    className: columnClassName,
  },
  {
    Header: 'City',
    accessor: 'APPL_CITY',
    className: columnClassName,
  },
  {
    Header: 'State',
    accessor: 'APPL_STATE',
    className: columnClassName,
  },
  {
    Header: 'Zip',
    accessor: 'APPL_POSTCODE',
    className: columnClassName,
  },
];
export const driverSearchColumns = [
  {
    Header: 'Branch',
    accessor: 'Branch',
    className: columnClassName,
  },
  {
    Header: 'LOB',
    accessor: 'LOB',
    className: columnClassName,
  },
  {
    Header: 'Appl#',
    accessor: 'APPL_ID',
    className: columnClassName,
  },
  {
    Header: 'Appl Last Name',
    accessor: 'Last_Name',
    className: columnClassName,
  },
  {
    Header: 'Appl First Name',
    accessor: 'First_Name',
    className: columnClassName,
  },
  {
    Header: 'Status',
    accessor: 'APPL_STATUS',
    className: columnClassName,
  },
  {
    Header: 'Home Phone',
    accessor: 'NUM_HOMEPHONE',
    className: columnClassName,
  },
  {
    Header: 'TPC',
    accessor: 'DET_TPC',
    className: columnClassName,
  },
  {
    Header: 'PPC',
    accessor: 'DET_PPC',
    className: columnClassName,
  },
  {
    Header: 'RPC',
    accessor: 'DET_RPC',
    className: columnClassName,
  },
  {
    Header: 'Cell Phone',
    accessor: 'DET_CELLULAR',
    className: columnClassName,
  },
  {
    Header: 'Work Phone',
    accessor: 'NUM_WORKPHONE',
    className: columnClassName,
  },
  {
    Header: 'Email',
    accessor: 'NUM_EMAILADDRESS',
    className: columnClassName,
  },
  {
    Header: 'Address Line 1',
    accessor: 'APPL_ADDRESS1',
    className: columnClassName,
  },
  {
    Header: 'Address Line 2',
    accessor: 'APPL_ADDRESS2',
    className: columnClassName,
  },
  {
    Header: 'City',
    accessor: 'APPL_CITY',
    className: columnClassName,
  },
  {
    Header: 'State',
    accessor: 'APPL_STATE',
    className: columnClassName,
  },
  {
    Header: 'Zip',
    accessor: 'APPL_POSTCODE',
    className: columnClassName,
  },
  {
    Header: 'SS#',
    accessor: 'SSN',
    className: columnClassName,
  },
  {
    Header: 'DOB',
    accessor: 'DOB',
    className: columnClassName,
  },
  {
    Header: 'Hire Date',
    accessor: 'HireDate',
    className: columnClassName,
  },
  {
    Header: 'Rehire Date',
    accessor: 'ReHireDate',
    className: columnClassName,
  },
  {
    Header: 'Termination Date',
    accessor: 'TerminationDate',
    className: columnClassName,
  },
  {
    Header: 'Available Date',
    accessor: 'AvailableDate',
    className: columnClassName,
  },
  {
    Header: 'Referral',
    accessor: 'Referral',
    className: columnClassName,
  },
  {
    Header: 'Date Entered',
    accessor: 'Date_Entered',
    className: columnClassName,
  },
  {
    Header: 'Date Last Updated',
    accessor: 'Date_LastUpdated',
    className: columnClassName,
  },
];

const DriverSearchTable = ({ data, loading, summaryOrDetail }) => {
  const memoData = useMemo(() => data, [data]);

  const driverSearchColumnsToUse = useMemo(
    () =>
      summaryOrDetail === 'Summary'
        ? driverSearchColumnsSummary
        : driverSearchColumns,
    [summaryOrDetail],
  );

  const tableInstance = useTable(
    {
      columns: driverSearchColumnsToUse,
      data: memoData,
      initialState: {
        pageSize: 200,
        sortBy: [
          {
            id: 'CreatedAt',
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>
          {column.canSort
            ? getSortedIcon(column.isSorted, column.isSortedDesc)
            : null}
        </span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}
      >
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading && <Spinner animation="border" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DriverSearchTable;
