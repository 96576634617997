import React, { useMemo } from 'react';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import './styles.scoped.css';
import { TablePagination } from '../../../components';

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const columnClassName = 'align-middle text-nowrap';
export const checkColumns = [
  {
    Header: 'Driver Number',
    accessor: 'Employee_ID',
    className: columnClassName,
  },
  {
    Header: 'Driver Name',
    accessor: 'Empl_Name',
    className: columnClassName,
  },
  {
    Header: 'Period End Date',
    accessor: 'Period_Ending_Date',
    className: columnClassName,
  },
  {
    Header: 'Branch',
    accessor: 'Branch',
    className: columnClassName,
  },
  {
    Header: 'Branch Name',
    accessor: 'Branch_Desc',
    className: columnClassName,
  },
  {
    Header: 'Customer Name',
    accessor: 'Client_Name',
    className: columnClassName,
  },
  {
    Header: 'Customer Number',
    accessor: 'Client_ID',
    className: columnClassName,
  },
  {
    Header: 'Job',
    accessor: 'Job_ID',
    className: columnClassName,
  },
  {
    Header: 'LOB',
    accessor: 'LineOfBusiness',
    className: columnClassName,
  },
  {
    Header: 'Check #',
    accessor: 'check_ID',
    className: columnClassName,
  },
  {
    Header: 'Check Date',
    accessor: 'check_Date',
    className: columnClassName,
  },
  {
    Header: 'Gross Amount',
    accessor: 'Gross_Amount',
    className: columnClassName,
  },
  {
    Header: 'Net Amount',
    accessor: 'Net_Amount',
    className: columnClassName,
  },
  {
    Header: 'Pay Description',
    accessor: 'Pay_Description',
    className: columnClassName,
  },
  {
    Header: 'Hours',
    accessor: 'Pay_Hours',
    className: columnClassName,
  },
  {
    Header: 'Rate',
    accessor: 'Pay_Rate',
    className: columnClassName,
  },
  {
    Header: 'Amount',
    accessor: 'Amount',
    className: columnClassName,
    Cell: ({ row }) => row.original.Gross_Amount,
  },
  {
    Header: 'Tax Auth',
    accessor: 'Tax_Code',
    className: columnClassName,
  },
  {
    Header: 'Tax Jur',
    accessor: 'Tax_Type',
    className: columnClassName,
  },
  {
    Header: 'Taxable Wages',
    accessor: 'Taxable_Wages',
    className: columnClassName,
  },
  {
    Header: 'Tax Amount',
    accessor: 'Tax_Amount',
    className: columnClassName,
  },
  {
    Header: 'Ded Description',
    accessor: 'Ded_Description',
    className: columnClassName,
  },
  {
    Header: 'Ded Amount',
    accessor: 'Ded_Amount',
    className: columnClassName,
  },
];

const PayrollCheckRegisterTable = ({ data, loading }) => {
  const memoData = useMemo(() => data, [data]);

  const tableInstance = useTable(
    {
      columns: checkColumns,
      data: memoData,
      initialState: {
        pageSize: 200,
        sortBy: [
          {
            id: 'CreatedAt',
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>
          {column.canSort
            ? getSortedIcon(column.isSorted, column.isSortedDesc)
            : null}
        </span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}
      >
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading && <Spinner animation="border" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PayrollCheckRegisterTable;
