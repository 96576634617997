import { Container, Row, Col, Form } from 'react-bootstrap';
import React, { useState } from 'react';
import { getCurrencyString, getDaysBetween } from '../../../../utils/utilities';
import ClericalCostField from '../clericalCostField';
import SaveDownloadForm from '../saveDownloadForm';
import { saveQuote } from '../operations';
import { useDispatch } from 'react-redux';

const GeneratedClericalQuote = ({ className, quote, showSave }) => {
  const {
    expires,
    quoteId,
    totalCost,
    totalCostAdmin,
    totalCostManager,
    totalCostSupervisor,
    annualBillingCostManager,
    annualBillingCostSupervisor,
    managerWages,
    adminWages,
    supervisorWages,
    managerMultiplier,
    adminMultiplier,
    supervisorMultiplier,
  } = quote;
  const daysValid = getDaysBetween(new Date(), new Date(expires));
  const [isSaved, setIsSaved] = useState(!showSave);
  const dispatch = useDispatch();

  const onSaveClicked = async quoteName => {
    const success = await saveQuote(dispatch, quoteName, quoteId);
    setIsSaved(success);
    return success;
  };

  return (
    <Container className={className} fluid>
      <Row>
        <Col>
          <h5>Quote*</h5>
        </Col>
        {isSaved && (
          <Col className="text-sm-right">
            <h5>{`#${quoteId}`}</h5>
          </Col>
        )}
      </Row>
      <Row>
        <Col className="d-flex flex-column">
          {totalCostManager > 0 && (
            <ClericalCostField
              label="Manager"
              costLabel="Total Cost / Week"
              payLabel="Annual Pay"
              billLabel="Annual Billing"
              value={{
                cost: totalCostManager,
                pay: managerWages,
                bill: annualBillingCostManager,
                multiplier: managerMultiplier,
              }}
            />
          )}
          {totalCostAdmin > 0 && (
            <ClericalCostField
              label="Admin"
              costLabel="Total Cost / Hour"
              payLabel="Hourly Pay"
              value={{
                cost: totalCostAdmin,
                pay: adminWages,
                multiplier: adminMultiplier,
              }}
            />
          )}
          {totalCostSupervisor > 0 && (
            <ClericalCostField
              label="Supervisor"
              costLabel="Total Cost / Week"
              payLabel="Annual Pay"
              billLabel="Annual Billing"
              value={{
                cost: totalCostSupervisor,
                pay: supervisorWages,
                bill: annualBillingCostSupervisor,
                multiplier: supervisorMultiplier,
              }}
            />
          )}
          <Form>
            <Form.Group>
              <Form.Label className="h5">
                Total Cost (Annual Estimate)
              </Form.Label>
              <Form.Text muted className="h6">
                {getCurrencyString(totalCost)}
              </Form.Text>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      {showSave && (
        <Row>
          <Col>
            <SaveDownloadForm
              quote={quote}
              onSaveClicked={onSaveClicked}
              isSaved={isSaved}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <span className="small">
            {`* Quote is valid for ${daysValid} days. 
            If ${daysValid} days crosses a calendar year, 
            this is an Estimate only, not a firm Quote.`}
          </span>
          <br />
          <span className="small">
            ** Quotes must be saved and submitted as PDF to be considered valid.
            Screen prints will not be accepted.
          </span>
        </Col>
      </Row>
    </Container>
  );
};

export default GeneratedClericalQuote;
