import React, { useEffect, useMemo, useState } from 'react';

import { API } from 'aws-amplify';
import { Button, Container, Col, Form, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { MdHome } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import ChecklistDocuments from './ChecklistDocuments';
import DQInfo from './DQInfo';
import NewDocument from './NewDocument';
import { dqSelectRest, getDQByID, updateDQDocName, updateDQOwner, updateDQStatus } from './operations';
import selectDocumentsAndAutoSelect from './selectDocumentsAndAutoSelect';
import { Breadcrumbs, ModalConfirmation, SubHeader } from '../../../components';

import './styles.scoped.css';

function tabsBorderRadius() {
  // eslint-disable-next-line no-undef
  document.querySelectorAll('.rounded-top-left').forEach(elem => {
    elem.style.borderTopLeftRadius = '15px';
  });
  // eslint-disable-next-line no-undef
  document.querySelectorAll('.rounded-top-right').forEach(elem => {
    elem.style.borderTopRightRadius = '15px';
  });
}

const AssembleNewDQF = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { id: userId } = useSelector(state => state.admin.user);
  const { tierData } = useSelector(state => state.newRequest);

  const [dqGenearlInfo, setDqGeneralInfo] = useState({});
  const [dq, setDQ] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const [loadingAutoselect, setLoadingAutoselect] = useState(true);
  const [loadingAttachToPDF, setLoadingAttachToPDF] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [error, setError] = useState({ show: false, text: '' });
  const [checklistError, setChecklistError] = useState(false);
  const [types, setTypes] = useState([]);
  const [sources, setSources] = useState([]);
  const [categories, setCategories] = useState([]);

  const rows = useMemo(
    () => dq?.driverQualificationChecklistDocuments?.items?.sort((a, b) => b.order - a.order) || [],
    [dq],
  );

  const handleOwnerClick = async () => {
    const success = await updateDQOwner(id, userId);
    if (success) {
      if (dq?.status === 'Requested') {
        setDQ({
          ...dq,
          dqSpecialistEmail: userId,
          status: 'Assembling',
        });
        await updateDQStatus(id, 'Assembling');
      } else {
        setDQ({
          ...dq,
          dqSpecialistEmail: userId,
        });
      }
    } else {
      console.log('Error with setting DQ owner on backend');
    }
  };

  const onAttachDQF = async () => {
    let localError = false;

    setLoadingAttachToPDF(true);
    rows.forEach(item => {
      if (item.documentName === null || item.documentName === undefined) {
        localError = true;
        setError({
          text: 'Some documents are not selected',
          show: true,
        });
      }
    });

    if (!localError) {
      try {
        const response = await dqSelectRest(
          [
            {
              documentName: `public/dqf-assembling/${id}.pdf`,
              IncludePages: 'All',
            },
          ],
          `public/dqf/${id}.pdf`,
        );
        if (response?.response_code === 200) {
          await updateDQDocName(id, `${id}.pdf`);
          setSuccessModal(true);
        }
      } catch (e) {
        console.log('error', e);
        setError({
          text: `Something went wrong: ${e.toString()}`,
          show: true,
        });
      } finally {
        setLoadingAttachToPDF(false);
      }
    }
  };

  useEffect(() => {
    if (id !== undefined && types.length && sources.length && categories.length) {
      getDQByID(id).then(newDQ => {
        setDqGeneralInfo(newDQ);
        selectDocumentsAndAutoSelect({
          id,
          dq: newDQ,
          setError,
          tierData,
          setDQ,
          setLoadingAutoselect,
          types,
          sources,
          categories,
        });
      });
    }
  }, [id, types]);

  useEffect(() => {
    tabsBorderRadius();
  }, []);

  useEffect(() => {
    setDqGeneralInfo(dq);
  }, [dq]);

  return (
    <div style={{ width: '90%' }} className="m-auto">
      <SubHeader
        text="Centerline Driver Qualification"
        btnSmall={<MdHome size={24} style={{ color: '#fff' }} />}
        btnLarge="View DQF"
        btnLargeStyle={{ fontWeight: 700 }}
        smallAction={() => navigate('../driver-qualification', { replace: true })}
        largeAction={() => navigate(`../driver-qualification/corporatedq/${dq?.id}`, { replace: true })}
        btnInfo
        emailAddress={process.env.REACT_APP_DQF_CONTACT_EMAIL}
        infoText="Need help with your driver qualification?"
      />
      <Breadcrumbs
        leftTitle="DQF Home"
        midItemAction={() => navigate(`../driver-qualification/corporatedq/${dq?.id}`, { replace: true })}
        middleTitle={id && `File ${id}\u00A0`}
        rightTitle="Assembling"
        route="/driver-qualification"
      />
      <Container className="vh-100 pb-5 pt-3" fluid>
        <Tabs fill activeKey="pdf">
          <Tab
            eventKey="pdf"
            title="Assemble New DQF"
            tabClassName="border border-primary rounded-top-left rounded-top-right bg-primary text-white active">
            <div className="h-100 p-4 border border-primary">
              <Form>
                <Form.Group>
                  <DQInfo dq={dqGenearlInfo} handleOwnerClick={handleOwnerClick} />
                  <NewDocument id={id} dq={dq} loadingAutoselect={loadingAutoselect} />
                  <ChecklistDocuments
                    id={id}
                    rows={rows}
                    dataLoading={dataLoading}
                    loadingAutoselect={loadingAutoselect}
                    setDataLoading={setDataLoading}
                    types={types}
                    setTypes={setTypes}
                    sources={sources}
                    setSources={setSources}
                    categories={categories}
                    setCategories={setCategories}
                  />
                  <Row className="mt-5">
                    <Col className="d-flex justify-content-center">
                      <Button
                        className="w-75"
                        onClick={() => {
                          onAttachDQF();
                        }}
                        disabled={
                          dataLoading || loadingAttachToPDF || loadingAutoselect || rows?.find(row => !row.documentName)
                        }>
                        {dataLoading || loadingAttachToPDF || loadingAutoselect ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          'Attach to DQF'
                        )}
                      </Button>
                    </Col>
                  </Row>
                  <Row className="p-4 d-flex justify-content-center">
                    {successModal && (
                      <ModalConfirmation
                        isOpen
                        title="Success"
                        primaryButtonTitle="Ok"
                        secondaryButtonTitle={null}
                        body="Checklist Documents successfully attached."
                        onClick={() => navigate(`../driver-qualification/corporatedq/${id}`)}
                        primaryButton
                      />
                    )}
                    {error.show && (
                      <ModalConfirmation
                        title="Error"
                        body={error.text}
                        primaryButtonTitle="Close"
                        onClick={() => {
                          setError({
                            ...error,
                            text: '',
                            show: false,
                          });
                        }}
                        isOpen
                        primaryButton
                      />
                    )}
                    {checklistError && (
                      <ModalConfirmation
                        title="Error"
                        body="DQF not supported for Auto Assembly. Create a new DQF and try again"
                        primaryButtonTitle="Close"
                        onClick={() => {
                          setChecklistError(false);
                          navigate('../driver-qualification', { replace: true });
                        }}
                        isOpen
                        primaryButton
                      />
                    )}
                  </Row>
                </Form.Group>
              </Form>
            </div>
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
};

export default AssembleNewDQF;
