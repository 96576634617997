import React, { useEffect, useRef, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import { Row, Container, Col, Button } from 'react-bootstrap';
import { FaFilePdf } from 'react-icons/fa';
import { MdHome } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { createQuestion, fetchUser, getDQByID, getPDF, getUserInfo, updateDQStatus } from './operations';
import { ModalConfirmation, SubHeader } from '../../../components';
import useIsMobileScreen from '../../../hooks/useCheckIsMobile';
import { formatIsoToDate } from '../../../utils';
import './styles.scoped.css';
import { getSkillsFromDriver } from '../CorporateDQ/operations';
import DQQuestionsTable from '../DQQuestionsTable';

const ViewDQF = () => {
  const isMobile = useIsMobileScreen();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { route } = useAuthenticator(context => [context.route]);
  const [driverSkills, setDriverSkills] = useState([]);

  const { dq, pdfError, dqPdfDocument, error } = useSelector(state => state.viewDQF);
  const [comment, setComment] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const updateAction = useRef('');

  const { id } = useParams();

  const statuses = ['Approved', 'Client Review', 'Information Requested', 'Rejected', 'Sent'];

  const getSkills = async driverId => {
    const driver = await getSkillsFromDriver(driverId);
    if (driver?.driverSkills?.length) {
      setDriverSkills(driver?.driverSkills);
    }
  };

  useEffect(() => {
    if (dq?.driverId) {
      getSkills(dq.driverId);
    }
  }, [dq?.driverId]);

  const getAndSetUserInfo = async () => {
    const email = await (await Auth.currentSession()).getIdToken().payload.email;
    const user = await getUserInfo(email);
    setCurrentUser(user);
  };

  const handleCheckCurrentSession = async () => {
    navigate(`/verify-pw-less/dqf?email=${dq.dqClientApproverUser}&dqf=${id}`);
  };

  useEffect(() => {
    if (route !== 'authenticated' && currentUser) {
      handleCheckCurrentSession();
    }
    if (dq && currentUser) {
      if (!currentUser?.customerId || currentUser?.customerId !== dq?.customerId) {
        navigate('/not-found');
      }
    }
  }, [currentUser]);

  useEffect(() => {
    getAndSetUserInfo();
    if (id && route === 'authenticated') getDQ(id);
  }, [id]);

  useEffect(() => {
    if (error || (currentUser && currentUser?.status !== 'Active') || (dq && !statuses.includes(dq?.status))) {
      navigate('/not-found');
    }
  }, [dq, currentUser, error]);

  useEffect(() => {
    if (dq) {
      getPDF(dispatch, `${dq?.id}.pdf`);
      if (dq?.id && dq?.status === 'Sent') {
        updateStatusToClientReview();
      }
    }
  }, [dq]);

  const updateStatusToClientReview = async () => {
    const email = await (await Auth.currentSession()).getIdToken().payload.email;
    const user = await fetchUser(email);

    if (email && user) {
      try {
        await updateDQStatus(dispatch, dq?.id, 'Client Review', email, user);
      } catch (error) {
        console.log('error update dq status', error);
      }
    }
  };

  const getDQ = async id => {
    await getDQByID(dispatch, id);
  };

  const getState = () => {
    if (updateAction.current.includes('approve')) {
      return 'Approved';
    }
    if (updateAction.current.includes('reject')) {
      return 'Rejected';
    }
    return 'Information Requested';
  };

  const actionsDisabled = approving =>
    ['approved', 'rejected', 'request'].some(
      element => dq?.status.toLowerCase().includes(element.toLowerCase()) || (!comment.length && !approving),
    );

  const handleDQRequest = async () => {
    const status = getState();
    const email = await (await Auth.currentSession()).getIdToken().payload.email;
    const user = await fetchUser(email);
    const response = await updateDQStatus(dispatch, dq?.id, status, email, user);
    if (response && comment && !status.includes('approve')) {
      const respQuestion = await createQuestion(dispatch, id, email, comment, user, status, dq.dqOwnerFullName);
      if (respQuestion) {
        setComment('');
      }
    }

    // if (!isMobile) {
    //   navigate('/driver-qualification', {
    //     replace: true,
    //   });
    // }
  };

  const Field = ({ label, data }) => (
    <div className="info-container">
      <div className="label">{label}</div>
      <div className="data">{data}</div>
    </div>
  );

  const MobileField = ({ label, data }) => (
    <div className="mobile-info-container">
      <div className="mobile-label">{label}</div>
      <div className="mobile-data">{data}</div>
    </div>
  );

  const getAllSkills = () => {
    let skills = '';
    driverSkills?.forEach(item => {
      skills = skills.concat(`${item?.skillName}: ${item?.skillDescription}, `);
    });
    return skills.substring(0, skills.length - 2);
  };

  return (
    <div style={{ paddingBottom: 100 }}>
      {dq && currentUser?.status === 'Active' && route === 'authenticated' ? (
        <>
          <SubHeader
            text="Centerline Driver Qualification File"
            btnSmall={<MdHome size={28} color="#FFF" />}
            smallAction={() => navigate('/', { replace: true })}
          />
          <div className={`wrap ${isMobile && 'mobile-wrap'}`}>
            <Container className={`title-container ${isMobile && 'mobile-title-container'}`}>
              <h1 className={`title ${isMobile && 'mobile-title'}`}>View DQF</h1>
            </Container>
            <div className="inner-wrap">
              <div className="client-data-row">
                <div className="client-name" style={isMobile ? { fontSize: '12px', width: '50%', flex: 'auto' } : {}}>
                  {dq?.name}
                </div>
                <div className="dqf-code" style={isMobile ? { fontSize: '12px', width: '50%', flex: 'auto' } : {}}>
                  {dq?.id}
                </div>
              </div>
              {!isMobile && (
                <>
                  <div className="info-row">
                    <Field label="Driver" data={dq?.driverName} />
                    <Field label="Job Order" data={dq?.jobId} />
                    <Field label="Status" data={dq?.status} />
                  </div>
                  <div className="info-row">
                    <Field label="Generated by" data={dq?.createUserFullName} />
                    <Field label="Client Approver" data={dq?.dqClientApproverUserFullName} />
                    <Field label="Created" data={formatIsoToDate(dq?.createdAt)} />
                  </div>
                  <div className="info-row">
                    <Field label="City" data={dq?.locationCity?.name} />
                    <Field label="State" data={dq?.locationState?.name} />
                    <Field label="" data="" />
                  </div>

                  <Row className="info-row">
                    <Col className="label">Summary</Col>
                  </Row>
                  {dq?.driverSkillSummary ? (
                    <Row className="info-row">
                      <textarea
                        defaultValue={dq?.driverSkillSummary}
                        className="summary-input gray-text"
                        style={{
                          pointerEvents: 'none',
                          paddingLeft: '16px',
                          border: 'none',
                        }}
                      />
                    </Row>
                  ) : null}

                  <Row className="info-row">
                    <Col className="label">Skills</Col>
                  </Row>
                  <Row className="info-row">
                    <Col className="scroll-list">
                      <div className="gray-text" style={{ paddingLeft: '16px', fontSize: '16px' }}>
                        {getAllSkills()}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {isMobile && (
                <>
                  <div className="info-row">
                    <MobileField label="Driver" data={dq?.driverName} />
                    <MobileField label="Job Order" data={dq?.jobId} />
                  </div>
                  <div className="info-row">
                    <MobileField label="Status" data={dq?.status} />
                    <MobileField label="Generated by" data={dq?.createUserFullName} />
                  </div>
                  <div className="info-row">
                    <MobileField label="Client Approver" data={dq?.dqClientApproverUserFullName} />
                    <MobileField label="Created" data={formatIsoToDate(dq?.createdAt)} />
                  </div>
                  <div className="info-row">
                    <MobileField label="City" data={dq?.locationCity?.name} />
                    <MobileField label="State" data={dq?.locationState?.name} />
                  </div>
                  <Row className="info-row">
                    <Col className="mobile-label">Summary</Col>
                  </Row>

                  {dq?.driverSkillSummary ? (
                    <Row className="info-row">
                      <textarea
                        defaultValue={dq?.driverSkillSummary}
                        className="summary-input gray-text"
                        style={{
                          pointerEvents: 'none',
                          paddingLeft: '30px',
                          border: 'none',
                        }}
                      />
                    </Row>
                  ) : null}

                  <Row className="info-row">
                    <Col className="mobile-label">Skills</Col>
                  </Row>
                  <Row className="info-row">
                    <Col className="scroll-list">
                      <div className="gray-text" style={{ paddingLeft: '16px', fontSize: '12px' }}>
                        {getAllSkills()}
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              <div className="info-row">
                <div
                  style={isMobile ? { fontSize: '14px', marginBottom: '15px' } : { marginBottom: '20px' }}
                  className="label">
                  Driver Qualification Document
                </div>
              </div>

              {pdfError && (
                <div className="p-2" style={{ color: '#c8102e', fontWeight: 500 }}>
                  There is no PDF to show.
                </div>
              )}
              {!pdfError &&
                dqPdfDocument &&
                (isMobile ? (
                  <span>
                    <a href={dqPdfDocument} data-ajax="false" target="_blank" rel="noreferrer">
                      <FaFilePdf size={60} /> View Document
                    </a>
                  </span>
                ) : (
                  <iframe src={dqPdfDocument} height="600" width="100%" />
                ))}
              <div className="info-row">
                <div className="info-container">
                  <div style={isMobile ? { fontSize: '14px' } : {}} className="label">
                    Previous Comments
                  </div>
                </div>
              </div>
              <div className="info-row">
                <div className="comments-container">
                  {!dq?.questions?.items.length ? <div>No comments found </div> : null}
                  {!isMobile && dq?.questions?.items.length ? (
                    <DQQuestionsTable data={dq?.questions?.items ? dq?.questions?.items : []} />
                  ) : null}
                  {isMobile &&
                    dq?.questions?.items.map(item => (
                      <div
                        style={
                          isMobile
                            ? {
                                fontSize: '14px',
                                marginBottom: 15,
                              }
                            : {
                                marginBottom: 15,
                              }
                        }
                        className="data">
                        {`${item?.createUserFullName} [${dayjs(item?.createdAt?.substring(0, 10)).format(
                          'MM/DD/YYYY h:mm a',
                        )}] : ${item?.text}`}
                      </div>
                    ))}
                </div>
              </div>
              <div style={isMobile ? { fontSize: '14px' } : {}} className="info-row mt-4">
                <div style={isMobile ? { fontSize: '14px' } : {}} className="label">
                  Comments should be entered when selecting Reject or Request More Information
                </div>
              </div>
              <textarea
                value={comment}
                className={`comments-input ${isMobile && 'mobile'}`}
                onChange={event => setComment(event.target.value)}
              />
              <div className="buttons-container">
                <Button
                  disabled={actionsDisabled({ approving: true })}
                  style={isMobile ? { width: 'auto', fontSize: '12px' } : {}}
                  className="button"
                  onClick={() => {
                    updateAction.current = 'approve';
                    setShowModalConfirmation(true);
                  }}>
                  Approve
                </Button>
                <Button
                  disabled={actionsDisabled()}
                  style={isMobile ? { width: 'auto', fontSize: '12px' } : {}}
                  className="button"
                  onClick={() => {
                    updateAction.current = 'request more information for';
                    setShowModalConfirmation(true);
                  }}>
                  Request More Info
                </Button>
                <Button
                  disabled={actionsDisabled()}
                  style={isMobile ? { width: 'auto', fontSize: '12px' } : {}}
                  className="button"
                  onClick={() => {
                    updateAction.current = 'reject';
                    setShowModalConfirmation(true);
                  }}>
                  Reject
                </Button>
              </div>
              {showModalConfirmation && (
                <ModalConfirmation
                  title="Confirmation Required:"
                  body={`Are you sure you want to ${updateAction.current} this DQF?`}
                  primaryButtonTitle="Yes"
                  primaryButton
                  optionalClose
                  secondaryButtonTitle="No"
                  onClick={() => {
                    handleDQRequest();
                    setShowModalConfirmation(false);
                  }}
                  secondaryOnClick={() => {
                    setShowModalConfirmation(false);
                  }}
                  isOpen={showModalConfirmation}
                />
              )}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ViewDQF;
