import React, { useMemo } from 'react';

import { Col, Container, Row, Table } from 'react-bootstrap';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { formatIsoToDate } from '../../utils';
import TableFilter from '../TableFilter';
import TablePagination from '../TablePagination';

import './styles.scoped.css';

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortUp /> : <FaSortDown />;
  }
  return iconProp;
}

const DriverQualificationListTable = ({ data, Route }) => {
  const columnClassName = 'align-middle text-nowrap';
  const columns = useMemo(
    () => [
      {
        Header: 'Number',
        accessor: 'id',
        className: columnClassName,
        Cell: instance => <Link to={`${Route}/${instance.value}`}>{instance.value}</Link>,
      },
      {
        Header: 'Driver',
        accessor: 'driverName',
        className: columnClassName,
      },
      {
        Header: 'City',
        accessor: 'locationCity',
        className: columnClassName,
        Cell: instance => `${typeof instance.value === 'string' ? instance.value : instance.value?.name}`,
      },
      {
        Header: 'State',
        accessor: 'locationState',
        className: columnClassName,
        Cell: instance => `${typeof instance.value === 'string' ? instance.value : instance.value?.name}`,
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: columnClassName,
      },
      {
        Header: 'DQF Specialist',
        accessor: 'dqOwnerFullName',
        className: columnClassName,
      },
      {
        Header: 'Client Approver',
        accessor: 'dqClientApproverUserFullName',
        className: columnClassName,
      },
      {
        Header: 'Client Name',
        accessor: 'name',
        className: columnClassName,
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        className: columnClassName,
        Cell: instance => `${formatIsoToDate(instance.value)}`,
      },
      {
        Header: 'Updated',
        accessor: 'updatedAt',
        className: columnClassName,
        Cell: instance => `${formatIsoToDate(instance.value)}`,
      },
    ],
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'id',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setFilter,
    setGlobalFilter,
  } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>{column.canSort ? getSortedIcon(column.isSortedDesc, column.isSorted) : null}</span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}>
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  function filter(event, columnName) {
    const filterColumn = columns.find(column => column.Header === columnName);
    if (filterColumn) {
      setFilter(filterColumn.accessor, event.target.value);
    } else {
      setGlobalFilter(event.target.value);
    }
  }

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col md={6} className="mr-auto">
          <TableFilter className="mb-3" columns={columns} onSearchChange={filter} />
        </Col>
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default DriverQualificationListTable;
