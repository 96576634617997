import React, { useEffect, useState } from "react";

import dayjs from 'dayjs';
import { deleteNationalPushNotification, getCountForPushNotification, getNationalPushNotification, updateNationalPushNotification, sendPushNotification } from "./operations";
import { useSelector } from 'react-redux';
import { Breadcrumbs, ModalConfirmation, SubHeader, Footer } from '../../../components'
import { getList } from './operations';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Home } from '@mui/icons-material';
import FormContent from './FormContent';
import "./styles.scoped.css";

const editNationalPushNotification = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const user = useSelector(state => state.admin?.user);
    const [recipientStatuses, setRecipientStatuses] = useState([]);
    const [isLoadingRecipientStatus, setIsLoadingRecipientStatus] = useState(false);
    const [messageCharCount, setMessageCharCount] = useState(0);
    const messageCharLimit = 150;
    const [subjectCharCount, setSubjectCharCount] = useState(0);
    const subjectCharLimit = 50;
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showDriversCountModal, setShowDriversCountModal] = useState(false);
    const [isLoadingNotifiedNotifications, setIsLoadingNotifiedNotifications] = useState(false);
    const [driversToBeNotified, setDriversToBeNotifed] = useState(0);
    const [driversNotified, setDriversNotifed] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [form, setForm] = useState({
        type: 'National',
        subject: '',
        message: '',
        url: '',
        status: 'Pending',
        lob: null,
        branch: null,
        recipientStatus: '',
        approvedBy: user?.name,
        approvedAt: null,
        createdBy: user?.name,
        createdAt: dayjs().utc().format(),
        sentOn: null,
    });

    const utc = require('dayjs/plugin/utc')
    dayjs.extend(utc)

    const handleGetRecipientStatuses = async () => {
        setIsLoadingRecipientStatus(true);
        try {
            const responses = await getList('driver-status');
            setRecipientStatuses(responses);
        } catch (e) {
            console.log('e')
        }
        setIsLoadingRecipientStatus(false);
    }

    const customStyles = width => ({
        control: provided => ({
            ...provided,
            width: width,
            height: '30px',
            minWidth: '300px',
        }),
        menuList: provided => ({
            ...provided,
            maxHeight: '200px',
        }),
        menu: provided => ({
            ...provided,
            zIndex: 100000,
        }),
        multiValue: (provided, state) => ({
            ...provided,
            backgroundColor: '#da0f2e',
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
            color: '#ffffff',
        }),
        multiValueRemove: (provided, state) => ({
            ...provided,
            color: '#ffffff',
        }),
    });

    const getNotification = async (id) => {
        try {
            const response = await getNationalPushNotification(id);
            setForm({
                ...form,
                id: response?.id,
                type: response?.type,
                subject: response?.subject,
                message: response?.message,
                url: response?.url,
                status: response?.status,
                lob: response?.lob,
                branch: response?.branch,
                recipientStatus: response?.recipientStatus,
                approvedBy: response?.name,
                approvedAt: response?.approvedAt,
                sentOn: response?.sentOn,
                createdBy: response?.createdBy,
                createdAt: response?.createdAt
            });
        } catch (e) {
            console.log('e', e);
        }
    }

    const removeNationalPushNotification = async () => {
        console.log('id', id)
        try {
            const response = await deleteNationalPushNotification(id);
            return response;
        } catch (e) {
            console.log('e', e);
        }
    }

    const fieldsAreValid = () => {
        let valid = true;
        let formErrorsCopy = { ...errors };

        const requiredFields = [
            'message',
            'recipientStatus',
            'subject',
        ];

        if (!form?.recipientStatus) {
            formErrorsCopy = {
                ...formErrorsCopy,
                ['recipientStatus']: 'Please provide a valid value.',
            };
            valid = false;
        } else {
            formErrorsCopy = {
                ...formErrorsCopy,
                ['recipientStatus']: '',
            };
            valid = true;
        }

        requiredFields.forEach(field => {
            if (!form[field] || form[field] === '') {
                formErrorsCopy = {
                    ...formErrorsCopy,
                    [field]: 'Please provide a valid value.',
                };
                valid = false;
            }
        });

        setErrors(formErrorsCopy);

        return valid;
    }

    const confirmPushNotification = async () => {

        setIsLoading(true);

        if (fieldsAreValid()) {

            if (form?.status === 'Pending') {
                try {
                    await updateNationalPushNotification(form);
                    navigate(-1);
                } catch (e) {
                    setShowErrorModal(true);
                    console.log('error', e);
                }
            }

            if (form?.status === 'Sent') {
                try {
                    const response = await getDriversToBeNotifiedForPushNotifications(form);
                    return response;
                } catch (e) {
                    console.log('error', e);
                }
            }
        };

        setIsLoading(false);

    }

    const sendBulkPushNotification = async (form) => {

        setIsLoadingNotifiedNotifications(true);

        try {
            const response = await sendPushNotification(form);

            if (response?.data?.publishPushNotification?.responseCode === 200 && response?.data?.publishPushNotification?.driverCount > 0) {
                setShowDriversCountModal(false);
                setDriversNotifed(response?.data?.publishPushNotification?.driverCount);
                setIsLoadingNotifiedNotifications(false);
                setShowConfirmationModal(true);
                await updateNationalPushNotification(form);
            }

            if (response?.data?.publishPushNotification?.responseCode === 404 && response?.data?.publishPushNotification?.responseMessage !== 'Success') {
                setDriversToBeNotifed(response?.data?.publishPushNotification?.driverCount);
                setShowDriversCountModal(true);
                setIsLoadingNotifiedNotifications(false);
                setForm({
                    ...form,
                    status: 'Pending',
                });
            }

            if (response?.errors !== undefined && response?.errors[0]?.errorType === 'Lambda:ExecutionTimeoutException') {
                setIsLoading(false);
                setIsLoadingNotifiedNotifications(false);
                setShowConfirmationModal(false);
                setShowExecutionTimeoutException(true);
            }
            return response;
        } catch (e) {
            console.log('error', e)
        }
    }

    const getDriversToBeNotifiedForPushNotifications = async (form) => {
        try {
            const response = await getCountForPushNotification(form);
            if (response?.responseCode === 200) {
                setIsLoading(false);
                setDriversToBeNotifed(response?.driverCount);
                setShowDriversCountModal(true);
            }

            if (response?.responseCode === 404) {
                setIsLoading(false);
                setDriversToBeNotifed(response?.driverCount);
                setShowDriversCountModal(true);
                setForm({
                    ...form,
                    status: 'Pending',
                });
            }
            return response;
        } catch (e) {
            setShowErrorModal(true);
            console.log('error', e);
        }
    }

    const handleCheckbox = (e) => {
        if (e.target.checked === true) {
            setForm({
                ...form,
                status: 'Sent',
                sentOn: dayjs().utc().format(),
                approvedBy: user.name,
                approvedAt: dayjs().utc().format(),
            });
        }
        else {
            setForm({
                ...form,
                status: 'Pending'
            });
        }
    }

    const setField = (fields, value) => {
        if (Array.isArray(fields)) {
            const itemsToAdd = {};
            const newErrors = { ...errors };
            fields.forEach(field => {
                itemsToAdd[(field?.fieldName)] = field?.fieldValue;
                if (errors && !!errors[field.fieldName]) delete newErrors[(field?.fieldName)];
            });
            setForm({
                ...form,
                ...itemsToAdd
            })

            setErrors({
                ...newErrors
            });
        } else {
            setForm({
                ...form,
                [fields]: value,
            })
            if (errors && !!errors[fields]) {
                setErrors({
                    ...errors,
                    [fields]: null,
                });
            };
        }
    };

    const handleCancel = () => {
        setDriversToBeNotifed(0);
        setDriversNotifed(0);
        navigate(-1);
    }

    useEffect(() => {
        if (form?.subject || form?.message) {
            setSubjectCharCount(form?.subject.trim().length);
            setMessageCharCount(form?.message.trim().length);
        }
    }, [form?.subject, form?.message])

    useEffect(() => {
        if (id) {
            getNotification(id);
        }
    }, [id])

    useEffect(() => {
        handleGetRecipientStatuses();
    }, [])

    return (
        <>
            <SubHeader
                text={"Push Notifications"}
                btnSmall={<Home sx={{ color: '#fff', fontSize: 30 }} />}
                smallAction={() => navigate(`/push-notifications`)}
            />

            <div style={{ marginLeft: 60 }}>
                <Breadcrumbs
                    leftTitle="PN Home"
                    middleTitle={"National Push Notifications"}
                    midItemAction={() => navigate(`../push-notifications/national-push-notifications`, { replace: true })}
                    rightTitle={`${location.pathname.includes('edit') ? 'Edit National Notification' : 'View National Notification'}`}
                    route="/push-notifications/national-push-notifications"
                    style={{ marginLeft: 30 }}
                />
            </div>

            <FormContent
                confirmPushNotification={confirmPushNotification}
                customStyles={customStyles}
                errors={errors}
                handleCancel={handleCancel}
                handleCheckbox={handleCheckbox}
                headerTitle={`${location.pathname.includes('edit') ? 'Edit National Notification' : 'View National Notification'}`}
                recipientStatuses={recipientStatuses}
                isLoadingRecipientStatus={isLoadingRecipientStatus}
                form={form}
                setForm={setForm}
                isLoading={isLoading}
                location={location}
                messageCharCount={messageCharCount}
                messageCharLimit={messageCharLimit}
                setField={setField}
                setMessageCharCount={setMessageCharCount}
                setSubjectCharCount={setSubjectCharCount}
                subjectCharCount={subjectCharCount}
                subjectCharLimit={subjectCharLimit}
                setShowDeleteModal={setShowDeleteModal}
            />

            <ModalConfirmation
                title={'Please Confirm'}
                body={'Are you sure you want to permanently delete this push notification?'}
                isOpen={showDeleteModal}
                primaryButton
                optionalClose
                primaryButtonTitle={'OK'}
                secondaryButtonTitle={'Cancel'}
                onClick={() => {
                    setShowDeleteModal(false);
                    removeNationalPushNotification(id);
                    navigate(-1);
                }}
                secondaryOnClick={() => { setShowDeleteModal(false) }}
            />

            <ModalConfirmation
                title="Error"
                body="There was an error with the push notification. Please contact support."
                primaryButtonTitle="Close"
                onClick={() => {
                    setShowErrorModal(false);
                    navigate(-1);
                }}
                isOpen={showErrorModal}
                primaryButton
            />


            <ModalConfirmation
                title={'Send Confirmation'}
                body={`This will be sent to ${driversToBeNotified} drivers`}
                isOpen={showDriversCountModal}
                primaryButton={driversToBeNotified > 0 ? true : false}
                optionalClose
                primaryButtonTitle={'Send'}
                secondaryButtonTitle={'Cancel'}
                isLoading={isLoadingNotifiedNotifications}
                onClick={async () => {
                    sendBulkPushNotification(form);
                }}
                secondaryOnClick={() => {
                    setForm({
                        ...form,
                        status: 'Pending'
                    });
                    setShowDriversCountModal(false);
                }}
            />

            <ModalConfirmation
                title={'Send Confirmation'}
                body={`This was sent to ${driversNotified} drivers`}
                isOpen={showConfirmationModal}
                primaryButton={driversNotified > 0 ? true : false}
                primaryButtonTitle={'Ok'}
                onClick={async () => {
                    setShowConfirmationModal(false);
                    navigate(-1);
                }}
            />

            <Footer />
        </>
    );
}

export default editNationalPushNotification;   