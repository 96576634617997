import React from 'react';

import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { TablePagination } from '../../../components';
import { formatIsoToDate } from '../../../utils';

import './styles.scoped.css';

const columnClassName = 'align-middle text-nowrap';
export const fourCeroOneColumns = [
  {
    Header: 'Plan Number',
    accessor: 'Plan_Number',
    className: columnClassName,
  },
  {
    Header: 'Employee SSN',
    accessor: 'Employee_SSN',
    className: columnClassName,
  },
  {
    Header: 'Division Number',
    accessor: 'Division_number',
    className: columnClassName,
  },
  {
    Header: 'Last Name',
    accessor: 'Last_Name',
    className: columnClassName,
  },
  {
    Header: 'First Name',
    accessor: 'First_Name',
    className: columnClassName,
  },
  {
    Header: 'Middle Name',
    accessor: 'Middle_Name',
    className: columnClassName,
  },
  {
    Header: 'Name Suffix',
    accessor: 'Name_Suffix',
    className: columnClassName,
  },
  {
    Header: 'Birth Date',
    accessor: 'Birth_Date',
    className: columnClassName,
  },
  {
    Header: 'Gender',
    accessor: 'Gender',
    className: columnClassName,
  },
  {
    Header: 'Marital Status',
    accessor: 'Marital_Status',
    className: columnClassName,
  },
  {
    Header: 'Address Line 1',
    accessor: 'Address_Line_1',
    className: columnClassName,
  },
  {
    Header: 'Address Line 2',
    accessor: 'Address_Line_2',
    className: columnClassName,
  },
  {
    Header: 'City',
    accessor: 'City',
    className: columnClassName,
  },
  {
    Header: 'State',
    accessor: 'State',
    className: columnClassName,
  },
  {
    Header: 'ZIP',
    accessor: 'Zip_Code',
    className: columnClassName,
  },
  {
    Header: 'Home Phone Number',
    accessor: 'Home_Phone_Number',
    className: columnClassName,
  },
  {
    Header: 'Work Phone Number',
    accessor: 'Work_Phone_Number',
    className: columnClassName,
  },
  {
    Header: 'Work Phone Ext',
    accessor: 'Work_Phone_Ext',
    className: columnClassName,
  },
  {
    Header: 'Country Code',
    accessor: 'Country_Code',
    className: columnClassName,
  },
  {
    Header: 'Hire Date',
    accessor: 'Hire_Date',
    className: columnClassName,
    Cell: instance => formatIsoToDate(instance.value) || '',
  },
  {
    Header: 'Termination Date',
    accessor: 'Termination_Date',
    className: columnClassName,
    Cell: instance => formatIsoToDate(instance.value) || '',
  },
  {
    Header: 'Rehire Date',
    accessor: 'Re_Hire_Date',
    className: columnClassName,
    Cell: instance => formatIsoToDate(instance.value) || '',
  },
  {
    Header: 'Check Date',
    accessor: 'Check_Date',
    className: columnClassName,
    Cell: instance => formatIsoToDate(instance.value) || '',
  },
  {
    Header: 'Contribution Amount 1',
    accessor: 'Contribution_Amount_1',
    className: columnClassName,
  },
  {
    Header: 'Contribution Amount 2',
    accessor: 'Contribution_Amount_2',
    className: columnClassName,
  },
  {
    Header: 'Contribution Amount 3',
    accessor: 'Contribution_Amount_3',
    className: columnClassName,
  },
  {
    Header: 'Contribution Amount 4',
    accessor: 'Contribution_Amount_4',
    className: columnClassName,
  },
  {
    Header: 'Contribution Amount 5',
    accessor: 'Contribution_Amount_5',
    className: columnClassName,
  },
  {
    Header: 'Contribution Amount 6',
    accessor: 'Contribution_Amount_6',
    className: columnClassName,
  },
  {
    Header: 'Contribution Amount 7',
    accessor: 'Contribution_Amount_7',
    className: columnClassName,
  },
  {
    Header: 'Contribution Amount 8',
    accessor: 'Contribution_Amount_8',
    className: columnClassName,
  },
  {
    Header: 'YTD Hours Worked',
    accessor: 'YTD_Hours_Worked',
    className: columnClassName,
  },
  {
    Header: 'YTD Total Compensation',
    accessor: 'YTD_Total_Compensation',
    className: columnClassName,
  },
  {
    Header: 'YTD Plan Compensation',
    accessor: 'YTD_Plan_Compensation',
    className: columnClassName,
  },
  {
    Header: 'YTD Pre Entry Compensation',
    accessor: 'YTD_Pre_Entry_Compensation',
    className: columnClassName,
  },
  {
    Header: 'Highly Comp Employee Code',
    accessor: 'Highly_Comp_Employee_Code',
    className: columnClassName,
  },
  {
    Header: 'Percent of Ownership',
    accessor: 'Percent_of_Ownership',
    className: columnClassName,
  },
  {
    Header: 'Officer Determination',
    accessor: 'Officer_Determination',
    className: columnClassName,
  },
  {
    Header: 'Participation Date',
    accessor: 'Participation_Date',
    className: columnClassName,
    Cell: instance => formatIsoToDate(instance.value) || '',
  },
  {
    Header: 'Eligibility Code',
    accessor: 'Eligibility_Code',
    className: columnClassName,
  },
  {
    Header: 'LOA Reason Code',
    accessor: 'LOA_Reason_Code',
    className: columnClassName,
  },
  {
    Header: 'LOA Start Date',
    accessor: 'LOA_Start_Date',
    className: columnClassName,
    Cell: instance => formatIsoToDate(instance.value) || '',
  },
  {
    Header: 'LOA End Date',
    accessor: 'LOA_End_Date',
    className: columnClassName,
    Cell: instance => formatIsoToDate(instance.value) || '',
  },
  {
    Header: 'Email Address',
    accessor: 'Email_Address',
    className: columnClassName,
  },
  {
    Header: 'Salary Amount',
    accessor: 'Salary_Amount',
    className: columnClassName,
  },
  {
    Header: 'Salary Amount Qualifier',
    accessor: 'Salary_Amount_Qualifier',
    className: columnClassName,
  },
  {
    Header: 'Termination Reason Code',
    accessor: 'Termination_Reason_Code',
    className: columnClassName,
  },
  {
    Header: 'SOx Reporting Indicator',
    accessor: 'Sarbanes_Oxley_Reporting_Indicator',
    className: columnClassName,
  },
  {
    Header: 'Filler',
    accessor: 'Filler',
    className: columnClassName,
  },
  {
    Header: 'Federal Exemptions',
    accessor: 'Federal_Exemptions',
    className: columnClassName,
  },
  {
    Header: 'Employer Assigned ID',
    accessor: 'Employer_Assigned_ID',
    className: columnClassName,
  },
  {
    Header: 'Compliance Status Code',
    accessor: 'Compliance_Status_Code',
    className: columnClassName,
  },
];

function getSortedIcon(isSorted, isSortedDesc) {
  let iconProp = <FaSort />;
  if (isSorted) {
    iconProp = isSortedDesc ? <FaSortDown /> : <FaSortUp />;
  }
  return iconProp;
}

const FourCeroOneTable = ({ data, loading }) => {
  const tableInstance = useTable(
    {
      columns: fourCeroOneColumns,
      data,
      initialState: {
        pageSize: 200,
        sortBy: [
          {
            id: 'CreatedAt',
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = tableInstance;

  const tableHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        <span>{column.canSort ? getSortedIcon(column.isSorted, column.isSortedDesc) : null}</span>
      </th>
    ));
    return <tr {...headerGroup.getHeaderGroupProps()}>{headers}</tr>;
  });

  const tableRows = page.map(row => {
    prepareRow(row);
    const cells = row.cells.map(cell => (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          style: cell.column.style,
        })}>
        {cell.render('Cell')}
      </td>
    ));

    return <tr {...row.getRowProps()}>{cells}</tr>;
  });

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col className="d-flex justify-content-center justify-content-md-end">
          <TablePagination tableInstance={tableInstance} />
        </Col>
      </Row>
      <Row>
        <Col className="border border-light rounded shadow-sm">
          <Table hover responsive borderless {...getTableProps()}>
            <thead className="text-nowrap">{tableHeaders}</thead>
            <tbody {...getTableBodyProps()}>{tableRows}</tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {loading && <Spinner animation="border" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FourCeroOneTable;
