import React from 'react';
import './styles.scoped.css';

const MenuItem = ({ icon, text, onPress, description }) => {
  const words = text.split(' ');
  const formattedText = words.flatMap((word, index) =>
    word === 'Edge'
      ? [
          <span style={{ fontStyle: 'italic' }} key={index}>
            {word.toUpperCase()}
          </span>,
          ' ',
        ]
      : [word, ' '],
  );

  return (
    <div className="menu-item" onClick={onPress}>
      <img
        alt=""
        className="icon"
        height={60}
        width={60}
        src={require(`../../assets/${icon}.png`)}
      />
      <div className="text-wrapper">
        <span className="title-text">{formattedText}</span>
        {description && <span className="description-text">{description}</span>}
      </div>
    </div>
  );
};

export default MenuItem;
