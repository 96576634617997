import { formatIsoToDate } from '../../../../utils';

export const getSearchExpensesCsvData = expenses => {
  const csvData = [
    [
      'ID',
      'Date Entered',
      'Pay Code',
      'Driver Name & Number',
      'Assignment / Contract Activity',
      'Customer Name & Number',
      'Client Branch',
      'Cost Center',
      'Expense Start Date',
      'Expense End Date',
      'Pay Week Ending',
      'Total Receipt Amount',
      'Total To Be Billed',
      'Status',
      'Updated At',
      'Statement Date',
      'Expense Report Appropriation Number',
      'Variance',
      'Receipt Amount',
      'Non Billable',
      'Invoice Date',
      'Invoice Number',
      'Projected Days',
      'Daily Charge',
      'Projected Amount',
      'Total Projected Amount',
      'Paid Through',
      'Airline',
      'Car Rental Company',
      'Hotel',
    ],
  ];

  for (let i = 0; i < expenses.length; i += 1) {
    for (let j = 0; j < expenses[i]?.expenseItem?.length; j += 1) {
      csvData.push([
        `${expenses[i].id}`,
        `${formatIsoToDate(expenses[i].createdAt) || ''}`,
        `${expenses[i].code}`,
        `${expenses[i].driverNameNumber}`,
        `${expenses[i].assignmentId}`,
        `${expenses[i].customerNameNumber}`,
        `${expenses[i].branch}`,
        `${expenses[i].costCenter}`,
        `${formatIsoToDate(expenses[i].startDate) || ''}`,
        `${formatIsoToDate(expenses[i].endDate) || ''}`,
        `${formatIsoToDate(expenses[i].payWeekEnding) || ''}`,
        `${expenses[i]?.totalReceiptAmount ? expenses[i]?.totalReceiptAmount : '-'}`,
        `${expenses[i]?.totalToBeBilled ? expenses[i]?.totalToBeBilled : '-'}`,
        `${expenses[i]?.status}`,
        `${formatIsoToDate(expenses[i]?.updatedAt) || ''}`,
        `${formatIsoToDate(expenses[i]?.statementDate) ? formatIsoToDate(expenses[i]?.statementDate) : '-'}`,
        `${expenses[i]?.expenseReportAppropriationNbr ? expenses[i]?.expenseReportAppropriationNbr : '-'}`,
        `${expenses[i]?.variance ? expenses[i]?.variance : '-'}`,
        `${expenses[i]?.expenseItem?.[j]?.totalReceiptAmount}`,
        `${expenses[i]?.nonBillable ? expenses[i]?.nonBillable : '-'}`,
        `${formatIsoToDate(expenses[i]?.invoiceDate) ? formatIsoToDate(expenses[i]?.invoiceDate) : '-'}`,
        `${expenses[i]?.invoiceNumber ? expenses[i]?.invoiceNumber : '-'}`,
        `${expenses[i]?.expenseItem?.[j]?.projectedDays}`,
        `${expenses[i]?.expenseItem?.[j]?.dailyCharge}`,
        `${expenses[i]?.expenseItem?.[j]?.projectedAmount}`,
        `${expenses[i]?.totalProjectedAmount}`,
        `${expenses[i]?.expenseItem?.[j]?.paidThrough}`,
        `${expenses[i]?.code?.includes('A') ? expenses[i]?.expenseItem[j]?.airline?.name || 'null' : ''}`,
        `${expenses[i]?.code?.includes('C') ? expenses[i]?.expenseItem[j]?.carRental?.name || 'null' : ''
        }`,
        `${expenses[i]?.code?.includes('H') ? expenses[i]?.expenseItem[j]?.hotel?.name || 'null' : ''}`,
      ]);
    }
  }

  return csvData;
};
