import { API, graphqlOperation } from 'aws-amplify';

import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';

export const getUserData = async userEmail => {
  try {
    const response = await API.graphql({
      query: queries.getUser,
      variables: { id: userEmail },
    });
    return response?.data?.getUser;
  } catch (e) {
    console.log(e);
  }
  return undefined;
};

export const getClient = async id => {
  try {
    const path = `clients/${id}`;
    const response = await API.get('centerlineportal', path);
    return response;
  } catch (e) {
    return e;
  }
};

export const updateUser = async input => {
  try {
    return await API.graphql(graphqlOperation(mutations.updateUser, { input }));
  } catch (e) {
    console.log(`Update User Error: ${JSON.stringify(e)}`);
    return false;
  }
};

export const createUserCustomer = async input => {
  try {
    await API.graphql(graphqlOperation(mutations.createUserCustomer, { input }));
    return true;
  } catch (e) {
    console.log(`Update Customers Error: ${JSON.stringify(e)}`);
    return false;
  }
};

export const deleteUserCustomer = async input => {
  try {
    await API.graphql(graphqlOperation(mutations.deleteUserCustomer, { input }));
    return true;
  } catch (e) {
    console.log(`Update Customers Error: ${JSON.stringify(e)}`);
    return false;
  }
};
