import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { setDocumentCategories, setDocumentTypes } from './standardDocumentSlice';
import store from '../../../../app/store';

export const createStandardDocument = async (payload) => {
    try {
        const response = await API.graphql(
            graphqlOperation(mutations.createStandardDocuments, { input: payload }),
        );
        return response.data?.createStandardDocument;
    } catch (e) {
        console.log('Error: ' + JSON.stringify(e));
        return e;
    }
}

export const updateStandardDocument = async (payload) => {
    try {
        const response = await API.graphql(
            graphqlOperation(mutations.updateStandardDocuments, { input: payload }),
        );
        return response.data?.updateStandardDocuments;
    } catch (e) {
        console.log('Error: ' + JSON.stringify(e));
        return e;
    }
}

export const getStandardDocument = async (id) => {
    try {
        const response = await API.graphql({
            query: queries.getStandardDocuments,
            variables: { id }
        });
        return response.data?.getStandardDocuments;
    } catch (e) {
        console.log('Error: ' + JSON.stringify(e));
        return e;
    }
}

export const getCategories = async () => {
    try {
        const response = await API.graphql({
            query: queries.listDocumentCategories,
            variables: {
                filter: { documentSourceCategoryId: { eq: '1' } }
            }
        });
        store.dispatch(setDocumentCategories(response.data?.listDocumentCategories?.items));
    } catch (e) {
        console.log('Error: ' + JSON.stringify(e));
        return e;
    }
};

export const getTypes = async (id) => {
    try {
        const response = await API.graphql({
            query: queries.listDocumentTypes,
            variables: {
                filter: { documentCategoryTypeId: { eq: id } }
            }
        });
        store.dispatch(setDocumentTypes(response.data?.listDocumentTypes?.items));
    } catch (e) {
        console.log(e);
    }
};

export const getClient = async (id) => {
    try {
        const path = `clients/${id}`;
        const response = await API.get('centerlineportal', path);
        return response;
    } catch (e) {
        console.log('Error: ' + JSON.stringify(e));
        return e;
    }
}

export const getDriver = async (id) => {
    try {
        const path = `drivers/${id}`;
        const response = await API.get('centerlineportal', path);
        return response;
    } catch (e) {
        console.log('Error: ' + JSON.stringify(e));
        return e;
    }
}
