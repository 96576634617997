/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { getRoleId } from './operations';
import useIsMobileScreen from '../../hooks/useCheckIsMobile';
import { SubHeader, Footer } from '../../components'
import { useNavigate } from 'react-router-dom';
import { Web } from '@mui/icons-material';
import "./styles.scoped.css";

const PushNotifications = () => {
  const navigate = useNavigate();
  const { menuData } = useSelector(state => state.pushNotifications);
  const user = useSelector(state => state.admin?.user);
  const [roleId, setRoleId] = useState();
  const isMobile = useIsMobileScreen;

  const getApplicationRoleUserAppAccessId = async () => {
    const response = await getRoleId(user.id);
    response?.map(item => {
      if (item?.appId?.name === 'Push Notifications') {
        setRoleId(item?.applicationRoleUserAppAccessId)
      }
    });
  }

  useEffect(() => {
    if (user.id) {
      getApplicationRoleUserAppAccessId(user.id);
    }
  }, [user])

  return (
    <>
      <SubHeader
        text={"Push Notifications"}
        btnSmall={<Web sx={{ color: '#fff', fontSize: 30 }} />}
        smallAction={() => navigate('/dashboard')}
      />

      <div className="wrap">
        <div className="row">
          <div className="header">Choose your Notification</div>
        </div>
        <div>
          <span className="row">
            {menuData?.map(i => (
              i.roleId === roleId &&
              i.items?.map(i => (
                <div
                  key={i.title}
                  style={{ minWidth: isMobile ? '320px' : '390px' }}
                  className="button"
                  onClick={() => navigate(i.to)}>
                  {i.title}
                </div>
              ))
            ))}
          </span>
        </div >
      </div >
      <Footer />
    </>
  );
}


export default PushNotifications;   