import React from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scoped.css';

const ErrorModal = ({ dismiss, error }) => (
  <div className="overlay-box">
    <div className="spinner-wrap">
      <FontAwesomeIcon
        onClick={dismiss}
        icon={faTimes}
        className="fa-lg"
        style={{ position: 'absolute', right: 10, top: 10 }}
        />
      <span className="spinner-text">{error}</span>
    </div>
  </div>
);

export default ErrorModal;
