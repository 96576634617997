import { API, graphqlOperation } from 'aws-amplify';

import { variablesDependingOnType } from './utils';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

export const getTableContent = async ({ type, selectedState = undefined, nextToken = null }) => {
  try {
    const accessor = variablesDependingOnType.list[type];

    let variables = { nextToken, limit: 100000 };
    if (selectedState) {
      variables = { filter: { stateCityId: { eq: selectedState.value } }, ...variables };
    }

    const response = await API.graphql(graphqlOperation(queries[accessor], variables));

    if (response.data[accessor].nextToken) {
      const nextTableContents = await getTableContent({
        type,
        selectedState,
        nextToken: response.data[accessor].nextToken,
      });
      return [...response.data[accessor].items, ...nextTableContents];
    }

    return response.data[accessor].items;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const createTableContent = async (type, input) => {
  try {
    const accessor = variablesDependingOnType.create[type];
    await API.graphql(graphqlOperation(mutations[accessor], { input }));
    return true;
  } catch (e) {
    console.log(`Create ${type} Error: ${JSON.stringify(e)}`);
    return { errorMessage: `Create ${type} failed. Please try with other values.` };
  }
};

export const deleteTableContent = async (type, input) => {
  try {
    const accessor = variablesDependingOnType.delete[type];
    await API.graphql(graphqlOperation(mutations[accessor], { input }));
    return true;
  } catch (e) {
    console.log(`Delete ${type} Error: ${JSON.stringify(e)}`);
    return { errorMessage: `Delete ${type} failed. Please try again later.` };
  }
};

export const updateTableContent = async (type, input) => {
  try {
    const accessor = variablesDependingOnType.update[type];
    await API.graphql(graphqlOperation(mutations[accessor], { input }));
    return true;
  } catch (e) {
    console.log(`Update ${type} Error: ${JSON.stringify(e)}`);
    return { errorMessage: `Update ${type} failed. Please try again later.` };
  }
};

export const getStateOptions = async nextToken => {
  try {
    const response = await API.graphql(graphqlOperation(queries.listStates, { nextToken }));
    if (response.data.listStates.nextToken) {
      const nextStatesOptions = await getStateOptions(response.data.listStates.nextToken);
      return [response.data.listStates.items, ...nextStatesOptions];
    }
    return response.data.listStates.items;
  } catch (e) {
    console.log(e);
    return [];
  }
};
